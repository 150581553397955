/**
 * This function only is used to Get Care Now and disable Chat with a doctor options
 * 
 * @param state 
 * @return boolean 
 */
const disableToTN = (state: string) => {
    if(state === 'TN') return true;
    return false;
}

export enum ScheduleCode {
    startDay = 0,
    endDay = 6,
    startTime = 8,
    endTime = 18,
    endTimeFHS = 19,
}

export {
    disableToTN
}