
import { UserCredentials } from '../entities/userCredentials';
import { AuthService } from './contracts/authService';

/**
 * Validate Session | Load user info
 */
const loadUserBySession = (
	service: Pick<AuthService, 'loadUserBySession'>,
	token: string,
	state: string,
) => service.loadUserBySession(token, state);

/**
 * Login | Get access token
 */
const login = (
	service: Pick<AuthService, 'login'>,
	data: Omit<UserCredentials, 'phone'>
) => service.login(data);


const loginSecurity = (
	service: Pick<AuthService, 'loginSecurity'>,
	data: Omit<UserCredentials, 'phone'>
) => service.loginSecurity(data);

const loadLoginAllStates = (
	service: Pick<AuthService, 'loadLoginAllStates'>,
	data: Omit<UserCredentials, 'phone'>
) => service.loadLoginAllStates(data);

const authUseCase = {
	login,
	loadUserBySession,
	loginSecurity,
	loadLoginAllStates,
};

export default authUseCase;
