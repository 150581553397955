import styled from '../../ui-core/styled-components';
import { KeraltyText, KeraltyIcon, KeraltyError, KeraltyTooltip } from '../atoms';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';

interface KeraltFieldProps {
	className?: string;
	children?: any[];
	label?: string;
	iconClass?: string;
	title?: string;
	control?: any;
	error?: any;
	noMargin?: boolean;
	actionIcon?: any;
	onActionIconClick?: () => void;
	infotooltip?: string;
	[key: string]: any;
	disabled?: boolean;
	modifyErrorMessage?: (string) => string;
	ignoreLabelSpace?: boolean;
	marginTopCheck?: boolean;
	placeholderColor?: string;
	isSelected?: boolean; // this prop is only used when is a radio button input or check box input
};

const InfoFieldIcon = styled(KeraltyIcon)`
	margin-left: 0.58rem;
	margin-right: 0.58rem;
`;

const FieldStructure = ({
	className, label, iconClass, title, string, actionIcon, onActionIconClick, error,
	control: Control, field, noMargin = false, mandatory = false, modifyErrorMessage,
	ignoreLabelSpace, ...props
}: KeraltFieldProps) => {
	const { t } = useTranslation();
	const tooltipElement = (
		<KeraltyTooltip>
			<InfoFieldIcon
				data-iscapture="true"
				data-tip={props.infotooltip}
				iconClass="fas fa-info-square"
				color="accent"
			/>
		</KeraltyTooltip>
	);

	if (mandatory) { label = label && `${label}*`; props.placeholder = props.placeholder && `${props.placeholder}*` };

	const controlStyles = {
		option: (styles: any) => {
		  return {
			...styles,
			fontSize: '1.13rem'
		  };
		},
	};

	return (
		<div className={`${className} field-${props.type || ''}`} >
			{(label && props.type !== 'checkbox') && <KeraltyText type="label">{label}</KeraltyText>}
			{label && props.infotooltip && tooltipElement}

			<div className={'input-container'}>
				{Control &&
					<Control
						{...props}
						styles={controlStyles}
						className={`${props.className || ''} ${props.type || ''}`} />}
				{(!Control && props.mask) &&
					<InputMask
						maskChar={props.maskChar ? props.maskChar : ''}
						{...props}
						{...field}
						inputRef={field?.ref}
					// id={`${field?.name}-control`}
					// autoComplete={`${field?.name}-control`}
					/>}
				{(!Control && !props.mask) &&
					<input
						{...props}
						{...field}
						style={actionIcon && onActionIconClick ? {paddingRight: '3rem'} : {}}
					// name={`${field?.name}-control`}
					// id={`${field?.name}-control`}
					// autoComplete={`${field?.name}-control`}
					/>}

				{iconClass && <div className={'icon-container'}><KeraltyIcon iconClass={iconClass} title={title} /></div>}
				{
					(actionIcon && onActionIconClick) &&
					<div onClick={() => onActionIconClick()} className={'icon-container action'}>{actionIcon}</div>
				}
			</div>

			<KeraltyError error={error ? (modifyErrorMessage ? modifyErrorMessage(t(`errors.${error.message}`)) : t(`errors.${error.message}`)) : null} />
		</div>
	);
}

const KeraltyField = styled(FieldStructure) <KeraltFieldProps>`
	width: 100%;
	position: relative;
	${({ noMargin }) => !noMargin && 'margin-bottom: .5rem;'}
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		${({ noMargin }) => !noMargin && 'margin-bottom: 1rem;'}
	}
	.input-container {
		${({ marginTopCheck }) => !marginTopCheck ? 'margin-top: 1.08rem !important;' : 'margin-top: 0px !important;'}
		${({ label, ignoreLabelSpace }) => !label && !ignoreLabelSpace ? 'margin-top: 1.08rem;' : 'margin-top: .5rem;'}
		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			margin-top: 0px;
		}

		width: 100%;
		/* max-width: 350px; */
		position: relative;
		border-bottom-color: ${({ theme: { colors } }) => colors.primary};
		.icon-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 3.67rem;
			height: 3.75rem;
			display: flex;
			justify-content: center;
			align-items: center;
			*{
				font-size: 1.33rem;
				color: ${({ theme: { colors } }) => colors.darkGray};
			}
			&.action {
				left: auto;
				right: 0;
				cursor: pointer;
				&:hover{
					opacity: .8;
				}
			}
		}
		input {
			${({ type }) => (type === 'select' || type === 'radioGroup') ? '' : `
				height: 3.75rem;
				max-height: 3.75rem;
			`}
			${({ type, theme: { colors }, iconClass }) => type === 'radioGroup' ? '' : `
				width: 100%;
				padding: 0px;
				border: none;
				border-bottom: .23rem solid ${colors.gray};
				border-radius: .33rem;
				padding-left: ${iconClass ? '3.5rem' : '.83rem'};
				margin-bottom: .8rem;
				box-sizing: border-box;
				background-color: ${colors.lightGray};
				color: ${colors.dark};
				outline: none !important;
				font-size: 1.5rem;
			`}
			&::placeholder {
				color: ${({ placeholderColor, theme: { colors } }) =>
      						placeholderColor || colors.dark};;
				font-size: 1.33rem;
				/* text-transform: lowercase; */
			}
			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0px 1000px #EEEDED inset !important;
				&::first-line {
					color: ${({ theme: { colors } }) => colors.primary};
					font-size: 1.5rem;
				}
			}
			&:focus {
				border-color: transparent;
				border-bottom-color: ${({ theme: { colors } }) => colors.primary};
				color: ${({ theme: { colors } }) => colors.dark} !important;
				& ~ .icon-container {
					* {
						color: ${({ theme: { colors } }) => colors.primary} !important;
					}
				}
			}
			&:not(:placeholder-shown) {
				color: ${({ disabled, theme: { colors } }) => disabled ? colors.dark : colors.primary};
				-webkit-text-fill-color: ${({ disabled, theme: { colors } }) => disabled ? colors.dark : colors.primary};
				opacity: 1;
			}
			&:focus:not(:placeholder-shown) {
				border-bottom-color: ${({ theme: { colors } }) => colors.accent};
				& ~ .icon-container {
					* {
						color: ${({ theme: { colors } }) => colors.accent} !important;
					}

				}
			}

			${({ theme: { colors }, isSelected }) => isSelected ? `border-bottom-color: ${ colors.accent } !important` : null };
		}

		/* REACT DATE PICKER */
		${({ selected, theme: { colors } }) => `
			.react-datepicker-wrapper:focus-within {
				border-bottom-color: ${selected ? colors.accent : colors.primary};
				color: ${colors.dark};
				& ~ .icon-container * {
					color: ${selected ? colors.accent : colors.primary};
				}
			}
		`}

		/* REACT SELECT */
		.select {
			width: calc(100% - 2px);
			max-width: calc(100% - 2px);
			margin: 0px 1px;
			height: 3.75rem;
			margin-bottom: .8rem;
			[class*="-control"] {
				min-height: 100%;
				outline: none;
				background-color: ${({ theme: { colors } }) => colors.lightGray};
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				&>div:nth-child(1) {
					padding-left: ${({ iconClass }) => iconClass ? '3.67rem' : '1.3rem'};
					* {
						color: ${({ value, theme: { colors } }) => value ? colors.primary : colors.dark};
						font-size: 1.33rem;
					}
					input {
						margin-bottom: 0px;
					}
				}
				[class*="-indicatorSeparator"] { display: none; }
			}
			${({ value, theme: { colors } }) => `
				&:focus-within {
					[class*="-control"] {
						border-color: ${value ? colors.accent : colors.primary};
						color: ${colors.dark};
						* {
							color: ${colors.dark};
						}
					}
					& ~ .icon-container * {
						color: ${value ? colors.accent : colors.primary};
					}
				}
			`}
		}

		${({ error, theme: { colors } }) => error && `
			input {
				border-bottom-color: ${colors.danger};
				color: ${colors.dark} !important;
			}
			.select [class*="-control"] {
				border-color: ${colors.danger};
			}
			.icon-container {
				* {
					color: ${colors.danger};
				}
			}
		`}
	}
	/* FILE UPLOADER */
	&.field-file {
		.error-msg {
			margin-top: .5rem;
		}
	}
	/* RADIO GROUP */
	&.field-radioGroup {
		.input-container {
			min-height: 3.75rem;
			display: flex;
			align-items: center;
			input{
				height: 21px !important;
				max-height: 21px !important;
			}
		}
	}
`;

export default KeraltyField;
