import styled from '../../ui-core/styled-components';

interface DividerProps {
	my?: number;
	mb?: number;
	mx?: number;
};


const Divider = styled.hr<DividerProps>`
	border: 1px solid ${({ theme: { colors } }) => colors.gray};
	border-bottom: none;
	margin-left: ${({ mx }) => (mx !== undefined? `${mx}rem` : '1.5rem')}; 
  	margin-right: ${({ mx }) => (mx !== undefined ? `${mx}rem` : '1.5rem')}; 
	width: ${({ mx }) => mx !== undefined && mx <= 0 ? `calc(100% + ${Math.abs(mx)}rem)` : 'calc(100% - 3rem)'};
	margin-bottom: 3rem;
	${({ my }) => my ? `margin-top: ${my}rem; margin-bottom: ${my}rem;` : ''}
	${({ mb }) => mb ? `margin-bottom: ${mb}rem;` : ''}
`;

export default Divider;
