import { useAppSelector } from 'adapter/hooks';
import { idEcw } from 'adapter/user/userSlice';
import { EcwRepository } from 'infrastructure/keraltyApi/repositories/ecw';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AppointmentsInfo } from '../../../../domain/entities/appointmentsInfo';
import { KeraltyIcon, KeraltyText } from '../../../components/atoms';
import { KeraltyFilterDate, KeraltyLongCard, PaginateItems } from '../../../components/molecules';
import { Column, Container, Loader, Row, SpaceWrapper } from '../../../components/templates';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import moment from 'moment';

import { capitalize } from 'app/ui-core/utils/helpers/format';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import EmptyPrevious from '../../../assets/icons/EmptyPrevious.svg';
import NoResult from '../../../assets/icons/noResult.svg';
import ROUTES from '../../../ui-core/utils/routes';

import useIsMobile from '../../../hooks/useIsMobile';
import { useParams } from 'react-router-dom';
import useAppointment from 'app/hooks/useAppointment';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';

const { MAIN, GET_CARE: CURRENT } = ROUTES;


const Heart = styled(Column)`
  justify-content: center;
  align-self: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    display: none !important;
  }
`;

const CardsContainer = styled(Column)`
  flex-direction: column;
  align-items: flex-start;
`;

const InfoColumn = styled(Column)`
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 14px;
`;

const InfoContainer = styled(Container)`
  width: 100%;
  margin-bottom: 2.5rem;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0rem;
    margin-top: 0.5rem;
  }
`;

const IconSpace = styled(KeraltyIcon)`
  margin-right: 7px;
  &.toggle {
    @media (min-width: ${BREAKPOINTS.maxPhoneWidth}) {
      display: none;
    }
  }
`;

const IconSpaceContainer = styled(Column)`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 1rem;
  }
`;

const Label = styled(KeraltyText)`
  margin-right: 0.7rem;
  ${(props) => {
    const weight = props.bold ? 600 : 300;
    return `font-weight: ${weight}`;
  }};
`;

const KeraltyButtonFilterContainer = styled(Column)`
  display: flex;
  justify-content: flex-start;
  margin-right: 1rem;
  margin-bottom: 1rem;
  span {
    margin: 0 1rem;
  }
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    display: none;
    margin-bottom: 0rem;

  }

  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    max-width: ${BREAKPOINTS.maxPhoneWidth};
    margin-bottom: 0rem;
  }
`;

const IconRow = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    flex-direction: column;
  }
`;

const ContentDiv = styled.div`

	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
	background: #3CA70D;
	border-radius: 29px;
	height:25px;
	};
`;

interface IconLabelProps {
  color?: string;
  iconClass?: string;
  iconKey?: any;
  labelKey: string;
  text?: string;
  textType?: boolean;
  title?: boolean;
  toggleMobile?: boolean;
  fontWeight?:number

}

const IconWithLabel = ({
  color,
  iconClass,
  iconKey,
  labelKey,
  text,
  title,
  toggleMobile,
  fontWeight,
}: IconLabelProps) => {
  const { t } = useTranslation();
  const colorType = !color ? 'accent' : color;

  return (
    <IconSpaceContainer md={'auto'} sm={12}>
      {iconClass && (
        <IconSpace
          className={toggleMobile ? 'toggle' : ''}
          iconClass={iconClass}
          color={colorType}
          size="common"
          title={t(iconKey)}
        />
      )}
      {!color ? (
        <Label type="label" bold={title ? true : false}>
          {t(labelKey)}
          {!title && ':'}
        </Label>
      ) : (
        <Label type="label" color={colorType} bold={title ? true : false}>
          {t(labelKey)}:
        </Label>
      )}
      {text && (
        <KeraltyText type="label" color="dark" fontWeight={fontWeight? fontWeight:''}>
          {text}
        </KeraltyText>
      )}
    </IconSpaceContainer>
  );
};

const PreviousAppointments = (props) => {
  const { titleFather } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { subTab } = useParams();
  const patientId = useAppSelector(idEcw);

  const [previousAppointmentsData, setPreviousAppointments] = useState<AppointmentsInfo[]>([]);
  const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [cleanInput, setCleanInput] = useState(false);
  const [visible, setVisible] = useState(false);
  const [closeModalError, setCloseModalError] = useState(false);
  const [isGetError, setIsGetError] = useState(false);

  const [initPage, setInitPage] = useState(false);

  const { getNewDate } = useAppointment();

  const title = useMemo(
    () =>
      t(
        `getCare.${!subTab
          ? 'appointments'
          : subTab === CURRENT.previousAppointments
            ? 'prevAppointments'
            : 'upcomingAppointments'
        }`
      ),
    [t, subTab]
  );


  useEffect(() => {
    if (patientId) {
      EcwRepository.getPreviousAppointments({ patientId })
        .then((result) => {
          setPreviousAppointments(result);
          setIsGetError(false);
        })
        .catch((error) => { 
          if(error?.message == '999') {
            setCloseModalError(true);
            setIsGetError(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [patientId]);

  const onChangeDate = useCallback((type, data) => {
    setIsFiltered(true)
    type && setDataObject(data);
    type && setVisible(true);
    setIsModalVisible(false);
	setInitPage(true)
  }, []);

  const appointmentsToShow = useMemo(() => {
    if (!dataObject.to || !dataObject.from) return previousAppointmentsData;
    const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
    const toDate = moment(dataObject.to, 'MM/DD/YYYY');
    return previousAppointmentsData.filter((appointment) => {
      const currentDate = moment(appointment.date, 'YYYY-MM-DD');
      return currentDate.isBetween(fromDate, toDate, undefined, '[]');
    });
  }, [dataObject, previousAppointmentsData]);

  const ClearDate = () => {
    var p1 = { from: "", to: "" };
    var Init = moment().format();
    console.log('dsadsa' + Init)
    setDataObject(p1)
    setVisible(false)
    setIsFiltered(false)
  }

  const FilterVisible = () => {
    const format = (date) => { return date?.split('/').reverse().join('/').slice(2).split('/').reverse().join('/') }
    return (
      <>
        {!isMobile ?
          <Row style={{ marginLeft: 10 }}>
            <Row style={{ justifyContent: 'space-between' }} noMargin >
              <ContentDiv style={{}} onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
                <div style={{ padding: 5, textAlign: 'left', marginLeft: '15px' }} >
                  <KeraltyText style={{ fontSize: '12px', color: '#FFFFFF', margin: 0 }}>{format(dataObject.from)}</KeraltyText>
                  <KeraltyText style={{ fontSize: '12px', color: '#FFFFFF' }}> - </KeraltyText>
                  <KeraltyText style={{ fontSize: '12px', color: '#FFFFFF', padding: 5 }}>{format(dataObject.to)}</KeraltyText>
                  <KeraltyText style={{ fontSize: '14px', paddingRight: 10, paddingLeft: 5, color: '#FFFFFF' }}>X</KeraltyText>
                </div>
              </ContentDiv>
              <div style={{ marginLeft: 10 }}>
                <KeraltyText style={{ fontSize: '16px', color: '#999999' }}>{appointmentsToShow.length} {t('myHealth.resul')}</KeraltyText>
              </div>
            </Row>
          </Row>
          :
          <Row>
            <Row style={{ justifyContent: 'space-between', marginRight: 10, marginLeft: 10 }} noMargin>
              <ContentDiv style={{}} onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
                <div style={{ padding: 5, textAlign: 'left', marginLeft: '15px' }} >
                  <KeraltyText style={{ fontSize: '12px', color: '#FFFFFF' }}>{format(dataObject.from)}</KeraltyText>
                  <KeraltyText style={{ fontSize: '12px', color: '#FFFFFF' }}> - </KeraltyText>
                  <KeraltyText style={{ fontSize: '12px', color: '#FFFFFF', padding: 5 }}>{format(dataObject.to)}</KeraltyText>
                  <KeraltyText style={{ fontSize: '14px', paddingRight: 10, paddingLeft: 5, color: '#FFFFFF' }}>X</KeraltyText>
                </div>
              </ContentDiv>
              <div>
                <KeraltyText style={{ fontSize: '16px', color: '#999999' }} >{appointmentsToShow.length} {t('myHealth.resul')}</KeraltyText>
              </div>
            </Row>
          </Row>
        }
      </>
    )
  }

  return (
    <>
      {closeModalError && <ModalErrorMyHealth setCloseModalError={()=>setCloseModalError(false)}/>}
      {isLoading && <Loader />}

      <Row style={{maxWidth: '1000px'}}>
	  	{
            appointmentsToShow.length > 0
            && (
              <KeraltyFilterDate
                cleanInput={cleanInput}
                onSuccess={onChangeDate}
                onCancel={() => setIsModalVisible(false)}
                showModalFlag={isModalVisible}
              />
            )
        }
		{
			titleFather != null ? (
				<Row noMargin>
					<Column style={{ maxWidth: '1000px' }}>
						<div style={{ width: '80%', }}>
							<KeraltyText type="title" align="left">{titleFather}</KeraltyText>
						</div>

						<div style={{ marginTop: '0%', width: '30%', textAlign: 'right' }}>
							<KeraltyFilterDate
								cleanInput={cleanInput}
								onSuccess={onChangeDate}
								onCancel={() => setIsModalVisible(false)}
								showModalFlag={isModalVisible}
							/>

							<div onClick={() => setIsModalVisible(true)}>
								<KeraltyIcon iconClass="fas fa-filter" color="accent" style={{ paddingRight: '2%' }} />
								<KeraltyText type="label" color={'accent'}>{t('KeraltyFilter.title')}</KeraltyText>
							</div>
						</div>
					</Column>
					<Row>
						{visible ? <FilterVisible /> : ''}
					</Row>
				</Row>
			) : (
				<div style={{ width: '90%' }}>
					<KeraltyButtonFilterContainer onClick={() => setIsModalVisible(true)}>
						<KeraltyIcon iconClass="fas fa-filter" color="accent" />
						<KeraltyText type="label" color={'accent'}>
							{t('KeraltyFilter.title')}
						</KeraltyText>
					</KeraltyButtonFilterContainer>
					<Row>
						{visible ? <FilterVisible /> : ''}
					</Row>
				</div>
			)
		}
        <CardsContainer lg={12} md={12} sm={12}>
          {appointmentsToShow.length < 1 && !isGetError ? (!isFiltered ?
            <Container centered={'xy'}>
              <NoSearchResult image={EmptyPrevious} subTitle={t('myHealth.noPrevious')} />
            </Container> : <Container centered={'xy'}>
              <NoSearchResult image={NoResult} title={t('myHealth.titleEmpty')} subTitle={t('myHealth.textEmpty')} />
            </Container>
          ) : (
            <PaginateItems setToStartPage={initPage}>
              {appointmentsToShow.map((aptInfo) => {

                const customDate = getNewDate({ date: `${aptInfo.date}T${aptInfo.startTime}:00Z`, isNotUTCDate: true });

                return (
                  <KeraltyLongCard noPadding>
                    <Row noMargin fullHeight>
                      <Heart lg={1} md={1} sm={12}>
                        <KeraltyIcon
                          iconClass="fas fa-heart"
                          color="accent"
                          size="big"
                          title={t('imgNicons.heartIcon')}
                        />
                      </Heart>

                      <InfoColumn lg={11} md={11} sm={12}>
                        <InfoContainer noPadding centered="y">

                          <IconRow>
                            <IconWithLabel
                              iconClass="fas fa-heart"
                              iconKey="imgNicons.heartIcon"
                              labelKey={capitalize(aptInfo.visitType)}
                              title={isMobile?true:false}
                              toggleMobile
                            />
                            <IconWithLabel
                              iconClass="fas fa-calendar-day"
                              iconKey="imgNicons.calendarIcon"
                              labelKey={'getCare.date'}
                              title
                              // text={capitalize(moment(aptInfo.date + ' ' + aptInfo.startTime).format('MMMM DD YYYY'))}
                              text={customDate?.date ? moment(customDate.date).format('MMMM DD YYYY') : ''}
                              fontWeight={400}
                              color='primary'
                            />

                            {isMobile && <IconWithLabel
                              iconClass={'fas fa-clock'}
                              iconKey={'imgNicons.clock'}
                              labelKey={'getCare.time'}
                              title
                              // text={moment(aptInfo.startTime, 'hh:mm:ss').format('hh:mm A')}
                              text={`${customDate?.time} (${ aptInfo.zonedTime })`}
                              color='primary'
                              fontWeight={400}
                            />}
                            {!isMobile && <IconWithLabel
                              iconClass="fas fa-user-md"
                              iconKey="imgNicons.chatDoctorIcon"
                              labelKey={'getCare.provider'}
                              title
                              text={
                                capitalize(aptInfo.ufName || '') +
                                ' ' +
                                capitalize(aptInfo.ulName || '')
                              }
                              fontWeight={400}
                              color='primary'
                            />}
                          </IconRow>
                          <IconRow>
                            <IconWithLabel
                              iconClass="fas fa-map-marker-alt"
                              iconKey="imgNicons.locationIcon"
                              labelKey={'getCare.location'}
                              title
                              text={aptInfo.name}
                              fontWeight={400}
                              color='primary'
                            />
                            {isMobile && <IconWithLabel
                              iconClass="fas fa-user-md"
                              iconKey="imgNicons.chatDoctorIcon"
                              labelKey={'getCare.provider'}
                              title
                              text={
                                capitalize(aptInfo.ufName || '') +
                                ' ' +
                                capitalize(aptInfo.ulName || '')
                              }
                              fontWeight={400}
                              color='primary'
                            />}
                            {!isMobile && <IconWithLabel
                              iconClass={'fas fa-clock'}
                              iconKey={'imgNicons.clock'}
                              labelKey={'getCare.time'}
                              title
                              // text={moment(aptInfo.startTime, 'hh:mm:ss').format('hh:mm A')}
                              text={`${customDate?.time} ` + (aptInfo.zonedTime ? `(${ aptInfo.zonedTime  })` : '')}
                              fontWeight={400}
                              color='primary'
                            />}
                          </IconRow>
                          <SpaceWrapper mb={2}></SpaceWrapper>
                        </InfoContainer>
                      </InfoColumn>
                    </Row>
                  </KeraltyLongCard>
                );
              })}
            </PaginateItems>
          )}
        </CardsContainer>
      </Row>
    </>
  );
};

export default PreviousAppointments;
