import nsLogin from './screens/login';
import nsComponents from './shared/components';
import nsContactConsent from './screens/contactConsent';
import nsConsentTreatment from './screens/consentTreatment';
import nsLocationSelect from './screens/locationSelect';
import nsRegister from './screens/register';
import nsRoutes from './shared/routes';
import nsErrors from './shared/errors';
import nsFinancialPolicy from './screens/financialPolicy';
import nsForgotPassword from './screens/forgotPassword';
import nsUnblockAccount from './screens/unblockAccount';
import nsFormLabel from './shared/formLabel';
import nsButton from './shared/button';
import nsPatientRegistration from './screens/patientRegistration';
import nsPharmacyInfo from './screens/pharmacyInfo';
import nsHipaaForm from './screens/hipaaForm';
import nsHome from './screens/home';
import nsImgNicons from './shared/imgNicons';
import nsSelect from './shared/select';
import nsMessages from './shared/message';
import nsMyHealth from './screens/myHealth';
import nsMentalHealth from './screens/mentalHealth';
import nsGetCare from './screens/getCare';
import nsMyAccount from './screens/myAccount';
import nsNotFound from './screens/notFound';
import nsMonthsCalendar from './shared/monthsCalendar';
import nsTooltip from './shared/tooltip';

const es = {
	...nsComponents.es,
	...nsLocationSelect.es,
	...nsLogin.es,
	register: { ...nsRegister.es },
	routes: { ...nsRoutes.es },
	errors: { ...nsErrors.es },
	financial: { ...nsFinancialPolicy.es },
	forgotPassword: { ...nsForgotPassword.es },
	unblockAccount: { ...nsUnblockAccount.es },
	formLabel: { ...nsFormLabel.es },
	getCare: { ...nsGetCare.es },
	myAccount: { ...nsMyAccount.es },
	button: { ...nsButton.es },
	select: { ...nsSelect.es },
	message: { ...nsMessages.es },
	myHealth: { ...nsMyHealth.es },
	mentalHealth: { ...nsMentalHealth.es },
	contactConsent: { ...nsContactConsent.es },
	patientRegistration: { ...nsPatientRegistration.es },
	pharmacyInfo: { ...nsPharmacyInfo.es },
	consentTreatment: { ...nsConsentTreatment.es },
	hipaaForm: { ...nsHipaaForm.es },
	home: { ...nsHome.es },
	imgNicons: { ...nsImgNicons.es },
	notFound: { ...nsNotFound.es },
	monthsCalendar: { ...nsMonthsCalendar.es },
	tooltip: { ...nsTooltip.es }
}

export default es;
