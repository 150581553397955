import React, { useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import isCordova from 'cordova';
import appConfig from 'config';
import { useLocation } from 'react-router-dom';
const { CAPTCHA_V3_KEY } = appConfig;

const removeRecaptchaItems = () => {
  document.querySelectorAll('script[src*=recaptcha]').forEach((script) => script.remove);
  document.querySelectorAll('.grecaptcha-badge').forEach((elem) => elem.parentElement?.remove());
};

const paths = ['/register/public/code-sent', '/register/public/one-more'];

const isSkipPath = (path) => {
  return paths.includes(path);
};

const CaptchaProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  const ref = useRef<string>();

  useEffect(() => {
    if (ref.current && ref.current !== i18n.language) removeRecaptchaItems();
    ref.current = i18n.language;
    return removeRecaptchaItems;
  }, [i18n.language]);

  const skip = useMemo(() => isSkipPath(pathname), [pathname]);

  useEffect(() => {
    if (skip) setTimeout(removeRecaptchaItems, 50);
    return;
  }, [skip]);

  return isCordova || skip ? (
    <>{children}</>
  ) : (
    <GoogleReCaptchaProvider
      language={i18n.language}
      reCaptchaKey={CAPTCHA_V3_KEY}
      scriptProps={{ async: true }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default CaptchaProvider;
