import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import i18n from 'i18next';
import { ReactComponent as Icon } from 'app/assets/images/assitance-externo.svg';
import { ReactComponent as IconBubble } from 'app/assets/images/icon-bubble.svg';
import { ReactComponent as IconX } from 'app/assets/images/assistance-externo-x.svg';
import { ReactComponent as CalendarIcon } from 'app/assets/icons/book-appointment.svg';
import { ReactComponent as TemperatureIcon } from 'app/assets/icons/termometer-icon.svg';
import { ReactComponent as ChatIcon } from 'app/assets/icons/chat-icon.svg';

import imageSupport from '../../../assets/images/chatrSupport.svg';
import openBurger from '../../../assets/images/openBurger.svg';
import closeBurger from '../../../assets/images/closeBurger.svg';
import appointImg from '../../../assets/images/appoinments.svg';
import checksImg from '../../../assets/images/ChatIcon_Symptoms.svg';

import { useAppSelector, useAppDispatch } from 'adapter/hooks';
import { utcTime } from 'app/ui-core/utils/times';

// Interaction Layer
import {
  openSymptomChecker,
  closeSymptomChecker,
  openBookAppointment,
  showChat,
} from 'adapter/chat/chatSlice';
import * as intercomInteractions from 'app/interactions/intercomChat';

import * as sanitasInteractions from 'app/interactions/sanitasChat';

import { useTranslation } from 'react-i18next';

import './styles.scss';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import useUnderMaintenance from 'app/hooks/useUnderMaintenance';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { KeraltyChat, KeraltyChatComponent } from '@quillbot/keralty-chat-lit';
import { BubbleButton } from 'app/components/organisms/login/loginLayout/bubbleButton/BubbleButton';
import {
  ContarinerFLoatingButton,
  ImageContainer,
} from 'app/components/organisms/login/loginLayout/login.styled';

export const BUTTON_SIZE = 56;
export const ITEM_SIZE = 64;
export const CONTAINER_SIZE = 150;
export const RADIUS = CONTAINER_SIZE / 1.4;

export const PRIMARY = '#2ea09b';
export const PRIMARY_2 = '#268e89';
export const BORDER = '#dadada';
export const TEXT = '#656565';

const FloatingMenu = ({ bottomPosition, openChat }) => {
  const { t } = useTranslation();

  const [triggerBurgerFloating, setTriggerBurgerFloating] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
  const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
  // const symptomCheckState = useAppSelector((state) => state.chat.symptomChecker);

  const { isUnderMaintenance } = useUnderMaintenance();

  // const { isOpen: chatIsOpen } = symptomCheckState;

  const language = (i18n.language || '').includes('es') ? 'es' : 'en';

  {
    /* const initSymptomChecker = async () => {
    await setIsOpen(false);
    await intercomInteractions.shutdownIntercomChat(dispatch);
    if (isVAOpen || isHLOpen) await dispatch(closeSymptomChecker());
    if (symptomCheckState.isInitialized && symptomCheckState.isMinimized) {
      await dispatch(showChat());
    } else {
      await dispatch(
        openSymptomChecker({
          isOpen: !chatIsOpen,
          isPublic: true,
          lng: language,
        })
      );
    }
  };*/
  }

  const initVim = async () => {
    await setIsOpen(false);
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.VIM, 'true');
    dispatch(openBookAppointment());
  };

  const openSanitasChat = async () => {
    await setIsOpen(false);
    await dispatch(closeSymptomChecker());

    /*   useRefChat.current.openChat(); */
    /*     await setIsOpen(false);
    await dispatch(closeSymptomChecker());
    await sanitasInteractions
      .setUpSanitasChat(dispatch, {
        actionColor: '#7cbc98',
        backgroundColor: '#7cbc98',
        name: '',
        email: '',
        state: '',
        currentTimeZone: `${utcTime()}`,
        authUid: '',
        sanitasAccountNumber: '',
        gender: '',
        birthday: '',
        customAttributes: { cType: 'support' },
      })
      .then(() => {
        sanitasInteractions.showSanitasChat(dispatch);
      }); */
  };

  const items = [
    /* {
      Icon: TemperatureIcon,
      alt: t('imgNicons.thermometerIcon'),
      value: 'Symptom Checker',
      label: t('routes.check'),
      callback: initSymptomChecker,
      hidden: false,
    },*/
    {
      Icon: CalendarIcon,
      alt: t('imgNicons.calendarIcon'),
      value: 'Appointments',
      label: t('routes.appointments'),
      callback: initVim,
      hidden: false,
    },
    {
      Icon: ChatIcon,
      alt: t('imgNicons.chatIcon'),
      value: 'chat',
      label: 'Chat',
      callback: openSanitasChat,
      hidden: false,
    },
    /*		{
      Icon: PersonsIcon,
      alt: t('imgNicons.personIcon'),
      value: 'Doctors',
      label: 'Doctors and locations',
      callback: alert,
      hidden: false,
    },

    {
      Icon: PersonsIcon,
      alt: t('imgNicons.personIcon'),
      value: 'Doctors',
      label: 'Doctors and locations',
      callback: alert,
      hidden: true,
    },
    */
  ];

  function getAlignItems(index, totalItems) {
    let coords = { x: 0, y: 0 };

    switch (true) {
      case totalItems === 2 && index === 0:
        coords = { x: -60, y: -70 };
        break;
      case totalItems === 2 && index === 1:
        coords = { x: -60, y: 70 };
        break;
      case totalItems === 3 && index === 0:
        coords = { x: 10, y: -100 };
        break;
      case totalItems === 3 && index === 1:
        coords = { x: -80, y: 0 };
        break;
      case totalItems === 3 && index === 2:
        coords = { x: 10, y: 100 };
        break;
      case totalItems === 4 && index === 0:
        coords = { x: 10, y: -100 };
        break;
      case totalItems === 4 && index === 1:
        coords = { x: -80, y: -40 };
        break;
      case totalItems === 4 && index === 2:
        coords = { x: -80, y: 40 };
        break;
      case totalItems === 4 && index === 3:
        coords = { x: 10, y: 100 };
        break;
    }

    return `translate(${coords.x}px, ${coords.y}px)`;
  }
  /*
  function getTransformText(progress, radius, index, totalItems) {
    let value

    switch (totalItems) {
      case 2:
        value = (index - 4.6 / (totalItems * 2.2)) * (progress / 1.15);
        break;
      case 3:
        value = (index - 6.4 / (totalItems * 2.2)) * (progress / 1.3);
        break;
      case 4:
        value = (index - 8 / (totalItems * 2.2)) * (progress / 1.16);
        break;
    }

    const x = radius * Math.sin(Math.PI * 2 * (value - 0.27));
    const y = radius * Math.cos(Math.PI * 2 * (value + 0.23));

    const scale = progress * 1;

    return `translate(${x * .8}px, ${y * 1.1}px) scale(${scale})`;
  }


  function getTransformText(progress, radius, index, totalItems) {
      const scale = progress * 1;

    const coords = {
      '1-0': { x: 50, y: 70 },
      '2-0': { x: 50, y: 70 },
      '2-1': { x: 30, y: 70 },
      '3-0': { x: 50, y: 70 },
      '3-1': { x: 100, y: 120 },
      '3-2': { x: 50, y: 70 },
      '4-0': { x: 0, y: -120 },
      '4-1': { x: -100, y: -50 },
      '4-2': { x: 10, y: 105 },
      '4-3': { x: -98, y: 40 },
      '5-0': { x: 20, y: -120 },
      '5-1': { x: -100, y: -40 },
      '5-2': { x: 40, y: 70 },
      '5-3': { x: 50, y: 70 },
      '5-4': { x: 50, y: 70 }
    }

      return `translate(${coords[`${ totalItems }-${ index }`][`x`]}px, ${coords[`${ totalItems }-${ index }`][`y`]}px) scale(${scale})`;
  }*/

  const isSafari =
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  const useRefChat: any = useRef(null);

  return (
    <>
      <ContarinerFLoatingButton stateBurger={triggerBurgerFloating}>
        <ImageContainer
          src={triggerBurgerFloating ? closeBurger : openBurger}
          onClick={() => setTriggerBurgerFloating(!triggerBurgerFloating)}
        />
        {triggerBurgerFloating && (
          <>
            <BubbleButton
              src={imageSupport}
              onClick={() => openChat?.()}
              text={t('routes.chatSupport')}
            />
            <div style={{ width: '70%', height: 1, background: 'gray', margin: '7px 0px' }} />
            <BubbleButton src={appointImg} onClick={initVim} text={t('routes.bookAppoiment')} />
            {
              //<BubbleButton src={checksImg} onClick={()=>console.log('')} text={t('routes.check')} />
            }
          </>
        )}
      </ContarinerFLoatingButton>

      {/*       <motion.div
        layout
        data-isopen={isOpen}
        className="parent"
        style={{
          borderRadius: 180,
          backgroundColor: isOpen ? 'white' : 'transparent',
          position: 'fixed',
          right: isOpen && !isSafari ? '-3%' : isOpen && isSafari ? '-1.5%' : '2%',
          bottom: isOpen ? '-2%' : bottomPosition ? bottomPosition : '2%',
        }}
      >
        {isOpen &&
          items.length > 0 &&
          items.map((item, index) => {
            return (
              <motion.div
                key={`floating-option-${index}`}
                style={{
                  display: 'inline-flex',
                  position: 'absolute',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 180,
                  height: 1,
                }}
                initial={{ x: 0, opacity: 0 }}
                animate={{ x: 1, opacity: 1 }}
                exit={{ x: 0, opacity: 0 }}
                transformTemplate={({ x }) => {
                  //@ts-ignore
                  return getAlignItems(index, items.length);
                }}
                transition={{
                  delay: index * 0.055,
                  type: 'spring',
                  stiffness: 600,
                  damping: 50,
                  mass: 1,
                }}
              >
                <div
                  id="label-container"
                  style={{
                    color: `${TEXT}`,
                    visibility: item.hidden ? 'hidden' : 'visible',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                    width: 100,
                    position: 'relative',
                  }}
                >
                  <p style={{ textAlign: 'right', maxWidth: 100 }}>{item.label}</p>
                </div>
                <div
                  id="icon-container"
                  style={{
                    width: ITEM_SIZE,
                    height: `${ITEM_SIZE}px`,
                    border: `1px solid ${BORDER}`,
                    color: `${TEXT}`,
                    display: 'inline-flex',
                    position: 'relative',
                    visibility: item.hidden ? 'hidden' : 'visible',
                  }}
                  onClick={() => {
                    if (isUnderMaintenance()) return;
                    item.callback();
                  }}
                >
                  {<item.Icon style={{ maxWidth: 25 }} title={item.alt} />}
                </div>
              </motion.div>
            );
          })}

        <motion.div
          layout
          onClick={() => {
            if (isUnderMaintenance()) return;
            setIsOpen(!isOpen);
          }}
          style={{
            position: 'relative',
            right: isOpen ? -66 : 3,
            width: 60,
            height: 60,
            cursor: 'pointer',
          }}
        >
          <Icon className="floatingMenuIcon" />
          {!isOpen ? (
            <IconBubble
              className="floatingMenuIconX"
              style={{ marginLeft: '5px', marginTop: '5px', width: '46px' }}
            />
          ) : (
            <IconX className="floatingMenuIconX" />
          )}
        </motion.div>
      </motion.div> */}
    </>
  );
};

export default FloatingMenu;
