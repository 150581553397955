import { useMemo, useState, useCallback } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// UI
import { Page, Container, Loader } from '../components/templates';
import { KeraltyStepper } from '../components/molecules';
import RenderStep from '../components/organisms/unblockAccount/RenderStep';
import LoginLayout from "../components/organisms/login/loginLayout/LoginLayout";
import CaptchaProvider from '../contexts/CaptchaProvider';
// INTERACTION
import { useStepper } from '../hooks/useStepper';
import { useAppSelector } from 'adapter/hooks';
import { selectUnblockLoading } from 'adapter/unblockAccount/unblockAccountSelectors';
import { DefaultContactInfo, DefaultNewPswInfo, DefaultResetPasswordInfo } from '../ui-core/schemas/unblockAccountInfo';
//ROUTES
import ROUTES from '../ui-core/utils/routes';
import useIsMobile from 'app/hooks/useIsMobile';


const { UNBLOCK: CURRENT, MAIN } = ROUTES;

const UnblockAccountFlow = ({ ...props }) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const isLoading = useAppSelector(selectUnblockLoading);
	const [states, setStates] = useState();
	const isMobile = useIsMobile();

	const steps = useMemo(() => [
		{
			label: t('forgotPassword.personalInfo'),
			route: CURRENT.recover,
			title: t('routes.unblockMyAccount')
		},
		{
			label: t('register.verification'),
			route: CURRENT.contact,
			title: t('routes.unblockMyAccount')
		},
		// { label: '', title: ' ', route: CURRENT.verify, hidden: true },
		{ title: t('unblockAccount2'), label: t('unblockAccount2'), route: CURRENT.verifyCode,  hidden: true },	
		{ label: '', title: ' ', route: CURRENT.verificationStatus, hidden: true },
		{
			label: t('forgotPassword.password'),
			//title: 'algo',
			route: CURRENT.newPass,
		},
		{ label: '', hidden: true, route: CURRENT.success }
	], [t]);

	const handleFinish = useCallback(() => push(`/${MAIN.login}`), [push]);

	const { currentStep, next, prev } = useStepper(0, steps, handleFinish);

	const [formValues, setFormValues] = useState({
		...DefaultResetPasswordInfo,
		...DefaultContactInfo,
		...DefaultNewPswInfo,
	});

	const title = useMemo(() => {
		const routesWithoutTitle: string[] = ['verify-option'];
		if (routesWithoutTitle.indexOf(steps[currentStep].route) >= 0) return '';

		return steps[currentStep]?.title || t('routes.unblockMyAccount');
	}, [t, currentStep, steps]);

	const methods = {};

	const handleUpdateForm = useCallback((value: any) => {
		setFormValues({ ...formValues, ...value });
	}, [formValues, setFormValues]);

	const routeNames = useMemo(() => title ? [
		{ label: t('routes.login'), callback: () => push('/login') },
		{ label: title || t('routes.unblockMyAccount') }
	] : [], [t, title, push]);
	//#endregion

	const [hiddenHeader, setHiddenHeader] = useState(false)
	console.log('title--->>', title,'routeNames--->>', routeNames,'steps[currentStep]--->>', steps[currentStep].route)
	//console.log('steps--->>>', steps[currentStep].route !,CURRENT.recover, prev())
	return (
		<CaptchaProvider>
			{isLoading && <Loader />}
			<LoginLayout hideStores>
				<Page
					title={(hiddenHeader || isMobile ) ? '' :steps[currentStep].route=='new-password-unblock'? t('routes.changeYour'):title }
					routes={routeNames}
					//hideMobileTitle
					onPrevious={ () => prev()}
					previousLabel={t('routes.previous')} >
					<Container centered={'xy'} pt noPadding>
						<KeraltyStepper hidden={hiddenHeader} steps={steps} activeStep={currentStep==0 ? 0 : currentStep==1 ? 1 : currentStep==2 ? 1 : currentStep==3 ? 1 : currentStep==4 ? 6 : 2} noPadding>
							<RenderStep
								states={states}
								setStates={setStates}
								prev={prev}
								next={next}
								methods={methods}
								currentStep={currentStep}
								updateForm={handleUpdateForm}
								formValues={formValues}
								setHiddenHeader={setHiddenHeader}
								{...props} />
						</KeraltyStepper>
					</Container>
				</Page>
			</LoginLayout>
		</CaptchaProvider>
	);
};

export default UnblockAccountFlow;
