import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

// Domain
import ForgotPassword from 'domain/useCases/forgotPassword';
import { UserIdentifier } from 'domain/entities/userIdentifier';
import { EmailIdentifier } from 'domain/entities/emailIdentifier';
import { ResetPassword } from 'domain/entities/resetPassword';

// Services
import forgotService from 'infrastructure/keraltyApi/repositories/forgotPassword';
import { RootState } from 'adapter/store';

// @TODO: This should be a common type across the entire adapter => Jesus +1
interface InitialState<DataEntityType = any> {
	data: DataEntityType | null;
	isLoading: boolean;
	error: SerializedError | null;
}

const INITIAL_STATE: InitialState<string> = {
	data: null,
	isLoading: false,
	error: null
}

export const tryValidateUserInfo = createAsyncThunk(
	'forgotpass/validateAccount/byState',
	async (data: UserIdentifier) => {
		return await ForgotPassword.validateUserInfo(forgotService, data);
	}
);

export const tryValidateStateInfo = createAsyncThunk(
	'forgotpass/validateAccount/byState',
	async (data: UserIdentifier) => {
		return await ForgotPassword.validateStateInfo(forgotService, data);
	}
);

export const trySendPassRecoveryEmail = createAsyncThunk(
	'forgotPass/sendPassRecoveryEmail',
	async (notificationPreference: EmailIdentifier, { getState }) => {
		const { forgotPassword: { data } } = getState() as RootState;
		return await ForgotPassword.sendRecoverPassEmail(forgotService, { ...notificationPreference, id: data });
	}
);

export const tryChangePassword = createAsyncThunk(
	'forgotPass/changePassword',
	async (newPass: ResetPassword) => {
		return await ForgotPassword.changePassword(forgotService, newPass);
	}
);

export const ForgotPassSlice = createSlice({
	name: 'forgotPass',
	initialState: INITIAL_STATE,
	reducers: {
		cleanError: (state) => {
			state.error = null;
		}
	},
	extraReducers: (builder) => {
		builder
			// tryValidateUserInfo
			.addCase(tryValidateUserInfo.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(tryValidateUserInfo.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(tryValidateUserInfo.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})

			// trySendPassRecoveryEmail
			.addCase(trySendPassRecoveryEmail.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(trySendPassRecoveryEmail.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(trySendPassRecoveryEmail.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})

			// tryChangePassword
			.addCase(tryChangePassword.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(tryChangePassword.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(tryChangePassword.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
	},
});

export const { cleanError } = ForgotPassSlice.actions;
export default ForgotPassSlice.reducer;
