import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../components/templates';
import { KeraltyButton, KeraltyText, Image } from '../../components/atoms';
import { SectionWidget } from 'app/components/molecules';
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../assets/icons/arrow-alt-circle-left.svg';
import { useAppSelector } from 'adapter/hooks';
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import useIsMobile from 'app/hooks/useIsMobile';
import { selectPaymentLoading } from 'adapter/payment/paymentSelectors';
import Loader from '../../components/templates/loader';
import helatLibraryIcon from '../../assets/icons/healtLibrary-icon.svg';
import stethoscope from '../../assets/icons/stethoscope.svg';
import useLibrary from 'app/hooks/useLibrary';


// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3, height: 3 };
const sizesLaptopMedicalIcon: IconSizes = { width: 3, height: 3 };

interface IconSizes {
	width: number;
	height: number;
}

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 10px;
	}
`;


const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
	}
`;

const LeftColumn = styled(Column)`
	justify-content: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const RightColumn = styled(Column)`
	justify-content: center;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 12.75rem;
  height: auto;
	width: 22vw;
	margin: 1.5rem 1.455rem;
	border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;

	h2 {
		margin: 0rem;
		font-size: 1.34rem;
		line-height: 1.34rem;
	}
	p {
		margin: 0rem;
		font-size: 1rem;
		line-height: 1.17rem;
		color:gray;
		text-transform: none;
		font-weight: 400;

	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const Wellness = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const isMobile = useIsMobile();
	const isLoading = useAppSelector(selectPaymentLoading);

	const {  triggerHealtLibrary } = useLibrary();

	return (
		<>
	
			{isLoading && <Loader />}

			<RightContent fullHeight style={!isMobile ? {marginTop: '0%', height: '100%', background: isMobile ? '#f5f5f5' : '#ffffff'} : {marginTop: '2%', height: '100%', background: isMobile ? '#f5f5f5' : '#ffffff'}}>
				<Row></Row>
				<div style={{marginLeft: '1rem'}}>
					<IconLinkRow
						onClick={goBack}
						color="accent"
						icon={arrowAltCircleLeftIcon}
						iconAlt={t('imgNicons.backArrow')}
						text={t('routes.back')}
					/>
				</div>

				<Row>
					<TitleContiner lg={12}>
						<KeraltyText align={isMobile ? 'left' : 'center'} type="title">
							{t('routes.wellness')}
						</KeraltyText>
					</TitleContiner>
				</Row>
				<Row style={{ padding: isMobile ? '0px' : '0px 1rem' }}>
					{/*<Column lg={0} md={0} sm={12} style={{ display: 'flex', justifyContent: 'center', height: '11.5rem' }}>
						<CenteredWrapper>
							<Card onClick={() => checkYourSymptoms()}>
								<SectionWidget
									img={stethoscope}
									text={t('routes.check')}
									description={t('routes.checkDescription')}
									alt={t('imgNicons.thermometerIcon')}
									style={{ fontWeight: '600', fontSize: '12px', color: '#5B5C5B'  }}
								/>
							</Card>
						</CenteredWrapper>
					</Column>*/}

					{/*<LeftColumn lg={6} md={6} sm={0}>
						<GetCareNowButton color="light" squareRadius={true} onClick={() => checkYourSymptoms()}>
							<span>
								<Image
									src={stethoscope}
									width={sizesUserChatIcon.width}
									height={sizesUserChatIcon.height}
									alt={t('imgNicons.thermometerIcon')}
								/>
								<KeraltyText
									type="title"
									fontWeight={'400'}
									style={{ paddingBottom: 10, fontWeight: 'normal' }}
									noTextTransform
								>
									{t('routes.check')}
								</KeraltyText>
								<KeraltyText type="paragraph" style={{ fontWeight: '600', fontSize: '12px', color: '#5B5C5B'  }}>{t('routes.checkDescription')}</KeraltyText>
							</span>
						</GetCareNowButton>
					</LeftColumn>*/}


					<Column lg={0} md={0} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
						<CenteredWrapper>
							<Card onClick={() => triggerHealtLibrary()}>
								<SectionWidget
									img={helatLibraryIcon}
									text={t('routes.healtLibrary')}
									description={capitalizeJustFirst(t('routes.healtLibraryDescription2'))}
									alt={t('imgNicons.healtLibraryIcon')}
									style={{ fontWeight: '600', fontSize: '12px', color: '#5B5C5B'  }}
								/>
							</Card>
						</CenteredWrapper>
					</Column>
					<RightColumn lg={12} md={12} sm={0} noPadding>
						
						<span onClick={() => triggerHealtLibrary()}>
							<GetCareNowButton color="light" squareRadius={true}>
								<span>
									<Image
										src={helatLibraryIcon}
										width={sizesLaptopMedicalIcon.width}
										height={sizesLaptopMedicalIcon.height}
									/>
									<KeraltyText
										type="title"
										fontWeight={'400'}
										style={{ paddingBottom: 10, fontWeight: '400' }}
										noTextTransform
									>
										{t('routes.healtLibrary')}
									</KeraltyText>
									<KeraltyText type="paragraph" style={{ fontWeight: '600', fontSize: '12px', color: '#5B5C5B' }}>{t('routes.healtLibraryDescription2')}</KeraltyText>
								</span>
							</GetCareNowButton>
						</span>
						{/* Time zone card */}
					</RightColumn>
				</Row>

			</RightContent>
		</>
	);
};

export default Wellness;