import styled from '../../ui-core/styled-components';
import Container from '../templates/container';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import { useMediaQuery } from '@mui/material';

interface KeraltyStepperProps {
	className?: string;
	steps: { label: string; route?: string; display?: number; hidden?: boolean; }[];
	children: any | any[];
	activeStep: number;
	noPadding?: boolean;
	currentRoute?: boolean;
	hidden?: boolean;
}




const StepperStructure = ({
  className,
  steps,
  currentRoute,
  children,
  activeStep,
  noPadding,
  hidden = false,
}: KeraltyStepperProps) => {

	const isScreenMobile = useMediaQuery('(max-width:380px)');

	return (
    <div className={className}>
      {!hidden && !steps[activeStep]?.hidden && (
        <Container centered={isScreenMobile ? '' : 'x'} spaceAround={isScreenMobile ? 'x' : ''}noPadding={noPadding}>
          {steps
            .filter((x) => !x.hidden)
            .map((step, i) => (
              <div
                style={i == 4 ? { display: 'none' } : undefined}
                className={`step ${activeStep >= i + (currentRoute ? 1 : 0) ? 'active' : ''}`}
                key={`step-${i}`}
              >
                <hr
                   style={{
					display: i === 3 ? 'none' : undefined,
					right: isScreenMobile ? '-57%' : '',
				  }}
                  className={`${activeStep > i + (currentRoute ? 1 : 0) ? 'active-hr' : ''}`}
                />
                <Container centered={'x'} noPadding>
                  <span>{`${step.display || i + 1}`}</span>
                </Container>
                <Container centered={'x'} noPadding>
                  <label>{step.label}</label>
                </Container>
              </div>
            ))}
        </Container>
      )}
      <Container className={'step-view'} centered={'x'} noPadding>
        {children}
      </Container>
    </div>
  );
};

const KeraltyStepper = styled(StepperStructure) <KeraltyStepperProps>`
	width: 100%;
	padding-top: 2rem;
	height: calc(100% - 4rem);
	position: relative;
	.step {
		position: relative;
		width: ${({ steps }) => 100 / steps.filter(x => !x.hidden).length}%;
		max-width: 12rem;
		line-height: 1.2em;
		height: 7.92rem;
		span {
			height: 2.3rem;
			width: 2.3rem;
			border-radius: 2.3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.5rem;
			font-weight: bold;
			${({ theme: { colors } }) => `
				color: ${colors.dark};
				background-color: ${colors.gray};
			`}
			@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
				font-size: 1.8rem;
			}
		}
		hr {
			position: absolute;
			width: 100%;
			background-color: ${({ theme: { colors } }) => colors.gray};
			height: 2px;
			border: none;
			right: -50%;
			// z-index: -1;
			top: 1.15rem;
			margin: 0px;
		}
		.active-hr {
			${({ theme: { colors } }) => `
				background-color: ${colors.accent};
		`}
		}
		label {
			margin-top: 1rem;
			font-size: 1.2rem;
			font-weight: bold;
			color: ${({ theme: { colors } }) => colors.gray};
			max-width: 10.5rem;
    	text-align: center;
		}
		&.active {
			${({ theme: { colors } }) => `
				span {
					color: ${colors.light};
					background-color: ${colors.accent};
				}
				label {
					color: ${colors.dark};
				}
			`}
		}
		&:last-child {
			hr { display: none; }
		}
	}
	.step-view {
		${({ steps, activeStep }) => !steps[activeStep]?.hidden ?
		'min-height: calc(100% - 7.92rem);'
		: ''}
		display: flex;
		align-items: inline-block;
	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		.step {
			height: auto;
			padding-bottom: 1rem;
		}
	}
`;

export default KeraltyStepper;
