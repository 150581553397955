import { ImageWidget, SectionWidget } from './styles';
import KeraltyText from '../keraltyText';
import useIsMobile from 'app/hooks/useIsMobile';

const HomeSectionWidget = (props) => {
  const isMobile = useIsMobile();
  return (
     <SectionWidget
       style={{ paddingTop: 7, whiteSpace: window.innerWidth >= 600 ? 'pre-line' : 'normal' }}
     >
       <ImageWidget src={props.img} alt={props.alt} />
       <KeraltyText type="info" color="primary" style={{ textAlign: 'center', fontWeight: isMobile ? '600' : '400' }} adaptable={false}>
         {props.text}
       </KeraltyText>
       {props.description && (
         <KeraltyText
           type="info"
           color="darkGray"
           style={{
             textAlign: 'center',
             lineHeight: 1.3,
             marginTop: 5,
             height: isMobile ? '32px' : 'auto',
             color: '#747474',
             fontSize: 12,
             fontWeight: 600
           }}
           bold
           adaptable={false}
         >
           {props.description}
         </KeraltyText>
       )}
     </SectionWidget>
  );
};
export default HomeSectionWidget;
