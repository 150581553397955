import * as Yup from 'yup';
import { UserIdentifier, ValidateForgotPasswordInfo } from 'domain/entities/userIdentifier';
import { REGEX } from '../utils/regex';

export type ResetPasswordInfoType = UserIdentifier;
export interface ContactInfoType {
	isPhoneSelected: boolean | undefined;
	isEmailSelected: boolean | undefined;
};

export interface NewPswInfoType {
	password: string;
	confirmPassword: string;
};

export const ResetPasswordInfoSchema: Yup.SchemaOf<ValidateForgotPasswordInfo> = Yup.object().shape({
	mobile: Yup.string()
		.required('required')
		.matches(REGEX.phone, 'invalidPhone'),
	dateOfBirth: Yup.date()
		.nullable()
		.transform((curr, orig) => (orig === '' || orig.length < 10 ? null : curr))
		.required('required')
		.max(new Date(), 'invalidBirthdate')
		.typeError('invalidBirthdate'),
	email: Yup.string()
		.required('required')
		.matches(REGEX.email, 'invalidEmail')
		.min(5, 'min')
		.max(255, 'max'),
	state: Yup.string()
		.notRequired()
		.max(50, 'max'),
});

export const ContactInfoSchema: Yup.SchemaOf<ContactInfoType> = Yup.object().shape({


	isPhoneSelected: Yup.boolean()
		.notRequired(),
		

	isEmailSelected: Yup.boolean()
	.notRequired(),
});

export const NewPswInfoSchema: Yup.SchemaOf<NewPswInfoType> = Yup.object().shape({
	password: Yup.string()
		.required('required')
		.min(8, 'min')
		.max(50, 'max')
		.matches(REGEX.mediumPassword, 'invalidPassword')
		.test('match', 'invalidPassword', function (pass = '', ctx) {
			const v = pass.toLowerCase();
			const fn = ctx.parent?.firstName?.toLowerCase();
			const ln = ctx.parent?.lastName?.toLowerCase();

			return (
				(!fn || v.indexOf(fn) < 0) &&
				(!ln || v.indexOf(ln) < 0) &&
				!REGEX.repeatedNumbers.test(v) &&
				!REGEX.consecutiveNumbers.test(v)
			);
		}),
	confirmPassword: Yup.string()
		.required('required')
		.oneOf([Yup.ref('password')], 'passwordMatch'),
});

export const DefaultResetPasswordInfo: Partial<ValidateForgotPasswordInfo> = {
	mobile: '',
	dateOfBirth: '',
	email: '',
};

export const DefaultContactInfo: ContactInfoType = {
	isPhoneSelected: false,
	isEmailSelected: false
};

export const DefaultNewPswInfo: NewPswInfoType = {
	password: '',
	confirmPassword: ''
};
