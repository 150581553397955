import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { useMemo } from 'react';

import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme'

import { KeraltyText } from '../atoms';

interface PaginateBarProps {
  currentPage: number;
  itemsPerPage: number;
  lastPage: number;
  select: (page: any) => void;
  totalItems: number;
}

const { lightAccent, dark, light } = theme.colors

const StyleTextContent = styled(KeraltyText)`
  color: ${dark};
  padding: 1rem 1rem;
  border-radius: 50%;
  margin: 1rem;
  &:hover {
    background-color: ${lightAccent};
    color: ${light};
  }
  &.pageActive {
    background-color: ${lightAccent};
    color: ${light};
  }
  @media (max-width: ${BREAKPOINTS.minTabletWidth}) {
    margin: 0.5rem;
  }
`;

const StyleText = styled(KeraltyText)`
  width: 1rem;
  height: 1rem;
  display: block;
  text-align: center;
`;
const StyleTextArrow = styled(KeraltyText)`
  color: ${lightAccent};
`;

const PaginateBar = ({
  itemsPerPage,
  totalItems,
  select,
  currentPage,
  lastPage,
}: PaginateBarProps) => {
  const pageNumber = useMemo(() => {
    let pageToList: Array<any> = [];
    const pages = Math.ceil(totalItems / itemsPerPage);

    if (pages <= 6) {
      pageToList = Array.from({ length: pages }, (_v, i) => i + 1);
    } else {
      pageToList = Array.from({ length: 5 }, (_v, i) => i + 1);
      pageToList.push('...');
      pageToList.push(pages);

      for (let n = 2; !pageToList.includes(currentPage); n++) {
        pageToList.length = 0;
        pageToList = Array.from({ length: 5 }, (_v, i) => i + n);
        pageToList.push('...');
        pageToList.push(pages);
      }
    }

    return pageToList;
  }, [currentPage, itemsPerPage, totalItems]);

  return (
    <>
      <StyleTextArrow
        bold
        type="subTitle"
        onClick={() => {
          currentPage !== 1 && select(currentPage - 1);
        }}
      >◀︎</StyleTextArrow>
      {pageNumber.map((page, i) => (
        <StyleTextContent className={`${currentPage === page ? 'pageActive' : ''}`}
        key={'pn-' + i}
        onClick={() => {
          page !== '...' && select(page);
        }}>
          <StyleText bold >
            {page}
          </StyleText>
        </StyleTextContent>
      ))}
      <StyleTextArrow
        bold
        onClick={() => {
          currentPage !== lastPage && select(currentPage + 1);
        }}
        type="subTitle"
      >▶︎</StyleTextArrow>
    </>
  );
};

export default PaginateBar;