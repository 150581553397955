import { KeraltyIcon, KeraltyText } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../../../ui-core/utils/routes';
import useIsMobile from 'app/hooks/useIsMobile';

const KeraltyTextButtom = styled(KeraltyText)`
  background: #3ca70d !important;
  line-height: 14px;
  border-radius: 25px;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
    padding-inline: 0px;
    font-size:14px;
    
  }
`;

const ModalTransactionCanceled = () => {

  const { t } = useTranslation();
  const { push } = useHistory();
  const { MAIN } = ROUTES;
  const isMobile = useIsMobile();

  return (
    <KeraltyModal size={ isMobile ? 'media' : 'almostMedia'} textalign="center" >

      <KeraltyIcon style={{ marginTop: '15px'}} iconClass="fas fa-check" size="common" color="accent" title={t('imgNicons.paperPlaneIcon')} rounded />

      <Row noMargin style={{ paddingTop: '25px', paddingBottom: '5px' }}>
        <KeraltyText type="info" color={'primary'} style={{ fontWeight: 'bold', fontSize: 18 }}>{t('getCare.televisit.transactionCanceled')} </KeraltyText>
      </Row>


      <div style={{ display: 'grid', placeItems: 'center', width: '100%', paddingBottom: '25px' }}>
        <KeraltyTextButtom onClick={ () => push(`/${MAIN.getCare}`) } type="label" undecorated style={{ width: '70%' }}>{t('getCare.televisit.accept')}</KeraltyTextButtom>
      </div>


    </KeraltyModal>

  );
}

export default ModalTransactionCanceled;