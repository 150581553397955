const nsComponents = {
    en:{
        TandC: {
            text1:`By accessing to our services you agree to Sanitas USA `,
            text2: "Privacy policy - Terms of use - Third party notices"
        },
        LoginLayout:{
            title:"Online and on the go, <br/> Sanitas is with you always",
            icons: {
                schedule: "Schedule an appointment",
                chatDoctor: "Connect with your doctor",
                monitor:"Monitor your health",
                testsResults: "Review tests results",
                healthRecord:"Access your health records",
                support: "Get services and support",
            }
        },
        symptomChecker: {
            title: "Assess symptoms"
        },
        virtualAssistant: {
            title: "Virtual assistant"
        },
        healtlibrary: {
            title: "Health Library"
        },
        StrengthValidator: {
            weak: 'Low',
            medium: 'Medium',
            strong: 'Strong'
        },
        KeraltyFilter: {
            title: 'Filter'
        },
        WellnessLibrary: {
            title: 'Wellness Library'
        }
    },
    es: {
        TandC: {
            text1:`Accediendo a nuestros servicios autoriza a Sanitas USA`,
            text2: "Políticas de privacidad - Términos de uso - Avisos de terceros"
        },
        LoginLayout:{
            title: "En línea y listo, <br/> Sanitas está siempre contigo",
            icons: {
                schedule: "Agenda una cita",
                chatDoctor: "Conéctate con tu doctor",
                monitor:"Monitorea tu salud",
                testsResults: "Revisa resultados de exámenes",
                healthRecord:"Accede a tus registros de salud",
                support: "Obtén servicios y soporte",
            }
        },
        symptomChecker: {
            title: "Revisa tus síntomas"
        },
        virtualAssistant: {
            title: "Asistente virtual"
        },
        healtlibrary: {
            title: "Biblioteca de salud"
        },
        StrengthValidator: {
            weak: 'Débil',
            medium: 'Medio',
            strong: 'Fuerte'
        },
        KeraltyFilter: {
            title: 'Filtro'
        },
        WellnessLibrary: {
            title: 'Enciclopedia del bienestar'
        }
    }
}; export default nsComponents;