import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';

import useAuth from '../../../hooks/useAuth';
import i18n from 'i18next';
// UI
import { KeraltyField, SeeIconCell, KeraltyFilterDate } from '../../molecules';
import TabWrapper from './TabWrapper';
import styled from '../../../ui-core/styled-components';
import { Row, Container, Column } from '../../templates';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import Img from '../../../assets/images/Group.svg';

import FilterImg from '../../../assets/images/filter.png';
import FilterEs from '../../../assets/images/filter-es.png';
import VirusImg from '../../../assets/images/shield-virus.svg';
import { KeraltyText, KeraltyIcon } from '../../atoms';
import PaginateBar from '../../atoms/paginateBar';
import moment from 'moment';
//Actions
import { getImmunizationData, cleanError } from 'adapter/myHealth/myHealthSlice';
import { selectmyHealthError, selectmyHealthImmunizations } from 'adapter/myHealth/myHealthSelectors';
//Adapter
import useIsMobile from '../../../hooks/useIsMobile';
import { Immunization } from '../../../../domain/entities/immunization';
import NoSearchResult from 'app/components/molecules/noSearchResult';

import NoResult from '../../../assets/icons/noResult.svg';
import IconImmunization from '../../../assets/icons/IconImmunization.svg';
import EmptyImmunizations from '../../../assets/icons/EmptyImmunizations.svg';
import FORMATS from 'app/ui-core/utils/formats';
import { basicScrollIntoTop } from 'app/ui-core/utils/screen';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';

type Props = {
	currentData: Immunization[];
	onClickItem: (idx?: number, cellText?: string) => void;
};

const MainContent = styled(Row)`
  background: #FFFFFF;
	width: 90%;
	height: 110px;
	box-shadow: 0px 3px 4px rgba(1, 42, 88, 0.25);
	border-radius: 14px;
	justify-content: flex-start;
  padding: 10px;
	align-content: center;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 120%;
	box-shadow: 0px 3px 4px rgba(1, 42, 88, 0.25);
		height: 110px;
		flex-wrap: nowrap;
  }
`;

const IconLab = styled(Row)`
	color: #055293;
  	width: 15px !important;
  	height: 15px !important;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 10px !important;
  	height: 10px !important;
	}
`;
const Search = styled(KeraltyField)`
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width:100%;
}
`;
const SearchWeb = styled(KeraltyField)`

	height: 90px;

`;
const styleText = {
	textAlign: "center",
	marginRight: 7,
	marginTop: 15,
	color: "#5b5c5b",
	fontWeight: 400,
}
const styleDiv = {
	marginTop: 0,
	paddingTop: 0,
	paddingBottom: 10,
	marginLeft: 0,
	marginRight: 0,
	flex: 1,
	align: "center",
	justifyContent: 'center',
	alignSelf: 'center',
}
const styleButton = {
	color: '#3CA70D',
	marginRight: 60,
	marginLeft: 52,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: '#FFFFFF',
	borderRadius: 40,
	border: 'none',
	borderColor: "#FFFFFF",
	fontSize: 16,
	fontWeight: 'bold',
}
const ContentDiv = styled.div`
	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
		background: #f5f5f5;
	};
`;

const DivContent = styled(Column)`
	width:100%;

	@media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
		max-width: ${BREAKPOINTS.maxTabletWidth};
		margin-left: 4em;
	}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		max-width: ${BREAKPOINTS.maxPhoneWidth};
		margin-left: 1.2em;
	}
`;

const styleNoResultMobile = {
	// marginTop: '40%',
}

const styleNoResult = {
	backgroundColor: '#fff'
}

const ImmunizationTab: React.FC<Props> = ({ currentData, onClickItem }) => {
	const { user } = useAuth();
	const { t } = useTranslation();
	const isMobile = useIsMobile();
	const [search, setSearch] = useState("");
	const immunizations = useAppSelector(selectmyHealthImmunizations);
	//const asyncError = useAppSelector(selectmyHealthError);
	const dispatch = useAppDispatch();
	const [isPwdVisible, setIsPwdVisible] = useState(false);
	const [page, setPage] = useState(false);
	const [filter, setFilter] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});
	const [visible, setVisible] = useState(false);
	const [cleanInput, setCleanInput] = useState(false);
	const [activeRow, setActiveRow] = useState<number | undefined>();
	const [openPopUp, setOpenPopUp] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [isFiltered, setIsFiltered] = useState(false);
	const [closeModalError, setCloseModalError] = useState(false);
	const error = useAppSelector(selectmyHealthError);
	useEffect(() => {
		if (error) setCloseModalError(error)
	}, [error])

	//console.log(currentData)
	useEffect(() => {
		dispatch(getImmunizationData(user.idEcw));

		return () => dispatch(cleanError());
	}, [dispatch, user.idEcw])


	// const resetActiveRow = useCallback(() => {
	// 	setActiveRow(undefined);
	// }, []);

	const data = useMemo(
		() =>
			currentData.map((currentItem, idx) => ({
				...currentItem,
				reason: <SeeIconCell idx={idx} cellText={currentItem.vaccineName} onClick={onClickItem} />,
				icon: <SeeIconCell idx={idx} cellText={''} hasIcon onClick={onClickItem} />,
			})),
		[currentData, onClickItem]
	);

	const pwdIcon = () => (
		<KeraltyIcon
			key='eye'
			title={t('imgNicons.eyeIcon')}
			iconClass={`fas	fa-backspace `}
			onClick={() => { setSearch("") }}
		/>
	);

	useMemo(() => {
		setFilter(!isMobile ? true : true)
	}, [isMobile])

	useMemo(() => {
		//method in charge of cleaning form
		if (filter) setDataObject({})
	}, [filter]);

	const onChangeDate = useCallback((type, data) => {
	//	console.log("Data=====>", data)
		setPage(false);
		type && setDataObject(data);
		setIsModalVisible(false);
		setCurrPage(1)
		// console.log("Data=====>", data)
		// setPage(false);
		// setDataObject(data);
		// setIsModalVisible(false);
	}, []);

	const dataToDisplay = useMemo(() => {
		//function in charge of filtering data by date range
		if (!dataObject.to || !dataObject.from) return data;
		const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
		const toDate = moment(dataObject.to, 'MM/DD/YYYY');
		setVisible(true)
		setIsFiltered(true)
		return data.filter((appointment) => {
			const currentDate = moment(appointment.givenDate, 'YYYY-MM-DD');
			return currentDate.isBetween(fromDate, toDate, undefined, '[]');
		});
	}, [dataObject, data]);

	const newData = useMemo(() => {
		const indexLastPage = currPage * 5;
		const indexFirstPage = indexLastPage - 5;

		return dataToDisplay.slice(indexFirstPage, indexLastPage)
	}, [currPage, dataToDisplay]);

	const newDataMovil = useMemo(() => {
		const indexLastPage = currPage * 5;
		const indexFirstPage = indexLastPage - 5;

		return dataToDisplay.slice(indexFirstPage, indexLastPage)
	}, [currPage, dataToDisplay]);

	const lastPage = Math.ceil(dataToDisplay.length / 5);

	const ClearDate = () => {
		var p1 = { from: "", to: "" };
		var Init = moment().format();
		//console.log('dsadsa' + Init)
		setDataObject(p1)
		setVisible(false)
		setIsFiltered(false)
		setFilter(!filter)
	}
	const MainFilter = () => {
		return (
			<div>
				{i18n.language == 'en' ?
					<img onClick={() => { setFilter(!filter); setIsModalVisible(filter); setCleanInput(!cleanInput) }} src={isMobile ? FilterImg : filter ? FilterImg : ''} alt="" style={{ cursor: 'pointer' }} />
					:
					<img onClick={() => { setFilter(!filter); setIsModalVisible(filter); setCleanInput(!cleanInput) }} src={isMobile ? FilterEs : filter ? FilterEs : ''} alt="" style={{ cursor: 'pointer' }} />
				}
			</div>
		);
	};

	const FilterVisible = () => {
		const format =(date)=>{return date?.split('/').reverse().join('/').slice(2).split('/').reverse().join('/')}

		return (
			<>
				<Row noMargin style={{ width: isMobile ? '85%' : '100%', justifyContent: 'space-between', marginLeft: isMobile ? '-7%' : '4%', display: dataObject.from ? 'flex' : 'none' }}>
					<ContentDiv onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
						<div style={{ background: '#3CA70D', paddingInline: 10, paddingBlock: 5, borderRadius: 20, paddingTop: 6 }} >

							<KeraltyText style={{ fontSize: '12px', padding: 5, color: '#FFFFFF' }}>{`${format(dataObject.from)} - ${format(dataObject.to)}`}</KeraltyText>
							<KeraltyText style={{ fontSize: '14px', padding: 5, color: '#FFFFFF' }}>   X</KeraltyText>
						</div>
					</ContentDiv>
					<KeraltyText style={{ width: 'auto',  marginRight: isMobile ? '-2%' : '3%',alignSelf: 'center', fontSize: '16px', color: '#999999' }} >{t('myHealth.show')} {dataToDisplay.length} {t('myHealth.resul')}</KeraltyText>
				</Row>
			</>
		)
	}

	const CardsLaboratories = () => {
		return (
			!isMobile ?
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					{dataToDisplay.map((dat) => (
						<MainContent>
							<img src={IconImmunization} alt="" style={{ marginLeft: '3%', width: 35, marginRight: '1.5%' }} />
							<div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '40%' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img src={VirusImg} alt="" style={{ marginRight: '10%' }} />
									<KeraltyText color={'#055293'} bold fontSize={'14px'} type={'info'} align={'center'} >{t('getCare.name')}:</KeraltyText>
								</div>
								<KeraltyText style={{ width: '60%' }} fontSize={'17px'} bold color={'#52575C'} type={'info'} align={'left'} >{dat.vaccineName}</KeraltyText>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
									<IconLab noMargin className={"fas fa-prescription-bottle-alt"} style={{ marginRight: '10px' }} />
									<KeraltyText fontSize={'14px'} style={{ marginRight: '10px' }} type={'info'} color={'#055293'} bold align={'center'} >{t('getCare.dosage')}:</KeraltyText>
									<KeraltyText fontSize={'14px'} color={'#52575C'} type={'info'} bold align={'center'} >{dat.dose} </KeraltyText>
								</div>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
									<IconLab noMargin className={"fas fa-calendar-day"} style={{ marginRight: '10px' }} />
									<KeraltyText fontSize={'14px'} style={{ marginRight: '-30px', marginLeft: '-30px' }} type={'info'} color={'#055293'} bold align={'center'} >{t('getCare.date')}:</KeraltyText>
									<KeraltyText fontSize={'14px'} width={'150px'} color={'#52575C'} type={'info'} bold align={'center'} >{moment(dat.givenDate).format(FORMATS.date)}</KeraltyText>
								</div>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
									<IconLab noMargin className={"fas fa-industry-alt"} style={{ marginRight: '10px' }} />
									<KeraltyText fontSize={'14px'} style={{ marginRight: '10px' }} type={'info'} color={'#055293'} bold align={'center'} >{t('myHealth.manufacturer')}:</KeraltyText>
									<KeraltyText fontSize={'14px'} color={'#52575C'} type={'info'} bold align={'center'} >{dat.manufacturer}</KeraltyText>
								</div>
							</div>
						</MainContent>
					))}
					{dataToDisplay.length >= 5 ?
						<Row>
							<Container centered="x" noPadding>
								<Container centered="y" noPadding>
									<PaginateBar
										currentPage={currPage}
										itemsPerPage={5}
										lastPage={lastPage}
										select={(page) => setCurrPage(page)}
										totalItems={page ? dataToDisplay.length : dataToDisplay.length}
									/>
								</Container>
							</Container>
						</Row>
						: <></>}
					<Row>
						<div style={{ marginBottom: dataToDisplay.length <= 2 ? 0 : 30, width: '70vw', display: 'flex', background: 'red' }}> </div>
					</Row>
				</div>
				:
				<div style={{ display: 'grid', alignItems: 'center', width: '73vw' }}>
					{dataToDisplay.map((dat) => (
						<MainContent>
							<div style={{ marginLeft: '5%', marginRight: '2%', marginTop: '5%' }}>
								<img src={Img} alt="" style={{}} />
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', padding: '10px', width: '70%' }}>
								<KeraltyText style={{ width: '100%', color: '#52575C' }} fontSize={'17px'} bold color={'#52575C'} type={'info'} align={'left'} >{dat.vaccineName}</KeraltyText>
								<Column noPadding style={{ marginBlock: 5 }}>
									<IconLab noMargin className={"fas fa-prescription-bottle-alt"} style={{ marginRight: '10px' }} />
									<Row noMargin style={{ width: '50%', textAlignLast: 'start' }}><KeraltyText fontSize={'14px'} style={{ marginRight: '10px' }} type={'info'} color={'#055293'} bold align={'center'} >{t('getCare.dosage')}:</KeraltyText></Row>
									<KeraltyText fontSize={'14px'} color={'#52575C'} type={'info'} bold align={'center'} >{dat.dose} </KeraltyText>
								</Column>
								<Column noPadding style={{ marginBlock: 5 }}>
									<IconLab noMargin className={"fas fa-calendar-day"} style={{ marginRight: '10px' }} />
									<Row noMargin style={{ width: '50%', textAlignLast: 'start' }}><KeraltyText fontSize={'14px'} style={{ marginRight: '10px' }} type={'info'} color={'#055293'} bold align={'center'} >{t('getCare.date')}:</KeraltyText></Row>
									<KeraltyText style={{ width: '125px', marginRight: '-200px' }} fontSize={'14px'} color={'#52575C'} type={'info'} bold align={'center'} >{moment(dat.givenDate).format(FORMATS.date)}</KeraltyText>
								</Column>
								<Column noPadding style={{ marginBlock: 5 }}>
									<IconLab noMargin className={"fas fa-industry-alt"} style={{ marginRight: '10px' }} />
									<Row noMargin style={{ width: '50%', textAlignLast: 'start' }}><KeraltyText fontSize={'14px'} style={{ marginRight: '10px' }} type={'info'} color={'#055293'} bold align={'center'} >{t('myHealth.manufacturer')}:</KeraltyText></Row>
									<KeraltyText fontSize={'14px'} color={'#52575C'} type={'info'} bold align={'center'} >{dat.manufacturer}</KeraltyText>
								</Column>
							</div>
						</MainContent>
					))}
					{data.length >= 5 ?
						<Row>
							<Container centered="x" noPadding>
								<Container centered="y" noPadding>
									<PaginateBar
										currentPage={currPage}
										itemsPerPage={5}
										lastPage={lastPage}
										select={(page) => setCurrPage(page)}
										totalItems={page ? data.length : data.length}
									/>
								</Container>
							</Container>
						</Row>
						: <></>}
					<Row>
						<div style={{ marginBottom: dataToDisplay.length <= 2 ? 0 : 30, width: '70vw', display: 'flex', background: 'red' }}> </div>
					</Row>
				</div>
		)
	}

	useEffect(() => {
		basicScrollIntoTop()
	}, [])


	return (
		<TabWrapper title={!isMobile ? t('myHealth.immunizations') : ''}>
		{closeModalError && <ModalErrorMyHealth setCloseModalError={()=>setCloseModalError(false)}/>}

			<div>
				<DivContent>
					<Row style={{ width: !isMobile ? '65vw' : '85vw', justifyContent: 'space-between' }}>
						<KeraltyText style={{ width: 'auto', margin: 0, fontSize: 18, marginLeft: 0 }} type={'title'} >{isMobile ? t('myHealth.immunizations') : ''}</KeraltyText>
						<div style={{ alignItems: 'center' }}>
							<MainFilter />
						</div>
					</Row>
				</DivContent>

				<div style={{
					zIndex: isMobile ? 4 : 1,
					display: filter ? 'none' : 'grid',
					marginInlineEnd: isMobile ? 'inherit' : 'auto',
					paddingInline: '2%'
				}}>
					<KeraltyFilterDate
						onSuccess={onChangeDate}
						onCancel={() => { setIsModalVisible(false); setFilter(true) }}
						showModalFlag={isModalVisible}
						cleanInput={cleanInput}
					></KeraltyFilterDate>
				</div>
				{visible ? <FilterVisible /> : ''}
				{!error?
					dataToDisplay?.length ?
						<CardsLaboratories /> :
						<>
							{
								!isFiltered ?
								<div style={ isMobile ? styleNoResultMobile : styleNoResult }>
									<NoSearchResult image={EmptyImmunizations} title={""} subTitle={t('myHealth.noImmunizations')} />
								</div>
							:
								<NoSearchResult image={NoResult} title={t('myHealth.titleEmpty')} subTitle={t('myHealth.textEmpty')}/>

							}
							<Row>
								<div style={{ marginBottom: 300, width: '40vw', display: 'flex', background: 'red' }}> </div>
							</Row>
						</>: <></>
				}
			</div>
		</TabWrapper>
	);
};
export default ImmunizationTab;
