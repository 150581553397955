const nsMyHealth = {
  en: {
    authorizationId: 'Authorization ID',
    clinicalCenter: 'Clinical center: ',
    date: 'Date',
    dose: 'Dose number',
    drug: 'Drug/Dose',
    frequency: 'Frequency',
    immunizations: 'My Immunizations',
    immunizationsEmpty: 'Your immunizations list is empty',
    instructions: 'Select a date range to start',
    labEmpty: 'Your laboratory list is empty',
    labNimgingResults: 'My Lab and imaging results',
    laboratory: 'Laboratory',
    manufacturer: 'Manufacturer',
    labResultsLong: 'My Laboratory and imaging results',
    medications: 'Medications',
    medicationsEmpty: 'Your medication list is empty',
    myHealth: 'Welcome',
    myHealthTitle: 'My Health',
    name: 'Name',
    notes: 'Notes: ',
    noResults: 'No items found',
    orderBy: 'Order by',
    orderDate: 'Order date: ',
    oldestFirst: 'Oldest first',
    newestFirst: 'Newest first',
    pdfButton: 'DOWNLOAD PDF',
    patientId: 'Patient ID: ',
    reason: 'Reason',
    reasonForReferrals: 'Reason for referral',
    receivedDate: 'Received date: ',
    referralDiagnosis: 'Referral Diagnosis',
    referrals: 'My Referrals',
    referralsEmpty: 'Your referrals list is empty',
    results: 'Results: ',
    resultsAccess:
      'Soon you will have access to your results here, to obtain them, please contact support chat.',
    route: 'Route',
    startDate: 'Start Date:',
    searchTitle: 'Search by Medication name',
    supportChat: 'Support chat',
    supportChatC: 'CHAT SUPPORT',
    validUntil: 'Valid Until:',
    viewResult: 'View result',
    visitSummary: 'My Visit Summary',
    visitSummaryEmpty: 'The visit summary list is empty',
    visitSummaryFrom: 'From',
    visitSummaryTo: 'To',
    visitSummaryLocation: 'Location:',
    visitSummaryHour: 'Hour',
    visitSummaryAddress: 'Address:',
    visitSummarySubmit: 'Submit',
    visitSummaryClose: 'Close',
    visitSummaryAttending: 'Attending:',
    visitSummaryReason: 'Reason:',
    visitSummaryType: 'Visit type:',
    visitSummartDate:'Date:',
    yes:'YES',
    no:'NO',
    currentMedication: 'My Current Medications',
    filter: 'Filter',
    search: 'Search...',
    titleSearch: 'Search by Medication name',
    titleEmpty: 'No results',
    textEmpty: "We couldn't find anything",
    textData: 'There are no records that meet the search criteria',
    medication: 'Medication: ',
    doses: 'Dose: ',
    direction: 'Directions: ',
    frequencyM: 'Frequency: ',
    dateinit: 'Start date: ',
    dateEnd: 'End date: ',
    prescribed: 'Prescribed by: ',
    title: 'My Health',
    current: 'My Current Medications',
    labText: 'Access to your labs and imaging results',
    currentText: 'Access to your list of current medications',
    referralsText: 'Check your health referrals',
    visitText: 'See your previous activity',
    InmunizationsText: 'Access to your vaccination records',
    show: 'Show ',
    resul: 'result',
    noResult: 'No results',
    noResultVisitSmmary: 'We couldn’t find anything',
    noVisitSmmary: 'Your visit summary list is empty',
    noLab: 'Your labs and imaging results list is empty',    
    noRef: 'Your referrals list is empty',
    noCurrentMedications: 'Your medications list is empty',
    noImmunizations: 'Your immunizations list is empty',

    noCurrentMedication: 'Your medications list is empty',
    noUpcoming: "Your upcoming  appointments list is empty",
    noPrevious: 'Your previous appointments list is empty',
    provider: 'Provider:',
    sufix: 'Sufix:',
    center: 'Medical Center:',
    issue: 'Issue Date:',
    authId: 'Authorization ID:',
    status: 'Status:',
    seeing: 'Who are you seeing:',
    speciality: 'Speciality:',
    address: 'Address:',
    phone: 'Phone Number:',
    seen: 'When are you being seen:',
    referralFrom: 'Referral from:',
    numberId: 'ID Number:',
    npi: 'NPI:',
    reasonRef: 'Reason for referral:',
    diag: 'Diagnosis:',
    details: 'SEE DETAILS',
    details2: 'DETAILS',
    pdf: 'Download PDF',
    pdf2: 'DOWNLOAD PDF',
    NN: "",
    open: "Referral placed. Pending referral approval.",
    pending: "Referral processed. Pending notes.",
    addressed: "No referral required and/or Progress Notes received.",
    info:'Only last year’s results will be displayed automatically. If you want to consult on previous dates, please use our filters.',
    saved:'The file will be saved in your device’s present folder',
    want:'Do you want to continue?',
    info2:'',

    textVisitSummary: 'Visit Summary',
    textDiagnostic: "Diagnostical imaging"
  },
  es: {
    NN: "",
    open: "Referencia creada. Pendiente por aprobación.",
    pending: "Referencia procesada. Notas pendientes.",
    addressed: "No se requiere referencia y/o Notas de Progreso recibidas.",
    authorizationId: 'ID autorización',
    clinicalCenter: 'Centro clínico: ',
    date: 'Fecha',
    dose: 'Número de dosis',
    drug: 'Droga/Dosis',
    frequency: 'Frequencia',
    immunizationsEmpty: 'La lista de inmunizaciones está vacía',
    immunizations: 'Mis Inmunizaciones',
    instructions: 'Selecciona un rango de fechas para empezar',
    labEmpty: 'La lista de laboratorios está vacía',
    labNimgingResults: 'Mis Laboratorios e imágenes diagnósticas',
    laboratory: 'Laboratorio',
    manufacturer: 'Manufacturador',
    labResultsLong: 'Mis Laboratorios e imágenes diagnósticas',
    medications: 'Medicamentos',
    medicationsEmpty: 'La lista de medicamentos está vacía',
    myHealth: 'Bienvenidos',
    myHealthTitle: 'Mi salud',
    name: 'Nombre',
    notes: 'Notas: ',
    noResults: 'No se encontraron resultados',
    orderBy: 'Ordenar por',
    orderDate: 'Fecha de orden: ',
    oldestFirst: 'Antiguos primero',
    newestFirst: 'Recientes primero',
    patientId: 'ID del paciente: ',
    pdfButton: 'DESCARGAR PDF',
    reason: 'Razón',
    reasonForReferrals: 'Motivo',
    receivedDate: 'Fecha de recibido: ',
    referralDiagnosis: 'Diagnóstico',
    referrals: 'Mis Referencias',
    referralsEmpty: 'La lista de referencias está vacía',
    results: 'Resultados: ',
    resultsAccess:
      'Pronto tendrás acceso a tus resultados aquí, para obtenerlos, por favor contacta a nuestro chat de soporte.',
    route: 'Vía',
    startDate: 'Fecha de inicio:',
    searchTitle: 'Buscar por nombre de medicamento',
    supportChat: 'Chat de soporte',
    supportChatC: 'CHAT DE SOPORTE',
    validUntil: 'Válido hasta:',
    viewResult: 'Ver resultado',
    visitSummary: 'Mi resumen de visitas',
    visitSummaryEmpty: 'El sumario de visitas está vacío',
    visitSummaryFrom: 'Desde',
    visitSummaryTo: 'Hasta',
    visitSummaryLocation: 'Ubicación:',
    visitSummaryHour: 'Hora',
    visitSummaryAddress: 'Dirección:',
    visitSummarySubmit: 'Enviar',
    visitSummaryClose: 'Cerrar',
    visitSummaryAttending: 'Atendiendo:',
    visitSummaryReason: 'Razón:',
    visitSummaryType: 'Tipo visita:',
    visitSummartDate:'Fecha:',
    yes:'SI',
    no:'NO',
    currentMedication: 'Mis Medicamentos Activos',
    filter: 'Filtro',
    search: 'Buscar...',
    titleSearch: 'Buscar por nombre de medicamento',
    titleEmpty: 'No hay resultados',
    textEmpty: 'No pudimos encontrar nada',
    textData: 'No hay ningún registro que cumpla con el criterio de búsqueda',
    medication: 'Medicamento: ',
    doses: 'Dosis: ',
    direction: 'Indicaciones: ',
    frequencyM: 'Frecuencia: ',
    dateinit: 'Fecha inicio: ',
    dateEnd: 'Fecha final: ',
    prescribed: 'Prescrito por: ',
    title: 'Mi salud',
    current: 'Mis Medicamentos activos',
    labText: 'Accede a tus exámenes diagnósticos',
    currentText: 'Accede a la lista de tus medicamentos activos',
    referralsText: 'Gestiona tus referencias médicas',
    visitText: 'Chequea tu actividad previa',
    InmunizationsText: 'Accede a tu historial del vacunas',
    show: 'Mostrar',
    resul: 'resultados',
    noResult: 'No hay resultados',
    noResultVisitSmmary: 'No pudimos encontrar nada ',
    noVisitSmmary: 'Tu lista de resumen de visitas está vacía.',
    noLab: 'Tu lista de laboratorios e imágenes \ndiagnósticas está vacía',
    noRef: 'Tu lista de referencias está vacía.',
    noCurrentMedications: 'Tu lista de medicamentos está vacía',
    noCurrentMedication: 'Tu lista de medicamentos está vacía',
    noImmunizations: 'Tu lista de inmunizaciones está vacía',
    noUpcoming: "Tu lista de próximas citas está vacía",
    noPrevious: 'Tu lista de citas previas está vacía.',
    provider: 'Proveedor:',
    sufix: 'Sufijo:',
    center: 'Centro Médico:',
    issue: 'Fecha de Expedición:',
    authId: 'No Autorización:',
    status: 'Estado:',
    seeing: 'A quien vas a ver:',
    speciality: 'Especialidad:',
    address: 'Dirección:',
    phone: 'Teléfono:',
    seen: 'Cuando te van a ver:',
    referralFrom: 'Quién te refiere:',
    numberId: 'No Identificación:',
    npi: 'NPI:',
    reasonRef: 'Razón de Referencia:',
    diag: 'Diagnostico:',
    details: 'DETALLES',
    details2: 'DETALLES',
    pdf: 'Descargar PDF',
    pdf2: 'DESCARGAR PDF',
    info:'Únicamente los resultados del último año se verán reflejados de manera automática. Si deseas consultar en fechas previas, por favor usa nuestros filtros por fecha.',
    saved:'Este archivo se guardará en la carpeta predetermidada de tu dispositivo',
   want:'¿Deseas continuar?',
   info2:'',

   textVisitSummary: 'Resumen de visita',
   textDiagnostic: "Imagen diagnóstica"
   
  },
};

export default nsMyHealth;
