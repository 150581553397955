import { useRef, useCallback, useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../ui-core/styled-components';
import { KeraltyText, KeraltyButton } from '../atoms';

interface KeraltyFileUploadeProps {
	className?: string;
	onChange?: (file: any) => void;
	value?: any;
	info?: string;
	sizeInfo?: string;
	placeholder?: string;
};

const FileUploader = ({ className, onChange, value, placeholder, info, sizeInfo }: KeraltyFileUploadeProps) => {
	const [fileName, setFileName] = useState('');
	const hiddenFileInput = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (value?.name && fileName !== value.name) setFileName(value.name)
	}, [value, fileName]);

	const handleClick = useCallback((e: any) => {
		e.preventDefault();
		hiddenFileInput?.current?.click();
	}, [hiddenFileInput]);

	const handleAttachFile = useCallback((file: any) => {
		if (!file) return;
		setFileName(file.name);
		if (onChange) onChange(file);
	}, [onChange]);

	return (
		<div className={className}>
			<input
				value={fileName}
				placeholder={placeholder}
				disabled />
			<KeraltyButton squareRadius onClick={handleClick}>
				{t('button.attach')}
			</KeraltyButton>
			{info &&
					<Fragment>
				<span className={'info'}>
					<KeraltyText color={'dark'}>{info}</KeraltyText>
				</span>
				<span className={'infoSize'}>
					<KeraltyText color={'dark'}>{sizeInfo}</KeraltyText>
					</span>
				</Fragment>
			}
			<input
				type="file"
				ref={hiddenFileInput}
				onChange={(e: any) => handleAttachFile(e.target.files[0])} />
		</div>
	);
};

const KeraltyFileUploader = styled(FileUploader)`
	display: inline-block;
	position: relative;
	width: 100%;
	* {
		margin: 0px;
	}
	button{
		position: absolute;
		right: 0;
		top: 0;
		height: 3.75rem;
	}
	.info {
		position: absolute;
		top: 4rem;
		left: .2rem;
	}
	.infoSize {
		position: relative;
		top: 0.5rem;
		left: .4rem;
	}
	input[type="file"] { display: none; }
	input::placeholder {
		font-size: 1.17rem !important;
	}
`;

export default KeraltyFileUploader;
