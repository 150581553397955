import isCordova from "cordova";


const initialState = {
	authUid: undefined,
	displayName: '',
	id: null,
	idEcw: '',
	role: '',
	address1: '',
	address2: '',
	city: '',
	birthdate: '',
	email: '',
	externalPartyId: '',
	firstName: '',
	mobile: '',
	homePhone: '',
	lastName: '',
	sex: '',
	ssn: '',
	state: '',
	zipCode: '',
	status: 'idle',
	isLoggedIn: false,
	//   hasError: false,
	//   success: false,
	//   errorMsg: '',
	locationSelected: isCordova ? { value: 'Florida', label: 'Florida' } : undefined,
	lng: 'en',
	registrationId: undefined,
	token: undefined,
	isLoading: false,
	// New Error Serialized
	error: undefined,
	// AVAILITY
	activeCoverage: false,
	isActivedChat: false
};

export default initialState;
