import { useAppDispatch } from "adapter/hooks";
import { registerActions } from "adapter/register/registerSlice";
import { userActions } from "adapter/user/userSlice";

const useGeneralErrors = () => {

	const dispatch = useAppDispatch();

	enum Errors {
		CHAT_IN_PROGRESS = "CHAT_IN_PROGRESS",
	};

	const setGeneralError = (value: Errors) => {
		dispatch(userActions.setGeneralError({ error: value }));
	}

	return {
		Errors,
		setGeneralError
	}
};

export default useGeneralErrors
