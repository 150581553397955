import { yupResolver } from '@hookform/resolvers/yup';
import { KeraltyButton, KeraltyError, KeraltyText } from 'app/components/atoms';
import { KeraltyField } from 'app/components/molecules';
import { Container, FormBox, Loader, SpaceWrapper } from 'app/components/templates';
import { useTwoFactorAuthentication } from 'app/hooks/useTwoFactorAuthentication';
import {
  DefaultValidCodeInfo,
  ValidCodeInfoSchema,
  ValidCodeInfoType,
} from 'app/ui-core/schemas/validCodeInfo';
import styled from 'app/ui-core/styled-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ROUTES from "app/ui-core/utils/routes";
import { TwoFactorAuthenticationSteps } from 'adapter/twoFactorAuthentication/twoFactorAuthentication.state';
import i18n from 'app/ui-core/i18n';
import useIsMobile from 'app/hooks/useIsMobile';

const { MAIN, REGISTER: CURRENT } = ROUTES;

const TitleWrapper = styled.div`
  margin-top: 7rem;
  width: 92%;
  margin-bottom: 2rem;
`;
const CodeInputsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CodeInput = styled(KeraltyField)`
  width: 60px;
  @media (max-width: 375px) {
    width: 50px;
    padding-right: 5px;
  }
  padding-right: 8px;
  input {
    padding-left: 45% !important;
    background-color: ${({ theme: { colors } }) => colors.gray} !important;
    color: ${({ theme: { colors } }) => colors.dark} !important;
  }
`;

const styleButton = {
  color: '#055283',
  padding: 0,
  backgroundColor: '#f5f5f5',
  borderRadius: 40,
  border: 'none',
  borderColor: '#FFFFFF',
  fontSize: 14,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  fontFamily: 'Proxima Nova',
  textDecoration: 'underline',
};

const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  button {
    padding-left: 7rem;
    padding-right: 7rem;
  }
`;

const SendCode = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { 
    infoTwoFactor, 
    saveInfoInTwoFactorStore, 
    sendAuthenticationCode, 
    login, 
    getAuthenticationCode, 
    setIsLoading, 
    isLoading,
    captchaError 
  } = useTwoFactorAuthentication();
  const history = useHistory();

  const digit1 = useRef(null);
  const digit2 = useRef(null);
  const digit3 = useRef(null);
  const digit4 = useRef(null);
  const digit5 = useRef(null);
  const digit6 = useRef(null);

  const [codeError, setCodeError] = useState(-1)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<ValidCodeInfoType>({
    resolver: yupResolver(ValidCodeInfoSchema),
    defaultValues: DefaultValidCodeInfo,
    mode: 'onBlur',
  });

  const digits = useMemo(() => [digit1, digit2, digit3, digit4, digit5, digit6], []);

  const handleDigitChange = useCallback(
    ({ target: { value } }, idx: number) => {
      const currentRef: any = digits[idx];
      const { code } = getValues();

      setCodeError(-1);

      if (Number.isNaN(Number(value))) {
        currentRef.current.value = '';
        setValue('code', code.substring(0, idx));
        return;
      } else if (code && idx > code.length) {
        currentRef.current.value = '';
        setValue('code', code.substring(0, code.length));
        const lastAllowedRef: any = digits[code.length];
        if (lastAllowedRef) lastAllowedRef.current.focus();
        return;
      }

      let newValue = value;

      if (value && value.length > 1 && currentRef) {
        const lastDigit = value[value.length - 1];
        currentRef.current.value = lastDigit;
        newValue = lastDigit;
      }

      const nextRef: any = digits[idx + 1];
      if (value.length > 0 && nextRef) nextRef.current.focus();

      setValue('code', `${code.substring(0, idx)}${newValue}`);
    },
    [digits, getValues, setValue]
  );

  const handleKeyPress = useCallback(
    (e, idx: number) => {

      setCodeError(-1);

      if (e.keyCode === 8) {
        const backRef: any = digits[idx - 1];
        if (backRef) {
          backRef.current.focus();
          backRef.current.value = '';
        }
        digits.slice(idx, digits.length).forEach((ref: any) => {
          if (ref && ref.current) ref.current.value = '';
        });
        setValue('code', `${getValues().code.substring(0, idx - 1) || ''}`);
      }
    },
    [digits, getValues, setValue]
  );

  const DigitsInputs = useMemo(
    () =>
      digits.map((digit, idx) => {
        return (
          <CodeInput
            key={`digit-${idx}`}
            control={() => (
              <input
                type="number"
                ref={digit}
                onChange={(e) => handleDigitChange(e, idx)}
                onKeyUp={(e) => handleKeyPress(e, idx)}
              />
            )}
          />
        );
      }),
    [digits, handleDigitChange, handleKeyPress, codeError]
  );

  // const onValidError = () => {
  // 	if (errorVal && errorVal.length > 0) {
  // 		return errorVal;
  // 	}
  // 	if (asyncError != null) {
  // 		return t(`errors.code${asyncError}`);
  // 	}

  // 	return null

  // }

  const reSendSmsCode = async () => {

    const body = {
      email: infoTwoFactor.email,
      phone: infoTwoFactor.phone,
      state: infoTwoFactor.state,
      isEnglish: i18n.language === 'en',
      isEmail: infoTwoFactor.sendCodeBy === 'email',
      resend: false
    };

    setIsLoading(true)

    try{
      const response = await getAuthenticationCode(body);

      saveInfoInTwoFactorStore({ 
        idTemp: response.idTemp, 
        sendCodeAttemps: response.attemps, 
        currentStep: TwoFactorAuthenticationSteps.resendCode 
      });

      setIsLoading(false)
      history.replace(`/${MAIN.authentication}/${CURRENT.status}`);
  
    }catch(error){
      setIsLoading(false)
    }
  };

  const onValidSubmit = useCallback(async (values: ValidCodeInfoType) => {

    const body = {
      idTemp: infoTwoFactor.idTemp,
      code: values.code,
      state: infoTwoFactor.state
    }

    setIsLoading(true)

    try {
      const response = await sendAuthenticationCode(body);

      saveInfoInTwoFactorStore({ 
        errorCodeAttemps: response.attemps, 
        validCode: response?.success === 'true', 
        expiredCode: response?.success === 'CODE_EXPIRED' 
      })

      if( response?.success === 'true' ) {
        // codigo valido
        // return history.replace(`/${MAIN.authentication}/${CURRENT.twoFactorAuthentication}`);
        await login();

        return
      }

      setIsLoading(false)

      if( response?.success === 'CODE_EXPIRED' || response?.attemps === '-1' ) {
        history.replace(`/${MAIN.authentication}/${CURRENT.status}`);
      }

      if( parseInt(response.attemps) < 5 ) {

        if( parseInt(response.attemps) === 0 ) {
          // te queda un intento
          setCodeError(0)
          setValue('code', '')
          return
        }

        // codigo erroneo
        setCodeError(parseInt(response.attemps))
        setValue('code', '')

        return
      }

      // redirigir a status
    } catch (error) {
      setIsLoading(false)
    }

	},[reset, digits]);

  useEffect(() => {
    saveInfoInTwoFactorStore({ currentStep: TwoFactorAuthenticationSteps.sendCode });
  }, [])

  return (
    <>
      { isLoading && <Loader /> }
      <Container noPadding>
        <Container centered={'x'} noPadding>
          <FormBox width="90%" autoComplete={'off'} onSubmit={handleSubmit(onValidSubmit)}>
            <TitleWrapper>
              <KeraltyText type="subTitle" bold={isMobile} align="left" fontSize="16px" pb>
                {t('routes.loginAuthentication')}
              </KeraltyText>

              <KeraltyText type="subTitle" color="dark" fontSize="14px" align="left">
                {t('authenticationTextValidation')}
              </KeraltyText>

              {/* // <KeraltyText type="subTitle" color="dark" align="left">{t('register.valCodeScreen.valCodeSubTitle')}</KeraltyText> */}
            </TitleWrapper>

            {/* FIELDS */}
            <CodeInputsWrapper>{DigitsInputs}</CodeInputsWrapper>

            {/* ERROR */}
            <SpaceWrapper mb={3}>
              { errors.code && <KeraltyError error={t(`errors.minCode`)} /> }
              { !errors.code && codeError >= 0 && <KeraltyError error={ codeError === 0 ? t(`errors.code151`) : t(`errors.code150`)} />}
              { captchaError && <KeraltyError error={captchaError} /> }
            </SpaceWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '-15px',
                marginBottom: '35px',
              }}
            >
              <button
                style={styleButton}
                type="button"
                onClick={(e) => {
                  reSendSmsCode();
                }}
              >
                {t('myAccount.smsVerification')}
              </button>
            </div>
            {/* ACTIONS */}
            <BodyWrapper>
              {/* <KeraltyText type="link" align="center" onClick={handleSendCodeAgain}>{t('register.valCodeScreen.codeNotReceived')}</KeraltyText> */}
              <KeraltyButton type="submit" smFull style={{ pointerEventes: isLoading ? 'none' : 'auto' }}>
                {t('register.next')}
              </KeraltyButton>
            </BodyWrapper>
          </FormBox>
        </Container>
      </Container>
    </>
  );
};

export default SendCode;
