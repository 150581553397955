import { KeraltyText } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row, Column } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'app/hooks/useIsMobile';

interface PreviousTransactionProps {
  createNewTransacion: (newTransaction: boolean) => void
}

const KeraltyTextButtom = styled(KeraltyText)`
  background: #3ca70d !important;
  line-height: 16px;
  border-radius: 25px;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
    padding-inline: 0px;
    font-size:13px;
    
  }
`;

const KeralTextButtom = styled(KeraltyText)`
  background: #FFFFFF !important;
  line-height: 12px;
  border-color: #3CA70D;
  border-radius: 25px;
  border: 2px solid #0F880D;
  margin-top: 17px;
  text-align: center;
  cursor: pointer;
  color: #3CA70D;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
    font-size:14px;
    padding-inline: 0px;
    
  }
`;


const ModalPreviousTransaction = ({createNewTransacion}: PreviousTransactionProps) => {
  const isMobile = useIsMobile();
  
  const { t } = useTranslation();

    return (
        <KeraltyModal size={ isMobile ? 'media' : 'almostMedia'} textalign="center">
          <div style={{marginRight: '3%'}}>
            <Row noMargin style={{ paddingBottom: 25, paddingTop:25 }}>
                <KeraltyText type={'info'} color={'primary'} style={{  fontSize:18, fontWeight: 600 }}>{t('getCare.televisit.previousTransaction')}</KeraltyText>
            </Row>

            <Row noMargin style={{ paddingBottom: '3%' }}>
                <KeraltyText type={'info'} color={!isMobile?'#5B5C5B':'#055293;'} style={{  fontSize:16 }}>{t('getCare.televisit.wantContinue')}</KeraltyText>
            </Row>
           
            <div style={{ display: 'grid', placeItems: 'center', width: '100%'  }}>
                <KeraltyTextButtom onClick={() => createNewTransacion(false)} type="label" undecorated style={{ width: '70%', fontSize: isMobile ? '13px' : '16px', fontWeight: 600}}>{t('getCare.televisit.continue')}</KeraltyTextButtom>
            </div>
            <div style={{ display: 'grid', placeItems: 'center', width: '100%' , paddingBottom:'25px'}}>
                <KeralTextButtom onClick={() => createNewTransacion(true)} type="label" undecorated style={{ width: '70%', fontSize: isMobile ? '13px' : '16px', fontWeight: 600, border: `${isMobile ? '1px' : '2px'} solid`}}>{t('getCare.televisit.createNew')}</KeralTextButtom>
            </div>
          </div>
        </KeraltyModal>

    );
}

export default ModalPreviousTransaction;