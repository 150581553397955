import KeraltySidebar, { SidebarProps } from "app/components/molecules/keraltySidebar";
import ROUTES from "app/ui-core/utils/routes";
import Wellness from "./Wellness";

import wellnessIcon from "app/assets/icons/wellness.svg"
import useAuth from "app/hooks/useAuth";
import { useTranslation } from "react-i18next";

const { MAIN } = ROUTES;

const WellnessPage = () => {

    const { t } = useTranslation();
    const { user } = useAuth();

    const userState = user?.state;

    const sidebar: SidebarProps = {
        sideTabs: [
          {
            name: MAIN.wellness,
            state: userState,
            icon: wellnessIcon,
            text: t('routes.wellness'),
            content: <Wellness />,
            routeName: `${MAIN.wellness}`,
            showOnMobile: false,
          },
        ],
    
        routeLabel: t('routes.wellness'),
        activeByRoute: MAIN.wellness,
    };

    return (
        <KeraltySidebar {...sidebar} hideMenu />
    )
}

export default WellnessPage;