import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import { KeraltyButton } from '../../components/atoms';

const StyledCard = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 16.75rem;
  height: auto;
  width: 16.75rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  padding: 0px 0.6rem !important;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0rem;
    margin-top: 1rem;
    font-size: 1.33rem;
    line-height: 2.25rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

export default StyledCard;
