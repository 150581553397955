import { useState, useEffect } from 'react';

const useBrowser = () => {

	const [isSafari, setIsSafari] = useState(false);

	useEffect(() => {
		const userAgent = window.navigator.userAgent;
		const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent);
		setIsSafari(isSafariBrowser);
	}, []);

	return {
		isSafari
	};

};

export default useBrowser;
