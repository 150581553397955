import appConfig from 'config';
import isCordova from '../../cordova';
// DOMAIN
import { BookAppointmentService } from "../../domain/useCases/contracts/bookAppointmentService";

const loadError = {
	name: 'Book Appointment Load Failure',
	message: 'bookAppointmentLoad',
	code: 93 // TODO: MAP code93 error
};

const BookAppointmentRepository: BookAppointmentService = {
	load: ({ lang = 'en', memberToken, onClose }) => {
		let VIM = (window as any).Vim;
		if (!VIM) return Promise.reject(loadError);

		return VIM(appConfig.VIM_API_KEY, {
			env: appConfig.VIM_ENVIRONMENT,
			supportedWidgets: ['PROVIDER-DISCOVERY']
		}).then(async (vim: any) => {
			const widget = vim.widgets['PROVIDER-DISCOVERY']?.wrappedElement;
        if (isCordova) vim.widgets['PROVIDER-DISCOVERY']?.rootElement.classList.add('cordova');
			if (widget) widget.subscribe('onClosed', () => onClose());
				//console.log('', memberToken);
			try {
				vim.showProviderDiscoveryDialog({
					...(memberToken ? { memberToken: memberToken } : {}), 
					user: { locale: lang }
				});
				return;
			}
			catch (err) { return Promise.reject(loadError); }
		}).catch((err: any) => {
			console.error('bookAppointment VIM', err);
			return Promise.reject(loadError);
		});
	}
};

export default BookAppointmentRepository;
