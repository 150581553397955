const nsMyAccount = {
    en: {
        currentGender: 'Current gender identity',
        employerName: 'Employer name',
        employmentStatus: 'Employment status',
        ethnicity: 'Ethnicity',
        insuranceProviders: 'Insurance providers',
        maritalStatus: 'Marital status',
        myInsurance: 'My insurance',
        updateTittle:"Update information",
        updateText:'In order to continue, please confirm the following information according to your Insurer ID Card. If you don’t know the Member ID, please contact our patient support chat.',
        nameOfInsurance: 'Name of health insurance',
        personalInfo: 'Personal information',
        personalDetails: 'Personal details',
        race: 'Race',
        sexAssigned: 'Gender at birth',
        studentStatus: 'Student status',
        validity: 'Validity',
        mobilePhone: 'Mobile Phone',
        email: 'Email',
        homePhone: 'Home phone',
        firstName: 'First name',
        lastName: 'Last name',
        currentGenderIdentity: 'Current gender identity',
        martialStatus: 'Martial status',
        birthday: 'Day of birth',
        patientID: 'Patient ID',
        name: 'Name',
        nameInsured:'Name of insured',
        suscriberId:'Member ID',
        groupId:'Group ID',
        status:'Status',
        active:'Active',
        inactive:'Inactive',
        update:'UPDATE INFORMATION',
        address: 'Address',
        address1: 'Address 1',
        address2: 'Address 2',
        city: 'City',
        state: 'State of service',
        zip: 'Zip code',
        phone: 'Phone',
        workPhone: 'Work phone',
        cellPhone: 'Mobile',
        coverageStartDate: 'Coverage Start Date',
        coverageEndDate: 'Coverage End Date',
        relationship: 'Patient relationship to insured',
        insurancesEmpty: 'Your insurances list is empty',
        emergencyContact: 'Emergency contact',
        deleteAccount: 'Delete account',        
        deleteAccountDetails: 'If you want to stop using our application and delete your information of it, you have to keep in mind that you will not be able to access any of the virtual services channels (Web Portal www.mysanitas.com, MySanitas App on iOS or Android) and if you want to use our virtual service channels again, you will have to create a new account.',
        deleteAccountDetails2: 'We regret your decision to delete your account. We would like to know the reason for this action, please take a few seconds and pick the reason from the following list:',
        reasonsOptions: {
            duplicate: 'I have a duplicate account',
            spam: 'I am receiving too many emails',
            privacy: 'I have a privacy concern',
            anyValue: 'I am not getting any value from this service',
            other: 'Other',
        },
        reasonPlaceholder: 'Reason for deletion',
        reasonSelectError: 'Please select an option',
        deleteAccountDetails3: 'We are about to permanently delete your account. Are you sure you want to proceed?',
        succesDeleteText: 'We have successfully deleted your account. We hope to see again.',
        succesDeleteButton: 'Close',
        firstDelete: 'Delete account',
        editAccount: 'Edit account',
        error: 'This file is required.',
        resendButton: 'RESEND LINK',
        resendText: 'If you have no received anything yet, touch the following button to send it again.',
        textToMuchEmail: 'Sorry, you have reached the code request limit. Please search the last code in your phone to continue or cancel to select another validation method. If you still haven’t received your code, contact our support chat',
        codeExpired:'Sorry, the verification code has expired. we need validate your data again ',
        codeTimer:'Sorry, you have reached the wrong code entry limit. For your security, please wait ',
        codeTimer2:' minutes to carry on with this action.',       
        textToMuchEmail2: '',
        close: 'CLOSE',
        emailSent: 'Your verification link was sent again. Please check your email.',
        smsVerification: '*I did not receive the verification code',
        verificationCode: 'Your verification code was sent again.',
        verificationCode2: 'Please check your mobile phone',
        verificationCode3: 'Please check your email',
        textToMuchSms: 'Sorry, you have reached the code request limit. Please search the last code in your phone to continue or cancel to select another validation method. If you still haven’t received your code, contact our support chat ',
        textToMuchSms2: 'Sorry, you have reached the code request limit. Please search the last code in your email to continue or cancel to select another validation method. If you still haven’t received your code, contact our support chat ',

        textContinue: 'CONTINUE',
        textCancel: 'CANCEL',
        textAccept: 'ACCEPT',
        limit1: "Sorry, you have reached the wrong code entry limit. For your security, please wait",
		limit2: "minutes to carry on with this action.",
        errorCodeRequestLimit_phone: 'Sorry, you have reached the code request limit. Please search the last code in your mobile to continue or close the action.',
        errorCodeRequestLimit_email: 'Sorry, you have reached the code request limit. Please search the last code in your email to continue or close the action.',
        noteCodeRequestLimit: 'If you select close, you will need to wait 30 minutes to continue the process. If you still haven’t received your code, contact our support chat.',
        holderName: 'Name of holder insured',
        holderLastName: 'Last name of holder insured',
        holderDateOfBirth: 'Date of birth of holder insured'
    },
    es: {
        limit1: "Lo sentimos, has alcanzado el límite de ingreso de códigos erróneos. Por tu seguridad, espera",
		limit2: "minutos para volver a ejecutar esta acción.",
        currentGender: 'Identidad de género actual',
        employerName: 'Nombre de empleador',
        employmentStatus: 'Estado laboral',
        ethnicity: 'Etnia',
        insuranceProviders: 'Proveedores de seguro',
        maritalStatus: 'Estado civil',
        myInsurance: 'Mi seguro médico',
        updateTittle:"Actualizar información",
        updateText:'Para continuar, confirme la siguiente información de acuerdo con su tarjeta de identificación del asegurador. Si no conoce la ID de miembro, comuníquese con nuestro chat de atención al paciente.',
        nameOfInsurance: 'Name of health insurance',
        personalInfo: 'Información personal',
        personalDetails: 'Detalles personales',
        race: 'Raza',
        sexAssigned: 'Género al nacer',
        studentStatus: 'Escolaridad',
        validity: 'Validity',
        mobilePhone: 'Celular',
        email: 'Correo electrónico',
        homePhone: 'Teléfono casa',
        firstName: 'Nombre(s)',
        lastName: 'Apellidos',
        currentGenderIdentity: 'Identidad de género actual',
        martialStatus: 'Estado civil',
        birthday: 'Fecha de nacimiento',
        patientID: 'ID de paciente',
        name: 'Nombre',
        nameInsured:'Nombre del asegurado ',
        suscriberId:'ID del suscriptor',
        groupId:'ID del grupo',
        status:'Estado',
        active:'Activo',
        inactive:'Inactivo',
        update:'ACTUALIZAR INFORMACIÓN',
        address: 'Dirección',
        address1: 'Dirección 1',
        address2: 'Dirección 2',
        city: 'Ciudad',
        state: 'Estado de servicio',
        zip: 'Código postal',
        phone: 'Teléfono',
        workPhone: 'Teléfono trabajo',
        cellPhone: 'Celular',
        coverageStartDate: 'Fecha de inicio de la cobertura',
        coverageEndDate: 'Fecha de fin de la cobertura',
        relationship: 'Relación del paciente y el asegurado',
        insurancesEmpty: 'La lista de seguros médicos está vacía',
        emergencyContact: 'Contacto de emergencia',
        deleteAccount: 'Borrar cuenta',
        deleteAccountDetails: 'Si desea dejar de usar nuestra aplicación y eliminar su información de la misma, debe tener en cuenta que no podrá ingresar a ninguno de los canales de servicios virtuales (Portal web www.mysanitas.com, MySanitas App en iOS o Android ) y si desea volver a utilizar nuestros canales de atención virtual, deberá volver a crear la cuenta.',
        deleteAccountDetails2: 'Lamentamos su decisión de eliminar su cuenta, queremos saber el motivo de la misma, para ello, por favor elija la opción de la lista a continuación.',
        reasonsOptions: {
            duplicate: 'Tengo una cuenta duplicada',
            spam: 'Estoy recibiendo muchos correos',
            privacy: 'Tengo un problema de privacidad',
            anyValue: 'No estoy recibiendo ningun valor del servicio',
            other: 'Otra',
        },
        reasonPlaceholder: 'Razón para borrar cuenta',
        reasonSelectError: 'Por favor seleccione una opción',
        deleteAccountDetails3: 'Estamos a punto de borrar su cuenta de manera permanente. ¿Desea continuar?',
        succesDeleteText: 'Hemos borrado su cuenta de manera exitosa. Esperamos verlo pronto de regreso',
        succesDeleteButton: 'Cerrar',
        firstDelete: 'Borrar cuenta',
        editAccount: 'Editar cuenta',
        error: 'Este campo es requerido.',
        resendButton: 'REENVIAR ENLACE',
        resendText: 'Si aún no ha recibido nada, toque el botón a continuación para solicitarlo de nuevo.',
        textToMuchEmail: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su teléfono para continuar o cancele para seleccionar otro método de validación. Si aún no ha recibido el código, puede contactar con nuestro chat de soporte',
        textToMuchEmail2: '',
        codeExpired:'Lo sentimos, tu código de verificación ha expirado, necesitamos validar tus datos nuevamente ',
        codeTimer:'Lo sentimos, has alcanzado el límite de ingreso de códigos erróneos. Por tu seguridad, espera ',
        codeTimer2:' minutos para volver a ejecutar esta acción',
       
        close: 'CERRAR',
        emailSent: 'Tu enlace de verificación fue enviado nuevamente. Por favor verifica tu correo electrónico.',
        smsVerification: '*No recibí el código de verificación',
        verificationCode: 'Tu código de verificación fue enviado nuevamente.',
        verificationCode2: 'Por favor verifica tu celular.',
        verificationCode3: 'Por favor verifica tu email.',
        textToMuchSms: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su teléfono para continuar o cancele para seleccionar otro método de validación. Si aún no ha recibido el código, puede contactar con nuestro chat de soporte',
        textToMuchSms2: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su email para continuar o cancele para seleccionar otro método de validación. Si aún no ha recibido el código, puede contactar con nuestro chat de soporte',
        textContinue: 'CONTINUAR',
        textCancel: 'CANCELAR',
        textAccept: 'ACEPTAR',
        errorCodeRequestLimit_phone: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su teléfono para continuar o seleccione la opción Cerrar para finalizar la acción.',
        errorCodeRequestLimit_email: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su correo electrónico para continuar o seleccione la opción Cerrar para finalizar la acción.',
        noteCodeRequestLimit: 'Recuerde que si selecciona la opción Cerrar deberá esperar 30 minutos para volver a realizar el proceso. Si aún no ha recibido el código, puede contactar con nuestro chat de soporte.',
        holderName: 'Nombre del titular del seguro',
        holderLastName: 'Apellido del titular del seguro',
        holderDateOfBirth: 'Fecha de nacimiento del titular del seguro'
    }
};

export default nsMyAccount;
