import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import appConfig from 'config';
import useAuth from '../../../hooks/useAuth';
import { Row, Column, IconLinkRow, CenteredWrapper, Card } from '../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../atoms';
//import { KeraltyModal } from '../../molecules';
import ROUTES from '../../../ui-core/utils/routes';


import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import laptopMedical from '../../../assets/icons/laptop-medical.svg';
import userChatIcon from '../../../assets/icons/user-md-chat.svg';

import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker } from 'adapter/chat/chatSlice';

import { userSelectors } from '../../../../adapter/user/userSelectors';
import SectionWidget from '../../molecules/sectionWidget';
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';

import { utcTime } from 'app/ui-core/utils/times';

const { MAIN, GET_CARE } = ROUTES;

// @TODO Move to env constant

const linkToTelevisit: string = appConfig.TELADOC_URL;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };
const sizesLaptopMedicalIcon: IconSizes = { width: 2.8, height: 2.24 };

interface IconSizes {
	width: number;
	height: number;
}

// const Text = styled(KeraltyText)`
// 	display: flex;
// 	cursor: pointer;

// 	span {
// 		line-height: 1rem;
// 		text-decoration: underline;
// 		margin-left: 0.5888rem;
// 	}
// `;

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
	}
`;

// const ButtonBackContainer = styled(Column)`
// 	margin-bottom: 0rem;
// 	padding: 0rem !important;
// `;

const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
	}
`;

const LeftColumn = styled(Column)`
	justify-content: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const RightColumn = styled(Column)`
	justify-content: flex-start;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 16.75rem;
  height: auto;
  width: 17.5rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;

  h2 {
    margin: 0rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

const TalkToaDoctorTab = () => {
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const dispatch = useAppDispatch();
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const { sanitasState } = sanitasChatState;
	const { user } = useAuth();
	const { authUid } = useAppSelector(state => state.user)


	const handleTelevisit = () => {
		push(`/${MAIN.getCare}/${GET_CARE.getCareNow}/${GET_CARE.televisit}`);
	};

	useEffect(() => {
		return () => {
			if (sanitasState.isShow && sanitasState.isSetted) {
				dispatch(sanitasAdapterMethods.shutdown());
			}
		}
	}, [sanitasState, dispatch]);

	const handleTalkToDoctor = async () => {
		dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			dispatch(sanitasAdapterMethods.shutdown());
		}

		/** Sanitas Chat Action */
		await setTimeout(async () => {
			await dispatch(sanitasAdapterMethods.setUp({
				actionColor: '#0071B2',
				backgroundColor: '#0071B2',
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'provider' },
			}));
			await dispatch(sanitasAdapterMethods.show());
		}, 500);
	};

	return (
		<RightContent fullHeight bg="light">
			<IconLinkRow
				onClick={goBack}
				color="accent"
				icon={arrowAltCircleLeftIcon}
				iconAlt={t('imgNicons.backArrow')}
				text={t('routes.back')} />
			<Row>
				<TitleContiner lg={12}>
					<KeraltyText type="title">{t('getCare.getCareNow')}</KeraltyText>
				</TitleContiner>
			</Row>
			<Row>
				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						<Card onClick={handleTalkToDoctor}>
							<SectionWidget img={userChatIcon} text={capitalizeJustFirst(t('button.chatWithaDoctor'))} alt={t('imgNicons.chatDoctorIcon')} />
						</Card>
					</CenteredWrapper>
				</Column>
				<LeftColumn lg={6} md={6} sm={0}>
					<GetCareNowButton color="light" squareRadius={true} onClick={handleTalkToDoctor}>
						<span>
							<Image
								src={userChatIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')} />
							<KeraltyText type="title">{t('button.chatWithaDoctor')}</KeraltyText>
						</span>
					</GetCareNowButton>
				</LeftColumn>
				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						{isActiveCoverage || window.KERALTY_CONFIG ? (
							<a href={linkToTelevisit} target="_blank" rel="noopener noreferrer">
								<Card onClick={handleTalkToDoctor}>
									<SectionWidget img={laptopMedical} text={capitalizeJustFirst(t('button.televisit'))} />
								</Card>
							</a>
						) : (
							<Card onClick={() => handleTelevisit()}>
								<SectionWidget img={laptopMedical} text={capitalizeJustFirst(t('button.televisit'))} />
							</Card>
						)}
					</CenteredWrapper>
				</Column>
				<RightColumn lg={6} md={6} sm={0}>
					{isActiveCoverage || window.KERALTY_CONFIG ? (
						<a href={linkToTelevisit} target="_blank" rel="noopener noreferrer">
							<GetCareNowButton color="light" squareRadius={true}>
								<span>
									<Image
										src={laptopMedical}
										width={sizesLaptopMedicalIcon.width}
										height={sizesLaptopMedicalIcon.height}
									/>
									<KeraltyText type="title">{t('button.televisit')}</KeraltyText>
								</span>
							</GetCareNowButton>
						</a>
					) : (
						<span onClick={() => handleTelevisit()}>
							<GetCareNowButton color="light" squareRadius={true}>
								<span>
									<Image
										src={laptopMedical}
										width={sizesLaptopMedicalIcon.width}
										height={sizesLaptopMedicalIcon.height}
									/>
									<KeraltyText type="title">{t('button.televisit')}</KeraltyText>
								</span>
							</GetCareNowButton>
						</span>
					)}
				</RightColumn>
			</Row>
		</RightContent>
	);
};

export default TalkToaDoctorTab;
