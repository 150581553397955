// Primary Colors

export const COLOR_BLUE_DC1 = "#055293";
export const COLOR_BLUE_DC2 = "#055293";
export const COLOR_GREEN_DC1 = "#3ca70d";
export const COLOR_GRAY_DC1 = "#5b5c5b";
export const COLOR_GRAY_DC2 = "#e5e5e5";
export const COLOR_GRAY_AC1 = "#a3a3a3";

// Secondary colors
export const COLOR_BLUE_DC3 = "#0079de";
export const COLOR_BLUE_DC4 = "#49c3b1";
export const COLOR_GREEN_DC2 = "#00b288";
export const COLOR_GREEN_DC3 = "#90a800";


// Alerts and Notification Colors
export const COLOR_ERROR = "#d74840";
export const COLOR_SUCCESS = "#b2c44a";
export const COLOR_WARNING = "#edc536";
