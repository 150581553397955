import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

// Domain
import UnblockAccount from 'domain/useCases/unblockAccount';
import { UserIdentifier } from 'domain/entities/userIdentifier';
import { EmailIdentifier } from 'domain/entities/emailIdentifier';
import { ResetPassword } from 'domain/entities/resetPassword';

// Services
import UnblockService from 'infrastructure/keraltyApi/repositories/unblockAccount';
import { RootState } from 'adapter/store';

// @TODO: This should be a common type across the entire adapter => Jesus +1
interface InitialState<DataEntityType = any> {
	data: DataEntityType | null;
	isLoading: boolean;
	error: SerializedError | null;
}

const INITIAL_STATE: InitialState<string> = {
	data: null,
	isLoading: false,
	error: null
}

export const tryValidateStateInfo = createAsyncThunk(
	'unblockAccount/validateStateInfo',
	async (data: UserIdentifier) => {
		return await UnblockAccount.validateStateInfo(UnblockService, data);
	}
);

export const trySendPassRecoveryEmail = createAsyncThunk(
	'unblockAccount/sendPassRecoveryEmail',
	async (notificationPreference: EmailIdentifier, { getState }) => {
		const { UnblockAccount: { data } } = getState() as RootState;
		return await UnblockAccount.sendRecoverPassEmail(UnblockService, { ...notificationPreference, id: data });
	}
);

export const tryChangePassword = createAsyncThunk(
	'unblockAccount/changePassword',
	async (newPass: ResetPassword) => {
		return await UnblockAccount.changePassword(UnblockService, newPass);
	}
);

export const UnblockAccountSlice = createSlice({
	name: 'unblockAccount',
	initialState: INITIAL_STATE,
	reducers: {
		cleanError: (state) => {
			state.error = null;
		}
	},
	extraReducers: (builder) => {
		builder
			// trySendPassRecoveryEmail
			.addCase(trySendPassRecoveryEmail.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(trySendPassRecoveryEmail.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(trySendPassRecoveryEmail.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})

			// tryChangePassword
			.addCase(tryChangePassword.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(tryChangePassword.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(tryChangePassword.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
	},
});

export const { cleanError } = UnblockAccountSlice.actions;
export default UnblockAccountSlice.reducer;
