import styled from "app/ui-core/styled-components";
import BREAKPOINTS from "app/ui-core/utils/breakpoints";
import { Controller, useForm } from "react-hook-form";
import theme from 'app/ui-core/utils/theme';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

const ReasonContainer = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  width: 100%;
  margin: auto;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
  }
`;

const TextAreaStyled = styled.textarea`
  height: 95px;
  border: none;
  font-size: 16px;
  padding: 14px;
  border-bottom: 0.17rem solid ${(props) => props.theme.colors.gray};
  border-radius: 0.33rem;
  margin-bottom: 0.8rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.dark};
  outline: none !important;
  word-break: break-all;
  font-family: 'Proxima Nova';
  &:focus,
  &:active {
    outline: none;
  }
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    height: 142px;
	font-size: 14px;
  }
`;

interface TextAreaControl {
	updateReasonText(arg: string): void;
	placeholder: string;
	maxLength?: number;
	charactersNotAllowed?: string[];
	initialValue?: string;
}

interface TextAreaControlForm {
	reasonText: string[]
}

const TextAreaControl: React.FC<TextAreaControl> = ({ updateReasonText, placeholder, maxLength = 500, charactersNotAllowed, initialValue = '' }) => {

	const { control } = useForm<TextAreaControlForm>({
		defaultValues: {
			reasonText: [initialValue],
		}
	});

	const validateText = (text: string) => {
		if(!charactersNotAllowed) return text;

		const regex = new RegExp(`[${charactersNotAllowed.join('')}]`, 'g');

		return text.replaceAll(regex, "")
	}
  
	return (
	  <Controller
		control={control}
		name="reasonText"
		render={({ field: { onChange, onBlur, value, name, ref } }) => (
		  <ReasonContainer>
			<TextAreaStyled
			  theme={theme}
			  maxLength={maxLength}
			  value={value}
			  onChange={(e) => {
				e.target.value = validateText(e.target.value)
				updateReasonText(e.target.value);
				onChange(e);
			  }}
			  placeholder={placeholder}
			  onBlur={onBlur}
			  ref={ref}
			  style={{ resize: 'none' }}
			/>
		  </ReasonContainer>
		)}
	  />
	);
};

export default TextAreaControl;
