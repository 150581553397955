
import { ChatState } from './chatState';
import { RootState } from '../store';
import { baseSelectors } from '../base/baseSelectors';


const selectSelf = (state: RootState): ChatState => state.chat;

const selectors = baseSelectors(selectSelf);



const chatSelectors = {
	...selectors
};

export default chatSelectors;
