interface ScrollIntoProps  {
	block: 'start' | 'center' | 'end' | 'nearest';
	ref: React.MutableRefObject<HTMLElement | undefined>
}

const scrollInto = ({ block = 'start', ref }: ScrollIntoProps) => {
	if (ref.current) {
		ref.current.scrollIntoView({
			behavior: 'smooth',
			block,
		});
	}
};

const basicScrollIntoTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

export {
	scrollInto,
	basicScrollIntoTop
}
