import { EcwService } from './contracts/ecwService';


export const getLaboratoryData = async (myHealthServices: EcwService, patientId: string) => {
  const patientIdParsed = parseInt(patientId);
  const response = await myHealthServices.getLabData(patientIdParsed);
  return response;
};
export const getCurrentMedicationData = async (myHealthServices: EcwService, patientId: string) => {
	const patientIdParsed = parseInt(patientId);
	const response = await myHealthServices.getCurrentMedication(patientIdParsed);
	return response;
  };
export const getMedicationsData = async (myHealthServices: EcwService, patientId: string) => {
  const patientIdParsed = parseInt(patientId);
  const response = await myHealthServices.getMedicationData(patientIdParsed);
  return response;
};

export const getReferralsData = async (myHealthServices: EcwService, patientId: string) => {
  const patientIdParsed = parseInt(patientId);
  const response = await myHealthServices.getReferralData(patientIdParsed);
  return response;
};

export const getImmunizationData = async (myHealthServices: EcwService, patientId: string) => {
  const patientIdParsed = parseInt(patientId);
  const response = await myHealthServices.getInmunizationData(patientIdParsed);
  return response;
};

export const getVisitSummaryData = async (myHealthServices: EcwService, data: any) => {
  const patientId = parseInt(data.idEcw) || 0;
  const response = await myHealthServices.getVisitSummaryData({ ...data, patientId });
  return response;
};

const myHealhtUseCases = {
  getLaboratoryData,
  getCurrentMedicationData,
  getMedicationsData,
  getReferralsData,
  getImmunizationData,
  getVisitSummaryData
}

export default myHealhtUseCases;
