import { ReactComponent as Logo } from 'app/assets/images/login-logo.svg';
import { Column } from 'app/components/templates';
import { ReactComponent as LogoArc } from 'app/assets/images/login-logo-arc.svg'

interface props {
	children?: JSX.Element;
}
const PreLoginHeader = ({ children }: props) => {

	return (
		<>
			<Column sm={0} noPadding>
				<div className={`pre-login-header`}>
					<Logo className={'pre-login-logo'} />
				</div>
			</Column>
			<Column lg={0} md={0} noPadding>
				<div className={`pre-login-header-arc`}>
					<LogoArc className={'pre-login-logo'} />
					{children}
				</div>
			</Column>
		</>
	);
};

export default PreLoginHeader;
