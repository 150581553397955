import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import appConfig from 'config';

import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../atoms';
import { SectionWidget } from 'app/components/molecules';

import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';

import labIcon from '../../../assets/icons/lab-results-blue.svg';
import referralsIcon from '../../../assets/icons/referrals-blue.svg';
import medicationsIcon from '../../../assets/icons/medicationby.svg';
import visitSumaryIcon from '../../../assets/icons/visitSumary.svg';
import inmunizationsByIcon from '../../../assets/icons/inmunizationsBy.svg';



import ROUTES from '../../../ui-core/utils/routes';

import {
	closeSymptomChecker,
	openSymptomChecker,
	showChat,
} from 'adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../hooks/useAuth';
import useIsMobile from '../../../hooks/useIsMobile'
import { shutdown, show, setUp } from 'adapter/sanitasChat/sanitasChat';

import i18n from "i18next";
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import { userSelectors } from 'adapter/user/userSelectors';
import isCordova from 'cordova';
import { utcTime } from 'app/ui-core/utils/times';
import ModalOnboarding from './ModalOnboarding';
import { useState } from 'react';
import theme from 'app/ui-core/utils/theme';

const { MAIN, MY_HEALTH: CURRENT } = ROUTES;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };


const linkToTelevisit: string = appConfig.TELADOC_URL;

interface IconSizes {
	width: number;
	height: number;
}

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 10px;
	};
`;

const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
		margin-bottom: 0rem;
		paddingLeft: -2%
	}
`;


const CustomRow = styled(Row)`
	& > ${Column} {
		margin-top: 1rem ;
		margin-bottom: .5rem !important;
		margin-left:2.5rem ;

		margin-right:2.5rem ;
		@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			margin-left:1rem ;
			
		};
	}
`;
const styleText = {
	fontFamily: 'Proxima Nova',
	fontSize: '16px',
	color: theme.colors.dark,
	lineHeight: '1.58rem'
}







const AboutPage = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const { user } = useAuth();
	const chatState = useAppSelector((state) => state.chat.symptomChecker);
	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isChatOpen = chatState.isOpen;
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const isMobile = useIsMobile();
	const { authUid } = useAppSelector(state => state.user)
	const [showOnBoard, sethowOnBoard] = useState(false)
	

	const styleText2 = {
		fontFamily: 'Proxima Nova',
		fontSize: '16px',
		color: theme.colors.dark,
		fontWeight: 700
	}

	const isEnglish = () => {
		if (i18n.language.includes('en')) return true;
		else return false
	}

	const show = () => { sethowOnBoard(false) }

	const handleMain = () => {
		push(`/${MAIN.mentalHealth}`);
	};

	const AboutText = () => {
		const { t } = useTranslation();
		return (
			<CustomRow >
				{/* TITLE */}
				<Column lg={12}>
					<KeraltyText type="link" bold pb style={{ fontSize: 16 }} onClick={() => sethowOnBoard(true)} >
						{t('mentalHealth.revisit')}
					</KeraltyText>
				</Column>
				<Column lg={12}>
					<KeraltyText type="subTitle" bold pb>
						{t('mentalHealth.new')}
					</KeraltyText>
				</Column>
				<Column lg={12}>
					<KeraltyText align="left" type="info" color={'dark'}>
						{t('mentalHealth.welcome')}
					</KeraltyText>
				</Column>

				<Row style={{ paddingInline: !isMobile ? 12 : 10, marginInline: isMobile ? 10 : 30, marginRight: isMobile ? 25 : '' }}>
					<text style={styleText} >
						{t('mentalHealth.our')}

						<text style={isEnglish() ? styleText2 : styleText}>
							{t('mentalHealth.our2')}
						</text>

						{' '}{t('mentalHealth.our3')}
					</text>
				</Row>
				<Column lg={12}>
					<KeraltyText align="left" type="info" color={'dark'}>
						{t('mentalHealth.thank')}
					</KeraltyText>
				</Column>

			</CustomRow>
		);
	};



	return (
		<RightContent fullHeight>
			{showOnBoard &&
				<ModalOnboarding
					skip={() => { sethowOnBoard(false); handleMain() }}
					start={() => { sethowOnBoard(false); handleMain() }}
				/>
			}
			<div style={{ marginLeft:!isMobile? '1%':'3%', width: '100%' }}>
			<IconLinkRow
				onClick={goBack}
				color="accent"
				icon={arrowAltCircleLeftIcon}
				iconAlt={t('imgNicons.backArrow')}
				text={t('routes.back')}
			/>
			</div>
			<Row>
				<TitleContiner lg={12}>
					<KeraltyText type="title">{t('mentalHealth.about')}</KeraltyText>
				</TitleContiner>
			</Row>
			<Row>
				<AboutText />
			</Row>

		</RightContent>
	);
};

export default AboutPage;
