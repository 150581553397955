
import styled from '../../ui-core/styled-components';

const TransitionWrapper = styled.div<any>`
	height: auto;
	min-height: 100%;
	position: absolute;
	right: 0;

	${({ show }) => show ? `
		width: 100%;
		opacity: 1;
		margin-left: 0%;
		transition: all .4s;
    	-webkit-transition: all 0.4s;
	`: `
		width: 0%;
		opacity: 0;
		transition: all .4s;
		-webkit-transition: all 0.4s;
	`}
`;

export default TransitionWrapper;
