import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState } from 'adapter/store';
//@TODO: This should go to the domain folder
// import { UserStateTypes } from 'entities/UserTypes';
import initialState from './initialState';
import baseReducer from '../base/baseReducer';
import jwtDecode from 'jwt-decode';
// INFRASTRUCTURE
import AuthRepository from 'infrastructure/keraltyApi/repositories/auth';
import { UserAvailityRepository } from 'infrastructure/keraltyApi/repositories/userAvaility';
// DOMAIN
import { UserCredentials as UserCredentialsEntity } from 'domain/entities/userCredentials';
import { UserAvaility } from 'domain/entities/userAvaility';
import authUseCase from '../../domain/useCases/auth';
import userAvailityUseCases from 'domain/useCases/userAvaility';
import CryptoJS from 'crypto-js';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import sessionStorage from 'redux-persist/lib/storage/session'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import appConfig from 'config';


export const persistConfig = {
	key: 'user',
	storage: sessionStorage,
	whitelist: ['locationSelected', 'token'],
	transforms: [
		encryptTransform({
			secretKey: appConfig.KERALTY_SWS_KEY,
		  	onError: function (error) {
				// Handle the error.
		  	},
		}),
	],
};

export const tryInitSession = createAsyncThunk(
	'user/tryInitSession',
	async (token: string): Promise<any> => {
		const userData = await authUseCase.loadUserBySession(AuthRepository, token, KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.STATE) ??'');
		window.dataLayer?.push({
			user_id: userData.authUid,
			k_id: userData.authUid,
			e_id: userData.idEcw,
		});
		const activeCoverage = (jwtDecode(token) as any)?.activeCoverage || false;
		return { ...userData, token, activeCoverage: activeCoverage };
	}
);

export const tryLogin = createAsyncThunk(
	'user/tryLogin',
	async (value: Omit<UserCredentialsEntity, 'phone'>, { dispatch }): Promise<any> => {
		const { token } = await authUseCase.loginSecurity(AuthRepository, value);
		if (token) {
			dispatch(tryInitSession(token));
			if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC) === 'true')
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED, CryptoJS.AES.encrypt(JSON.stringify(value), 'MyS4n1t4s').toString());
		}

		if(token === '-1'){
			KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ERROR_SECURITY, token)
		}

		return token;

	}
);

export const tryAllStates = createAsyncThunk(
	'user/tryAllStates',
	async (value: Omit<UserCredentialsEntity, 'phone'>) => {
		return await authUseCase.loadLoginAllStates(AuthRepository, value);
	}
);


export const trySSOLogin = createAsyncThunk(
	'user/ssoLogin',
	async (token: string, { dispatch }): Promise<any> => {
		dispatch(userActions.setLocation({ value: 'Florida', label: 'Florida' }));
		dispatch(tryInitSession(token));
	}
);

const tryToVerifyTelevisitUser = createAsyncThunk(
	'user/VerifyTelevisitUser',
	async (userData: UserAvaility, { getState }) => {
		const { user: { activeCoverage, authUid } } = getState() as RootState;
		const data = {
			...userData,
			isActive: activeCoverage,
			authUid
		};
		return await userAvailityUseCases.sendDataAvaility(UserAvailityRepository, data);
	}
);

export const UserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setProp: (state, action: PayloadAction<{ key: any; value: any; }>) => {
			const {
				payload: { key, value },
			} = action;
			return { ...state, [key]: value };
		},
		setLanguage: (state, { payload }) => ({ ...state, lng: payload }),
		setLocation: (state, { payload }) => ({ ...state, locationSelected: payload }),
		cleanError: (state) => ({ ...state, error: undefined }),
		tryLogout: (state) => ({ ...initialState, locationSelected: state.locationSelected }),
		setGeneralError: (state, { payload }) => ({ ...state, generalError: payload }),
	},
	extraReducers: (builder) => {
		builder
			//#region tryLogin
			.addCase(tryLogin.pending, (state) => {
				state.status = 'pending';
				baseReducer.pending(state);
			})
			.addCase(tryLogin.fulfilled, (state, action: any) => {
				state.status = 'pending';
				baseReducer.fullfilled(state);
			})
			.addCase(tryLogin.rejected, (state, action: any) => {
				state.isLoggedIn = false;
				baseReducer.rejected(state, action);
			})
			//#endregion

			//#region trySSOLogin
			.addCase(trySSOLogin.pending, (state) => {
				state.status = 'pending';
				baseReducer.pending(state);
			})
			.addCase(trySSOLogin.fulfilled, (state) => {
				baseReducer.fullfilled(state);
			})
			.addCase(trySSOLogin.rejected, (state, action: any) => {
				state.status = 'failed';
				state.displayName = '';
				state.isLoggedIn = false;
				baseReducer.rejected(state, action);
			})
			//#endregion

			//#region tryInitSession
			.addCase(tryInitSession.pending, (state) => { baseReducer.pending(state); })
			.addCase(tryInitSession.fulfilled, (state, action) => {
				const { firstName, lastName, activeCoverage } = action.payload;
				const patientInformation = action.payload;

				let newState: any = {
					...state,
					...patientInformation,
					activeCoverage,
					displayName: `${firstName} ${lastName}`,
					hasError: undefined,
					isLoggedIn: true,
					isLoading: false,
					status: 'succeeded'
				};
				state = Object.assign(state, newState);
			})
			.addCase(tryInitSession.rejected, (state) => ({
				...initialState,
				locationSelected: state.locationSelected,
			}))
			//#endregion

			//#region tryInitSession
			.addCase(tryToVerifyTelevisitUser.fulfilled, (state, { payload }) => {
				state.activeCoverage = payload?.isActive;
				baseReducer.fullfilled(state);
			})
			//#endregion

			//#region tryAllStates
			/*.addCase(tryAllStates.pending, (state) => { baseReducer.pending(state); })
			.addCase(tryAllStates.fulfilled, (state) => { baseReducer.fullfilled(state); })
			.addCase(tryAllStates.rejected, (state, action) => { baseReducer.rejected(state, action); })*/
			//#endregion



			.addCase(REHYDRATE, (state) => {
				if (!(state.error && !state.isLoggedIn)) {
					state.error = undefined;
					state.isLoggedIn = false;
				}
			});
	}
});

export const userActions = {
	...UserSlice.actions,
	tryLogin,
	trySSOLogin,
	tryToVerifyTelevisitUser,
	tryAllStates,
};

export const user = (state: RootState) => state.user;
export const userName = (state: RootState) => state.user.displayName;
export const idEcw = (state: RootState) => state.user.idEcw;

export default UserSlice.reducer;
