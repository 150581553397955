import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

import AnxietySteps from "./components/AnxietySteps";
import TitleSection from "./components/TitleSection";
import { ReactComponent as thinking } from '../../../../assets/icons/thinking.svg';
import { KeraltyButton, KeraltyError, KeraltyText } from "app/components/atoms";
import { TitleContiner } from "./Anxiety.styled";
import ROUTES from "app/ui-core/utils/routes";
import TextAreaControl from "app/components/molecules/TextAreaControl";
import { useAnxiety } from "./hooks/useAnxiety";
import { useEffect, useState } from "react";
import useIsMobile from "app/hooks/useIsMobile";

const ThinkingDetails = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const { register, saveInfoInStore } = useAnxiety();
  const [currentValue, setCurrentValue] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const MIN_CHARACTERS = 10;

	const { MENTAL_HEALTH } = ROUTES;

  const nextStep = () => {
    if(currentValue.length < MIN_CHARACTERS){
      setError(true);
      return
    }

    saveInfoInStore({ moreAbout: currentValue })
    history.replace(`${MENTAL_HEALTH.actionsDetails}`)
  }

  const handleTextArea = (text: string) => {
    setError(false);
    setCurrentValue(text);
  }

  useEffect(() => {
    if(register.moreAbout) setCurrentValue(register.moreAbout)
  }, [register])

  return (
    <AnxietySteps>
      <TitleSection 
        icon={thinking} 
        title={t('mentalHealth.thinking')} 
        subtitle={t('mentalHealth.thinkingText')} 
      />
      <div style={{ display: 'grid', placeItems: 'center', marginTop: 20, marginBottom: 20 }}>
        <KeraltyButton fontWeight={ 600 } fontSize="14px" width="27rem" withShadow withoutTextTransform>
          { register.thinking }
        </KeraltyButton>
      </div>
      <div style={isMobile ? { display: 'grid', marginLeft: 20, marginRight: 10 } : { marginLeft: 55, display: 'grid' }}>
        <TitleContiner lg={12} style={{marginTop: 10, marginBottom: 10}}>
          <KeraltyText type="subTitle" fontWeight={400} fontSize="14px" align="left" color="#212121">{t('mentalHealth.thinkingDetails')}</KeraltyText>
          <KeraltyText type="subTitle" fontWeight={400} fontSize="14px" align="left" color="gray">{t('mentalHealth.minAnswer')}</KeraltyText>
        </TitleContiner>

        <TextAreaControl 
            updateReasonText={handleTextArea}
            placeholder={t('mentalHealth.typeYourAnswer')}
            charactersNotAllowed={['|']}
            initialValue={register.moreAbout}
        />

      </div>
      {
        error 
        && (
          <div style={{ display: 'grid', placeItems: 'center', marginTop: 0, marginBottom: 40 }}>
            <KeraltyError error={t('mentalHealth.thinkingDetailError')} />
          </div>
        )
      }
      <div style={{ marginTop: 20, display: 'grid', placeItems: 'center' }}>
				<KeraltyButton              
					onClick={nextStep}
					fontWeight={ 600 }
          withShadow
				>
					{t('formLabel.enter')}
				</KeraltyButton>
			</div>
    </AnxietySteps>
  )
}

export default ThinkingDetails;