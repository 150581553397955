import styled from '../../ui-core/styled-components';
import KeraltyIcon from './keraltyIcon';
import KeraltyText from './keraltyText';
import KeraltyTooltip from './keraltyTooltip';

const InfoFieldIcon = styled(KeraltyIcon)`
	margin-left: 0.58rem;
`;

const InputStructure = ({ className, field, label, infotooltip, noPadding, children, ...props }: any) => (
	<div className={className}>
		<input type="radio" {...field} {...props} />
		{label &&
			<KeraltyText align="left" type={'info'} color={'dark'}>
				{label}{infotooltip &&
					<KeraltyTooltip>
						<InfoFieldIcon data-tip={infotooltip} iconClass="fas fa-info-square" color='accent' />
					</KeraltyTooltip>}
			</KeraltyText>
		}
		{children}
	</div>
);

const KeraltyRadio = styled(InputStructure)`
	position: relative;
	display: flex;
	align-items: center;
	${({ noPadding }) => noPadding ? '' : 'padding: 0 1rem;'}
	margin-bottom: 1rem;
	span>* {
		display: inline;
	}
	input[type="radio"] {
		width: 21px;
        height: 21px;
        border-radius: 21px;
		outline: none;
		position: relative;
		margin-right: 1.16rem;
		margin-top: 0px;
		padding: 0px;
		&:before {
			width: 17px;
			height: 17px;
			border: 2px solid ${({ theme: { colors } }) => colors.primary};
			border-radius: 17px;
			position: absolute;
			background-color: white;
			content: '';
			display: inline-block;
			visibility: visible;
		}
		&:after {
			width: 11px;
			height: 11px;
			border-radius: 11px;
    		margin: 5px;
			position: relative;
			content: '';
			display: inline-block;
			visibility: visible;
			border: none;
		}
		&:checked {
			&:after {
				background-color: ${({ theme: { colors } }) => colors.accent};
			}
		}
    }
`;

export default KeraltyRadio;
