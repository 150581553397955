import { useCallback, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import ImmunizationTab from './ImmunizationTab';
import useAuth from '../../../hooks/useAuth';

//Actions
import { getCurrentMedication, cleanError } from 'adapter/myHealth/myHealthSlice';
import { selectmyHealthImmunizations } from 'adapter/myHealth/myHealthSelectors';


const Immunizations = () => {
	// @TODO: Each row should be a route, but for now this works
	const [activeRow, setActiveRow] = useState<number | undefined>();
	const { user } = useAuth();

	const currentInfo = useAppSelector(selectmyHealthImmunizations);
	//const asyncError = useAppSelector(selectmyHealthError);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getCurrentMedication(user.idEcw));
		console.log("por aqui estoy",currentInfo)
		return () => dispatch(cleanError());
	}, [dispatch, user.idEcw])

	const resetActiveRow = useCallback(() => {
		setActiveRow(undefined);
	}, []);

	return (

		<ImmunizationTab currentData={currentInfo || []} onClickItem={setActiveRow} />
	);
};

export default Immunizations;
