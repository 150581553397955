import { openBookAppointment } from 'adapter/chat/chatSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import chatSelectors from '../../../adapter/chat/chatSelectors';
import { chatActions } from '../../../adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../../../adapter/hooks';
import arrowAltCircleLeftIcon from '../../assets/icons/arrow-alt-circle-left.svg';
import appointmentIcon from '../../assets/icons/calendar_icon.svg';
import previusAppoinmentsIcon from '../../assets/images/previusAppoinmentsIcon.svg';
import upcomingAppointmentsIcon from '../../assets/images/upcomingAppointmentsIcon.svg';
import upcoming from '../../assets/icons/upcoming.svg';
import previous from '../../assets/icons/previous.svg';
import { Image, KeraltyButton, KeraltyError, KeraltyIcon, KeraltyText } from '../../components/atoms';
import { KeraltyCard, SectionWidget } from '../../components/molecules';
import PreviousAppointments from '../../components/organisms/getCare/PreviousAppt';
import UpcomingApptsTab from '../../components/organisms/getCare/upcomingApptsTab';
import { Card, CenteredWrapper, Column, Container, IconLinkRow, Loader, Row } from '../../components/templates';
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import ROUTES from '../../ui-core/utils/routes';
import theme from '../../ui-core/utils/theme';
import { useRouteQuery } from '../../hooks/useRouteQuery';
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import useIsMobile from 'app/hooks/useIsMobile';

const { MAIN, GET_CARE: CURRENT } = ROUTES;

const RightContent = styled(Container)`
  margin: 1rem 0 5rem 0;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0;
  };
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 2vh;
	padding-inline: 15px;
  }
`;

let getVal = (BREAKPOINTS.maxPhoneWidth).split("px");
let getNum = parseInt(getVal[0]) + 1;
let widthEnd = getNum.toString() + 'px'; //600px;
const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }

  @media (max-width: ${widthEnd}) {
    //display: none !important;;
	//max-width: ${BREAKPOINTS.maxPhoneWidth};
    margin-bottom: -2rem;		
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 12.75rem;
  height: auto;
  width: 28.5rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;
  	h2 {
		margin: 0rem;
		font-size: 1.5rem;
		line-height: 2.25rem;
	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const OptionColumn = styled(Column)`
  padding-right: 0;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const CardsRow = styled(Row)`
  justify-content: center;
  padding: 0rem;
`;

const Appointments: React.FC<any> = ({ isPublic, bg, tableWidth }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(chatSelectors.selectIsLoading);
	const error = useAppSelector(chatSelectors.selectError);
	const { goBack, replace } = useHistory();
	const { push } = useHistory();
	const { subTab } = useParams();
	const { query } = useRouteQuery();
	const trigger = query.get('trigger');
	const [ageWidth, setWidth] = useState(window.innerWidth);
	const isMobile = useIsMobile();


	useEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
		});
		return () => {
			window.removeEventListener("resize", () => {
				setWidth(window.innerWidth);
			})
		}
	}, []);

	const handleBookAppointment = useCallback(() => {
		console.log('handleBookAppointment');
		dispatch(openBookAppointment());
		replace({ search: '' });
	}, [dispatch, replace]);

	useEffect(() => {
		return () => {
			dispatch(chatActions.setProp({ error: undefined }));
		};
	}, [dispatch]);

	useEffect(() => {
		if (trigger === 'book-appointment') setTimeout(handleBookAppointment, 0);
	}, [trigger, handleBookAppointment]);

	const title = useMemo(
		() =>
			t(
				`getCare.${!subTab
					? 'appointments'
					: subTab === CURRENT.previousAppointments
						? 'prevAppointments'
						: 'upcomingAppointments'
				}`
			),
		[t, subTab]
	);

	const handlePrevApt = () => {
		push(`/${MAIN.getCare}/${CURRENT.appointments}/${CURRENT.previousAppointments}`);
	};

	const handleUpAppts = () => {
		push(`/${MAIN.getCare}/${CURRENT.appointments}/${CURRENT.upcomingAppointments}`);
	};

	return (
		<>
			{isLoading && <Loader />}
			<RightContent centered="x" >
				<div style={{ marginLeft: '2%', width: '100%' }}>
					<IconLinkRow
						onClick={goBack}
						color="accent"
						icon={arrowAltCircleLeftIcon}
						iconAlt={t('imgNicons.backArrow')}
						text={t('routes.back')}
					/>
				</div>

				{title && !isMobile && (
					<Row>
						<TitleContiner lg={12}>
							<KeraltyText type="title" align={isMobile?"left":"center"}>{title}</KeraltyText>
						</TitleContiner>
					</Row>
				)}
				

				{!subTab && (
					<CardsRow noMargin>
						<Column lg={0} md={0} sm={12}>
							<CenteredWrapper>
								<Card onClick={handleBookAppointment}>
									<SectionWidget
										img={appointmentIcon}
										text={capitalizeJustFirst(t('button.bookAppointment'))}
										description={t('getCare.bookAppointmentDesc')}
										alt={t('imgNicons.bookAppointmentIcon')} 
										descriptionFontSize={'12px'}
										/>
								</Card>
							</CenteredWrapper>
						</Column>
						<Column lg={0} md={0} sm={12}>
							<CenteredWrapper>
								<Card onClick={handleUpAppts}>
									<SectionWidget
										img={upcomingAppointmentsIcon}
										text={capitalizeJustFirst(t('getCare.upcomingAppointments'))}
										description={t('getCare.upcomingAppointmentsDesc')}
										alt={t('imgNicons.locationIcon')} 
										descriptionFontSize={'12px'}
										/>
								</Card>
							</CenteredWrapper>
						</Column>
						<Column lg={0} md={0} sm={12}>
							<CenteredWrapper>
								<Card onClick={handlePrevApt}>
									<SectionWidget
										img={previusAppoinmentsIcon}
										text={capitalizeJustFirst(t('getCare.prevAppointments'))}
										description={t('getCare.prevAppointmentsDesc')}
										alt={t('imgNicons.locationIcon')} 
										descriptionFontSize={'12px'}
										/>
								</Card>
							</CenteredWrapper>
						</Column>

						<OptionColumn lg={3} md={4} sm={0} >
							<Row style={{ paddingInline: '25%' }}>

								<GetCareNowButton color="light" squareRadius={true} onClick={handleBookAppointment}>
									<span>
										<Image
											src={appointmentIcon}
											width={theme.sizes.icon.big}
											height={theme.sizes.icon.big}
											alt={t('imgNicons.bookAppointmentIcon')}
										/>

										<KeraltyText type="title" fontWeight={'400'} style={{ fontWeight: 'normal', fontSize: '16px' }}>{t('button.bookAppointment')}</KeraltyText>
										<KeraltyText type="title" color={'#747474'} style={{ color: '#7A7A7A', fontSize: '12px', lineHeight: '18px' }}>{capitalizeJustFirst(t('getCare.bookAppointmentDesc'))}</KeraltyText>
									</span>
								</GetCareNowButton>

							</Row>
						</OptionColumn>
						
						<OptionColumn lg={3} md={4} sm={0} >
							<Row style={{ paddingInline: '25%' }}>

								<GetCareNowButton color="light" squareRadius={true} onClick={handleUpAppts}>
									<span>
										<Image
											src={upcoming}
											width={theme.sizes.icon.big}
											height={theme.sizes.icon.big}
											alt={t('imgNicons.bookAppointmentIcon')}
										/>

										<KeraltyText type="title" fontWeight={'400'} style={{ fontWeight: 'normal', fontSize: '16px' }}>{t('getCare.upcomingAppointments')}</KeraltyText>
										<KeraltyText type="title" color={'#747474'} style={{ color: '#7A7A7A', fontSize: '12px', lineHeight: '18px' }}>{capitalizeJustFirst(t('getCare.upcomingAppointmentsDesc'))}</KeraltyText>
									</span>
								</GetCareNowButton>

							</Row>
						</OptionColumn>
						
						<OptionColumn lg={3} md={4} sm={0} >
							<Row style={{ paddingInline: '25%' }}>

								<GetCareNowButton color="light" squareRadius={true} onClick={handlePrevApt}>
									<span>
										<Image
											src={previous}
											width={theme.sizes.icon.big}
											height={theme.sizes.icon.big}
											alt={t('imgNicons.bookAppointmentIcon')}
										/>

										<KeraltyText type="title" fontWeight={'400'} style={{ fontWeight: 'normal', fontSize: '16px' }}>{t('getCare.prevAppointments')}</KeraltyText>
										<KeraltyText type="title" color={'#747474'} style={{ color: '#7A7A7A', fontSize: '12px', lineHeight: '18px' }}>{capitalizeJustFirst(t('getCare.prevAppointmentsDesc'))}</KeraltyText>
									</span>
								</GetCareNowButton>


							</Row>
						</OptionColumn>
						<Column lg={12}>
							{error && <KeraltyError error={t('errors.somethingWentWrong')}></KeraltyError>}
						</Column>
					</CardsRow>
				)}

				{subTab === CURRENT.previousAppointments && <PreviousAppointments titleFather={ageWidth >= 601 ? null : title} />}
				{subTab === CURRENT.upcomingAppointments && <UpcomingApptsTab titleFather={ageWidth >= 601 ? null : title} />}
			</RightContent>
		</>
	);
};

export default Appointments;
