import { useTranslation } from 'react-i18next';

import styled from '../../../ui-core/styled-components';
import { COLOR_GREEN_DC1 } from '../../../ui-core/styles/colors';

import { ReactComponent as LessIcon } from 'app/assets/icons/less-icon.svg';
import { ReactComponent as PlusIcon } from 'app/assets/icons/plus-icon.svg';

import { useAppSelector, useAppDispatch } from 'adapter/hooks';

import {
	closeSymptomChecker,
	minimizeChat as minimizeChatAction,
	showChat,
} from 'adapter/chat/chatSlice';

interface chatHeaderProps {
	children?: any;
}

const ChatHeader = styled.div<chatHeaderProps>`
  background: ${COLOR_GREEN_DC1};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
const Chat = () => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const symptomCheckerState = useAppSelector((state) => state.chat.symptomChecker);

	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	const isLSLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'lifeStyleLib');
	const { isMinimized, isOpen, isLoading } = symptomCheckerState;

	const minimizeChat = () => {
		if (!isMinimized) {
			dispatch(minimizeChatAction())
			return;
		}

		dispatch(showChat());
	};

	const closeChat = () => {
		dispatch(closeSymptomChecker());
	};

	const content = (
		<div className="sensely-content_filled">
			<div id="sensely-custom_header">
				<ChatHeader className="bg-container">
					<div className="profile-pic">
						<span></span>
					</div>
					<span className="sensely-custom-title">
						{t(`${isVAOpen ? 'virtualAssistant' : (isSCOpen ? 'symptomChecker' : (isLSLOpen ? 'WellnessLibrary' : 'healtlibrary'))}.title`)}
					</span>
					<div className="sensely-custom-controls">
						<span className="sensely-header-icon-container" onClick={minimizeChat}>
							<LessIcon />
						</span>
						<span className="sensely-header-icon-container" onClick={closeChat}>
							<PlusIcon />
						</span>
					</div>
				</ChatHeader>
				{/* <ChatHeaderBg className="sensely-custom-header-bg" /> */}
			</div>
			<div
				style={{ display: !isOpen && isLoading ? 'none' : 'block' }}
				id="sensely-widget"
			></div>
		</div>
	);


	return (
		<div
			id="sensely-custom_wrapper"
			className={
				isMinimized && isOpen ? 'chat-minimized' : !isOpen && !isMinimized ? 'chat-hidden' : ''
			}>
			{isLoading && <div className="sensely-chat-loader"> loading...</div>}
			{content}
		</div>
	);
};

export default Chat;
