const nsForgotPassword = {
  en: {
    recoverPassword: 'Recover Password',
    change: 'Change your password',
    personalInfo: 'Personal information',
    recoverInfo: 'Verification',
    newPassword: 'New Password',
    password: 'Password',
    confirmNewPassword: 'Confirm your new password',
    instructions: 'Select the mobile number or email where you want to receive the verification code.',
    instructions2: 'By clicking in one of the following options you are accepting to be contacted by email or by phone via a text message.',
    selectEmail: 'Select the email',
    verifyOption: 'Please check your selected option to reset your password.',
    success: 'Your password has been updated',
    infoChangePassword: 'We find that your email is registered to more than one State. Please select at least one State for which you want to change the password',
    dateofbirth:'Date of birth', 
    mobile:'Mobile', 
    error:'The verification code is not correct. Please try again',
    errorRequired:'This field is required.'

  },
  es: {
    recoverPassword: 'Recuperar contraseña',
    change: 'Cambiar contraseña',
    personalInfo: 'Información Personal',
    recoverInfo: 'Verificación',
    newPassword: 'Crear contraseña',
    password: 'Contraseña',
    confirmNewPassword: 'Confirma tu contraseña nueva',
    instructions:'Selecciona el número de móvil o correo electrónico donde deseas recibir el código de verificación.',
    instructions2: 'Al elegir una de las opciones estás accediendo a ser contactado por teléfono o correo electrónico via mensaje de texto.',
    selectEmail: 'Selecciona el correo electrónico',
    verifyOption: 'Verifica la opción seleccionada para recuperar tu contraseña',
    success: 'Tu contraseña ha sido actualizada',
    infoChangePassword: 'Encontramos que su correo electrónico está registrado en varios Estados, por favor seleccione al menos un Estado para el cual necesitas cambiar la contraseña',
    dateofbirth:'Fecha de Nacimiento',
    mobile:'Celular',
    error:'El código de seguridad es incorrecto. Por favor inténtalo de nuevo',
    errorRequired:'Este campo es requerido.'
  },
};

export default nsForgotPassword;
