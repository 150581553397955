import styled from 'app/ui-core/styled-components';
import { AlertStyled } from './Alert.styled';
import { AlertProps } from './Alert.type';
import Image from '../image';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import circleExclamation from '../../../assets/icons/circle-exclamation2.svg';
import { useState } from 'react';
import useIsMobile from 'app/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

const IconExclamation = styled(Image)`
  width: 34px;
  cursor: initial !important;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 24px;
  }
`;

export const Alert = ({
  type,
  message,
  isDefault = true,
  isSmall = false,
  fullWidth = false,
  tittleAlert,
  close
}: AlertProps) => {

  const [visible, setVisible] = useState(true)
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <>
       {
        visible &&
          <AlertStyled
            isDefault={isDefault}
            isSmall={isSmall}
            defaultColor={'red'}
            mainColor={'#E7A304'}
            secundaryColor={'#f0d9a3'}
            className="box"
            fullWidth={fullWidth}
          >
            <div className="border-left" />
            <div className="content">
                <div className="icon-content">
                  <IconExclamation src={circleExclamation} alt="" style={{ width: '41.67px', height: '41.67px' }} />
                </div>
                <span>
                  {tittleAlert && (
                    <>
                      {tittleAlert}
                      <br />
                    </>
                  )}

                    <section dangerouslySetInnerHTML={{__html: `${message}`}}></section>
                    <section 
                      className="close-option" 
                      style={{ color: 'black', cursor: 'default' }}
                    >
                      <label 
                        onClick={ () => {
                          setVisible(false)
                          close?.()
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {t('button.close')}
                      </label>
                    </section>
              
                </span>
            </div>
        </AlertStyled>
      }
    </>
  );
};
