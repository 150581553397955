//import baseConfig from './base.config.json';
import baseConfig from './base.config.json';
import nodeKeys from './node_keys.json';

const getValues = (configObj, dataObj) => {
  for (const prop in configObj) {
    const envName = configObj[prop];
    if (typeof envName === 'string' || envName instanceof String) {
      const envValue = process.env[envName as string];
      if (envValue) dataObj[prop] = envValue;
    } else if (envName && dataObj[prop]) {
      getValues(envName, dataObj[prop]);
    } else {
      dataObj[prop] = {};
      getValues(envName, dataObj[prop]);
    }
  }
};

getValues(nodeKeys, baseConfig);
if (window.location.search.includes('showEnvInfo')) {
  //console.log({ baseConfig });
  //console.log({ env: process.env });
}
export default baseConfig;
