import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'adapter/store';
import { ChatState } from './chatState';
import Chat from 'domain/useCases/chat';
import widgetUseCase from '../../domain/useCases/widget';
import userInitialState from '../user/initialState';
import VirtualAssistantRepository from '../../infrastructure/sensely/virtualAssistant';
import i18n from 'i18next';
import BookAppointmentRepository from '../../infrastructure/vim/bookAppointment';
import AppointmentsRepository from '../../infrastructure/keraltyApi/repositories/appointment';
import baseReducer from '../base/baseReducer';
import { baseActions } from '../base/baseActions';
import HealtLibraryRepository from 'infrastructure/sensely/HealtLibrary';
import LifeStyleLibRepository from 'infrastructure/sensely/LifeStyleLib';

const language: 'en' | 'es' = i18n.language?.includes('en') ? 'en' : 'es';


const initialState: ChatState = {
	currentInstance: '',
	isOpen: false,
	//isLoading: false,
	isInitialized: false,
	isMinimized: false,
	isLoading: false,
	error: undefined,
	// VENDORS TODO: REMOVE "symptomChecker" SUB STATE
	// IN ORDER TO HANDLE ALL VENDORS BEHAVIOR WITH THE PREVIOUS PROPERTIES
	symptomChecker: {
		isOpen: false,
		isPublic: false,
		isLoading: false,
		isInitialized: false,
		isMinimized: false,
		lng: language
	}
};

export const ChatInstance = new Chat();

export const openSymptomChecker = createAsyncThunk(
	'chat/initChat',
	async (payload: ChatState['symptomChecker']): Promise<any> => {
		const response = ChatInstance.initChat(payload);
		return response;
	}
);

export const openVirtualAssistant = createAsyncThunk(
	'chat/openVirtualAssistant', async (payload, { getState }) => {
		// VENDORS TODO: DISPATCH AN ACTION TO CLOSE ALL OPEN WIDGETS
		// BEFORE EACH "openXXX" ACTION
		const userState: typeof userInitialState = (getState() as RootState).user;
		const { birthdate, sex, idEcw, authUid, lng } = userState;
		return await widgetUseCase.loadVirtualAssistant(
			VirtualAssistantRepository,
			{ lang: i18n.language.includes('en') ? 'en' : 'es' || lng as any, birthdate, sex, idEcw, authUid }
		);
	}
);

export const openLifeStyleLib = createAsyncThunk(
	'chat/openLifeStyleLib', async (payload, { getState }) => {
		// VENDORS TODO: DISPATCH AN ACTION TO CLOSE ALL OPEN WIDGETS
		// BEFORE EACH "openXXX" ACTION
		const userState: typeof userInitialState = (getState() as RootState).user;
		const { birthdate, sex, idEcw, authUid, lng } = userState;
		return await widgetUseCase.loadLifeStyleLib(
			LifeStyleLibRepository,
			{ lang: i18n.language.includes('en') ? 'en' : 'es' || lng as any, birthdate, sex, idEcw, authUid }
		);
	}
);

export const openHealtLibrary = createAsyncThunk(
	'chat/openHealtLibray', async (payload, { getState }) => {
		// VENDORS TODO: DISPATCH AN ACTION TO CLOSE ALL OPEN WIDGETS
		// BEFORE EACH "openXXX" ACTION
		const userState: typeof userInitialState = (getState() as RootState).user;
		const { birthdate, sex, idEcw, authUid, lng } = userState;
		return await widgetUseCase.loadHealtLibrary(
			HealtLibraryRepository,
			{ lang: i18n.language.includes('en') ? 'en' : 'es' || lng as any, birthdate, sex, idEcw, authUid }
		);
	}
);

export const openBookAppointment = createAsyncThunk(
	'chat/openBookAppointment', async (payload, { getState, dispatch }) => {
		const userState: typeof userInitialState = (getState() as RootState).user;
		const { lng, idEcw, firstName, lastName, birthdate, email, mobile, isLoggedIn, state } = userState;

		return await widgetUseCase.loadBookAppointment(
			BookAppointmentRepository,
			AppointmentsRepository,
			{
				lang: i18n.language.includes('en') ? 'en' : 'es' || lng as any,
				idEcw, firstName, lastName, birthdate, email, mobile, isLoggedIn, state,
				onClose: () => dispatch(closeSymptomChecker())
			}
		);
	}
);

export const resetSymptomChecker = (lang) => {
	openSymptomChecker(lang);
};

export const ChatSlice = createSlice({
	// name: 'vendors' | 'widgets'...
	name: 'chat',
	initialState,
	reducers: {
		closeSymptomChecker: (state) => {
			state.currentInstance = '';
			state.isLoading = false;
			state.error = undefined;
			state.isInitialized = false;
			state.isMinimized = false;
			state.isOpen = false;
			state.symptomChecker.isPublic = false;
			state.symptomChecker.isOpen = false;
			state.symptomChecker.isInitialized = false;
			state.symptomChecker.isMinimized = false;
		},
		reInitializeSymptonChecker: (state) => {
			resetSymptomChecker(state.symptomChecker.lng);
		},
		showChat: (state) => {
			state.symptomChecker.isMinimized = false;
		},
		minimizeChat: (state) => {
			state.symptomChecker.isMinimized = true;
		},
		...baseActions<ChatState>()
	},
	extraReducers: (builder) => {
		builder
			.addCase(openSymptomChecker.pending, (state) => {
				state.currentInstance = 'symptomChecker';
				state.symptomChecker = {
					...state.symptomChecker,
					isLoading: true,
					isInitialized: false,
					isOpen: true,
				};
			})
			.addCase(openSymptomChecker.fulfilled, (state, action: any) => {
				state.symptomChecker = {
					...state.symptomChecker,
					...action.payload,
					isLoading: false,
					isInitialized: true,
					isOpen: true,
				};
			})
			.addCase(openSymptomChecker.rejected, (state, action: any) => {
				state.currentInstance = '';
				state.symptomChecker = {
					...state.symptomChecker,
					isLoading: false,
					isInitialized: false,
					isOpen: false,
				};
			})
			.addCase(openVirtualAssistant.pending, (state) => {
				state.currentInstance = 'virtualAssistant';
				state.isLoading = true;
				state.isInitialized = false;
				state.isOpen = true;
				// VENDORS TODO: REPLACE ALL SELECTORS WHERE "state.symptomChecker.XXX" IS USED
				// AFTER THAT WE WONT NEED BELOW SUB STATE ASSIGNEMENTS
				state.symptomChecker.isLoading = true;
				state.symptomChecker.isInitialized = false;
				state.symptomChecker.isOpen = true;
			})
			.addCase(openVirtualAssistant.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isInitialized = true;
				// VENDORS TODO: SAME
				state.symptomChecker = {
					...state.symptomChecker,
					...action.payload,
					isLoading: false,
					isInitialized: true
				};
			})
			.addCase(openVirtualAssistant.rejected, (state) => {
				state.currentInstance = '';
				state.isLoading = false;
				state.isInitialized = false;
				state.isOpen = false;
				// VENDORS TODO: SAME
				state.symptomChecker.isLoading = false;
				state.symptomChecker.isInitialized = false;
				state.symptomChecker.isOpen = false;
			})

			.addCase(openLifeStyleLib.pending, (state) => {
				state.currentInstance = 'lifeStyleLib';
				state.isLoading = true;
				state.isInitialized = false;
				state.isOpen = true;
				// VENDORS TODO: REPLACE ALL SELECTORS WHERE "state.symptomChecker.XXX" IS USED
				// AFTER THAT WE WONT NEED BELOW SUB STATE ASSIGNEMENTS
				state.symptomChecker.isLoading = true;
				state.symptomChecker.isInitialized = false;
				state.symptomChecker.isOpen = true;
			})
			.addCase(openLifeStyleLib.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isInitialized = true;
				// VENDORS TODO: SAME
				state.symptomChecker = {
					...state.symptomChecker,
					...action.payload,
					isLoading: false,
					isInitialized: true
				};
			})
			.addCase(openLifeStyleLib.rejected, (state) => {
				state.currentInstance = '';
				state.isLoading = false;
				state.isInitialized = false;
				state.isOpen = false;
				// VENDORS TODO: SAME
				state.symptomChecker.isLoading = false;
				state.symptomChecker.isInitialized = false;
				state.symptomChecker.isOpen = false;
			})

			.addCase(openHealtLibrary.pending, (state) => {
				state.currentInstance = 'healtLibrary';
				state.isLoading = true;
				state.isInitialized = false;
				state.isOpen = true;
				// VENDORS TODO: REPLACE ALL SELECTORS WHERE "state.symptomChecker.XXX" IS USED
				// AFTER THAT WE WONT NEED BELOW SUB STATE ASSIGNEMENTS
				state.symptomChecker.isLoading = true;
				state.symptomChecker.isInitialized = false;
				state.symptomChecker.isOpen = true;
			})
			.addCase(openHealtLibrary.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isInitialized = true;
				// VENDORS TODO: SAME
				state.symptomChecker = {
					...state.symptomChecker,
					...action.payload,
					isLoading: false,
					isInitialized: true
				};
			})
			.addCase(openHealtLibrary.rejected, (state) => {
				state.currentInstance = '';
				state.isLoading = false;
				state.isInitialized = false;
				state.isOpen = false;
				// VENDORS TODO: SAME
				state.symptomChecker.isLoading = false;
				state.symptomChecker.isInitialized = false;
				state.symptomChecker.isOpen = false;
			})

			//#region BookAppointment
			.addCase(openBookAppointment.pending, (state) => {
				state.currentInstance = 'bookAppointment';
				state.isInitialized = false;
				state.isOpen = true;
				baseReducer.pending(state);
			})
			.addCase(openBookAppointment.fulfilled, (state) => {
				state.isInitialized = true;
				baseReducer.fullfilled(state);
			})
			.addCase(openBookAppointment.rejected, (state, action) => {
				state.currentInstance = '';
				state.isLoading = false;
				state.isInitialized = false;
				state.isOpen = false;
				baseReducer.rejected(state, action);
			})
		//#endregion
	},
});

export const { closeSymptomChecker, showChat, reInitializeSymptonChecker, minimizeChat } =
	ChatSlice.actions;

export const chatActions = {
	...ChatSlice.actions,
	openVirtualAssistant,
	openBookAppointment,
	openHealtLibrary,
	openLifeStyleLib
};


export const chat = (state: RootState) => state.chat;

export default ChatSlice.reducer;
