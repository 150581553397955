const nsHome = {
  en: {
    hi: 'Hi ',
    welcome: "Welcome, we're glad you're here. Where do you want to take your health today?",
    banner: 'Always on. ALWAYS BY YOUR SIDE',
    bannerDesc:
      "At Sanitas, we've reimagined care to fit into your lifestyle-not the other way around. Because when you don't feel good, life's just not right.",
    careTitle: 'My care team',
    findADoctor: 'Find a Doctor',
    findALocation: 'Find a Location',
    findCenter: 'Find a medical center nearby clicking below.',
    locationNServices: 'Location and Services',
    buttonDirects: 'This button directs to the Mysanitas website.',
    languageSelector: 'EN',
    yourId: 'Your Sanitas account number is: ',
    modalDesc: 'It’s time for your Annual visit. Let’s do it now!',
    modalRemember: 'Remind me later',
    modalButton: 'START ANNUAL VISIT',
    modalAnnual: 'I already had my Annual visit',
    termAndPrivacy: {
      term: "Our Terms and Conditions have changed",
      privacy: "Our Privacy Policy has changed",
      tAndP: "Our Terms and Conditions and our Privacy Policy have changed",
      changeOne: "the new version",
      changeOne1: "Please accept ",
      changeOne2: " to continue",
      changeTwo: "the new version",
      changeTwo1: "Please accept ",
      changeTwo2: " to continue"
    }
  },
  es: {
    hi: 'Hola ',
    welcome: 'Bienvenido, estamos felices de que estés aquí. ¿A dónde quieres llevar tu salud hoy?',
    banner: 'Siempre en línea. Siempre a tu lado',
    bannerDesc:
      'En Sanitas, hemos reinventado la atención para que encaje en tu estilo de vida, y no al revés. Porque cuando no te sientes bien, la vida simplemente no está bien.',
    careTitle: 'Mi equipo de atención',
    findADoctor: 'Encontrar doctor',
    findALocation: 'Encontrar Sede',
    findCenter: 'Encuentra un centro médico cerca tuyo.',
    locationNServices: 'Sedes y servicios',
    buttonDirects: 'Este botón te dirige a la página de Mysanitas',
    languageSelector: 'ES',
    yourId: 'Tú número de cuenta Sanitas es: ',
    modalDesc: 'Es hora de tu Visita Anual. ¡Empecemos!',
    modalRemember: 'Recuérdame más tarde',
    modalButton: 'INICIAR VISITA ANUAL',
    modalAnnual: 'Ya tuve mi visita anual',
    termAndPrivacy: {
      term: "Nuestros Términos y Condiciones han cambiado",
      privacy: "Nuestra Política de privacidad ha cambiado",
      tAndP: "Nuestros Términos y Condiciones y nuestra Política de privacidad han cambiado",
      changeOne: "nueva versión",
      changeOne1: "Por favor acepta la ",
      changeOne2: " para poder continuar",
      changeTwo: "las nuevas versiones",
      changeTwo1: "Por favor acepta ",
      changeTwo2: " para poder continuar"
    }
  },
};


export default nsHome;
