import { useCallback, useRef } from 'react';
//Adapter layer
import { useAppSelector, useAppDispatch } from 'adapter/hooks';
import { persistor } from 'adapter/store';
// Interaction Layer
import { ITryLogin, ITrySSOLogin, ITryLogout } from 'app/interactions/IUser';
import { closeSymptomChecker } from 'adapter/chat/chatSlice';
import { cleanState } from 'adapter/myAccount/myAccountSlice';
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { cleanStateMyHealthSlice } from 'adapter/myHealth/myHealthSlice';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import { KeraltySWS } from './useKeraltySWS';
import AuthRepository from 'infrastructure/keraltyApi/repositories/auth';

function useAuth() {
  const user = useAppSelector((state) => state.user);
  const chatState = useAppSelector((state) => state.chat.symptomChecker);
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState } = sanitasChatState;

  const isSymptonCheckerOpen = chatState.isOpen;
  const { isLoggedIn, hasError } = user;
  const dispatch = useAppDispatch();

  const login = useCallback(
    async (email, password, state) => {
      try {
        await ITryLogin(email, password, state, dispatch);
        if (isSymptonCheckerOpen) {
          await dispatch(closeSymptomChecker());
        }
        if (sanitasState.isShow) {
          await dispatch(sanitasAdapterMethods.shutdown());
        }
        dispatch();
      } catch (err) {
        //   setError('Oops, invalid username or password!');
        // throw err('error trying to login --> ', err);
      }
    },
    [dispatch, isSymptonCheckerOpen, sanitasState]
  );

  const ssoLogin = useCallback(
    async (token) => {
      return await ITrySSOLogin(token, dispatch);
    },
    [dispatch]
  );

  const { token: bearer, state } = useAppSelector(state => state.user)
  const logout = useCallback(async () => {
    try {
      const response = await AuthRepository.logoutRequest(bearer, state);
      KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.IS_PRIVATE);
      ITryLogout(dispatch);
      if (isSymptonCheckerOpen) {
        dispatch(closeSymptomChecker());
      }
      dispatch(cleanStateMyHealthSlice());
      dispatch(cleanState());
      persistor.purge();

    } catch (error) {
      console.error("Error service logout:", error);
    }
  }, [dispatch, isSymptonCheckerOpen, state]);

  return {
    user,
    isLoggedIn,
    login,
    ssoLogin,
    logout,
    hasError,
  };
}
export default useAuth;
