import { UserIdentifier as UserIdentifierEntity } from '../entities/userIdentifier';
import { EmailIdentifier as EmailIdentifierEntity } from '../entities/emailIdentifier';
import { ResetPassword as ResetPasswordEntity } from '../entities/resetPassword';

import { UnblockAccountService } from './contracts/unblockAccountService';

/**
 * Unblock Account | Step 01 | State info validation
 */
 export const validateStateInfo = (
  service: UnblockAccountService,
  data: UserIdentifierEntity
) => {
  return service.validateStateExistence(data);
};

/**
 * Unblock Account | Step 02 | Send the recover code to the user email or SMS
 */
export const sendRecoverPassEmail = (
  service: UnblockAccountService,
  data: EmailIdentifierEntity
) => {
  delete data.id
  return service.sendRecoverEmail(data);
};

/**
 * Unblock Account | Step 03 | Change Password
 */
export const changePassword = (
  service: UnblockAccountService,
  data: ResetPasswordEntity
) => {
  return service.changePassword(data);
};

const unblockAccountUseCases = {
  sendRecoverPassEmail,
  changePassword,
  validateStateInfo
}

export default unblockAccountUseCases;
