import { useHistory } from 'react-router-dom';

import { Container, Row, Column } from 'app/components/templates';
import { KeraltyText, KeraltyButton, Image } from 'app/components/atoms';

import { useTranslation } from 'react-i18next';
import useAuth from 'app/hooks/useAuth';

import MobileImageNotFound from 'app/assets/images/mobile-image-not-found.svg';
import { ReactComponent as NotFoundImage } from 'app/assets/images/not-found-image.svg';

import styled from 'app/ui-core/styled-components';

import BREAKPOINTS from 'app/ui-core/utils/breakpoints';

// ROUTES
import ROUTES from 'app/ui-core/utils/routes';

const MainContainer = styled(Container)`
  height: 100vh;
  display: flex;
`;

const Content = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
`;

const GoToBackButton = styled(KeraltyButton)`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const NotFoundTitle = styled(KeraltyText)`
  font-size: 2.1rem;
  width: 100%;
`;

const NotFoundSubTitle = styled(KeraltyText)`
  font-size: 1.1rem;
  width: 100%;
  margin-bottom: 3.33rem;
`;

const ImageContainer = styled(Column)`
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: -10rem;

  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 4.83rem;
  }
`;

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const history = useHistory();

  const handleGoToHomePage = () => {
    isLoggedIn ? history.push(`/${ROUTES.MAIN.home}`) : history.push(`/${ROUTES.MAIN.login}`);
  };

  return (
    <MainContainer max noPadding>
      <Content noMargin>
        <Content>
          <NotFoundTitle type="title" color="primary" bold weight="700">
            {t('notFound.pageNotFound')}
          </NotFoundTitle>
          <NotFoundSubTitle type="subTitle" color="black" bold weight="400">
            {t('notFound.pageNotFoundSubTitle')}
          </NotFoundSubTitle>
          <Content>
            <GoToBackButton onClick={handleGoToHomePage}>{t('button.backToHome')}</GoToBackButton>
          </Content>
          <ImageContainer sm={0}>
            <NotFoundImage></NotFoundImage>
          </ImageContainer>
          <ImageContainer lg={0} md={0} sm={5}>
            <Image src={MobileImageNotFound}></Image>
          </ImageContainer>
        </Content>
      </Content>
    </MainContainer>
  );
};

export default NotFoundPage;
