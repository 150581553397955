const nsFinancialPolicy = {
	en: {
	financialPolicy: 'Financial policy',
        fees: 'The doctors and the healthcare providers of Sanitas charge fees for the care provided to you. The fees may not be exactly the same as the estimate given. This includes any deductibles and coinsurances.',
        copays: 'Copays are due at time of service. You are also responsible for any deductibles and coinsurances on your insurance plan. In the event your health insurance company does not pay the full amount of fees charged by Sanitas, you (patient or responsible party) would have to pay Sanitas for the cost of care not paid by the health insurance company. This also applies for patients within their grace period.',
        incorrectData: 'If the insurance information provided to Sanitas is not correct, you may have to pay the fees for your care. If you do not have health insurance, then you will have to pay the fees for the medical services provided.',
        medicare: 'Medicare will only pay for the care that is acceptable and needed under section 19862 (a) (1) of the Medicare Law. By signing below, you certify the facts you have given to Sanitas for payment under Tittle XVIII and XIX of the Social Security Act are correct. Sanitas can bill my health insurance company for my care. Payments will be made to Sanitas on my behalf.',
	},
	es: {
        financialPolicy: 'Política financiera',
        fees: 'Los médicos y el equipo médico de Sanitas Medical Centers deben cobrar por los servicios de salud brindados a los pacientes. Las tarifas pueden variar de acuerdo al tratamiento médico y pueden ser diferentes a las ofrecidas en el estimado inicial. Esto incluye cualquier deducible y coaseguro/costo compartido.',
        copays: 'Los copagos son responsabilidad del paciente y deben ser pagados al moment de la cita o de recibir el servicio. Lo mismo aplica para cualquier deducible y coaseguro de acuerdo al plan de seguro.',
        incorrectData: 'En el caso de que su compañía de seguros de salud no pague el monto total de las tarifas cobradas por Sanitas, usted (el paciente o la parte responsable) deberá realizar los pagos a los centros medicos de Sanitas por el costo de la atención no pagado por la aseguradora. Esto también aplica a los pacientes dentro del periodo de gracia. Si la información de seguro proporcionada a Sanitas no es correcta, es posible que reciba una cuenta de cobro por el valor de los servicios recibidos. Si no tiene Seguro médico, deberá pagar los costos de los servicios médicos brindados al momento de su visita.',
        medicare: 'Medicare solo pagará por el valor aceptado y regulado según la sección 19862 (a) (1) de La Ley de Medicare. Al firmar a continuación, usted certifica que la información que le ha dado a Sanitas para el pago en virtud del título XVIII y XIX de la Ley de Seguridad Social es correcta. Autorizo a Sanitas Medical Center facturarle a mi compañía de seguros de salud por mi atención. Los pagos se harán a Sanitas en mi favor.',
	}
};

export default nsFinancialPolicy;
