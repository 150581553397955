import axios from 'axios';
import appConfig from 'config';

export interface HttpResponse<T> {
	success: boolean;
	data?: T | boolean;
	error?: string;
};

/**
 * HTTP CLIENT instance for non-authenticated API calls
 * TEMP: THIS INSTANCE USES A HARCODED (STATIC) AUTHORIZATION TOKEN
 */
const ChatHttpClient = axios.create({
	baseURL: `${appConfig.CON_CHAT}/`,
	timeout: 90000,
	headers: {
		'Content-Type': 'application/json',
	},
});

ChatHttpClient.interceptors.request.use(
	(config) => {
		return config;
	}
)

ChatHttpClient.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		return Promise.reject({
			name: error?.response?.config?.url || 'No Network Connection',
			message: String(error?.response?.data?.code || 999),
			code: error?.response?.status ? String(error?.response?.status) : 0,
		});
	}
);

export default ChatHttpClient;
