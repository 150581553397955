import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';

interface RowProps {
	pl?: boolean;
	mt?: number;
	noMargin?: boolean;
	fullHeight?: boolean;
	fullHeightChildren?: boolean;
	bg?: keyof typeof theme.colors;
	stretch?: boolean;
  televist?: boolean;
  wrap?: boolean;
  alingItemsCenter?: boolean;
}

const Row = styled.div<RowProps>`
  width: 100%;
  display: inline-flex;
  flex-wrap: ${({ wrap }) => (wrap ? 'nowrap' : 'wrap')};
  ${({ alingItemsCenter }) => alingItemsCenter ? 'align-items: center;' : ''}
  margin: ${({ noMargin }) => (noMargin ? '0' : '1rem 0')};
  ${({ fullHeight, noMargin }) =>
		fullHeight ? `height: calc(100% - ${!noMargin ? '2rem' : '0px'});` : ''}
  &>div {
    ${({ fullHeightChildren }) => (fullHeightChildren ? 'height: 100%;' : '')}
    display: flex;
    align-items: ${({ stretch }) => (stretch ? 'stretch' : 'flex-start')};
  }

  ${({ televist }) => televist ? `flex-direction: column !important; aling-items: center !important;` : ''}
  ${({ mt }) => mt ? `margin-top: ${mt}rem;` : ''}
  ${({ pl }) => pl ? 'margin-left: 1rem;' : ''}

  ${({ noMargin }) => (noMargin ? 'margin: 0px;' : '')}

	background-color:  ${({ bg, theme: { colors } }) => (bg ? colors[bg] : 'transparent')};
`;

export default Row;
