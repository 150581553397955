// 3RD PARTY
import { useTranslation } from 'react-i18next';
// UI
import React from 'react';
import { KeraltyIcon } from '../atoms';
import { Container, SpaceWrapper } from '../templates';

type Props = {
  cellText: string;
  onClick: (idx?: number, cellText?: string) => void;
  hasIcon?: undefined | boolean;
  iconName?: string;
  // Ideally we should have an Id, but for now it works
  idx?: number;
};

const SeeIconCell: React.FC<Props> = ({ cellText, onClick, idx, hasIcon, iconName }) => {
  const { t } = useTranslation();
  const handleCellClick = () => {
    onClick(idx, cellText);
  };

  return (
    <Container centered={'y'} noPadding>
      {cellText}
      <SpaceWrapper ml={1.5}>
        {hasIcon && (
          <KeraltyIcon
            iconClass={iconName ? iconName : 'fas fa-eye'}
            title={t('imgNicons.eyeIcon')}
            color="accent"
            size="common"
            onClick={handleCellClick}
          />
        )}
      </SpaceWrapper>
    </Container>
  );
};

export default SeeIconCell;
