import { Fragment, useEffect, useMemo, useState } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import i18n from 'i18next';
// UI
import { Divider } from './atoms';
import { SectionWidget } from './molecules';
import { Column, Container, SpaceWrapper, TransitionWrapper } from './templates';
import handCareIcon from '../assets/icons/hand-care-duotone.svg';
import ThermometerIcon from '../assets/icons/thermometer-duotone.svg';
import MyHealthIcon from '../assets/icons/my-health-duotone.svg';
import CalendarIcon from '../assets/icons/calendar-duotone.svg';
import LogoutIcon from '../assets/icons/logout-duotone.svg';
// LOGIC
import useAuth from '../hooks/useAuth';
//Interaction
import { closeSymptomChecker, openSymptomChecker } from 'adapter/chat/chatSlice';
// ROUTES
import ROUTES from '../ui-core/utils/routes';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
const {
	GET_CARE: { appointments },
	MAIN: { getCare, myHealth },
} = ROUTES;

const Sidebar = ({ toggleMenu }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const { push } = useHistory();
	const { user, logout } = useAuth();
	const dispatch = useAppDispatch();
	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');

	const cards = useMemo(
		() => [
			{
				img: handCareIcon,
				alt: t('getCareIcon'),
				text: t('routes.getCare'),
				callback: () => {
					push(`/${getCare}`);
				},
			},
			/*{
				img: ThermometerIcon,
				alt: t('imgNicons.thermometerIcon'),
				text: t('routes.check'),
				callback: () => {
					if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
					dispatch(openSymptomChecker({
						isOpen: true,
						isPublic: false,
						lng: (i18n.language || '').includes('es') ? 'es' : 'en',
						userInfo: {
							dob: user.birthdate,
							gender: user.sex,
							memberid: user.idEcw,
							organization_user_id: user.idEcw,
						},
					}))
				},
			},*/
			{
				img: MyHealthIcon,
				atl: t('imgNicons.healthReportIcon'),
				text: t('routes.myHealth'),
				callback: () => {
					push(`/${myHealth}`);
				},
			},
			{
				img: CalendarIcon,
				alt: t('imgNicons.medAptIcon'),
				text: t('routes.appointments'),
				callback: () => {
					push(`/${getCare}/${appointments}`);
				},
			},
			{
				img: LogoutIcon,
				alt: t('imgNicons.logoutIcon'),
				text: t('logoutButtonText'),
				callback: () => getOut(),
			},
		],
		[t, dispatch, user, push, logout, isVAOpen, isHLOpen,]
	);

	useEffect(() => setShow(true), []);

	const handleCardCallback = (callback) => {
		callback();
		toggleMenu(false);
	};

	const getOut=()=>{
		
		 KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, 'true');
		 logout() ;
	  }

	return (
		<TransitionWrapper show={show}>
			<Container max noPadding>
				{/* Sidebar Menu */}
				<SpaceWrapper my={3}></SpaceWrapper>
				{cards.map((card, i) => (
					<Fragment key={`menu-card-${i}`}>
						<Column
							onClick={() => handleCardCallback(card.callback)}
							style={{ cursor: 'pointer' }}
							lg={6}
							md={12}
							sm={12}
							noPadding
						>
							<SectionWidget img={card.img} text={card.text} alt={card.alt} />
						</Column>
						<Divider my={2} />
					</Fragment>
				))}
			</Container>
		</TransitionWrapper>
	);
};

export default Sidebar;
