import Dropdown from "react-multilevel-dropdown";
import styled from '../../ui-core/styled-components';
import { ReactElement } from 'react';
import { KeraltyIcon } from '../atoms';

interface KeraltyNestedMenuProps {
  title: ReactElement;
  className?: string;
  items: any;
  iconClass?: string;
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  button {
    background-color: transparent !important;
  }
`;

const KeraltyNestedMenu = ({ title, className, items, iconClass }: KeraltyNestedMenuProps) => {
  return (
    <Container>
      {iconClass && <KeraltyIcon color={'accent'} iconClass={iconClass}></KeraltyIcon>}
      <Dropdown position="left" title={title}>
        {items.map((item) =>
          item.children?.length ? (
            <Dropdown.Item>
              {item.label}
              <Dropdown.Submenu position="right">
                {item.children.map((child) => (
                  <Dropdown.Item>{child.label}</Dropdown.Item>
                ))}
              </Dropdown.Submenu>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item>{item}</Dropdown.Item>
          )
        )}
      </Dropdown>
    </Container>
  );
};

export default KeraltyNestedMenu;
