import { useMemo, useState, useCallback, useEffect } from 'react';
import PrivateHttpClient from '../../../../infrastructure/keraltyApi/privateHttpClient';
// 3RD PARTY
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import { shutdown, show, setUp } from '../../../../adapter/sanitasChat/sanitasChat';

//Interaction
import { closeSymptomChecker, openBookAppointment } from '../../../../adapter/chat/chatSlice';
//LOGIC
import useAuth from '../../../hooks/useAuth';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
// UI
import TabWrapper from './TabWrapper';
import { KeraltyText, Image, KeraltyIcon } from '../../atoms';
import EmptyIcon from '../../atoms/emptyIcon';
import EmptyIcon2 from '../../atoms/emptyIcon2';
import { SeeIconCell, KeraltyFilterDate, KeraltyModal, KeraltyField, KeraltyNestedMenu } from '../../molecules';
import { Column, Container, Row, SpaceWrapper } from '../../templates';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import styled from '../../../ui-core/styled-components';
import { CurrentMedication } from 'domain/entities/currentMedication';
import useIsMobile from '../../../hooks/useIsMobile';
import FilterImg from '../../../assets/images/filter.png';
import FilterEs from '../../../assets/images/filter-es.png';
import Medical from '../../../assets/icons/medical.svg';
import Direction from '../../../assets/icons/direction.svg';
import FilterRemove from '../../../assets/images/filter-remove.png';
import FilterClose from '../../../assets/images/filter-close.png';
import PaginateBar from 'app/components/atoms/paginateBar';
import moment from 'moment';
import DownLen from '../../../assets/icons/che-down.svg';
import RightLen from '../../../assets/icons/chevron-down.svg';
import Down from '../../../assets/icons/down.svg';
import Right from '../../../assets/icons/right.svg';
import isCordova from 'cordova';
import EmptyImmunizations from '../../../assets/icons/Empty_Current.svg';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import NoResult from '../../../assets/icons/noResult.svg';
import FORMATS from 'app/ui-core/utils/formats';
import { utcTime } from 'app/ui-core/utils/times';
import { selectmyHealthError } from 'adapter/myHealth/myHealthSelectors';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';

declare global {
	interface Window {
		FB: any;
	}
}

declare global {
	interface Window {
		resolveLocalFileSystemURL: any;
	}
}
let FB = window.FB;
type Props = {
	currentData: CurrentMedication[];
	onClickItem: (idx?: number, cellText?: string) => void;
};
const MainContain = styled(Row)`
	display: contents;
	justify-content: space-around;
	align-content: center;
	align-items: center;
	background: #fff !important;
	z-index: 1;
	width: 100%;
	padding-bottom: 10%;
`;

const ContainMap = styled(Row)`
  	display: flex;
	justify-content: space-between;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);
	border-radius: 14px;
	width: 96%;
	height: 84px;
  	align-content: center;
	align-items: center !important;
	padding: 10px;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) {
		height: max-content;
	};
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 85%;
	};
`;
const ContentDiv = styled.div`

	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
	background: #3CA70D;
	border-radius: 29px;
	height:20px;
	};
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-left:-5px;
		height:20px;
		padding-inline: 5px
	}
`;
const PdfButton = styled(Column)`
	display: block !important;
  	background: #3CA70D;
	border-radius: 29px;
	color: #FFFFFF;
	width: 151px;
	height: 35px;
	text-align: center !important;
	cursor: pointer;
	font-weight: 700;
	font-size: 17px;
	line-height: 35px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 286px;
	};
	@media (max-width: 433px) {
		width: 230px;
	};
	@media (max-width: 803px){
		width: 240px;
		margin: 10px
	};
`;

const ContentetDiv = styled.div`
	@media (max-width: 803px){
		background: #f5f5f5;
	};
	width: -webkit-fill-available;
`;
const ContainInfo = styled(Column)`
	flex-direction: column;
	width: 60%;
	margin: 0px;
	@media (min-width: 1238px){
		width: 65%;
		max-height:100%;
	};
	@media (min-width: 1021px) and (max-width: 1344px){
		width: 60%;
		max-height:100%;
	};
	@media (max-width: 803px){
		width: 100%;
		max-height:100%;
	};
`;

const ContainImg = styled(Column)`
	margin-top:-5px;
	width: '20px';


	@media (min-width: 600px) and (max-width: 1028px){
		margin-right: 20px;
		margin-left: -10px;

	};
	@media (max-width: 600px){
		margin-right: 10px;
		margin-left: 21px;
	};
	@media (max-width: 500px){
		margin: 6px 10px 0 15px
	};
`;
const ContainImgMovil = styled(Column)`

		margin-left:-20px;
		align-content:end;


		`;

const Content = styled.div`
		max-height:100%;
		`;

const CardTablet = styled(Row)`
	@media (min-width: 600px) and (max-width: 1028px){
		margin-right: 20px;
		margin-left: -10px;

	};
	@media (max-width: 600px){
		margin-right: 10px;
		margin-left: 21px;
	};
	@media (max-width: 500px){
		margin: 6px 10px 0 15px
	};

`;
const Icon = styled(Row)`
	color: #055293;
  	width: 15px !important;
  	height: 15px !important;
  	margin: 0;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #055293;
	}
`;
const IconSub = styled(Row)`
	color: #055293;
  	width: 15px !important;
  	height: 15px !important;
  	margin: 0;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #055293;
	}
`;

const IconLab = styled(Row)`
	color: #48AC1C;
  	width: 40px !important;
  	height: 40px !important;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 30px !important;
  	height: 30px !important;
	}
`;

const ContainHelp = styled(Row)`
	//color: #055293;
  	width: 235px !important;
  	height: 140px !important;
  	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(20px);
	border-radius: 10px;
	position: absolute;
	margin-top: 2.5%;
	padding: 10px;
	align-content: space-between;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 7% !important;
	}
`;
const Search = styled(KeraltyField)`
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width:100%;
}
`;
const SearchWeb = styled(KeraltyField)`

	height: 90px;

`;
const EmptyIconWrap = styled.div`
  margin: 100px auto;
  position: relative;
  left: -5%;
`;

const MyTitle = styled(Column)`
	@media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
		max-width: ${BREAKPOINTS.maxTabletWidth};
	}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		max-width: ${BREAKPOINTS.maxPhoneWidth};
	}
`;


const DivContent = styled(Column)`
	width:90%;
	margin-left: 3%;

	@media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
		max-width: ${BREAKPOINTS.maxTabletWidth};
		margin-left: 5.5%;
		//background-color: yellow;
	}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		max-width: ${BREAKPOINTS.maxPhoneWidth};
		margin-left: 6%;
		width:95%;
		//background-color: pink;
	}
`;

const styleAcordding = {
	border: "none",
	boxShadow: "none"
}

const styleButton = {
	color: '#3CA70D',
	marginRight: 60,
	marginLeft: 52,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: '#FFFFFF',
	borderRadius: 40,
	border: 'none',
	borderColor: "#FFFFFF",
	fontSize: 16,
	fontWeight: 'bold',
}

const styleDiv = {
	marginTop: 0,
	paddingTop: 0,
	paddingBottom: 10,
	marginLeft: 0,
	marginRight: 0,
	flex: 1,
	align: "center",
	justifyContent: 'center',
	alignSelf: 'center',
}

const styleText = {
	textAlign: "center",
	marginRight: 7,
	marginTop: 15,
	color: "#5b5c5b",
	fontWeight: 400,
}

const styleNoResultMobile = {
	marginTop: '35%',
}

const styleNoResult = {
	backgroundColor: '#fff'
}

const CurrentMedicationTable: React.FC<Props> = ({ currentData, onClickItem }) => {
	const { t } = useTranslation();
	const [isHelp, setIsHelp] = useState(true);
	const { user } = useAuth();
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const [filter, setFilter] = useState(!isMobile);
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	const endpoint = 'ecw';
	const [cleanInput, setCleanInput] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [openPopUp, setOpenPopUp] = useState(false);
	const [search, setSearch] = useState("");
	const [isPwdVisible, setIsPwdVisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const [page, setPage] = useState(false);
	const [selected, setSelected] = useState(null);
	const [isFiltered, setIsFiltered] = useState(false);
	const { authUid } = useAppSelector(state => state.user)
	const [closeModalError, setCloseModalError] = useState(false);
	const error = useAppSelector(selectmyHealthError);
	useEffect(() => {
		if (error) setCloseModalError(error)
	}, [error])

	const data = useMemo(
		() =>
			currentData.map((currentItem, idx) => ({
				...currentItem,
				reason: <SeeIconCell idx={idx} cellText={currentItem.medicationName} onClick={onClickItem} />,
				icon: <SeeIconCell idx={idx} cellText={''} hasIcon onClick={onClickItem} />,
			})),
		[currentData, onClickItem]
	);

	useMemo(() => {
		//method in charge of cleaning form
		if (filter) setDataObject({})
	}, [filter]);

	const PopupError = () => {
		return (
			<KeraltyModal size="long"  >
				<Row noMargin>
					<SpaceWrapper mb={1}>
						<Container centered={'x'} noPadding noMargin>
							<KeraltyText type={'label'} align={'center'} style={styleText} >{t('popupPdhError')}</KeraltyText>
						</Container>
					</SpaceWrapper>
				</Row>
				<Row noMargin>
					<div style={styleDiv}>
						<button
							style={styleButton}
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setOpenPopUp(false);
							}}
						> {t('ok')}</button>
					</div>
				</Row>
			</KeraltyModal>
		);
	}

	useEffect(() => {
		setFilter(!filter);
	}, [])

	useMemo(() => {
		//method in charge of cleaning form
		if (filter) setDataObject({})
	}, [filter]);

	const onChangeDate = useCallback((type, data) => {

		setPage(false);
		console.log('falllseee');

		type && setDataObject(data);
		setIsModalVisible(false);
		setCurrPage(1)
	}, []);

	const dataToDisplay = useMemo(() => {
		//function in charge of filtering data by date range
		if (!dataObject.to || !dataObject.from) return data;
		const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
		const toDate = moment(dataObject.to, 'MM/DD/YYYY');
		setVisible(true)
		setIsFiltered(true)
		return data.filter((appointment) => {
			const currentDate = moment(appointment.startDate, 'YYYY-MM-DD');
			return currentDate.isBetween(fromDate, toDate, undefined, '[]');
		});
	}, [dataObject, data]);


	const newData = useMemo(() => {
		const indexLastPage = currPage * 5;
		const indexFirstPage = indexLastPage - 5;

		return dataToDisplay.slice(indexFirstPage, indexLastPage)
	}, [currPage, dataToDisplay]);

	const newDataMovil = useMemo(() => {
		const indexLastPage = currPage * 5;
		const indexFirstPage = indexLastPage - 5;

		return dataToDisplay.slice(indexFirstPage, indexLastPage)
	}, [currPage, dataToDisplay]);

	const lastPage = Math.ceil(dataToDisplay.length);

	const options = () => {
		setIsHelp(!isHelp)
	}

	const handleTalkToDoctor = async () => {
		dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			dispatch(shutdown());
		}
		await setTimeout(async () => {
			await dispatch(setUp({
				actionColor: undefined,
				backgroundColor: '#055293',
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'provider' },
			}));
			await dispatch(show());
		}, 500);
	};

	const triggerPatientSupport = useCallback(async () => {
		setIsHelp(!isHelp)
		if (isSCOpen) dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			await dispatch(shutdown());
		}
		await setTimeout(async () => {
			await dispatch(setUp({
				actionColor: "#7cbc98",
				backgroundColor: "#7cbc98",
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'patientSupport' },
			}));
			await dispatch(show());
		}, 500);
	}, [dispatch, sanitasState, user, isSCOpen]);

	//Download PDF
	const downloadPdf = (dat) => {
		const body = {
			"patientId": dat.patientId,
			"reportId": dat.reportId,
			"dob": user.birthdate,
			"facilityInfo": dat.facilityInfo,
			"providerInfo": dat.providerInfo,
			"itemName": dat.itemName,
			"clinicalCenter": dat.clinicalCenter,
			"notes": dat.notes,
			"language": i18n.language,
		};
		const headers = {
			"Content-Type": "application/json",
			"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
		};
		return PrivateHttpClient.post(`${endpoint}/patient/currentMedications`, body, { headers: headers })
			.then((res) => {
				if (res) {
					if (isCordova) {
						var dataBase64 = res;

						var contentType = "application/pdf";
						var folderpath = window.cordova.file.dataDirectory
						var filename = 'document' + body.patientId;
						savebase64AsPDF(folderpath, filename, dataBase64, contentType);
					} else {
						const linkSource = `data:application/pdf;base64,${res}`;
						let downloadLink = document.createElement("a");
						downloadLink.href = linkSource;
						downloadLink.download = 'doc';
						downloadLink.click();
					}
				}
			}
			).catch((e) => {

				setOpenPopUp(true)
			})
			;
	}

	const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
		contentType = contentType || '';

		var byteCharacters = atob(b64Data);
		var byteArrays: any = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const ClearDate = () => {
		var p1 = { from: "", to: "" };
		var Init = moment().format();

		setDataObject(p1)
		setVisible(false)
		setIsFiltered(false)
		if (isMobile) setFilter(!filter);
	}

	const FilterVisible = () => {
		const format = (date) => { return date?.split('/').reverse().join('/').slice(2).split('/').reverse().join('/') }
		return (
			<>
				{!isMobile ?

					<Row style={{ width: '93%', justifyContent: 'space-between', display: dataObject.from ? 'flex' : 'none' }}>
						<Row style={{ width: '40%' }} noMargin>
							<ContentDiv onClick={() => ClearDate()}>
								<div style={{ padding: 7, textAlign: 'left', marginLeft: 3 }} >
									<KeraltyText style={{ fontSize: '12px', color: '#FFFFFF' }}>{moment(dataObject.from).format("MM/DD/YY")}</KeraltyText>
									<KeraltyText style={{ fontSize: '12px', color: '#FFFFFF', marginLeft: 3 }}> - </KeraltyText>
									<KeraltyText style={{ fontSize: '12px', color: '#FFFFFF', padding: 5 }}>{moment(dataObject.to).format("MM/DD/YY")}</KeraltyText>
									<KeraltyText style={{ fontSize: '14px', padding: 5, color: '#FFFFFF' }}>X </KeraltyText>
								</div>
							</ContentDiv>
						</Row>

						<KeraltyText style={{ fontSize: '16px', color: '#999999' }} >{t('myHealth.show')} {dataToDisplay.length} {t('myHealth.resul')}</KeraltyText>
					</Row>
					:

					<Row style={{ width: '93%', justifyContent: 'space-between' }}>
						<Row style={{ width: '80%' }} noMargin>
							<ContentDiv onClick={() => ClearDate()}>
								<div style={{ textAlign: 'left', marginLeft: 6 }} >
									<KeraltyText style={{ fontSize: '12px', color: '#FFFFFF' }}>{moment(dataObject.from).format("MM/DD/YY")}</KeraltyText>
									<KeraltyText style={{ fontSize: '12px', color: '#FFFFFF' }}> - </KeraltyText>
									<KeraltyText style={{ fontSize: '12px', color: '#FFFFFF', padding: 5 }}>{moment(dataObject.to).format("MM/DD/YY")}</KeraltyText>
									<KeraltyText style={{ fontSize: '14px', padding: 5, color: '#FFFFFF' }}>x   </KeraltyText>
								</div>
							</ContentDiv>
						</Row>
					</Row>}
			</>
		)


	}

	const savebase64AsPDF = (folderpath, filename, content, contentType) => {
		var DataBlob = b64toBlob(content, contentType);
		window.resolveLocalFileSystemURL(folderpath, function (dir) {
			dir.getFile(filename, { create: true }, function (file) {
				file.createWriter(function (fileWriter) {
					fileWriter.write(DataBlob);
					var finalPath = folderpath + filename;
					window.cordova.plugins.fileOpener2.open(finalPath, 'application/pdf'
						,
						{
							error: function (e) {
								console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
							},
							success: function () {
								console.log('file opened successfully');
							}
						}
					);

				}, function () {
					console.log("err");
				});
			});
		});
	}

	const MainFilters = () => {
		return (
			<>
				<div style={{ zIndex: 2, justifyContent: 'space-between', display: isMobile ? 'auto' : 'none' }}>
					{i18n.language == 'en' ?
						<img onClick={() => { setPage(false); setFilter(!filter); setIsModalVisible(filter); }} src={isMobile ? FilterImg : filter ? FilterImg : FilterRemove} alt="" style={{ cursor: 'pointer' }} />
						:
						<img onClick={() => { setPage(false); setFilter(!filter); setIsModalVisible(filter) }} src={isMobile ? FilterEs : filter ? FilterEs : FilterClose} alt="" style={{ cursor: 'pointer' }} />
					}
				</div>
			</>
		);

	};
	const MainFilter = () => {
		return (
			<div style={{ display: isMobile ? 'block' : 'flex', width: isMobile ? '99%' : '40%', justifyContent: 'space-between' }}>
				{isMobile ?
					<div >
						<Row style={{ justifyContent: 'space-between' }}>
							<KeraltyText type='label' >{t('myHealth.titleSearch')}</KeraltyText>
						</Row>

						<Search
							iconClass={"fas fa-search"}
							placeholder={t('myHealth.search')}
							className="search"
							autoFocus
							type="text"
							actionIcon={pwdIcon()}
							onActionIconClick={() => { setIsPwdVisible(!isPwdVisible); setIsFiltered(false) }}
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								setPage(true);
								setIsFiltered(true)
							}}
						/>
						<Row style={{ justifyContent: 'space-between' }}>
							<MainFilters />

							<KeraltyText style={{ fontSize: '14px', color: '#999999' }}  > {dataToDisplay.length} {t('myHealth.resul')}</KeraltyText>
							{visible && isMobile && !filter ?
								<FilterVisible />
								: ''}
						</Row>
					</div>
					:
					<SearchWeb
						label={t('myHealth.titleSearch')}
						type="text"
						iconClass={"fas fa-search"}
						placeholder={t('myHealth.search')}
						className="search"
						actionIcon={pwdIcon()}
						onActionIconClick={() => { setIsPwdVisible(!isPwdVisible); setIsFiltered(false) }}
						autoFocus
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
							setPage(true)
							setIsFiltered(true)
						}} />
				}

			</div>
		);
	};
	const filterresult = data.filter((dat) => dat.medicationName.toLowerCase().includes(search.toLowerCase()));
	const toggleBtn = (i) => {
		if (selected == i) {
			setSelected(null)
		} else {
			setSelected(i)
		}
	}
	const CardsLaboratories = () => {
		return (
			<MainContain>
				{openPopUp ?
					<PopupError />
					: null
				}
				{search && filterresult.map((dat, i) => (
					<ContainMap key={dat.medicationName}>
						{isMobile ? '' :
							<ContainImg style={{ paddingInline: 15, alignSelf: 'start' }}>
								<div style={{ flexDirection: 'column' }}>
									<div>
										<Icon className="fas fa-pills" style={{ color: '#48AC1C' }} ></Icon>
										<span style={{ fontSize: '14px', fontWeight: '600', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.medication')}</span>
									</div>
									<div onClick={() => { toggleBtn(i) }}>
										<div style={{ fontSize: '16px', fontWeight: '600', color: '#5B5C5B', lineHeight: '17px', maxHeight: '100%', width: 210, lineBreak: 'anywhere', alignSelf: 'center', }}>{selected == i ? dat.medicationName : dat.medicationName.substr(0, 105)}
											<span style={{ position: 'absolute', display: 'flex', marginLeft: '210px', marginTop: -10 }} >
												{selected == i ? <Image src={DownLen} /> : <Image src={RightLen} />}</span>
										</div>
									</div>
								</div>
							</ContainImg>}

						<ContainInfo style={{ justifyContent: 'center' }}>

							{!isMobile ? '' :
								<div className='wrapper'>

									<div className='accordion'>
										<div className='item' onClick={() => { toggleBtn(i) }}>
											<Content className='title' >
												<ContainImgMovil>
													<Image src={Medical} height={4} style={{ position: 'absolute', marginTop: '3%' }} />
												</ContainImgMovil>
												<div style={{ display: 'flex', justifyContent: 'space-between' }} >
													<span style={{ fontSize: '16px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: '30px', width: 200, maxHeight: '100%', overflow: 'hidden', lineBreak: 'anywhere', alignSelf: 'center', }}>{selected == i ? dat.medicationName : dat.medicationName.substr(0, 88)}</span>
													<div style={{ marginTop: '6%', width: 45, marginLeft: 40 }}>
														<span >{selected == i ? <Image src={Down} /> : <Image src={Right} />}</span>
													</div>
												</div>
											</Content>

											<div className={selected == i ? 'content.show' : 'content'} style={{ display: 'flex', flexDirection: 'column', marginLeft: '3%', maxHeight: selected == i ? 'max-content' : 0 }}>
												<div style={{ marginTop: 20 }}>
													<Row >
														<Image src={Direction} height={1.5} style={{ marginRight: '6px' }} />
														<span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.direction')}</span>
														<span style={{ fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', width: '53%', textAlign: 'right' }}>{dat.directions}</span>
													</Row>
												</div>
												<div style={{ marginBlock: -5 }} >
													<Row >

														<Icon className="fas fa-clock" style={{ marginRight: '10px' }} ></Icon>
														<span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.frequencyM')}</span>
														<span style={{ fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', width: '52%', textAlign: 'right' }}>{dat.frequency}</span>
													</Row>
												</div>

												<div style={{ marginBlock: -5 }}>
													<Row >


														<Icon className="fas fa-balance-scale" style={{ marginRight: '10px' }} ></Icon>
														<span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.doses')}</span>
														<span style={{ fontSize: '14px', color: '#5B5C5B', fontWeight: '600', lineHeight: '17px', width: '66%', textAlign: 'right' }}>{dat.doses}</span>
													</Row>
												</div>

												<div style={{ marginBlock: -5 }}>
													<Row >
														<IconSub className="fa fa-calendar-day" style={{ marginRight: '10px' }} ></IconSub>
														<span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.dateinit')}</span>
														<span style={{ fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', width: '52%', textAlign: 'right' }}>{dat.startDate ? moment(dat.startDate).format("MM-DD-YYYY") : ''}</span>
													</Row>
												</div>
												<div style={{ marginBlock: -5 }}>
													<Row>
														<IconSub className="fas	fa-calendar-check" style={{ marginRight: '10px' }} ></IconSub>
														<span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.dateEnd')}</span>
														<span style={{ fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', width: '52%', textAlign: 'right' }}>{dat.endDate ? moment(dat.endDate).format("MM-DD-YYYY") : ''}</span>
													</Row>
												</div>
												<div style={{ marginBlock: -5 }}>
													<Row>
														<IconSub className="fas	fa-user-md" style={{ marginRight: '10px' }} ></IconSub>
														<span style={{ fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.prescribed')}</span>
														<span style={{ fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', width: '50%', textAlign: 'right' }}>{dat.prescribedBy}</span>
													</Row>
												</div>
											</div>
										</div>
									</div>
								</div>
							}
							{!isMobile ? <div style={{ marginLeft: '-30%' }}>
								<CardTablet style={{ paddingInline: 15 }}>
									<Row televist style={{ alignContent: 'flex-start', marginLeft: 80, marginTop: -10 }}>
										<Row >
											<Image src={Direction} height={1.2} style={{ marginLeft: 10, marginTop: 3 }} />
											<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.direction')}</span>
											<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.directions}</span>
										</Row>
										<Row style={{ marginTop: -5 }}>
											<Icon className="fas fa-clock" style={{ marginLeft: 10 }} ></Icon>
											<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.frequencyM')}</span>
											<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.frequency}</span>
										</Row>
										<Row style={{ marginTop: -5 }}>
											<Icon className="fas fa-balance-scale" style={{ marginLeft: 10 }} ></Icon>
											<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.doses')}</span>
											<span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.doses}</span>
										</Row>
									</Row>

									<Row televist style={{ alignContent: 'end', marginTop: -110, marginLeft: 350 }}>
										<Row>
											<IconSub className="fa fa-calendar-day" style={{ marginLeft: 10 }} ></IconSub>
											<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.dateinit')}</span>
											<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.startDate ? moment(dat.startDate).format("MM-DD-YYYY") : ''}</span>
										</Row>
										<Row style={{ marginTop: -5 }}>
											<IconSub className="fas	fa-calendar-check" style={{ marginLeft: 10 }} ></IconSub>
											<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.dateEnd')}</span>
											<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.endDate ? moment(dat.endDate).format("MM-DD-YYYY") : ''}</span>
										</Row>
										<Row style={{ marginTop: -5 }}>
											<IconSub className="fas fa-user-md" style={{ marginLeft: 10 }} ></IconSub>
											<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.prescribed')}</span>
											<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.prescribedBy}</span>
										</Row>
									</Row>

								</CardTablet>
							</div> : ''}

						</ContainInfo>
					</ContainMap>
				))}
				{isMobile && !search && filterresult && newDataMovil.map((dat, i) => (

					<ContainMap key={dat.doses && dat.directions}>

						<ContainInfo style={{ justifyContent: 'center' }}>

							{!isMobile ? '' :

								<div className='wrapper'>

									<div className='accordion'>
										<div className='item' onClick={() => { toggleBtn(i) }}>
											<Content className='title' >
												<ContainImgMovil>
													<Image src={Medical} height={4} style={{ position: 'absolute', marginTop: -10 }} />
												</ContainImgMovil>
												<div style={{ display: 'flex', justifyContent: 'space-between' }} >
													<span style={{ fontSize: '16px', fontWeight: '700', color: '#5B5C5B', lineHeight: '17px', marginLeft: '30px', width: 200, maxHeight: '100%', overflow: 'hidden', alignSelf: 'center', lineBreak: 'anywhere' }}>{selected == i ? dat.medicationName : dat.medicationName.substr(0, 88)}</span>
													<div style={{ marginTop: 'auto', width: 45, marginLeft: 40 }}>
														<span >{selected == i ? <Image src={Down} /> : <Image src={Right} />}</span>
													</div>
												</div>
											</Content>

											<div className={selected == i ? 'content.show' : 'content'} style={{ display: 'flex', flexDirection: 'column', marginLeft: '3%', maxHeight: selected == i ? 'max-content' : 0 }}>
												<div style={{ marginTop: 20 }}>
													<Row noMargin>
														<Image src={Direction} height={1.5} style={{ marginRight: '6px' }} />
														<span style={{ width: '40%', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.direction')}</span>
														<span style={{ width: '40%', fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', textAlign: 'right' }}>{dat.directions}</span>
													</Row>
												</div>
												<div style={{ marginBlock: 3 }} >
													<Row noMargin>

														<Icon className="fas fa-clock" style={{ marginRight: '10px' }} ></Icon>
														<span style={{ width: '40%', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.frequencyM')}</span>
														<span style={{ width: '40%', fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', textAlign: 'right' }}>{dat.frequency}</span>
													</Row>
												</div>

												<div style={{ marginBlock: 3 }}>
													<Row noMargin>
														<Icon className="fas fa-balance-scale" style={{ marginRight: '10px' }} ></Icon>
														<span style={{ width: '40%', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.doses')}</span>
														<span style={{ width: '40%', fontSize: '14px', color: '#5B5C5B', fontWeight: '600', lineHeight: '17px', textAlign: 'right' }}>{dat.doses}</span>
													</Row>
												</div>

												<div style={{ marginBlock: 3 }}>
													<Row noMargin>
														<IconSub className="fa fa-calendar-day" style={{ marginRight: '10px' }} ></IconSub>
														<span style={{ width: '40%', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.dateinit')}</span>
														<span style={{ width: '40%', fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', textAlign: 'right' }}>{dat.startDate ? moment(dat.startDate).format("MM/DD/YYYY") : ''}</span>
													</Row>
												</div>
												<div style={{ marginBlock: 3 }}>
													<Row noMargin>
														<IconSub className="fas	fa-calendar-check" style={{ marginRight: '10px' }} ></IconSub>
														<span style={{ width: '40%', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.dateEnd')}</span>
														<span style={{ width: '40%', fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', textAlign: 'right' }}>{dat.endDate ? moment(dat.endDate).format("MM/DD/YYYY") : ''}</span>
													</Row>
												</div>
												<div style={{ marginBlock: 3 }}>
													<Row noMargin>
														<IconSub className="fas	fa-user-md" style={{ marginRight: '10px' }} ></IconSub>
														<span style={{ width: '40%', fontSize: '14px', fontWeight: '700', color: '#5B5C5B', lineHeight: '19px' }}>{t('myHealth.prescribed')}</span>
														<span style={{ width: '40%', fontSize: '14px', color: '#5B5C5B', lineHeight: '17px', textAlign: 'right' }}>{dat.prescribedBy}</span>
													</Row>
												</div>
											</div>
										</div>
									</div>
								</div>
							}


							{!isMobile ?
								<div style={{ marginLeft: '-40%' }}>
									<CardTablet style={{ paddingInline: 15 }}>
										<Row televist style={{ alignContent: 'flex-start', marginLeft: 80, marginTop: -10 }}>
											<Row>
												<Icon className="fas fa-vial" style={{ marginLeft: 10 }} ></Icon>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.direction')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.directions}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<Icon className="fas fa-clock" style={{ marginLeft: 10 }} ></Icon>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.frequencyM')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.frequency}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<Icon className="fas fa-balance-scale" style={{ marginLeft: 10 }} ></Icon>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.doses')}</span>
												<span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.doses}</span>
											</Row>
										</Row>

										<Row televist style={{ alignContent: 'end', marginTop: -110, marginLeft: 350 }}>
											<Row>
												<IconSub className="fa fa-calendar-day" style={{ marginLeft: 10 }} ></IconSub>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.dateinit')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.startDate ? moment(dat.startDate).format("MM/DD/YYYY") : ''}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<IconSub className="fas	fa-calendar-check" style={{ marginLeft: 10 }} ></IconSub>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.dateEnd')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.endDate ? moment(dat.endDate).format("MM/DD/YYYY") : ''}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<IconSub className="fas fa-user-md" style={{ marginLeft: 10 }} ></IconSub>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.prescribed')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.prescribedBy}</span>
											</Row>
										</Row>

									</CardTablet>
								</div>
								: ''
							}
						</ContainInfo>
					</ContainMap>
				))}
				{!isMobile && !search && filterresult && newData.map((dat, i) => (
					<ContainMap key={dat.id} style={{ paddingTop: 25, paddingInline: 20 }}>
						{isMobile ? '' :
							<ContainImg style={{ paddingInline: 15, alignSelf: 'start' }}>
								<div style={{ flexDirection: 'column' }}>
									<Row noMargin>
										<ContainImgMovil>
											<Image src={Medical} width={3.5} style={{ margin: 0 }} />
										</ContainImgMovil>
										<span style={{ fontSize: '14px', fontWeight: '600', color: '#055293', lineHeight: '19px', alignSelf: 'center', marginLeft: -10 }}>{t('myHealth.medication')}</span>
									</Row>
									<div onClick={() => { toggleBtn(i) }}>
										<div style={{ fontSize: '16px', fontWeight: '600', color: '#5B5C5B', lineHeight: '17px', maxHeight: '100%', width: 210, lineBreak: 'anywhere', alignSelf: 'center', }}>{selected == i ? dat.medicationName : dat.medicationName.substr(0, 105)}
											<span style={{ position: 'absolute', display: 'flex', marginLeft: '210px', marginTop: -10 }} >
												{selected == i ? <Image src={DownLen} /> : <Image src={RightLen} />}</span>
										</div>
									</div>
								</div>
							</ContainImg>}
						<ContainInfo style={{ justifyContent: 'center' }}>

							{!isMobile ?
								<div style={{ marginLeft: '-30%' }}>
									<CardTablet style={{ paddingInline: 15 }}>
										<Row televist style={{ alignContent: 'flex-start', marginLeft: 80, marginTop: -10 }}>
											<Row >
												<Image src={Direction} height={1.2} style={{ marginLeft: 10, marginTop: 3 }} />
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.direction')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.directions}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<Icon className="fas fa-clock" style={{ marginLeft: 10 }} ></Icon>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.frequencyM')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.frequency}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<Icon className="fas fa-balance-scale" style={{ marginLeft: 10 }} ></Icon>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.doses')}</span>
												<span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.doses}</span>
											</Row>
										</Row>

										<Row televist style={{ alignContent: 'end', marginTop: -110, marginLeft: 350 }}>
											<Row>
												<IconSub className="fa fa-calendar-day" style={{ marginLeft: 10 }} ></IconSub>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.dateinit')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.startDate ? moment(dat.startDate).format("MM/DD/YYYY") : ''}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<IconSub className="fas	fa-calendar-check" style={{ marginLeft: 10 }} ></IconSub>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.dateEnd')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.endDate ? moment(dat.endDate).format("MM/DD/YYYY") : ''}</span>
											</Row>
											<Row style={{ marginTop: -5 }}>
												<IconSub className="fas fa-user-md" style={{ marginLeft: 10 }} ></IconSub>
												<span style={{ fontSize: '14px', fontWeight: '700', color: '#055293', lineHeight: '19px', marginLeft: 10 }}>{t('myHealth.prescribed')}</span>
												<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.prescribedBy}</span>
											</Row>
										</Row>

									</CardTablet>
								</div>
								:
								''}
						</ContainInfo>
					</ContainMap>
				))}
				{filterresult.length >= 5 ?
					<Row>
						<Container centered="x" noPadding>
							<Container centered="y" noPadding>
								<PaginateBar
									currentPage={currPage}
									itemsPerPage={5}
									lastPage={lastPage}
									select={(page) => setCurrPage(page)}
									totalItems={page ? filterresult.length : dataToDisplay.length}
								/>
							</Container>
						</Container>
					</Row>
					: <></>
				}
				<Row>
					<div style={{ marginBottom: dataToDisplay.length <= 2 ? 0 : 30, width: '70vw', display: 'flex', background: 'red' }}> </div>
				</Row>
			</MainContain >
		);
	};

	const pwdIcon = () => (

		<KeraltyIcon
			key='eye'
			title={t('imgNicons.eyeIcon')}
			iconClass={`fas	fa-backspace `}
			onClick={() => { setSearch("") }}
		/>
	);

	return (
		<TabWrapper title={isMobile ? '' : t('myHealth.currentMedication')}>
			{closeModalError && <ModalErrorMyHealth setCloseModalError={()=>setCloseModalError(false)}/>}
			<DivContent>
				<Row>
					<MyTitle>
						<KeraltyText style={{ width: 'auto', margin: 0, fontSize: 18, marginLeft: -10 }} type={'title'} >{isMobile ? t('myHealth.currentMedication') : ''}</KeraltyText>
						<div></div>
					</MyTitle>
				</Row>

				<div style={{ display: isMobile ? 'block' : 'flex', width: '98%', justifyContent: 'space-between' }}>
					<MainFilter />
					<div style={{ zIndex: isMobile ? 4 : 1, display: filter ? 'none' : 'block' }}>
						<KeraltyFilterDate
							onSuccess={onChangeDate}
							onCancel={() => { setIsModalVisible(false); setFilter(true) }}
							showModalFlag={isModalVisible}
							cleanInput={!visible}
						/>
					</div>
					{isMobile ? '' : <MainFilters />}
				</div>

				{visible && !isMobile ? <FilterVisible /> : ''}


				{!error? filterresult?.length && dataToDisplay?.length ?
					<CardsLaboratories /> :
					<>
						{!isFiltered ?
							<div style={isMobile ? styleNoResultMobile : styleNoResult}>
								<NoSearchResult image={EmptyImmunizations} subTitle={t('myHealth.noCurrentMedication')} />
							</div>
							:
							<NoSearchResult image={NoResult} title={t('myHealth.titleEmpty')} subTitle={t('myHealth.textEmpty')} />
						}

						<Row>
							<div style={{ marginBottom: 300, width: '40vw', display: 'flex', background: 'red' }}> </div>
						</Row>
					</>
				: <></>}
			</DivContent>
		</TabWrapper>
	);
};
export default CurrentMedicationTable;
