const nsUnblockAccount = {
  en: {
    change: 'Change your password',
    personalInfo: 'Personal information',
    recoverInfo: 'Recover information',
    newPassword: 'Create a Password',
    password: 'Password',
    confirmNewPassword: 'Confirm your new password',
    instructions: 'Select the mobile number or email where you want to receive the verification code. By clicking in one of the following options you are accepting to be contacted by email or by phone via a text message.',
    selectEmail: 'Select the email',
    verifyOption: 'Please check your selected option to reset your password.',
    success: 'Your password has been successfully updated',
    infoChangePassword: 'We find that your email is registered to more than one State. Please select at least one State for which you want to change the password',
    personalInfoSub: 'To unblock your account, first we need to validate your account. Please insert the next information:',
  },
  es: {
    change: 'Cambiar contraseña',
    personalInfo: 'Información personal',
    recoverInfo: 'Información de recuperación',
    newPassword: 'Crear contraseña',
    password: 'Contraseña',
    confirmNewPassword: 'Confirma tu nueva contraseña',
    instructions: 'Selecciona el número de celular o correo electrónico donde quieres recibir el método de verificación. Al elegir una de las opciones estás accediendo a ser contactado por teléfono o correo electrónico via mensaje de texto.',
    selectEmail: 'Selecciona el correo electrónico',
    verifyOption: 'Verifica la opción seleccionada para recuperar tu contraseña',
    success: 'Tu contraseña ha sido cambiada exitosamente',
    infoChangePassword: 'Encontramos que su correo electrónico está registrado en varios Estados, por favor seleccione al menos un Estado para el cual necesitas cambiar la contraseña',
    personalInfoSub: 'Para desbloquear su cuenta, primero debemos validar su cuenta. Por favor inserte la siguiente información:',

  },
};

export default nsUnblockAccount;
