import axios from 'axios';
import appConfig from 'config';

export interface HttpResponse<T> {
	success: boolean;
	data?: T | boolean;
	error?: string;
}

/**
 * Http client instance for Authenticated API calls
 */
const PrivateHttpClient = axios.create({
	baseURL: `${appConfig.API_URL}/`,
	timeout: 90000,
	headers: {
		'Content-Type': 'application/json',
	},
});

PrivateHttpClient.interceptors.response.use(
	(response): Promise<HttpResponse<any>> => {
		return Promise.resolve(response.data);
	},
	(error): Promise<HttpResponse<any>> => {

		return Promise.reject({
			name: error?.response?.config?.url || 'No Network Connection',
			message: String(error?.response?.data?.code || 999),
			code: error?.response?.status ? String(error?.response?.status) : 0,
		});
	}
);

export const PrivateHttpClient2 = axios.create({
	baseURL: `https://back.qal.kdc.mysanitas.com/api`,
	timeout: 90000,
	headers: {
		'Content-Type': 'application/json',
	},
});

PrivateHttpClient2.interceptors.response.use(
	(response): Promise<HttpResponse<any>> => {
		return Promise.resolve(response.data);
	},
	(error): Promise<HttpResponse<any>> => {

		return Promise.reject({
			name: error?.response?.config?.url || 'No Network Connection',
			message: String(error?.response?.data?.code || 999),
			code: error?.response?.status ? String(error?.response?.status) : 0,
		});
	}
);

export const setClientAuthorization = (token: string) => {
	PrivateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	PrivateHttpClient2.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export function deleteDefaultAuthHeader() {
	delete axios.defaults.headers.common['Authorization'];
};

export default PrivateHttpClient;
