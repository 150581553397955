import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

interface CardProps {
	sm?: boolean;
	bg?: keyof typeof theme.colors;
	bgImg?: any;
}

const Card = styled.div<CardProps>`
	margin: 1.3rem;
	padding: .83rem 1rem;
	min-height: ${({ sm }: any) => sm ? '5rem' : 'calc(9.56rem - 1.66rem)'};
	background-color:  ${({ bg, theme: { colors } }) => bg ? colors[bg] : colors.light};
	width: calc(100% - 2rem);
	border-radius: .67rem;
	box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);

	display: flex;
	justify-content: center;
	align-items: flex-start;
	cursor: pointer;

	${({ bgImg }) => bgImg ? `
		background-image: url(${bgImg});
		background-size: cover;
		background-repeat: no-repeat;
	`: ''}


	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin: .83rem 0rem;
		width: calc(100% - .6rem);
		min-height: ${({ sm }: any) => sm ? '5rem' : 'calc(8.8rem - 1.66rem)'};
	}
`;

export default Card;
