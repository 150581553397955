import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'app/ui-core/styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';

import { CenteredWrapper, Column, Loader, Row, Card } from 'app/components/templates';
import { Image, KeraltyButton, KeraltyText } from 'app/components/atoms';
import { useAppSelector } from 'adapter/hooks';
import chatSelectors from 'adapter/chat/chatSelectors';
import { SectionWidget } from 'app/components/molecules';
import theme from 'app/ui-core/utils/theme';

import { useEffect, useState } from 'react';
import ROUTES from 'app/ui-core/utils/routes';
import AppointmentsFormFHS from './AppointmentsFormFHS';
import i18n from 'app/ui-core/i18n';

import sheduleIcon from '../../../assets/icons/mh-shedule.svg';
import calledIcon from '../../../assets/icons/mh-calledLater.svg';
import calledIconMobile from '../../../assets/icons/mh-calledLater-mobile.svg';

import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import useAuth from 'app/hooks/useAuth';
import { ScheduleCode } from 'app/ui-core/utils/disableOptions';
import useIsMobile from 'app/hooks/useIsMobile';
const sizesIcon: IconSizes = { width: 3.58, height: 2.88 };

interface IconSizes {
  width: number;
  height: number;
}

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    // margin-top: 5vh;
    padding-inline: 10px;
  }
`;

const LeftColumn = styled(Column)`
  justify-content: flex-end;
  padding: 0rem;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const RightColumn = styled(Column)`
  justify-content: flex-start;
  padding: 0rem;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  height: 166px;
  width: 308px;
  margin: 1.5rem 1.455rem;
  border-radius: 10px;
  padding: 2.34rem 0.6rem !important;
  display: flex;
  align-items: flex-start;
  padding-top: 32px !important;

  h2 {
    margin: 0rem;
    font-size: 1.34rem;
    line-height: 1.34rem;
  }
  p {
    margin: 0rem;
    font-size: 1rem;
    line-height: 1.17rem;
    color: gray;
    text-transform: none;
    font-weight: 400;
  }
`;

const DisabledGetCareNowOptionContentTN = styled.div`
  width: 288.43px;
  min-height: 171px;
  height: auto;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  margin-bottom: 2.5rem;
  margin-left: 2.5rem;
  margin-right: 1.46rem;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

const NoteContentTN = styled.div`
  width: 100%;
  min-height: 26px;
  background: #0269a7;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const NoteTN = styled.span<{ isSafari: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isSafari }) => (isSafari ? '9px' : '11px')};
  line-height: 16px;
  text-transform: uppercase;
  @media screen and (min-width: 599px) and (max-width: 1066px) {
    font-size: ${({ isSafari }) => (isSafari ? '8px' : '9px')};
  }
`;

const ImgTN = styled.img`
  margin-top: 27.32px;
`;

const CardTN = styled(Card)`
  padding: 0px !important;
  cursor: none !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const SectionWidgetTN = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;

// Time zone card
const DisabledGetCareSchedule = ({ title, subtitle, Image }) => {
  const { t } = useTranslation();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = useIsMobile();

  return (
    <DisabledGetCareNowOptionContentTN>
      <NoteContentTN>
        <NoteTN isSafari={isSafari}>{t('mentalHealth.schedule')}</NoteTN>
      </NoteContentTN>
      <ImgTN src={Image} alt={t('imgNicons.mhCallScheduleIcon')} />

      <KeraltyText type="title" fontWeight={'400'} fontSize="16px" style={{ lineHeight: '16px' }}>
        {title}
      </KeraltyText>
      <KeraltyText
        type="title"
        fontWeight={'400'}
        color="#747474"
        fontSize="1rem"
        style={{ lineHeight: '14px', marginTop: -2, width: '21rem', padding: '0 .6rem' }}
      >
        {subtitle}
      </KeraltyText>
    </DisabledGetCareNowOptionContentTN>
  );
};

const AppointmentsFHS: React.FC = () => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(chatSelectors.selectIsLoading);
  const { push } = useHistory();
  const { subSubTab } = useParams();
  const { user } = useAuth();
  
  const goToForm = () => {
    push(
      `/${MAIN.mentalHealth}/${CURRENT.appointments}/${CURRENT.appointmentsFHS}/${CURRENT.appointmentsFormFHS}`
    );
  };
  const [isAvailable, setIsAvailable] = useState<boolean>();
  const [initialRender, setInitialRender] = useState(true);

  const handlePhone = () => {
    window.location.href = 'tel:+7864205924';
  };

  const diferenceTimeByZone = [
    {
      state: 'FL',
      diference: -4,
    },
    {
      state: 'TN',
      diference: -5,
    },
  ];

  useEffect(() => {
    const originalDate = new Date();

    const converted = originalDate.toISOString();

    const timeConverted = converted.split('T')[1].split(':');

    const newDate = new Date(
      `${converted.split('T')[0]} ${timeConverted[0]}:${timeConverted[1]}:${
        timeConverted[2].split('.')[0]
      }`
    );

    newDate.setHours(
      newDate.getHours() +
        (user.state ? diferenceTimeByZone.find((i) => i.state === user.state)!.diference : 0)
    );
    if (newDate.getDay() !== ScheduleCode.startDay && newDate.getDay() !== ScheduleCode.endDay) {
      if (
        newDate.getHours() >= ScheduleCode.startTime &&
        newDate.getHours() < ScheduleCode.endTimeFHS
      ) {
        setIsAvailable(true);
        setInitialRender(false);
      } else {
        setIsAvailable(false);
        setInitialRender(false);
      }
    } else {
      setIsAvailable(false);
      setInitialRender(false);
    }
  }, [new Date().getHours()]);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      {isLoading && <Loader />}
      <RightContent fullHeight>
        <Row style={{ justifyContent: 'center', marginBottom: '30px' }}>
          <Column lg={0} md={0} sm={12}>
            {!initialRender &&
                <CenteredWrapper onClick={isAvailable ? () => handlePhone() : () => {}} style={{ cursor: 'pointer' }}>
                  <CardTN>
                    <NoteContentTN>
                      <NoteTN isSafari={isSafari}>{t('mentalHealth.schedule')}</NoteTN>
                    </NoteContentTN>
                    <SectionWidgetTN>
                      <div
                        style={{
                          width: '20%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Image
                          src={sheduleIcon}
                          width={3.3}
                          height={3.5}
                          alt={t('imgNicons.mhCallScheduleIcon')}
                          style={{marginLeft:'8px'}}
                        />
                      </div>
                      <div style={{ width: '80%' }}>
                        <KeraltyText
                          align="left"
                          type="title"
                          fontWeight={'400'}
                          color={theme.colors.primary}
                          fontSize="1.67rem"
                        >
                          {t('mentalHealth.callSchedule')}
                        </KeraltyText>
                        <KeraltyText
                          align="left"
                          type="title"
                          color="#747474"
                          fontSize="14px"
                          style={{
                            lineHeight: '15px',
                            marginTop: -12,
                            width: '95%',
                            marginBottom: '15px',
                          }}
                        >
                          {t('mentalHealth.callScheduleText')}
                        </KeraltyText>
                      </div>
                    </SectionWidgetTN>
                  </CardTN>
                </CenteredWrapper>
              }
          </Column>
          <LeftColumn  lg={4} md={6} sm={0} >
            {
              !initialRender &&
                <div onClick={isAvailable ? () => handlePhone() : () => {}} style={{ cursor: 'pointer' }}>
                  <DisabledGetCareSchedule
                    title={t('mentalHealth.callSchedule')}
                    subtitle={t('mentalHealth.callScheduleText')}
                    Image={sheduleIcon}
                  />
                </div>
            }
          </LeftColumn>

          <Column lg={0} md={0} sm={12}>
            <CenteredWrapper>
              <Card onClick={() => goToForm()}>
                <SectionWidget
                  img={calledIconMobile}
                  text={capitalizeJustFirst(t('mentalHealth.calledLater'))}
                  description={capitalizeJustFirst(t('mentalHealth.calledLaterText'))}
                  alt={t('imgNicons.mhCalledLaterIcon')}
                  imgSize="45px"
                />
              </Card>
            </CenteredWrapper>
          </Column>
          <RightColumn lg={4} md={6} sm={0} noPadding>
            <GetCareNowButton color="light" squareRadius={true} onClick={goToForm}>
              <span>
                <Image
                  style={{ marginBottom: '10px' }}
                  src={calledIcon}
                  width={sizesIcon.width}
                  height={sizesIcon.height}
                  alt={t('imgNicons.mhCalledLaterIcon')}
                />
                <KeraltyText
                  type="title"
                  fontWeight={'400'}
                  style={{ paddingBottom: 10, fontWeight: 'normal' }}
                >
                  {t('mentalHealth.calledLater')}
                </KeraltyText>
                <KeraltyText type="paragraph" style={{ width: '21rem' }}>
                  {t('mentalHealth.calledLaterText')}
                </KeraltyText>
              </span>
            </GetCareNowButton>
          </RightColumn>
        </Row>

        {subSubTab === CURRENT.appointmentsFormFHS && <AppointmentsFormFHS />}
      </RightContent>
    </>
  );
};

export { AppointmentsFHS };
