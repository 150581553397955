import { useCallback, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import PrivateHttpClient from '../../../../infrastructure/keraltyApi/privateHttpClient';


// 3RD PARTY
import { useAppSelector } from 'adapter/hooks';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import { Controller, useForm } from "react-hook-form";

//Actions

import { selectmyAccountLoading } from '../../../../adapter/myAccount/myAccountSelector';
// UI
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { KeraltyText, KeraltyIcon, KeraltyError } from '../../atoms';
import { KeraltyModal } from '../../molecules';
import { Container, Column, Row, Loader, SpaceWrapper, IconLinkRow } from '../../templates';
import styled from '../../../ui-core/styled-components';
import { useHistory } from 'react-router-dom';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import { KeraltyField } from '../../molecules';
import ROUTES from '../../../ui-core/utils/routes';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';

//import { fontSize } from '@mui/system';

const { MY_ACCOUNT } = ROUTES;

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  align-items: flex-start;
  background: transparent;
  height: 105%;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    height: auto;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    text-align: center;
  }
`;

const BlockInfoContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
  align-self: 'center';
  max-width: 75%;
`;

const Icon = styled(Row)`
  color: white;
  width: 40px !important;
  height: 40px !important;
  margin: 0;
`;

const DoneButton = styled(Column)`
  align-self: center;
  padding: 10px;
  margin: 10px;
  font-size: 15px !important;
  background: #fff !important;
  color: #0F880D !important;
  width: 30% !important;
  line-height: 1.9rem;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
  cursor: pointer;
  border: 2px solid #0F880D;
  border-radius: 25px;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 100% !important;
	}
`;

const CancelButton = styled(Column)`
  background: #3ca70d !important;
  border-radius: 25px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  align-self: center;
  padding: 10px;
  font-size: 15px !important;
  width: 30% !important;
	line-height: 1.9rem;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 100% !important;
	}
`;

const ReasonContainer = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  border-width: 2px;
  border-color: #5b5b5b;
  border-style: solid;
  border-radius: 10px;
  margin-top: 10px;
  width: 69%;
  margin: auto;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 100% !important;
	}
`

const TextAreaStyled = styled.textarea`
  height: 60px;
  border: none; 
  border-width: 0px;
  border-color: #fff; 
  border-radius: 10px;
  color: #5b5b5b;
  font-size: 12px;
  padding: 5px;
  &:focus, &:active {
    outline: none;
  }
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		height: 85px !important;
	}
`

const styleText = {
  textAlign: "center",
  marginRight: 7,
  fontSize: 20,
  fontWeight: 400

};

const styleHr = {
  color: '#fff',
  height: 0.001,
  width: 390,
}

const styleButton = {
  color: '#3CA70D',
  marginRight: 60,
  marginLeft: 60,
  marginTop: 10,
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: '#FFFFFF',
  borderRadius: 40,
  border: 'none',
  borderColor: "#FFFFFF",
  fontSize: 18,
  fontWeight: 'bold',
}

const styleDiv = {
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 10,
  marginLeft: 0,
  marginRight: 0,
  flex: 1,
  align: "center",
  justifyContent: 'center',
  alignSelf: 'center',
}
const styleIcon = {
  flex: 1,
  align: "center",
  margin: 20,
  paddingBottom: 5,
  fontSize: 32,
  fontWeight: "bold",

}

interface iTextAreaControl {
  updateReasonText(arg: string): void;
}

const TextAreaControl: React.FC<iTextAreaControl> = (props) => {
  const { control, watch } = useForm<{
    reasonText: string[];
  }>({
    defaultValues: {
      reasonText: [""],
    }
  });

  const currentText = watch('reasonText');
  return (
    <Controller
      control={control}
      name="reasonText"
      render={({
        field: { onChange, onBlur, value, name, ref },
      }) => (
        <ReasonContainer>
          <TextAreaStyled
            maxLength={250}
            value={value}
            onChange={(e) => {
              props.updateReasonText(e.target.value)
              onChange(e)
            }}
            onBlur={onBlur}
            ref={ref}
            style={{ fontSize: '14px' }}
          />
          <text
            style={{ color: '#5b5b5b', fontSize: '10px', padding: '5px' }}
          >{currentText ? currentText.length : '0'}/250</text>
        </ReasonContainer>
      )}
    />
  )

}

const DeleteAccountTab = () => {
  const { t } = useTranslation();
  //const { goBack } = useHistory();
  const { logout, user } = useAuth();
  const endpoint = 'auth/delete';

  const isLoading = useAppSelector(selectmyAccountLoading);
  const userEmail = user.email;
  const userState = user.state;
  const [reason, setReason] = useState('');
  const [activeError, setActiveError] = useState(false);
  const [activeError2, setActiveError2] = useState(false);
  const history = useHistory();

  const [screen, setScreen] = useState(1);
  const reasonText = useRef<string>('');


  const goBack2 = () => {
    history.replace(`${MY_ACCOUNT.personalInfo}`)

  };


  const reasonsOptions = useMemo(() => [
    { value: '1', label: t('myAccount.reasonsOptions.duplicate') },
    { value: '2', label: t('myAccount.reasonsOptions.spam') },
    { value: '3', label: t('myAccount.reasonsOptions.privacy') },
    { value: '4', label: t('myAccount.reasonsOptions.anyValue') },
    { value: '5', label: t('myAccount.reasonsOptions.other') },
  ], [t]);

  const BaseContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', width: '100%' }}>
          <div style={{ background: '#3CA70D', borderRadius: '50%', width: "40px", height: "40px", padding: "13px", marginBottom: '20px' }}>
            <div style={{ fontSize: "20px" }} >
              <Icon className="fa fa-times-circle fa-lg" style={{ fontSize: "20px" }} ></Icon>
            </div>
          </div>
          <KeraltyText width={'60%'} type={'info'} align={'center'} color={'#5B5C5B'} style={{ fontSize: '18px', color: '#5B5C5B' }}>{t('myAccount.deleteAccountDetails')}</KeraltyText>
          <div style={{ display: "flex", flexDirection: "column", marginTop: '30px', width: '100%' }}>
            <CancelButton
              onClick={goBack2}
            >
              {t('button.cancel')}

            </CancelButton>
            <DoneButton
              onClick={(e) => {
                setScreen(2);

              }}
            >
              {t('button.continue')}

            </DoneButton>
          </div>
        </div>
      </div>
    );
  };

  const BaseContent3 = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', width: '100%' }}>
          <div style={{ background: '#3CA70D', borderRadius: '50%', width: "40px", height: "40px", padding: "13px", marginBottom: '20px' }}>
            <div style={{ fontSize: "20px" }} >
              <Icon className="fa fa-times-circle fa-lg" style={{ fontSize: "20px" }} ></Icon>
            </div>
          </div>
          <KeraltyText width={'60%'} type={'info'} align={'center'} color={'#5B5C5B'} style={{ fontSize: '18px', color: '#5B5C5B' }}>{t('myAccount.deleteAccountDetails3')}</KeraltyText>
          <div style={{ display: "flex", flexDirection: "column", marginTop: '30px', width: '100%' }}>
            <CancelButton
              onClick={goBack2}
            >
              {t('button.cancel')}

            </CancelButton>
            <DoneButton
              onClick={(e) => {
                deleteAction();
                setScreen(4);

              }}
            >
              {t('button.continue')}

            </DoneButton>
          </div>
        </div>
      </div>
    );
  };

  const SelectContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '13px', width: '100%' }}>
          <div style={{ background: '#3CA70D', borderRadius: '50%', width: "40px", height: "40px", padding: "13px", marginBottom: '20px' }}>
            <div style={{ fontSize: "20px" }} >
              <Icon className="fa fa-times-circle fa-lg" style={{ fontSize: "20px" }} ></Icon>
            </div>
          </div>
          <KeraltyText width={'60%'} type={'info'} align={'center'} color={'#5B5C5B'} style={{ fontSize: '18px', color: '#5B5C5B' }} >{t('myAccount.deleteAccountDetails2')}</KeraltyText>
          {<div style={{ width: responsive, fontSize: '14px' }}>
            <KeraltyField
              control={Select}
              iconClass="fas fa-question-circle"
              placeholder={t('myAccount.reasonPlaceholder')}
              type={'select'}
              options={reasonsOptions}
              onChange={handleSelect}
              value={reason}
            />
            <KeraltyError error={ !reason ? t('myAccount.reasonSelectError') : null} />
          </div>
          }
          {
            (reason['label'] === t('myAccount.reasonsOptions.other')) ?
              <div style={{ width: '100%' }}>
                <form >
                  <TextAreaControl updateReasonText={handlerReasonText} />
                </form>
              </div>
              : null
          }
          <KeraltyError error={ activeError ? t('errors.min') : null} />
          <KeraltyError error={ activeError2 ? t('errors.required') : null} />


          <div style={{ display: "flex", flexDirection: "column", marginTop: '30px', width: '100%' }}>
            <CancelButton
              onClick={goBack2}
            >
              {t('button.cancel')}
            </CancelButton>
            <DoneButton
              onClick={(e) => {
                if(reasonText?.current.length < 3 && reason['label'] === t('myAccount.reasonsOptions.other')){
                  if(reasonText?.current.length == 0){
                    setActiveError2(true)
                    setActiveError(false)
                  }else{
                    setActiveError2(false)
                    setActiveError(true)
                  }
                }else{
                  setActiveError(false)
                  setActiveError2(false)
                  getSelect(3, reason['label'])
                }
              }}
            >
              {t('button.continue')}
            </DoneButton>
          </div>
        </div>
      </div>
    );
  };

  function handleSelect(data) {
    setReason(data);
  };


  function getSelect(number, data) {
    if (data) { setScreen(number) }
  };

  const handlerReasonText = useCallback((arg) => {
    reasonText.current = arg;
  }, [reasonText]);

  const deleteAction = () => {
    const body = {
      "email": userEmail,
      "motive": send,
      "state": userState,
    };
    const headers = {
      "Content-Type": "application/json",
      "recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"

    };

    return PrivateHttpClient.post(`${endpoint}/userAccount`, body, { headers: headers })
      .then((res) => {
        // console.log(res);

      }
      );
  }




  let responsive;
  if (window.innerWidth < 1024) {
    responsive = '100%'
  } else { responsive = '58%' }


  let show;
  if (screen === 1) {
    show = <BaseContent />;
  } if (screen === 2) {
    show = <SelectContent />;
  } if (screen === 3) {
    show = <BaseContent3 />;
  } if (screen === 4) {
    show =
      <div>
        <BaseContent3 />

        <KeraltyModal size="extraLong"  >
          <Row noMargin>
            <KeraltyIcon iconClass="fas fa-times-circle" color="accent" size="big" style={styleIcon} mx />
            <SpaceWrapper mb={1}>
              <Container centered={'x'} noPadding noMargin>

                <KeraltyText type={'label'} align={'center'} style={styleText} >{t('myAccount.succesDeleteText')}</KeraltyText>

              </Container>
            </SpaceWrapper>
            <hr
              style={styleHr}
            />
          </Row>
          <Row noMargin>


            <div style={styleDiv}>

              <button
                style={styleButton}
                type="button"
                onClick={(e) => {
                  getOut();
                }}
              > {t('myAccount.succesDeleteButton')}</button>
            </div>
          </Row>
        </KeraltyModal>
      </div>
  }

  const getOut=()=>{
    
     KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, 'true');
     logout() ;
  }


  let send = '';
  if (reasonText.current.length > 2) {

    send = reasonText.current;
  } else { send = reason['label'] }


  return (
    <RightContent noMargin={false} bg="light">
      {(window.innerWidth < 1023) ?
        <IconLinkRow
          onClick={goBack2}
          color="accent"
          icon={arrowAltCircleLeftIcon}
          iconAlt={t('imgNicons.backArrow')}
          text={t('routes.back')}
        />
        : null
      }
      <Row style={{ marginBottom: '0px' }}>
        <TitleContiner lg={12}>
          <KeraltyText type={'title'} align={'left'}>
            {t('myAccount.deleteAccount')}
          </KeraltyText>
        </TitleContiner>
      </Row>
      <Container centered={'x'} noPadding style={{ marginTop: '0px' }}>
        {isLoading && <Loader />}
        {!isLoading && (
          <BlockInfoContainer style={{ marginTop: '0px', marginBottom: '50px' }}>

            {show}


          </BlockInfoContainer>
        )}
      </Container>
    </RightContent>
  );
};

export default DeleteAccountTab;
