import { lazy, Suspense, useCallback, useMemo } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../adapter/hooks';
// UI
import { KeraltyStepper } from '../../components/molecules';
import { Page, Container, Loader } from '../../components/templates';
// LOGIC
import CaptchaProvider from '../../contexts/CaptchaProvider';
import { useStepper } from '../../hooks/useStepper';
import { useRouteQuery } from '../../hooks/useRouteQuery';
// ADAPTER
import { registerActions } from '../../../adapter/register/registerSlice';
import registerSelectors from '../../../adapter/register/registerSelectors';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import moment from 'moment';

import FORMATS from 'app/ui-core/utils/formats';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const { REGISTER: CURRENT, MAIN } = ROUTES;


const RegisterFBPage = () => {
	const { t } = useTranslation();
	const { replace, push, goBack } = useHistory();
	const { step } = useParams();
	const { query } = useRouteQuery();
	const dispatch = useAppDispatch();
	const asyncError = useAppSelector(registerSelectors.selectError);
	const formValues = useAppSelector(registerSelectors.selectUserInfo);
	const isLoading = useAppSelector(registerSelectors.selectIsLoading);

	const steps = useMemo(() => [
		{
			label: t('register.confirmLoginInfo'), route: CURRENT.confirm, hidden: true,
			component: lazy(() => import('./organisms/ConfirmLoginForm'))
		},
		{
			label: t('register.contactInfo'), route: CURRENT.contact, hidden: true,
			component: lazy(() => import('./organisms/ContactInfoForm'))
		},
		{
			label: '', route: CURRENT.codeSent, hidden: true,
			component: lazy(() => import('./organisms/CodeSent'))
		},
		...(formValues?.contactMethod === 'phone' ?
			[{
				label: '', route: CURRENT.verify, hidden: true,
				component: lazy(() => import('./organisms/VerifySMSCode'))
			}]
			:
			[{
				label: '', route: CURRENT.oneMore, hidden: false,
				component: lazy(() => import('./organisms/OneMoreStep'))
			}]
		)
	], [t, formValues]);

	//#region FLOW ACTIONS
	const handleFinish = useCallback(async () => {
		push(`/${MAIN.fb}/${MAIN.patientRegistration}`);
	}, [push]);

	const { currentStep, next, Step: FormStep } = useStepper(0, steps, handleFinish);

	const handleSuccessStep = async (value) => {
		//console.debug('Submit values =>', value);
		dispatch(registerActions.setProp({
			userInfo: {
				...formValues,
				...(formValues?.birthdate ? { birthdate: moment(formValues.birthdate).format(FORMATS.date) } : {}),
				...value
			}
		}));
		let res;
		switch (step) {
			case CURRENT.confirm:
				res = await dispatch(registerActions.isEmailAvailable(value.email)).unwrap();
				break;
			case CURRENT.contact:
				res = await dispatch(registerActions.initialSave()).unwrap();
				break;
			case CURRENT.codeSent: res = true; break;
			case CURRENT.verify:
				KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.RESPONSIBLE_PARTY_DATA);
				res = await dispatch(registerActions.loadMaxUserInfoByCode(formValues)).unwrap()
				setTimeout(() => { window.location.reload() }, 1000);
				break;
			case CURRENT.oneMore:
				const registerId = query.get('id');
				if (registerId) {
					res = await dispatch(registerActions.loadMaxUserInfo({id: registerId, email: formValues.email})).unwrap();
				}
				break;
		}
		if (res) next();
	};

	const handleCleanAsyncError = useCallback(() => {
		dispatch(registerActions.setProp({ error: undefined }))
	}, [dispatch]);

	const handleMissingData = () => replace(`/${MAIN.register}`);
	//#endregion

	//#region UI LOGIC
	const title = useMemo(() => {
		const routesWithoutTitle: string[] = [CURRENT.codeSent, CURRENT.oneMore];
		if (routesWithoutTitle.indexOf(steps[currentStep].route) >= 0) return '';

		return currentStep === 0 ? t('register.confirmLoginInfo') : t('register.createAccount');
	}, [t, currentStep, steps]);

	const handleGoBack = useCallback(() => {
		handleCleanAsyncError();
		goBack();
	}, [handleCleanAsyncError, goBack]);

	const routeNames = useMemo(() => title ? [
		{ label: t('routes.login'), callback: () => replace(`/${MAIN.login}`) },
		{ label: title || t('routes.register') }
	] : [], [t, title, replace]);
	//#endregion

	return (
		<CaptchaProvider>
			{isLoading && <Loader />}
			<Page
				title={title}
				routes={routeNames}
				onPrevious={handleGoBack}
				previousLabel={t('routes.back')} >
				<Container centered={'xy'} noPadding>
					<KeraltyStepper steps={steps} activeStep={currentStep} noPadding>
						<Suspense fallback={<Loader />}>
							<FormStep
								formValues={formValues}
								onSuccess={handleSuccessStep}
								asyncError={asyncError}
								cleanAsyncError={handleCleanAsyncError}
								onMissingData={handleMissingData} />
						</Suspense>
					</KeraltyStepper>
				</Container>
			</Page>
		</CaptchaProvider>
	);
};

export default RegisterFBPage;
