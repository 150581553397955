import styled from '../../ui-core/styled-components';
import Container from '../templates/container';

interface KeraltyTabsProps {
	className?: string;
	tabs: { label: string, key: string }[];
	children: any | any[];
	activeTab: string;
	noPadding?: boolean;
	onSelectTab: (value: string) => void;
}

const TabsStructure = ({ className, tabs, children, activeTab, noPadding, onSelectTab }: KeraltyTabsProps) => (
	<div className={className}>
		<Container className="tabs-header" centered={'x'} noPadding={noPadding}>
			{
				tabs.map(({ key, label }, i) => (
					<div key={`tab-item-${i}`}
						className={`tab ${activeTab === key ? 'active' : ''}`}
						onClick={() => onSelectTab(key)}>
						<Container centered={'x'} noPadding>
							<label>{label}</label>
						</Container>
					</div>
				))
			}
		</Container>
		<Container className={'tab-view'} noPadding>
			{children[tabs.findIndex((x: any) => x.key === activeTab) || 0]}
		</Container>
	</div>
);

const KeraltyTabs = styled(TabsStructure) <KeraltyTabsProps>`
	width: 100%;
	padding-top: 2rem;
	height: calc(100% - 4rem);
	position: relative;
	.tabs-header {
		width: calc(100% + 4rem);
		margin-left: -2rem;
		margin-right: -2rem;
		.tab {
			position: relative;
			width: ${({ tabs }) => 100 / tabs.length}%;
			height: 3.33rem;
			border-bottom: 3px solid ${({ theme: { colors } }) => colors.darkLight};
			cursor: pointer;
			label {
				margin-top: 1rem;
				font-size: 1.17rem;
				font-weight: bold;
				color: ${({ theme: { colors } }) => colors.lightDark};
				cursor: pointer;
			}
			&.active {
				${({ theme: { colors } }) => `
				border-bottom: 3px solid ${colors.accent};

				label {
					color: ${colors.dark};
				}
				`}
			}
		}
	}
	.tab-view {
		padding: 3rem;
		max-width: calc(100% - 6rem);
		p {
			color: ${({ theme: { colors } }) => colors.dark};
			font-size: 1.33rem;
			text-align: justify;
			line-height: 1.67rem;
		}
	}
`;

export default KeraltyTabs;
