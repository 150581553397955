const nsSelect = {
	en: {
		guarantorReason: {
			underAge: 'Under-age',
			disabilities: 'Disabilities',
			senior: 'Senior',
		},
		patientRelationship: {
			self: 'Self',
			spouse: 'Spouse',
			naturalChild: 'Natural Child - Insured/Guarantor has financial responsibility',
			naturalChild2: 'Natural Child - Insured/Guarantor does not have financial responsibility (includes legally adopted child)',
			stepChild: 'Stepchild',
			fosterChild: 'Foster Child',
			ward: 'Ward of the court',
			employee: 'Employee',
			unknown: 'Unknown - Patient`s relationship to the insured/guarantor is other that any listed',
			handicapped: 'Handicapped Dependent',
			organ: 'Organ Donor',
			cadaver: 'Cadaver Donor',
			granchild: 'Granchild',
			nephew: 'Niece/Nephew',
			injured: 'Injured Plaintiff',
			sponsored: 'Sponsored Dependent',
			minor: 'Minor Dependent',
			parent: 'Parent',
			grandparent: 'Grandparent',
			other: 'Other'
		}
	},
	es: {
		guarantorReason: {
			underAge: 'Menor de edad ',
			disabilities: 'Discapacidad',
			senior: 'Adulto mayor'
		},
		patientRelationship: {
			self: 'Uno mismo',
			spouse: 'Esposo(a)',
			naturalChild: 'Hijo(a) Natural-Garante/Asegurado responde económicamente',
			naturalChild2: 'Hijo(a) Natural-Garante/Asegurado no responde económicamente',
			stepChild: 'Hijastro(a)',
			fosterChild: 'Hijo(a) adoptivo',
			ward: 'Responsable por orden judicial',
			employee: 'Empleado',
			unknown: 'Desconocido - Relación no listada',
			handicapped: 'Dependiente discapacitado',
			organ: 'Donante de órganos',
			cadaver: 'Donante de cadáver',
			granchild: 'Nieto(a)',
			nephew: 'Sobrino(a)',
			injured: 'Demandante lesionado',
			sponsored: 'Dependiente patrocinado',
			minor: 'Dependiente menor',
			parent: 'Padre/Madre',
			grandparent: 'Abuelo(a)',
			other: 'Otro'
		}
	}
};

export default nsSelect;
