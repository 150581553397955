import { useEffect } from 'react';
// 3RD PARTY
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../adapter/hooks';
// UI
import HomeContent from 'app/components/organisms/homeContent/homeContent';
import { Container, Loader } from '../components/templates';
// LOGIC
import ROUTES from '../ui-core/utils/routes';
import useAuth from '../hooks/useAuth';
import { useRouteQuery } from '../hooks/useRouteQuery';
//Interaction
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker } from 'adapter/chat/chatSlice';
import styled from 'styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { utcTime } from 'app/ui-core/utils/times';

const {
  GET_CARE: { appointments, carePrograms },
  MAIN: { getCare, myHealth, mentalHealth, wellness },
  MY_HEALTH: { labResults, referrals },
} = ROUTES;

const CenteredContainer = styled(Container)`
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    background: #f5f5f5;
  }
`;

const HomePage = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const isBALoading = useAppSelector((state) => state.chat.isLoading);
  const language = i18n.language.includes('en') ? 'en' : 'es';

  const { query } = useRouteQuery();
  const trigger = query.get('trigger');
  const { replace } = useHistory();
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState } = sanitasChatState;
  const { authUid } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (trigger) {
      switch (trigger) {
        case 'patient-support':
          dispatch(closeSymptomChecker());
          if (sanitasState.isShow && sanitasState.isSetted) {
            dispatch(sanitasAdapterMethods.shutdown());
          }
          setTimeout(async () => {
            await dispatch(
              sanitasAdapterMethods.setUp({
                name: `${user.displayName}`,
                email: `${user.email}`,
                state: `${user.state}`,
                currentTimeZone: `${utcTime()}`,
                authUid: `${authUid}`,
                sanitasAccountNumber: `${user.idEcw}`,
                gender: `${user.sex}`,
                birthday: `${user.birthdate}`,
                customAttributes: { cType: 'support' },
              })
            );
            await dispatch(sanitasAdapterMethods.show());
          });
          break;
        case 'chat-with-doctor':
          dispatch(closeSymptomChecker());
          if (sanitasState.isShow && sanitasState.isSetted) {
            dispatch(sanitasAdapterMethods.shutdown());
          }
          setTimeout(async () => {
            await dispatch(
              sanitasAdapterMethods.setUp({
                actionColor: undefined,
                backgroundColor: undefined,
                name: `${user.displayName}`,
                email: `${user.email}`,
                state: `${user.state}`,
                currentTimeZone: `${utcTime()}`,
                authUid: `${authUid}`,
                sanitasAccountNumber: `${user.idEcw}`,
                gender: `${user.sex}`,
                birthday: `${user.birthdate}`,
                customAttributes: { cType: 'provider' },
              })
            );
            await dispatch(sanitasAdapterMethods.show());
          });
          break;
      }
      replace({ search: '' });
    }
  }, [trigger, replace, dispatch, sanitasState, user]);

  return (
    <CenteredContainer>
      {isBALoading && <Loader />}
      <HomeContent
        getCare={getCare}
        myHealth={myHealth}
        referrals={referrals}
        labResults={labResults}
        carePrograms={carePrograms}
        language={language}
        mentalHealth={mentalHealth}
        user={user}
        appointments={appointments}
        wellness={wellness}
      />
    </CenteredContainer>
  );
};

export default HomePage;
