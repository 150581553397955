const nsMessages = {
	en: {
		emailCodeSent: 'Your verification code has been sent to your email.	Please validate to continue.',
		phoneCodeSent: 'Your verification code has been sent to your mobile	Please validate to continue.',
		televisitAccess: 'We have successfully validated your access to the service. Now you can access to "Televisit".',
		chatWithDocAccess: 'We have successfully validated your access to the service. Now you can access to "Chat with a doctor".',
		typeCodeMail:'Type the validation code sent to your email',
		typeCodeSms:'Type the validation code sent to your mobile',
		verifyCodeMail:'Your verification code was sent again. Please check your email.',
		verifyCodeSms:'Your verification code was sent again. Please check your mobile phone.',
		verification:'Verification',
		expiredCode:'Sorry, the verification code has expired. we need validate your data again',
		verifiedCode: 'Verification code confirmed',
		codeEntryLimit: 'Sorry, you have reached the wrong code entry limit. For your security, please wait 30 minutes to carry on with this action.',
		descEmail:'The authentication code has been sent to your email. Please validate to continue.',
		descMovil:'The authentication code has been sent to your phone. Please validate to continue.',
		sorryExpired:'Sorry, the verification code has expired. we need validate your data again',
		timeOut:'Sorry, you have reached the wrong code entry limit. For your security, please wait 30 minutes to carry on with this action.',
		resendEmailCode: 'A new verification code was sent, please check your email.',
		resendPhoneCode: 'A new verification code was sent, please check your mobile phone.',
		limitResendCodeByPhone: "Sorry, you have reached the code request limit. Please search the last code in your phone to continue or cancel to select another validation method. If you still haven't received your code, contact our support chat",
		limitResendCodeByEmail: "Sorry, you have reached the code request limit. Please search the last code in your email to continue or cancel to select another validation method. If you still haven't received your code, contact our support chat",
		messageUnblockAccount: 'Remeber that the changes applied on your password will be reflected in all your accounts.',
		preVerifiedCode: 'Sorry, a verification code was previously validated . For your security, please wait 30 minutes to carry on with this action.',
		limitRequestCode: 'Sorry you exceeded the code request limit. For your security, please wait 30 minutes to carry on with this action.',
		limitSentCode: 'Sorry you exceeded the code request limit. For your security, please wait 30 minutes to carry on with this action.',
		textState: "A new account creation process was started with the same data registered and we cannot continue.",
		twoFactorCodeSentByPhone: "The verification code has been sent to your phone.",
		twoFactorCodeSentByEmail: "The verification code has been sent to your email.",
		twoFactorCodeResendByPhone: "A new verification code was sent, please check your mobile",
		twoFactorCodeReSendByEmail: "Your verification code was sent again. Please check your email.",
		validateToContinue: "Please validate to continue.",
		twoFactorCodeExpired: "Sorry, the verification code has expired. We need validate your data again",
		unblockAccountTitle: "Your account has been blocked.",
		unblockAccountTextCodeRequest: "Because you exceeded the number of code requests. Please, click the option below to unblock your account",
		unblockAccountTextCodeError: "Because you exceeded the number of login attempts to MySanitas. Please, click the option below to unblock your account"
	},
	es: {
		emailCodeSent: 'Tu código de verificación ha sido enviado a tu correo electrónico. Por favor, valida para continuar.',	
		phoneCodeSent: 'Tu código de verificación ha sido enviado a tu telefono. Por favor, valida para continuar.',
		televisitAccess: 'Hemos validado exitosamente tu acceso al servicio. Ahora puedes acceder a "Televisita".',
		chatWithDocAccess: 'Hemos validado exitosamente tu acceso al servicio. Ahora puedes acceder a "Chatea con un doctor".',
		typeCodeMail:'Escribe el código de validación enviado a tu correo electrónico.',
		typeCodeSms:'Escribe el código de validación enviado a tu correo electrónico.',
		verifyCodeMail:'Tu código de verificación fue enviado nuevamente. Por favor revisa Tu correo electrónico.',
		verifyCodeSms:'Tu código de verificación fue enviado nuevamente. Por favor, revisa tu teléfono móvil.',
		verification:'Verificación',
		expiredCode:'Lo sentimos, tu código de verificación ha expirado, necesitamos validar tus datos nuevamente',
		verifiedCode: 'Código de verificación confirmado',
		codeEntryLimit: 'Lo sentimos, has alcanzado el límite de ingreso de códigos erróneos. Por tu seguridad, espera 30 minutos para volver a ejecutar esta acción.',
		descEmail:'Tu código de verificación ha sido enviado a tu correo electrónico. Por favor, valida para continuar.',
		descMovil:'Tu código de verificación ha sido enviado a tu telefono. Por favor, valida para continuar.',
		sorryExpired:'Lo sentimos, tu código de verificación ha expirado, necesitamos validar tus datos nuevamente',
		timeOut:'Lo sentimos, has alcanzado el límite de ingreso de códigos erróneos. Por tu seguridad, espera 30 minutos para volver a ejecutar esta acción',
		resendEmailCode: 'Un nuevo código de verificación fue enviado, por favor verifica tu correo electrónico',
		resendPhoneCode: 'Un nuevo código de verificación fue enviado, por favor verifica tu teléfono celular',
		limitResendCodeByPhone: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su teléfono para continuar o cancele para seleccionar otro método de validación. Si aún no ha recibido el código, puede contactar con nuestro chat de soporte',
		limitResendCodeByEmail: 'Lo sentimos, ha alcanzado el límite de solicitud de código, busque el último código en su correo electrónico para continuar o cancele para seleccionar otro método de validación. Si aún no ha recibido el código, puede contactar con nuestro chat de soporte',
		messageUnblockAccount: 'Recuerda que los cambios aplicados a tu contraseña se verán efectuados en todas tus cuentas.',
		preVerifiedCode: 'Lo sentimos, un código de verificación ya fue previamente validado. Por tu seguridad, espera 30 minutos para volver a ejecutar esta acción',
		limitRequestCode: 'Lo sentimos, excediste el limite de solicitud de codigo. Por tu seguridad, espera 30 minutos para volver a ejecutar esta acción.',

		limitSentCode: 'Lo sentimos, excediste el limite de solicitud de codigo. Por tu seguridad, espera 30 minutos para volver a ejecutar esta acción.',
		textState: "Se inició un nuevo proceso de creación de cuenta con los mismos datos registrados y no podemos continuar.",
		twoFactorCodeSentByPhone: "Tu código de verificación ha sido enviado a tu telefono.",
		twoFactorCodeSentByEmail: "Tu código de verificación ha sido enviado a tu correo electrónico.",
		twoFactorCodeResendByPhone: "Un nuevo código de verificación fue enviado, por favor verifica tu teléfono",
		twoFactorCodeReSendByEmail: "Un nuevo código de verificación fue enviado, por favor verifica tu correo electrónico",
		validateToContinue: "Por favor, valida para continuar.",
		twoFactorCodeExpired: "Lo sentimos, tu código de verificación ha expirado, necesitamos validar tus datos nuevamente",
		unblockAccountTitle: "Tu cuenta ha sido bloqueada",
		unblockAccountTextCodeRequest: "Excediste el número de solicitud de código. Por favor, haga clic en la opción a continuación para desbloquear su cuenta.",
		unblockAccountTextCodeError: "Excediste el número de intentos de inicio de sesión en MySanitas. Por favor, haga clic en la opción a continuación para desbloquear su cuenta."
	}
};

export default nsMessages;
