
import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';
import { Link } from 'react-router-dom';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

interface KeraltyTextProps {
	className?: string;
	link?: boolean;
	type?: 'label' | 'title' | 'subTitle' | 'info' | 'link' | 'tooltip' | 'big' | 'paragraph';
	color?: keyof (typeof theme.colors);
	bold?: boolean;
	pb?: boolean;
	children: any;
	onClick?: () => void;
	tooltip?: string;
	align?: 'left' | 'center' | 'right';
	fontSize?: string;
	width?: string;
	noMargin?: boolean;
	marginLeft?: string
	underline?: boolean;
	undecorated?: boolean;
	fontWeight?: string;
	[key: string]: any;
	noTextTransform?: boolean
}

const Text = ({
	type, link, children, bold = false, pb = false, underline, undecorated, noMargin, align, ...props
}: KeraltyTextProps) => {
	if (link) return <Link {...props}>{children}</Link>
	else if (type === 'title') return <h2 {...props}>{children}</h2>;
	else if (type === 'subTitle') return <h3 {...props}>{children}</h3>;
	else if (type === 'label') return <label {...props}>{children}</label>;
	else if (type === 'paragraph') return <p {...props}>{children}</p>
	else return <span {...props}>{children}</span>;
};

const KeraltyText = styled(Text) <KeraltyTextProps>`
	${({ type }) => !type ? 'font-size: 1rem;' : ''}
	margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '0px;'};

	${props => (props.type === 'title' || props.type === 'label') ? 'font-weight: bold;' : ''}

	${({ type, color, fontWeight, theme: { colors }, align, fontSize, noTextTransform }) => type === 'title' ? `
		font-family: 'Proxima Nova';
		font-size: ${fontSize ? fontSize : '2rem'};
		line-height: 3.25rem;
		font-weight: ${fontWeight ? fontWeight : '600 !important'};
		color: ${color ? color : colors.primary} !important;
		width: 100%;
		text-align: ${align ? align : 'center'} !important;
		text-transform: ${ noTextTransform ? 'none' : 'lowercase' };
		&::first-letter {
			text-transform: ${ noTextTransform ? 'none' : 'uppercase' };
		}
	`: ''}

	${({ type, fontSize, fontWeight, color, theme: { colors }, align }) => type === 'subTitle' ? `
		font-family: 'Proxima Nova';
		font-size: ${fontSize ? fontSize : '1.67rem'};
		line-height: 2rem;
		text-align: ${align ? align : 'center'};
		margin 0px;
		color: ${color ? color : colors.primary} !important;
		font-weight: ${fontWeight ? fontWeight : 'normal'};

		@media (max-width: 360px) {
			font-size: 1.0625rem;
		}
		@media (min-width: 360px) and (max-width: 414px) {
			font-size: 16px;
		}
	`: ''}

	${({ type, underline, width, align, fontSize, color, margin, fontWeight, theme: { colors } }) => type === 'info' ? `
		color: ${color ? color : 'black'};
		width: ${width ? width : '100%'};
		text-align: ${align ? align : 'center'};
		margin-top: ${margin};
		font-size: ${fontSize ? fontSize : '16px'};
		text-decoration: ${underline ? underline : ''};
		line-height: 1.58rem;
		font-weight: ${fontWeight ? fontWeight : 'normal'};
		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			color: ${color ?  color : colors.primary};
			width: ${width ? '100%' : ''};
		}
	`: ''}

	${({ type, align, fontWeight, fontSize, theme: { colors } }) => type === 'label' ? `
		font-size: 16px;
		text-align: ${align ? align : 'center'};
		line-height: 1.58rem;
		color: ${colors.primary};
		font-weight: ${fontWeight ? fontWeight : '600'};

		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			font-size: 1.6rem;
			line-height: 1.9rem;
		}
	`: ''}

	${({ type, fontSize, theme: { colors }, color }) => type === 'link' ? `
	font-size: ${fontSize ? fontSize : '1.17rem'};
		
		line-height: 1.42rem;
		color: ${color ? color : colors.primary} !important;
		text-decoration-line: underline;
		text-decoration-color: ${color ? color : colors.primary} !important;
		cursor: pointer;

		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			
			font-size: ${fontSize ? fontSize : '16px'} ;

			line-height: 1.7rem;
		}
	`: ''}

	${({ type, theme: { colors }, align }) => type === 'tooltip' ? `
		display: flex;
		align-items: ${align ? align : 'center'};
		color: ${colors.primary};
	`: ''}

	${({ type }) => type === 'big' ? `
		font-size: 2.75rem;

		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			font-size: 3.3rem;
		}
	`: ''}

	${({ type, fontWeight, capitalize }) => type === 'paragraph' ? `
		font-size: 1.17rem;
		font-style: normal;
		font-family: 'Proxima Nova';
		font-weight: ${fontWeight ? fontWeight : '600'};
		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			font-size: 16px;
			line-height: 1.7rem;
		}
		text-transform: capitalize;
		text-transform: ${ capitalize ? 'capitalize' : 'lowercase' };
		&::first-letter {
		 	text-transform: ${ capitalize ? 'capitalize' : 'uppercase' };
		 }
	` : ''}

	${({ color, theme: { colors } }) => color ? `
		color: ${colors[color]};
		text-underline: ${colors[color]};
	` : ''}
	${({ bold, weight }) => bold ? 'font-weight: bold;' :
		(bold === false ? 'font-weight: normal;' :
			(weight ? `font-weight: ${weight};` : '')
		)
	}
	${({ pb }) => pb ? 'padding-bottom: 1rem;' : ''}
	${({ underline }) => underline ? `text-decoration: underline;` : ''}
	${({ undecorated }) => undecorated ? 'text-decoration: none !important;' : ''}
	${({ onClick }) => onClick ? 'cursor: pointer;' : ''}


`;

export default KeraltyText;
