import { useState } from "react";

import { useAppDispatch, useAppSelector } from "adapter/hooks";
import { updateUnderstandingYourAnxiety } from "adapter/understandingAnxiety/understandingAnxiety.slice";
import { InfoAnxietyRegiter, UnderstandingYourAnxietyState } from "adapter/understandingAnxiety/understandingAnxiety.state";
import { AnxietyParameter, TypeAnxietyParameter } from "infrastructure/keraltyApi/models/mentalHealth";
import MentalHealth from "infrastructure/keraltyApi/repositories/mentalHealth";

export const useAnxiety = () => {

    const dispatch = useAppDispatch();
    const { register, stepInStorage } = useAppSelector<UnderstandingYourAnxietyState>(state => state.understandingYourAnxiety);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const saveInfoInStore = (data: Partial<InfoAnxietyRegiter>): void => {
        dispatch(updateUnderstandingYourAnxiety(data));
    }

    const getAnxietyParametersByType = async (type: TypeAnxietyParameter): Promise<AnxietyParameter[]> => {
        return await MentalHealth.getAnxietyParameters(type);
    } 

    return {
        saveInfoInStore,
        getAnxietyParametersByType,
        setIsLoading,
        isLoading,
        register,
        stepInStorage
    }
}