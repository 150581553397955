import { KeraltyText, Image } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import circleExclamation from '../../assets/icons/circle-exclamation2.svg';

import useIsMobile from 'app/hooks/useIsMobile';
import { TEXT_TO_REPLACE } from 'app/ui-core/utils/constants';


const IconExclamation = styled(Image)`
  width: 34px;
  margin-top: 40px;
  cursor: initial !important;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 24px;
    margin-top: 5px;
  }
`;

interface InsuranceNoValidatedProps {
  close?: any;
  scheduleText: string
}

const ModalMaintenance = ({
  close,
  scheduleText
}: InsuranceNoValidatedProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <KeraltyModal size={!isMobile ? "extraLongEighty" : "long"} textalign="center" style={{ width: isMobile ? '280px' : '473px' }}>
      <div
        style={{
          marginRight: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconExclamation src={circleExclamation} alt="" style={{ marginTop: 0, width: 50 }} />
        <Row mt={isMobile ? 1 : 2.3}>
          <KeraltyText
            type="title"
            fontWeight="600"
            fontSize="18px"
            color="#055293"
            style={{ lineHeight: '20px', marginTop: 0 }}
            noTextTransform
          >
            {t('maintenanceText').replace(TEXT_TO_REPLACE.SCHEDULE, scheduleText)}
          </KeraltyText>
        </Row>
        <Row
          noMargin
          style={{
            width: isMobile ? '255px' : '320px',
            marginTop: isMobile ? '-10px' : '10px',
            paddingBottom: '3%',
          }}
        >
          <KeraltyText type={'info'} color="#055293" fontSize="16px" noTextTransform>
            {t('maintenanceApologize')}
          </KeraltyText>
        </Row>
      </div>
    </KeraltyModal>
  );
};


export {
  ModalMaintenance,
};
