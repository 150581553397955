import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import appConfig from 'config';

import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../atoms';
import { SectionWidget } from 'app/components/molecules';

import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';

import labIcon from '../../../assets/icons/lab-results-blue.svg';
import referralsIcon from '../../../assets/icons/referrals-blue.svg';
import medicationsIcon from '../../../assets/icons/medicationby.svg';
import visitSumaryIcon from '../../../assets/icons/visitSumary.svg';
import inmunizationsByIcon from '../../../assets/icons/inmunizationsBy.svg';



import ROUTES from '../../../ui-core/utils/routes';

import {
	closeSymptomChecker,
	openSymptomChecker,
	showChat,
} from 'adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../hooks/useAuth';
import useIsMobile from '../../../hooks/useIsMobile'
import { shutdown, show, setUp } from 'adapter/sanitasChat/sanitasChat';

import i18n from "i18next";
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import { userSelectors } from 'adapter/user/userSelectors';
import isCordova from 'cordova';
import { utcTime } from 'app/ui-core/utils/times';
import { useMediaQuery } from '@mui/material';

const { MAIN, MY_HEALTH: CURRENT } = ROUTES;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };


const linkToTelevisit: string = appConfig.TELADOC_URL;

interface IconSizes {
	width: number;
	height: number;
}

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 10px;
	};
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }
`;

const LeftColumn = styled(Column)`
	justify-content: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const RightColumn = styled(Column)`
	justify-content: flex-start;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;
const MidleColumn = styled(Column)`
	justify-content: center;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const CenterColumn = styled(Row)`
    align-items: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 12.75rem;
  height: auto;
	width: 25.5rem;
	margin: 1.5rem 1.455rem;
	border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;

	h2 {
		margin: 0rem;
		font-size: 1.34rem;
		line-height: 1.34rem;
	}
	p {
		margin: 0rem;
		font-size: 1rem;
		line-height: 1.17rem;
		color:gray;
		text-transform: none;
		font-weight: 400;
		
	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const GetCareNowPage = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const { user } = useAuth();
	const isScreenMobile = useMediaQuery('(max-width:600px)');
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const chatState = useAppSelector((state) => state.chat.symptomChecker);
	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isChatOpen = chatState.isOpen;
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const isMobile = useIsMobile();
	const { authUid } = useAppSelector(state => state.user)


	const handleTalkToDoctor = async () => {
		dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			dispatch(shutdown());
		}
		await setTimeout(async () => {
			await dispatch(setUp({
				actionColor: undefined,
				backgroundColor: '#055293',
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'provider' },
			}));
			await dispatch(show());
		}, 500);
	};

	const handleLabResults = () => {
		push(`/${MAIN.myHealth}/${CURRENT.labResults}`);
	};
	const handleCurrent = () => {
		push(`/${MAIN.myHealth}/${CURRENT.currentMedication}`);
	};
	const handleReferrals = () => {
		push(`/${MAIN.myHealth}/${CURRENT.referrals}`);
	};
	const handleVisit = () => {
		push(`/${MAIN.myHealth}/${CURRENT.visitSummary}`);
	};
	const handleInmunizations = () => {
		push(`/${MAIN.myHealth}/${CURRENT.immunization}`);
	};

	const handleTelevisit = () => {
		if (isActiveCoverage || window.KERALTY_CONFIG) {
			isCordova ? window.cordova.InAppBrowser.open(linkToTelevisit, '_system') : window.open(linkToTelevisit, '_blank')
		} else {
			//push(`/${MAIN.getCare}/${GET_CARE.getCareNow}/${GET_CARE.televisit}`)
		}
	};

	return (
		<RightContent fullHeight>
			<IconLinkRow
				onClick={goBack}
				color="accent"
				icon={arrowAltCircleLeftIcon}
				iconAlt={t('imgNicons.backArrow')}
				text={t('routes.back')}
			/>
			<Row>
				<TitleContiner lg={12}>
					<KeraltyText type="title">{t('myHealth.title')}</KeraltyText>
				</TitleContiner>
			</Row>

			<Row style={{ display: 'flex', justifyContent: 'center' }}>
			<div
          		style={{
            		width: isMobile ? '100%' : '',
            		display: 'flex',
            		justifyContent: 'center',
            		flexDirection: isScreenMobile ? 'column' : 'initial',
          		}}
        	>
				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						<Card onClick={handleLabResults}>
							<SectionWidget 
								img={labIcon} 
								text={capitalizeJustFirst(t('myHealth.labNimgingResults'))} 
								description={capitalizeJustFirst(t('myHealth.labText'))} 
								alt={t('imgNicons.chatDoctorIcon')} 
							/>
						</Card>
					</CenteredWrapper>
				</Column>

				<LeftColumn lg={4} md={4} sm={0} style={{ display: 'flex' }}>
					<GetCareNowButton color="light" squareRadius={true} onClick={handleLabResults}>
						<span >
							<Image
								style={{  marginBottom: '10px' }}
								src={labIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')} />
							<KeraltyText type="title" fontWeight={'400'} style={{ paddingBottom: 10, fontWeight: 'normal' }}>{t('myHealth.labNimgingResults')} </KeraltyText>
							<KeraltyText type="paragraph">{(t('myHealth.labText'))}</KeraltyText>
						</span>
					</GetCareNowButton>
				</LeftColumn>

				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						<Card onClick={handleCurrent}>
							<SectionWidget 
								img={medicationsIcon} 
								text={capitalizeJustFirst(t('myHealth.current'))} 
								description={capitalizeJustFirst(t('myHealth.currentText'))} 
								alt={t('imgNicons.chatDoctorIcon')} 
							/>

						</Card>
					</CenteredWrapper>
				</Column>

				<MidleColumn lg={4} md={4} sm={0} style={{ display: 'flex' }}>
					<GetCareNowButton color="light" squareRadius={true} onClick={handleCurrent}>
						<span>
							<Image
								style={{  marginBottom: '10px' }}
								src={medicationsIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')} />
							<KeraltyText type="title" fontWeight={'400'} style={{ paddingBottom: 10, fontWeight: 'normal' }}>{t('myHealth.current')} </KeraltyText>
							<KeraltyText type="paragraph" >{(t('myHealth.currentText'))}</KeraltyText>
						</span>
					</GetCareNowButton>
				</MidleColumn>

				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						<Card onClick={() => handleReferrals()}>
							<SectionWidget 
								img={referralsIcon} 
								text={capitalizeJustFirst(t('myHealth.referrals'))} 
								description={capitalizeJustFirst(t('myHealth.referralsText'))} 
								alt={'televisit'} 
							/>
						</Card>
					</CenteredWrapper>
				</Column>

				<RightColumn lg={4} md={4} sm={0} style={{ display: 'flex' }}>
					<GetCareNowButton color="light" squareRadius={true} onClick={handleReferrals}>
						<span>
							<Image
								style={{  marginBottom: '10px' }}
								src={referralsIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')} />
							<KeraltyText type="title" fontWeight={'400'} style={{ paddingBottom: 10, fontWeight: 'normal' }}>{t('myHealth.referrals')} </KeraltyText>
							<KeraltyText type="paragraph" >{(t('myHealth.referralsText'))}</KeraltyText>
						</span>
					</GetCareNowButton>
				</RightColumn>
				</div>
			</Row>

			<Row style={{ justifyContent: 'center', marginTop: '-10px', marginBottom: '30px' }}>
				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						<Card onClick={handleVisit}>
							<SectionWidget 
								img={visitSumaryIcon} 
								text={capitalizeJustFirst(t('myHealth.visitSummary'))} 
								description={capitalizeJustFirst(t('myHealth.visitText'))} 
								alt={t('imgNicons.chatDoctorIcon')} 
							/>

						</Card>
					</CenteredWrapper>
				</Column>

				<LeftColumn lg={4} md={4} sm={0}>
					<GetCareNowButton color="light" squareRadius={true} onClick={handleVisit}>
						<span>
							<Image
								style={{ marginTop: '-15px', marginBottom: '10px' }}
								src={visitSumaryIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')} />
							<KeraltyText type="title" fontWeight={'400'} style={{ paddingBottom: 10, fontWeight: 'normal' }}>{t('myHealth.visitSummary')} </KeraltyText>
							<KeraltyText type="paragraph" >{(t('myHealth.visitText'))}</KeraltyText>
						</span>
					</GetCareNowButton>
				</LeftColumn>

				<Column lg={0} md={0} sm={12}>
					<CenteredWrapper>
						<Card onClick={() => handleInmunizations()}>
							<SectionWidget 
								img={inmunizationsByIcon} 
								text={capitalizeJustFirst(t('myHealth.immunizations'))} 
								description={capitalizeJustFirst(t('myHealth.InmunizationsText'))} 
								alt={'televisit'} 
							/>

						</Card>
					</CenteredWrapper>
				</Column>
				<RightColumn lg={4} md={4} sm={0} noPadding>

					<GetCareNowButton color="light" squareRadius={true} onClick={handleInmunizations}>
						<span>
							<Image
								style={{ marginTop: '-15px', marginBottom: '10px' }}
								src={inmunizationsByIcon}
								width={sizesUserChatIcon.width}
								height={sizesUserChatIcon.height}
								alt={t('imgNicons.chatDoctorIcon')}
							/>
							<KeraltyText type="title" fontWeight={'400'} style={{ paddingBottom: 10, fontWeight: 'normal' }}>{t('myHealth.immunizations')} </KeraltyText>
							<KeraltyText type="paragraph" >{(t('myHealth.InmunizationsText'))}</KeraltyText>
						</span>
					</GetCareNowButton>
				</RightColumn>
			</Row>
		</RightContent>
	);
};

export default GetCareNowPage;
