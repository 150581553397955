import FORMATS from 'app/ui-core/utils/formats';
import moment from 'moment';
import { AppointmentsService } from '../../../domain/useCases/contracts/appointmentsService';

const appointmentMapper = {
	mapToCreateToken: (domainObject: Parameters<AppointmentsService['createToken']>[0]) => {
		const { idEcw, firstName, lastName, birthdate, email, mobile, state } = domainObject;
		return {
      memberId: idEcw,
      firstName,
      lastName,
      email,
      state,
      dateOfBirth: moment(birthdate).format(FORMATS.date),
      phoneNumber: {
        countryDialingCode: '+1',
        number: mobile.replace(/[{()}]|-/g, ''),
      },
    };
	}
};

export default appointmentMapper;


// UI => API    -    prefix MAP TO
// UI <= API    -    prefix MAP FROM
