import { useTranslation } from 'react-i18next';
import beWell from '../../../assets/icons/bewell.svg';
import fhs from '../../../assets/icons/fhs.svg';
import ask from '../../../assets/icons/ask.svg';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Container } from '@mui/material';
import styled from 'app/ui-core/styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { CenteredWrapper, Column, Loader, Row } from 'app/components/templates';
import { Image, KeraltyButton, KeraltyText } from 'app/components/atoms';
import { useAppSelector } from 'adapter/hooks';
import chatSelectors from 'adapter/chat/chatSelectors';
import { SectionWidget } from 'app/components/molecules';
import theme from 'app/ui-core/utils/theme';
import { ActionLink, IconLinkRow } from 'app/components/templates/iconLinkRow';
import ROUTES from '../../../ui-core/utils/routes';
import { KeraltyModal } from 'app/components/molecules';
import { useMemo, useState } from 'react';
import BeWellMain from './BewellMain';

import AppointmentsFormFHS from './AppointmentsFormFHS';
import { AppointmentsFHS } from './AppointmentsFHS';
import i18n from 'app/ui-core/i18n';
import UpcomingBewell from './BeWell/upcomingBeWell';
import PreviousBeWell from './BeWell/PreviousBeWell';
import useIsMobile from 'app/hooks/useIsMobile';

const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;


const RightContent = styled(Row)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0;
  }
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    // margin-top: 5vh;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  height: auto;
  width: 28.5rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;
  align-items: flex-start;
  padding-top: 32px !important;
  display: flex;
  h2 {
    margin: 0rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

const OptionColumn = styled(Column)`
  padding-right: 2px;
  padding-left: 2px;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const CardsRow = styled(Row)`
  justify-content: center;
  padding: 0rem;
`;

const MHAppointments: React.FC = () => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(chatSelectors.selectIsLoading);
  const { goBack, push } = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { subTab, subSubTab } = useParams();
  const isMobile = useIsMobile();



  const goToBewellProgram = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.appointments}/${CURRENT.beWellProgram}`);
  };

  const goToFloridaHealthCareSystem = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.appointments}/${CURRENT.appointmentsFHS}`);
  };

  const title = useMemo(
    () =>
      t(
        `${!subTab
          ? t('getCare.appointments')
          : subTab === CURRENT.appointmentsFHS && subSubTab === undefined
            ? t('mentalHealth.floridaHealthcareSystem')
            : ''
        }`
      ),
    [t, subTab]
  );

  const language = i18n.language === 'en';

  return (
    <>
      {isLoading && <Loader />}
      {openModal && <InfoModal close={setOpenModal} />}

      <RightContent
        fullHeight
        style={{
          margin: 5,
        }}
      >
        <div style={{ marginLeft: 10 }}>
          <IconLinkRow
            onClick={goBack}
            color="accent"
            icon={arrowAltCircleLeftIcon}
            iconAlt={t('imgNicons.backArrow')}
            text={t('routes.back')}
          />
        </div>
        {title && (
          <Row>
            <TitleContiner lg={12}>
              <KeraltyText type="title" align={isMobile ? 'left' : 'center'} style={{ textTransform: 'capitalize' }}>
                {title}
              </KeraltyText>
            </TitleContiner>
          </Row>
        )}
        {!subTab && (
          <>
            <CardsRow noMargin>
              <Column lg={0} md={0} sm={12}>
                <CenteredWrapper>
                  <Card onClick={goToBewellProgram}>
                    
                    <SectionWidget
                      img={beWell}
                      text={t('mentalHealth.beWellProgram')}
                      description={t('mentalHealth.beWellProgramText')}
                      alt={t('imgNicons.bookAppointmentIcon')}
                      descriptionFontSize={'12px'}
                      
                    />
                  </Card>
                </CenteredWrapper>
              </Column>
              <OptionColumn lg={12} md={12} sm={12} style={{ display: isMobile ? 'none' : 'grid', placeItems: 'center' }}>
              {/* <OptionColumn lg={6} md={6} sm={0}> */}
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <GetCareNowButton
                    color="light"
                    squareRadius={true}
                    onClick={goToBewellProgram}
                    style={{ borderRadius: '10px' }}
                  >
                    <span>
                      <Image
                        src={beWell}
                        width={theme.sizes.icon.extraBig}
                        height={theme.sizes.icon.extraBig}
                        alt={t('imgNicons.bookAppointmentIcon')}
                      />

                      <KeraltyText
                        type="title"
                        fontWeight={'400'}
                        style={{ fontWeight: 'normal', fontSize: '16px' }}
                        noTextTransform
                      >
                        {t('mentalHealth.beWellProgram')}
                      </KeraltyText>
                      <KeraltyText
                        type="paragraph"
                        color={'#747474'}
                        style={{
                          color: '#7A7A7A',
                          fontSize: '12px',
                          lineHeight: '18px',
                          fontWeight: '400',
                          width:'250px'
                        }}
                      >
                        {t('mentalHealth.beWellProgramText')}
                      </KeraltyText>
                    </span>
                  </GetCareNowButton>
                </Row>
              </OptionColumn>

              {/* <Column lg={0} md={0} sm={12} style={{ marginTop: 15, marginBottom: 20 }}>
                <CenteredWrapper>
                  <Card onClick={goToFloridaHealthCareSystem}>
                    <SectionWidget
                      img={fhs}
                      text={t('mentalHealth.floridaHealthcareSystem')}
                      description={t('mentalHealth.floridaHealthcareSystemText')}
                      alt={t('imgNicons.locationIcon')}
                    />
                  </Card>
                </CenteredWrapper>
              </Column>
              <OptionColumn lg={6} md={6} sm={0}>
                <Row>
                  <GetCareNowButton
                    color="light"
                    squareRadius={true}
                    onClick={goToFloridaHealthCareSystem}
                    style={{ minHeight: language ? '12.75rem' : '18.75rem' }}
                  >
                    <span>
                      <Image
                        src={fhs}
                        width={theme.sizes.icon.extraBig}
                        height={theme.sizes.icon.extraBig}
                        alt={t('imgNicons.bookAppointmentIcon')}
                      />

                      <KeraltyText
                        type="title"
                        fontWeight={'400'}
                        style={{ fontWeight: 'normal', fontSize: '16px' }}
                        noTextTransform
                      >
                        {t('mentalHealth.floridaHealthcareSystem')}
                      </KeraltyText>
                      <KeraltyText
                        type="paragraph"
                        color={'#747474'}
                        style={{
                          color: '#7A7A7A',
                          fontSize: '12px',
                          lineHeight: '18px',
                          fontWeight: '400',
                        }}
                      >
                        {t('mentalHealth.floridaHealthcareSystemText')}
                      </KeraltyText>
                    </span>
                  </GetCareNowButton>
                </Row>
              </OptionColumn> */}
            </CardsRow>

            <Row>
              {/* <ActionLink
                onClick={() => setOpenModal(true)}
                color="accent"
                iconSize={1.5}
                icon={ask}
                iconAlt={t('imgNicons.backArrow')}
                text={t('mentalHealth.chooseService')}
                bold
                fontSize={14}
                centerOnScreen
              /> */}
            </Row>
          </>
        )}



        {subTab === CURRENT.appointmentsFHS && subSubTab === undefined && <AppointmentsFHS />}
        {subTab === CURRENT.appointmentsFHS && subSubTab === CURRENT.appointmentsFormFHS && (
          <AppointmentsFormFHS />)}
        {subTab === CURRENT.beWellProgram && subSubTab === undefined && <BeWellMain />}

        {subTab === CURRENT.beWellProgram && subSubTab === CURRENT.upcomingAppointments && (
          <UpcomingBewell />
        )}
        {subTab === CURRENT.beWellProgram && subSubTab === CURRENT.previousAppointments && (
          <PreviousBeWell />
        )}
      </RightContent>
    </>
  );
};




const KeralTextButton = styled(KeraltyText)`
  background: #ffffff !important;
  line-height: 14px;
  border-color: #3CA70D;
  border-radius: 25px;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  color: #3CA70D;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline #3CA70D;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
    color: #3CA70D !important;
    font-weight: 600;
    text-decoration: underline #3CA70D;
    font-size: 16px;
  }
`;

const InfoModal = ({ close }: { close: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <KeraltyModal style={{ width: isMobile ? '300px' : '699px', height: isMobile ? '496px' : '462px' }}>
      <div style={{ display: 'grid', placeItems: 'center' }}>
        <Row noMargin style={{ paddingBottom: '2%', marginTop: isMobile ? '10%' : '2%' }}>
          <KeraltyText type="title" fontWeight={'600'} color="#022F58" fontSize={isMobile ? "18px" : "24px"} style={{lineHeight: isMobile ? '21.92px' : '29.23px'}}>
            {t('mentalHealth.areYouSure')}
          </KeraltyText>
        </Row>
        <section style={{marginRight: isMobile ? 15 : 25, marginLeft: isMobile ? 15 : 25}}>
          <Row noMargin style={{ paddingBottom: '5%' }}>
            <KeraltyText type={'title'} color={'#055293'} fontSize={ isMobile ? "16px" : "20px" } align="center" noTextTransform style={{ margin: 0, lineHeight: isMobile ? '19.49px' : '24.36px' }}>
              {`${t('mentalHealth.fhs')}:`}
            </KeraltyText>
            <KeraltyText type={'info'} color={'#055293'} fontSize={ isMobile ? "16px" : "20px" } align="center" style={{lineHeight: isMobile ? '19.49px' : '24.36px'}}>
              {t('mentalHealth.fhsDefinition')}
            </KeraltyText>
          </Row>
          <Row noMargin style={{ paddingBottom: '5%' }}>
            <KeraltyText type={'title'} color={'#055293'} fontSize={ isMobile ? "16px" : "20px" } align="center" noTextTransform style={{ margin: 0, lineHeight: isMobile ? '19.49px' : '24.36px' }}>
              {`${t('mentalHealth.beWell')}:`}
            </KeraltyText>
            <KeraltyText type={'info'} color={'#055293'} fontSize={ isMobile ? "16px" : "20px" } align="center" style={{lineHeight: isMobile ? '19.49px' : '24.36px'}}>
              {t('mentalHealth.beWellDefinition')}
            </KeraltyText>
          </Row>
        </section>

        <KeralTextButton
          onClick={() => close((value) => !value)}
          type="label"
          style={{ paddingBottom: '7%', marginTop: isMobile ? '12%' : 0}}
        >
          {t('myAccount.close')}
        </KeralTextButton>
      </div>
    </KeraltyModal>
  );
};

export default MHAppointments;
