import { useState, useEffect, useMemo, useCallback, createRef, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Select from 'react-select';
import {
  Row,
  Column,
  IconLinkRow,
  Loader,
  FormBox,
  Container,
} from '../../../components/templates';

import { Divider, KeraltyButton, KeraltyText } from '../../../components/atoms';
import { EcwRepository } from '../../../../infrastructure/keraltyApi/repositories/ecw';
import MyInsuranceCards from './myInsuranceCards';
import EmptyIcon from '../../atoms/emptyIcon';
import { useTranslation } from 'react-i18next';
import { getInsuranceListData, cleanError } from '../../../../adapter/myAccount/myAccountSlice';
import {
  selectmyAccountInsuranceInfo,
  selectmyAccountLoading,
} from '../../../../adapter/myAccount/myAccountSelector';
import { useForm } from 'react-hook-form';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { useAppSelector, useAppDispatch } from 'adapter/hooks';

import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import { InsuranceInfo } from '../../../../domain/entities/insuranceInfo';

import styled from '../../../ui-core/styled-components';
import { KeraltyField, KeraltyDotChatTrigger } from 'app/components/molecules';
import { formatDiagnosticsWithColorAndContext } from 'typescript';
import { AvailityInfo, NewUserInfo } from 'domain/entities/availityInfo';
import { AvailityInfoSchema } from '../../../ui-core/schemas/availityInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import useIsMobile from '../../../hooks/useIsMobile';
import FormUpdateInsurance from './formUpdateInsurance';
import { ReactComponent as SupportChatIcon2 } from '../../../assets/icons/newChat.svg';
import { ReactComponent as SupportChatIcon } from '../../../assets/icons/chat 2.svg';
import { closeSymptomChecker } from '../../../../adapter/chat/chatSlice';
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';

import { utcTime } from 'app/ui-core/utils/times';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

type FormType = AvailityInfo;

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  margin: 3rem;
  align-items: flex-start;
  background: transparent;
  height: 105%;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    height: auto;
  }
`;

const CardsContainer = styled(Row)`
  margin: 0rem;
`;

const EmptyCards = styled(Column)`
  margin-top: 9rem !important;
  flex-direction: column;
  align-items: center !important;
  width: 100%;
`;

const TextTitle = styled(KeraltyText)`
  font-size: 2rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    font-weight: 400;
  }
`;

const TextSubTitle = styled(KeraltyText)`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0rem;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    display: none;
  }
`;

const dataInsuranceUser = async (authId, state) => {
  const result = await EcwRepository.getInsuranceListDataByState(authId, state);
  return result;
};

const MyInsurance = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { goBack } = useHistory();

  const insuranceInfo = useAppSelector(selectmyAccountInsuranceInfo);
  const isLoading = useAppSelector(selectmyAccountLoading);
  const dispatch = useAppDispatch();

  const [cards, setCards] = useState<NewUserInfo[] | null>(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [isFb, setIsFb] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [isTn, setIsTn] = useState(false);
  const [isSc, setIsSc] = useState(false);
  const [newCards, setNewCards] = useState<any>(null);
  const [onLoading, setOnLoading] = useState(true);
  const childRef = createRef();
  const isMobile = useIsMobile();
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState } = sanitasChatState;
  const { authUid } = useAppSelector((state) => state.user);

  const getNewInsurancedata = async () => {
    try {
      setNewCards(await dataInsuranceUser(user.authUid, user.state));
      setOnLoading(false);
    } catch (e: any) {
      setOnLoading(false);
    }
  };

  const triggerPatientSupport = useCallback(async () => {
    dispatch(closeSymptomChecker());
    if (sanitasState.isShow && sanitasState.isSetted) {
      await dispatch(sanitasAdapterMethods.shutdown());
    }

    await setTimeout(async () => {
      await dispatch(
        sanitasAdapterMethods.setUp({
          actionColor: '#00cdac',
          backgroundColor: '#00cdac',
          name: `${user.displayName}`,
          email: `${user.email}`,
          state: `${user.state}`,
          currentTimeZone: `${utcTime()}`,
          authUid: `${authUid}`,
          sanitasAccountNumber: `${user.idEcw}`,
          gender: `${user.sex}`,
          birthday: `${user.birthdate}`,
          customAttributes: { cType: 'support' },
        })
      );
      await dispatch(sanitasAdapterMethods.show());
    }, 500);
  }, [dispatch]);

  //console.log('sessionstorage', sessionStorage)
  /*	useEffect(() => {
		
		if (
			(!insuranceInfo) ||
			(insuranceInfo && !insuranceInfo.some((info) => {
				return `${info.patientId}` === `${user.idEcw}`;
			}))
		) {
			dispatch(getInsuranceListData(user.idEcw));
		} else if (insuranceInfo && !cards) {
			setCards(insuranceInfo);
		}
		return () => {
			dispatch(cleanError());
		}
	}, [dispatch, insuranceInfo, user, cards, t])  */

  useEffect(() => {
    setShowUpdate(
      KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.SHOW_FORM) === 'true' ? true : false
    );
  }, [t]);
  useEffect(() => {
    getNewInsurancedata();
  }, []);

  const printCallback = useCallback(
    (e) => {
      setShowUpdateForm(e);
    },
    [showUpdateForm]
  );

  const setValuesByMemberId = (memberId: string) => {
    const insuranceFound = (newCards as any[]).find((item) => item.memberId === memberId);

    setIsPrimary(insuranceFound?.isPrimary);
    setIsSc(insuranceFound?.isSc);
    setIsTn(insuranceFound?.isTn);
    setIsFb(insuranceFound?.isFb);
  };

  return (
    <RightContent bg="light">
      <IconLinkRow
        onClick={showUpdateForm ? () => setShowUpdateForm(false) : goBack}
        color="accent"
        icon={arrowAltCircleLeftIcon}
        iconAlt={t('imgNicons.backArrow')}
        text={t('routes.back')}
      />
      <TextTitle type={'title'} align={isMobile ? 'left' : 'center'}>
        {isLoading ? '' : showUpdateForm ? t('myAccount.update') : t('myAccount.myInsurance')}
      </TextTitle>
      {onLoading && <Loader />}
      {!onLoading && newCards && newCards.length > 0 && (
        <>
          {!showUpdateForm ? (
            <CardsContainer>
              <MyInsuranceCards
                data={newCards}
                handleShowForm={printCallback}
                setValuesByMemberId={setValuesByMemberId}
              />
            </CardsContainer>
          ) : (
            <div style={!isMobile ? { width: '70%', marginLeft: '15%' } : {}}>
              <FormUpdateInsurance
                state={user?.state}
                memberId={user?.idEcw}
                insuranceName={newCards[0].insuranceName}
                handleShowForm={printCallback}
                isFb={isFb}
                isTn={isTn}
                isSc={isSc}
                isPrimary={isPrimary}
              />
            </div>
          )}
        </>
      )}
      {/*!isLoading && (!cards || cards.length === 0) && (<EmptyCards><EmptyIcon text={t('myAccount.insurancesEmpty')} /> </EmptyCards>)*/}

      {!onLoading && isMobile ? (
        <div style={{ marginTop: '5%', width: '100%', justifyContent: 'center' }}>
          {/*           <KeraltyDotChatTrigger
            color={'primary'}
            colordot={'primary'}
            colorlabel={'primary'}
            label={t('button.supportChat')}
            position={isMobile}
            right={false}
            svg={SupportChatIcon2}
            onClick={() => triggerPatientSupport()}
          /> */}
        </div>
      ) : (
        !onLoading && null
        /* 	<div style={{ marginBottom: '-5%', marginLeft: '10%' }}>
						<KeraltyDotChatTrigger
							color={'primary'}
							colordot={'primary'}
							colorlabel={'primary'}
							label={t('button.supportChat')}
							position={false}
							right={false}
							svg={SupportChatIcon}
							onClick={() => triggerPatientSupport()} 
						/>
					</div> */
      )}
    </RightContent>
  );
};

export default MyInsurance;
