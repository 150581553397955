import { UserAvailityService } from './contracts/userAvailityService';
import { UserAvaility } from '../entities/userAvaility';

export const sendDataAvaility = async (userAvailityServices: UserAvailityService, userData: UserAvaility) => {
    const response = await userAvailityServices.sendUserData(userData);
    return response;
};

const userAvailityUseCases = {
    sendDataAvaility,
}

export default userAvailityUseCases;
  