const nsNotFound = {
	en: {
		pageNotFound: 'Page not found.',
		pageNotFoundSubTitle: 'We couldn\'t find the page you are looking for.'
	},
	es: {
		pageNotFound: 'Página no encontrada.',
		pageNotFoundSubTitle: 'No podemos encontrar la página que solicitó.'
	}
};

export default nsNotFound;
