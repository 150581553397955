import { IInsurancePaymentResponse } from 'domain/entities/paymentInfo';

export const SERVICES_TYPES = {
  VIDEO_CALL: 'Videocall',
  CHAT_WITH_DOCTOR: 'ChatWithDoctor'
};

export const VARIABLE_TYPES = {
  SESSION_ID: 'session_id',
  SESSION_URL: 'url_session',
  SESSION: 'session',
}; 

export const ERROR_CODES = ['21', '25', '500']; // Error codes return from service to logout user

export enum PaymentCode {
  default_code = 0,
  modal_televisit_pay = 1,
  modal_insurance_not_validated = 2,
  modal_insurance_inactived = 3,
  modal_pcp_no_validated = 4,
  previous_transaction = 5,
  impossible_to_create_new_payment = 6, // transaction in proccess
  transaction_in_process = 7,
  transaction_completed = 8,
  cant_continue_with_a_new_payment = 9,
  unknown_value_co_insurance=24,
}

export const SESSION_STATUS = {
  SUCCEEDED: 'completed',
  EXPIRED: 'canceled',
  IN_PROCESS: 'in_process',
  PROCESSING: 'pending',

  CREATED: 'created',
  REJECTED: 'rejected',
};

export const defaultValue: IInsurancePaymentResponse = {
  service: '',
  cost: 0,
  quantity: 0,
  tax: 0,
  subtotal: 0,
  total: 0,
  totalTax: 0,
  code: PaymentCode.default_code,
  message: '',
  transactionId: 0,
  transactionCreatedDate: 0,
  patientInformation: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobile: '',
    sex: '',
    homePhone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    ssn: null,
  },
};

export const EXPIRATION_TIMES = {
  PAYMENT_CREATED: 30,
};
