import appConfig from 'config';
import AuthRepository from '../../infrastructure/keraltyApi/repositories/auth';
export default class Chat {
  async initChat(payload) {
    let isLoaded;

    // const plugStateId = "611309841a52b";
    // const symptomCheckId = "6078a07f044e6";

    // return new Promise((resolve, reject): any => {
    //   return setTimeout(() => resolve({ data: { name: 'Orlando' } }), 100000);
    // });

    const { THEME: theme, LAN } = appConfig.SYMPTOM_CHECKER;

    const symptomCheckerConfig = (payload.lng || '').includes('es') ? LAN.ES : LAN.EN;

    //@ts-ignore
    //console.log('SenselyWidget', SenselyWidget);

    let response = { access_token: '' };

    try {
      response = await AuthRepository.senselyAuthentication({
        locale: (payload.lng || '').includes('es') ? 'es' : 'en',
      });
    } catch (e) {
      response = { access_token: '' };
    }
    const { access_token: token } = response;
    //@ts-ignore
    return await SenselyWidget.init({
      theme,
      token,
      lang: payload.lng || 'en', // User language - en, ar, cn etc.
      procedureId: symptomCheckerConfig.PROC_ID,
      conversationData: {
        // Optional additional data to control the conversation
        userInfo: {
          usertype: !payload.isPublic ? 'nonpublic' : 'public',
          ...(payload.userInfo || {}),
        },
      },
    })
      .then(function (success) {
        // If you need to perform an action once the widget has been initialized successfully
        isLoaded = true;
        return isLoaded;
      })
      .catch(function (err) {
        // If the widget fails to initialize for some reason and you need to catch and.or display the error
      });
  }
}
