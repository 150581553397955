import PublicHttpClient from '../publicHttpClient';
// DOMAIN
import { UserService } from '../../../domain/useCases/contracts/userService';

const endpoint = 'users';

const UsersRepository: UserService = {
	isEmailAvailable: (email: string, state: string) => {
		return PublicHttpClient.get(`${endpoint}/isemailavailable/${email}/${state}`);
	},
	isOnboardingValidation: (email: string, state: string) => {
		PublicHttpClient.defaults.headers['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM2OTkxODIsInN1YiI6IkxPR0lOU1NPIiwiaXNzIjoiQkFDS0VORFNTTyIsInVzZXJSb2xlIjoiU1BFQ0lBTCIsImV4cGlyZUluIjotMX0.q7dXMg2K5P4Wm-YasdpYggAo2C7OXHN09s9mBZT3ayg`;
		return PublicHttpClient.get(`${endpoint}/onboardingValueByEmail/${email}/${state}`);
	},
	 updateOnboardingStatus: (email: string, state: string, status:boolean) => {
		return PublicHttpClient.get(`${endpoint}/updateOnboardingByEmail/${email}/${state}/${status}`);
	}
};


export default UsersRepository;
