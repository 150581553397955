import styled from 'styled-components';

export const ContainerBubbleButton = styled.div`
  min-height: 40px;
  width: 82px;
  cursor: pointer;
  // margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
export const Text = styled.p`
  color: #004a76;

  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 1.5px;
`;
