import { userActions } from '../../adapter/user/userSlice';

export const ITrySSOLogin = async (token, dispatch) => {
	return await dispatch(userActions.trySSOLogin(token));
};

export const ITryLogin = async (email, password, state, dispatch) => {
	const result = await dispatch(userActions.tryLogin({ email, password, state }));
	setTimeout(() => dispatch(userActions.cleanError()), 4 * 1000);
	return result;
};

export const ITryLogout = async (dispatch) => {
	return await dispatch(userActions.tryLogout());
};
