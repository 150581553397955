import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, KeraltyButton, KeraltyText } from './atoms';
import KeraltyModal from './molecules/keraltyModal';
import { Container, Row, SpaceWrapper } from './templates';

import {ReactComponent as FaceSmileIcon} from '../assets/icons/face-smile.svg';
import styled from 'app/ui-core/styled-components';
import useIsMobile from 'app/hooks/useIsMobile';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const Healow = () => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState<boolean>(false);
	const isMobile = useIsMobile();

  useEffect(() => {
    const show = !localStorage.getItem(SESSION_STORAGE_ITEMS.HEALOW_SHOW);
    setShowModal(show);
  },[]);

	const handleOnClose = () => {
		localStorage.setItem(SESSION_STORAGE_ITEMS.HEALOW_SHOW, 'true');
		setShowModal(false);
	}
  
	return (
		<>
			{
				showModal &&
				<KeraltyModal scroll='hidden' size={'long'} onClickContainer={handleOnClose}>
					<Container centered={'x'} padding={'1rem'} >
						<FaceSmileIcon/>
					</Container>	
					<Row noMargin>
						<KeraltyTextStyle bold color={'lightAccent'} type={'label'}>
							{t('errors.healoWelcome')}
						</KeraltyTextStyle>
							<SpaceWrapper my={1}/>
            <KeraltyTextStyle type={'label'}>
							{isMobile ? t('errors.healowCreateMobile') : t('errors.healowCreate')}
						</KeraltyTextStyle>
					</Row>
						<Divider my={1}/> 
					<Row noMargin>
						<Container centered={'x'} noPadding>
							<KeraltyButtonStyle outline noBorders onClick={handleOnClose}>
								{t('button.ok')}
							</KeraltyButtonStyle>
						</Container>
					</Row>
				</KeraltyModal>
			}
		</>
	);
};

export default Healow;

const KeraltyTextStyle = styled (KeraltyText)`
	font-size: 1.8rem;
	text-align: center;
`;

const KeraltyButtonStyle = styled (KeraltyButton)`
	height: 2.75rem;
	margin-bottom: 0;
`;