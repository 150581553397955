// 3RD PARTY
import { useTranslation } from 'react-i18next';
//UI
import { Divider, Image } from '../atoms';
import KeraltyText from '../atoms/keraltyText';
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

const FullCard = styled.div`
  padding: 0px;
  width: 100%;
  position: relative;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    height: auto;
    padding: 0px;
    * {
      display: block;
      position: relative;
    }
    display: flex;
    flex-wrap: wrap;
  }
`;

const BannerIcon = styled(Image)`
  height: 48px;
  margin-right: 1.5rem;
`;

const BannerDivider = styled(Divider)`
  border: .5px solid ${({ theme: { colors } }) => colors.primary};
  border-bottom: none;
  margin-bottom: 1.1rem;
  width: 99% !important;
`;

const BannerTitle = styled(KeraltyText)`
  font-size: 18px;
  font-weight: 400;
`;

const BannerDescription = styled(KeraltyText)`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  width: 60% !important;
  margin-left: 2rem;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
`;

const HomeBanner = ({ img, icon, title, desc }: any) => {
  const { t } = useTranslation();
  return (
    <FullCard>
      {/* <BannerDivider /> */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
        <BannerContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BannerIcon src={icon} alt={t('sanitasLogo')} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <BannerTitle color="primary" pb>
              {title}
            </BannerTitle>
            <BannerDescription type="label" color={'dark'} bold={false} align="center">
              {desc}
            </BannerDescription>
          </div>
        </BannerContent>
      </div>
    </FullCard>
  );
};

export default HomeBanner;
