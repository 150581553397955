import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { SanitasChatDataTypes } from './entities';
import { RootState } from '../store';
import i18n from 'i18next';

const initialState: SanitasChatDataTypes = {
  stateNewChatkeralty: { queue: '', state: false },
  sanitasProps: {
    name: null,
    email: null,
    actionColor: undefined,
    backgroundColor: undefined,
    languageOverride: i18n.language?.includes('en') ? 'en' : 'es',
    customAttributes: {}
  },
  sanitasState: {
    isSetted: false,
    isShow: false,
    isInitialized: false,
  }
}

export const openSanitasChat = createAsyncThunk(
  'sanitasChat/initChat',
  async (payload: SanitasChatDataTypes['sanitasProps']) => {
    const state: SanitasChatDataTypes = initialState;

    state.sanitasState.isSetted = true;
    state.sanitasProps = { ...payload };

    return state;
  }
);

export const SanitasChatSlice = createSlice({
  name: 'sanitasChat',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setKeraltChatAction: (state, { payload }: PayloadAction<{ queue: string; state: boolean }>) => {
      state.stateNewChatkeralty = { queue: payload.queue, state: payload.state };
    },
    shutdown: (state) => {
      state.sanitasProps = { ...initialState.sanitasProps };
      state.sanitasState.isShow = false;
      state.sanitasState.isSetted = false;
      state.sanitasState.isInitialized = false;
    },
    show: (state) => {
      state.sanitasState.isShow = true;
    },
    setUp: (state, action: any) => {
      const lng = i18n.language.includes('es') ? 'es' : 'en';
      state.sanitasState.isSetted = true;

      if (action.payload) {
        const currentProps = !action.payload?.languageOverride
          ? { ...action.payload, languageOverride: lng }
          : { ...action.payload };
        state.sanitasProps = currentProps;
      }
    },
    hide: (state) => {
      state.sanitasState.isShow = false;
      state.sanitasState.isInitialized = true;
    },
    setPayload: (state, action: any) => {
      state.sanitasProps = { ...action.payload };
    },
    setStateSanitas: (state, action: any) => {
      state.sanitasState = { ...state.sanitasState, isShow: action.payload.isShow };
    },
  },
});

export const { show, hide, setUp, shutdown, setPayload, setStateSanitas, setKeraltChatAction } = SanitasChatSlice.actions;

export const sanitasChat = (state: RootState) => state.sanitasChat;

export const selectNewChatKeralty = createSelector(
  sanitasChat,
  ({ stateNewChatkeralty }) => stateNewChatkeralty
);

export default SanitasChatSlice.reducer;
