import moment from "moment";
import { PatientConsents } from "../entities/patientInfo";
import FORMATS from 'app/ui-core/utils/formats';

export const DefaultPatientConsents: PatientConsents = {
	acceptedTreatment: false,
	acceptedHipaa: false,
	acceptedStudents: false,
	acceptedFinancial: false,
	acceptedEmail: 1,
	acceptedPhone: 1,
	signature: '',
	date: moment().format(FORMATS.date)
};
