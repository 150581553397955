import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '../../../../ui-core/styled-components';
// UI
import arrowAltCircleLeftIcon from '../../../../assets/icons/arrow-alt-circle-left.svg';
import { ReactComponent as pendienteicon } from '../../../../assets/icons/pendienteicon.svg';

import { Row, Column, IconLinkRow } from '../../../templates';
import { KeraltyButton, KeraltyText, KeraltyIcon, Image, KeraltyCheckbox } from '../../../atoms';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';

// ADAPTER
import { useAppDispatch, useAppSelector } from '../../../../../adapter/hooks';
import * as sanitasAdapterMethods from '../../../../../adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker } from '../../../../../adapter/chat/chatSlice';
// LOGIC
import CaptchaProvider from '../../../../contexts/CaptchaProvider';
import useIsMobile from '../../../../hooks/useIsMobile';
import { TEXT } from 'app/components/atoms/circularMenu/FloatingMenu';
import { KeraltyField } from 'app/components/molecules';
import { useState, useEffect } from 'react';
import { ISessionInformation } from '../../../../../domain/entities/paymentInfo';
import moment from 'moment';
import FORMATS from '../../../../ui-core/utils/formats';
import { SERVICES_TYPES, SESSION_STATUS } from '../../../../../domain/valueObjects/payment';
import ModalTransactionCanceled from 'app/components/organisms/getCare/televisitPayment/modals/ModalTransactionCanceled';
import ModalTransactionNotCanceled from 'app/components/organisms/getCare/televisitPayment/modals/ModalTransactionNotCanceled';
import { notifyUserBySms, cancelUserTransaction } from 'adapter/payment/paymentSlice';

type SvgIconProps = {
    src: React.FC,
    color?: string;
    w?: number | string | null; 
    h?: number | string | null;
  }

const IconWrapper = styled.span<Partial<SvgIconProps>>`
  svg {
    ${({ theme: { colors }, color, w, h }) => `
      color: ${color ? colors[color] : undefined};
      fill: ${color ? colors[color] : undefined};
      
      path {
        color: ${color ? colors[color] : undefined};
        fill: ${color ? colors[color] : undefined};
      }

      ${h ? `height: ${h}rem !important;` : null}

      ${w ? `width: ${w}rem !important;` : null}
    `}
  }
`

const RightContent = styled(Row)`
    display: flex;
    flex-direction: column;
    flex: 100%;
    align-items: flex-start;
    background: transparent;
    height: auto;
`;
const Container = styled(Column)`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
        @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
            width: auto;
            align-items: center;
            text-align: center;
        }
  }
`;

const SubTitleContainer = styled(Column)`
		justify-content: center;
		margin-right: 20px;
		padding: 1rem 0rem;
		width: 60% !important;
		text-align: center;
		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		text-align: center;
		margin-bottom: '5%' !important;
		display: flex;
		align-items: center;
		width: 80% !important;
	}
`;

const TextSubtitle = styled(Column)`
	color: #000000 !important;
	text-align: center;
	font-size: 16px;
	line-height: 1.58rem;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color:#055293 !important;
	}
`;


const FormContainer = styled(Row)`
    justify-content: center;
		
`;

const SvgIcon: React.FC<SvgIconProps> = ({ src: IconComponent, color, w, h }) => (
    IconComponent ? (
      <IconWrapper color={color} w={w ? w : null} h={h ? h : null}>
        <IconComponent />
      </IconWrapper>
    ) : null
  );

const LabelRefBottom = ({ title, text, width = '100%', customSize='16px' }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

    return (
        <Row noMargin style={{ marginBottom: 7, width: '100%' }}>
            <Column noPadding style={{ width, textAlign: 'left' }}>
                    <span style={{ width: !isMobile ? '100%' : '60%', fontSize: customSize, fontWeight: isMobile ? '700' : '400', color: ! isMobile?'#5B5C5B':'#055293', lineHeight: '30px' }}>{t(title)}</span>
                    <span style={{ textAlign: 'right', overflowWrap: 'break-word', width: !isMobile ? '100%' : '50%', fontSize: customSize, fontWeight: '400', color: ! isMobile?'#5B5C5B':'#055293', lineHeight: '30px', marginRight: isMobile ? '10%' : '40%' }}>{text}</span>
            </Column>
        </Row>
    )
}

interface ITelevisitPayResultProps {
  paymentInfo: ISessionInformation,
  checkPaymentStatus: () => Promise<void>
}

enum ModalTypes {
  DEFAULT,
  SUCCESS,
  ERROR
}


const TelevisitPaPending = ({ paymentInfo, checkPaymentStatus }: ITelevisitPayResultProps) => {

    const isMobile = useIsMobile();
    const { replace } = useHistory();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [modalActived, setModalActived] = useState<ModalTypes>(ModalTypes.DEFAULT)
    const { id, notifySMS, transactionInformation: { paymentID, paymentDate, serviceTotalValue } } = paymentInfo;
    const { authUid, state } = useAppSelector(state => state.user)

    const time = paymentDate ? moment(new Date(paymentDate)).format(FORMATS.date4) : null;

    const cancelTransaction = async () => {

      dispatch(cancelUserTransaction({
        authUid,
        state,
        service: SERVICES_TYPES.VIDEO_CALL
      }))
      .unwrap()
      .then( (response: any) => {
        setModalActived(ModalTypes.SUCCESS)
      }).catch( (error: any) => {
        setModalActived(ModalTypes.ERROR)
      })
    }

    const notifyBySms = async (check: boolean) => {
      const body = {
        authUid,
        state,
        service: SERVICES_TYPES.VIDEO_CALL,
        check
      }

      dispatch(notifyUserBySms(body)).unwrap()
  
      // try{
      //   const res = await TempopublicHttpClient.put(`payment/transaction/check-sms`, body, { headers })
      //   console.log(res)
        
      // }catch(e){
      //   console.log(e)
      // }
    }

    const closeModal = () => {
      setModalActived(ModalTypes.DEFAULT)
      checkPaymentStatus();
    }

    useEffect(() => {
      const interval = setInterval(() => {
        checkPaymentStatus();
      }, 30000);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      setIsChecked(notifySMS)
    }, [notifySMS]);

    return (
      <>
        { modalActived === ModalTypes.SUCCESS && <ModalTransactionCanceled/> }
        { modalActived === ModalTypes.ERROR && <ModalTransactionNotCanceled close={closeModal} /> }
        {/* <CaptchaProvider> */}
          <RightContent fullHeight bg="light">

              <Container style={{ alignItems: 'center', alignSelf: 'center', marginTop: isMobile ? '10%' : '1%'  }}>
                <Column 
                  style={{ 
                    display: isMobile ? 'grid' : '-webkit-box', 
                    WebkitBoxAlign: 'center', paddingRight: isMobile ? '1%' : '5%' 
                }}>
                  <SvgIcon src={pendienteicon}/>
                  <KeraltyText type="title" fontWeight={'400'} fontSize={isMobile ? '24px' : '32px'} style={{fontWeight: 'normal'}}>
                    {t('getCare.televisit.transactPending')}{' '}
                  </KeraltyText>
                </Column>
                {
                  (
                    <SubTitleContainer lg={12}>
                      <TextSubtitle>{t('getCare.televisit.transactPendingSubtitle')}</TextSubtitle>
                    </SubTitleContainer>
                  )
                }

              </Container>

            <FormContainer>
              <Container>
                <div style={{ width: '100%', textAlign: 'center' , paddingTop: '3%', paddingLeft: isMobile ? '5%' : '10%'  }}>
                  <Row noMargin style={{ width: isMobile ? '90%' : '50%', paddingTop: 3 }}>
                    <LabelRefBottom title={'getCare.televisit.transactionID'} text={id} />
                    <LabelRefBottom title={'getCare.televisit.transactionState'} text={t('getCare.televisit.pending')} />
                    <LabelRefBottom title={'getCare.televisit.transactionTotal'} text={`$${Number.parseFloat(serviceTotalValue.toString()).toFixed(2)}`} customSize={isMobile ? '20px' : '16px'} />
                  </Row>
                </div>
                {/* serviceTotalValue */}
                <div style={{ width: isMobile ? '90%': '100%', marginTop: 10 }}>
                  <Column lg={12} style={!isMobile ? { marginLeft: '26%', paddingLeft: '10%' } : { marginLeft: '5%' }}>
                    <KeraltyField
                      ignoreLabelSpace
                      autoHeight
                      control={KeraltyCheckbox}
                      type={'checkbox'}
                      checked={isChecked}
                      onChange={(check: boolean) => {
                        notifyBySms(check)
                        setIsChecked(check)
                      }}
                      content={
                        <KeraltyText type="info" color={! isMobile?'#5B5C5B':'#055293'}>
                          {t('getCare.televisit.notifyViaSms')}
                        </KeraltyText>
                      }
                      mandatory 
                    />
                  </Column>
                </div>
            
              <div style={{ width: '100%', textAlign: 'center' , paddingTop: isMobile ? '10%' : '0%' }}>
                <KeraltyButton 
                  onClick={ () => replace('/get-care') } 
                  style={{ width: isMobile ? '80%' : window.innerWidth <= 1280 ? '40%' : window.innerWidth === 1366 ? '35%' : '30%', fontWeight: 600, fontSize: isMobile ? '14px' : '20px' }} 
                  full 
                  smFull
                > 
                  {t('button.returnPreviousMenu')} 
                </KeraltyButton>
              </div>
              {
                !paymentInfo.transactionInformation.paymentStatus
                && (
                  <div style={{ width: '100%', textAlign: 'center' , marginTop: '0%' }}>
                    <KeraltyButton 
                      onClick={ () => cancelTransaction() } 
                      style={{ 
                        width: isMobile ? '80%' : window.innerWidth <= 1280 ? '40%' : window.innerWidth === 1366 ? '35%' : '30%', 
                        fontWeight: 600, 
                        fontSize: isMobile ? '14px' : '20px', 
                        marginTop: '0px', 
                        background: '#FFFFFF',
                        color: '#3CA70D',
                        border: `${isMobile ? '1px' : '2px'} solid`
                      }} 
                      full 
                      smFull
                    > 
                      {t('button.cancelTransaction')} 
                    </KeraltyButton>
                  </div>
                )
              }
              
            </Container>
            </FormContainer>
          </RightContent>
        {/* </CaptchaProvider> */}
      </>
    )
}

export default TelevisitPaPending;