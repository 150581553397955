// 3RD PARTY
import { useTable } from 'react-table';
//UI
import styled from '../../ui-core/styled-components';

type TableProps = {
  width: string | undefined;
};
const Table = styled.table<TableProps>`
  ${({ width }) => (width ? `width: ${width}px;` : 'width: 739px;')}
  padding: 1rem;
  border-spacing: 0;
  background-color: ${({ theme: { colors } }) => colors.light};
`;

const TableHead = styled.thead`
  font-size: 1.273rem;
  background: #e8e8e8;
  height: 56px;
  font-weight: bold;
`;

const HeadCells = styled.th`
  color: '#055293';
  border-bottom: 1px solid #dbdbdb;
  padding: 0 2.18rem;
  text-align: left;
`;

const BodyCells = styled.td`
  padding: 0 2.18rem;
  text-align: left;
  font-size: 1.4rem;
  margin: 0;
  border-bottom: 1px solid #dbdbdb;
  :last-child {
    border-right: 0;
  }
`;

const TableStructure = styled.div`
  text-align: center;
  max-width: 100%;
`;

interface KeraltyTableProps {
  columns: any;
  data: any;
  tableWidth?: string | undefined;
}

const KeraltyTable = ({ columns, data, tableWidth }: KeraltyTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <TableStructure>
      <Table {...getTableProps()} width={tableWidth}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeadCells
                  {...column.getHeaderProps()}
                  style={{
                    color: '#055293',
                  }}
                >
                  {column.render('Header')}
                </HeadCells>
              ))}
            </tr>
          ))}
        </TableHead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <BodyCells
                      {...cell.getCellProps()}
                      style={{
                        padding: '18px',
                        color: '#5B5C5B',
                      }}
                    >
                      {cell.render('Cell')}
                    </BodyCells>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableStructure>
  );
};

export default KeraltyTable;
