import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import appConfig from 'config';

import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../atoms';

import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';





import ROUTES from '../../../ui-core/utils/routes';


import { useAppDispatch, useAppSelector } from 'adapter/hooks';

import useIsMobile from '../../../hooks/useIsMobile'


import i18n from "i18next";

import { userSelectors } from 'adapter/user/userSelectors';

import { useState } from 'react';
import theme from 'app/ui-core/utils/theme';

import DataStinkingThinking from './StinkingThinking/DataStinkingThinking';
import OffboardingStiking from './StinkingThinking/OffboardingStinkinkg';
import OnboardingStiking from './StinkingThinking/OnboardingStinkinkg';
import AnimationStiking from './StinkingThinking/AnimationStinkinkg';
import FinishStiking from './StinkingThinking/FinishStiking';


const { MAIN, MY_HEALTH: CURRENT } = ROUTES;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };


const linkToTelevisit: string = appConfig.TELADOC_URL;

interface IconSizes {
	width: number;
	height: number;
}

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		// margin-top: 5vh;
		padding-inline: 10px;
	};
`;

const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
		margin-bottom: 0rem;
		paddingLeft: -2%
	}
`;


const CustomRow = styled(Row)`
	& > ${Column} {
		margin-top: 1rem ;
		margin-bottom: .5rem !important;
		margin-left:2.5rem ;

		margin-right:2.5rem ;
		@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
			margin-left:1rem ;
			
		};
	}
`;
const styleText = {
	fontFamily: 'Proxima Nova',
	fontSize: '16px',
	color: theme.colors.dark,
	lineHeight: '1.58rem'
}







const StinkingThinking = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const [showDtStinking, setShowDtStinking] = useState(false)
	
	const language = i18n.language.includes('en') ? 'en' : 'es';
	
	
	;
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const isMobile = useIsMobile();
	
	const [sthinkingSelcted, setsthinkingSelcted] = useState('')
	const [step, setstep] = useState(1)

	const styleText2 = {
		fontFamily: 'Proxima Nova',
		fontSize: '16px',
		color: theme.colors.dark,
		fontWeight: 700
	}

	

	const handleMain = () => {
		push(`/${MAIN.mentalHealth}`);
	};



	console.log('step---', step)
	console.log(showDtStinking,"sho");
	

	return (
    <RightContent fullHeight>
      <Row>
        <TitleContiner lg={12} style={isMobile ? { padding: 0 } : {}}>
          <KeraltyText
            align={isMobile ? 'left' : 'center'}
            type="title"
			fontWeight='700'
            style={{ fontSize: showDtStinking ? 18 : 24 }}
          >
            {t('mentalHealth.stinkingTitle')}
          </KeraltyText>
          {step == 2 && (
            <KeraltyText
              color={!isMobile ? 'accent' : 'textBlueCounter'}
              bold
              style={{
                fontSize: 16,
                paddingTop: 7,
                position: isMobile ? '' : 'relative',
                top: isMobile ? '' : 35,
                right: isMobile ? '' : 90,
                color: '#3CA70D',
              }}
            >
              {sthinkingSelcted}/3
            </KeraltyText>
          )}
        </TitleContiner>
      </Row>
      <Row>
        {
          step == 1 && (
            <OnboardingStiking
              start={() => {
                setstep(2);
              }}
              skip={() => {
                setstep(2);
              }}
            />
          )
          //

          //<FinishStiking/>
        }
        {step == 2 && (
          <DataStinkingThinking
            onCallback={setsthinkingSelcted}
            nextStep={() => {
              setstep(3);
            }}
          />
        )}
        {step == 3 && (
          <OffboardingStiking
            nextStep={() => {
              setstep(4);
            }}
          />
        )}
        {step == 4 && (
          <AnimationStiking
            nextStep={() => {
              setstep(5);
            }}
          />
        )}
        {step == 5 && <FinishStiking />}
      </Row>
    </RightContent>
  );
};

export default StinkingThinking;
