import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../adapter/hooks";
import { registerActions } from '../../../adapter/register/registerSlice';
import { Switch, Route, Redirect, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import RegisterPage from './RegisterPage';
import RegisterFBPage from './RegisterFBPage';
import RegisterSanitasPage from './RegisterSanitasPage';
import ROUTES from '../../ui-core/utils/routes';
import LoginLayout from '../../components/organisms/login/loginLayout/LoginLayout';
import { useRouteQuery } from 'app/hooks/useRouteQuery';
import { Loader } from 'app/components/templates';
import { KeraltyDotChatTrigger } from 'app/components/molecules';
import { ReactComponent as SupportChatIcon2 } from '../../assets/icons/newChat.svg';
import { ReactComponent as SupportChatIcon } from '../../assets/icons/chat 2.svg';
import { closeSymptomChecker } from 'adapter/chat/chatSlice';
import * as sanitasAdapterMethods from '../../../adapter/sanitasChat/sanitasChat';

import { utcTime } from 'app/ui-core/utils/times';
import useIsMobile from 'app/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { Row } from 'app/components/templates';
// ROUTES
const { MAIN, REGISTER: CURRENT } = ROUTES;


const Register = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { push } = useHistory();
	const { isExact } = useRouteMatch({ path: '/register', strict: true });
	const params = useParams();
	const { step, flow } = params;
	const { query } = useRouteQuery();
	const id = query.get('id') || '';
	const state = query.get('state') || '';
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	const [currentStep, setCurrentStep] = useState('');
	const isMobile = useIsMobile();

	useEffect(() => {
		// TO CLEAN OLDER VALUES
		if (isExact)
			dispatch(registerActions.setProp({ userInfo: undefined, registerId: '', userId: '' }));
	}, [isExact, dispatch]);

	useEffect(() => {
		if (step && currentStep !== step) {
			dispatch(registerActions.setProp({ error: undefined }));
			setCurrentStep(step);
		}
	}, [step, dispatch, currentStep]);

	useEffect(() => {
		// WE NEED TO CATCH "registerId" FROM EMAIL LINK TO STORE IT IN OUR APP STATE
		if (step === CURRENT.oneMore && id) {
			dispatch(registerActions.setProp({ registerId: id, state: state }));
		}
		// WE NEED TO CATCH "userId" FROM FB SSO REGISTRATION TO STORE IT IN OUR APP STATE
		if (!step && flow === CURRENT.fb) {
			dispatch(registerActions.setProp({ userId: id }));
			push(`/${MAIN.register}/${CURRENT.fb}/${CURRENT.confirm}`);
		}
	}, [flow, step, id, dispatch, push]);

	const triggerPatientSupport = useCallback(async () => {
		if (isSCOpen) dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
		  //await dispatch(sanitasAdapterMethods.shutdown());
		  dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: '', state: false }));
		}
		dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: 'support', state: true }));
	  }, [dispatch, sanitasState, isSCOpen]);

	const handlerOnClick = async () => {
		triggerPatientSupport();
	};

	const dotContent = !sanitasState.isShow && isMobile ? (
		<Row style={{justifyContent:"center", display: "flex"}}>
			<div style={{marginBottom: 50}}>
				<KeraltyDotChatTrigger
					color={'primary'}
					colordot={'primary'}
					colorlabel={'primary'}
					label={t('button.supportChat')}
					position={isMobile}
					svg={SupportChatIcon2}
					onClick={handlerOnClick}
				/>
			</div>
		</Row>
		) : (
		<KeraltyDotChatTrigger
			color={'primary'}
			colordot={'primary'}
			colorlabel={'primary'}
			label={t('button.supportChat')}
			svg={SupportChatIcon}
			position={false}
			right={false}
			onClick={handlerOnClick}
		/>
    );

	//console.debug('Params', params);

	return <>
		<LoginLayout hideStores hideFloattingMenu>
			<Switch>
				{/* DEFAULT */}
				<Redirect
					exact from={`/${MAIN.register}`}
					to={`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.hadSanitas}`} />
				{/* ROUTES - SANITAS ACCOUNT */}
				<Redirect
					exact from={`/${MAIN.register}/${CURRENT.sanitas}`}
					to={`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.hadSanitas}`} />
				<Route
					path={`/${MAIN.register}/${CURRENT.sanitas}/:step`}
					component={RegisterSanitasPage} />
				{/* ROUTES - FLORIDA BLUE ACCOUNT */}
				<Route
					exact from={`/${MAIN.register}/${CURRENT.fb}`}
					component={Loader} />
				<Route
					path={`/${MAIN.register}/${CURRENT.fb}/:step`}
					component={RegisterFBPage} />
				{/* ROUTES - PUBLIC ACCOUNT */}
				<Route
					path={`/${MAIN.register}/${CURRENT.public}/:step`}
					component={RegisterPage} />
				{/* PREVENT OLDER PUBLIC ROUTES */}
				<Route
					exact path={`/${MAIN.register}/${CURRENT.oneMore}`}
					render={({ location: { search } }) => <Redirect to={{
						pathname: `/${MAIN.register}/${CURRENT.public}/${CURRENT.oneMore}`,
						search
					}} />} />
				{/* NOT FOUND  */}
				<Redirect to={`/${MAIN.notFound}`} />
			</Switch>
			{dotContent}
		</LoginLayout>
	</>;
};

export default Register;
