import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
// LOGIC
import { useAppSelector, useAppDispatch } from 'adapter/hooks';
import { setClientAuthorization } from 'infrastructure/keraltyApi/privateHttpClient';
import { tryInitSession } from 'adapter/user/userSlice';
import { Loader } from 'app/components/templates';
import { setPaymentAuthorization } from 'infrastructure/keraltyApi/paymentHttpClient';

const AuthGuard = ({ children }) => {
	const { token, authUid, isLoading, hasError } = useAppSelector(state => state.user)
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const dispatch = useAppDispatch();
	const [isAllowed, setIsAllowed] = useState<boolean>(false);
	let innerContent;

	useEffect(() => {
		if (authUid) {
			setClientAuthorization(token);
			setPaymentAuthorization(token);
			setIsAllowed(true);
		} else if (token) {
			dispatch(tryInitSession(token))
		} else {
			setIsAllowed(false);
		}
	}, [token, authUid, dispatch]);

	if (isLoading) {
		innerContent = <Loader />;
	} else if (isAllowed) {
		innerContent = children;
	} else if (hasError || !token) {
		if (sanitasState.isShow && sanitasState.isSetted) {
			window.location.href = '/login'
			return
		}
		innerContent = <Redirect to="/login" />;

	}

	return innerContent || null;
};

export default AuthGuard;
