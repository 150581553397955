import { createSlice } from "@reduxjs/toolkit";
import { UnderstandingYourAnxietyState } from "./understandingAnxiety.state";
import sessionStorage from 'redux-persist/lib/storage/session'
import { encryptTransform } from "redux-persist-transform-encrypt";
import appConfig from 'config';

const stateName = 'understanding-your-anxiety'

const initialState: UnderstandingYourAnxietyState = {
    register: {
        date: '',
        uuid: '',
        authUid: '',
        thinking: '',
        moreAbout: '',
        actions: '',
        feeling: '',
        physicalSymptoms: '',
        patientId: '',
    },
    registerSelected: undefined,
	stepInStorage: 0
};

export const persistUnderstandingConfig = {
	key: stateName,
	storage: sessionStorage,
	whitelist: ['register', 'registerSelected', 'currentStep'],
	transforms: [
		encryptTransform({
			secretKey: appConfig.KERALTY_SWS_KEY,
		  	onError: function (error) {
				// Handle the error.
		  	},
		}),
	],
};

const UnderstandingYourAnxietySlice = createSlice({
	name: stateName,
	initialState,
	reducers: {
		updateUnderstandingYourAnxiety: (state, action) => {
			return {
				...state,
				register: {
					...state.register,
					...action.payload
				}
			}
		},
		setRegisterSelected: (state, action) => {
			return {
				...state,
				registerSelected: {
					...state.registerSelected,
					...action.payload
				}
			}
		},
		setStepInStorage: (state, action) => {
			return {
				...state,
				stepInStorage: action.payload
			}
		},
		resetUnderstandingYourAnxiety: () => {
			return initialState;
		}
	}
});

export const { updateUnderstandingYourAnxiety, setRegisterSelected, resetUnderstandingYourAnxiety, setStepInStorage } = UnderstandingYourAnxietySlice.actions;

export default UnderstandingYourAnxietySlice.reducer;