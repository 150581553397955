
import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';


interface ContainerProps {
	centered?: 'x' | 'y' | 'xy';
	spaceAround?: 'x' | 'y';
	spaceBetween?: 'x' | 'y';
	end?: 'x' | 'y';
	padding?: string,
	noPadding?: boolean;
	pt?: boolean;
	px?: boolean;
	max?: boolean;
	[key: string]: any;
}

const DivStructure = ({ className, children, noPadding, pt, px, max, spaceAround, spaceBetween, ...props }) => (
	<div className={className} {...props}>{children}</div>
)

const Container = styled(DivStructure) <ContainerProps>`
	position: relative;
	${props => !props.noPadding && !props.padding ? 'padding-bottom: 2.5rem;' : ''}

	${props => !props.noPadding && props.padding ? `padding-bottom: ${props.padding};` : ''}

	${props => (props.centered || props.spaceAround || props.spaceBetween || props.end) ?
		'display: inline-flex;  flex-wrap: wrap;' : ''}

	${props => props.centered && props.centered.includes('x') ? `
		justify-content: center;
		width: 100%;
	`: ''}
	${props => props.centered && props.centered.includes('y') ? `
		align-items: center;
		height: 100%;
	`: ''}
	${props => props.spaceAround && props.spaceAround.includes('x') ? `
		justify-content: space-around;
		width: 100%;
	`: ''}
	${props => props.spaceAround && props.spaceAround.includes('y') ? `
		align-items: space-around;
		height: 100%;
	`: ''}
	${props => props.spaceBetween && props.spaceBetween.includes('x') ? `
		justify-content: space-between;
		width: 100%;
	`: ''}
	${props => props.spaceBetween && props.spaceBetween.includes('y') ? `
		align-items: space-between;
		height: 100%;
	`: ''}
	${props => props.end && props.end.includes('x') ? `
		justify-content: flex-end;
		width: 100%;
	`: ''}
	${props => props.spaceAround && props.spaceAround.includes('y') ? `
		align-items: flex-end;
		height: 100%;
	`: ''}

	${props => props.pt ? 'padding-top: 1rem;' : ''}
	${props => props.px ? 'padding-left: 1rem; padding-right: 1rem; width: calc(98% - 1rem);' : ''}

	@media (min-width: ${BREAKPOINTS.maxTabletWidth}) {
		${props => props.max ? 'max-width: 1024px; margin: 0px calc((100% - 1024px) / 2); height: auto;' : ''}
	}
	@media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
		${props => props.max ? 'width: 100%; height: auto;' : ''}
	}
	max-width: 100%;
`;

export default Container;
