const nsImgNicons = {
	en: {
		backArrow: 'Left back arrow',
		bannerDoctor: 'Smiling doctor in med center',
		birthdayCakeIcon: 'Birthday cake icon',
		bookAppointmentIcon: 'Book appointment icon',
		calendarDayIcon: 'Calendar day icon',
		calendarIcon: 'Calendar icon',
		chatIcon: 'Chat icon',
		timeActive: 'Available Monday thru Friday 8:00 a.m - 7:00 p.m',
		chatDoctorIcon: 'Chat with doctor icon',
		chevronLeft: 'Chevron left',
		chevronRight: 'Chevron right',
		checkMarkIcon: 'Checkmark icon',
		clock: 'Clock icon',
		doctor: 'Doctor icon',
		envelopeIcon: 'Envelope icon',
		eyeIcon: 'Open eye icon',
		femaleIcon: 'Female icon',
		flaskIcon: 'Flask icon',
		graduationCapIcon: 'Graduation cap icon',
		getCareIcon: 'Kind hand holding a medical cross',
		healthReportIcon: 'Health report icon',
		hamburguerIcon: 'Burguer menu',
		heartIcon: 'Heart icon',
		hospital: 'Hospital icon',
		idIcon: 'Patient ID icon',
		infoCircleIcon: 'Information icon',
		laptopIcon: 'Laptop icon',
		loader: 'loading...',
		lockIcon: 'Lock Icon',
		logoutIcon: 'Logout icon',
		mapMarkedIcon: 'Map marked icon',
		mapMarkerIcon: 'Map marker icon',
		medAptIcon: 'Medical appointment icon',
		medicalFileIcon: 'Medical file icon',
		medicalNotesIcon: 'Medical notes icon',
		memeberNumber: 'Member number',
		mobileIcon: 'Moble phone icon',
		myAccount: 'My Account icon',
		paperPlaneIcon: 'Paper plane Icon',
		rotaryPhoneIcon: 'Rotary phone icon',
		sanitasLogo: 'Sanitas butterlfy logo',
		slashedEyeIcon: 'Slashed eye icon, show password',
		telephoneIcon: 'Telephone icon',
		thermometerIcon: 'thermometer icon',
		userIcon: 'User icon',
		userCircleIcon: 'User circle icon',
		userTagIcon: 'User tag icon',
		relationshipIcon: 'Relationship Icon',
		addressIcon: 'Address Icon',
		cityIcon: 'City Icon',
		videoCamera: 'Video Camera icon',
		referralsIcon: 'Referrals icon',
		careProgramsIcon: 'Care Programs icon',
		labAndImagingResultsIcon: 'Lab and Imaging Results icon',
		medicationIcon: 'Medications Icon',
		healtLibraryIcon: 'Healt Library',
		locationIcon: 'Find my nearest Sanitas service icon',
		mhIcon:'Mental Health icon',
		mhAboutIcon: 'Mental Health about icon',
		mhAppointmentsIcon: 'Mental Health appointments icon',
		mhEducationalIcon: 'Mental Health educational icon',
		mhNeedHelpIcon: 'Mental Health need help icon',
		mhToolsIcon: 'Mental Health self-management tools icon',
		mhCallScheduleIcon: 'Mental Health call to schedule icon',
		mhCalledLaterIcon: 'Mental Health called back later icon',
		wellnessIcon: 'Wellness icon',
	},
	es: {
		backArrow: 'Flecha izquierda regresar',
		bannerDoctor: 'Doctora sonriendo en hospital',
		birthdayCakeIcon: 'Ícono pastel de cumpleaños',
		bookAppointmentIcon: 'Ìcono de agendar cita',
		calendarDayIcon: 'Ícono día de calendario',
		calendarIcon: 'Ícono calendario',
		chatIcon: 'Ícono de chat',
		timeActive: 'Disponible Lunes a Viernes 8:00 a.m - 7:00 p.m',
		chatDoctorIcon: 'Ícono chat con doctor',
		chevronLeft: 'Chevron izquierdo',
		chevronRight: 'Chevron derecho',
		checkMarkIcon: 'Ícono marca de verificación',
		clock: 'Ícono de Reloj',
		doctor: 'Ícono de médico',
		envelopeIcon: 'Ícono sobre',
		eyeIcon: 'Ícono ojo abierto',
		femaleIcon: 'Ícono mujer',
		flaskIcon: 'Ícono matraz',
		getCareIcon: 'mano sosteniendo cruz medica',
		graduationCapIcon: 'Ícono birrete',
		healthReportIcon: 'Ícono reporte de salud',
		hamburguerIcon: 'Menú hamburguesa',
		heartIcon: 'Ícono de corazón',
		hospital: 'Ícono de Hospital',
		idIcon: 'Ícono identificación de paciente',
		infoCircleIcon: 'Ícono información',
		laptopIcon: 'Ícono computadora portatil',
		loader: 'cargando...',
		lockIcon: 'Īcono candado',
		logoutIcon: 'Ícono salir de sesión',
		mapMarkedIcon: 'Ícono mapa marcado',
		mapMarkerIcon: 'Īcono marcador de mapa',
		medAptIcon: 'Ícono cita médica',
		medicalFileIcon: 'Ícono reporte médico',
		memeberNumber: 'Número de cuenta',
		mobileIcon: 'Ícono teléfono celular',
		myAccount: 'Ícono mi cuenta',
		paperPlaneIcon: 'Ícono avión de papel',
		medicalNotesIcon: 'Ícono notas médicas',
		rotaryPhoneIcon: 'Ícono teléfono',
		sanitasLogo: 'Logo mariposa de Sanitas',
		slashedEyeIcon: 'Ícono ojo tachado, mostrar contraseña',
		telephoneIcon: 'Ícono teléfono',
		thermometerIcon: 'Ícono termómetro',
		userIcon: 'Ícono usuario',
		userCircleIcon: 'Ícono usuario en círculo',
		userTagIcon: 'Ícono usuario con etiqueta',
		relationshipIcon: 'Ícono relación',
		addressIcon: 'Ícono dirección',
		cityIcon: 'Ícono ciudad',
		videoCamera: 'Ícono de video cámara',
		referralsIcon: 'Icono de referencias',
		careProgramsIcon: 'Icono de programas de atención',
		labAndImagingResultsIcon: 'Icono de Resultados de Laboratorio',
		medicationIcon: 'Icono de Medicamentos',
		healtLibraryIcon: 'Icono de Healt Library',
		locationIcon: 'Icono de Servicio de Sanitas más cercano',
		mhIcon:'Icono de Salud Mental',
		mhAboutIcon: 'Icono de Salud Mental acerca',
		mhAppointmentsIcon: 'Icono de Salud Mental citasn',
		mhEducationalIcon: 'Icono de Salud Mental educacion',
		mhNeedHelpIcon: 'Icono de Salud Mental necesita ayuda',
		mhToolsIcon: 'Icono de Salud Mental herramientas de autogestión',
		mhCallScheduleIcon: 'Icono de Mental Health llama para agendar',
		mhCalledLaterIcon: 'Icono de Mental Health llamada luego icon',
		wellnessIcon: 'Icono de Bienestar',
	},
};

export default nsImgNicons;
