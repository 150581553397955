import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { REGEX } from '../../../ui-core/utils/regex';
import { GENDERS } from 'app/ui-core/utils/constants';

export interface EditAccountProps {
  authUid?: string;
  sexAssignedAtBirth?: string | undefined;
  maritalStatus?: string;
  homePhone?: string;
  mobile?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  dateOfBirth?: string;
}

export const ListGender = () => {
  const { t } = useTranslation();

  return [
    { key: 1, label: t('formLabel.sexF'), value: GENDERS.female },
    { key: 2, label: t('formLabel.sexM'), value: GENDERS.male },
    { key: 3, label: t('formLabel.sexD'), value: GENDERS.unknown },
  ];
};

export const EditAccountInf: Yup.SchemaOf<EditAccountProps> = Yup.object().shape({
  authUid: Yup.string().required('required'),
  sexAssignedAtBirth: Yup.string().required('required'),
  maritalStatus: Yup.string().required('required'),
  homePhone: Yup.string()
    .notRequired()
    .matches(REGEX.phone, { message: 'invalidPhone', excludeEmptyString: true }),
  mobile: Yup.string().matches(REGEX.phone, 'invalidPhone').required('required'),
  address: Yup.string().min(3, 'min').max(120, 'max').required('required'),
  zipCode: Yup.string()
    .max(5, 'max')
    .min(5, 'min')
    .matches(REGEX.zipcode, 'invalidFormatGeneric')
    .required('required'),
  city: Yup.string().required('required'),
  state: Yup.string().required('required'),
  dateOfBirth: Yup.string().required('required'),
});
