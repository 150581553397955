import Row from '../templates/row';
import Column from '../templates/Column';
import { CenteredWrapper, SpaceWrapper } from '../templates';
import { Image } from '../atoms';
import KeraltyText from '../atoms/keraltyText';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { StyledCordobaWrapper } from '../../components/templates/cordovaWrapper';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

type Props = {
	img?: string;
	alt?: string;
	svg?: ReactElement;
	text?: string;
	noMargin?: boolean;
	textColor?: string;
	[key: string]: any;
	icon?: any;
	adaptable?: boolean;
	description?: string;
	description2?: string;
	descriptionFontSize?: string;
	imgSize?: string;
	marginLeft?: number;
};

const CordovaColumn = styled(Column)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    ${StyledCordobaWrapper} & {
      width: 100%;
      height: 33%;
      margin-bottom: 5%;
    }
  }
`;

const CordovaSpaceWrapper = styled(SpaceWrapper)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    ${StyledCordobaWrapper} & {
      ${(props) =>
		props.adaptable
			? `
				display: flex;
				width: 100%;
				justify-content: center;
				margin-left: 0;
				height: 100%;`
			: ''}
    }
  }
`;

const CordovaKeraltyText = styled(KeraltyText)` 
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    ${StyledCordobaWrapper} & {
      ${(props) =>
		props.adaptable
			? `
				text-align: center;				
				font-weight: normal;`
			: 
				`text-align: left` 
		}
    }
  }
`;

const SectionWidget: React.FC<Props> = ({
	img,
	alt,
	text,
	noMargin = false,
	textColor = 'primary',
	svg,
	icon,
	adaptable = false,
	description,
	description2,
	descriptionFontSize,
	imgSize,
	marginLeft=2,
	...props
}) => {
	const UseColumn = adaptable ? CordovaColumn : Column;

	return (
		<Row {...props}>
			<UseColumn lg={3} md={2} sm={2} noPadding adaptable={adaptable} style={{marginLeft:0, display: 'grid', placeItems: 'center'}}>
				{!description ?
					<CenteredWrapper>
						<CordovaSpaceWrapper ml={marginLeft} adaptable={adaptable}>
							{img ? <Image src={img} alt={alt} style={{ height: '100%', marginRight: '20px' }} /> : null}
							{icon ? icon : null}
							{svg ? svg : null}
						</CordovaSpaceWrapper>
					</CenteredWrapper>
					: <CordovaSpaceWrapper ml={marginLeft} style={{marginLeft:10}}>
						{img ? <Image src={img} alt={alt} style={{ height: imgSize ? imgSize : '35px', marginTop:'0px' }} /> : null}
						{icon ? icon : null}
						{svg ? svg : null}
					</CordovaSpaceWrapper>
				}
			</UseColumn>
			<Column lg={9} md={10} sm={10} style={{ alignItems: !description ? 'center' : 'start', justifyContent: 'left' }}>
				<Row noMargin>
					<Column lg={12} md={12} sm={12} noPadding style={{paddingBottom:0,marginBottom:0}}>
						<CordovaKeraltyText
							type="subTitle"
							color={textColor}
							align={'left'}
							
							style={{paddingBottom:5, fontWeight: '600'}}
							pb={!!description}
							adaptable={adaptable}>
							{text}
						</CordovaKeraltyText>
					</Column>
					{description &&
						<Column lg={12} md={12} sm={12} noPadding>
							<CordovaKeraltyText
								noMargin
								type="link"
								undecorated
								color={ textColor != 'primary' ? textColor : '#747474' }
								align={'center'}
								fontSize={descriptionFontSize ? descriptionFontSize : '14px'}
								bold
								adaptable={adaptable}>
								{description} {description2} 
							</CordovaKeraltyText>
							
						</Column>
					}		
				</Row>
			</Column>
		</Row>
	);
};

export default SectionWidget;
