import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Column, IconLinkRow, Loader } from '../../templates';
import { KeraltyText } from '../../atoms';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { ReactComponent as HelpIcon } from '../../../assets/icons/telephone.svg';
import useIsMobile from '../../../hooks/useIsMobile';
import theme from 'app/ui-core/utils/theme';
import { useMediaQuery } from '@mui/material';
import ModalNeedHelp from '../../molecules/ModalNeedHelp';
import { useEffect, useState } from 'react';
import i18n from 'app/ui-core/i18n';
import MentalHealth from 'infrastructure/keraltyApi/repositories/mentalHealth';

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 5vh;
    padding-inline: 10px;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
      text-align: left !important;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }
`;

const CustomRow = styled(Row)`
  & > ${Column} {
    margin-top: 1rem;
    margin-bottom: 0.5rem !important;
    margin-left: 2.5rem;

    margin-right: 2.5rem;
    @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
      margin-left: 1rem;
    }
  }
`;

const KeraltyTextButtom = styled(KeraltyText)`
  background: #3ca70d !important;
  line-height: 14px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  align-self: center !important;
  padding: 10px 30px;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
`;

const NeedHelpText = ({ selectedCardPosition, handleTitle }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const isScreenMobile = useMediaQuery('(max-width:600px)');
  const [description, setDescription] = useState('');
  const [helpData, setHelpData] = useState<any>();
  const [isLoading, setisLoading] = useState(true);

  const needHelp = async () => {
    try {
      await MentalHealth.needHelp()
      .then((result) => {
        setHelpData(result);
        setisLoading(false);
      })
    } catch (error) {
      setisLoading(false);
      console.error('Error:', error);
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    needHelp();
  }, []);

  const selectedCard =
    helpData && selectedCardPosition !== null ? helpData[selectedCardPosition] : null;

  const handlePhone = (phoneNumber) => {
    const cleanedPhoneNumber = phoneNumber.replace(/-/g, '').replace(/SAFE/g, '7233');
    const phoneUrl = `tel:+${cleanedPhoneNumber}`;
    window.location.href = phoneUrl;
  };

  const handleFacebookClick = (link) => {
    window.open('https://' + link);
  };

  const language = i18n.language === 'en';

  useEffect(() => {
    if (selectedCard && language) {
      setDescription(selectedCard?.descriptionEN.replace('911. ', '911. \n'));
    } else {
      setDescription(selectedCard?.descriptionES.replace('911.', '911.\n'));
    }
    handleTitle(selectedCard && language ? selectedCard?.titleEN : selectedCard?.titleES);
  }, [language, helpData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomRow style={{ marginTop: '0px' }}>
          <Column
            style={{
              padding: isMobile ? '0 10px' : '0 14px',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '0px',
            }}
          >
            <KeraltyText align="left" type="info" color={theme.colors.TEXTCOLORLIGHT}>
              <section
                dangerouslySetInnerHTML={{
                  __html:
                    selectedCard && language
                      ? selectedCard?.descriptionEN
                      : selectedCard?.descriptionES,
                }}
              />
            </KeraltyText>

            <Column
              sm={12}
              lg={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0rem 0.166rem 0.33rem 0rem #bbbcbd',
                borderRadius: '10px',
                height: '175px',
                width: isScreenMobile ? 'calc(100% - 25px)' : 'calc(100% - 39px)',
                margin: '12px !important',
                marginTop: '27px',
                padding: '30px 19px',
                background: 'white',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HelpIcon />
                <KeraltyText
                  align="left"
                  type="label"
                  style={{
                    color: theme.colors.blueDark,
                    fontWeight: 700,
                    marginLeft: '10px',
                    fontSize: '16px',
                  }}
                >
                  {selectedCard && language
                    ? selectedCard?.cardHeaderEn
                    : selectedCard?.cardHeaderEs}
                </KeraltyText>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <KeraltyTextButtom
                  type="label"
                  undecorated
                  style={{ width: 'auto', fontWeight: '600' }}
                  onClick={() => handlePhone(selectedCard && selectedCard?.phoneNumber)}
                >
                  {selectedCard && selectedCard?.phoneNumber}
                </KeraltyTextButtom>
              </div>
              <KeraltyText
                align="left"
                type="label"
                style={{
                  color: theme.colors.black,
                  fontWeight: 700,
                  fontSize: '14px',
                  marginBottom:'12px',
                  textAlign: 'center',
                }}
              >
                {t('mentalHealth.linkInfo')}
                <span
                  onClick={() => handleFacebookClick(selectedCard && selectedCard?.url)}
                  style={{
                    color: theme.BLUE307,
                    fontWeight: 700,
                    fontSize: '14px',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  {selectedCard && selectedCard?.url}
                </span>
              </KeraltyText>
            </Column>
          </Column>
        </CustomRow>
      )}
    </>
  );
};

const NeedHelpPage = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const [modal, setModal] = useState<boolean>(false);
  const [selectedCardPosition, setSelectedCardPosition] = useState<number | null>(null);
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
  const [helpData, setHelpData] = useState<any>();
  const [isLoading, setisLoading] = useState(true);

  const needHelp = async () => {
    return await MentalHealth.needHelp()
      .then((result) => {
        setisLoading(false);
        setModal(true);
        setHelpData(result);
      })
      .finally(() => console.log(''));
  };

  useEffect(() => {
    needHelp();
  }, []);

  const closeModal = (position) => {
    setModal(false);
    setSelectedCardPosition(position);
  };

  const handleTitle = (titleSelected) => {
    setSelectedTitle(titleSelected);
  };

  return (
    <RightContent fullHeight>
      {isLoading && <Loader />}
      {modal && <ModalNeedHelp close={closeModal} />}

      <IconLinkRow
        onClick={goBack}
        color="accent"
        icon={arrowAltCircleLeftIcon}
        iconAlt={t('imgNicons.backArrow')}
        text={t('routes.back')}
      />
      {!modal && helpData && (
        <>
          <Row>
            <TitleContiner lg={12}>
              <KeraltyText type="title">{selectedTitle}</KeraltyText>
            </TitleContiner>
          </Row>
          <Row>
            <NeedHelpText selectedCardPosition={selectedCardPosition} handleTitle={handleTitle} />
          </Row>
        </>
      )}
    </RightContent>
  );
};

export default NeedHelpPage;
