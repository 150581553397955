import HomeSectionWidget from 'app/components/atoms/HomeSectionWidget/HomeSectionWidget';
import { ClickeableCard } from './styles';

const HomeCard = (props) => {
  const { onClick, ...widget } = props;
  
  return (
  <ClickeableCard onClick={props.onClick}>
    <HomeSectionWidget {...widget} />
  </ClickeableCard>
    
  );
};

export default HomeCard;
