import { useAppDispatch, useAppSelector } from "adapter/hooks";
import i18n from "app/ui-core/i18n";
import { useCallback } from "react";
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { chatActions, closeSymptomChecker, openSymptomChecker, showChat } from 'adapter/chat/chatSlice';
import useAuth from 'app/hooks/useAuth';

const useLibrary = () => {

    const dispatch = useAppDispatch();
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
	const chatState = useAppSelector((state) => state.chat.symptomChecker);
    const isChatOpen = chatState.isOpen;
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const { user } = useAuth();


    const triggerHealtLibrary = useCallback(() => {
		dispatch(sanitasAdapterMethods.shutdown());
		if (isSCOpen || isVAOpen) dispatch(closeSymptomChecker());
		if ((!chatState.isChatOpen && !chatState.isInitialized) || isVAOpen || isSCOpen) {
		  dispatch(chatActions.openHealtLibrary());
		} else {
		  dispatch(showChat());
		}
	}, [dispatch, isSCOpen, chatState, isVAOpen]);

	const checkYourSymptoms = useCallback(() => {
    	dispatch(sanitasAdapterMethods.shutdown());

    	if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
     
    	if ((!isChatOpen && !chatState.isInitialized) || isVAOpen || isHLOpen) {
        	dispatch(
				openSymptomChecker({
				isOpen: true,
				isPublic: false,
				lng: language,
				userInfo: {
					dob: user.birthdate,
					gender: user.sex,
					memberid: user.idEcw,
					organization_user_id: user.idEcw,
				},
				})
			);
    	} else {
    	  dispatch(showChat());
    	}
	}, [dispatch, isVAOpen, isHLOpen, chatState]);

    return {
        triggerHealtLibrary,
        checkYourSymptoms
    }
}

export default useLibrary;