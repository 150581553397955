import moment from 'moment';
import { UserCredentialsSecurityDTO } from '../models/userCredentialsSecurityDTO';
// DOMAIN
import { UserCredentials } from '../../../domain/entities/userCredentials';
import { UserBasicInfo } from '../../../domain/entities/userBasicInfo';
import { AccountInfo } from '../../../domain/entities/accountInfo';
// FORMAT
import FORMATS from '../../../app/ui-core/utils/formats';
import { formatPhone } from './formatsMapper';

const authMapper = {
	mapToValidateAccount: ({ accountNumber, birthdate }: AccountInfo): any => ({
		accountNumber: accountNumber || '0',
		dateOfBirth: moment(birthdate).format(FORMATS.dateISO8601)
	}),
	mapToValidateAccountContactMethods: (domainObject: AccountInfo & Omit<UserCredentials, 'password'>): any => {
		const { accountNumber, birthdate, phone, email } = domainObject;
		// ------------------ DO => DTO
		return {
			accountNumber: accountNumber || '0',
			dateOfBirth: moment(birthdate).format(FORMATS.dateISO8601),
			email: email.trim(),
			mobile: formatPhone(phone)
		};
	},
	mapToMatchAccountInfo: ({ gender, cellphone, birthdate, ...domainObject }: UserBasicInfo): any => ({
		patientInformation: {
			...domainObject,
			dateOfBirth: moment(birthdate).format(FORMATS.dateISO8601),
			sex: gender,
			cellphone: formatPhone(cellphone)
		}
	}),
	mapToInitialRegistrationCheck: (domainObject: UserBasicInfo) => {
		const { birthdate, cellphone, gender, email, ...values } = domainObject;
		// ------------------ DO => DTO
		console.log('--mapToInitialRegistrationCheck')
		return {
			patientInformation: {
				...values,
				firstName: values.firstName.trim(),
				lastName: values.lastName.trim(),
				sex: gender,
				dateOfBirth: moment(birthdate).format(FORMATS.dateISO8601),
				email: email.trim(),
				cellphone
			}
		};
	},
	mapToUserCredentials: ({ email, password }: Omit<UserCredentials, 'phone'>): any => ({
		email,
		pass: password
	}),
	mapToUserSecuirtyCredentials: (domainObject: Omit<UserCredentials, 'phone'>): UserCredentialsSecurityDTO => {
		const { password, ...attrs } = domainObject;
		const encodedData = btoa(JSON.stringify({ ...attrs, pass: password }));
		return {
			info: encodedData,
			//info: 'eyJlbWFpbCI6InJhbWlyZXpmcmVkeUB5b3BtYWlsLmNvbSIsInBhc3MiOiJEaWNpZW1icmUwNyIsICJzdGF0ZSI6IG51bGx9',
		};
	},
	mapFromLoadUserBySession: (dto: any) => {
		const { dateOfBirth, mobileNumber, ...restPatientoInfo } = dto.patientInformation;
		return {
			authUid: dto.authUid,
			id: dto.id,
			idEcw: dto.idEcw,
			externalPartyId: dto.externalPartyId,
			role: dto.role,
			birthdate: dateOfBirth,
			phoneNumber: mobileNumber,
			...restPatientoInfo
		};
	}
};

export default authMapper;
