import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Row,
  Column,
  FormBox,
  Container,
  Loader,
} from '../../templates';
import {
  KeraltyButton,
  KeraltyText,
  KeraltyDatePicker,
  KeraltyHourPicker,
} from '../../atoms';
import {
  KeraltyField,
  KeraltyModal,
} from 'app/components/molecules';

import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';

import ROUTES from '../../../ui-core/utils/routes';

import useAuth from '../../../hooks/useAuth';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import theme from 'app/ui-core/utils/theme';
import { Grid, useMediaQuery } from '@mui/material';

import { ReactComponent as MessageIcon } from '../../../assets/icons/mh-messageCheck.svg';
import { FHSProps, FHSInf } from './AppointmentsFormTypes';
import MentalHealth from 'infrastructure/keraltyApi/repositories/mentalHealth';
import moment from 'moment';
import i18n from 'app/ui-core/i18n';
import TextAreaControl from 'app/components/molecules/TextAreaControl';

const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
      text-align: left !important;
      margin-left: 1.2rem;
    }
  }
`;

const Form = styled(FormBox)`
  width: 719px;
  @media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    width: calc(100% - 100px);
  }
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: calc(100% - 28px);
  }
`;

const SubTitle = styled(KeraltyText)`
  width: 468px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.dark};
  @media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    width: calc(100% - 100px);
  }
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: calc(100% - 47px);
    margin-left: 2.1rem;
  }
`;

const CustomRow = styled(Row)`
  display: flex;
  justify-content: center;
  & > ${Column} {
    margin-top: 1rem;
    margin-bottom: 0.5rem !important;
    margin-left: 2.5rem;

    margin-right: 2.5rem;
    @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
      margin-left: 1rem;
    }
  }
`;

const AppointmentsFormFHS = () => {
  const { t } = useTranslation();

  const { push } = useHistory();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [ageSuccessModalConfirm, setSuccessModalConfirm] = useState(false);

  const isScreenMobile = useMediaQuery('(max-width:600px)');

  const goFHS = () => {
    push(`/${MAIN.mentalHealth}/${CURRENT.appointments}/${CURRENT.appointmentsFHS}`);
  };

  const ModalSuccess = () => {
    return (
      <KeraltyModal size="long" textalign="center" style={{ padding: '40px 53px' }}>
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center' }}>
          <MessageIcon />
        </Row>
        <Row noMargin style={{ justifyContent: 'center', marginTop: '20px' }}>
          <KeraltyText
            fontSize={'20px'}
            type={'label'}
            color={'primary'}
            style={{ fontWeight: 400 }}
          >
            {t('mentalHealth.fhsModalText')}
          </KeraltyText>
        </Row>

        <KeraltyButton
          onClick={() => (setSuccessModalConfirm(false), goFHS())}
          style={{ fontSize: 18, marginTop: '20px' }}
        >
          {t('formLabel.btnTitleSuccessConfirm')}
        </KeraltyButton>
      </KeraltyModal>
    );
  };

  const FhsForm = () => {
    const { t } = useTranslation();
    const reasonText = useRef<string>('');
    const [formattedTime, setFormattedTime] = useState('');
    const language = i18n.language === 'en';
    const currentDate = new Date();
    const calendarRef = useRef(null);
    const [reasonTextValue, setReasonTextValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handlerReasonText = useCallback(
      (arg) => {
        reasonText.current = arg;
        setReasonTextValue(arg);
      },
      [reasonText]
    );

    const { register, setValue, watch, formState, reset } = useForm<FHSProps>({
      mode: 'onBlur',
      resolver: yupResolver(FHSInf),
      defaultValues: {},
    });

    const handleTimeSelect = (time) => {
      if (time) {
        const hours = time.getHours();
        const minutes = time.getMinutes().toString().padStart(2, '0');
        const amPm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;
        setFormattedTime(`${formattedHours.toString().padStart(2, '0')}:${minutes} ${amPm}`);
      }
    };

    const dateD = watch('dateD');
    const formattedDateD = moment(dateD).format('MM/DD/YYYY');
    const combinedDateTime = `${formattedDateD} ${formattedTime}`;

    useEffect(() => {
      setValue('dateH', undefined);
      setFormattedTime('');
    }, [watch('dateD')]);

    useEffect(() => {
      if (watch('dateD') !== undefined && watch('dateH') !== undefined && formattedTime !== '') {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }, [watch('dateD'), watch('dateH')]);

    const requesTwo = {
      comments: reasonTextValue,
      date: combinedDateTime,
      email: user?.email,
      idEcw: user?.idEcw,
      inEnglish: language,
      mobile: user?.mobile,
      name: user?.displayName,
      state: user?.state,
    };

    const sendData = async () => {
      setIsLoading(true);
      try {
        await MentalHealth.postFHS(requesTwo);
        setIsLoading(false);
        setSuccessModalConfirm(true);
      } catch (error) {
        setIsLoading(false);
        console.error('Error:', error);
        console.log(error);
        throw error;
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      sendData();
    };

    const { errors } = formState;

    return (
      <CustomRow>
        <Form style={{}}>
          <Row style={{}}>
            <Column lg={6}>
              <KeraltyField
                iconClass={'far fa-envelope'}
                title={t('imgNicons.idIcon')}
                placeholder={user?.email}
                label={`${t('formLabel.mail')}*`}
                disabled
              />
            </Column>

            <Column lg={6}>
              <KeraltyField
                iconClass={'far fa-mobile-alt'}
                title={t('imgNicons.idIcon')}
                placeholder={user?.mobile}
                label={`${t('formLabel.movilNumber')}*`}
                disabled
              />
            </Column>

            <Column lg={6}>
              <KeraltyField
                control={KeraltyDatePicker}
                read={true}
                customInput={<input {...register('dateD')} />}
                iconClass={'far fa-calendar-day'}
                title={t('imgNicons.calendarIcon')}
                label={`${t('formLabel.preferredDate')}*`}
                placeholderText={t('register.preferredDatePlaceholder')}
                bg={false}
                minDate={currentDate}
                onChange={(v) => {
                  setValue('dateD', v || '', { shouldValidate: true, shouldTouch: true });
                }}
                selected={watch('dateD')}
                error={errors.dateD}
                readOnly
              />
            </Column>

            <Column lg={6}>
              <KeraltyField
                control={KeraltyHourPicker}
                ref={calendarRef}
                read={true}
                activeHour={watch('dateD')}
                customInput={<div {...register('dateH')} />}
                iconClass={'far fa-clock'}
                title={t('imgNicons.idIcon')}
                label={`${t('formLabel.PreferredHour')}*`}
                placeholderText={
                  formattedTime ? formattedTime : t('register.preferredHourPlaceholder')
                }
                bg={false}
                min={currentDate}
                onChange={(v) => {
                  setValue('dateH', v || '', { shouldValidate: true, shouldTouch: true });
                }}
                selected={watch('dateH')}
                onTimeSelect={handleTimeSelect}
                placeholderColor={formattedTime && theme.colors.primary}
                className="custom-datepicker-width"
              />
            </Column>

            <Column lg={12} style={{ flexDirection: 'column' }}>
              <KeraltyText type="label" style={{ color: theme.colors.primary }}>
                {t('mentalHealth.fhsComment')}
              </KeraltyText>
              <KeraltyText
                type="paragraph"
                style={{
                  color: theme.colors.dark,
                  fontWeight: 400,
                  fontSize: '16px',
                  margin: '8px 0px',
                }}
              >
                {t('mentalHealth.fhsCommentText')}
              </KeraltyText>
              <TextAreaControl
                updateReasonText={handlerReasonText} 
                placeholder={t('mentalHealth.fhsCommentPlaceholder')}
              />
            </Column>

            <Container style={{ justifyContent: 'center', width: '100%', marginTop: '33px' }}>
              <KeraltyButton
                onClick={handleSubmit}
                fontWeight={false}
                style={{ width: '334px' }}
                disabled={isButtonDisabled}
              >
                {t('myHealth.visitSummarySubmit')}
              </KeraltyButton>
            </Container>
          </Row>

          {ageSuccessModalConfirm ? <ModalSuccess /> : null}
        </Form>
      </CustomRow>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <RightContent fullHeight>
        <Row>
          <TitleContiner lg={12}>
            <KeraltyText noTextTransform type="title" fontWeight={400}>
              {t('mentalHealth.fhsForm')}
            </KeraltyText>
          </TitleContiner>
          <Grid sx={{justifyContent: isScreenMobile ? "flex-start" : "center", width: '100%' }} mt={1}>
            <SubTitle align={isScreenMobile ? "left":"center"} noTextTransform type="info">
              {t('mentalHealth.fhsFormText')}
            </SubTitle>
          </Grid>
        </Row>
        <Row>
          <FhsForm />
        </Row>
      </RightContent>
    </>
  );
};

export default AppointmentsFormFHS;
