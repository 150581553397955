
import { VirtualAssistantService } from './contracts/virtualAssistantService';
import { BookAppointmentService } from './contracts/bookAppointmentService';
import { AppointmentsService } from './contracts/appointmentsService';
import { HealtLibraryService } from './contracts/HealtLibraryService';
import { LifeStyleLibService } from './contracts/LifeStyleLibService';

/**
 * Virtual Assistant | Widget initialization
 */
 const loadHealtLibrary = (
	service: Pick<HealtLibraryService, 'load'>,
	data: Parameters<HealtLibraryService['load']>[0]
): Promise<any> => service.load(data);

/**
 * Virtual Assistant | Widget initialization
 */
const loadVirtualAssistant = (
	service: Pick<VirtualAssistantService, 'load'>,
	data: Parameters<VirtualAssistantService['load']>[0]
): Promise<any> => service.load(data);

/**
 * Life Style Library | Widget initialization
 */
const loadLifeStyleLib = (
	service: Pick<LifeStyleLibService, 'load'>,
	data: Parameters<LifeStyleLibService['load']>[0]
): Promise<any> => service.load(data);

/**
 * Book Appointment | Widget initialization
 * FEATURE ADDED: Authenticated tokenize on private version
 */
const loadBookAppointment = async (
	bookAppointmentService: Pick<BookAppointmentService, 'load'>,
	createTokenService: Pick<AppointmentsService, 'createToken'>,
	data: { lang: 'en' | 'es'; onClose: () => void; } & Parameters<AppointmentsService['createToken']>[0]
): Promise<any> => {
	let memberToken = '';
	const { lang, onClose, ...userData } = data;
	if (userData.isLoggedIn) {
    try {
      memberToken = (await createTokenService.createToken(userData))?.token;
      //console.log('data', userData, 'token', memberToken);
    } catch (exc) {
      console.error('API => VIM Tokenize error...', exc);
    }
  }
	return bookAppointmentService.load({ lang, onClose, memberToken });
};

const widgetUseCase = {
	loadVirtualAssistant,
	loadBookAppointment,
	loadHealtLibrary,
	loadLifeStyleLib
};

export default widgetUseCase;
