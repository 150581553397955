import { useCallback, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import CurrentMedicationTable from './CurrentMedicationTable';
import useAuth from '../../../hooks/useAuth';

//Actions
import { getCurrentMedication, cleanError } from 'adapter/myHealth/myHealthSlice';
import { selectmyHealthCurrentMedication } from 'adapter/myHealth/myHealthSelectors';

const CurrentMedicationTab = () => {
  // @TODO: Each row should be a route, but for now this works
  const [activeRow, setActiveRow] = useState<number | undefined>();
  const { user } = useAuth();

  const currentInfo = useAppSelector(selectmyHealthCurrentMedication);
  //const asyncError = useAppSelector(selectmyHealthError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentMedication(user.idEcw));
    return () => dispatch(cleanError());
  }, [dispatch, user.idEcw]);

  const resetActiveRow = useCallback(() => {
    setActiveRow(undefined);
  }, []);

  return <CurrentMedicationTable currentData={currentInfo || []} onClickItem={setActiveRow} />;
};

export default CurrentMedicationTab;
