// 3RD PARTY
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// UI
import { KeraltyText } from '../../../components/atoms';
import { Container, Row, Column, Card } from '../../../components/templates';
import { StyledCordobaWrapper } from '../../../components/templates/cordovaWrapper';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
// ASSETS
import blueCurvedVector from '../../../assets/images/blue-curved-vector.png';
// LOGIC
import useAuth from '../../../hooks/useAuth';
import useIsMobile from 'app/hooks/useIsMobile';
import { disableToTN } from 'app/ui-core/utils/disableOptions';
import { width } from '@mui/system';

const CordovaColumn = styled(Column)`
  > div {
    display: none;

    @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
      ${StyledCordobaWrapper} & {
        display: flex;
        color: #055293 !important;
      }
    }
  };
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    ${StyledCordobaWrapper} & {
      & > div {
        display: none;
      }
    }
  }
`;

const RegularColumn = styled(Column)`
  > div {
    display: flex;

    @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
      ${StyledCordobaWrapper} & {
        display: none;
      }
    }
  }
`;

const ContentHeader = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userName = user?.firstName.charAt(0) + user?.firstName.slice(1).toLowerCase();
  const userId = user?.idEcw;
  const isMobile = useIsMobile();

  const shortDescription = desc => {
    if (desc && desc.length > 13) {
      return desc.substr(0, 13) + "..";
    }
    return desc;
  };

  return (
    <>
      <CordovaColumn lg={12}>
        <Row>
          <Column sm={12}>
            <KeraltyText color={'primary'} bold style={{ fontSize: 'calc(20px + 2vw)' }}>
              {`${t('home.hi')} ${shortDescription(userName) ?? ''}!`}
            </KeraltyText>
          </Column>
          <Column sm={12}>
            <Container centered="y">
              <KeraltyText
                type="label"
                color={'black'}
                bold={false}
                style={{
                  fontSize: 'calc(12px + 1.6vw)',
                  lineHeight: 'normal',
                }}
              >
                {t('home.welcome')}
              </KeraltyText>
            </Container>
          </Column>
          {user?.idEcw && !isMobile ? 
              <div style={{ marginLeft: '3%' , display: 'flex', flexDirection: 'column', width: '75%', textAlign: 'left'}}>
                <KeraltyText align={'left'} type="info" bold={true}>
                  {t('home.yourId')}
                </KeraltyText>
                <KeraltyText type="info" align={'left'}>
                    {userId ? userId : ''}
                </KeraltyText>
              </div>
            : ''}
        </Row>
      </CordovaColumn>

      <RegularColumn lg={12}>
        <Card sm bg="primary" bgImg={blueCurvedVector} style={disableToTN(user?.state) ? {}:{margin:"0 0 .5rem 1.3rem", width:"100%"}}>
          <Row wrap alingItemsCenter>
            <div style={{width:'68%', flexDirection:'column'}}>
            <Column sm={12} lg={12}>
              <KeraltyText type="big" color={'light'} bold>
                {`${t('home.hi')} ${capitalizeJustFirst(user?.firstName) ?? ''}!`}
              </KeraltyText>
            </Column>
            <Column sm={12} lg={12}>
                <div style={{width:'100%'}}>
                <KeraltyText fontSize='14px' type="info" color={'light'} bold={false}>
                  {t('home.welcome')}
                </KeraltyText>

                </div>
                
              
            </Column>
            </div>
            {user?.idEcw ? <div style={{ background: '#fff',  width: '1px', height: '60px'}} />: '' }
            
            {user?.idEcw ? 
              <div style={{display: 'flex',alignItems:'center',flexDirection: 'column', width: '28%', textAlign: 'right'}}>
                <KeraltyText fontSize='16px' align='right' type="info" color={'light'}>
                  {t('home.yourId')}
                </KeraltyText>
                <KeraltyText align='right' type="info" color={'light'}>
                    {userId ? userId : ''}
                </KeraltyText>
              </div>
            : ''}
            
          </Row>
        </Card>
      </RegularColumn>
    </>
  );
};

export default ContentHeader;
