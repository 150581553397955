import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { ValidInsurance as ValidInsuranceEntity, IBasePayload, Init } from '../../domain/entities/paymentInfo';
import PaymentUseCases from 'domain/useCases/payment';

// Services
import { PaymentRepository as paymentServices } from 'infrastructure/keraltyApi/repositories/payment';

// @TODO: This should be a common type across the entire adapter
interface InitialState<DataEntityType = any> {
	data: DataEntityType | null;
	isLoading: boolean;
	error: SerializedError | null;
}

const INITIAL_STATE: InitialState<string> = {
	data: null,
	isLoading: false,
	error: null
}

export const initPayment = createAsyncThunk(
	'payment/initPayment',
	async (data: Init) => {
		return await PaymentUseCases?.init(paymentServices, {...data});
	}
);

export const createNewTransactionBase = createAsyncThunk(
	'payment/createTransactionBase',
	async (data: Init) => {
		return await PaymentUseCases.createTransactionBase(paymentServices, {...data});
	}
);

export const checkTransactionStatus = createAsyncThunk(
	'payment/checkTransactionStatus',
	async (id: string) => {
		return await PaymentUseCases.checkTransactionStatus(paymentServices, id);
	}
);

export const getUrlStripe = createAsyncThunk(
	'payment/getUrlStripe',
	async (transactionId: number) => {
		return await PaymentUseCases.getUrlStripe(paymentServices, transactionId);
	}
);

export const notifyUserBySms = createAsyncThunk(
	'payment/notifyBySms',
	async (data: IBasePayload) => {
		return await PaymentUseCases.notifyBySms(paymentServices, {...data});
	}
);

export const cancelUserTransaction = createAsyncThunk(
	'payment/cancelUserTransaction',
	async (data: Omit<IBasePayload, 'check'>) => {
		return await PaymentUseCases.cancelUserTransaction(paymentServices, {...data});
	}
);

export const validateInsurance = createAsyncThunk(
	'payment/validateInsurance',
	async (data: ValidInsuranceEntity) => {
		return await PaymentUseCases.validateInsurance(paymentServices, {...data});
	}
);


export const PaymentSlice = createSlice({
	name: 'payment',
	initialState: INITIAL_STATE,
	reducers: {
		cleanError: (state) => {
			state.error = null;
		}
	},
	extraReducers: (builder) => {
		builder
			// tryValidateUserInfo
			.addCase(initPayment.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(initPayment.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(initPayment.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
			// createTransactionBase
			.addCase(createNewTransactionBase.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(createNewTransactionBase.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(createNewTransactionBase.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
			// checkTransactionStatus
			.addCase(checkTransactionStatus.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(checkTransactionStatus.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(checkTransactionStatus.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
			// getUrlStripe
			.addCase(getUrlStripe.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(getUrlStripe.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(getUrlStripe.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
			// notifyBySms
			.addCase(notifyUserBySms.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(notifyUserBySms.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(notifyUserBySms.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
			// cancelUserTransaction
			.addCase(cancelUserTransaction.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(cancelUserTransaction.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(cancelUserTransaction.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})
			.addCase(validateInsurance.pending, (state) => {
				state.data = null;
				state.isLoading = true;
				state.error = null;
			})
			.addCase(validateInsurance.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isLoading = false;
				state.error = null;
			})
			.addCase(validateInsurance.rejected, (state, action) => {
				state.data = null;
				state.isLoading = false;
				state.error = action.error;
			})

	},
});

export const { cleanError } = PaymentSlice.actions;
export default PaymentSlice.reducer;
