import useMaintenanceAndNotifications from 'app/hooks/useMaintenanceAndNotifications';
import { PrivateModalMaintenance } from './molecules/PrivateModalMaintenance';

const Maintenance = () => {
  const { privateScheduleText, privateModal, setPrivateModal } = useMaintenanceAndNotifications();

  return (
    <>
      {privateModal && (
        <PrivateModalMaintenance
          scheduleText={privateScheduleText}
          close={() => setPrivateModal(false)}
        />
      )}
    </>
  );
};

export default Maintenance;
