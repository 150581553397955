import axios from 'axios';
import appConfig from 'config';

export interface HttpResponse<T> {
  success: boolean;
  data?: T | boolean;
  error?: string;
}



const PublicTokenClient = axios.create({
  baseURL: `${appConfig.API_URL}/`,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
  },

});

PublicTokenClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    return Promise.reject({
      name: error?.response?.config?.url || 'No Network Connection',
      message: String(error?.response?.data?.code || 999),
      code: error?.response?.status ? String(error?.response?.status) : 0,
    });
  }
);

export default PublicTokenClient;
