const nsPharmacyInfo = {
	en: {
		pharmacyInfo: "Pharmacy information",
		pharmacyName: "Pharmacy name",
		authorization: "I give Sanitas authorization to obtain my prescription records from participating pharmacies.",
		disclosure: "Disclosure to friends and/or family members",
		permission: "I give permission for my Protected Health Information to be disclosed for purposes of communication restults, findings and care decisions to the family members and others listed below.",
		communication: "Consent to email or phone for healthcare communications",
		consentNotReq: "You are not required to sign below or otherwise directly or indirectly agree to being contacted as a condition for receiving services from Sanitas and/or its affiliates.",
		consent: "I consent to receiving communications by the following means (check all that you consent to):",
		healthcareCommunications: "Consent to email or phone healthcare communications",
		email: "Email",
		phone: "Phone (Including voicemail and text messages)",
		yes: "Yes",
		no: "No",
		name: "Name",
		relationship: "Relationship",
		phoneNum: "Phone number",
	},
	es: {
		pharmacyInfo: "Información de farmacia",
		pharmacyName: "Nombre de la farmacia",
		authorization: "Doy mi autorización a Sanitas para obtener mis records de prescripciones en las farmacias participantes.",
		disclosure: "Disclosure to friends and/or family members",
		permission: "I give permission for my Protected Health Information to be disclosed for purposes of communication restults, findings and care decisions to the family members and others listed below.",
		communication: "Consentimiento para comunicaciones de salud a través de correo electrónico o teléfono",
		consentNotReq: "No estás obligado a firmar debajo ni a estar directa o indirectamente de acuerdo con ser contactado por Sanitas para recibir servicios médicos de ellos o sus afiliados.",
		consent: "Estoy de acuerdo con ser contactado por Sanitas por los siguientes medios:",
		healthcareCommunications: "Consentimiento para comunicaciones de salud a través de correo electrónico o teléfono",
		email: "Correo electrónico",
		phone: "Teléfono (Incluidos mensajes de voz y mensajes de texto)",
		yes: "Si",
		no: "No",
		name: "Nombre",
		relationship: "Relación",
		phoneNum: "Número telefónico",
	}
};

export default nsPharmacyInfo;
