import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { LabResult } from 'domain/entities/labResult';
import { Medication } from 'domain/entities/medication';
import { Referral } from 'domain/entities/referral';
import { CurrentMedication } from 'domain/entities/currentMedication'
import { Immunization } from 'domain/entities/immunization';
import MyHealth from 'domain/useCases/myHealth';

import { EcwRepository as myHealthServices } from 'infrastructure/keraltyApi/repositories/ecw';

import { UserAvaility } from 'domain/entities/userAvaility';

interface InitialState<DataEntityType = any> {
	data: DataEntityType | null;
	isLoading: boolean;
	error: SerializedError | null;
}

type MyHealthType = {
	lab?: LabResult[];
	current?:CurrentMedication[];
	medications?: Medication[];
	referrals?: Referral[];
	immunization?: Immunization[];
	userAvaility?: UserAvaility
}

const initialState: InitialState<MyHealthType> = {
	data: null,
	isLoading: false,
	error: null
};

export const getLabData = createAsyncThunk(
	'myHealth/getLabData', async (id: string) => {
		return await MyHealth.getLaboratoryData(myHealthServices, id);
	}
);

export const getCurrentMedication = createAsyncThunk(
	'myHealth/currentMedications', async (id: string) => {
		return await MyHealth.getCurrentMedicationData(myHealthServices, id);
	}
);
export const getMedicationsData = createAsyncThunk(
	'myHealth/getMedicationsData', async (id: string) => {
		return await MyHealth.getMedicationsData(myHealthServices, id);
	}
);

export const getReferralsData = createAsyncThunk(
	'myHealth/getReferralsData', async (id: string) => {
		return await MyHealth.getReferralsData(myHealthServices, id);
	}
);

export const getImmunizationData = createAsyncThunk(
	'myHealth/getImmunizationData', async (id: string) => {
		return await MyHealth.getImmunizationData(myHealthServices, id);
	}
);

export const getVisitSummaryData = createAsyncThunk(
	'myHealth/getVisitSummaryData', async (payload: any, { getState }) => {
		const userState = (getState() as any).user;

		return await MyHealth.getVisitSummaryData(myHealthServices, { idEcw: userState.idEcw, ...payload });
	}
);

// Just an utility function to use b/c these payload are similar
// If we need something custom, we can delete these
const handleFulfilled = (stateKey: string) => (state, { payload }) => {
	state.error = null;
	state.isLoading = false;
	if (payload?.length) {
		state.data = {
			...state.data,
			[stateKey]: payload
		}
	}
}

const handlePending = (state, action) => {
	state.error = null;
	state.isLoading = true;
}

const handleError = (state, action) => {
	state.error = action.error;
	state.isLoading = false;
}


export const MyHealthSlice = createSlice({
	name: 'myHealth',
	initialState,
	reducers: {
		cleanError: (state) => {
			state.error = null;
		},
		cleanStateMyHealthSlice: (state) => {
			state.data = null;
		}
	},
	extraReducers: (builder) => {
		builder
			// Lab Results
			.addCase(getLabData.pending, handlePending)
			.addCase(getLabData.fulfilled, handleFulfilled('lab'))
			.addCase(getLabData.rejected, handleError)
			// Medications Results
			.addCase(getMedicationsData.pending, handlePending)
			.addCase(getMedicationsData.fulfilled, handleFulfilled('medications'))
			.addCase(getMedicationsData.rejected, handleError)
			// Referrals Results
			.addCase(getReferralsData.pending, handlePending)
			.addCase(getReferralsData.fulfilled, handleFulfilled('referrals'))
			.addCase(getReferralsData.rejected, handleError)
			// Current Results
			.addCase(getCurrentMedication.pending, handlePending)
			.addCase(getCurrentMedication.fulfilled, handleFulfilled('current'))
			.addCase(getCurrentMedication.rejected, handleError)
			// Inmunnization Results
			.addCase(getImmunizationData.pending, handlePending)
			.addCase(getImmunizationData.fulfilled, handleFulfilled('immunization'))
			.addCase(getImmunizationData.rejected, handleError)
			// Visit Summary results
			.addCase(getVisitSummaryData.pending, handlePending)
			.addCase(getVisitSummaryData.fulfilled, handleFulfilled('visit-summary'))
			.addCase(getVisitSummaryData.rejected, handleError)
	}
});

export const myHealthState = (state: RootState) => state.MyHealthSlice;
export const { cleanError } = MyHealthSlice.actions
export const { cleanStateMyHealthSlice } = MyHealthSlice.actions
export default MyHealthSlice.reducer;
