import TwoFactorAuthenticationRepository from "infrastructure/keraltyApi/repositories/twoFactorAuthentication.respositories";
import { CodeEmailMobileRequest, ConfirmCodeRequest } from "./contracts/twoFactorAuthenticationService";

const verifyEmailAndPassword = (info: string) => {
	return TwoFactorAuthenticationRepository.verifyEmailAndPassword(info);
};

const requestCodeEmailMobile = (payload: CodeEmailMobileRequest) => {
	return TwoFactorAuthenticationRepository.requestCodeEmailMobile(payload);
};

const confirmCode = (payload: ConfirmCodeRequest) => {
	return TwoFactorAuthenticationRepository.confirmCode(payload);
};

const TwoFactorAuthenticationUseCase = {
    verifyEmailAndPassword,
    requestCodeEmailMobile,
    confirmCode
};

export default TwoFactorAuthenticationUseCase;