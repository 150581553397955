import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { checkTransactionStatus } from 'adapter/payment/paymentSlice';

import TelevisitPayResult from 'app/components/organisms/getCare/televisitPayment/TelevisitPayResult';
import TelevisitPayPending from 'app/components/organisms/getCare/televisitPayment/TelevisitPayPending';
import TelevisitPayTransactionExpired from 'app/components/organisms/getCare/televisitPayment/TelevisitPayTransactionExpired';

import { ISessionInformation, SessionInformationError } from 'domain/entities/paymentInfo';
// import Iframe from 'react-iframe'
import { ERROR_CODES, SESSION_STATUS, VARIABLE_TYPES } from '../../../domain/valueObjects/payment';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { selectPaymentLoading } from 'adapter/payment/paymentSelectors';
import Loader from '../../components/templates/loader';
import useAuth from 'app/hooks/useAuth';

const PaymentStatus = () => {

  const location  = useLocation();
  const dispatch = useAppDispatch();
  const [sessionResponse, setSessionResponse] = useState<ISessionInformation>()
  const isLoading = useAppSelector(selectPaymentLoading);
  const { logout } = useAuth();

  const checkPaymentStatus = async () => {

    const sessionId = location.search.split(`${VARIABLE_TYPES.SESSION}=`)[1];

    dispatch(checkTransactionStatus(sessionId))
    .unwrap()
    .then( (response: ISessionInformation) => {
      setSessionResponse(response);
    }).catch( ({message}: SessionInformationError) => {
      if( ERROR_CODES.includes(message) ) logout();
    })
  }

  useEffect(() => {
    setSessionResponse({} as ISessionInformation);
    if( !location.search || !location.search.includes(`${VARIABLE_TYPES.SESSION}=`) ) return;
    checkPaymentStatus();
  }, []);


  return (
    <>
      { isLoading && <Loader /> }
      {
        sessionResponse?.transactionInformation?.status === SESSION_STATUS.SUCCEEDED 
        && <TelevisitPayResult paymentInfo={sessionResponse} />
      }
      {
        sessionResponse?.transactionInformation?.status === SESSION_STATUS.EXPIRED 
        && <TelevisitPayTransactionExpired paymentInfo={sessionResponse} />
      }
      {
        sessionResponse?.transactionInformation?.status === SESSION_STATUS.IN_PROCESS
        && <TelevisitPayPending paymentInfo={sessionResponse} checkPaymentStatus={checkPaymentStatus} />
      }
    </>
  )

};

export default PaymentStatus;
