
import styled from '../../ui-core/styled-components';
import Row from './row';
import Column from './Column';
import CenteredWrapper from './centeredWrapper';
import SpaceWrapper from './spaceWrapper';
import Image from '../atoms/image';
import KeraltyText from '../atoms/keraltyText';
import theme from '../../ui-core/utils/theme';

interface IconLinkRowProps {
	onClick?: () => void;
	icon: any;
	iconAlt: string;
	text: string;
	color: keyof (typeof theme.colors);
	nounderline?: boolean | null;
	iconSize?: number | null;
	textType?: string | null;
	bold?: boolean | false;
	weight?: string | null;
	fontSize?: number | null;
	centerOnScreen?: boolean;
}

export const Template = ({ onClick, icon, iconAlt, text, color, nounderline, iconSize, textType, bold = false, weight = '700', fontSize, centerOnScreen }: IconLinkRowProps) => (
	<Row noMargin style={centerOnScreen ? {display: 'grid', placeItems: 'center'} : {}}>
		<Column auto noPadding>
			<CenteredWrapper direction="vertical" onClick={onClick}>
				<SpaceWrapper mr={.5} mt={.4}>
					<Image
						size={iconSize ? iconSize : 1}
						src={icon}
						alt={iconAlt} />
				</SpaceWrapper>
				<KeraltyText 	type={ !nounderline ? "link" : (textType ? textType : "subTitle") }
								color={color} bold={bold} weight={bold ? weight : 'normal'} style={{fontSize:fontSize? fontSize:''}}>{text}</KeraltyText>
			</CenteredWrapper>
		</Column>
	</Row>
)

export const IconLinkRow = styled(Template)`
	margin-bottom: 0rem;
	padding: 0rem !important;
	cursor: pointer;
`;

export const ActionLink = styled(Template)`
	margin-bottom: 0rem;
	padding: 0rem !important;
	cursor: pointer;
`;
