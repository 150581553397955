import PrivateHttpClient, { PrivateHttpClient2 } from '../privateHttpClient';
import { FHSRequest } from '../models/mentalHealth';

// DOMAIN
import { NeedHelpService } from 'domain/useCases/contracts/mentalHealthService';

const endpoint = 'mental-health';

const MentalHealth: NeedHelpService = {
	needHelp: () => {
		return PrivateHttpClient.get(`${endpoint}/need-help/getAllItems`);
	},
	getAllBaseInfo: () => {
		return PrivateHttpClient.get(`${endpoint}/educational/getAllBaseInfo`);
	},
	postFHS: (data): Promise<void> => {
		return PrivateHttpClient.post(`auth/${endpoint}/sendEmail`, data);
	},
	getStinkingThinking: () => {
		return PrivateHttpClient.get(`${endpoint}/stinking-thinking`);
	},
	getAnxietyRegisters: ({ authUid, state }) => {
		return PrivateHttpClient.get(`${endpoint}/anxiety/byUser?authUid=${authUid}&state=${state}`);
	},
	getAnxietyParameters: (type) => {
		return PrivateHttpClient.get(`${endpoint}/anxiety-parameters/name-type/${type}`);
	},
	saveEntry: (entry) => {
		return PrivateHttpClient.post(`${endpoint}/anxiety`, entry);
	},
	downloadResourse: (data) => {
		return PrivateHttpClient.get(`${endpoint}/educational/getFile/` + data);
	}

};

export default MentalHealth;
