import { getMonthName } from "app/ui-core/utils/dates";

interface EntryCardProps {
	text: string;
	date: string;
}

const EntryCard = ({ text, date }: EntryCardProps) => {
  const getDateByFormat = (date: string) => {
	if(!date) {
		return {
			month: '', 
			day: ''
		}
	}

    return {
		month: getMonthName(date, "short"), 
		day: new Date(date).getDate()
	}
  }

  const { month, day } = getDateByFormat(date);

  return (
    <div style={{ width: '335px', height: '90px', border: '1px solid #002D574D', borderRadius: '10px', boxShadow: '1px 5px 5px #002D574D', cursor: 'pointer'}}>
		<div style={{ padding: 10, display: 'flex', width: '100%'}}>
			<div style={{ width: '20%', height: '70px', display: 'grid', placeItems: 'center' }}>
				<span style={{ fontWeight: '700', fontSize: '18px', lineHeight: '21.92px', color: '#3CA70D', textTransform: 'uppercase' }}>{ month }</span>
				<span style={{ fontWeight: '700', fontSize: '18px', lineHeight: '21.92px', color: '#3CA70D' }}>{ day }</span>
			</div>
			<hr style={{ height: '60px', width: '.05vw', borderWidth: 0, color: '#065394', backgroundColor: '#065394' }} />
			<div style={{ width: '80%', height: '70px', fontWeight: 400, fontSize: '14px', display: 'grid', placeItems: 'center'}}>
				<span style={{paddingLeft: 10, paddingRight: 10}}>
					{ text }
				</span>
			</div>
		</div>
	</div>
  )
}

export default EntryCard;