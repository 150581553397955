import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IconLinkRow, Loader, Row } from '../../../templates';
import { KeraltyButton, KeraltyIcon, KeraltyText } from '../../../atoms';
import { Grid, RightContent, TitleContiner } from './Anxiety.styled';
import EntryCard from './components/EntryCard';
import ROUTES from "app/ui-core/utils/routes";
import NoSearchResult from 'app/components/molecules/noSearchResult';

import NoEntryYet from '../../../../assets/icons/noEntryYet.svg';
import arrowAltCircleLeftIcon from '../../../../assets/icons/arrow-alt-circle-left.svg';
import { useEffect, useState } from 'react';
import MentalHealth from 'infrastructure/keraltyApi/repositories/mentalHealth';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { AnxietyRegistersResponse } from 'infrastructure/keraltyApi/models/mentalHealth';
import { resetUnderstandingYourAnxiety, setRegisterSelected } from 'adapter/understandingAnxiety/understandingAnxiety.slice';
import useIsMobile from 'app/hooks/useIsMobile';

const UnderstandingYourAnxietyPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const isMobile = useIsMobile();
	const { MENTAL_HEALTH } = ROUTES;
	const { authUid, state  } = useAppSelector(state => state.user);

	const [registers, setCurrentRegisters] = useState<AnxietyRegistersResponse[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const dispatch = useAppDispatch();

	const getRegisters = async () => {
		setIsLoading(true);
		try {
			const { anxietyRegistersDtoList } = await MentalHealth.getAnxietyRegisters({ authUid, state });
			setCurrentRegisters(anxietyRegistersDtoList);
			setIsLoading(false);
		}catch(error){
			console.error(error);
			setIsLoading(false);
		}
	}

	useEffect(() => {
		dispatch(resetUnderstandingYourAnxiety());
		getRegisters();
	}, [])

	const selectItem = (data: AnxietyRegistersResponse) => {
		dispatch(setRegisterSelected(data));
		history.replace(`${MENTAL_HEALTH.anxietyDetails}`)
	}

	const NewEntryButton = () => (
		<div style={{ marginTop: 40 }}>
			<KeraltyButton              
				onClick={ () => history.replace(`${MENTAL_HEALTH.thinkingOptions}`) }
				fontWeight={ 600 }
				withShadow
			>
			<KeraltyIcon iconClass="fa fa-plus" color="lightBg" />
				{' '}
				{t('formLabel.newEntry')}
			</KeraltyButton>
		</div>
	)
	
	return (
		<>
			{ isLoading && <Loader /> }
			<RightContent fullHeight top='10'>
				<IconLinkRow
					onClick={() => history.replace(`${MENTAL_HEALTH.selfmanagement}`)}
					color="accent"
					icon={arrowAltCircleLeftIcon}
					iconAlt={t('imgNicons.backArrow')}
					text={t('routes.back')}
				/>
				<Row style={{ paddingBottom: isMobile ? 10 : 20 }}>
					<TitleContiner lg={12}>
						<KeraltyText align={isMobile ? 'left' : 'center'} type="title" fontWeight={isMobile ? 600 : 400}>{t('mentalHealth.understandingYourAnxiety')}</KeraltyText>
					</TitleContiner>
				</Row>
				<Row style={{ display: 'grid', placeItems: 'center'}}>
					{
						registers.length > 0
						? (
							<>
								<Grid>
									{
										registers.map( (register, index) => (
											<div key={index} onClick={() => selectItem(register)}>
												<EntryCard text={register.thinking} date={ register.dateTime } />
											</div>
										))
									}
								</Grid>
								<NewEntryButton />
							</>
						)
						: (
							<>
								{
									!isLoading 
									&&
									<>
										<NoSearchResult 
											image={NoEntryYet} 
											subTitle={t('mentalHealth.noEntryYet')} 
										/> 
										<NewEntryButton />
									</>
								}
							</>
						)
					}
				</Row>
			</RightContent>
		</>
	);
};

export default UnderstandingYourAnxietyPage;
