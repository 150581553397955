import { useMemo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../hooks/useAuth';
import ReferralsTable from './ReferralsTable';
// UI
import { KeraltyTable } from '../../molecules';
import EmptyIcon from '../../atoms/emptyIcon';
import TabWrapper from './TabWrapper';
//Actions
import { getReferralsData, cleanError } from 'adapter/myHealth/myHealthSlice';
import { selectmyHealthReferrals } from 'adapter/myHealth/myHealthSelectors';
import { useTranslation } from 'react-i18next';


const Referrals = () => {
	const { user } = useAuth();
	const { t } = useTranslation();
	const [activeRow, setActiveRow] = useState<number | undefined>();

	const referralsInfo = useAppSelector(selectmyHealthReferrals);
	//const asyncError = useAppSelector(selectmyHealthError);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getReferralsData(user.idEcw));

		return () => dispatch(cleanError());
	}, [dispatch, user.idEcw])

	const resetActiveRow = useCallback(() => {
		setActiveRow(undefined);
	}, []);

	return (
		<ReferralsTable referralsData={referralsInfo || []} onClickItem={setActiveRow} />
	);
};

export default Referrals;