// 3RD PARTY
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'adapter/hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
//UI
import Laboratories from './Laboratories';
//import MedicationTab from '../../components/organisms/myHealth/MedicationTab';
import ReferralsTab from '../../components/organisms/myHealth/ReferralsTab';
import ImmunizationTab from '../../components/organisms/myHealth/Immunizations';
import CurrentMedicationTab from '../../components/organisms/myHealth/CurrentMedicationTab';
import VisitSummaryTab from '../../components/organisms/myHealth/VisitSummaryTab';
import Loader from '../../components/templates/loader';
import { ReactComponent as LabResultsIcon } from '../../assets/icons/lab-results.svg';
//import { ReactComponent as MedicationIcon } from '../../assets/icons/medication.svg';
import { ReactComponent as ReferralsIcon } from '../../assets/icons/referrals.svg';
import { ReactComponent as CurrentIcon } from '../../assets/icons/Current.svg';
import { ReactComponent as VisitSummary } from '../../assets/icons/visitSummaryIcon.svg';
import { ReactComponent as InmunizationIcon } from '../../assets/icons/immunization.svg';
import KeraltySidebar, { SidebarProps } from '../../components/molecules/keraltySidebar';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import { selectmyHealthLoading } from 'adapter/myHealth/myHealthSelectors';
import MyHealthTab from 'app/components/organisms/myHealth/MyHealthTab';


const { MY_HEALTH, MAIN } = ROUTES;

const MyHealthMain = () => {
	const { t } = useTranslation();
	const isLoading = useAppSelector(selectmyHealthLoading);
	const { tab } = useParams();
	const history = useHistory();
 
	const sidebar: SidebarProps = {
		sideTabs: [
			{
				name: MY_HEALTH.medication,
				icon: LabResultsIcon,
				
				content: <MyHealthTab />,
				routeName: `${MAIN.myHealth}/${MY_HEALTH.medication}`,
				showOnMobile: false,
				
			},
			{
				name: MY_HEALTH.labResults,
				icon: LabResultsIcon,
				text: t('myHealth.labNimgingResults'),
				content: <Laboratories />,
				routeName: `${MAIN.myHealth}/${MY_HEALTH.labResults}`,
				showOnMobile: false,
			},
			{
				name: MY_HEALTH.referrals,
				icon: ReferralsIcon,
				text: t('myHealth.referrals'),
				content: <ReferralsTab />,
				routeName: `${MAIN.myHealth}/${MY_HEALTH.referrals}`,
				showOnMobile: false,
			},
			{
				name: MY_HEALTH.currentMedication,
				icon: CurrentIcon,
				text: t('myHealth.currentMedication'),
				content: <CurrentMedicationTab />,
				routeName: `${MAIN.myHealth}/${MY_HEALTH.currentMedication}`,
				showOnMobile: false,
			},
			{
				name: MY_HEALTH.visitSummary,
				icon: VisitSummary,
				text: t('myHealth.visitSummary'),
				content: <VisitSummaryTab />,
				routeName: `${MAIN.myHealth}/${MY_HEALTH.visitSummary}`,
				showOnMobile: false,
			},
			
			{
				name: MY_HEALTH.immunization,
				icon: InmunizationIcon,
				text: t('myHealth.immunizations'),
				content: <ImmunizationTab />,
				routeName: `${MAIN.myHealth}/${MY_HEALTH.immunization}`,
				showOnMobile: false,
			},
			
		],
		routeLabel: t('routes.myHealth'),
		activeByRoute: tab || MY_HEALTH.medication,
	};
	
	return (
		<>
			{isLoading && <Loader />}
			<KeraltySidebar {...sidebar}  hideMenu={history.location.pathname == '/my-health'? true : false} />
		</>
	);
};

export default MyHealthMain;
