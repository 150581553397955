import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CodeEmailMobileRequest, ConfirmCodeRequest } from 'domain/useCases/contracts/twoFactorAuthenticationService';
import TwoFactorAuthenticationUseCase from 'domain/useCases/twoFactorAuthentication.usecases';
import { TwoFactorAuthenticationSteps, TwoFactorAuthenticationState } from './twoFactorAuthentication.state';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import appConfig from 'config';

export const verifyEmailAndPassword = createAsyncThunk(
    'auth/verifyEmailAndMobile',
	async (info: string) => {
		return await TwoFactorAuthenticationUseCase.verifyEmailAndPassword(info);
	}
);

export const requestCodeEmailMobile = createAsyncThunk(
    'auth/requestCodeEmailMobile',
	async (payload: CodeEmailMobileRequest) => {
		return await TwoFactorAuthenticationUseCase.requestCodeEmailMobile(payload);
	}
);

export const confirmCode = createAsyncThunk(
    'auth/confirmCode',
	async (payload: ConfirmCodeRequest) => {
		return await TwoFactorAuthenticationUseCase.confirmCode(payload);
	}
);


export const stateName2FA = 'two-factor-authentication'

const initialState: TwoFactorAuthenticationState = {
   info: {
    email: '',
    password: '',
    state: '',
	encryptedInfo: '',
	phone: '',
	sendCodeBy: '',
	idTemp: '',
	sendCodeAttemps: '',
	errorCodeAttemps: '',
	validCode: false,
	currentStep: TwoFactorAuthenticationSteps.default,
	expiredCode: false
   }
};

export const persistTwoFactorConfig = {
	key: stateName2FA,
	storage: sessionStorage,
	whitelist: ['info'],
	transforms: [
		encryptTransform({
			secretKey: appConfig.KERALTY_SWS_KEY,
		  	onError: function (error) {
				// Handle the error.
		  	},
		}),
	],
};


const TwoFactorAuthenticationSlice = createSlice({
	name: stateName2FA,
	initialState,
	reducers: {
		updateInfoTwoFactor: (state, action) => {
			return {
				...state,
				info: {
                    ...state.info,
                    ...action.payload
                }
			}
		},
		resetInfoTwoFactor: () => {
			return initialState;
		}
	}
});

export const { updateInfoTwoFactor, resetInfoTwoFactor } = TwoFactorAuthenticationSlice.actions;

export default TwoFactorAuthenticationSlice.reducer;

// const dispatch = useAppDispatch();
// const data: MaintenanceAndNotificationsResponse[] = await dispatch(getMaintenanceInfo()).unwrap();