
import styled from '../../ui-core/styled-components';

interface ImageProps {
	size?: number;
	height?: number;
	width?: number;
};

const Image = styled.img<ImageProps>`
	${({ size, height, width }) => `
		height: ${size ? `${size}rem` : (height ? `${height}rem` : 'auto')};
		width: ${size ? `${size}rem` : (width ? `${width}rem` : 'auto')};
	`}
	cursor: pointer;
`;

export default Image;
