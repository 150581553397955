import { KeraltyText, Image, KeraltyButton } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import circleExclamation from '../../assets/icons/circle-exclamation2.svg';
import useIsMobile from 'app/hooks/useIsMobile';
import { TEXT_TO_REPLACE } from 'app/ui-core/utils/constants';


const IconExclamation = styled(Image)`
  width: 34px;
  margin-top: 40px;
  cursor: initial !important;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 24px;
    margin-top: 5px;
  }
`;

const KeralTextButt = styled(KeraltyText)`
background: #FFFFFF !important;
line-height: 14px;
border-color: #3CA70D;
border-radius: 25px;
text-align: center;
cursor: pointer;
color: #3CA70D;
align-self: center !important;
padding: 13px;
text-align: center;
font-weight: 100;
text-transform: uppercase;
font-weight: 700;
font-size: 18px;
`;

interface InsuranceNoValidatedProps {
  close?: any;
  scheduleText: string
}

const PrivateModalMaintenance = ({
  close,
  scheduleText
}: InsuranceNoValidatedProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <KeraltyModal size="extraLongEighty" textalign="center">
      <div
        style={{
          marginRight: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconExclamation src={circleExclamation} alt="" style={{ marginTop: 0, width: 50 }} />
        <Row mt={isMobile ? 1 : 2.3}>
          <KeraltyText
            type="title"
            fontWeight="600"
            fontSize="18px"
            color="#055293"
            style={{ lineHeight: '2.25rem', marginTop: 0}}
            noTextTransform
          >
            {t('privateMaintenanceText').replace(TEXT_TO_REPLACE.SCHEDULE, scheduleText)}
          </KeraltyText>
        </Row>
        <Row
          noMargin
          style={{
            width: isMobile ? '255px' : '320px',
            marginTop: isMobile ? '-10px' : '10px',
            paddingBottom: '3%',
          }}
        >
          <KeraltyText type={'info'} color="#055293" fontSize="16px" noTextTransform>
            {t('maintenanceApologize')}
          </KeraltyText>
        </Row>
        <hr style={{ height: '1px', width: '98%', borderWidth: 0, color: '#999999', backgroundColor: '#999999' }} />
        <KeralTextButt onClick={close} type="label">{t('button.accept')}</KeralTextButt>
      </div>
    </KeraltyModal>
  );
};


export {
  PrivateModalMaintenance,
};
