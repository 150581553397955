import { Column, SpaceWrapper, IconLinkRow, Row } from '../templates';
import { KeraltyText } from '../atoms';

import theme from '../../ui-core/utils/theme';

import styled from '../../ui-core/styled-components';

export interface DataProps {
    labelTitle: string,
    labelData: string,
    icon: any,
    color?: keyof (typeof theme.colors) | null;
    size?: number | null;
}

interface DataCardProps {
    data: DataProps[],
}

const TextDataList = styled(KeraltyText)`
    vertical-align: bottom;
`;

const KeraltyDataList = ({ data }: DataCardProps) => {
    return (
        <Column>
            {
                data.map((item) => {
                    return (
                        <Row>
                            <SpaceWrapper mr={1}>
                                <IconLinkRow
                                    color={item.color ? item.color : 'dark'}
                                    iconSize={item.size ? item.size : 1.67}
                                    nounderline
                                    icon={item.icon}
                                    iconAlt={item.labelTitle}
                                    text={item.labelTitle}
                                />
                            </SpaceWrapper>
                            <TextDataList color={item.color ? item.color : 'dark'} type="subTitle">{item.labelData}</TextDataList>
                        </Row>
                    )
                })
            }
        </Column>
    )
}

export default KeraltyDataList;