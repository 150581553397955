import { KeraltyStepper } from "app/components/molecules";
import { IconLinkRow, Row } from "app/components/templates";
import ROUTES from "app/ui-core/utils/routes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TitleContiner } from "../Anxiety.styled";
import { KeraltyText } from "app/components/atoms";
import { useLocation } from 'react-router-dom';
import styled from "app/ui-core/styled-components";
import BREAKPOINTS from "app/ui-core/utils/breakpoints";
import arrowAltCircleLeftIcon from '../../../../../assets/icons/arrow-alt-circle-left.svg';
import { useHistory } from 'react-router-dom';
import { useAnxiety } from "../hooks/useAnxiety";

const Section = styled.section`
	margin-left: 20%; 
	margin-right: 20%;

	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-left: 7%; 
		margin-right: 7%;
	};
`

const AnxietySteps = ({children}: { children: any }) => {

  const { FORGOT: CURRENT, MAIN, MENTAL_HEALTH } = ROUTES;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { register } = useAnxiety();

  const [currentStep, setCurrentStep] = useState<number>(0)

  const steps = useMemo(() => [
		{	
			label: '',
			route: `${MAIN.mentalHealth}/${MENTAL_HEALTH.thinkingOptions}`,
			step: 0
		},
		{	
      		label: '',
			route: `${MAIN.mentalHealth}/${MENTAL_HEALTH.thinkingDetails}`,	
			step: 1		
		},
		{
			label: '',
			route: `${MAIN.mentalHealth}/${MENTAL_HEALTH.actionsDetails}`,
			step: 1
		},
    	{
			label: '',
			route: `${MAIN.mentalHealth}/${MENTAL_HEALTH.feelingOptions}`,
			step: 2
		},
		{
			label: '',
			route: `${MAIN.mentalHealth}/${MENTAL_HEALTH.physicalSymptomsOptions}`,
			step: 3
		},
  ], [t]);

  const back = () => {
	const index = steps.findIndex( step => `/${step.route}` === pathname );

	if(index === 0){
		history.replace(`${MENTAL_HEALTH.anxiety}`);
		return;
	}

	history.replace(steps[ index - 1 ].route.replace(`${MAIN.mentalHealth}/`, ''));

  }

  useEffect(() => {
	steps.forEach((step) => {
		if(`/${step.route}` === pathname) setCurrentStep(step.step)
	});

	if( pathname === `/${MAIN.mentalHealth}/${MENTAL_HEALTH.thinkingDetails}` && register.thinking === ''){
		history.replace(`${MENTAL_HEALTH.anxiety}`);
		return;
	}
	if( pathname === `/${MAIN.mentalHealth}/${MENTAL_HEALTH.actionsDetails}` && register.moreAbout === ''){
		history.replace(`${MENTAL_HEALTH.anxiety}`);
		return;
	}
	if( pathname === `/${MAIN.mentalHealth}/${MENTAL_HEALTH.feelingOptions}` && register.actions === ''){
		history.replace(`${MENTAL_HEALTH.anxiety}`);
		return;
	}
	if( pathname === `/${MAIN.mentalHealth}/${MENTAL_HEALTH.physicalSymptomsOptions}` && register.feeling === ''){
		history.replace(`${MENTAL_HEALTH.anxiety}`);
		return;
	}
  }, [pathname])
  

  return (
	<section style={{ display: 'grid', width: '100%' }}>
		<div style={{ marginLeft: 10 }}>
			<IconLinkRow
				onClick={() => back()}
				color="accent"
				icon={arrowAltCircleLeftIcon}
				iconAlt={t('imgNicons.backArrow')}
				text={t('routes.back')}
			/>
		</div>
		<Row style={{ paddingBottom: 20,  }}>
			<TitleContiner lg={12}>
				<KeraltyText type="title" fontWeight={400}>{t('mentalHealth.understandingYourAnxiety')}</KeraltyText>
			</TitleContiner>
		</Row>
		<KeraltyStepper steps={steps} activeStep={currentStep} noPadding>
			<section style={{ width: '100%' }}>
				<Section>
					{ children }	
				</Section>
      		</section>
		</KeraltyStepper>
	</section>
  )
}

export default AnxietySteps;