import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from './en';
import es from './es';
import { KeraltySWS } from "app/hooks/useKeraltySWS";
import { SESSION_STORAGE_ITEMS } from "../utils/constants";

const localStorageLang = localStorage.getItem(SESSION_STORAGE_ITEMS.I18NEXTLNG);
const hasStoredLang = localStorageLang && localStorageLang !== 'null';
const userLangBrowser = (navigator.language || 'en').split('-')[0];
const currentLang = hasStoredLang && (localStorageLang !== userLangBrowser) ? localStorageLang : (userLangBrowser || 'en');

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: en
			},
			es: {
				translation: es
			}
		},
		react: {
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
		}
	});

i18n.changeLanguage(`${currentLang}`);

export default i18n;
