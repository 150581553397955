import PublicHttpClient from "../publicHttpClient";
import { MaintenanceAndNotificationsService } from "domain/useCases/contracts/maintenanceAndNotificationsService";
import appConfig from 'config';

const endpoint = 'params';

const MaintenanceAndNotificationsRepository: MaintenanceAndNotificationsService = {
	maintenanceInfo: async () => {
		if( PublicHttpClient.defaults.headers['Authorization'] === 'Bearer ') PublicHttpClient.defaults.headers['Authorization'] = `Bearer ${appConfig.PUBLIC_API_TOKEN}`

		return await PublicHttpClient.get(`${endpoint}/maintenance/info`);
	},
};

export default MaintenanceAndNotificationsRepository;