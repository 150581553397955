// 3RD PARTY
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
// UI
import { KeraltyButton } from '../../components/atoms';
import { KeraltyField } from '../../components/molecules';
import { Container, FormBox } from 'app/components/templates';
import PreLoginHeader from 'app/components/molecules/preLoginHeader/PreLoginHeader';
import TermsAndConditions from 'app/components/termsAndConditions/TermsAndConditions';
// ADAPTER
import { useAppSelector, useAppDispatch } from 'adapter/hooks';
import { userActions } from 'adapter/user/userSlice';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';

const SANITAS_EXTERNAL_URL = 'https://mycw88.ecwcloud.com/portal11700/jsp/100mp/login_otp.jsp';
type ISelectOptions = any;

const LocationSelect = () => {
	const dispatch = useAppDispatch();
	const locationSelected = useAppSelector((state) => state.user.locationSelected);
	const history = useHistory();
	const { t } = useTranslation();

	const mockOptions: ISelectOptions[] = [
		{ value: 'Florida', label: 'Florida' },
		{ value: 'Texas', label: 'Texas' },
		{ value: 'Tennessee', label: 'Tennessee' },
		{ value: 'New Jersey', label: 'New Jersey' },
	];

	const handleStateChange = (val: ISelectOptions): void => {
		// @TODO : Remove this when other locations are supported
		if (val.value !== mockOptions[0].value) {
			window.location.assign(SANITAS_EXTERNAL_URL);
		} else {
			dispatch(userActions.setLocation(val));
		}
	};

	const handleContinue = () => {
		locationSelected && history.push(`/${ROUTES.MAIN.home}`);
	};

	return (
		<div className="pre-login">
			<PreLoginHeader />
			<div className="pre-login-body">
				<div className="title">
					<h3 className="font-size-h3">{t('preLoginTitle')}</h3>
					<p className="font-size-body2">{t('preLoginSubTitle')}</p>
				</div>
				<Container centered={'x'}>
					<FormBox>
						<Container px>
							<KeraltyField
								iconClass="far fa-map-marked-alt"
								title={t('imgNicons.mapMarkedIcon')}
								type={'select'}
								control={Select}
								options={mockOptions}
								onChange={handleStateChange}
								value={locationSelected} />
							<KeraltyButton
								full
								type={'button'}
								disabled={!locationSelected}
								onClick={handleContinue} >
								{t('continueButtonText')}
							</KeraltyButton>
						</Container>
					</FormBox>
				</Container>
			</div>
			<div className="pre-login-footer">
				<TermsAndConditions />
			</div>
		</div>
	);
};

export default LocationSelect;
