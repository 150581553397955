import * as Yup from 'yup';
import { REGEX } from '../utils/regex';

export interface LoginType {
	email?: string;
	password?: string;
	state?: string;
};

export const LoginSchema: Yup.SchemaOf<LoginType> = Yup.object().shape({
	email: Yup.string()
		//.required('required')
		//	.min(5, 'min')
		//	.max(255, 'max')
		//	.matches(REGEX.email, 'invalidEmail')
		.when("$isLoginDisabled", (isLoginDisabled, schema) => {
			if (!isLoginDisabled) {
				return schema.required('required').min(5, 'min').max(255, 'max').matches(REGEX.email, 'invalidEmail');
			}
		}),
	password: Yup.string()
		//	.required('required')
		//	.min(8, 'min')
		//	.max(50, 'max')
		.when("$isLoginDisabled", (isLoginDisabled, schema) => {
			if (!isLoginDisabled) {
				return schema.required('required').min(8, 'min').max(50, 'max');
			}
		}),
	state: Yup.string()
		.notRequired()
		.max(50, 'max'),
});

export const DefaultLogin: LoginType = {
	email: '',
	password: '',
	state: '',
};
