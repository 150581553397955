import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import AnxietySteps from "./components/AnxietySteps";
import TitleSection from "./components/TitleSection";
import { ReactComponent as feeling } from '../../../../assets/icons/feeling.svg';
import { KeraltyButton } from "app/components/atoms";
import ROUTES from "app/ui-core/utils/routes";
import Chip from "./components/Chip";
import { useAnxiety } from "./hooks/useAnxiety";
import { AnxietyParameter, TypeAnxietyParameter } from "infrastructure/keraltyApi/models/mentalHealth";
import { useEffect, useState } from "react";
import { Loader } from "app/components/templates";
import { CustomSection } from "./Anxiety.styled";

const FeelingOptions = () => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const { register, saveInfoInStore, setIsLoading, isLoading, getAnxietyParametersByType } = useAnxiety();
  const [feelings, setFeelings] = useState<AnxietyParameter[]>([]);
  const [optionsSelected, setOptionsSelected] = useState<string[]>([])

	const { MENTAL_HEALTH } = ROUTES;

  const getData = async () => {
    setIsLoading(true)
    try{
      const data = await getAnxietyParametersByType(TypeAnxietyParameter.FEELING);
      setFeelings(data);
      setIsLoading(false);
    }catch(error){
      console.error(error);
      setIsLoading(false);
    }
  }

  const valueSelected = (text: string): void => {

    const valueFound = optionsSelected.find(option => option === text);

    if(!valueFound){
      setOptionsSelected([...optionsSelected, text]);
      return      
    }

    const newOptions = optionsSelected.filter(option => option !== text);
    setOptionsSelected(newOptions);

  }

  const nextStep = () => {
    if(optionsSelected.length === 0){
      return
    }

    const feeling = optionsSelected.reduce((accumulator, currentValue) => `${accumulator} ${ accumulator === '' ? accumulator : '|' } ${currentValue}`, '');

    saveInfoInStore({ feeling: feeling.trim() })
    history.replace(`${MENTAL_HEALTH.physicalSymptomsOptions}`)
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if(register.feeling) setOptionsSelected(register.feeling.split(' | '))
  }, [register])

  return (
    <>
        {
          isLoading
          ? ( <Loader /> )
          : (
            <AnxietySteps>
              <div style={{ display: 'grid', placeItems: 'center', marginBottom: 20 }}>
                <KeraltyButton fontWeight={ 600 } fontSize="14px" width="27rem" withShadow withoutTextTransform>
                  { register.thinking }
                </KeraltyButton>
              </div>
              <TitleSection 
                icon={feeling} 
                title={t('mentalHealth.feeling')}
                subtitle={t('mentalHealth.feelingText')}
              />
              <CustomSection responsiveColums={2}>
                {
                    feelings.map( (item, index) => (
                      <div key={index}>
                        <Chip 
                          text={ i18n.language === 'es' ? item.contentEs : item.contentEn }
                          valueSelected={ valueSelected } 
                          currentValue={ optionsSelected.find( option => option === (i18n.language === 'es' ? item.contentEs : item.contentEn) ) }
                        />
                      </div>
                    ))
                }
              </CustomSection>
              <div style={{ marginTop: 70, display: 'grid', placeItems: 'center' }}>
                  <KeraltyButton              
                    onClick={nextStep}
                    fontWeight={ 600 }
                    withShadow
                    disabledColor="#00000040"
                    disabled={optionsSelected.length === 0 ? true : false }
                  >
                    {t('formLabel.enter')}
                  </KeraltyButton>
              </div>
            </AnxietySteps>
          )
        }
    </>
  )
}

export default FeelingOptions;