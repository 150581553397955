import { LazyExoticComponent, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

type StepItem = {
	label: string;
	route: string;
	hidden?: boolean;
	component?: LazyExoticComponent<any>;
};

type useStepperType = (
	initialStep?: number,
	steps?: StepItem[],
	finish?: () => void
) => {
	currentStep: number;
	next: (val?: number) => void;
	prev: (val?: number) => void;
	Step: any;
};

export const useStepper: useStepperType = (initialStep = 0, steps = [], finish) => {
	const { step } = useParams();
	const { push, goBack } = useHistory();

	const currentStep = useMemo(() => {
		if (steps.length) return steps.findIndex((x: any) => x.route === step) || 0;
		else return initialStep;
	}, [step, steps, initialStep]);

	const handleNext = (val = 1) => {
		if ((currentStep + val) === steps.length && finish) finish();
		else {
			const newStep = steps[currentStep + val];
			if (newStep) push(newStep.route);
		}
	};

	const handlePrev = () => goBack();

	const Step = useMemo(() => steps[currentStep]?.component || null, [steps, currentStep]);

	return {
		currentStep,
		Step,
		next: handleNext,
		prev: handlePrev
	};
};
export default useStepper