// 3RD PARTY
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'adapter/hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
//UI

//import MedicationTab from '../../components/organisms/myHealth/MedicationTab';
import Loader from '../../components/templates/loader';

//import { ReactComponent as MedicationIcon } from '../../assets/icons/medication.svg';
import { ReactComponent as AboutIcon } from '../../assets/icons/mh-about.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-duotone.svg';
import { ReactComponent as helpIcon } from '../../assets/icons/mh-help.svg';
import { ReactComponent as selfManagmentTools } from '../../assets/icons/self-managment-tools.svg';
import { ReactComponent as EducationalIcon } from '../../assets/icons/mh-educational.svg';
import { ReactComponent as SelfIconWeb } from '../../assets/icons/SelfIconWeb.svg';


import KeraltySidebar, { SidebarProps } from '../../components/molecules/keraltySidebar';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import AboutPage from 'app/components/organisms/MentalHealth/About';
import MentalMainPage from 'app/components/organisms/MentalHealth/MentalMain';
import MHAppointments from 'app/components/organisms/MentalHealth/Appointments';
import NeedHelpPage from 'app/components/organisms/MentalHealth/NeedHelp';
import EducationalResourcesPage from 'app/components/organisms/MentalHealth/EducationalResources';
import UnderstandingYourAnxietyPage from 'app/components/organisms/MentalHealth/Anxiety/UnderstandingYourAnxietyPage';
import AnxietyDetails from 'app/components/organisms/MentalHealth/Anxiety/AnxietyDetails';
import ThinkingOptions from 'app/components/organisms/MentalHealth/Anxiety/ThinkingOptions';
import ThinkingDetails from 'app/components/organisms/MentalHealth/Anxiety/ThinkingDetails';
import ActionsDetails from 'app/components/organisms/MentalHealth/Anxiety/ActionsDetails';
import FeelingOptions from 'app/components/organisms/MentalHealth/Anxiety/FeelingOptions';
import PhysicalSymptomsOptions from 'app/components/organisms/MentalHealth/Anxiety/PhysicalSymptomsOptions';
import Cbt from 'app/components/organisms/MentalHealth/Anxiety/Cbt';
import YouMadeIt from 'app/components/organisms/MentalHealth/Anxiety/YouMadeIt';
import SelfManagement from 'app/components/organisms/MentalHealth/SelfManagement';
import StinkingThinking from 'app/components/organisms/MentalHealth/StinkingThinking';

import BeWellMain from 'app/components/organisms/MentalHealth/BewellMain';
import UpcomingBewell from 'app/components/organisms/MentalHealth/BeWell/upcomingBeWell';
import PreviousBeWell from 'app/components/organisms/MentalHealth/BeWell/PreviousBeWell';

const { MENTAL_HEALTH, MAIN } = ROUTES;

const MentalHealthMain = () => {
	const { t } = useTranslation();
	const isLoading = false;
	const { tab } = useParams();
	const history = useHistory();
 
	const sidebar: SidebarProps = {
		sideTabs: [			
			{
				name: MENTAL_HEALTH.mental,
				icon: AboutIcon,
				content: <MentalMainPage />,
				routeName: `${MAIN.mentalHealth}`,
				showOnMobile: false,
				hideMenu: true,
				showRoute:true,
			},
			{
				name: MENTAL_HEALTH.about,
				icon: AboutIcon,
				text: t('mentalHealth.about'),
				content: <AboutPage />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.about}`,
				showOnMobile: false,
			},
			{
				name: MENTAL_HEALTH.appointments,
				icon: CalendarIcon,
				text: t('mentalHealth.appointments'),
				content: <MHAppointments />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.appointments}`,
				showOnMobile: false,
				hideMenu: true
			},
			{
				name: MENTAL_HEALTH.edures,
				icon: EducationalIcon,
				text: t('mentalHealth.educational'),
				content: <EducationalResourcesPage />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.edures}`,
				showOnMobile: false,
			},
			{
				name: MENTAL_HEALTH.help,
				icon: helpIcon,
				text: t('mentalHealth.needHelp'),
				content: <NeedHelpPage />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.help}`,
				showOnMobile: false,
			},
			{
				name: MENTAL_HEALTH.anxiety,
				icon: selfManagmentTools,
				content: <UnderstandingYourAnxietyPage />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.anxiety}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.anxietyDetails,
				icon: selfManagmentTools,
				content: <AnxietyDetails />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.anxietyDetails}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.thinkingOptions,
				icon: selfManagmentTools,
				content: <ThinkingOptions />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.thinkingOptions}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.thinkingDetails,
				icon: selfManagmentTools,
				content: <ThinkingDetails />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.thinkingDetails}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.actionsDetails,
				icon: selfManagmentTools,
				content: <ActionsDetails />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.actionsDetails}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.feelingOptions,
				icon: selfManagmentTools,
				content: <FeelingOptions />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.feelingOptions}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.physicalSymptomsOptions,
				icon: selfManagmentTools,
				content: <PhysicalSymptomsOptions />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.physicalSymptomsOptions}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.cbt,
				icon: selfManagmentTools,
				content: <Cbt />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.cbt}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.youMadeIt,
				icon: selfManagmentTools,
				content: <YouMadeIt />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.youMadeIt}`,
				showOnMobile: false,
				showRoute: true
			},
			{
				name: MENTAL_HEALTH.selfmanagement,
				icon: SelfIconWeb,
				text: t('mentalHealth.tools'),
				content: <SelfManagement />,
				routeName: `${MAIN.mentalHealth}/${MENTAL_HEALTH.selfmanagement}`,
				showOnMobile: false,
			},




		],
		routeLabel: t('routes.mentalHealth'),
		activeByRoute: tab|| MENTAL_HEALTH.mental,
	};

	const routesWithMenu = sidebar.sideTabs
		.filter( sidebar => sidebar.hideMenu)
		.map( sidebar => `/${sidebar.routeName}`)
	
	return (
    <>
      {isLoading && <Loader />}
      <KeraltySidebar
        {...sidebar}
        hideMenu={
          routesWithMenu.includes(history.location.pathname) ||
          history.location.pathname == `/${MAIN.mentalHealth}/${MENTAL_HEALTH.appointments}/${MENTAL_HEALTH.appointmentsFHS}` 
		  || history.location.pathname == `/${MAIN.mentalHealth}/${MENTAL_HEALTH.edures}`
        }
      />
    </>
  );
};

export default  MentalHealthMain  ;
