
import PreLoginHeader from 'app/components/molecules/preLoginHeader/PreLoginHeader';

import { ReactComponent as Abstract } from 'app/assets/images/abstract-login-ellipsis.svg';
import { ReactComponent as AppointmentIcon } from 'app/assets/icons/appointment.svg';
import { ReactComponent as ConnectDoctor } from 'app/assets/icons/connect-doctor.svg';
import { ReactComponent as HealthRecordIcon } from 'app/assets/icons/health-records.svg';
import { ReactComponent as MonitorIcon } from 'app/assets/icons/monitor.svg';
import { ReactComponent as SupportIcon } from 'app/assets/icons/support.svg';
import { ReactComponent as TestsResultsIcon } from 'app/assets/icons/tests-results.svg';
import { ReactComponent as PlayStore } from 'app/assets/icons/play-store.svg';
import { ReactComponent as AppStore } from 'app/assets/icons/app-store.svg';
import { ReactComponent as AbstractHeader } from 'app/assets/images/abstract-login-header.svg';
import useIsMobile from '../../../../hooks/useIsMobile';
import { ReactComponent as SupportChatIcon2 } from '../../../../assets/icons/newChat.svg';
import { ReactComponent as SupportChatIcon } from '../../../../assets/icons/chat 2.svg';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import * as sanitasAdapterMethods from '../../../../../adapter/sanitasChat/sanitasChat';
import { useHistory } from 'react-router-dom';

import FloatingMenu from 'app/components/atoms/circularMenu/FloatingMenu';
import { useTranslation, Trans } from 'react-i18next';

import './loginLayoutStyles.scss';
import { Column, Loader } from 'app/components/templates';
import { useCallback, useEffect, useRef } from 'react';

import { utcTime } from 'app/ui-core/utils/times';
import { Alert } from 'app/components/atoms/Alert';
import useMaintenanceAndNotifications from 'app/hooks/useMaintenanceAndNotifications';
import { MaintenanceAndNotificationsMessage } from 'domain/useCases/contracts/maintenanceAndNotificationsService';
import i18n from 'app/ui-core/i18n';
import { ModalMaintenance } from 'app/components/molecules/ModalMaintenance';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { KeraltyChat, KeraltyChatComponent } from '@quillbot/keralty-chat-lit';

const LoginLayout = ({ children, hideStores, bottomPosition, hideFloattingMenu = false }: any) => {
	const { t } = useTranslation();
	const isMobile = useIsMobile();
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { notifications, isActivedLoading, scheduleText, inMaintenance, modal, closeAlert, alertClosed } =  useMaintenanceAndNotifications();

	KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.IS_PRIVATE)

	const triggerPatientSupport = useCallback(async () => {
		//if (isSCOpen) dispatch(closeSymptomChecker());
		//if (sanitasState.isShow && sanitasState.isSetted) {
		//	await dispatch(sanitasAdapterMethods.shutdown());
		//}
		dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: 'support', state: true }));
		// await setTimeout(async () => {
		// 	await dispatch(sanitasAdapterMethods.setUp({
		// 		actionColor: "#7cbc98",
		// 		backgroundColor: "#7cbc98",
		// 		name: ``,
		// 		email: ``,
		// 		state: '',
		// 		currentTimeZone: `${utcTime()}`,
		// 		authUid: '',
		// 		sanitasAccountNumber: '',
		// 		gender: '',
		// 		birthday: '',
		// 		customAttributes: { cType: 'patientSupport' },
		// 	}));
		// 	await dispatch(sanitasAdapterMethods.show());
		// }, 500);
	}, [dispatch])

	const handlerOnClick = async () => {
		triggerPatientSupport();
	};

	const CustomAlert = ({ message }: {message: MaintenanceAndNotificationsMessage}) => {

		const title = i18n.language === 'en' ? message.title_en : message.title_es;
		const body = i18n.language === 'en' ? message.body_en : message.body_es;

		if(alertClosed) return (<></>)

		return (
		<>
			<br />
			<div style={{ display: 'grid', placeItems: 'center' }}>
				<Alert
					message={ body }
					tittleAlert={title}
					isDefault={false}
					fullWidth={false}
					close={() => closeAlert()}
				/>
			</div>
		</>
	)}

	const useRefChat: any = useRef(null);
	useEffect(() => {
	  useRefChat.current = new KeraltyChat();
	}, []);

	//Open chat for SMS (Stop & Help)
	useEffect(() => {
	  const urlParams = new URLSearchParams(window.location.search);
	  const id = urlParams.get('chat');

	  setTimeout(() => {
		if (id === 'true' && useRefChat.current) {
		  dispatch(sanitasAdapterMethods.setKeraltChatAction({ queue: 'support', state: true }));
		  // useRefChat.current.openChat();
		}
	  }, 1000);
	}, []);

	return (
		<div className="login">
			{ isActivedLoading && <Loader />  }
			<PreLoginHeader>
				{
					window.innerWidth < 600 ?
					<>
						{
							inMaintenance.displayAlert && window.location.pathname === '/login' && notifications.map(({ message }, index)=> (
								<CustomAlert key={index} message={message} />
							))
						}
					</>
				 	: undefined
				}
			</PreLoginHeader>

			<div className={"login-body arc"}>
				<div className="login-left-side">
					<div className="text-container">
						<div className="main-text bold">
							<Trans i18nKey="LoginLayout.title"></Trans>
							<div className="divider"></div>
						</div>
					</div>
					<div className="icons-container">
						<div className="wrapper">
							<div className="icon">
								<AppointmentIcon />
								<p className="icon-desc font-size-body2">{t('LoginLayout.icons.schedule')}</p>
							</div>
							<div className="icon">
								<ConnectDoctor />
								<p className="icon-desc font-size-body2">{t('LoginLayout.icons.chatDoctor')}</p>
							</div>
							<div className="icon">
								<MonitorIcon />
								<p className="icon-desc font-size-body2">{t('LoginLayout.icons.monitor')}</p>
							</div>
							<div className="icon">
								<TestsResultsIcon />
								<p className="icon-desc font-size-body2">{t('LoginLayout.icons.testsResults')}</p>
							</div>
							<div className="icon">
								<HealthRecordIcon />
								<p className="icon-desc font-size-body2">{t('LoginLayout.icons.healthRecord')}</p>
							</div>
							<div className="icon">
								<SupportIcon />
								<p className="icon-desc font-size-body2">{t('LoginLayout.icons.support')}</p>
							</div>
						</div>
					</div>
					{/* PEDING UNTIL FIRST APP RELEASE */}
					<div className="stores-container">
						<div className="play-store">
							<a
								href="https://play.google.com/store/apps/details?id=com.keralty.mysanitas"
								rel="noreferrer"
								target="_blank"
							>
								<PlayStore />
							</a>
						</div>
						<div className="app-store">
							<a
								href="https://apps.apple.com/us/app/mysanitas/id1600791076"
								rel="noreferrer"
								target="_blank"
							>
								<AppStore />
							</a>
						</div>
					</div>
					<div className="abstract-wrapper">
						<Abstract />
					</div>
				</div>

				<div className="login-right-side">
					<Column sm={0}>
						<AbstractHeader className="abstract-header" />
					</Column>
					<div className="children-wrapper">
						{
							window.innerWidth > 600 &&
							<>
								{
									inMaintenance.displayAlert && window.location.pathname === '/login' && notifications.map(( { message }, index ) => (
										<CustomAlert key={index} message={message} />
									))
								}
							</>
						}
						{modal && <ModalMaintenance scheduleText={scheduleText} />}
						{children}
						{!hideStores && (
							<div className="stores-container">
								<div className="play-store">
									<PlayStore />
								</div>
								<div className="app-store">
									<AppStore />
								</div>
							</div>
						)}
					</div>
					{(!hideFloattingMenu && !isMobile) || history.location.pathname === '/login' ? (
						<FloatingMenu
						bottomPosition={bottomPosition}
						openChat={() => useRefChat.current.openChat()}
						/>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};

export default LoginLayout;
