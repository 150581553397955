// 3RD PARTY
import { useTranslation } from 'react-i18next';

import { ReactComponent as PeopleArrowsSvg } from '../../../../assets/icons/people-arrows.svg';

// Sub Components
import PersonalInfoBlocks from '../personalInfoBlocks';
import { EmergencyContanctInfo } from '../../../../../domain/entities/emergencyContanctInfo';
import isEmpty from 'lodash/isEmpty';
interface EmergencyContactProps {
    emergencyContactInfo: EmergencyContanctInfo
}

const EmergencyContactBlock = ({emergencyContactInfo}: EmergencyContactProps) => {
    const { t } = useTranslation();
    if (isEmpty(emergencyContactInfo)) {
        return <></>
    }
    return (
        <PersonalInfoBlocks infoBlock={{
            title: t('myAccount.emergencyContact'),
            icon: 'fas fa-user',
            iconTitle: t('imgNicons.userCircleIcon'),
            items: [
                {
                    label: t('formLabel.firstName')+':',
                    icon: 'fas fa-user',
                    iconLabel: t('imgNicons.userIcon'),
                    value: emergencyContactInfo.firstName
                },
                {
                    iconLabel: t('imgNicons.userIcon'),
                    icon: 'fas fa-user',
                    label: t('formLabel.lastName')+':',
                    value: emergencyContactInfo.lastName
                },
                {
                    label: t('myAccount.relationship')+':',
                    icon: 'fas fa-people-arrows',                    
                    iconLabel: t('imgNicons.relationshipIcon'),
                    value: emergencyContactInfo.relation,
                    svg: PeopleArrowsSvg
                },
                {
                    label: t('myAccount.address1')+':',
                    icon: 'fas fa-map-marker-alt',
                    iconLabel: t('imgNicons.addressIcon'),
                    value: emergencyContactInfo.address1
                },
                {
                    label: t('myAccount.address2')+':',
                    icon: 'fas fa-map-marker-alt',
                    iconLabel: t('imgNicons.addressIcon'),
                    value: emergencyContactInfo.address2
                },
                {
                    label: t('myAccount.city')+':',
                    icon: 'fas fa-city',
                    iconLabel: t('imgNicons.cityIcon'),
                    value: emergencyContactInfo.city
                },
                {
                    label: t('myAccount.state')+':',
                    icon: 'fas fa-map-marked-alt',
                    iconLabel: t('imgNicons.flagUSAIcon'),
                    value: emergencyContactInfo.state
                },
                {
                    label: t('myAccount.zip')+':',
                    icon: 'fas fa-map-marked-alt',
                    iconLabel: t('imgNicons.mapMarkerIcon'),
                    value: emergencyContactInfo.zipCode
                },
                {
                    label: t('myAccount.cellPhone')+':',
                    icon: 'fas fa-mobile-alt',
                    iconLabel: t('imgNicons.mobileIcon'),
                    value: emergencyContactInfo.cellPhone
                },
                {
                    label: t('myAccount.workPhone')+':',
                    icon: 'fas fa-phone-rotary',
                    iconLabel: t('imgNicons.rotaryPhoneIcon'),
                    value: emergencyContactInfo.workPhone
                }
            ]
        }} />
    )
}

export default EmergencyContactBlock;