// 3RD PARTY
import { useTranslation } from 'react-i18next';

// Sub Components
import PersonalInfoBlocks from '../personalInfoBlocks';

// Moment Library
import moment from 'moment';
import FORMATS from 'app/ui-core/utils/formats';
import { validateGender } from 'app/ui-core/utils/personalInfo';
import { GENDERS } from 'app/ui-core/utils/constants';

const PersonalInfoBlock = ({personalInfo, maritalStatuses}) => {
    const { t } = useTranslation();

    const listGender = [
        { key: 1, label: t('formLabel.sexF'), value: GENDERS.female },
        { key: 2, label: t('formLabel.sexM'), value: GENDERS.male },
        { key: 3, label: t('formLabel.sexD'), value: GENDERS.unknown },
    ];

    const validateMaritalStatus = (maritalStatusUser: string) => {
        if(!maritalStatuses) return '';

        const englishData: string[] = maritalStatuses['en']

        if( t('register.lenguage') === 'en' ) return maritalStatusUser;

        const index = englishData?.findIndex(( value ) => ( value === maritalStatusUser ));

        return maritalStatuses['es']?.[index] ?? ''

    }

    return (
        <PersonalInfoBlocks infoBlock={{
            title: t('myAccount.personalDetails'),
            icon: 'fas fa-user-circle',
            iconTitle: t('imgNicons.userCircleIcon'),
            items: [
                {
                    label: t('formLabel.firstName')+':',
                    icon: 'fas fa-user',
                    iconLabel: t('imgNicons.userIcon'),
                    value: personalInfo?.firstName
                },
                {
                    label: t('formLabel.lastName')+':',
                    icon: 'fas fa-user',
                    iconLabel: t('imgNicons.userIcon'),                    
                    value: personalInfo?.lastName
                },
                {
                    label: t('formLabel.birthdate')+':',
                    icon: 'fas fa-birthday-cake',
                    iconLabel: t('imgNicons.birthdayCakeIcon'),
                    value: moment(personalInfo?.dateOfBirth).format(FORMATS.date)
                },
                {
                    label: t('myAccount.sexAssigned')+':',
                    icon: 'fas fa-transgender',
                    iconLabel: t('imgNicons.femaleIcon'),
                    value: validateGender(personalInfo?.sex, listGender)?.label ?? '' 
                },
                {
                    label: t('myAccount.maritalStatus')+':',
                    icon: 'fas fa-rings-wedding',
                    iconLabel: t('imgNicons.femaleIcon'),
                    value: validateMaritalStatus(personalInfo?.maritalStatus)
                },                                
                {
                    label: t('myAccount.email')+':',
                    icon: 'fas fa-envelope',
                    iconLabel: t('imgNicons.email'),
                    value: personalInfo?.email
                },
                {
                    label: t('myAccount.homePhone')+':',
                    icon: 'fas fa-phone-rotary',
                    iconLabel: t('imgNicons.rotaryPhoneIcon'),
                    value: personalInfo?.homePhone
                },                
                {
                    label: t('myAccount.mobilePhone')+':',
                    icon: 'fas fa-mobile-alt',
                    iconLabel: t('imgNicons.email'),
                    value: personalInfo?.mobile
                },
                {
                    label: t('myAccount.address')+':',
                    icon: 'fas fa-map-marker-alt',
                    iconLabel: t('imgNicons.market'),
                    value: personalInfo?.address1
                },
                {
                    label: t('myAccount.zip')+':',
                    icon: 'fas fa-map-marked-alt',
                    iconLabel: t('imgNicons.market'),
                    value: personalInfo?.zipCode
                },
                {
                    label: t('myAccount.city')+':',
                    icon: 'fas fa-city',
                    iconLabel: t('imgNicons.cityIcon'),
                    value: personalInfo?.city
                },
                {
                    label: t('myAccount.state')+':',
                    icon: 'fas fa-flag-usa',
                    iconLabel: t('imgNicons.flagUSAIcon'),
                    value: personalInfo?.state
                },
            ]
        }} />
    )
}

export default PersonalInfoBlock;