import { KeraltyText, Image } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row, Column } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import circleExclamation from '../../assets/icons/circle-exclamation.svg';

import { IInsurancePaymentResponse } from 'domain/entities/paymentInfo';
import useIsMobile from 'app/hooks/useIsMobile';

const KeraltyTextButtom = styled(KeraltyText)`
  background: #3ca70d !important;
  line-height: 14px;
  border-radius: 25px;
  margin-top: 27px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
  }
`;

const KeralTextButtom = styled(KeraltyText)`
  background: #ffffff !important;
  line-height: 12px;
  border-color: #3ca70d;
  border-radius: 25px;
  border: 2px solid #0f880d;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  color: #3ca70d;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
  }
`;

const KeralTextButt = styled(KeraltyText)`
  background: #ffffff !important;
  line-height: 14px;
  border-color: #3ca70d;
  border-radius: 25px;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  color: #065394;
  align-self: center !important;
  padding: 13px;
  text-align: center;
  font-weight: 600;
  text-decoration: underline #3ca70d;
  font-size: 14px;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline #055293;
  }
`;

const IconExclamation = styled(Image)`
  width: 34px;
  margin-top: 40px;
  cursor: initial !important;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 24px;
    margin-top: 5px;
  }
`;

const Link = styled.span`
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
`;

interface InsuranceNoValidatedProps {
  close: any;
  triggerPatientSupport: () => Promise<void>;
  externalAction?: () => void;
}

const ModalInsuranceNotValid = ({
  close,
  triggerPatientSupport,
  externalAction
}: InsuranceNoValidatedProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <KeraltyModal size="extraLongEighty" textalign="center">
      <div
        style={{
          marginRight: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconExclamation src={circleExclamation} alt="" />
        <Row mt={isMobile ? 1 : 2.3} style={{ width: isMobile ? '276px' : '336px' }}>
          <KeraltyText
            type="title"
            fontWeight="600"
            fontSize="20px"
            style={{ lineHeight: '2.25rem' }}
          >
            {t('getCare.ChatWithDoc.insuranceTitleNoCoverage')}
          </KeraltyText>
        </Row>
        <Row
          noMargin
          style={{
            width: isMobile ? '255px' : '320px',
            marginTop: isMobile ? '-10px' : '10px',
            paddingBottom: '3%',
          }}
        >
          <KeraltyText type={'info'} color={'#5B5C5B'} fontSize="16px">
            {t('getCare.ChatWithDoc.insuranceTextNoCoverage')}
          </KeraltyText>
        </Row>
        <div style={{ display: 'grid', placeItems: 'center', width: isMobile ? '' : '100%' }}>
          <KeraltyTextButtom
            link
            to="/my-account/my-insurance"
            type="label"
            undecorated
            style={{ width: isMobile ? '200px' : '40%', fontWeight: '600' }}
            onClick={() => {
              close();
              externalAction && externalAction();
            }}
          >
            {t('getCare.ChatWithDoc.insuranceBtnGoTo')}
          </KeraltyTextButtom>
        </div>
        <Row noMargin style={{ marginTop: isMobile ? '43px' : '88px', width: '253px' }}>
          <KeraltyText type={'info'} color={!isMobile ? '#065394' : '#055293;'} fontSize="16px">
            {t('getCare.ChatWithDoc.textSupport')}
            <Link onClick={() => triggerPatientSupport()}>
              {t('getCare.ChatWithDoc.linkSupport')}
            </Link>
          </KeraltyText>
        </Row>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            width: '100%',
            paddingBottom: '5%',
            marginTop: isMobile ? '-5px' : '',
          }}
        >
          <KeralTextButt onClick={close} type="label">
            {t('getCare.ChatWithDoc.goBack')}
          </KeralTextButt>
        </div>
      </div>
    </KeraltyModal>
  );
};


export {
  ModalInsuranceNotValid,
};
