import styled from 'styled-components';

export const ContarinerFLoatingButton = styled.div<{ stateBurger: boolean }>`
  width: 90px;
  height: ${({ stateBurger }) => (stateBurger ? 'min-content' : '90px')};
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.75);
  border-radius: 60px;
  background-color: white;
  position: absolute;
  z-index: 4;
  right: 20px;
  bottom: 25px;
  display: flex;
  justify-content: ${({ stateBurger }) => (stateBurger ? 'space-between' : 'center')};
  align-items: center;
  align-content: center;
  flex-direction: column-reverse;
  padding: ${({ stateBurger }) => (stateBurger ? '15px 0px' : '0px')};
  @media (max-width: 900px) {
    right: 30px;
  }

  @media (max-width: 600px) {
    right: 10px;
  }
  /*   @media (max-width: 500px) {
    display: ${({ stateBurger }) => (!stateBurger ? '' : 'none')};
  } */
  // padding: px;
`;
export const ImageContainer = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 30px;
`;
export const ComponentChat = styled.div`
  z-index: 7;
  position: relative;
  margin-right: 100px;
  @media (max-width: 900px) {
    right: 110px;
    width: 100%;
  }
  @media (max-width: 500px) {
    right: 0px;
    margin-right: 100px;
    width: 100%;
  }
`;
