import styled from "styled-components";
import { KeraltyIcon } from ".";

interface KeraltyCheckboxProps {
	className?: string;
	checked: boolean;
	onChange: (checked: boolean) => void;
	content?: any;
	centered?: boolean;
	noMargin?: boolean;
	autoHeight?: boolean;
}

const CheckboxStructure = ({
	className, checked, content, onChange, centered, noMargin, autoHeight
}: KeraltyCheckboxProps) => (
	<div className={className}>
		<KeraltyIcon
			key={`${checked ? '' : 'not-'}checked`}
			iconClass={checked ? "fas fa-check-square" : "far fa-square"}
			size={'medium'}
			color={checked ? 'accent' : 'dark'}
			onClick={() => onChange(!checked)} />
		<span className={'content'}>
			{content}
		</span>
	</div>
);

const KeraltyCheckbox = styled(CheckboxStructure) <KeraltyCheckboxProps>`
	${({ autoHeight }) => autoHeight ? '' : 'min-height: 3.75rem;'}
	width: 100%;
	display: flex;
	align-items: ${({ centered }) => centered ? 'center' : 'start'};
	margin-bottom: ${({ noMargin }) => noMargin ? '0px' : '1.2rem'};
	justify-content: start;
	span {
		min-width: 3rem;
		width: 3rem;
		max-width: calc(100% - 5rem);
	}
	.content {
		width: calc(100% - 3rem);
		padding: 0.2rem 0 0 .2rem;
	}
`;

export default KeraltyCheckbox;
