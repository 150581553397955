import { useTranslation } from 'react-i18next';

import moment from 'moment';

//import { Row, Column, Container, Loader, SpaceWrapper } from '../../../../components/templates';
import { KeraltyIcon, KeraltyText, KeraltyButton } from '../../../../components/atoms';
import Card from '../../../../components/templates/card';
import PrivateHttpClient from '../../../../../infrastructure/keraltyApi/privateHttpClient';
import i18n from 'i18next';
import { KeraltyFilterDate } from '../../../../components/molecules';
import styled from '../../../../ui-core/styled-components';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';
import { capitalize } from 'app/ui-core/utils/helpers/format';
import { EcwRepository } from 'infrastructure/keraltyApi/repositories/ecw';
import { useAppSelector, useAppDispatch } from 'adapter/hooks';
import { idEcw } from 'adapter/user/userSlice';
import { useCallback, useEffect, useState } from 'react';
import { openBookAppointment } from 'adapter/chat/chatSlice';
import type { AppointmentsInfo } from '../../../../../domain/entities/appointmentsInfo';

import IconModal3 from '../../../../assets/images/cancelAppoiment.svg';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import EmptyImmunizations from '../../../../assets/icons/EmptyupcommingAppoinments.svg';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useHistory } from 'react-router-dom';
import NoResult from '../../../../assets/icons/noResult.svg';
import { KeraltyModal } from 'app/components/molecules';
import FORMATS from 'app/ui-core/utils/formats';
import { Row, Column, Container, Loader, SpaceWrapper } from 'app/components/templates';
import { VALID_TYPES } from 'app/ui-core/utils/appointments';
import ValidationsRepository from 'infrastructure/keraltyApi/repositories/validations.repositories';
import useAppointment from 'app/hooks/useAppointment';
import useAuth from 'app/hooks/useAuth';
import theme from 'app/ui-core/utils/theme';

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 2vh;
    padding-inline: 10px;
  }
`;
const TitleContiner = styled(Column)`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`;


const LeftColumn = styled(Column)`
  justify-content: flex-end;
  padding: 0rem;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    justify-content: center;
  }
`;

const RightColumn = styled(Column)`
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    justify-content: center;
  }
`;

const StyledCard = styled(Card)`
  flex-wrap: wrap;
  width: calc(100% - 9rem);

  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: calc(100% - 3.6rem);
  }
`;

const GoToVirtualAppoinments = styled(Column)`
	display: block !important;
  background: #3CA70D;
	border-radius: 29px;
	color: #FFFFFF;
	padding-inline: 25px;
  margin-block: 15px;
  margin-bottom: 0px;
	height: 35px;
	text-align: center !important;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	line-height: 35px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	};
	@media (max-width: 433px) {
	};
	@media (max-width: 803px){
	};
`;

const ButtonCancelAppoinments = styled.a`
	display: block !important;
  color: #3CA70D;
  text-decoration: underline #3CA70D;
  text-underline-offset: 2px;
  padding: 0px;
  margin-block: 15px;
  margin-top: 3%, 0%;
	text-align: center !important;
	font-weight: 600;
	font-size: 16px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	};
	@media (max-width: 433px) {
	};
	@media (max-width: 803px){
	};
`;

const Modal = styled(Column)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  width: 25%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 75%;
    margin-top: 60%;
	}
`;

const KeraltyButtonFilterContainer = styled(Column)`
  margin-top: 0.7rem;
  justify-content: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100px;
  span {
    margin: 0 1rem;
  }

`;

const ContentDiv = styled.div`

  align-items: center;
  background: #3CA70D;
  border-radius: 29px;
  height:30px;
  @media (max-width: 803px){
    align-items: center;
    background: #3CA70D;
    border-radius: 29px;
    height:30px;
  };
`;

interface IconWithLabelProps {
  iconClass: string;
  iconColor?: keyof (typeof theme.colors);
  iconKey: string;
  label?: string;
  labelColor?: keyof (typeof theme.colors);
  textColor?: keyof (typeof theme.colors);
  labelTitle?: string;
  noMargin?: boolean;
  text?: string;
  isLink?: boolean;
  onClick?: () => void
}

const IconWithLabel = ({
  iconClass,
  iconColor = 'primary',
  iconKey,
  label = '',
  labelColor = 'primary',
  textColor = 'dark',
  labelTitle = '',
  noMargin = false,
  text = '',
  isLink = false,
  onClick
}: IconWithLabelProps) => {
  const { t } = useTranslation();

  return (
    <Row noMargin={noMargin}>
      <KeraltyIcon color={iconColor} iconClass={iconClass} mx title={t(iconKey)} />
      <KeraltyText type="label" color={labelColor} bold>
        {t(labelTitle)}
      </KeraltyText>
      <SpaceWrapper mr={label ? 1 : 0}>
        <KeraltyText type="label" color={labelColor} bold>
          {t(label)}
          {label && ':'}
        </KeraltyText>
      </SpaceWrapper>
      <KeraltyText type="label" color={textColor} onClick={onClick} style={{ fontWeight: 300, textDecoration: isLink ? 'underline' : 'none' }}>
        {text}
      </KeraltyText>
    </Row>
  );
};

const UpcomingBewell = (props) => {

  const { titleFather } = props;
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { replace } = useHistory();
  const isMobile = useIsMobile();
  const patientId = useAppSelector(idEcw);
  const { t } = useTranslation();

  const [upcomingAppointmentsData, setUpcomingAppointments] = useState<AppointmentsInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(0);
  const [cleanInput, setCleanInput] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ageCancelAppointments, setCancelAppointments] = useState(false);
  const [reShoudleTime, setReShoudleTime] = useState(false);
  const [ageTimeCancelAppointments, setTimeCancelAppointments] = useState(false);
  const [ageSuccessAppointments, setSuccessAppointments] = useState(false);
  const [ageErrorAppointments, setErrorAppointments] = useState(false);
  const [ageErrorOcurredAppointments, setErrorOcurredAppointments] = useState(false);

  const {
    getNewDate,
    isCurrentlyAppointmentTime,
	isCurrentlyAppointmentTimeByTimeZone,
    calculateTimeDifference,
	calculateTimeDifferenceByTimeZone,
    calculateServerTimeDifference,
    setAppointmentOptions,
    TIME_TO_VALIDATE,
    ACCEPTED_STATUS,
	getTimeZoneByPrefix,
	getDate
  } = useAppointment();

  const title = t('getCare.upcomingAppointments')

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ageAppoinPlus15, setAppoinPlus15] = useState<string>('');
  const [itemSelected, setItemSelected] = useState<any>();
  const [ageHourAppoin, setHourAppoin] = useState<string>('');
  const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});

  const [appointmentsToShow, setAppointmentsToShow] = useState<AppointmentsInfo[]>([])
  const [showLinkErrorAlert, setShowLinkErrorAlert] = useState<boolean>(false)

  let appointmentsSetInterval: NodeJS.Timer;

  /* Logic end */

  const hangleGoToURL = async (url: string, item: any) => {
    setIsLoading(true);
    let timeLeft = 0;

	const currentDateByTimeZone = getDate(item.timeZone);

    const time = async (item) => {
      const res = calculateServerTimeDifference(item?.startTime, item?.date, currentDateByTimeZone)
      timeLeft = res
      setIsLoading(false);
    };
    await time(item)

    if (timeLeft <= TIME_TO_VALIDATE && timeLeft >= -TIME_TO_VALIDATE) {

      if (!url) {
        setIsLoading(true);
        try {
          const response = await EcwRepository.getUpcomingAppointmentsBeWell({ patientId }, user.state)
          setIsLoading(false);

          const appointment = response.find(res => res.encounterId === item.encounterId);

          if (appointment?.url) {
            window?.open(appointment?.url, '_blank')?.focus();
          } else {
            setShowLinkErrorAlert(true);
          }

          setUpcomingAppointments(response);

        } catch (error) {
          setIsLoading(false);
          setShowLinkErrorAlert(true);
        }

        return;
      }

      window?.open(url, '_blank')?.focus();

    } else setReShoudleTime(true)
  };

  useEffect(() => {

    if (patientId) {
      EcwRepository.getUpcomingAppointmentsBeWell({ patientId }, user.state)
        .then((result) => {
          setUpcomingAppointments(result);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }

  }, [patientId]);

  const onChangeDate = useCallback((type, data) => {
    setIsFiltered(true)
    type && setDataObject(data);
    type && setVisible(true);
    setIsModalVisible(false);
  }, []);

  useEffect(() => {
    clearInterval(appointmentsSetInterval)

    if (upcomingAppointmentsData.length === 0) return;

    appointmentsSetInterval = setInterval(() => {

      const result = upcomingAppointmentsData.filter((appointment) => {
        if (!dataObject.to || !dataObject.from) return true;

        const currentDate = moment(appointment.date, 'YYYY-MM-DD');
        return currentDate.isBetween(moment(dataObject.from, 'MM/DD/YYYY'), moment(dataObject.to, 'MM/DD/YYYY'), undefined, '[]');
      });

      const result2 = result.map((item, i) => {
        const startTime = item.date;
        const date = item.startTime;
        const utcDate = `${startTime}T${date}:00Z`
        const newDate: Date = new Date(utcDate);

        const customDate = getNewDate({ date: utcDate, isNotUTCDate: true });

		const timeZone = getTimeZoneByPrefix(item.zonedTime);

        // const time = calculateTimeDifference(customDate);
        const time = calculateTimeDifferenceByTimeZone(customDate, timeZone);
        // const { isDay, dateHasPassed } = isCurrentlyAppointmentTime(customDate);
        const { isDay, dateHasPassed } = isCurrentlyAppointmentTimeByTimeZone(customDate, timeZone);


        return {
          ...item,
          newDate,
          customDate,
		  timeZone,
          ...setAppointmentOptions({
            dateHasPassed,
            isDay,
            time,
            visitType: item.visitType,
            status: item.status
          }),
        }
      })

      setAppointmentsToShow(result2)

    }, 1000);

    return () => clearInterval(appointmentsSetInterval)

  }, [dataObject, upcomingAppointmentsData])

  interface Appointment {
    date: string;
    // Otras propiedades...
  }

  const appointmentsToShow2 = (dataObject: any, upcomingAppointmentsData: Appointment[]): Appointment[] => {
    if (!dataObject.to || !dataObject.from) return upcomingAppointmentsData;

    const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
    const toDate = moment(dataObject.to, 'MM/DD/YYYY');

    const filteredAppointments = upcomingAppointmentsData.filter((appointment) => {
      const currentDate = moment(appointment.date, 'YYYY-MM-DD');
      return currentDate.isBetween(fromDate, toDate, undefined, '[]');
    });

    return filteredAppointments;
  };



  if (loading) {
    return <Loader />;
  }

  const cancelAppointment = (idAppointment) => {
    setCancelAppointments(false);

    if (ageAppoinPlus15 > ageHourAppoin) {
      setTimeCancelAppointments(true);
      setAppoinPlus15('');
      setHourAppoin('');
    } else {
      const body = {
        "identifier": [
          {
            "type": "APPOINTMENT_ID",
            "value": idAppointment.idAppointment,
          }
        ],
        "communication": {
          "language": i18n.language
        }
      };
      const headers = {
        "Content-Type": "application/json",
      };
      return PrivateHttpClient.post(`appointment/CancelAppointment`, body, { headers: headers })
        .then((res) => {
          setModal(0)
          if (res) {
            setSuccessAppointments(true);
          }
        })
        .catch((error) => {
          if (error.code !== '500') {
            setErrorOcurredAppointments(true);
          } else {
            setModal(0)
            setErrorAppointments(true)
          }
        });
    }
  }

  const ClearDate = () => {
    var p1 = { from: "", to: "" };
    var Init = moment().format();

    setDataObject(p1)
    setVisible(false)
    setIsFiltered(false)
  }

  const FilterVisible = () => {
    const format = (date) => { return date?.split('/').reverse().join('/').slice(2).split('/').reverse().join('/') }
    return (
      <>
        {
          <Row>
            <Row style={{ justifyContent: 'space-between', marginRight: 20, marginLeft: 20 }} noMargin>
              <ContentDiv style={{}} onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
                <div style={{ padding: 5, textAlign: 'left', marginLeft: '15px' }} >
                  <KeraltyText style={{ fontSize: 12, color: '#FFFFFF' }}>{format(dataObject.from)}</KeraltyText>
                  <KeraltyText style={{ fontSize: 12, color: '#FFFFFF' }}> - </KeraltyText>
                  <KeraltyText style={{ fontSize: 12, color: '#FFFFFF', padding: 5 }}>{format(dataObject.to)}</KeraltyText>
                  <KeraltyText style={{ fontSize: 14, paddingRight: 10, paddingLeft: 5, color: '#FFFFFF' }}>X</KeraltyText>
                </div>
              </ContentDiv>

              <KeraltyText style={{ fontSize: 16, color: '#999999', paddingTop: 5, marginRight: isMobile ? '0rem' : '5rem' }} >{appointmentsToShow.length} {t('myHealth.resul')}</KeraltyText>

            </Row>
          </Row>
        }
      </>
    )
  }

  const ModalCancelAppointments = (idAppointment) => {//EN BTN YES, TENIA UN BTN SUBMIT EN DISPLAY NONE, VALIDAR SI ES NECESARIO
    return (
      <KeraltyModal size="medialong" textalign="center">
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="small" rounded />
        </Row>
        <Row style={{ margin: '13% 0%' }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ color: '#055293' }}>
            {t('getCare.televisit.modalTitleTelevisit')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{}}>
          <KeraltyButton
            onClick={() => (setModal(0), setCancelAppointments(!ageCancelAppointments))}
            style={{ backgroundColor: 'white', color: '#077BBF', borderColor: '#077BBF', marginRight: '10%', width: '35%', fontSize: 12, padding: '0', }}
          >
            {t('getCare.televisit.btnNoModalTitleTelevisit')}
          </KeraltyButton>

          <KeraltyButton
            onClick={() => cancelAppointment(idAppointment)}
            style={{ width: '35%', fontSize: 12, padding: '0', }}
          >
            {t('getCare.televisit.btnYesModalTitleTelevisit')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const ModalReShoudleTime = () => {
    return (
      <KeraltyModal size="medialong" textalign="center" >
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '2%' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="small" rounded />
        </Row>
        <Row style={{ margin: '13% 0%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ color: '#0069A7' }}>
            {t('getCare.televisit.sorry')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{}}>
          <KeraltyButton
            style={{ width: '45%', fontSize: 12, padding: '0', }}
            onClick={useCallback(() => {
              setReShoudleTime(!reShoudleTime)
              dispatch(openBookAppointment());
              replace({ search: '' });
            }, [dispatch, replace])}
          >
            {t('getCare.televisit.btnNoModalTitleTimeTelevisitOne')}
          </KeraltyButton>

          <KeraltyButton
            onClick={() => setReShoudleTime(!reShoudleTime)}
            style={{ width: '45%', fontSize: 12, backgroundColor: 'white', color: '#3CA70D', borderColor: '#3CA70D', marginLeft: '10%' }}
          >
            {t('getCare.televisit.btnYesModalTitleTimeTelevisitOne')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };
  const ModalTimeCancelAppointments = () => {
    return (
      <KeraltyModal size="medialong" textalign="center" >
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '2%' }}>
          <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="small" rounded />
        </Row>
        <Row style={{ margin: '13% 0%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ color: '#0069A7' }}>
            {t('getCare.televisit.modalTitleTimeTelevisitOne')}  <br /> {t('getCare.televisit.modalTitleTimeTelevisitTwo')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{}}>
          <KeraltyButton
            style={{ width: '45%', fontSize: 12, padding: '0', }}
            onClick={useCallback(() => {
              setTimeCancelAppointments(!ageTimeCancelAppointments)
              dispatch(openBookAppointment());
              replace({ search: '' });
            }, [dispatch, replace])}
          >
            {t('getCare.televisit.btnNoModalTitleTimeTelevisitOne')}
          </KeraltyButton>

          <KeraltyButton
            onClick={() => setTimeCancelAppointments(!ageTimeCancelAppointments)}
            style={{ width: '45%', fontSize: 12, backgroundColor: 'white', color: '#3CA70D', borderColor: '#3CA70D', marginLeft: '10%' }}
          >
            {t('getCare.televisit.btnYesModalTitleTimeTelevisitOne')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const ModalSuccessAppointments = () => {
    return (
      <KeraltyModal size="long" textalign="center" >
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '2%' }}>
          <KeraltyIcon iconClass="fas fa-check" color="accent" size="small" rounded />
        </Row>
        <Row style={{ margin: '13% 0%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} color={'primary'}>
            {t('getCare.televisit.modalTitleSuccessTelevisit')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{}}>
          <KeraltyButton
            onClick={() => (window.location.reload(), setSuccessAppointments(false))}
            style={{ paddingLeft: '9%', paddingRight: '9%', fontSize: 13 }}
          >
            {t('getCare.televisit.btnYesModalTitleSuccessTelevisit')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const ModalErrorAppointments = () => {//EN BTN, TENIA UN BTN SUBMIT EN DISPLAY NONE, VALIDAR SI ES NECESARIO
    return (
      <KeraltyModal size="medialong" textalign="center" >
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', margin: '4% 0%' }}>
          <div>
            <img style={{ background: '#3CA70D', borderRadius: '50%' }} src={IconModal3} alt="" />
          </div>
        </Row>
        <Row style={{ margin: '8% 0%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} color={'primary'}>
            {t('getCare.televisit.modalTitleErrorTelevisit')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{ margin: '5% 0%' }}>
          <KeraltyButton
            onClick={() => setErrorAppointments(!ageErrorAppointments)}
            style={{ paddingLeft: '9%', paddingRight: '9%', fontSize: 13 }}
          >
            {t('getCare.televisit.btnYesModalTitleSuccessTelevisit')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const ModalErrorOcurredAppointments = () => {
    return (
      <KeraltyModal size="extraLong" textalign="center" >
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '2%' }}>
          <span style={{ backgroundColor: '#3CA70D', padding: '1%', borderRadius: 28 }}>
            <KeraltyIcon iconClass="fas fa-exclamation" color="accent" size="small" rounded />
          </span>

        </Row>
        <Row style={{ marginTop: '7%', justifyContent: 'center', }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ color: '#002D57' }}>
            {t('getCare.televisit.modalTitleErrorOcurredTelevisit')}
          </KeraltyText>
        </Row>

        <Row style={{ marginTop: '5%', marginBottom: '10%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ fontWeight: 'normal', color: '#212121' }}>
            {t('getCare.televisit.modalSubTitleErrorOcurredTelevisit')}
          </KeraltyText>
        </Row>

        <div className="unblock-account" style={{}}>
          <KeraltyButton
            onClick={() => setErrorOcurredAppointments(!ageErrorOcurredAppointments)}
          >
            {t('getCare.televisit.btnYesModalTitleSuccessTelevisit')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const ModalLinkError = () => {
    return (
      <KeraltyModal size="extraLong" textalign="center" >
        <Row noMargin alingItemsCenter style={{ justifyContent: 'center', marginTop: '2%' }}>
          <span style={{ backgroundColor: '#E7A304', padding: '1%', borderRadius: 28 }}>
            <KeraltyIcon iconClass="fas fa-exclamation" color="secundaryAlert" size="small" rounded />
          </span>
        </Row>

        <Row style={{ marginTop: '5%', marginBottom: '10%', justifyContent: 'center' }}>
          <KeraltyText fontSize={'20px'} type={'label'} style={{ fontWeight: 'normal', color: '#212121' }}>
            {t('getCare.televisit.linkError')}
          </KeraltyText>
        </Row>

        <div className="unblock-account">
          <KeraltyButton
            onClick={() => setShowLinkErrorAlert(false)}
          >
            {t('getCare.televisit.btnYesModalTitleSuccessTelevisit')}
          </KeraltyButton>
        </div>
      </KeraltyModal>
    );
  };

  const handleCancelAppoinments = (id: number, item: any) => {
    setItemSelected(item);
    setModal(id);
    setCancelAppointments(!ageCancelAppointments);
  }

  return (
    <RightContent fullHeight>

      {isLoading && <Loader />}
      {showLinkErrorAlert && <ModalLinkError />}

      {title && (
        <Row>
          <TitleContiner lg={12}>
            <KeraltyText type="title" align={isMobile ? "left" : "center"} style={{ paddingLeft: isMobile ? 10 : '' }}>{title}</KeraltyText>
            {isMobile && upcomingAppointmentsData.length > 0 &&
              <KeraltyButtonFilterContainer onClick={() => setIsModalVisible(true)} style={{ display: 'flex', marginRight: 0 }}>
                <KeraltyIcon iconClass="fas fa-filter" color="accent" />
                <KeraltyText type="label" color={'accent'}>
                  {t('KeraltyFilter.title')}
                </KeraltyText>
              </KeraltyButtonFilterContainer>}
          </TitleContiner>

        </Row>
      )}
      {
        visible && isMobile
        && (
          <Row>
            <FilterVisible />
          </Row>
        )
      }


      <Row>

	  	{
			<Row style={{ paddingLeft: '4%', margin: 0 }}>
			{
				upcomingAppointmentsData.length > 0 && (
				<KeraltyFilterDate
					onSuccess={onChangeDate}
					onCancel={() => setIsModalVisible(false)}
					showModalFlag={isModalVisible}
					cleanInput={cleanInput}
				/>
				)
			}
			{visible && !isMobile ? <Row>
				<FilterVisible />
			</Row>
				: ''}
			</Row>
		}

        {appointmentsToShow.length < 1 ? (!isFiltered ?
          <Container centered={'xy'}>
            <NoSearchResult image={EmptyImmunizations} subTitle={t('myHealth.noUpcoming')} />
          </Container> : <Container centered={'xy'}>
            <NoSearchResult image={NoResult} title={t('myHealth.titleEmpty')} subTitle={t('myHealth.textEmpty')} />
          </Container>
        ) : (
          appointmentsToShow.map((item: any, index) => {
            const getNames = capitalize(item?.ufName != null ? item?.ufName : '') + ' ' + capitalize(item?.ulName != null ? item?.ulName : '')
            const idItem = item.encounterId;
            const Wrapper = index % 2 ? RightColumn : LeftColumn;


            return (
              <Wrapper lg={6} md={6} sm={12} noPadding>
                <StyledCard>
                  <IconWithLabel
                    iconColor={'accent'}
                    iconClass={'fas fa-heart'}
                    iconKey={'imgNicons.heartIcon'}
                    labelTitle={capitalize(item.visitType)}
                    labelColor={'primary'}
                  />

                  <IconWithLabel
                    iconClass={'fas fa-calendar-day'}
                    iconKey={'imgNicons.calendarDayIcon'}
                    label={'myHealth.date'}
                    noMargin
                    // text={moment(item.newDate).format('MMMM DD YYYY')}
                    text={item.customDate?.date ? moment(item.customDate.date).format('dddd, MMMM D YYYY') : ''}
                  />

                  <IconWithLabel
                    iconClass={'fas fa-clock'}
                    iconKey={'imgNicons.clock'}
                    label={'getCare.time'}
                    // text={moment(item.newDate, 'hh:mm:ss').format('hh:mm A')}
                    text={`${item.customDate?.time} ` + (item.zonedTime ? `(${ item.zonedTime  })` : '')}
                  />

                  <IconWithLabel
                    iconClass="fas fa-map-marker-alt"
                    iconKey="imgNicons.locationIcon"
                    noMargin
                    label={'getCare.location'}
                    text={item.name}
                  />

                  <IconWithLabel
                    iconClass={'fas fa-user-md'}
                    iconKey={'imgNicons.doctor'}
                    label={'getCare.provider'}
                    text={getNames}
                  />

                  <IconWithLabel
                    iconClass={'fa fa-heart-rate'}
                    iconKey={'imgNicons.hospital'}
                    noMargin
                    label={'getCare.status'}
                    text={item.status === ACCEPTED_STATUS ? t('getCare.status2') : t('getCare.status3')}
                  />

                  {item.enabledOption && item.general_text &&
                    <span style={{ color: '#055293', margin: '6%', marginBottom: 0, fontWeight: 600, fontSize: isMobile ? 14 : 16, display: 'flex', alignItems: 'center', textAlign: 'center', letterSpacing: '0.03em' }}>
                      {t('getCare.televisit.titleAvailableAppointmentsOne')}<br />{t('getCare.televisit.titleAvailableAppointmentsTwo')}
                    </span>
                  }

                  {item.enabledOption && item.go_to_appointment &&
                    <Row style={{ justifyContent: 'center', marginTop: '5%', marginBottom: 0, }}>
                      <GoToVirtualAppoinments onClick={() => hangleGoToURL(item.url, item)}>{t('getCare.goToVirtualAppointment')}</GoToVirtualAppoinments>
                    </Row>
                  }

                  {item.enabledOption && item.disable_cancelButton &&
                    <span style={{ color: '#8B8B8B', fontWeight: 700, fontSize: 16, marginBottom: '5%', marginTop: 20 }}>
                      {t('getCare.televisit.titleCancelAppointments')}
                    </span>}

                  {item.status === ACCEPTED_STATUS && item.cancel_button &&
                    <Row style={{ justifyContent: 'center', marginTop: 20 }}>
                      <ButtonCancelAppoinments onClick={() => handleCancelAppoinments(idItem, item)}>{t('getCare.cancelAppointment')}</ButtonCancelAppoinments>
                    </Row>}
                </StyledCard>
              </Wrapper>
            );
          })
        )
        }

        {ageCancelAppointments ?
          <ModalCancelAppointments idAppointment={modal} />
          : ageTimeCancelAppointments ?
            <ModalTimeCancelAppointments />
            : ageSuccessAppointments ?
              <ModalSuccessAppointments />
              : ageErrorAppointments ?
                <ModalErrorAppointments />
                : ageErrorOcurredAppointments ?
                  <ModalErrorOcurredAppointments />
                  : reShoudleTime ?
                    <ModalReShoudleTime />
                    :
                    null
        }
      </Row>

    </RightContent >
  );
};

export default UpcomingBewell;
