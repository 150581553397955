import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Row } from '../../../templates';
import { KeraltyButton, KeraltyText } from '../../../atoms';
import { RightContent, TitleContiner } from './Anxiety.styled';
import ROUTES from "app/ui-core/utils/routes";
import win from '../../../../assets/icons/win.svg';
import useIsMobile from 'app/hooks/useIsMobile';

const YouMadeIt = () => {
	const { t } = useTranslation();
	const isMobile = useIsMobile();
	const history = useHistory();
	const { MENTAL_HEALTH } = ROUTES;

	return (
		<RightContent fullHeight top='10'>
			<Row style={{ paddingBottom: 0 }}>
				<TitleContiner lg={12}>
					<KeraltyText align={isMobile ? 'left' : 'center'} type="title" fontWeight={isMobile ? 600 : 400}>{t('mentalHealth.understandingYourAnxiety')}</KeraltyText>
				</TitleContiner>
			</Row>
			<Row>
				<div style={{marginLeft: isMobile ? '10%' : '14%', marginRight: '14%', display: 'grid', placeItems: 'center'}}>
					<Row>
						<TitleContiner lg={12}>
							<KeraltyText type="title" fontWeight={600}>{t('mentalHealth.youMadeIt')}</KeraltyText>
						</TitleContiner>
					</Row>
					<section style={{ display: 'grid', placeItems: 'center' }}>
						<img style={{ width: '85px', height: '76px' }} src={win} alt="" />
						<section style={{ fontSize: '16px', textAlign: 'justify', marginLeft: isMobile ? '0%' : '10%', marginRight: isMobile ? '0%' : '10%' }}>
							<p>{t('mentalHealth.winText')}</p>
						</section>
					</section>
				</div>
			</Row>
			<Row style={{ display: 'grid', placeItems: 'center'}}>
				<div style={{ marginTop: 0 }}>
					<KeraltyButton              
						onClick={ () => history.replace(`${MENTAL_HEALTH.anxiety}`) }
						fontWeight={ 600 }
						withShadow
					>
						{t('mentalHealth.goToMenu')}
					</KeraltyButton>
				</div>
				<section style={{ display: 'grid', placeItems: 'center', marginTop: 20 }}>
					<section style={{ fontSize: '15px', textAlign: 'justify', marginLeft: isMobile ? '10%' : '25%', marginRight: isMobile ? '10%' : '25%', color: 'rgba(2, 47, 88, 0.8)', fontWeight: 600 }}>
						<p>{t('mentalHealth.youMadeItOptions')}</p>
					</section>
				</section>
			</Row>
		</RightContent>
	);
};

export default YouMadeIt;
