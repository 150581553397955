const nsConsentTreatment = {
  en: {
    sanitasPatient:
      'I am patient of Sanitas. By signing this form, I give my consent to be treated by the clinical providers of this practice.',
    services: 'I understand treatment and services may include:',
    tests: 'Lab tests',
    exams: 'Routine exams',
    screening:
      'Screening tests (tests that can find an illness early, before a person shows signs of having the disease)',
    diagnostic:
      'Diagnostic tests (tests that shows if a person has a certain illness or health problem)',
    results:
      'No guarantees or promises have been made concerning the results or any treatment or service',
    acknowledge:
      '',
    consent:
      'I have also had the opportunity to ask any questions, and by my signature, I consent and agree to such provisions individually and collectively. A copy may be used in lieu of the original.',
  },
  es: {
    sanitasPatient:
      'Yo soy paciente de Sanitas. Al firmar este documento, doy mi consentimiento para ser tratado por doctores en esta práctica médica.',
    services: 'Entiendo que el tratamiento y los servicios pueden incluir:',
    tests: 'Exámenes de laboratorio',
    exams: 'Exámenes de rutina',
    screening:
      'Exámenes de detección (pruebas que pueden detectar una enfermedad temprana, antes que la persona muestre signos de la enfermedad)',
    diagnostic:
      'Exámenes de diagnóstico (pruebas que identifican si la persona tiene una enfermedad o problema de salud)',
    results:
      'Entiendo que no me han hecho promesas sobre los resultados o sobre cualquier tratamiento o servicio.',
    acknowledge:
      '',
    consent:
      'He tenido además la oportunidad de hacer cualquier pregunta, y mediante mi firma doy la aprobación y estoy de acuerdo con tales disposiciones, individual y colectivamente. Una copia debe ser utilizada en lugar de la original.',
  },
};

export default nsConsentTreatment;
