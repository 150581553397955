// 3RD PARTY
import styled from 'styled-components';

interface ModalProps {
  children: any;
  size?: 'sm' | 'wide' | 'long' | 'extraLong' | 'extraLongEighty' | 'extraSixHundred' | 'medialong' | 'withMargin' | 'media' | 'almostMedia' | 'intermediate';
  scroll?: string;
  onClickContainer?: any;
  textalign?: string;
  style?: React.CSSProperties;
}

const KeraltyModal = ({ children, size = 'sm', scroll = 'auto', onClickContainer, textalign, style  }: ModalProps) => {
  const handlerClick = () => {
    if (onClickContainer) {
      onClickContainer();
    }
  }

  return (
    <ModalContainer onClick={handlerClick} textalign={textalign} >
      <ModalBox size={size} scroll={scroll} style={style}>
        {children}
      </ModalBox>
    </ModalContainer>
  )
};

export default KeraltyModal;

const ModalContainer = styled.div<any>`
  text-align: ${({ textalign }) => textalign ? `${textalign}` : 'left'};
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 17000003;
  background-color: rgba(0, 0, 0,0.5);
`;

const ModalBox = styled.div<any>`
  align-self: center;
  background: WHITE;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 2rem;
  min-height: 500;
  max-height: 90vh;
  overflow: ${({ scroll }) => scroll ? `${scroll} !important` : 'auto'};
  width: ${({ size }) => {
    switch (size) {
      case 'sm':
        return '200px';
      case 'wide':
        return '90%';
      case 'withMargin':
        return '80%';
      case 'media':
        let percen;
        if (window.innerWidth <= 1280) { percen = '87%' }
        else if (window.innerWidth === 1366) { percen = '75%' }
        else { percen = '55%' }
        return percen;
        case 'intermediate':
          let percenmediate;
          if (window.innerWidth <= 1280) { percenmediate = '87%' }
          else if (window.innerWidth === 1366) { percenmediate = '70%' }
          else { percenmediate = '42%' }
          return percenmediate;
        case 'almostMedia':
        let percentage
        if (window.innerWidth <= 1280) { percentage = '75%' }
        else if (window.innerWidth === 1366) { percentage = '45%' }
        else { percentage = '35%' }
        return percentage;
      case 'long':
        return '280px';
      case 'medialong':
        return '300px';
      case 'extraLong':
        return '400px';
      case 'extraLongEighty':
        let Responsive
        if (window.innerWidth >= 320 && window.innerWidth <= 559) { Responsive = '330px' }
        else if (window.innerWidth >= 559) { Responsive = '480px' }
        else { Responsive = '100%' }
        return Responsive;
        case 'extraSixHundred':
        let ResponsiveS
        if (window.innerWidth >= 320 && window.innerWidth <= 559) { ResponsiveS = '330px' }
        else if (window.innerWidth >= 559) { ResponsiveS = '699px' }
        else { ResponsiveS = '100%' }
        return ResponsiveS;
      default:
        return '74%';
    }
  }
  };
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  margin: 10px;
`;
