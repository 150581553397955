
const nsMentalHealth = {
  en: {
    main: 'Mental health',
    about: 'About',
    aboutText: 'More info about our new features and services',
    revisit: 'Revisit Onboarding',
    new: 'About the new mental health module',
    welcome:
      'Welcome to our mental health platform, where we are dedicated to supporting individuals on their mental health journey. Our platform offers a range of features and resources designed to help you improve your mental health and well-being.',
    our: 'Our mental health functionality includes a range of features, including ', 
    our2:'educational resources, grounding exercises, and scheduling tools.',
    our3:'Our educational resources provide information on a range of mental health topics, including stress, anxiety, and depression, and offer practical tips for improving your mental health. Our grounding exercises help you stay grounded in the present moment and our scheduling options make it easier to schedule appointments with our mental health professionals.',
    thank:
      'Thank you for choosing our platform as your partner on your mental health journey. We look forward to supporting you every step of the way',
    appointments: 'Appointments',
    appointmentsText: 'Book a virtual or in-person visit with your behavioral health professional',
    educational: 'Resources',
    lifestyle: 'Lifestyle library',
    lifestyleText: 'These resources cover a range of topics, from mindfulness and meditation to cognitive-behavioral therapy',
    otherResources: 'Other resources',
    pDFsandEbooks: 'PDFs and Ebooks',
    otherResourcesText: 'More information on a specific condition or just want to learn more about mental health in general',
    selfmanagement: 'Understanding my symptoms',
    educationalText: 'A selection of educational information',
    needHelp: 'Need help?',
    needHelpText: 'Access to hotlines and other resources',
    needHelpInfo: 'Here are some features that we offer to help support your mental well-being',
    tools:'Understanding my symptoms',
    toolsText:'Choose from different lengths and themes to find what works best for you.',
    OnBoardTitle:'Welcome to our Mental Health module!',
    OnBoardSubTitle2:'Resources',
    OnBoardSubTitle3:'Grounding exercises',
    OnBoardSubTitle4:'Appointments',
    OnBoardSubTitle5:'',    
    OnBoardText1:'We understand that taking care of your mental health is just as important as taking care of your physical health. Here are some features that we offer to help support your mental well-being.',
    OnBoardText2:'We´ve curated a list of mental health resources, including hotlines accessible directly from the app.',
    OnBoardText3:'Writing down your thoughts and feelings can be a great way to process and reflect on them. Our grounding exercises allows you to jot down your thoughts and track your progress over time.',
    OnBoardText4:'Scheduling an appointment with a behavioral health professional is an important step towards improving your mental well-being. Find and book appointments with a dedicated providers in your area.',
    OnBoardText5:'Please note that the mental health functionality provided in  ',
    OnBoardText6:'our app is not intended to serve as a substitute for professional mental health care.',
    OnBoardText7:'Our app is designed to provide tools and resources to support your mental well-being on an ongoing basis. It is not equipped to handle crisis situations, and ',
    OnBoardText8:'it is not a crisis line.',
    lets:'LET´S GET STARTED',
    skip:'SKIP',
    beWellProgram: 'BeWell Program',
    beWellProgramText: 'Get support from a behavioral health professional available 7 days a week.',
    beWellDefinition: "The BeWell program can help you to reduce anxiety, improve moods, have a greater sense of calm, increase self-esteem, promote stress management and balance life and work.",
    fhsDefinition: "In FHS we offer outpatient mental health services for psychiatric evaluation, psychotherapy, medication management, marriage and family therapy and more.",
    fhs: 'FHS',
    beWell: 'BeWell',
    floridaHealthcareSystem: 'Florida Healthcare System',
    floridaHealthcareSystemText: "Florida Healthcare System's team of professionals are available to meet the mental health needs of all ages.",
    chooseService: 'Not sure which service to choose?',
    areYouSure: 'Not sure which service to choose?',
    modalNeedTitle: 'How can we help?',
    linkInfo: 'For more information visit: ',
    understandingYourAnxiety: 'Understanding your anxiety',
    thinking: 'Thinking',
    thinkingText: 'Select the option below of what you’re currently thinking about',
    thinkingDetails: 'Please tell me more about what happended:',
    actions: 'Actions',
    actionsText: 'How did you manage the situation?',
    feeling: 'Feeling',
    feelingText: 'How did you feel about the situation?',
    physicalSymptoms: 'Physical symptoms',
    physicalSymptomsText: 'Did you feel any of these physicial symptoms? Select as many as you like:',
    endOfEntry: 'End of entry',
    cbtTitle: 'How CBT helps with your anxiety',
    firstCbtP: 'CBT focuses on understanding how your behaviors relate to your emotions and thoughts. When we can understand how our anxiety works, we can start to make changes to feel better.',
    secondCbtP: 'The Cognitive Triangle is the center of CBT which theorizes that <b>the way you think impacts the way you feel and that affects the way you behave.</b> This can work in any direction. The way you feel can affect what you’re thinking and what you do.',
    youMadeIt: 'You made it!',
    winText: 'Struggling with anxiety is not easy but healing is possible. You are fully capable of making a difference in your behaviors, thoughts, and feelings. Just do not give up. As long as you take breath there is hope. We are here for you during your journey toward healing and we encourage you to reach out if you need support.',
    youMadeItOptions: 'We offer a variety of treatment options, from individual therapy to support groups, to meet your unique needs. Additionally, our network of mental health professionals is available to provide you with personalized and effective treatment.',
    disclaimer:'This self-management tool is intended to be used as a guide and support for individuals seeking to improve their mental health and wellbeing. It is not intended to replace professional advice, diagnosis, or treatment. The information provided in this tool is based on general principles and may not be applicable to every individual. The user should consult with a qualified healthcare provider before making any changes to their treatment plan or undertaking any new activities. The user assumes all responsibility and risk for their use of this tool.',
    disclaimerTitle:'Disclaimer',
    close:'Close',
    callSchedule: 'Call to Schedule',
    callScheduleText: 'Click on the call button and our team will be happy to assist you',
    calledLater: 'I want to be called back later',
    calledLaterText: 'Our team will get in touch with you as soon as possible to schedule a convenient appointment time. (1-2 business days)',
    schedule: 'Available Monday thru Friday 8:00 a.m - 7:00 p.m',  
    fhsForm:'FHS Contact Form',
    fhsFormText:'Our team will get in touch with you as soon as possible to schedule a convenient appointment time. (1-2 business days)',
    fhsComment:'Additional comments',
    fhsCommentText:'Feel free to type other date options for your appointment',
    fhsCommentPlaceholder: 'Additional comments (max 500 characters)',
    fhsModalText:'Your information has been submitted. We will get back to you as soon as possible!',
    beWellP:'Be Well Program',
    book:'Book a virtual or in-person visit with a behavioral health professional.', 
    determine:'To determine your eligibility for the program, make an appointment with a dedicated behavioral health professional.',
    stinkingTitle:'Understanding your negative thinking',
    stinkingSub:'Negative thinking',
    stinkingText1:'Let’s think about our thoughts. Identifying and changing unhelpful thinking is one of the main ways to help create lasting change.',
    stinkingText2:'The most important thing to remember when considering our thoughts is our perception. Perception is the act of giving meaning to things we see, hear, taste, smell, and feel.',
    stinkingText3:'When something happens, we perceive the event using our senses and form thoughts based on that perception. That means, thoughts are not always true.',
    stinkingText4:'Swipe right or left to explore different types of negative thinking. Tap on the “Applies” button if you can relate to that certain type of thinking. You can select up to three types. ',
    stinkingText5:'Let’s start checking on your shrinking thoughts! ',
     more:'Find out more',
    applies:'Applies',
      already:'I already chose my types of thinking',
    challenging:'Challenging your negative thinking',
    challengingText1:'When we are able to identify the unhelpful thoughts that are causing anxiety, we can then begin to confront them by asking new questions that change our perspective.',
    challengingText2:'By asking these types of questions, we analyze and challenge our thinking and end up <strong>feeling better.</strong> The more you practice this skill, the better you can get at it. <strong>Let’s give it a try!</strong> ',
    animation1:'What am I reacting to?',
    animation2:'What does this situation mean to me over my whole life?',
    animation3:'What would this situation look like to someone outside of the situation, would they see it the same way? (It helps to think of someone you respect and admire)  ',
    animation4:'Will this situation matter in 1 year, 5 years, 10 years, 50 years?',
    animation5:'Is my reaction in proportion to the actual event?',
    animationDesc:'We offer a variety of treatment options, from individual therapy to support groups, to meet your unique needs. Additionally, our network of mental health professionals is available to provide you with personalized and effective treatment.',
    finishText:'Struggling with negative thinking is not easy but healing is possible. You are fully capable of making a difference in your behaviors, thoughts, and feelings. Just do not give up. As long as you take breath there is hope. We are here for you during your journeytoward healing and we encourage you to reach out if you need support.',
    finishTitle:'You made it!',
    goToMenu:'Finish exercise',
    stinkingCard:'Understanding your negative thinking',
    stinkingCarTex:' Identify and challenge negative thought patterns',
    back : 'BACK',
    start:'start',
    next:'next',
    done:'done',
    anxiety: 'Understanding your anxiety',
    anxietyText: 'Take a step back and observe your anxiety',
    stinking: 'Understanding your negative thinking',
    stinkingText: ' Identify and challenge negative thought patterns',
    download: 'DOWNLOAD',
    noEntryYet: "You don't have any entry yet",
    typeYourAnswer: 'Type your answer here',
    minAnswer:'(min 10 characters - max 500 characters)',
    thinkingError: 'Please select one of the types of thinking to continue with the exercise.',
    thinkingDetailError: "Please write down what you're thinking. Enter at least 10 characters to continue with this exercise.",
    actionDetailError: 'Please write down what actions you took. Enter at least 10 characters to continue with this exercise.',
    submit: 'Submit',
    disclaimerOtherRes:'This library is intended to provide patients/users, access to up-to date health information. The information provided is not intended to replace the advice of a healthcare professional and is not a substitute for medical advice, diagnosis, treatment or telemedicine services. My Sanitas App does not operate or controls the library or its content and will not be responsible for any errors or omissions in the information provided, nor for any consequences that may arise from relying on the information presented.',
    alertStinking1:'Please select at least one distortion of thinking to continue with the exercise',
    alertStinking2:'You already selected your three distortions of thinking. If you changed your mind about one of the selected options, go back to the distortion of thinking and deactivate it by tapping on the applies button',
    stinkingThinkingLimitMessage: 'Select 3 of the most common shrinking thinking and check if they apply to your current situation.',
    remember: 'Remember',
    rememberDetail: 'To schedule an appointment with the BeWell program, you must select the option “Behavioral Health”',
    omboardingText: 'Do you often find yourself engaging in negative self-talk? This can hold you back from reaching your full potential.',
    totalCharactersTextArea: '(min :min_characteres characters - max 500 characters)'
  },
  es: {
    main: 'Salud mental',
    about: 'Acerca de',
    aboutText: 'Más información acerca de nuestras nuevas funciones y servicios',
    revisit: 'Volver a visitar la introducción',
    new: 'Acerca del nuevo módulo de Salud Mental',
    welcome:'Bienvenido a nuestra plataforma de salud mental, donde estamos dedicados a apoyar a las personas en su camino hacia la salud mental. Nuestra plataforma ofrece una variedad de características y recursos diseñados para ayudarte a mejorar tu salud mental y bienestar.',
    our: 'Nuestra funcionalidad de salud mental incluye una variedad de características, como ',
    our2:'recursos educativos, ejercicios de conexión y herramientas de agendamiento.',
    our3:'Nuestros recursos educativos brindan información sobre una variedad de temas de salud mental, como estrés, ansiedad y depresión, y ofrecen consejos prácticos para mejorar tu salud mental. Nuestros ejercicios de conexión te ayudan a mantenerte presente en el momento actual y nuestras opciones de agendamiento facilitan la programación de citas con nuestros profesionales de salud mental.',
    thank:  'Gracias por elegir nuestra plataforma como tu compañera en tu camino hacia la salud mental. Esperamos poder apoyarte en cada paso del camino.',
    appointments: 'Citas',
    appointmentsText: 'Agenda una cita virtual o presencial con tu profesional de salud conductual',
    educational: 'Recursos',
    lifestyle: 'Biblioteca de estilo de vida',
    lifestyleText: 'Estos recursos abarcan una variedad de temas, desde la atención plena y la meditación hasta la terapia cognitivo-conductual.',
    otherResources: 'Otros recursos',
    pDFsandEbooks: 'Ebooks y PDFs',
    otherResourcesText: 'Más información sobre una condición específica o simplemente deseas aprender más sobre la salud mental en general.',
    selfmanagement: 'Entendiendo mis síntomas',
    educationalText: 'Una selección de información educacional',
    needHelp: '¿Necesitas ayuda? ',
    needHelpText: 'Acceso a líneas telefónicas de \n ayuda y otros recursos',
    needHelpInfo: 'Aquí hay algunas funciones que ofrecemos para ayudar a apoyar tu bienestar mental',
    tools:'Entendiendo mis síntomas',
    toolsText:'Elige entre diferentes herramientas para encontrar la que funcione mejor para ti.',
    OnBoardTitle:'¡Bienvenido a nuestro modulo de Salud Mental! ',
    OnBoardSubTitle2:'Recursos',
    OnBoardSubTitle3:'Ejercicios de conexión',
    OnBoardSubTitle4:'Citas médicas',
    OnBoardSubTitle5:'',    
    OnBoardText1:'Entendemos que cuidar tu salud mental es tan importante como cuidar tu salud física. Aquí tienes algunas funciones que ofrecemos para ayudar a apoyar tu bienestar mental.',
    OnBoardText2:'Hemos seleccionado una lista de recursos de salud mental, que incluyen líneas de ayuda a los que puedes acceder directamente desde la aplicación.',
    OnBoardText3:'Escribir tus pensamientos y sentimientos puede ser una excelente manera de procesarlos y reflexionar sobre ellos. Nuestros ejercicios de conexión te permiten anotar tus pensamientos y hacer un seguimiento de tu progreso con el tiempo.',
    OnBoardText4:'Programar una cita con un profesional de salud conductual es un paso importante para mejorar tu bienestar mental. Encuentra y agenda una cita con proveedores licenciados en tu área.',
    OnBoardText5:'Ten en cuenta que la funcionalidad de salud mental proporcionada en  ',
    OnBoardText6:'nuestra aplicación no pretende sustituir la atención profesional de salud mental.',
    OnBoardText7:'Nuestra aplicación está diseñada para brindar herramientas y recursos que respalden tu bienestar mental de manera continua. No está preparada para manejar situaciones de crisis y ',
    OnBoardText8:'no es una línea de emergencia.',
    lets:'EMPECEMOS',
    skip:'SALTAR',
    beWellProgram: 'Programa BeWell',
    beWellProgramText: 'Obtén apoyo de un profesional de salud conductual disponible los 7 días de la semana.',
    beWellDefinition: "El programa BeWell puede ayudarte a reducir la ansiedad, mejorar el estado de ánimo, tener una mayor sensación de calma, aumentar la autoestima, promover la gestión del estrés y equilibrar la vida y el trabajo.",
    fhsDefinition: "En FHS ofrecemos servicios de salud mental ambulatorios que incluyen evaluación psiquiátrica, psicoterapia, manejo de medicación, terapia de pareja y familia, y más.",
    fhs: 'FHS',
    beWell: 'BeWell',
    floridaHealthcareSystem: 'Florida Healthcare System',
    floridaHealthcareSystemText: 'El equipo de profesionales de Florida Healthcare system está disponible para satisfacer las necesidades de salud mental de todas las edades.',
    chooseService: '¿No estás seguro de qué servicio elegir? ',
    areYouSure: '¿No estás seguro de qué servicio elegir?',
    modalNeedTitle: '¿Cómo podemos ayudarte?',
    linkInfo: 'Para más información visita: ',
    understandingYourAnxiety: 'Entendiendo tu ansiedad',
    thinking: 'Pensamiento',
    thinkingText: 'Selecciona una opción que se relacione a lo que estás pensando',
    thinkingDetails: 'Por favor cuéntame más sobre lo que pasó:',
    actions: 'Acciones',
    actionsText: '¿Cómo manejaste la situación?',
    feeling: 'Sentimientos',
    feelingText: '¿Qué sentiste en ese momento? Selecciona los que apliquen ',
    physicalSymptoms: 'Síntomas físicos',
    physicalSymptomsText: '¿Sentiste alguno de estos síntomas físicos? Selecciona los que apliquen',
    endOfEntry: 'Fin de entrada',
    cbtTitle: 'Cómo el TCC te ayuda con tu ansiedad',
    firstCbtP: 'La TCC se enfoca en comprender cómo tus comportamientos se relacionan con tus emociones y pensamientos. Cuando podemos entender cómo funciona nuestra ansiedad, podemos comenzar a realizar cambios para sentirnos mejor.',
    secondCbtP: 'El Triángulo Cognitivo es el centro de la TCC, <b>que teoriza que la forma en que piensas afecta la forma en que te sientes, y eso a su vez afecta la forma en que te comportas.</b> Esto puede funcionar en cualquier dirección. La forma en que te sientes puede afectar lo que estás pensando y lo que haces.',
    youMadeIt: '¡Lo lograste!',
    winText: 'Lidiar con la ansiedad no es fácil, pero la sanación es posible. Eres totalmente capaz de generar un cambio en tus comportamientos, pensamientos y emociones. No te rindas. Mientras respires, hay esperanza. Estamos aquí para ti en tu camino hacia la sanación y te animamos a buscar apoyo si lo necesitas.',
    youMadeItOptions: 'Ofrecemos una variedad de opciones de tratamiento, desde terapia individual hasta grupos de apoyo, para satisfacer tus necesidades únicas. Además, nuestra red de profesionales de la salud mental está disponible para brindarte un tratamiento personalizado y efectivo.',
    disclaimer:'Esta herramienta de autogestión está diseñada para servir como una guía y apoyo para las personas que buscan mejorar su salud mental y bienestar. No pretende sustituir el consejo, diagnóstico o tratamiento profesional. La información proporcionada en esta herramienta se basa en principios generales y puede no ser aplicable a cada individuo. El usuario debe consultar a un proveedor de atención médica calificado antes de realizar cambios en su plan de tratamiento o emprender nuevas actividades. El usuario asume toda la responsabilidad y riesgo por el uso de esta herramienta',
    disclaimerTitle:'Advertencia',
    close:'Cerrar',
    determine:'Para determinar tu elegibilidad para el programa, haz una cita con un profesional de salud conductual especializado.',
    callSchedule: 'Llama para agendar',
    callScheduleText: 'Haz clic en el botón de llamada y nuestro equipo estará encantado de ayudarte.',
    calledLater: 'Quiero ser llamado luego',
    calledLaterText: 'Nuestro equipo se pondrá en contacto contigo lo antes posible para programar un horario de cita conveniente (1-2 días hábiles).',
    schedule: 'Disponible Lunes a Viernes 8:00 a.m - 7:00 p.m',
    fhsForm:'FHS Formulario de contacto',
    fhsFormText:'Nuestro equipo se pondrá en contacto contigo lo antes posible para programar un horario de cita conveniente (1-2 días hábiles).',
    fhsComment:'Comentarios adicionales',
    fhsCommentText:'Siéntete libre de escribir otras opciones de fechas para tu cita.',
    fhsCommentPlaceholder: 'Comentarios adicionales (max 500 carácteres)',
    fhsModalText:'Tu información ha sido enviada. ¡Nos pondremos en contacto contigo lo antes posible!',
    stinkingTitle:'Entendiendo tus pensamientos negativos',
    stinkingSub:'Pensamientos negativos',
    stinkingText1:'Pensemos en nuestros pensamientos. Identificar y cambiar los pensamientos poco útiles es una de las principales formas de ayudar a crear un cambio duradero.',
    stinkingText2:'Lo más importante que debemos recordar al considerar nuestros pensamientos es nuestra percepción. La percepción es el acto de darle significado a las cosas que vemos, oímos, saboreamos, olemos y sentimos.',
    stinkingText3:'Cuando algo sucede, percibimos el evento a través de nuestros sentidos y formamos pensamientos basados en esa percepción. Esto significa que los pensamientos no siempre son verdaderos.',
    stinkingText4:'Desliza hacia la derecha o la izquierda para explorar diferentes tipos de pensamiento negativo. Toca el botón "Aplica" si puedes relacionarte con ese tipo de pensamiento en particular. Puedes seleccionar hasta tres tipos.',
    stinkingText5:'¡Comencemos a verificar tus pensamientos negativos!',
   
    more:'saber más',
    applies:'aplica',
   

    already:'Ya elegí mis tipos de pensamientos',
    challenging:'Desafiando tus pensamientos negativos',
    challengingText2:'Al hacer este tipo de preguntas, analizamos y desafiamos nuestro pensamiento <strong>y terminamos sintiéndonos mejor.</strong> Cuanto más practiques esta habilidad, mejor podrás dominarla. <strong>¡Vamos a intentarlo!</strong>',
    challengingText1:'Cuando logramos identificar los pensamientos poco útiles que causan ansiedad, podemos comenzar a enfrentarlos haciéndonos nuevas preguntas que cambien nuestra perspectiva.',
    animation1:'¿A qué estoy reaccionando?',
    animation3:'¿Cómo se vería esta situación para alguien ajeno a ella? ¿Lo verían de la misma manera? (Puede ser útil pensar en alguien a quien respetes y admires)',
    animation2:'¿Qué significa esta situación para mí en el contexto de toda mi vida?',
    animation4:'¿Esta situación importará dentro de 1 año, 5 años, 10 años, 50 años?',
    animation5:'¿Mi reacción es proporcional al evento real?',
    animationDesc:'Ofrecemos una variedad de opciones de tratamiento, desde terapia individual hasta grupos de apoyo, para satisfacer tus necesidades únicas. Además, nuestra red de profesionales de la salud mental está disponible para brindarte un tratamiento personalizado y efectivo.',
    finishText:'Lidiar con pensamientos negativos no es fácil, pero la sanación es posible. Eres totalmente capaz de generar un cambio en tus comportamientos, pensamientos y emociones. No te rindas. Mientras respires, hay esperanza. Estamos aquí para ti en tu camino hacia la sanación y te animamos a buscar apoyo si lo necesitas.',
    finishTitle:'¡Lo lograste!',
    goToMenu:'Finalizar Ejercicio',
    stinkingCard:'Entendiendo tus pensamientos negativos',
    stinkingCarTex:'Identifica y reta tus patrones de pensamiento negativo',
    back : 'ATRÁS',
    start:'EMPEZAR',
    next:'Siguiente',
    done:'listo',

    anxiety: 'Entendiendo tu ansiedad',
    anxietyText: 'Toma un paso atrás y observa tu ansiedad',
    stinking: 'Entendiendo tus pensamientos tóxicos',
    stinkingText: 'Identifica y reta tus patrones de pensamiento negativo',
    download: 'DESCARGAR',
    noEntryYet: "No tienes ninguna entrada aún",
    typeYourAnswer: 'Escribe tu respuesta aquí',
    minAnswer: '(min 10 caracteres - max 500 caracteres)',
    thinkingError: 'Por favor selecciona un tipo de pensamiento para continuar con el ejercicio',
    thinkingDetailError: 'Por favor escribe lo que piensas. Inserta al menos 10 caracteres para continuar con el ejercicio',
    actionDetailError: 'Por favor escribe lo que hiciste. Inserta al menos 10 caracteres para continuar con el ejercicio.',
    submit: 'Enviar',
    book:'Programa tu cita con un profesional de salud conductual',

    disclaimerOtherRes:'Esta biblioteca tiene la intención de brindar a los pacientes/usuarios acceso a información de salud actualizada. La información proporcionada no pretende reemplazar el consejo de un profesional de la salud y no es un sustituto del asesoramiento médico, diagnóstico, tratamiento o servicios de telemedicina. Mi Sanitas App no opera ni controla la biblioteca ni su contenido y no será responsable de ningún error u omisión en la información proporcionada, ni de las consecuencias que puedan surgir al confiar en la información presentada.',  
    alertStinking1:'Por favor selecciona al menos un tipo de distorsión de pensamiento para continuar con el ejercicio ',
    alertStinking2:'Ya has seleccionado tus tres distorsiones del pensamiento. Si cambiaste de opinión sobre una de las opciones seleccionadas, regresa a la distorsión del pensamiento y desactívala presionando el botón Aplica. ',
    stinkingThinkingLimitMessage: 'Selecciona 3 de los pensamientos de encogimiento más comunes y verifica si se aplican a tu situación actual.',
    remember: 'Recuerda',
    rememberDetail: 'Para agendar una cita con el programa BeWell debes seleccionar la opción “Behavioral Health”',
    omboardingText: '¿A menudo te hablas negativamente? Esto puede limitarte y evitar que alcances todo tu potencial.',
    totalCharactersTextArea: '(min :min_characteres caracteres - max 500 caracteres)'
  },
};

export default nsMentalHealth;
