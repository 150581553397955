// DOMAIN
import PublicHttpClient from '../publicHttpClient';
import { UserAvailityService } from '../../../domain/useCases/contracts/userAvailityService';
import { UserAvaility } from '../../../domain/entities/userAvaility';
import appConfig from '../../../config';

export const UserAvailityRepository: UserAvailityService = {
	sendUserData: (userData: UserAvaility) => {
		//return PublicHttpClient.post(`auth/availity/coverage`, userData);
		return PublicHttpClient.post(appConfig.AVAILITY_URL, userData);
		
	},
};


