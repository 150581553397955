import { useTranslation } from 'react-i18next';
import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';
import { REGEX } from '../../ui-core/utils/regex';

import { 
  KeraltyBarsIndicator,
  KeraltyIcon,
  KeraltyText,
  KeraltyTooltip, 
} from '../atoms';

type RuleType = {
  /** Color to be used if the regex matches value */
  color: keyof (typeof theme.colors);
  /** Rule label (Ex. Weak, medium) */
  label: string;
  /** Regular expression to test value */
  regex: RegExp;
}

type StrengthValidatorProps = {
  indicatorRules?: RuleType[];
  value: string;
}

const DEFAULT_VALIDATOR: RuleType[] = [
  {
    color: 'danger',
    label: 'StrengthValidator.weak',
    regex: REGEX.atLeastOne
  },
  {
    color: 'alert',
    label: 'StrengthValidator.medium',
    regex: REGEX.mediumPassword
  },
  {
    color: 'accent',
    label: 'StrengthValidator.strong',
    regex: REGEX.strongPassword
  }
];

const StrengthLabel = styled.span`
  margin: 0 0.5rem;
`;


const KeraltyStrengthValidator: React.FC<StrengthValidatorProps> = ({ indicatorRules = DEFAULT_VALIDATOR, value = '' }) => {
  const { t } = useTranslation();
  
  // Find the strongest matching rule
  const matchingRule = [...indicatorRules].reverse().find(
    ({ regex }) => regex.test(value)
  );

  return (
    <>
      <KeraltyText type="tooltip" bold underline>
        <KeraltyTooltip>
          <KeraltyIcon  data-tip={t('register.passwordInfo')} data-multiline iconClass={'fas fa-info-circle'} title={t('imgNicons.infoCircleIcon')}/>
          <span data-tip={t('register.passwordInfo')}>{t('register.passwordStrenght')}</span>          
          <StrengthLabel>
            {matchingRule ? (
              <KeraltyText type='tooltip' color={matchingRule.color} underline>
                {t(matchingRule.label)}
              </KeraltyText>
            ) : null}
          </StrengthLabel>
        </KeraltyTooltip>
      </KeraltyText>

      <KeraltyBarsIndicator
        indicatorRules={indicatorRules} 
        value={value} 
      />
    </>
  ); 
}

export default KeraltyStrengthValidator;