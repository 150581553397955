import React from 'react';
import styled from '../../ui-core/styled-components';

type SvgIconProps = {
  src: React.FC,
  color?: string;
  w?: number | string | null; 
  h?: number | string | null;
}

const IconWrapper = styled.span<Partial<SvgIconProps>>`
  svg {
    ${({ theme: { colors }, color, w, h }) => `
      color: ${color ? colors[color] : undefined};
      fill: ${color ? colors[color] : undefined};
      
      path {
        color: ${color ? colors[color] : undefined};
        fill: ${color ? colors[color] : undefined};
      }

      ${h ? `height: ${h}rem !important;` : null}

      ${w ? `width: ${w}rem !important;` : null}
    `}
  }
`

const SvgIcon: React.FC<SvgIconProps> = ({ src: IconComponent, color, w, h }) => (
  IconComponent ? (
    <IconWrapper color={color} w={w ? w : null} h={h ? h : null}>
      <IconComponent />
    </IconWrapper>
  ) : null
);

export default SvgIcon;