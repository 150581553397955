import { Column, Row } from "app/components/templates";
import BREAKPOINTS from "app/ui-core/utils/breakpoints";
import styled from "styled-components";

const RightContent = styled(Row)<{top?: string}>`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top:  ${({ top }) => top ? top : '5vh'};
		padding-inline: 10px;
	};
`;

const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
		margin-bottom: 0rem;
		paddingLeft: -2%
	}
`;

const Grid = styled.section`
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 80px 80px; 
	column-gap: 80px;
	row-gap: 25px;

	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		grid-template-columns: auto;
		grid-template-rows: 80px; 
	};
`;

const CustomSection = styled.section<{colums?: number, responsiveColums?: number}>`
  display: grid; 
//   grid-template-columns: auto auto auto auto; 
//   grid-template-rows: 60px 60px 60px 60px; 
  grid-template-columns: repeat(${({ colums }) => colums ? colums : 4}, auto); 
  grid-auto-rows: auto;
  column-gap: 40px;
  row-gap: 25px; 

  margin-top: 20px; 

  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    // grid-template-columns: auto auto;
    // grid-template-rows: 60px 60px; 
	grid-template-columns: repeat(${({ responsiveColums }) => responsiveColums ? responsiveColums : 1}, auto); 
  	grid-auto-rows: auto;
    margin-left: 0;
	row-gap: 10px; 
  };
`

export {
    RightContent,
    TitleContiner,
    Grid,
	CustomSection
}