
import styled from '../../ui-core/styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const TextStyled = styled.text`
  
  color: #055293;
  font-size: 16px;
  text-align: right !important;
  width: 100%;
 
 
`

const contentStyle = {
  marginTop: 45,
  paddingTop: 0,
  paddingBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  display: 'flex',
  alignItems: 'right'


}
const VersionView = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div style={contentStyle}>
      {history.location.pathname === '/login' ?
        <TextStyled>
          {t('version')} {process.env.REACT_APP_MOBILE_VERSION}
        </TextStyled>
        : null
      }

    </div>
  )

}





export default VersionView;