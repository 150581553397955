import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../../atoms';
import { KeraltyModal, SectionWidget } from 'app/components/molecules';

import styled from '../../../../ui-core/styled-components';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../../assets/icons/arrow-alt-circle-left.svg';
import theme from '../../../../ui-core/utils/theme';


import Icon1 from '../../../../assets/icons/blueCup.svg';




import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../../hooks/useAuth';
import useIsMobile from '../../../../hooks/useIsMobile'
import i18n from "i18next";
import { userSelectors } from 'adapter/user/userSelectors';
import isCordova from 'cordova';
import { DataOnboarding, OnboardingProps } from '../MentalHealth.types';
import { useEffect, useState } from 'react';
import ROUTES from 'app/ui-core/utils/routes';

const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;


const contentBodyStl = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: 10
}
const btnPevStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}
const btnNextStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '15px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}





const FinishStiking = (onboardingProps: OnboardingProps) => {
	const { start, skip } = onboardingProps
	const dispatch = useAppDispatch();
	const [step, setStep] = useState(0);
	const isMobile = useIsMobile();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const { t } = useTranslation();
	const { goBack, push } = useHistory();

	const handleMain = () => {
		push(`/${MAIN.mentalHealth}`);
	  };
	


	const buttonContentStl = {
		display: 'flex',
		justifyContent: 'center',
		marginLeft: isMobile ? '0 auto' : '11%',
		marginRight: isMobile ? '0 auto' : '7%',
		marginTop: isMobile ? 0 : 20
	}
	const btnLetsStl = {
		height: isMobile ? 41 : '',
		marginBottom: isMobile ? '15px' : '',
		marginRight: isMobile ? 20 : '',
		marginTop: isMobile ? 45 : ''
	}







	return (
		<Row style={{ display: 'flex', flexDirection: 'row', marginTop: 0 }}>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<Row style={{ marginInline: isMobile ? '2%' : '11%' }}>


					<KeraltyText
						align={'center'} type="title" color={isMobile ? 'primary' : 'blue997'}
						style={{ fontSize: isMobile ? 18 : 24 }}
					>
						{t('mentalHealth.finishTitle')}
					</KeraltyText>


					<Row
						style={contentBodyStl}
					>
						<Image
							size={isMobile ? 12 : 8}
							src={Icon1}

							alt={t('imgNicons.finishText')}
						/>
					</Row>
					<KeraltyText align="left" type="info"
						color={'TEXTCOLORLIGHT'}
						style={{ fontSize: 15, paddingInline: isMobile ? '2%' : '10%', paddingTop: 25, fontWeight: 400 }}>
						{t('mentalHealth.finishText')}
					</KeraltyText>
					<Row style={buttonContentStl} >
						<KeraltyButton onClick={handleMain}
							style={btnLetsStl}
						>
							{t('mentalHealth.goToMenu')}
						</KeraltyButton>
					</Row>

					<KeraltyText align="left" type="info" bold
						color={'blue007'}
						style={{ fontSize: 15, paddingInline: isMobile ? '2%' : '10%', paddingTop: 25 }}>
						{t('mentalHealth.animationDesc')}
					</KeraltyText>


				</Row>
			</div>

		</Row>
	);
};

export default FinishStiking;
