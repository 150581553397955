// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// UI
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { KeraltyText } from '../../atoms';
import { Container, Column, Row, IconLinkRow } from '../../templates';
import styled from '../../../ui-core/styled-components';
import useIsMobile from '../../../hooks/useIsMobile';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import isCordova from 'cordova';
import i18n from 'i18next';

/*const RightContent = styled(Row)`
  width:100%;
  padding: 0rem 1rem;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	padding: 3rem 1rem 0 1rem;
	background: #f5f5f5;
}`;*/

const RightContent = styled(Container)`
  margin: 1rem 0 5rem 0;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0;
  };
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 3vh;
	padding-inline: 10px;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }
`;

const TableContainer = styled(Container)`

	@media (min-width: ${BREAKPOINTS.minTabletWidth})
		and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
			max-width: ${BREAKPOINTS.maxTabletWidth};
	}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		max-width: ${BREAKPOINTS.maxPhoneWidth};
	}
`
const IconContainer = styled(Column)`
	width:100%;
	margin-left: 2.7%;

	@media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
		max-width: ${BREAKPOINTS.maxTabletWidth};
		margin-left: 5.5%;
		margin-bottom: 3%;
		//background-color: orange;
	}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		max-width: ${BREAKPOINTS.maxPhoneWidth};
		margin-left: 2.8%;
		margin-bottom: 5%;
		//background-color: purple;
	}
`;

type Props = {
	title?: string;
	children: React.ReactNode;
};

const TabWrapper: React.FC<Props> = ({ children, title }) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const isMobile = useIsMobile();
	const history = useHistory();

	const goBack2 = () => {
		history.replace('/my-health')
	};

	return (
		<>
		<RightContent centered="x" >
			<IconContainer>
				<IconLinkRow
					onClick={ (window.innerWidth < 1024 || isCordova) ? goBack2 : goBack }
					color="accent"
					icon={arrowAltCircleLeftIcon}
					iconAlt={t('imgNicons.backArrow')}
					text={t('routes.back')}
				/>
			</IconContainer>

			{title ? <TitleContiner lg={12}>
					{isMobile ? i18n.language == 'en' ? <KeraltyText type={'title'} style={{ marginRight: isMobile ? '25%' : '44%', height: '0', marginBottom: isMobile ? '10%' : '0' }}>{title}</KeraltyText>
						: <KeraltyText type={'title'} style={{ marginRight: '0%', height: '0%' }}>{title}</KeraltyText>
						:
						<KeraltyText type={'title'} >{title}</KeraltyText>}
				</TitleContiner> : <></>}

			<TableContainer centered={'xy'}>
				{children}
			</TableContainer>
		</RightContent>
		</>
	);
};

export default TabWrapper;
