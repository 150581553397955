import { PATIENT_RELATIONSHIPS } from '../valueObjects/patientRelationships';
import { RegisterService } from './contracts/registerService';
import { UserService } from './contracts/userService';
import { AuthService } from './contracts/authService';
import { EcwService } from './contracts/ecwService';
import { PatientInfo } from '../entities/patientInfo';
import { AccountInfo } from '../entities/accountInfo';
import { UserBasicInfo } from '../entities/userBasicInfo';
import { UserCredentials } from '../entities/userCredentials';
import { UserInfo } from 'domain/entities/userInfo';


/**
 * Register | Check account existence by accNumber & DOB
 */
const validateAccount = (
	service: Pick<AuthService, 'validateAccount'>,
	data: AccountInfo
) => service.validateAccount(data);

/**
 * Register | Check account contact methods by accNumber & DOB
 */
const validateAccountContactMethods = (
	service: Pick<AuthService, 'validateAccountContactMethods'>,
	data: AccountInfo & Omit<UserCredentials, 'password'>
) => service.validateAccountContactMethods(data);

/**
 * Register | Step 01 | Basic personal info
 */
const matchAccountInfo = (
	service: Pick<AuthService, 'matchAccountInfo'>,
	formValues: UserBasicInfo
) => service.matchAccountInfo(formValues);

/**
 * Register | Step 01 | Basic personal info
 */
const registrationCheck = (
	service: Pick<AuthService, 'registrationCheck'>,
	formValues: UserBasicInfo
) => service.registrationCheck(formValues);

/**
 * Register FB | Step 02 | Confirm login info
 */
const isEmailAvailable = (
	service: Pick<UserService, 'isEmailAvailable'>,
	email: string,
	state: string,
) => service.isEmailAvailable(email, state);

/**
 * Register & Register FB | Step 03 | Contact Info
 */
const initialSave = (
	service: Pick<RegisterService, 'initialSave'>,
	formValues: any
): Promise<any> => service.initialSave(formValues);

const resendEmail = (
	service: Pick<RegisterService, 'resendEmail'>,
	formValues: any
): Promise<any> => service.resendEmail(formValues);


/**
 * Register Sanitas | Step 03 | Contact Info
 */
const initialSaveByAccountNumber = (
	service: Pick<RegisterService, 'initialSaveByAccountNumber'>,
	data: any
): Promise<any> => service.initialSaveByAccountNumber(data);

/**
 * Patient Registration FB | Step 02 | Insurance Info
 */
export const getInsuranceList = (
	service: Pick<EcwService, 'loadInsurances'>
) => service.loadInsurances();

/**
 * Patient Registration FB | Step 01 | Pharmacy Info

 export const getPharmacyList = (
	service: Pick<EcwService, 'loadInsurances'>
) => service.loadInsurances();*/

/**
 * Patient Registration | Step 00 | One more step
 */
const getloadElegibilityData = (
	service: Pick<RegisterService, 'getloadElegibilityData'>,
	id: string,
) => service.getloadElegibilityData(id);

const loadUserInfo = (
	service: Pick<RegisterService, 'loadUserInfo'>,
	data: {code: string, state: string ,email: string}
): Promise<any> => service.loadUserInfo(data);

/**
 * Patient Registration FB | Step 00 | One more step
 */
const loadMaxUserInfo = (
	service: Pick<RegisterService, 'loadMaxUserInfo'>,
	data: {id: string,email: string}
) => service.loadMaxUserInfo(data);

/**
 * Patient Registration | Step 00 | Verify Code SMS
 */
const loadUserInfoByCode = (
	service: Pick<RegisterService, 'loadUserInfoByCode'>,
	data: {code: string, state: string ,email: string}
): Promise<any> => service.loadUserInfoByCode(data);

/**
 * Patient Registration FB | Step 00 | Verify Code SMS
 */
const loadMaxUserInfoByCode = (
	service: Pick<RegisterService, 'loadMaxUserInfoByCode'>,
	data: {id: string,email: string}
) => service.loadMaxUserInfoByCode(data);

/**
 * Expiration time for consents process
 */
const expirationConsents = (
	service: Pick<RegisterService, 'expirationConsents'>,
	data: { state: string, id: string, isFBMax: string}

) => service.expirationConsents(data);

/**
 * Patient Registration & Patient Registration FB | Final Step | Financial policy form
 */
const createAccount = (
	service: Pick<RegisterService, 'createAccount'>,
	patientData: PatientInfo & { userInfo: UserInfo, registerId: string, accountNumber?: string }
) => {
	let rp = { ...patientData.responsibleParty };
	let pi = { ...patientData.insurance };

	//#region BUSINESS RULES
	const isSelf = Number(rp.selfGuarantor) === 1;
	const isFBMax = Boolean(patientData.userInfo.externalPartyId);

	if (isFBMax) {
		pi.companyId = '999';
		pi.company = 'My Blue Connected Care';
		pi.patientToInsured = patientData.userInfo.patientRelationship ?? '';
		pi.insuredName = rp.firstName;
        pi.insuredLastName = rp.lastName;
        pi.subscriberId = patientData.userInfo.contractNumber ?? '';
	}

	if (isSelf) {
		rp.patientRelationship = '1';
		rp['patientRelationshipLabel'] = PATIENT_RELATIONSHIPS.self;
	}
	//#endregion

	const data = { isFBMax, isSelf, ...patientData, responsibleParty: rp, insurance: pi, accountNumber: patientData.accountNumber };

	return service.createAccount(data);
};


const registerUseCase = {
	validateAccount,
	validateAccountContactMethods,
	matchAccountInfo,
	registrationCheck,
	isEmailAvailable,
	resendEmail,
	initialSave,
	initialSaveByAccountNumber,
	getInsuranceList,
	loadUserInfo,
	loadMaxUserInfo,
	loadUserInfoByCode,
	loadMaxUserInfoByCode,
	getloadElegibilityData,
	createAccount,
	expirationConsents,
};

export default registerUseCase;
