import * as Yup from 'yup';

export interface FHSProps {
	dateH?: string | undefined;	
	dateD?:Date | string;	
}


export const FHSInf: Yup.SchemaOf<FHSProps> = Yup.object().shape({
	dateH: Yup.string().notRequired(),
	dateD: Yup.date().notRequired()
});
