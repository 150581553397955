import { RootState } from '../store';
// import { UserStateTypes } from '../../entities/UserTypes';
import { baseSelectors } from '../base/baseSelectors';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState): any => state.user;


const selectors = baseSelectors(selectSelf);


const selectActiveCoverage = createSelector(
	selectSelf,
	({ activeCoverage }) => activeCoverage
);


export const userSelectors = {
	...selectors,
	selectActiveCoverage
};
