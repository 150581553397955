import styled from '@emotion/styled';
import { AlertStyledProps } from './Alert.type';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';

export const AlertStyled = styled.div<AlertStyledProps>`
  position: relative;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    position: absolute;
    width: 90%;
  };
  z-index: 1;
  float: right;
  ${({ fullWidth }) => fullWidth ? 'width: 100%' : ''};
  border-style: solid;
  border-color: #E7A304;
  border-width: 1px;
  border-radius: 5px 5px 5px 5px;

  .border-left {
    width: 10px;
    height: 100%;
    background-color: ${({ mainColor }) => mainColor};
    position: absolute;
    border-radius: 5px 0px 0px 5px;
  }

  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-right: 10px;
    margin-left: 10px;
  };

  .content {
    display: flex;
    width: ${({ isSmall, fullWidth }) => fullWidth ? '100%' : (isSmall ? '390px' : '428px')};
    @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
      width: auto;
    };
    padding: ${({ isDefault }) => (isDefault ? '12px 0px 13px' : '12px 12px 5px 13px')};
    align-items: center;
    background-color: ${({ isDefault, defaultColor, secundaryColor }) =>
      isDefault ? defaultColor : secundaryColor};
    border-radius: 3px;

    &  .icon-content {
      margin-left: 10px;
    }
  }

  span {
    & span {
      font-weight: 600;
      padding-left: 0;
    };
    padding-left: 12px;
    ${({ isSmall }) =>
      isSmall
        ? `font-weight: 400;
                font-size: 15px;
                line-height: 18.27px;`
        : ''}color: #000000;
    
        & .close-option {
          margin-top: 10px;
          cursor: pointer;
          color:#000000;
          font-weight: 600;
          font-size: 12px !important;
          line-height: 17.05px;
          text-decoration: underline;
        }
  }
`;
