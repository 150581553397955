import PublicHttpClient from '../publicHttpClient';
import registerMapper from '../mappers/registerMapper';
import { FinalSaveRequest } from '../../keraltyApi/models/finalSaveRequest';
// DOMAIN
import { RegisterService } from '../../../domain/useCases/contracts/registerService';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const endpoint = 'auth/register';

const RegisterRepository: RegisterService = {
	initialSave: (data) => {
		const body = registerMapper.mapToInitialSave(data);
		// return PublicHttpClient.post(`${endpoint}/test`, body);
		return PublicHttpClient.post(`${endpoint}/initialsave`, body);
	},
	initialSaveByAccountNumber: (data) => {
		const body = registerMapper.mapToInitialSaveByAccountNumber(data);
		return PublicHttpClient.post(`${endpoint}/initialsaveecw`, body);
	},
	loadUserInfo: (data) => {
		return PublicHttpClient.get(`${endpoint}/gettempusernofb/${data.code}/${data.state}/${data.email}`)
			.then((res) => registerMapper.mapFromLoadUserInfo(res));
	},
	loadMaxUserInfo: (data) => {
		return PublicHttpClient.get(`${endpoint}/eligibilitydata/${data.id}/${data.email}`)
			.then((res) => registerMapper.mapFromLoadMaxUserInfo(res));
	},
	loadUserInfoByCode: (data) => {
		return PublicHttpClient.get(`${endpoint}/tempnofbuserviacode/${data.code}/${data.state}/${data.email}`)
			.then((res) => registerMapper.mapFromLoadUserInfo(res));
	},
	loadMaxUserInfoByCode: (data) => {
		return PublicHttpClient.get(`${endpoint}/tempfbuserviacode/${data.id}/${data.email}`)
			.then((res) => registerMapper.mapFromLoadMaxUserInfo(res));
	},
	getloadElegibilityData: (id) => {
		return PublicHttpClient.get(`${endpoint}/loadElegibilityData/${id}/`)
			.then((res) => res);
	},
	createAccount: (data) => {
		const body: FinalSaveRequest = registerMapper.mapToFinalSave(data);
		KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_USER);
		return PublicHttpClient.post(`${endpoint}/finalsave`, body);
	},

	resendEmail: (data) => {
		const body = registerMapper.mapToResendEmail(data);
		// return PublicHttpClient.post(`${endpoint}/test`, body);
		return PublicHttpClient.post(`${endpoint}/resendemail`, body);
	},

	expirationConsents: (data) => {
		return PublicHttpClient.get(`${endpoint}/can-user-continue-to-consents/${data.state}/${data.id}/${data.isFBMax}`)
		.then((res) => res);
	}, 

};

export default RegisterRepository;
