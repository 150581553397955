const nsContactConsent = {
  en: {
    consent: 'Consent to email or phone for healthcare communications',
    purpose:
      'From time to time, Sanitas and its affiliates desire to contact patients and other individuals via emails, phone calls, text messages, prerecorded messages or voicemails to:',
    aptReminder: 'Remind patients of appointments',
    opinions: 'Obtain feedback from patients on their experience with our healthcare team',
    gralInfo: 'Provide general health information',
    promosInfo: 'Inform individuals about new and /or existing products, promotions or services',
    reqInfo:
      "Provide/request any other information Sanitas believes may be relevant and /or useful as it relates to an individuals' healthcare.",
    automatedCom:
      'To efficiently communicate such information, Sanitas and its affiliates may use automated dialing technology for the selection and dialing of telephone numbers, and/or automated technology to play a recorded message when a connection is made. The automated technology may include equipment that has the capacity to store and/or produce telephone numbers to be called using a random or sequential generation and/or to dial such numbers.',
    comunicatios: "Such communications may contain your protected health information. Although Sanitas works hard to protect your personal health information, please know that no electronic mode of communication or transmission of information can be completely secure, and there is no guarantee that your electronically transmitted information will not be compromised.",
    electronicCom:
      'Such communications may contain your protected health information. Although Sanitas works hard to protect your personal health information, please know that no electronic mode of communication or transmission of information can be completely secure, and there is no guarantee that your electronically transmitted information will not be compromised.',
    authorize:
      'If you consent to receiving such communications as set forth herein, please indicate such by checking the appropriate box(es) below in connection with which mode(s) of communication you wish to receive such communications. Checking such box(es) will constitute your consent, authorizing Sanitas and its affiliates to contact you via emails if you select the email box; and via phone calls (even if your phone number is on a state or national Do Not Call registry), text messages, prerecorded messages and/or voicemails using automated telephone dialing technology as described herein and for the purposes described above, which may include telephonic sales calls, if you select the phone box.',
    checkBox:
      'You are not required to check the box below or otherwise directly or indirectly agree to being contacted as a condition for receiving services from Sanitas and/or its affiliates.',
    back: 'BACK',
    accept: 'By clicking this checkbox, I have read and accepted the Sanitas {0}',
  },
  es: {
    consent:
      'Consentimiento para comunicaciones de salud a través de correo electrónico o teléfono',
    purpose:
      'Ocasionalmente, Sanitas y sus afiliados desean comunicarse con los pacientes y otras personas a través de correos electrónicos, llamadas telefónicas, mensajes de texto, mensajes pregrabados o mensajes de voz para:',
    aptReminder: 'Recordar a los pacientes de las citas médicas',
    opinions:
      'Obtener opiniones de los pacientes sobre su experiencia con nuestro equipo de atención médica',
    gralInfo: 'Brindar información de salud general',
    promosInfo:
      'Informar a las personas sobre productos, promociones o servicios nuevos o existentes',
    reqInfo:
      'Brindar/solicitar cualquier otra información que Sanitas considere relevante y/o útil respecto a la atención médica de una persona',
    automatedCom:
      'Para comunicar de manera eficiente dicha información, Sanitas y sus afiliadas pueden utilizar tecnología de marcación automatizada para la selección y marcación de números de teléfono y/o tecnología automatizada para reproducir un mensaje grabado cuando se establece una conexión. La tecnología automatizada puede incluir equipos que tengan la capacidad de guardar y/o generar números de teléfono para ser llamados usando un sistema aleatorio o secuencial y/o para marcar dichos números.',
    comunicatios: "",
    electronicCom: '',
    authorize:
      'Si aceptas recibir dichas comunicaciones como se establece en este documento, indícalo marcando la casilla correspondiente a continuación. Al marcar la casilla constituirá tu firma electrónica, y estarás autorizando a Sanitas y sus afiliadas a comunicarse contigo (a través de los canales seleccionados en tus Preferencias de comunicación) vía correos electrónicos, llamadas telefónicas, (incluso si tu número de teléfono está en un registro estatal o nacional de No llamar), mensajes de texto, mensajes pregrabados y/o correos de voz utilizando tecnología de marcación telefónica automatizada como se describe en este documento y para los fines descritos anteriormente, que pueden incluir llamadas telefónicas de ventas.',
    checkBox:
      'No es necesario que marques la casilla a continuación o que aceptes, directa o indirectamente, ser contactado como condición para recibir servicios de Sanitas y/o sus afiliados.',
    back: 'VOLVER',
    accept: 'Al activar esta casilla confirmo que leí y acepto los términos del ',
  },
};

export default nsContactConsent;
