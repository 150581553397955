import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import { Container } from '../templates';

const StyledLongCard = styled(Container)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  background-color: white;
  width: 100%;
  border-radius: 0.93rem;
  margin-bottom: 1em;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

export default StyledLongCard;
