import KeraltyText from './keraltyText';
import { ReactComponent as EmptyIconSvg } from '../../assets/icons/emptyState.svg'
import styled from '../../ui-core/styled-components';

type Props = {
  text?: string;
}

const EmptyIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & svg {
    margin-bottom: 1rem;
  }
`;

const EmptyIcon: React.FC<Props> = ({ text }) => {
  return (
    <EmptyIconWrapper>
      <EmptyIconSvg />
      <KeraltyText type="subTitle" bold>
        {text}
      </KeraltyText>
    </EmptyIconWrapper>
  )
}

export default EmptyIcon;