import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Column } from 'app/components/templates';
import KeraltyLongCard from 'app/components/molecules/KeraltyLongCard';
import { SectionWidget } from 'app/components/molecules';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { Divider } from 'app/components/atoms';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { shutdown, show, setUp } from 'adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker, openSymptomChecker } from 'adapter/chat/chatSlice';
import i18n from 'i18next';
import { openBookAppointment } from 'adapter/chat/chatSlice';
import ROUTES from 'app/ui-core/utils/routes';
import ModalTelevisitPay from './organisms/getCare/televisitPayment/modals/ModalTelevisitPay';
import ModalInsuranceInactived from './organisms/getCare/televisitPayment/modals/ModalInsuranceInactived';
import ModalInsuranceNoValidated from './organisms/getCare/televisitPayment/modals/ModalInsuranceNoValidated';
import ModalPCPNoSanitas from './organisms/getCare/televisitPayment/modals/ModalPCPNoSanitas';
import ModalNoNewPayment from './organisms/getCare/televisitPayment/modals/ModalNoNewPayment';
import ModalTransactionProcess from './organisms/getCare/televisitPayment/modals/ModalTransactionProcess';
import ModalPreviousTransaction from './organisms/getCare/televisitPayment/modals/ModalPreviousTransaction';
import handCareIcon from 'app/assets/icons/hand-care-duotone.svg';
import Location from 'app/assets/icons/locationicon.svg';
import CareProgramIcon from 'app/assets/icons/care-programs-blue.svg';
import MyHealthIcon from 'app/assets/icons/my-health-duotone.svg';
import CalendarIcon from 'app/assets/icons/calendar-duotone.svg';
import InsuranceCardIcon from 'app/assets/icons/my-account-menu.svg';
import LogoutIcon from 'app/assets/icons/logout-duotone.svg';
import mhIcon from 'app/assets/icons/mh-webicon.svg';

import { disableToTN, ScheduleCode } from 'app/ui-core/utils/disableOptions';
import { userSelectors } from 'adapter/user/userSelectors';
import isCordova from 'cordova';
import appConfig from 'config';
import { utcTime } from 'app/ui-core/utils/times';
import { IValidateInsuranceToChatResponse } from '../../domain/entities/chatWithDocInfo';
import { InsuranceCode } from '../../domain/valueObjects/insurance';
import * as sanitasAdapterMethods from '../../adapter/sanitasChat/sanitasChat';
import Loader from '../components/templates/loader';
import { selectPaymentLoading } from '../../adapter/payment/paymentSelectors';
import ModalInsuranceValidate from './molecules/ModalInsuranceValidate';
import useChatProvider from 'app/hooks/useChatProvider';
import {
  SERVICES_TYPES,
  PaymentCode,
  SESSION_STATUS,
  defaultValue,
  VARIABLE_TYPES,
  EXPIRATION_TIMES,
} from '../../domain/valueObjects/payment';
import {
  initPayment,
  createNewTransactionBase,
  checkTransactionStatus,
  cancelUserTransaction,
  validateInsurance,
} from 'adapter/payment/paymentSlice';
import {
  IInsurancePaymentResponse,
  ISessionInformation,
  OtherValue,
} from 'domain/entities/paymentInfo';
import ModalNoCopayment from './organisms/getCare/televisitPayment/modals/ModalNoCopayment';
import wellnessIcon from 'app/assets/icons/wellness.svg';
import useLibrary from 'app/hooks/useLibrary';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import useGetCareNow from 'app/hooks/useGetCareNow';

const {
  MY_ACCOUNT,
  MAIN,
  MENTAL_HEALTH,
  GET_CARE,
  GET_CARE: {
    carePrograms,
    getCareNow,
    talkToaDoctor,
    upcomingAppointments,
    appointments,
    previousAppointments,
    televisit,
  },
  MAIN: { getCare },
  //	MY_HEALTH: { referrals, visitSummary, labResults, immunization },
} = ROUTES;

const linkToTelevisit: string = appConfig.TELADOC_URL;

const Widget = styled.div`
  width: 100%;
`;

const SubMenuText = styled.span`
  /* margin-left: 1.5rem; */
  cursor: pointer;
`;

const LongCardMainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const LongCardListCollapsible = styled(LongCardMainContent)`
  flex-direction: column;
  align-items: baseline;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme: { colors } }) => colors.primary};
`;

const SubMenuWrapper = styled.div`
  width: 100%;
`;

const SidebarRow = styled(Row)`
  margin: 0;
`;

const LongCard = styled(KeraltyLongCard)`
  margin-top: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

const ArrowContainer = styled.div`
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-right: -20px;
  margin-left: -20px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const Arrow = styled.div`
  $easing: cubic-bezier(0.25, 1.7, 0.35, 0.8);
  $duration: 0.5s;

  background-color: $background;
  height: 0;
  width: 30px;
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  transform: ${(props) => (props['data-open'] ? 'rotate(-180deg)' : 'rotate(0deg)')};
  left: ${(props) => (props['data-open'] ? '-15px' : '0')};

  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 8px;
    height: 2px;
    display: block;
    transform: rotate(35deg);
    float: right;
    border-radius: 2px;
    &:after {
      content: '';
      background-color: gray;
      width: 8px;
      height: 2px;
      display: block;
      float: right;
      border-radius: 3px 5px 5px 3px;
      transition: all $duration $easing;
      z-index: -1;
    }
  }

  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 6px;
    width: 8px;
    height: 2px;
    display: block;
    transform: rotate(-35deg);
    float: right;
    border-radius: 2px;
    &:after {
      content: '';
      background-color: gray;
      width: 8px;
      height: 2px;
      display: block;
      float: right;
      border-radius: 5px 3px 3px 5px;
      transition: all $duration $easing;
      z-index: -1;
    }
  }
`;

const SidebarColumn = styled(Column)`
  padding: 0;
  margin-top: 30px;
  margin-bottom: 50px;
`;

const SidebarContainer = styled.div`
  width: fit-content;
`;

const initialChatStatus: IValidateInsuranceToChatResponse = {
  code: InsuranceCode.default_code,
  message: '',
};

const SidebarCordova = ({ toggleMenu }) => {
  const [openCard, setOpenCard] = useState(-1);
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { push, replace } = useHistory();
  const dispatch = useAppDispatch();
  const [paymentResponse, setPaymentResponse] = useState<IInsurancePaymentResponse>(defaultValue);
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState } = sanitasChatState;
  const { token, authUid, state } = useAppSelector((state) => state.user);
  const userState = user?.state;
  const [sessionId, setSessionId] = useState('');
  const isLoading = useAppSelector(selectPaymentLoading);
  const history = useHistory();
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const language = i18n.language.includes('en') ? 'en' : 'es';

  const [paymentError, setPaymentError] = useState<boolean>(false);

  const { handleChatWithDoc, currentChatStatus, closeModalChatStatus } = useChatProvider();

  const { triggerHealtLibrary } = useLibrary();
  const { enableVideoCall } = useGetCareNow();
  const assistancePhone = appConfig.CALL_NUMBER;
  const prefix = appConfig.CALL_NUMBER_PREFIX;

  const closeToggle = () => {
    toggleMenu();
    history.replace(`/my-account/my-insurance`);
  };

  const triggerPatientSupport = useCallback(async () => {
    await setTimeout(async () => {
      await dispatch(
        sanitasAdapterMethods.setUp({
          actionColor: '#7cbc98',
          backgroundColor: '#7cbc98',
          name: `${user.displayName}`,
          email: `${user.email}`,
          state: `${user.state}`,
          currentTimeZone: `${utcTime()}`,
          authUid: `${authUid}`,
          sanitasAccountNumber: `${user.idEcw}`,
          gender: `${user.sex}`,
          birthday: `${user.birthdate}`,
          customAttributes: { cType: 'support' },
        })
      );
      await dispatch(sanitasAdapterMethods.show());
    }, 500);
    closeModalChatStatus();
  }, [dispatch]);

  const handleTalkToDoctor = async () => {
    dispatch(closeSymptomChecker());
    if (sanitasState.isShow && sanitasState.isSetted) {
      dispatch(shutdown());
    }
    await setTimeout(async () => {
      await dispatch(
        setUp({
          actionColor: undefined,
          backgroundColor: undefined,
          name: `${user.displayName}`,
          email: `${user.email}`,
          state: `${user.state}`,
          currentTimeZone: `${utcTime()}`,
          authUid: `${authUid}`,
          sanitasAccountNumber: `${user.idEcw}`,
          gender: `${user.sex}`,
          birthday: `${user.birthdate}`,
          // customAttributes: { cType: disableToTN(user?.state) ? 'provider-tennesse' : 'provider' },
          customAttributes: { cType: 'provider' },
        })
      );
      await dispatch(show());
    }, 500);
  };
  const isModalDisplayed = (paymentCode: PaymentCode): boolean =>
    !isLoading && paymentResponse && paymentResponse.code === paymentCode;
  const handleTelevisit = () => {
    dispatch(
      initPayment({
        authUid,
        state: state.toUpperCase(),
        token,
        service: SERVICES_TYPES.VIDEO_CALL,
		english: language === 'en'
      })
    )
      .unwrap()
      .then((response: IInsurancePaymentResponse) => {
        if (response.code === PaymentCode.transaction_completed) {
          window.open(linkToTelevisit, '_blank');
          return;
        }

        setPaymentResponse(response);
      });
  };
  const diferenceTimeByZone = [
    {
      state: 'FL',
      diference: -4,
    },
    {
      state: 'TN',
      diference: -5,
    },
  ];

  const cards = [
    {
      img: handCareIcon,
      state: userState,
      alt: t('getCareIcon'),
      text: t('routes.getCare'),
      subMenus: [
        {
          text: userState === 'TN' ? '' : t('button.chatWithaDoctor'),
          callback: () => handleChatWithDoc(),
        },
        {
          text: isAvailable ? disableToTN(user?.state) ? t('button.televisitTN') : t('button.televisit') : '',
          callback: false
            ? null
            : () => {
              handleTelevisit();
            },
        },
        {
          text: userState == 'TN' ? '' : t('button.callDoctor'),
          callback: () => {
            window.location.href = `tel:${prefix}${assistancePhone}`;
            toggleMenu();
          },
        },
      ],
	  hidden: !enableVideoCall
    },
    // {
    //   img: CareProgramIcon,
    //   state: userState,
    //   atl: t('imgNicons.careProgramsIcon'),
    //   text: t('routes.carePrograms'),
    //   callback: () => {
    //     push(`/${getCare}/${carePrograms}`);
    //     toggleMenu();
    //   },
    // },
    {
      img: MyHealthIcon,
      state: userState,
      atl: t('imgNicons.healthReportIcon'),
      text: t('routes.myHealth'),
      subMenus: [
        {
          text: t('myHealth.labNimgingResults'),
          callback: () => {
            push('/my-health/lab-results');
            toggleMenu();
          },
        },
        {
          text: t('routes.referrals'),
          callback: () => {
            push('/my-health/referrals');
            toggleMenu();
          },
        },
        {
          text: t('myHealth.current'),
          callback: () => {
            push('/my-health/current-Medication');
            toggleMenu();
          },
        },
        {
          text: t('myHealth.visitSummary'),
          callback: () => {
            push('/my-health/visit-summary');
            toggleMenu();
          },
        },

        {
          text: t('myHealth.immunizations'),
          callback: () => {
            push('/my-health/immunization');
            toggleMenu();
          },
        },
      ],
	  hidden: false
    },
    {
      img: CalendarIcon,
      state: userState,
      alt: t('imgNicons.medAptIcon'),
      text: t('routes.appointments'),
      subMenus: [
        {
          text: t('button.bookAppointment'),
          callback: () => {
            dispatch(openBookAppointment());
            replace({ search: '' });
            toggleMenu();
          },
        },
        {
          text: t('getCare.upcomingAppointments'),
          callback: () => {
            push(`/${getCare}/${appointments}/${upcomingAppointments}`);
            toggleMenu();
          },
        },
        {
          text: t('getCare.prevAppointments'),
          callback: () => {
            push(`/${getCare}/${appointments}/${previousAppointments}`);
            toggleMenu();
          },
        },
      ],
	  hidden: false
    },
    {
      img: mhIcon,
      state: userState,
      atl: t('imgNicons.mhIcon'),
      text: t('routes.mh'),
      subMenus: [
        {
          text: t('mentalHealth.about'),
          callback: () => {
            push(`/${MAIN.mentalHealth}/${MENTAL_HEALTH.about}`);
            toggleMenu();
          },
        },
        {
          text: t('mentalHealth.appointments'),
          callback: () => {
            push(`/${MAIN.mentalHealth}/${MENTAL_HEALTH.appointments}`);
            toggleMenu();
          },
        },

        {
          text: t('mentalHealth.educational'),
          callback: () => {
            push(`/${MAIN.mentalHealth}/${MENTAL_HEALTH.edures}`);
            toggleMenu();
          },
        },
        {
          text: t('mentalHealth.needHelp'),
          callback: () => {
            push(`/${MAIN.mentalHealth}/${MENTAL_HEALTH.help}`);
            toggleMenu();
          },
        },
        {
          text: t('mentalHealth.tools'),
          callback: () => {
            push(`/${MAIN.mentalHealth}/${MENTAL_HEALTH.selfmanagement}`);
            toggleMenu();
          },
        },
      ],
	  hidden: false
    },
    {
      img: wellnessIcon,
      state: userState,
      atl: t('imgNicons.wellnessIcon'),
      text: t('routes.wellness'),
      subMenus: [
       /* {
          text: t('routes.check'),
          callback: () => {
            checkYourSymptoms();
            toggleMenu();
          },
        },*/
        {
          text: t('routes.healtLibrary'),
          callback: () => {
            triggerHealtLibrary();
            toggleMenu();
          },
        },
      ],
	  hidden: false
    },
    {
      img: InsuranceCardIcon,
      state: userState,
      atl: t('imgNicons.idIcon'),
      text: t('routes.myAccount'),
      subMenus: [
        {
          text: t('myAccount.personalInfo'),
          callback: () => {
            push(`/${MAIN.myAccount}/${MY_ACCOUNT.personalInfo}`);
            toggleMenu();
          },
        },
        {
          text: t('myAccount.myInsurance'),
          callback: () => {
            push(`/${MAIN.myAccount}/${MY_ACCOUNT.myInsurance}`);
            toggleMenu();
          },
        },
      ],
	  hidden: false
    },
    {
      img: Location,
      state: userState,
      alt: t('imgNicons.locationIcon'),
      text: t('routes.location'),
      callback: () => {
        window.open('https://www.mysanitas.com/en/fl#state-locations', '_blank');
      },
	  hidden: false
    },
    {
      img: LogoutIcon,
      state: userState,
      alt: t('imgNicons.logoutIcon'),
      text: t('logoutButtonText'),
      callback: () => {
        getOut();
        toggleMenu();
      },
	  hidden: false
    },
  ];

  const getOut = () => {
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, 'true');
    logout();
  };

  const handleCardCallback = (callback) => {
    callback();
  };

  const handleOpenCard = (index) => {
    const newIdex = openCard === index ? -1 : index;
    setOpenCard(newIdex);
  };
  const closeModal = () => {
    setPaymentError(false);
    setPaymentResponse(defaultValue);
  };

  const createTransactionBase = async () => {
    dispatch(
      createNewTransactionBase({
        authUid,
        state: state.toUpperCase(),
        token,
        service: SERVICES_TYPES.VIDEO_CALL,
		english: language === 'en'
      })
    )
      .unwrap()
      .then((response: IInsurancePaymentResponse) => {
        setPaymentResponse({
          ...paymentResponse,
          transactionId: response.id,
          code: PaymentCode.modal_televisit_pay,
        });
      });
  };
  const validateSessionStatus = (newTransaction: boolean, sessionId: any) => {
    dispatch(checkTransactionStatus(sessionId))
      .unwrap()
      .then((response: ISessionInformation) => {
        if (
          newTransaction &&
          response.transactionInformation.status !== SESSION_STATUS.IN_PROCESS
        ) {
          setPaymentResponse({
            ...paymentResponse,
            code: PaymentCode.cant_continue_with_a_new_payment,
          });
          return;
        } else if (
          !newTransaction &&
          response.transactionInformation.status !== SESSION_STATUS.IN_PROCESS
        ) {
          replace(
            `/${MAIN.getCare}/${GET_CARE.paymentStatus}?${VARIABLE_TYPES.SESSION}=${sessionId}`
          );
          return;
        }

        if (newTransaction) closeTransaction();

        setPaymentResponse({
          ...paymentResponse,
          code: PaymentCode.modal_televisit_pay,
        });
      });
  };

  const createTransactionBaseOnly = async () => {

    dispatch(createNewTransactionBase({
      authUid,
      state: state.toUpperCase(),
      token,
      service: SERVICES_TYPES.VIDEO_CALL,
	  english: language === 'en'
    }))
      .unwrap()
      .then((response: IInsurancePaymentResponse) => {

      })

  };
  const createNewTransaction = async (newTransaction: boolean) => {
    if (paymentResponse.code === PaymentCode.transaction_in_process) {
      const sessionUrl = paymentResponse.otherValues?.find(
        (value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_URL
      );

      if (!sessionUrl?.value) {
        dispatch(
          cancelUserTransaction({
            authUid,
            state,
            service: SERVICES_TYPES.VIDEO_CALL,
          })
        )
          .unwrap()
          .then((response: any) => {
            createTransactionBase();
          })
          .catch((error: any) => { });

        return;
      }

      const sessionId = paymentResponse.otherValues?.find(
        (value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_ID
      );

      if (!sessionId?.value) return;

      setSessionId(sessionId.value);

      validateSessionStatus(newTransaction, sessionId.value);

      return;
    }

    const finalDate = new Date(paymentResponse.transactionCreatedDate);
    finalDate.setMinutes(finalDate.getMinutes() + EXPIRATION_TIMES.PAYMENT_CREATED);

    const currentDate = new Date();

    if (currentDate > finalDate || newTransaction) closeTransaction();

    setPaymentResponse({
      ...paymentResponse,
      code: PaymentCode.modal_televisit_pay,
    });
  };
  const closeTransaction = () => {
    dispatch(
      cancelUserTransaction({
        authUid,
        state: state.toUpperCase(),
        service: SERVICES_TYPES.VIDEO_CALL,
      })
    )
      .unwrap()
      .then((response: any) => {
        handleTelevisit();
      })
      .catch((error: any) => { });

    return;
  };
  const handleNoCopaymentValue = () => {
    createTransactionBaseOnly();
    window.open(linkToTelevisit, '_blank')
    closeModal();
    return;

  }

  return (
    <>
      {isLoading && <Loader />}
      {/* Payment modals */}
      {isModalDisplayed(PaymentCode.modal_televisit_pay) && (
        <ModalTelevisitPay
          close={closeModal}
          paymentError={paymentError}
          setPaymentError={setPaymentError}
          paymentInfo={paymentResponse}
        />
      )}
      {isModalDisplayed(PaymentCode.modal_insurance_not_validated) && (
        <ModalInsuranceNoValidated
          close={closeModal}
          paymentInfo={paymentResponse}
          triggerPatientSupport={triggerPatientSupport}
          createTransactionBase={createTransactionBase}
        />
      )}
      {isModalDisplayed(PaymentCode.modal_insurance_inactived) && (
        <ModalInsuranceInactived
          close={closeModal}
          paymentInfo={paymentResponse}
          triggerPatientSupport={triggerPatientSupport}
          createTransactionBase={createTransactionBase}
        />
      )}
      {isModalDisplayed(PaymentCode.modal_pcp_no_validated) && (
        <ModalPCPNoSanitas
          close={closeModal}
          paymentInfo={paymentResponse}
          triggerPatientSupport={triggerPatientSupport}
          createTransactionBase={createTransactionBase}
        />
      )}
      {isModalDisplayed(PaymentCode.previous_transaction) && (
        <ModalPreviousTransaction createNewTransacion={createNewTransaction} />
      )}
      {isModalDisplayed(PaymentCode.impossible_to_create_new_payment) && (
        <ModalTransactionProcess close={closeModal} />
      )}
      {isModalDisplayed(PaymentCode.transaction_in_process) && (
        <ModalPreviousTransaction createNewTransacion={createNewTransaction} />
      )}
      {isModalDisplayed(PaymentCode.cant_continue_with_a_new_payment) && (
        <ModalNoNewPayment sessionId={sessionId} />
      )}

      {isModalDisplayed(PaymentCode.unknown_value_co_insurance) && (
        <ModalNoCopayment handleNoCopaymentValue={handleNoCopaymentValue} close={closeModal} />
      )}
      <ModalInsuranceValidate
        currentChatStatus={currentChatStatus}
        closeModalChatStatus={closeModalChatStatus}
        externalAction={toggleMenu}
      />
      <SidebarContainer>
        <SidebarColumn>
          {cards.filter( card => !Boolean(card.hidden) ).map((card, index) => {
            if (disableToTN(user?.state) && card.text === t('routes.mh')) {
              return <></>;
            } else {
              return (
                <SidebarRow
                  onClick={() => card.callback && handleCardCallback(card.callback)}
                  key={index}
                >
                  <Widget>
                    <LongCard>
                      <LongCardMainContent>
                        <SectionWidget img={card.img} text={card.text} alt={card.alt} marginLeft={0} />
                        {card.subMenus && (
                          <>
                            <ArrowContainer onClick={() => handleOpenCard(index)}>
                              <Arrow data-open={openCard === index}>
                                <span className="left-bar"></span>
                                <span className="right-bar"></span>
                              </Arrow>
                            </ArrowContainer>
                          </>
                        )}
                      </LongCardMainContent>
                      {card.subMenus && openCard === index && (
                        <LongCardListCollapsible>
                          {card.subMenus.map((subMenu) =>
                            subMenu.text ? (
                              <SubMenuWrapper
                                onClick={() =>
                                  subMenu.callback && handleCardCallback(subMenu.callback)
                                }
                              >
                                <Divider my={1} mx={0} />
                                <div style={{ marginLeft: 1 }}>
                                  <SubMenuText style={subMenu.callback ? {} : { marginLeft: 0 }}>
                                    {subMenu.text}
                                  </SubMenuText>
                                </div>
                              </SubMenuWrapper>
                            ) : (
                              ''
                            )
                          )}
                        </LongCardListCollapsible>
                      )}
                    </LongCard>
                  </Widget>
                </SidebarRow>
              );
            }
          })}
        </SidebarColumn>
      </SidebarContainer>
    </>
  );
};

export default SidebarCordova;
