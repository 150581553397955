
import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';

interface LayoutProps {
	bg: keyof typeof theme.colors;
}

const Layout = styled.div<LayoutProps>`
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	${({ bg, theme: { colors } }) => bg ? `background-color: ${colors[bg]};` : ''}
`;

export default Layout;
