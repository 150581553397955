import { useTranslation } from 'react-i18next';

import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../../templates';
import { KeraltyButton, KeraltyText, Image } from '../../../atoms';
import { KeraltyModal, SectionWidget } from 'app/components/molecules';

import styled from '../../../../ui-core/styled-components';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../../assets/icons/arrow-alt-circle-left.svg';
import theme from '../../../../ui-core/utils/theme';


import Icon1 from '../../../../assets/icons/offBoard1.svg';
import Icon2 from '../../../../assets/icons/offBoard2.svg';



import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../../hooks/useAuth';
import useIsMobile from '../../../../hooks/useIsMobile'
import i18n from "i18next";
import { userSelectors } from 'adapter/user/userSelectors';
import isCordova from 'cordova';
import { AnimationProps, DataOnboarding, OnboardingProps } from '../MentalHealth.types';
import { useEffect, useState } from 'react';



const contentBodyStl = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: 10
}
const btnPevStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '14px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}
const btnNextStl = {
	height: 59,
	paddingTop: '3px',
	paddingLeft: '15px',
	paddingRight: '14px',
	border: '7px solid #e2f3dc',
	marginInline: 5,
	borderRadius: 29
}




const AnimationStiking = (animationProps: AnimationProps) => {
	const {nextStep } = animationProps
	const dispatch = useAppDispatch();
	const [step, setStep] = useState(0);
	const isMobile = useIsMobile();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const { t } = useTranslation();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [showButton, setShowButton] = useState(false)

	const buttonContentStl = {
		display: 'flex',
		justifyContent: 'center',
		marginLeft: isMobile ? '0 auto' : '11%',
		marginRight: isMobile ? '0 auto' : '7%',
		marginTop: isMobile?0:20
	}
	const btnLetsStl = {
		height: isMobile ? 41 : '',
		marginBottom: isMobile ? '15px' : '',
		marginRight: isMobile ? 20 : '',
		marginTop: isMobile ? 25 : ''
	}


	const data: any[] = [

		{
			id: 0,

			text: t('mentalHealth.animation1'),

		},
		{
			id: 1,

			text: t('mentalHealth.animation2'),

		},
		{
			id: 2,

			text: t('mentalHealth.animation3'),

		}, {
			id: 3,

			text: t('mentalHealth.animation4'),

		},
		{
			id: 4,

			text: t('mentalHealth.animation5'),

		}


	];

	const activateButton = () => {
		
		if (currentIndex >= data.length) return true

	}

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => prevIndex + 1);
		}, 2000);

		// Detener el intervalo cuando el componente se desmonta

		return () => clearInterval(interval);
	}, []);

	return (
		<Row style={{ display: 'flex', flexDirection: 'row', marginTop: 0 }}>
			<div style={{ display: 'flex', flexDirection: 'row', height: isMobile ? 370 : '' }}>
				<Row style={{ marginInline: isMobile ? '2%' : '11%' }}>



					{data.slice(0, currentIndex + 1).map((item, index) => (

						<Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingInline: isMobile ? '4' : '20%' }}>
							<KeraltyText
								bold type="subTitle" align="center" color='blue307'
								style={{ fontSize: isMobile ? '14px' : '18px' }} key={index}
							>
								{item.text}
							</KeraltyText>
						</Row>
					))}


					{activateButton() && !isMobile &&
					<Row style={buttonContentStl} >
						<KeraltyButton onClick={nextStep}
							style={btnLetsStl}
							fontWeight={ 600 }
						>
							{t('mentalHealth.done')}
						</KeraltyButton>
					</Row>}

					<KeraltyText align="left" type="info" bold
						color={'textBlueCounter'}
						style={{ fontSize: 15, paddingInline: isMobile ? '2%' : '10%', paddingTop: 25 }}>
						{t('mentalHealth.animationDesc')}
					</KeraltyText>
					{activateButton() && isMobile &&
					<Row style={buttonContentStl} >
						<KeraltyButton onClick={nextStep}
							style={btnLetsStl}
							fontWeight={ 600 }
						>
							{t('mentalHealth.done')}
						</KeraltyButton>
					</Row>}

				</Row>
			</div>

		</Row>
	);
};

export default AnimationStiking;
