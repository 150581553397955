import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IntercomChatDataTypes } from './entities';
import { RootState } from '../store';
import i18n from 'i18next';

const initialState: IntercomChatDataTypes = {
	intercomProps: {
		name: null,
		email: null,
		actionColor: undefined,
		backgroundColor: undefined,
		languageOverride: i18n.language?.includes('en') ? 'en' : 'es',
		customAttributes: {}
	},
	intercomState: {
		isSetted: false,
		isShow: false,
		isInitialized: false,
	}
}

export const openIntercomChat = createAsyncThunk(
	'intercomChat/initChat',
	async (payload: IntercomChatDataTypes['intercomProps']) => {
		const state: IntercomChatDataTypes = initialState;

		state.intercomState.isSetted = true;
		state.intercomProps = { ...payload };

		return state;
	}
);

export const IntercomChatSlice = createSlice({
	name: 'intercomChat',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		shutdown: (state) => {
			state.intercomProps = { ...initialState.intercomProps };
			state.intercomState.isShow = false;
			state.intercomState.isSetted = false;
			state.intercomState.isInitialized = false;
		},
		show: (state) => {
			state.intercomState.isShow = true;
		},
		setUp: (state, action: any) => {
			const lng = i18n.language.includes('es') ? 'es' : 'en';
			state.intercomState.isSetted = true;

			if (action.payload) {
				const currentProps = !action.payload?.languageOverride ? { ...action.payload, languageOverride: lng } : { ...action.payload };
				state.intercomProps = currentProps;
			}
		},
		hide: (state) => {
			state.intercomState.isShow = false;
			state.intercomState.isInitialized = true;
		},
		setPayload: (state, action: any) => {
			state.intercomProps = { ...action.payload };
		},
		setStateIntercom: (state, action: any) => {
			state.intercomState = {...state.intercomState, isShow: action.payload.isShow};
		},
	},
});

export const { show, hide, setUp, shutdown, setPayload, setStateIntercom } = IntercomChatSlice.actions;

export const intercomChat = (state: RootState) => state.intercomChat;

export default IntercomChatSlice.reducer;
