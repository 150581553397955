const nsHipaaForm = {
  en: {
    patientHIPAA: 'Patient HIPAA Acknowledgement',
    patientHIPAAs: 'Patient HIPAA Acknowledgement - Healthcare students',
    patientHIPAA2nd: 'Patient HIPAA Acknowledgement',
    patientHIPAAs2nd: 'Patient HIPAA Acknowledgement - Healthcare students',
    name: 'Patient name',
    noticePrivacy:
      "Notice of Privacy Practice. I acknowledge that I have received the practice's Notice of Privacy Practice, which describes the ways in which the practice may use and disclose my healthcare information for its treatment, payment, healthcare operations and other described and permitted uses and disclosures. I understand that I may contact the office if I have a question or complaint. To the extent permitted by law, I consent to the use and disclosure of my information for the purposes described in the practice's Notice of Privacy Practices.",
    releaseInfo:
      'Release of Information. I hereby permit the practice and the physicians or other health professionals involved in my care to release healthcare information for the purposes of treatment, payment, or healthcare operations, or as allowed by law.',
    medicalStudents:
      "Sanitas is proud to be an Academic Center for healthcare students. With your consent, as part of the program, a student may be invited to speak to you about your visit and/or overall health before you are seen by your physician. Critical to the experience is the awareness and education of the importance of patient's privacy and confidentiality.",
    medicalStudents2: "As a result, all students participating in this program will have completed HIPAA compliance training, signed an agreement to adhere to the Sanitas Code of Conduct, and will have signed an agreement of confidentiality, prior to commencing, as to ensure that your patient rights are protected.",
    studentsAgreement:
      'As a result, all students participating in this program will have completed HIPAA compliance training, signed and agreement to adhere to the Sanitas Code of Conduct, and will have signed an agreement of confidentiality, prior to commencing, as to ensure that your patient rights are protected.',
  },
  es: {
    patientHIPAA: 'Conocimiento del paciente del HIPAA',
    patientHIPAAs: 'Conocimiento del paciente del HIPAA - estudiantes de salud',
    patientHIPAA2nd: 'Conocimiento de cláusulas HIPAA',
    patientHIPAAs2nd: 'Conocimiento de cláusulas HIPAA - estudiantes de salud',
    name: 'Nombre del paciente',
    medicalStudents2: "",
    noticePrivacy:
      'Notificación de práctica privada. Reconozco que he recibido aviso de las prácticas de privacidad, las cuales describen la forma en que debe ser utilizada y divulgada mi información de salud para tratamiento, pago, administración del cuidado de la salud y otros usos o divulgaciones permitidas y descritas. Entiendo que puedo comunicarme con la oficina si tengo una pregunta o queja. En la medida que la ley lo permita, autorizo el uso y divulgación de mi información para los propósitos descritos en la Notificación de prácticas de privacidad de la práctica médica.',
    releaseInfo:
      'La divulgación de la información. Autorizo a Sanitas y a los médicos u otros profesionales de la salud involucrados en mi cuidado de salud a divulgar información sobre elcuidado de salud para propósitos pertinentes como tratamiento, pago o atención médica.',
    medicalStudents:
      'Sanitas se enorgullece de ser un centro académico para estudiantes de ciencias de la salud. Con tu aprobación, como parte de su programa de formación, un estudiante puede ser invitado para hablarte sobre tu consulta médica o sobre tu salud en general antes de ver a tu doctor. Es importante para esta experiencia el conocimiento y educación sobre la importancia de la privacidad y confidencialidad de la información del paciente. Teniendo en cuenta lo anterior y para garantizar que tus derechos como paciente sean protegidos, todos los estudiantes que participan en este programa han completado el formulario de cumplimiento HIPAA, y han firmado el acuerdo para adherirse al Código de Conducta de Sanitas y el acuerdo de confidencialidad.',
    studentsAgreement:
      'Teniendo en cuenta lo anterior y para garantizar que tus derechos como paciente sean protegidos, todos los estudiantes que participan en este programa han completado el formulario de cumplimiento HIPAA, y han firmado el acuerdo para adherirse al Código de Conducta de Sanitas y el acuerdo de confidencialidad.',
  },
};

export default nsHipaaForm;
