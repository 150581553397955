import isCordova from '../../../cordova';
import useIsMobile from '../../hooks/useIsMobile';
import styled from 'styled-components';
import { HashRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import { Fragment } from 'react';

interface Props {
  className?: string;
}

const SimpleClassWrapper: React.FC<Props> = ({ className, children }) => (
  <div className={`cordova ${className}`}>{children} </div>
);

export const StyledCordobaWrapper = styled(SimpleClassWrapper)``;

const CordovaWrapper = ({ children }) => {
  const isMobile = useIsMobile();
  const AppWrapper = isMobile ? StyledCordobaWrapper : Fragment;

  return isCordova ? (
    <StyledCordobaWrapper>
      <HashRouter>{children}</HashRouter>
    </StyledCordobaWrapper>
  ) : (
    <AppWrapper>
      <BrowserRouter>{children}</BrowserRouter>
    </AppWrapper>
  );
};

export default CordovaWrapper;
