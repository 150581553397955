import { createSlice } from "@reduxjs/toolkit";
import { SystemState } from "./system.state";
import sessionStorage from 'redux-persist/lib/storage/session'
import { encryptTransform } from "redux-persist-transform-encrypt";
import appConfig from 'config';

const stateName = 'system'

const initialState: SystemState = {
    currentServerDate: {
		day: 0,
		hour: 0,
		minute: 0,
	}
};

export const persistSystemConfig = {
	key: stateName,
	storage: sessionStorage,
	whitelist: ['currentServerDate'],
	transforms: [
		encryptTransform({
			secretKey: appConfig.KERALTY_SWS_KEY,
		  	onError: function (error) {
				// Handle the error.
		  	},
		}),
	],
};

const SystemConfigSlice = createSlice({
	name: stateName,
	initialState,
	reducers: {
		updateSystemConfig: (state, { payload }: { payload: SystemState; type: string;}) => {
			return {
				...state,
				currentServerDate: payload.currentServerDate
			}
		},
		resetCurrentServerDate: (state) => {
			return {
				...state,
				currentServerDate: {
					day: 0,
					hour: 0,
					minute: 0,
				}
			}
		},
		resetSystemConfig: () => {
			return initialState;
		}
	}
});

export const { updateSystemConfig, resetSystemConfig, resetCurrentServerDate } = SystemConfigSlice.actions;

export default SystemConfigSlice.reducer;
