import KeraltyText from './keraltyText';
import { ReactComponent as EmptyIconSvg } from '../../assets/icons/emptyGroup.svg'
import styled from '../../ui-core/styled-components';

type Props = {
  text?: string;
  title?:string;
}

const EmptyIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & svg {
    margin-bottom: 1rem;
  }
`;

const EmptyIcon: React.FC<Props> = ({ text, title  }) => {
  return (
    <EmptyIconWrapper>
      <EmptyIconSvg />
	  <KeraltyText type="subTitle" bold style={{color:'#5B5C5B'}}>
		{title}
      </KeraltyText>
      <KeraltyText type="subTitle" style={{color:'#5B5C5B'}} >
        {text}
      </KeraltyText>


    </EmptyIconWrapper>
  )
}

export default EmptyIcon;
