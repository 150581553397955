import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectSelf = (state: RootState): any => state.myAccount

export const selectmyAccountError = createSelector(selectSelf, ({ error }) => error?.message);
export const selectmyAccountLoading = createSelector(selectSelf, ({ isLoading }) => isLoading);
export const selectmyAccount = createSelector(selectSelf, ({ data }) => data);

export const selectmyAccountInsuranceInfo = createSelector(selectSelf, ({ data }) => data?.insuranceInfo);
export const selectmyAccountEmergencyContactInfo = createSelector(
  (state: RootState) => ({ myAccount: state.myAccount }),
  ({ myAccount }) => ({
    ...(myAccount?.data?.emergencyContactInfo || {}),
  })
);
export const selectmyAccountPersonalInfo = createSelector(
  (state: RootState) => ({ myAccount: state.myAccount, user: state.user }),
  ({ myAccount, user }) => ({
    ...(myAccount.personalInfo || {}),
    ...(user || {}),
    sexAssigned: myAccount?.personalInfo?.sexAssigned || user?.sex
  })
)

export const selectmyAccountVisitSummaryInfo = createSelector(
  (state: RootState) => ({ myHealth: state.myHealth }),
  ({ myHealth }) => (myHealth.data ? myHealth.data['visit-summary'] : []) || []
);
