import styled from '../../ui-core/styled-components';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';

interface ColumnProps {
	lg?: number;
	md?: number | 'auto';
	sm?: number | 'auto';
	auto?: boolean;
	noPadding?: boolean;
	full?: boolean;
	adaptable?: boolean;
	show?: string | undefined | boolean;
	hidden?: boolean;
	txtCenter?: boolean;
}

const Column = styled.div<ColumnProps>`
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: ${({ sm, noPadding }) => sm ?
		(sm === 'auto' ? 'auto' : `calc(${100 / 12 * sm}% - ${noPadding ? '0px' : '2rem'})`)
		: '100%'};
		${({ sm }) => sm === 0 ? 'display: none !important;' : ''}
	}

	@media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
			width: ${({ md, noPadding }) => md ?
		(md === 'auto' ? 'auto' : `calc(${100 / 12 * md}% - ${noPadding ? '0px' : '2rem'})`)
		: '100%'};
		${({ md }) => md === 0 ? 'display: none !important;' : ''}
	}

	@media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
		width: ${({ lg, noPadding }) => lg ?
		`calc(${100 / 12 * lg}% - ${noPadding ? '0px' : '2rem'})`
		: 'auto'};
		${({ lg }) => lg === 0 ? 'display: none !important;' : ''}
	}


	${({ auto }) => auto ? 'width: auto !important;' : ''}

	${({ noPadding }) => noPadding ? '' : 'padding: 0px 1rem;'}
	${({ hidden }) => hidden ? 'display: none !important;' : ''}
	${({ full }) => full ? 'width: 100%;' : ''}
`;

export default Column;
