import PublicHttpClient from "../publicHttpClient";
import { ValidationService } from "domain/useCases/contracts/validationsService";
import appConfig from 'config';
import PrivateHttpClient, { PrivateHttpClient2 } from "../privateHttpClient";

const service = 'core';

const ValidationsRepository: ValidationService = {
    loginValidation: async () => {
		const body = { 
			password: appConfig.PUBLIC_VERSION_PSSWRD, 
			user: appConfig.PUBLIC_VERSION_USER 
		};
		
		return await PublicHttpClient.post(`${service}/opt-ins/authenticate`, body);
	},
	emailValidation: async (domain: string) => {
		return await PublicHttpClient.get(`${service}/general/verifyDomain/${domain}`);
	},
	timeValidation: async (accessToken: string) => {
		PublicHttpClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
		return await PublicHttpClient.get(`${service}/general/serverDate`);
	},
};

export default ValidationsRepository;