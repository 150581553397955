const nsLocationSelect = {
    en: {
        preLoginTitle: "Welcome",
        preLoginSubTitle: "Select the state you live in",
        continueButtonText: "Continue", 
    },
    es: {
        preLoginTitle: "Bienvenidos",
        preLoginSubTitle: "Selecciona el estado en el que vives",
        continueButtonText: "Continuar", 
    }
};

export default nsLocationSelect;