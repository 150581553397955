import { useParams, Redirect } from 'react-router-dom';
import ROUTES from '../ui-core/utils/routes';


const { MAIN, FORGOT } = ROUTES;

const ForgotPasswordGuard = ({ children }) => {
  const { step } = useParams();

  //@TODO: Validate forgot form
  return step ? children : <Redirect to={`/${MAIN.forgot}/${FORGOT.recover}`}/>
};

export default ForgotPasswordGuard;