
import { ValidInsurance as ValidInsuranceEntity, IBasePayload, Init } from '../../domain/entities/paymentInfo';
import { PaymentService } from "./contracts/paymentService";



/**
 * Insurance info validation
 */
export const init = (paymentServices: PaymentService, data: Init) => {
  return paymentServices.init(data);
};

export const createTransactionBase = (paymentServices: PaymentService, data: Init) => {
  return paymentServices.createTransactionBase(data);
};

export const checkTransactionStatus = (paymentServices: PaymentService, sessionId: string) => {
  return paymentServices.checkTransactionStatus(sessionId);
};

export const getUrlStripe = (paymentServices: PaymentService, transactionId: number) => {
  return paymentServices.getUrlStripe(transactionId);
};

export const notifyBySms = (paymentServices: PaymentService, data: IBasePayload) => {
  return paymentServices.notifyBySms(data);
};

export const cancelUserTransaction = (paymentServices: PaymentService, data: Omit<IBasePayload, 'check'>) => {
  return paymentServices.cancelUserTransaction(data);
};

export const validateInsurance = (paymentServices: PaymentService, data: ValidInsuranceEntity) => {
  return paymentServices.validateInsurance(data);
};

const paymentUseCases = {
    init,
    createTransactionBase,
    checkTransactionStatus,
    getUrlStripe,
    notifyBySms,
    cancelUserTransaction,
    validateInsurance,
}

export default paymentUseCases;
