import styled from '../../ui-core/styled-components';

interface CenteredWrapperProps {
	direction?: 'horizontal' | 'vertical';
}

const CenteredWrapper = styled.div<CenteredWrapperProps>`
	display: flex;
	${({ direction }) => direction ? (
		direction === 'horizontal' ? `
			width: 100%;
			justify-content: center;
			height: auto;
		` : `
			height: 100%;
			align-items: center;
			width: auto;
		`
	) : `
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	`}
`;

export default CenteredWrapper;
