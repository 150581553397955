import appConfig from 'config';
import AuthRepository from '../../infrastructure/keraltyApi/repositories/auth';
// DOMAIN
import { HealtLibraryService } from '../../domain/useCases/contracts/HealtLibraryService';

const HealtLibraryRepository: HealtLibraryService = {
  load: ({ lang = 'en', ...userData }) => {
    const {
      THEME: theme,
      EN,
      ES,
    } = appConfig.HEALTH;

    const { PROCEDURE_ID: procedureId } = !lang || lang !== 'es' ? EN : ES;

    const loadWidget = (token) => {
      // @ts-ignore
      return SenselyWidget.init({
        // Sensely provided username & password (not required if using token)
        theme,
        token,
        procedureId,
        lang,
        // Optional additional data to control the conversation
        conversationData: {
          userInfo: {
            userType: 'nonpublic',
            memberid: userData.idEcw || '0',
            dob: userData.birthdate,
            gender: userData.sex,
            organization_user_id: userData.authUid,
            // ...userData,
          },
        },
      }).catch((err) => {
        console.error('HealtLibrary Sensely', err);
        return {
          name: 'HealtLibrary Load Failure',
          message: 'HealtLibrarytLoad',
          code: 92, // TODO: MAP code92 error
        };
      });
    };

    return AuthRepository.senselyAuthentication({ locale: lang })
      .then((data) => {
        const { access_token: token } = data;
        return loadWidget(token);
      })
      .catch(() => {
        loadWidget('');
      });
  },
};

export default HealtLibraryRepository;

// -------------------------------------------------------
// "memberid": "0",
// "userType": "nonpublic",
// "dlgflowProject": "usa-kdc-dev-dialogflow-a403",
// "dlgflowEnv": "keralty-dev",
// "dlgflowRegion": "us-east1",
// "dlgflowSymptomAgentID": "2671f595-b277-4206-ac2a-32796e78598a"

// -------------------------------------------------------
// ...(payload.userInfo || {}),
// usertype: !payload.memberid ? 'public' : 'nonpublic',
// memberid: !payload.memberid ? "0" : payload.memberid,
// dlgflowEnv: "keralty_dev",
// dlgflowProject: "usa-kdc-dev-dialogflow-a403",
// dlgflowRegion: "us-east1",
// dlgflowSymptomAgentID: "2671f595-b277-4206-ac2a-32796e78598a",
