import { UserIdentifier as UserIdentifierEntity } from '../../../domain/entities/userIdentifier';
import { ResetPassword as ResetPasswordEntity } from '../../../domain/entities/resetPassword';
import { NewPasswordDTO, UserIdentifierDTO } from '../models/forgotPasswordModel';
import moment from 'moment';
import FORMATS from '../../../app/ui-core/utils/formats';

/**
 * Map Domain Objects to Data Transfer Objects expected by API
 */
const forgotMapper = {
  mapToUserIdentifier: (domainObject: UserIdentifierEntity): UserIdentifierDTO => {
    const { mobile, dateOfBirth, email, state } = domainObject;

    return {
      mobile,
      dateOfBirth:moment(dateOfBirth).format(FORMATS.dateISO8601),
      email,
      state,
    };
  },
  mapToNewPassword: (domainObject: ResetPasswordEntity): NewPasswordDTO => {
    const { password, idBelongState, code } = domainObject;
    return {
      pass: password,
      idBelongState, 
      code
    };
  },
};

export default forgotMapper;
