import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'adapter/hooks';

import { openSymptomChecker } from 'adapter/chat/chatSlice';

const ChatPage = () => {
	const location = useLocation();
	// const [minimized, setMinimized] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const systemLang = useAppSelector((state) => state.chat.lng);
	const symptomCheckState = useAppSelector((state) => state.chat.symptomChecker);
	const { isOpen: chatIsOpen } = symptomCheckState;
	const isOpeningFromApp = location.pathname === '/chat';

	//   const language = useAppSelector((state) => state.chat.lng);

	const { isOpen, isLoading } = symptomCheckState;

	useEffect(() => {
		if (isOpeningFromApp && !chatIsOpen) {
			dispatch(openSymptomChecker({ isOpen: true, isPublic: true, lng: systemLang }))
		}
	}, [chatIsOpen, isOpeningFromApp, dispatch, systemLang]);

	return (
		<div>
			<div
				style={{
					display: !isOpen && isLoading ? 'none' : 'block',
					height: window.innerHeight,
				}}
				id="sensely-widget"
			></div>
		</div>
	);
};

export default ChatPage;
