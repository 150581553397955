import { useTranslation } from 'react-i18next';
import Lifestyle from '../../../assets/icons/lifestyle.svg';
import OtherresoucesIcon from '../../../assets/icons/Otherresouces.svg';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import styled from 'app/ui-core/styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { CenteredWrapper, Column, Loader, Row, SpaceWrapper, Container, IconLinkRow } from 'app/components/templates';
import { Image, KeraltyButton, KeraltyText, KeraltyIcon } from 'app/components/atoms';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import chatSelectors from 'adapter/chat/chatSelectors';
import { SectionWidget } from 'app/components/molecules';
import theme from 'app/ui-core/utils/theme';
import { KeraltyModal } from 'app/components/molecules';
import { useCallback, useState } from 'react';
import ROUTES from 'app/ui-core/utils/routes';
import i18n from 'app/ui-core/i18n';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import OtherResources from './OtherResources';
import useIsMobile from '../../../hooks/useIsMobile';
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker, showChat } from '../../../../adapter/chat/chatSlice';
import { chatActions } from '../../../../adapter/chat/chatSlice';



const RightContent = styled(Row)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0;
  }
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    margin-top: 5vh;
    padding-inline: 15px;
  }
`;

const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  height: auto;
  width: 24.5rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  align-items: flex-start;
  padding-top: 10px !important;
  display: flex;
  h2 {
    margin: 0rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

const OptionColumn = styled(Column)`
  padding-right: 2px;
  padding-left: 2px;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const CardsRow = styled(Row)`
  justify-content: center;
  padding: 0rem;
`;


const styleIcon = {
	margin: 20,
	flex: 1,
	align: "center",
  }



const EducationalResouces = () => {

	const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;
	const { t } = useTranslation();
    const isLoading = useAppSelector(chatSelectors.selectIsLoading);
    const { goBack, push } = useHistory();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { subTab, subSubTab } = useParams();
    const lang = i18n.language?.includes('en') ? 'en' : 'es';
    const [showDisclaimer, setshowDisclaimer] = useState(false);
    const isMobile = useIsMobile();
    const chatState = useAppSelector((state) => state.chat.symptomChecker);
    const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
    const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
    const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
    const dispatch = useAppDispatch();
  
    const triggerLifeStyleLib = useCallback(() => {
      dispatch(sanitasAdapterMethods.shutdown());
      if (isSCOpen || isHLOpen) dispatch(closeSymptomChecker());
      if ((!chatState.isChatOpen && !chatState.isInitialized) || isSCOpen || isHLOpen || isVAOpen ) {
        dispatch(chatActions.openLifeStyleLib());
      } else {
        dispatch(showChat());
      }
    }, [dispatch, isSCOpen, chatState, isHLOpen, isVAOpen]);

    const goToLifestyle = () => {
      setshowDisclaimer(true);
    };

    const goToOtherResources = () => {
		  push(`/${MAIN.mentalHealth}/${CURRENT.edures}/${CURRENT.otherResources}`);
    };

    const ChilderPopup = () => {
      return (
        <Row>
        <Row noMargin style={{ paddingRight: 20, paddingLeft: 10, justifyContent: 'center' }}>
          <div>
          <KeraltyIcon
            iconClass="fas	fa-exclamation"
            color="orange"
            size="medium"
            style={styleIcon}
            rounded
          />
          </div>
          <SpaceWrapper mb={1}>
          <Container centered={'x'} noPadding noMargin style={{ paddingRigth: 25, marginTop: 10 }}>
            <KeraltyText
            type={'label'}
            align={'center'}
            style={{ fontSize: isMobile ? '20px' : '24px', paddingBottom: 25, color: "#022F58" }}
            >
            {t('mentalHealth.disclaimerTitle')}
            </KeraltyText>
            <KeraltyText
            type={'label'}
            align={'center'}
            fontWeight={'400'}
            style={{
              fontSize: isMobile ? '16px' : '20px',
              lineHeight: isMobile ? '18px' : '21.5px',
            }}
            >
            {t('mentalHealth.disclaimerOtherRes')}
            </KeraltyText>
          </Container>
          </SpaceWrapper>
        </Row>
        <Container centered={'x'} noPadding noMargin style={{ paddingRigt: 25 }}>
          <KeraltyText
          bold
          type={'link'}
          align="center"
          color={isMobile ? "blueAction" : "#3CA70D"}
          style={{
            textTransform: 'uppercase',
            paddingTop: 25,
            paddingBottom: 10,
            fontSize: isMobile ? '18px' : '16px',
            fontWeight: 700
          }}
          onClick={() => {setshowDisclaimer(false); triggerLifeStyleLib();}}
          >
          {t('mentalHealth.close')}
          </KeraltyText>
        </Container>
        </Row>
      );
      }
  
    return (
        <>
          {isLoading && <Loader />}
          <RightContent
            fullHeight
            style={{
              margin: '1rem 0 5rem 0'
                /* !subTab || (subTab === CURRENT.otherResources && subSubTab === undefined)
                  ? '1rem 0 5rem 0'
                  : '0 0 5rem 0', */
            }}
          >
            {showDisclaimer && <KeraltyModal style={{width: isMobile ? '70%' : '689px'}} ><ChilderPopup/></KeraltyModal>}
            {!subTab && (
              <>      <div style={{ marginLeft: '1%', width: '100%' }}>
              <IconLinkRow
                onClick={goBack}
                color="accent"
                icon={arrowAltCircleLeftIcon}
                iconAlt={t('imgNicons.backArrow')}
                text={t('routes.back')}
              />
            </div>



            <Row>
            <TitleContiner lg={12}>
                <KeraltyText type="title" align={isMobile ? 'left' : 'center'}  style={{ textTransform: 'capitalize' }}>
                {t('mentalHealth.educational')}
                </KeraltyText>
            </TitleContiner>
            </Row>
           
                <CardsRow noMargin>
                  <Column lg={0} md={0} sm={12}>
                    <CenteredWrapper>
                      <Card onClick={goToLifestyle}>
                        <SectionWidget
                          img={Lifestyle}
                          text={t('mentalHealth.lifestyle')}
                          description={t('mentalHealth.lifestyleText')}
                        />
                      </Card>
                    </CenteredWrapper>
                  </Column>
                  <OptionColumn lg={6} md={6} sm={0}>
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <GetCareNowButton
                        color="light"
                        squareRadius={true}
                        onClick={goToLifestyle}
                        style={{ minHeight: lang ? '12.75rem' : '18.75rem', minWidth: '340px', wordBreak: 'break-word' }}
                      >
                        <span>
                          <Image
                            src={Lifestyle}
                            width={theme.sizes.icon.extraBig}
                            height={theme.sizes.icon.extraBig}
                          />
                          <KeraltyText
                            type="title"
                            fontWeight={'400'}
                            style={{ fontWeight: 'normal', fontSize: '16px' }}
                            noTextTransform
                          >
                            {t('mentalHealth.lifestyle')}
                          </KeraltyText>
                          <KeraltyText
                            type="paragraph"
                            color={'#747474'}
                            style={{
                              color: '#7A7A7A',
                              fontSize: '14px',
                              lineHeight: '18px',
                              fontWeight: '400',
                              margin: '5px 0 10px 0'
                            }}
                          >
                            {t('mentalHealth.lifestyleText')}
                          </KeraltyText>
                        </span>
                      </GetCareNowButton>
                    </Row>
                  </OptionColumn>
    
                  <Column lg={0} md={0} sm={12} style={{ marginTop: 15, marginBottom: 20 }}>
                    <CenteredWrapper>
                      <Card onClick={goToOtherResources}>
                        <SectionWidget
                          img={OtherresoucesIcon}
                          text={t('mentalHealth.otherResources')}
                          description={t('mentalHealth.otherResourcesText')}
                        />
                      </Card>
                    </CenteredWrapper>
                  </Column>
                  <OptionColumn lg={6} md={6} sm={0}>
                    <Row>
                      <GetCareNowButton
                        color="light"
                        squareRadius={true}
                        onClick={goToOtherResources}
                        style={{ minHeight: lang ? '12.75rem' : '18.75rem', minWidth: '340px', wordBreak: 'break-word' }}
                      >
                        <span>
                          <Image
                            src={OtherresoucesIcon}
                            width={theme.sizes.icon.extraBig}
                            height={theme.sizes.icon.extraBig}
                          />
                          <KeraltyText
                            type="title"
                            fontWeight={'400'}
                            style={{ fontWeight: 'normal', fontSize: '16px' }}
                            noTextTransform
                          >
                            {t('mentalHealth.otherResources')}
                          </KeraltyText>
                          <KeraltyText
                            type="paragraph"
                            color={'#747474'}
                            style={{
                              color: '#7A7A7A',
                              fontSize: '14px',
                              lineHeight: '18px',
                              fontWeight: '400',
                              margin: '5px 0 10px 0'
                            }}
                          >
                            {t('mentalHealth.otherResourcesText')}
                          </KeraltyText>
                        </span>
                      </GetCareNowButton>
                    </Row>
                  </OptionColumn>
                </CardsRow>
              </>
            )}
    
            {subTab === CURRENT.otherResources && subSubTab === undefined && <OtherResources />}
          </RightContent>
        </>
      );

	
}

export default EducationalResouces;