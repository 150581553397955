import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';

type IndicatorType = {
	/** Color to be used when value match regex */
	color: keyof (typeof theme.colors);
	/** Whether match or not */
	isActive: boolean;
}

export const Indicator = styled.div<IndicatorType>`
	width: 3.1rem;
	height: .4rem;
	margin-right: .8rem;
	background-color: ${({ color, isActive, theme }) => isActive ? 
		theme.colors[color] : 
		theme.colors.gray
	}
`;

type BarsIndicatorProps = {
	indicatorRules: Array<{
		color: keyof (typeof theme.colors);
		label: string;
		regex: RegExp;
	}>;
	value: string;
};

const BarsWrapper = styled.div`
	display: flex;
	justify-content: left;
	margin: .5rem 0 1.5rem;
`;

const KeraltyBarsIndicator: React.FC<BarsIndicatorProps> = ({ indicatorRules, value }) => (
	<BarsWrapper>
		{indicatorRules.map(({ color, label, regex }) => (
			<Indicator
				key={label}
				color={color} 
				isActive={regex.test(value)}
			/>
		))}
	</BarsWrapper>
);

export default KeraltyBarsIndicator;
