const nsGetCare = {
	en: {
		status: 'Status',
		status2: 'Scheduled',
		status3: 'Cancelled',
		getCare: 'FLIES',
		getCareNow: 'Get care now',
		appointments: 'Appointments',
		name: 'Name',
		dosage: 'Dosage',
		location: 'Location',
		noAppointments: 'No appointments found',
		bookAppointment: 'Book Appointment',
		bookAppointmentC: 'BOOK AN APPOINTMENT',
		bookAppointmentDesc: 'Schedule a visit for you with a physician or behavioral health counselor.',
		upcomingAppointments: 'Upcoming appointments',
		goToVirtualAppointment: "GO TO VIRTUAL APPOINTMENT",
		cancelAppointment: "CANCEL APPOINTMENT",
		cancelAppointmentError: "There has been an error. Please try again later.",
		cancelAppointmentConfirmation: "Do you want to cancel the appointment?",
		cancelAppointmentConfirmation2: "The appointment has been cancelled successfully",
		upcomingAppointmentsDesc: 'Check your upcoming scheduled appointments',
		prevAppointments: 'Previous appointments',
		prevAppointmentsDesc: 'Check the history of your previously scheduled appointments.',
		talkToADoctor: 'Talk to a doctor',
		carePrograms: 'Care programs',
		symptomsChecker: 'Check your symptoms',
		provider: 'Provider',
		time: 'Time',
		date: 'Date',
		url:'URL',
		goToUrl:'Go to virtual appointment',
		televisit: {
			televisitForm: {
				firstName: 'First name*',
				lastName: 'Last name*',
				birthdate: 'Date of Birth*',
				memberId: 'Member ID*',
				memberFieldMessage: 'If you don’t know the Member ID, please contact our patient support chat.'
			},
			modalTitleTelevisit: "Are you sure you want to cancel your appointment?",
			btnYesModalTitleTelevisit: "Yes",
			btnNoModalTitleTelevisit: "No",

			modalTitleTimeTelevisitOne: "The time to cancel this appointment",
			modalTitleTimeTelevisitTwo: "has expired",
			btnNoModalTitleTimeTelevisitOne: "Re- schedule",
			btnYesModalTitleTimeTelevisitOne: "Close",

			modalTitleSuccessTelevisit: "The appointment has been cancelled successfully",
			btnYesModalTitleSuccessTelevisit: "ok",

			modalTitleErrorTelevisit: "There has been an error. Please try again later.",
			sorry: "Sorry, the televisit link has expired due to the exceeded time limit.",
			modalTitleErrorOcurredTelevisit: "An error has ocurred",
			modalSubTitleErrorOcurredTelevisit: "It’s not possible to cancel this appointment",

			titleAvailableAppointmentsOne: "Your link to access to your televisit will be available",
			titleAvailableAppointmentsTwo: "15 minutes before your appointment",
			titleCancelAppointments: "Cancel appointment not available",

			televistSubTitle: 'In order to continue, please confirm the following information according to your Insurer ID Card.',

			transactionApproved: 'Your transaction is approved',
			transactionDetails: 'Transaction details',
			transactionID: 'Transaction ID: ',
			transactionState: 'Payment Status: ',
			transactionDate: 'Payment Date: ',
			transactionNumber: 'Payment Code: ',
			transactionTotal: 'Total Payment: ',
			transactTotal: 'Total: ',

			transactInsuranceNoCoverage: 'We could not validate your insurance information',
			transactInsNoCoverSubtitle: 'Please validate your coverage with your insurance company or proceed with the service full fee',
			transactionContinue: 'Continue to payment',
			transactGoInsurance: 'Go to my Insurance',
			transactSupport: 'If you have a problem, please contact ',
			transactBack: 'Return to previous menu',
			previousTransaction:'It seems that you have a transaction previously created',
			wantContinue:'Do you want to continue? ',
			continue:'Continue with the same transaction',
			createNew:'Create a new one',
			cantContinue:"We can't continue with a new payment. ",
			pleaseWait:'Please wait until its status changes to perform this action.',
			accept:'accept',
			notSanitasProvider:"Your PCP is not a Sanita's Provider",
			ableToGo:'You are able to go to validate your insurance information, contact our Support Chat or Video Call full fee',
			tansacciornProcess:'It seems that you have a transaction in process',
			waitUntil:'Please wait until the transaction is completed',
			transactionCanceled:'The transaction was canceled successfully. ',
			cannotCancel:'You cannot cancel this transaction',
			pleaseWairUntil:'Please wait until the transaction is completed. If you have any questions, please contact',
			transactPending: 'Your transaction is pending',
			transactPendingSubtitle: 'Your payment is pending. You can wait on this page for the transaction results or return in a few minutes to this functionality and verify if you can access it',
			noCopaymentTitle:'The videocall service may apply charges',
			noCopaymentText1:'You can access now. However a cost may be applied later.',
			noCopaymentText2:'For more information, contact your insurance',
			noCopaymentButton:'CONTINUE',
			noCopaymentSub:'Return to previous menu',
			name: 'Name',
			lastname: 'Last name',
			city: 'City',
			state: 'State',
			email: 'Email',
			phonenumber: 'Phone number',
			service: 'Service',
			cost: 'Cost: ',
			quantity: 'Quantity: ',
			subtotal: 'Subtotal: ',
			total: 'Total: ',

			transactionExpired: 'Your transaction expired',
			transactionExpiredSubtitle: 'We’re sorry! The time to complete your payment has expired. Please try again by going back to the selected service and repeat the payment process.',
			expired: 'EXPIRED',

			approved: 'APPROVED',
			pending: 'PENDING',

			transactionRejected: 'Your transaction was rejected',
			transactionRejectedSubtitle: 'We’re sorry! Your payment was rejected. Please verify your payment method, go back to the selected service and repeat the payment process.',
			stateRejected: 'REJECTED',
			insuranceInactived: 'Your insurance seems to be inactive',
			insuranceInactivedSubtitle: 'Please validate your status with your insurance company or proceed with the service full fee',
			patientSupport: "Patient support",
			notifyViaSms: 'Notify me via SMS when the payment changes its status',
			goToVideoCall: 'GO TO VIDEOCALL WITH A DOCTOR',
			errorTransactionCompleted: "We can't continue with the payment because it was already completed",
			linkError: "There has been an issue with your televisit link. Please contact Patient Support."

		},
		ChatWithDoc: {
			tChatWithDocForm: {
				firstName: 'First name*',
				lastName: 'Last name*',
				birthdate: 'Date of Birth*',
				memberId: 'Member ID*',
				memberFieldMessage: 'If you don’t know the Member ID, please contact our patient support chat.'
			},
			title:'Chat with a doctor',
			subTitle: 'In order to continue, please confirm the following information according to your Insurer ID Card.',
			insuranceTitleInactive: 'Your insurance seems to be inactive',
			insuranceTextInactive: 'Please validate your insurance status with your insurance company.',
			insuranceBtnGoTo: 'GO TO MY INSURANCE',
			textSupport: 'If you have a problem, please contact ',
			linkSupport: "Patient support",
			goBack: 'Return to previous menu',
			insuranceTitleNoCoverage: 'We could not validate your insurance information',
			insuranceTextNoCoverage: 'Please validate your coverage with your insurance company.',
			insuranceTitleNoPCP: "Your PCP is not a Sanita's Provider",
			insuranceTextNoPCP: 'You are able to go to validate your insurance information or contact our Support Chat.',
			textMeaningPCP: '*PCP means Primary care provider'
		}
	},
	es: {
		status: 'Estado',
		status2: 'Agendada',
		status3: 'Cancelada',
		getCare: 'Atención',
		getCareNow: 'Atención inmediata',
		appointments: 'Citas',
		name: 'Nombre',
		dosage: 'Dosis',
		location: 'Ubicación',
		noAppointments: 'No se encontraron citas',
		bookAppointment: 'Agenda una cita',
		bookAppointmentC: 'AGENDAR UNA CITA',
		bookAppointmentDesc: 'Programa tu cita con un médico o un consejero de salud conductual',
		upcomingAppointments: 'Próximas citas',
		goToVirtualAppointment: "IR A CITA VIRTUAL",
		cancelAppointment: "CANCELAR CITA",
		cancelAppointmentError: "Ha ocurrido un error. Por favor inténtalo más tarde.",
		cancelAppointmentConfirmation: "¿Estás seguro que deseas cancelar tu cita?",
		cancelAppointmentConfirmation2: "Tu cita ha sido cancelada exitosamente",
		upcomingAppointmentsDesc: 'Revisa tus próximas citas programadas',
		prevAppointments: 'Citas previas',
		prevAppointmentsDesc: 'Revisa el historial de tus anteriores citas programadas',
		talkToADoctor: 'Hablar con un médico',
		carePrograms: 'Programas de atención',
		symptomsChecker: 'Chequea tus síntomas',
		provider: 'Proveedor',
		time: 'Hora',
		date: 'Fecha',
		url:'URL',
		goToUrl:'Ir a la cita virtual',
		televisit: {
			televisitForm: {
				firstName: "Nombre(s)*",
				lastName: "Apellidos*",
				birthdate: 'Fecha de nacimiento*',
				memberId: 'Número de póliza*',
				memberFieldMessage: 'Si no sabe su número de póliza, use el chat de atención al paciente.'
			},
			modalTitleTelevisit: "¿Estás seguro de querer cancelar tu cita?",
			btnYesModalTitleTelevisit: "Yes",
			btnNoModalTitleTelevisit: "No",

			modalTitleTimeTelevisitOne: "El tiempo para cancelar esta cita",
			modalTitleTimeTelevisitTwo: "ha expirado",
			btnNoModalTitleTimeTelevisitOne: "Reagendar",
			btnYesModalTitleTimeTelevisitOne: "Cerrar",

			modalTitleSuccessTelevisit: "Tu cita ha sido cancelada exitosamente",
			btnYesModalTitleSuccessTelevisit: "ok",

			modalTitleErrorTelevisit: "Ha ocurrido un error. Por favor inténtalo más tarde.",
			sorry: "Lo sentimos, el enlace de la teleconsulta ha expirado debido a que se ha excedido el límite de tiempo.",
			modalTitleErrorOcurredTelevisit: "Ha ocurrido un error",
			modalSubTitleErrorOcurredTelevisit: "No es posible cancelar tu cita",

			titleAvailableAppointmentsOne: "El link para acceder a tu televista estará disponible",
			titleAvailableAppointmentsTwo: "15 minutos antes de tu cita.",
			titleCancelAppointments: "No es posible cancelar la cita",

			televistSubTitle: 'Para continuar a la llamada llena la siguiente información de acuerdo a tu tarjeta de seguro médico.',

			transactionApproved: 'Tu transacción ha sido aprobada',
			transactionDetails: 'Detalles de la transacción',
			transactionID: 'ID de transacción: ',
			transactionState: 'Estado de transacción: ',
			transactionDate: 'Fecha de pago: ',
			transactionNumber: 'Código de pago: ',
			transactionTotal: 'Pago total: ',
			transactTotal: 'Total: ',

			transactInsuranceNoCoverage: 'No pudimos validar la información de tu seguro ',
			transactInsNoCoverSubtitle: 'Por favor valida tu cobertura con tu compañía de seguro o procede a tomar el servicio a precio completo',
			transactionContinue: 'Continuar a pago',
			transactGoInsurance: 'Ir a mi seguro médico',
			transactSupport: 'Si tienes problemas, por favor contacta a ',
			transactBack: 'regresar a menú anterior',
			previousTransaction:'Parece que ya tienes una transacción previamente creada',
			wantContinue:'¿Deseas continuar? ',
			continue:'Continuar con la misma transacción',
			createNew:'Crear una nueva',
			cantContinue:'No podemos continuar con un nuevo pago',
			pleaseWait:'Por favor espera a que el estado de la misma cambie para ejecutar esta acción',
			accept:'aceptar',
			notSanitasProvider:'Tu PCP no es proveedor de Sanitas',
			ableToGo:'Puedes validar la información de tu seguro médico, comunicarte con el chat de soporte o proceder a tomar el servicio a precio completo.',
			tansacciornProcess:'Parece que ya tienes una transacción en proceso ',
			waitUntil:'Por favor espere hasta que la transacción sea completada',
			transactionCanceled:'La transacción ha sido cancelada exitosamente',
			cannotCancel:'No puedes cancelar esta transacción',
			pleaseWairUntil:'Por favor espera que la transaccion sea completada. Si tienes alguna pregunta, por favor contacta a',


			transactPending: 'Tu transacción está pendiente',
			transactPendingSubtitle: 'Tu pago está pendiente. Puedes esperar a que la transacción cambie de estado o volver en unos minutos al servicio seleccionado y verificar si puedes ingresar',

			noCopaymentTitle:'El servicio de videollamada puede aplicar cargos',
			noCopaymentText1:'Puedes acceder ahora. Sin embargo, podría aplicarse un costo más adelante.',
			noCopaymentText2:'Para mas infromación, contacta tu aseguradora.',
			noCopaymentButton:'CONTINUAR',
			noCopaymentSub:'Regresar a menú anterior',

			name: 'Nombre',
			lastname: 'Apellido',
			city: 'Ciudad',
			state: 'Estado',
			email: 'Correo electrónico',
			phonenumber: 'Teléfono',
			service: 'Servicio',
			cost: 'Costo: ',
			quantity: 'Cantidad: ',
			subtotal: 'Subtotal: ',
			total: 'Total: ',

			transactionExpired: 'Tu transacción ha vencido',
			transactionExpiredSubtitle: '¡Lo sentimos! El tiempo para completar el pago ha finalizado. Por favor inténtalo de nuevo regresando al servicio seleccionado y repite el proceso de pago. ',
			expired: 'VENCIDA',

			approved: 'APROBADA',
			pending: 'PENDIENTE',

			transactionRejected: 'Tu transacción ha sido rechazada',
			transactionRejectedSubtitle: '¡Lo sentimos! Tu pago ha sido rechazado. Por favor verifica tu método de pago, regresa al servicio seleccionado y repite el proceso de pago.',
			stateRejected: 'RECHAZADA',
			insuranceInactived: 'Tu seguro parece estar inactivo',
			insuranceInactivedSubtitle: 'Por favor valida el estado de tu seguro médico con tu compañía de seguros o procede a tomar el servicio a precio completo',
			patientSupport: "Soporte al paciente",
			notifyViaSms: 'Notificarme via SMS cuando cambie el estado del pago',
			goToVideoCall: 'CONTINUAR',
			errorTransactionCompleted: "No podemos continuar con el pago porque ya ha sido completado",
			linkError: "Ha ocurrido un problema con el link de la televisita. Por favor contacta a soporte al paciente"
		},
		ChatWithDoc: {
			tChatWithDocForm: {
				firstName: 'First name*',
				lastName: 'Last name*',
				birthdate: 'Date of Birth*',
				memberId: 'Member ID*',
				memberFieldMessage: 'If you don’t know the Member ID, please contact our patient support chat.'
			},
			title:'Chatea con un Doctor',
			subTitle: 'Para continuar al chat llena la siguiente información de acuerdo a tu tarjeta de seguro médico.',
			insuranceTitleInactive: 'Tu seguro parece estar inactivo',
			insuranceTextInactive: 'Por favor valida el estado de tu seguro médico con tu compañía de seguros.',
			insuranceBtnGoTo: 'IR A SEGURO MÉDICO',
			textSupport: 'Si tienes problemas, por favor contacta a ',
			linkSupport: "Soporte al paciente",
			goBack: 'Regresar a menú anterior',
			insuranceTitleNoCoverage: 'No pudimos validar la información de tu seguro',
			insuranceTextNoCoverage: 'Por favor valida tu cobertura con tu compañía de seguro.',
			insuranceTitleNoPCP: 'Tu PCP no es un proveedor de Sanitas',
			insuranceTextNoPCP: 'Puedes validar la información de tu seguro médico o comunicarte con el chat de soporte.',
			textMeaningPCP: '*PCP significa Proveedor de atención primaria'
		}

	},
};

export default nsGetCare;
