import { useState, useRef, useEffect } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';

import { KeraltyIcon, KeraltyButton } from '.';
import { Row, Column } from '../templates';
import styled from '../../ui-core/styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from 'i18next';
import es from 'date-fns/locale/es';
import enUS from 'date-fns/locale/en-US';
import InputMask from 'react-input-mask';
import { MASK } from '../../ui-core/utils/mask';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import FORMATS from 'app/ui-core/utils/formats';
import moment from 'moment';
import useIsMobile from '../../hooks/useIsMobile';
import { width } from '@mui/system';

registerLocale('es', es);
registerLocale('en', enUS);

// ****************************************************************
// API REFERENCE =>  https://next.material-ui.com/api/date-picker/
// ****************************************************************

const ButtonDone = styled(KeraltyButton)`
  align-self: center;
  padding: 0px auto !important;
  font-size: 12px !important;
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    max-width: 8rem;
    min-width: 7rem;
    height: 3rem;
    padding: 0rem 1.2rem;
  }
`;

const ButtonCancel = styled(KeraltyButton)`
  align-self: center;
  padding: 0px auto !important;
  font-size: 12px !important;
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    max-width: 10rem;
    min-width: 8rem;
    height: 3rem;
    padding: 0rem 1.2rem;
  }
`;

const CalendarHeader = styled.div`
  display: flex;
  flex: column;
  justify-content: space-between;
  margin: 0.83rem;
`;

const CalendarHeaderArrowBtn = styled.span`
  font-family: Proxima Nova;
  font-size: 1.33rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0rem;
  text-align: center;
  color: #055293;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const CalendarHeaderBtn = styled.span`
  font-family: Proxima Nova;
  font-size: 1.33rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0rem;
  text-align: center;
  color: #055293;
  background: transparent;
  border: none;
  cursor: pointer;

  &.month {
    margin-right: 0.5rem;
  }

  &.year {
    margin-left: 0.5rem;
  }
`;

const ButtonContainer = styled(Column)`
  justify-content: center;
`;

const TranslateDatePicker = ({ ...props }) => {
  const [calendarStages, setCalendarStages] = useState({
    mode: 'day',
  });

  const { minDate, hasNotMaxDate } = props;
  const { read } = props;
  const defaultFormat: string = FORMATS.date;
  const defaultPattern = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/;
  const calendarRef = useRef<any>(null);
  const isMobile = useIsMobile();
  const [selectedDate, setSelectedDate] = useState<null | Date | string>(null);
  const [initialDate, setInitialDate] = useState<null | Date | string>(null);

  const { t } = useTranslation();

  const { selected } = props;

  const changeMode = (mode: string = 'day') => {
    setCalendarStages({ ...calendarStages, mode: mode });
  };

  const decrease = (date, decreaseMonth, changeYear) => {
    if (calendarStages.mode !== 'year') {
      decreaseMonth();
    } else {
      changeYear(parseInt(date.getFullYear()) - 12);
    }
  };

  const increase = (date, increaseMonth, changeYear) => {
    if (calendarStages.mode !== 'year') {
      increaseMonth();
    } else {
      changeYear(parseInt(date.getFullYear()) + 12);
    }
  };

  const validateMethod = (stringToValidate: string) => {

    if (stringToValidate) {
      if (validateInput(stringToValidate)) {
        setSelectedDate(new Date(stringToValidate));
        setInitialDate(new Date(stringToValidate));
        props.onChange(new Date(stringToValidate));
      } else {
        setSelectedDate(null);
        setInitialDate(null);
        props.onChange('Invalid Date');
      }
    } else {
      setSelectedDate(null);
      setInitialDate(null);
      props.onChange(null);
    }

    handleForceClose();
  };

  const [ monthVisible, setMonthVisible ] = useState(true);

  const customHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    increaseYear,
    decreaseYear
  }) => (
    <CalendarHeader>
      <CalendarHeaderArrowBtn style={{width:'10%'}}
        className="arrow arrow-back"
        onClick={() => decrease(date, decreaseYear, changeYear)}
      >
        <KeraltyIcon iconClass="fa fa-angle-double-left" color="primary" size="common" />
      </CalendarHeaderArrowBtn>
      { monthVisible && <CalendarHeaderArrowBtn style={{width:'10%'}}
        className="arrow arrow-back"
        onClick={() => decrease(date, decreaseMonth, changeYear)}
      >
        <KeraltyIcon iconClass="fa fa-angle-left" color="primary" size="common" />
      </CalendarHeaderArrowBtn>
      }
      <label style={{width:'60%'}}>
        <CalendarHeaderBtn 
          onClick={() => {
            setMonthVisible(true);
            changeMode('month');
          }}
          className="month"
        >
          {t(`monthsCalendar.${date.getMonth() + 1}`)}
        </CalendarHeaderBtn>
        <CalendarHeaderBtn
          onClick={() => {
            setMonthVisible(false);
            changeMode('year');
          }}
          className="year"
        >
          {date.getFullYear()}
        </CalendarHeaderBtn>
      </label>
      { monthVisible && <CalendarHeaderArrowBtn style={{width:'10%'}}
        className="arrow arrow-next"
        onClick={() => increase(date, increaseMonth, changeYear)}
      >
        <KeraltyIcon iconClass="fa fa-angle-right" color="primary" size="common" />
      </CalendarHeaderArrowBtn>
      }
      <CalendarHeaderArrowBtn style={{width:'10%'}}
        className="arrow arrow-next"
        onClick={() => increase(date, increaseYear, changeYear)}
      >
        <KeraltyIcon iconClass="fa fa-angle-double-right" color="primary" size="common" />
      </CalendarHeaderArrowBtn>
    </CalendarHeader>
  );

  const setLocale = () => {
    if (i18n.language.includes('es')) {
      return es;
    }

    return enUS;
  };

  const validateInput = (stringDate: string): boolean => {
    const pattern = props.regexPattern ? props.regexPattern : defaultPattern;
    const currentFormat = props.dateFormatInput ? props.dateFormatInput : defaultFormat;
    const regexValidation = stringDate.match(pattern);
    const momentValidation = moment(stringDate, currentFormat, true);
    if (momentValidation.isValid() && regexValidation) {
      return true;
    }

    return false;
  };

  const handleKeyDownEvent = (e) => {
    const stringDate = calendarRef?.current?.input.value;

    if (e && (e.key === 'Tab' || e.key === 'Enter')) {
      validateMethod(stringDate);
    }
  };

  const ExampleCustomTimeInput = ({ date, value, onChange, onCancel, onDone }) => (
    <Row>
      <ButtonContainer lg={6} md={6} sm={6}>
        <ButtonCancel outline bgcolor={'transparent'} noBorders type={'button'} onClick={onCancel}>
          {t('button.cancel')}
        </ButtonCancel>
      </ButtonContainer>
      <ButtonContainer lg={6} md={6} sm={6}>
        <ButtonDone type={'button'} onClick={onDone}>
          {t('button.done')}
        </ButtonDone>
      </ButtonContainer>
    </Row>
  );

  const handleCalendarOpen = () => {
    if (!initialDate) {
      setInitialDate(props.selected);
    }
  };

  const handleSelectMonthandYear = (date, $event, $z) => {
    if (date && $event) {

      setMonthVisible(true);

      if (calendarStages.mode !== 'day') {
        setCalendarStages({
          ...calendarStages,
          mode: 'day',
        });

      } else {
        props.onChange(date);
        setSelectedDate(date);
      }
    }
  };

  const handleClickOutside = () => {

    if (selected) {
      if (selected instanceof Date) {
        setInitialDate(selected);
        props.onChange(selected);
      } else if (validateInput(selected)) {
        setSelectedDate(new Date(selected));
      } else {
        setSelectedDate(null);
        props.onChange('Invalid Date');
      }
    } 
    calendarRef?.current.clear();

    handleForceClose();
  
  }

  const handleCancel = () => {

    if (initialDate) {
      if (initialDate instanceof Date) {
        setSelectedDate(initialDate);
        props.onChange(initialDate);
      } else if (validateInput(initialDate)) {
        setSelectedDate(new Date(initialDate));
      } else {
        setSelectedDate(null);
        props.onChange('Invalid Date');
      }
    } 
    calendarRef?.current.clear();

    //setSelectedDate(null);
    //props.onChange(null);
    //setInitialDate(null);
    handleForceClose();
  };

  const handleForceClose = () => {
    
    if (calendarRef && calendarRef?.current) {
      calendarRef?.current?.setOpen(false);
    }
  };

  useEffect(() => {

    /*if (selected && selected instanceof Date) {
      setSelectedDate(selected);
    } else {
      setSelectedDate(null);
      setInitialDate(null);
    }*/

  }, [selected]);

  const onClickDone = () => {
    setInitialDate(selected);
    handleForceClose();
  }

  useEffect(() => {
    setTimeout(() => {
      setSelectedDate(selected)
    }, 1000)
  },[]);


  const handleInputKeyPress = (event) => {
    if (read) {
      event.preventDefault();
    }
  };

  return (
    <DatePicker 
      ref={calendarRef}
      selected={selectedDate}
      autoComplete={'off'}
      placeholderText={props.placeholderText}
      disabled={props.disabled}
      locale={setLocale()}
      showMonthYearPicker={calendarStages.mode === 'month' ? true : false}
      showFullMonthYearPicker
      showYearPicker={calendarStages.mode === 'year' ? true : false}
      yearItemNumber={12}
      onChange={handleSelectMonthandYear}
      renderCustomHeader={customHeader}
      onClickOutside={handleClickOutside}
      onKeyDown={handleKeyDownEvent}
      minDate={minDate}
      maxDate={hasNotMaxDate ? null : new Date()}
      //onBlur={handleBlurEvent}
      customInput={
        <InputMask mask={MASK.date} maskChar="" inputMode={isMobile ? 'none' : 'text'} onKeyPress={handleInputKeyPress} />
      }
      showTimeInput      
      onCalendarOpen={handleCalendarOpen}
      withPortal={isMobile}
      customTimeInput={
        <ExampleCustomTimeInput
          date={null}
          value={null}
          onChange={null}
          onDone={onClickDone}
          onCancel={handleCancel}
        />
      }
      shouldCloseOnSelect={false}
    />
  );
};

const KeraltyDatePicker = styled(TranslateDatePicker).attrs({
  dateFormat: 'MM/ dd/ yyyy',
  autoComplete: 'off',
  // onChangeRow: (e) => e.target.value,
  // showMonthDropdown: true,
  // showYearDropdown: true,
  // dropdownMode: "select"
})`
  .react-datepicker {
    background: ${({ theme: { colors } }) => colors.lightGrey};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    .react-datepicker__input-container {
      position: inherit;
    }
    .react-datepicker__day-names,
    .react-datepicker__header {
      background-color: ${({ theme: { colors } }) => colors.lightGrey};
      border: none;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .react-datepicker__month {
      margin-top: 0px;
    }

    .react-datepicker__current-month {
      margin-bottom: 10px;
    }

    .react-datepicker__header div {
      color: ${({ theme: { colors } }) => colors.primary};
      font-size: 14px;
    }
    .react-datepicker__navigation-icon::before {
      border-color: ${({ theme: { colors } }) => colors.primary};
      border-width: 2.5px 2.5px 0 0;
      height: 3px;
      width: 3px;
      top: 8px;
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
      background-color: transparent;
    }

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        visibility: hidden;
        background-color: ${({ theme: { colors } }) => colors.lightGrey};
        font-size: 0px;
        width: 2.413rem !important;
        text-transform: capitalize;
      }
      .react-datepicker__day-name::first-letter {
        visibility: visible;
        color: ${({ theme: { colors } }) => colors.accent};
        font-size: 12px;
        line-height: 1.3rem;
      }
    }
    .react-datepicker__day {
      color: ${({ theme: { colors } }) => colors.primary};
      font-size: 12px;
      line-height: 1.5rem;
    }

    .react-datepicker__day--selected {
      color: ${({ theme: { colors } }) => colors.lightGrey};
      background-color: ${({ theme: { colors } }) => colors.accent};
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${({ theme: { colors } }) => colors.lightGrey};
    }

    .react-datepicker__month.react-datepicker__monthPicker .react-datepicker__month-text {
      font-family: Proxima Nova;
      font-size: 1.16rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: 0em;
      text-align: left;
      color: #055293;
      text-transform: capitalize;
      width: 6rem;
      text-align: center;
    }

    .react-datepicker__month.react-datepicker__monthPicker
      .react-datepicker__month-text--keyboard-selected {
      background-color: transparent !important;
      font-weight: bold !important;
      color: #055293;
    }

    .react-datepicker__year .react-datepicker__year-wrapper {
      justify-content: center;
    }

    .react-datepicker__year .react-datepicker__year-text {
      font-family: Proxima Nova;
      font-size: 1.16rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: 0em;
      text-align: left;
      color: #055293;
      text-transform: capitalize;
      width: 5rem !important;
      text-align: center;
    }

    .react-datepicker__year .react-datepicker__year-text--keyboard-selected {
      background-color: transparent !important;
      font-weight: bold !important;
      color: #055293;
    }

    .react-datepicker__day--outside-month {
      color: #bdbdbd !important;
    }
  }
`;

export default KeraltyDatePicker;
