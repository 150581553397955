
import styled from '../../ui-core/styled-components';
import theme from '../../ui-core/utils/theme';

interface KeraltyIconProps {
	className?: string;
	iconClass?: string; // fas fa-eye {FONTAWESOME}
	title?: string;
	size?: keyof (typeof theme.sizes.icon);
	rounded?: boolean;
	color?: keyof (typeof theme.colors);
	mx?: boolean;
	onClick?: () => void;
	[key: string]: any;
};

const IconContainer = ({ iconClass, title, rounded = false, mx, ...props }: KeraltyIconProps) => (
	<span {...props} ref={props.ref}>
		<i className={iconClass} title={title} />
	</span>
);

const KeraltyIcon = styled(IconContainer) <KeraltyIconProps>`
	height: 1.5rem;
	width: 1.5rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	${({ size = 'small', rounded, color = 'primary', theme: { sizes, colors } }) => `
		font-size: ${sizes.icon[size]}rem;
		min-height: ${sizes.icon[size]}rem;
		color: ${colors[color]};

		${rounded ? `
			color: ${colors.light};
			background-color: ${colors[color]};
			height: ${(sizes.icon[size]) * 2}rem;
			width:  ${(sizes.icon[size]) * 2}rem;
			border-radius:  ${(sizes.icon[size]) * 2}rem;
		` : ''}
	`}

	${({ mx }) => mx ? 'margin-left: 1.5rem; margin-right: 1.5rem;' : ''}
`;

export default KeraltyIcon;


// USAGE
// <KeraltyIcon iconClass="fas fa-lock-alt" size="big" color="accent" rounded />
// <KeraltyIcon iconClass="fas fa-lock-alt" size="big" color="accent" />
// <KeraltyIcon iconClass="fas fa-lock-alt" rounded />
// <KeraltyIcon iconClass="fas fa-lock-alt" />
