import { useEffect, useState } from "react";
import moment from "moment";

import { getCustomDate as customDate, getHourByTimeZone } from "app/ui-core/utils/dates";
import { VALID_TYPES } from "app/ui-core/utils/appointments";
import FORMATS from "app/ui-core/utils/formats";
import ValidationsRepository from "infrastructure/keraltyApi/repositories/validations.repositories";
import { TimeZoneTypes } from "app/ui-core/utils/types";
import { SPECIFIC_TIME_ZONES } from "app/ui-core/utils/constants";

interface InputProps {
    date: string;
    time: string;
    dateToValidate: string;
}

interface NewDateInputProps extends InputProps {
	isNotUTCDate?: boolean;
}

interface AppointmentOptionsProps {
    dateHasPassed: boolean;
    isDay: boolean;
    time: number;
    visitType: string;
    status: string;
}

interface ResponseOptions {
    general_text: boolean;
    go_to_appointment: boolean;
    cancel_button: boolean;
    disable_cancelButton: boolean;
    enabledOption: boolean;
}

const useAppointment = () => {

    const [minutesFromServer, setMinutesFromServer] = useState(0);

    const TIME_TO_VALIDATE = 15;
    const ACCEPTED_STATUS = 'ACCEPTED';

	const getTimeZoneByPrefix = (prefix: string): TimeZoneTypes => SPECIFIC_TIME_ZONES.find(tz => tz.prefixes.includes(prefix))?.timeZone as TimeZoneTypes;

	/**
     *
     * @param {String} date
     * @param {Boolean} isNotUTCDate - Optional. Default value: false
     * @returns {InputProps}
    */
	const getNewDate = ({ date, isNotUTCDate = false }: Omit<NewDateInputProps, 'time' | 'dateToValidate'>): InputProps => {
		return customDate({ date: !isNotUTCDate ? date : date.replace('Z', '') });
	}

    const getCurentTime = () => ValidationsRepository.timeValidation('');

    const differenceServerLocalTime = async () => {

        try {

            const res = await getCurentTime();
            const currentDate = res.date;
            const currentTime = res.time;
            const formattedcurrentUtcDateTime = (`${currentDate}T${currentTime}`)
            const parts = formattedcurrentUtcDateTime.split(":");
            const dateParts = new Date(`${parts[0]}:${parts[1]}:00Z`)

            const [currentHourServer, currentMinuteServer] = moment(dateParts, 'hh:mm:ss').format('HH:mm').split(':').map(Number);
            const [currentHourLocal, currentMinuteLocal] = moment().format('HH:mm').split(':').map(Number);

            const totalMinutesServer = currentHourServer * 60 + currentMinuteServer;
            const totalMinutesLocal = currentHourLocal * 60 + currentMinuteLocal;

            setMinutesFromServer(totalMinutesLocal - totalMinutesServer)

        }catch(error){
            console.log(error);
        }

    };

    const isCurrentlyAppointmentTime = (customDate: InputProps) => {

        const [month, day, year] = customDate.date.split('/');
        const inputDate = `${year}-${month}-${day}`;
        const currentDate = moment().format(FORMATS.date2);

        return {
          isDay: inputDate === currentDate,
          dateHasPassed: new Date(inputDate).getTime() < new Date(currentDate).getTime()
        }
    };

	const isCurrentlyAppointmentTimeByTimeZone = (customDate: InputProps, timeZone: TimeZoneTypes) => {

        const [month, day, year] = customDate.date.split('/');
        const inputDate = `${year}-${month}-${day}`;

        const fullDate = getHourByTimeZone(timeZone);
        const date = `${fullDate.year}-${fullDate.month}-${fullDate.day}`;

        return {
          isDay: inputDate === date,
          dateHasPassed: new Date(inputDate).getTime() < new Date(date).getTime()
        }
    };

    const calculateTimeDifference = (customDate: InputProps): number => {
        const newDate: Date = new Date(customDate.dateToValidate);

        const [startHour, startMinute] = moment(newDate, 'hh:mm:ss').format('HH:mm').split(':').map(Number);
        const [currentHour, currentMinute] = moment().format('HH:mm').split(':').map(Number);

        const startTotalMinutes = startHour * 60 + startMinute;
        const currentTotalMinutes = currentHour * 60 + currentMinute;
        return startTotalMinutes - currentTotalMinutes + minutesFromServer;
    };

	const getDate = (timeZone: TimeZoneTypes) => {
		const { year, month, day, hour, minute } = getHourByTimeZone(timeZone);

        const date = `${year}-${month}-${day}`;
        const nHour = `${hour}`;
        const time = `${nHour === '24' ? '00' : nHour}:${minute}`;
        const utcDate = `${date}T${time}:00Z`

		return utcDate;
	}

	const calculateTimeDifferenceByTimeZone = (customDate: InputProps, timeZone: TimeZoneTypes): number => {
        const newDate: Date = new Date(customDate.dateToValidate);

		const date = getDate(timeZone);

		const customLocaleDate = getNewDate({ date: date, isNotUTCDate: true });

        const newCustomLocaleDate: Date = new Date(customLocaleDate.dateToValidate);

        const [startHour, startMinute] = moment(newDate, 'hh:mm:ss').format('HH:mm').split(':').map(Number);
        const [currentHour, currentMinute] = moment(newCustomLocaleDate, 'hh:mm:ss').format('HH:mm').split(':').map(Number);

        const startTotalMinutes = startHour * 60 + startMinute;
        const currentTotalMinutes = currentHour * 60 + currentMinute;
        return startTotalMinutes - currentTotalMinutes + minutesFromServer;
    };

    const calculateServerTimeDifference = (startTimeI: string, dataI: string, currentDateByTimeZone: string): number => {

        if (!startTimeI) return 0;

        const date = moment(dataI).format(FORMATS.date2);
        const startTime = startTimeI;
        const utcDateAppointment = `${date}T${startTime}:00Z`;
        const utcDateAppointmentCol: Date = new Date(utcDateAppointment);

        const currentUtcDateTimeCol: Date = new Date(currentDateByTimeZone);

        const [startHour, startMinute] = moment(utcDateAppointmentCol, 'hh:mm:ss').format('HH:mm').split(':').map(Number);
        const [currentHour, currentMinute] = moment(currentUtcDateTimeCol, 'hh:mm:ss').format('HH:mm').split(':').map(Number);
        const startTotalMinutes = startHour * 60 + startMinute;
        const currentTotalMinutes = currentHour * 60 + currentMinute;

        return startTotalMinutes - currentTotalMinutes;

    };

    const setAppointmentOptions = ({ dateHasPassed, isDay, time, visitType, status }: AppointmentOptionsProps): ResponseOptions => {

        const options = {
            general_text: false,
            go_to_appointment: false,
            cancel_button: false,
            disable_cancelButton: false,
            enabledOption: VALID_TYPES.includes(visitType.toLowerCase()) && status === ACCEPTED_STATUS
        };

        if(dateHasPassed) return {
            ...options,
            disable_cancelButton: true,
        }

        if(isDay && time <= TIME_TO_VALIDATE) {

			const hideOptions = time <= -TIME_TO_VALIDATE ? false : true

			return {
				...options,
				go_to_appointment: hideOptions,
				cancel_button: hideOptions,
				disable_cancelButton: time <= -TIME_TO_VALIDATE ? true : false
        	}
		}

        if(!isDay || time >= TIME_TO_VALIDATE) return {
            ...options,
            general_text: true,
            cancel_button: true,
        }

        return {
            ...options,
        }

    };

    useEffect(() => {
        differenceServerLocalTime();
    }, []);

    return {
        getNewDate,
        isCurrentlyAppointmentTime,
		isCurrentlyAppointmentTimeByTimeZone,
        calculateTimeDifference,
		calculateTimeDifferenceByTimeZone,
        calculateServerTimeDifference,
        setAppointmentOptions,
        TIME_TO_VALIDATE,
        ACCEPTED_STATUS,
		getTimeZoneByPrefix,
		getDate
    }
}

export default useAppointment;
