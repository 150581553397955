
import styled from '../../ui-core/styled-components';

interface KeraltyFormProps {
	width?: string;
	autoComplete?: string;
}

const FormBox = styled.form<KeraltyFormProps>`
	height: auto;
	min-height: 100%;
	width: ${props => props.width ? props.width : '29rem'};

	.grecaptcha-badge {
		bottom: 40% !important;
		z-index: 10;
	}
`;

export default FormBox;
