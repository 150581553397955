export interface TwoFactorAuthenticationState {
    info: InfoTwoFactorAuthentication
}

export interface InfoTwoFactorAuthentication {
    email: string,
    password: string,
    state: string,
    encryptedInfo: string,
    phone: string,
    sendCodeBy: string,
    idTemp: string,
    sendCodeAttemps: string | undefined,
    errorCodeAttemps: string | undefined,
    validCode: boolean,
    currentStep: TwoFactorAuthenticationSteps,
    expiredCode: boolean,
}

export enum TwoFactorAuthenticationSteps {
    'chooseOptions',
    'sendCode',
    'resendCode',
    'default'
}