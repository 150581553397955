import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { RootState, store } from 'adapter/store';

type Props = {
	component?: React.ComponentType<RouteComponentProps>;
	render?: (routerProps: any) => React.ReactNode;
	guards?: Array<() => boolean | string>;
	[key: string]: any;
}

const PrivateRoute = ({ component: Component, guards = [], ...rest }: Props) => {
	// IMPORTANT: WE CANNOT USE REACT HOOKS BEFORE ROUTE RENDER
	const { user: { locationSelected } }: RootState = store.getState();
	const { type, authenticated, redirectTo } = rest;

	if (redirectTo) return <Redirect to={redirectTo} />;

	// PRIVATE ROUTES VALIDATION, TODO: AUTH GUARD
	if (type === 'private' && !authenticated) {
		if (locationSelected) return <Redirect to="/login" />;
		else return <Redirect to="/select-location" />;
	}

	// ROUTE GUARDS
	const guardsResult = guards.map((guard) => guard());
	if (guards.length && guardsResult.some(result => result !== true)) {
		const guardRedirection = guardsResult.find((result) => typeof result === 'string');
		return <Redirect to={guardRedirection || "/login"} />;
	}

	return Component ? (
		<Route
			{...rest}
			render={routerProps => <Component {...routerProps} {...rest} />}
		/>
	) : <Route {...rest} />;
};

export default PrivateRoute;
