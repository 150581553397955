import { KeraltyText } from "app/components/atoms";
import { KeraltyBreadcrumb } from "app/components/molecules";
import useIsMobile from "app/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

interface AuthenticationLayoutProps {
    children: React.ReactNode,
    headerOptions: HeaderOptions[];
    handleGoBack: HandleGoBack
}

interface HeaderOptions {
    label: string, 
    callback?: () => void
}

interface HandleGoBack {
    label: string, 
    callback: () => void
}

const AuthenticationLayout = ({ children, headerOptions, handleGoBack }: AuthenticationLayoutProps ) => {

    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const location = useLocation();

    const showOptionsAndTitle = () => location.pathname === "/authentication/status"

    return (
        <div style={{ padding: isMobile ? '.5rem' : '2rem'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem', padding: isMobile ? '1rem' : '0rem' }}>
                { !showOptionsAndTitle() && <KeraltyBreadcrumb routes={headerOptions} /> }    
                <KeraltyBreadcrumb routes={[{ label: handleGoBack.label || '<', callback: handleGoBack.callback}]} />
            </div>
            {
                !showOptionsAndTitle() && !isMobile &&
                (
                    <div style={{ display: 'grid', placeItems: 'center'}}>
                        <KeraltyText type="subTitle" align="left" bold pb>
                            {t('routes.loginAuthentication')}
                        </KeraltyText>
                    </div>
                )
            }
            { children }
        </div>
    )
}

export default AuthenticationLayout;