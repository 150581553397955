import { UserIdentifier as UserIdentifierEntity } from '../../../domain/entities/userIdentifier';
import { ResetPasswordBlocked, ResetPassword as ResetPasswordEntity } from '../../../domain/entities/resetPassword';
import { NewPasswordBlockedDTO, NewPasswordDTO, UserIdentifierDTO } from '../models/unblockAccountModel';

/**
 * Map Domain Objects to Data Transfer Objects expected by API
 */
const unblockMapper = {
  mapToUserIdentifier: (domainObject: UserIdentifierEntity): UserIdentifierDTO => {
    const { firstName, lastName, email, mobile, states } = domainObject;

    return {
      name: firstName,
      surname: lastName,
      mobile: mobile,
      email,
      states,
    };
  },
  mapToNewPassword: (domainObject: ResetPasswordEntity): NewPasswordDTO => {
    const { password, idBelongState } = domainObject;
    return {
      pass: password,
      idBelongState
    };
  },
  mapToPasswordBlocked: (domainObject: ResetPasswordBlocked): NewPasswordBlockedDTO => {
    const { code, id, pass, idBelongState } = domainObject;
    return {
      code,
      id,
      pass,
      idBelongState
    }
  }
};

export default unblockMapper;
