const nsRoutes = {
  en: {
    back: 'Back',
    return: 'Return',
    previous: 'Previous',
    login: 'Login',
    forgotMyPassword: 'Forgot my password',
    unblockMyAccount: 'Unblock account',
    register: 'Create account',
    legal: {
      privacy: 'Privacy Policy',
      terms: 'Terms and Conditions',
      third: 'Third party notices',
    },
    patientRegistration: 'Patient registration form',
    home: 'Home',
    getCare: 'Get care now',   
    check: 'Check your symptoms',    
    virtualAssistant: 'Virtual assistant',
    patientSupport: 'Chat with Patient Support',
    appointments: 'Appointments',
    previousAppointments: 'Previous appointments',
    myHealth: 'My health',
    mentalHealth: 'Mental health',    
    myAccount: 'My Account',
    talkToaDoctor: 'Talk to a doctor',
    referrals: 'My Referrals',
    carePrograms: 'Care Programs',
    labAndImagingResults:'My labs and imaging Results',
    medication: 'Medications',
    getCareDescription: 'Start a Videocall or chat with a \n doctor on demand',
    checkDescription: "Assess your symptoms \nwith AI" ,
    myHealthDescription: 'Access to your medical\nrecords',
    appointmentsDescription: 'Book a virtual or in-person visit \nwith your doctor',
    referralsDescription: 'Check your health referrals',
    careProgramsDescription: 'Get instant care from our \nspecialists',
    labAndImagingResultsDescription: 'Access to your labs and \nimaging results',
	  healtLibrary: 'Health Library',
    healtLibraryDescription: 'Access to educational medical \ninformation',
    changeYour:'Change your password',
    location: 'Find my nearest Sanitas service',
    locationDescription: 'Tap to find a doctor or clinic \nnearby.',
    mh:'Mental health',
    mhDescription: 'Reach out and find support\n\n',
    loginAuthentication: "Login Authentication",
    wellnessDescription: "Check your signs and\n symptoms with AI",
    wellness: "Wellness",
    healtLibraryDescription2: 'Your source for reliable wellness information',
    bookAppoiment: 'Book\nAppointment',
    chatSupport: 'Support \nChat',
  },
  es: { 
    back: 'Volver',
    return: 'Atrás',
    previous: 'Anterior',
    login: 'Iniciar sesión',
    forgotMyPassword: 'Olvidé mi contraseña',
    unblockMyAccount: 'Desbloquea tu cuenta',
    register: 'Registrarme',
    legal: {
      privacy: 'Política de Privacidad',
      terms: 'Términos y condiciones',
      third: 'Avisos de terceros',
    },
    patientRegistration: 'Formulario de registro del paciente',
    home: 'Inicio',
    getCare: 'Atención inmediata',
    check: 'Chequea tus síntomas',
    virtualAssistant: 'Asistente virtual',
    patientSupport: 'Chat atención al cliente',
    appointments: 'Citas',
    previousAppointments: 'Citas previas',
    myHealth: 'Mi salud',
    mentalHealth: 'Salud mental',
    myAccount: 'Mi Cuenta',
    talkToADoctor: 'Hablar con un médico',
    referrals: 'Mis Referencias',
    carePrograms: 'Programas de atención',
    labAndImagingResults: 'Mis Lab e imágenes diagnósticas',
    medication: 'Medicamentos',
    getCareDescription: 'Inicia una videollamada o chat con\n un doctor',
    checkDescription: 'Chequea tus síntomas con inteligencia artificial' ,
    myHealthDescription: 'Accede a tu historial \nmédico',
    appointmentsDescription:'Agenda una cita virtual o presencial \n con tu doctor' ,
    referralsDescription: 'Gestiona tus referencias médicas',
    careProgramsDescription: 'Resuelve tus dudas con \n nuestros especialistas',
    labAndImagingResultsDescription: 'Accede a tus laboratorios y \n resultados de imágenes',
	  healtLibrary: 'Biblioteca de salud',
    healtLibraryDescription: 'Accede a contenido médico de \neducación',
    changeYour:'Desbloquea tu cuenta.',
    location: 'Servicio de Sanitas más cercano',
    locationDescription: 'Encuentra un centro médico \ncerca tuyo',
    mh:'Salud mental',
    mhDescription: 'Ponte en contacto y  encuentra \napoyo',
    loginAuthentication: "Autenticación de inicio de sesión",
    wellnessDescription: "Chequea tus signos vitales y\n síntomas con inteligencia artificial",
    wellness: "Bienestar",
    healtLibraryDescription2: 'Tu recurso para información de salud confiable',
    bookAppoiment: 'Agendar\nCitas',
    chatSupport: 'Chat de \nsoporte',
  },
};

export default nsRoutes;
