import { useLocation, useHistory } from 'react-router-dom';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#methods
 * @returns  URLSearchParams 
 */
export const useRouteQuery = () => {
  const URLParamsInstance = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const removeParam = (queryKey: string) => {
    if (URLParamsInstance.get(queryKey)) {
      URLParamsInstance.delete(queryKey);
      history.replace({
        search: URLParamsInstance.toString(),
      })
    }
  }

  return {
    query: URLParamsInstance,
    removeParam
  }
}