import { useCallback, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import LabTable from './LabTable';
import LabItem from './LabItemResults';
import useAuth from '../../../hooks/useAuth';

//Actions
import { getLabData, cleanError } from 'adapter/myHealth/myHealthSlice';
import { selectmyHealthLabInfo } from 'adapter/myHealth/myHealthSelectors';

const LabTab = () => {
	// @TODO: Each row should be a route, but for now this works
	const [activeRow, setActiveRow] = useState<number | undefined>();
	const { user } = useAuth();

	const labInfo = useAppSelector(selectmyHealthLabInfo);
	//const asyncError = useAppSelector(selectmyHealthError);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getLabData(user.idEcw));

		return () => dispatch(cleanError());
	}, [dispatch, user.idEcw])

	const resetActiveRow = useCallback(() => {
		setActiveRow(undefined);
	}, []);

	return (labInfo && activeRow !== undefined ? 
		<LabItem data={labInfo[activeRow]} onBackClick={resetActiveRow} /> :
		<LabTable labData={labInfo || []} onClickItem={setActiveRow} />
	);
};

export default LabTab;
