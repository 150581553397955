import { TwoFactorAuthenticationService } from "domain/useCases/contracts/twoFactorAuthenticationService";
import PublicHttpClient from "../publicHttpClient";

const server = 'auth';

const TwoFactorAuthenticationRepository: TwoFactorAuthenticationService = {
	verifyEmailAndPassword: async (info) => {
		return await PublicHttpClient.post(`${server}/verifyEmailAndMobile`, { info });
	},
    requestCodeEmailMobile: async (payload) => {
		return await PublicHttpClient.post(`${server}/requestCodeEmailMobile`, payload);
	},
    confirmCode: async (payload) => {
		return await PublicHttpClient.post(`${server}/confirmCode`, payload);
	},
};

export default TwoFactorAuthenticationRepository;