const nsErrors = {
	en: {
		captchaExpired: 'The captcha is expired. Please try again.',
		contactSupport: 'Please contact patient support.',
		featureDisabled: 'Unfortunately, we were unable to validate your access to the service, please contact patient support chat',
		insuranceDisabled: 'Sorry, your insurance company doesn’t cover this service',
		insuranceDisabledError: 'Unfortunately we were unable to validate your access to the service, please contact patient support chat ',
		emailExists: 'La cuenta de e-mail ya existe.',
		healoWelcome: 'Welcome to your new healthcare experience!',
		healowCreate: 'If you haven´t logged in this portal before, you will need to create a new account.',
		healowCreateMobile: 'If you haven´t logged in this app before, you will need to create a new account.',
		internalError: 'An internal error has ocurred.',
		invalidDate: 'Invalid date. Please try again.',
		invalidDateRange: 'Invalid date. The \'To\' date can\'t be before of the \'From\' date',
		invalidDateRangeYearAfter: 'The date range must be less than $yearNumber year.',
		invalidBirthdate: 'Invalid date of birth. Please try again.',
		invalidEmail: 'Invalid email. Please review and correct any errors.',
		invalidFormat: 'The type of file you are trying to upload is not allowed, please choose a different format.',
		invalidFormatGeneric: 'Invalid format.',
		invalidName: 'The name format is not valid. Please try again.',
		invalidPassword: 'The password does not meet the requirements. Please enter a new password.',
		invalidPhone: 'Please enter a valid number. Example: (XXX)XXX-XXXX',
		loginFailure: 'Your information does not match. Please re-enter.',
		max: 'Too many characters. Please try again.',
		maxMB3: 'The file you are trying to upload exceeds de maximum size, please choose a file of 3MB or less.',
		min: 'Not enough characters. Please try again.',
		minCode: 'The security code is incomplete. Please re-enter all 6 digits. ',
		passwordMatch: 'Passwords do not match. Please try again.',
		required: 'This field is required.',
		sessionExpired: 'Your session has expired. Please log in.',
		somethingWentWrong: 'Something Went Wrong. Please try again',
		wrongCombination: 'Wrong combination. Please try again.',

		// Codes
		code10: 'User partially exists.',
		code20: 'This account already exists. Please verify or log in.',
		code25: 'Incorrect data entered. Contact support for help.',
		code30: 'Wrong combination. Please try again.',
		code50: 'Success.',
		code60: 'Invalid token.',
		code70: 'Token expired.',
		code80: 'Your information does not match. Please re-enter.',
		code90: 'Invalid parameter.',
		code100: 'Invalid ID.',
		code101: "The time of account creation has expired. Please start over.",
		code110: 'User is not Florida Blue.',
		code120: 'Error sending e-mail.',
		code130: 'Error sending SMS.',
		code140: 'User not fully found in ECW. Please contact support.',
		code150: 'Security code is incorrect. Please try again.',
		code151: 'The verification code is not correct. You have 1 attempts left.',
		code160: 'Your security code has expired. Please request a new one and try again.',
		code170: 'Email already sent',
		code190: "We're sorry, the information you provided doesn't match our records. Click on the 'Support Chat' icon 24/7 to talk to a live agent and get help.",
		code200: 'Email expired. Please contact support.',
		code210: 'The registration process failed, please contact to Patient Support Chat',
		code999: 'Something Went Wrong.',
		codeNoContactMatch: 'The email/phone entered does not match your current medical account, in case you need help please contact support.',
		codeNoMatch: 'The verification code is not correct, please verify and type the last sent.',
		code290: 'It seems that you have a duplicated account. Please go to support chat.',
		code400: 'The Sanitas account number you entered is already associated with an existing account in our system.',
		code502: "Sorry! we were unable to validate your facility. Please contact support for resolution.",

		insuranceError: 'There has been an issue updating your information. Please try again later or contact our support chat.',
        emailNotValid: 'Please enter a valid e-mail to continue',
		troubleWithChat: "Sorry! It looks like you already have an <br/>ongoing chat. To open a new one, <br/>please ask to close the active chat."
	},
	es: {
		captchaExpired: 'The captcha expiró. Por favor intentalo de nuevo.',
		contactSupport: 'Por favor, contacta soporte al paciente.',
		insuranceDisabled: 'Lo sentimos, tu aseguradora no cubre este servicio.',
		insuranceDisabledError: 'Desafortunadamente no pudimos validar su acceso al servicio, por favor póngase en contacto con el chat de Atención al cliente',
		featureDisabled: 'Desafortunadamente, no pudimos validar su acceso al servicio, póngase en contacto con el chat de "Atención al cliente"',
		healoWelcome: '¡Bienvenido a tu nueva experiencia de salud!',
		healowCreate: 'Si no has iniciado sesión antes en este portal es posible que tengas que crear una cuenta nueva',
		healowCreateMobile: 'Si no has iniciado sesión antes en esta aplicación es posible que tengas que crear una cuenta nueva',
		invalidBirthdate: 'Fecha de nacimiento inválida. Por favor intentalo de nuevo.',
		invalidDate: 'Fecha inválida. Por favor intentalo de nuevo.',
		invalidDateRange: 'Fecha inválida. El campo \'Hasta\' no puede tener una fecha anterior al campo \'Desde\'',
		invalidDateRangeYearAfter: 'El intervalo de fechas debe ser inferior a $yearNumber año.',
		invalidEmail: 'Correo electrónico invalido. Por favor inténtalo de nuevo.',
		invalidFormat: 'El formato de archivo que tratas de subir no está permitido, por favor elige un formato diferente.',
		invalidFormatGeneric: 'El formato no es válido.',
		invalidName: 'El formato del nombre no es válido. Por favor inténtalo de nuevo.',
		invalidPassword: 'La contraseña no cumple con los requisitos. Ingrese una nueva contraseña.',
		invalidPhone: 'Por favor ingresa un teléfono de celular válido. Ejemplo: (XXX)XXX-XXXX',
		loginFailure: 'Combinación incorrecta de correo electrónico y contraseña. Por favor inténtalo de nuevo.',
		max: 'El número de carácteres supera el límite. Por favor inténtalo de nuevo.',
		maxMB3: 'El tamaño del archivo que intentas subir excede el peso máximo, por favor usa uno de 3MB o menos.',
		min: 'El número de carácteres es menor de lo requerido. Por favor inténtalo de nuevo.',
		minCode: 'El código no está completo. Por favor ingresa los 6 dígitos.',
		passwordMatch: 'La contraseñas deben coincidir. Por favor inténtalo de nuevo.',
		required: 'Este campo es requerido.',
		sessionExpired: 'Tu sesión ha expirado. Por favor ingresa de nuevo.',
		somethingWentWrong: 'Algo salió mal. Inténtalo de nuevo',
		wrongCombination: 'Combinación incorrecta. Por favor inténtalo de nuevo.',

		// Codes
		code10: 'El usuario existe parcialmente.',
		code20: 'Esta cuenta ya existe. Por favor verifica o entra a login.',
		code25: 'Los datos ingresados son incorrectos. Porfavor contacte a soporte.',
		code30: 'Combinación incorrecta. Por favor inténtalo de nuevo.',
		code40: 'The e-mail account already exists.',
		code50: 'Éxito.',
		code60: 'Token inválido.',
		code70: 'Token expirado.',
		code80: 'Información incorrecta. Por favor inténtalo de nuevo.',
		code90: 'Parámetro inválido.',
		code100: 'ID inválido.',
		code101: "El tiempo para creación de cuenta ha expirado. Por favor inicia de nuevo.",
		code110: 'El usuario no es Florida Blue.',
		code120: 'Error enviando el e-mail.',
		code130: 'Error enviando SMS.',
		code140: 'El usuario no se encuentra completamente en ECW. Por favor, contacta soporte al paciente.',
		code150: 'El código de seguridad es incorrecto. Por favor inténtalo de nuevo.',
		code151: 'Tu código de verificación no es correcto. Te queda 1 intento',
		code160: 'Tu código de seguridad ha expirado. Por favor solicita uno nuevo e inténtalo nuevamente.',
		code170: 'Correo electrónico ya enviado',
		code190: 'Lo sentimos, la información que proporcionó no coincide con nuestros registros. Haga clic en el ícono "Chat de soporte 24/7" para hablar con un agente y obtener ayuda.',
		code200: 'Correo electrónico expirado. Por favor contacta a soporte al cliente.',
		code210: 'El proceso de registración falló, por favor contáctese con soporte al paciente',
		code999: 'Algo salió mal.',
		codeNoContactMatch: 'No coincide el mail/telefono ingresado con tu base actual, en caso de necesitar ayuda contacta a soporte',
		codeNoMatch: 'El código de verificación no es correcto, por favor verifíquelo y escriba el último enviado.',
		code290: 'Al parecer tienes una cuenta duplicada. Por favor contacta con el chat de soporte',
		code400: 'El número de cuenta Sanitas que ingresaste ya está asociado a una cuenta existente en nuestro sistema.',
		code502: "Lo sentimos, no ha sido posible validar su centro medico. No podremos continuar. Por favor contacte a soporte para recibir ayuda.",

		insuranceError: 'Ha ocurrido un problema actualizando tu información. Por favor inténtalo más tarde o contacta a nuestro chat de soporte.',
        emailNotValid: 'Por favor ingresa un correo electrónico válido para continuar',
		troubleWithChat: "Lo sentimos! parece que actualmente <br/>tienes un chat en curso. Para abrir uno nuevo, <br/>por favor, solicite el cierre del chat activo."
	}
};

export default nsErrors;
