import BREAKPOINTS from "app/ui-core/utils/breakpoints";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface ChipProps { 
  text: string, 
  valueSelected?: (value: string) => void,
  currentValue?: string
}

const Chip = ({ text, valueSelected, currentValue }: ChipProps) => {

  const [selected, setSelected] = useState(false)

  const handleClick = () => {
    setSelected( selected => !selected )
    valueSelected?.( text )
  }

  useEffect(() => {
    if(currentValue === text) setSelected(true)
    if(currentValue !== text) setSelected(false)
  }, [currentValue])

  return (
    <Content onClick={handleClick} selected={selected}>
        {text}
    </Content>
  );
};

const Content = styled.div<{selected: boolean}>`
	width: auto;
  max-width: 284px;
	font-family: 'Proxima Nova';
  display: grid;
  place-items: center;
	cursor: pointer;
    min-height: 43px;
    border-radius: 2.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
	padding: 0px 1rem;
	font-size: 14px;
	line-height: 1.9rem;
	text-align: center;
	font-weight: 600;	
  word-break: break-word;
  box-shadow: ${({ selected }) => selected ? '1px 5px 5px #00000040' : ''};

	${({ color = 'accent', theme: { colors }, selected }) => `
		border: solid 2px ${colors[color]};
		${!selected ? `
			background-color: ${colors.light};
			color: ${colors[color]};
		` : `
			background-color: ${colors[color]};
			color: ${colors.light};
		`}
	`}
`;

export default Chip;
