export interface NeedHelpRequest {
  titleES: string;
  titleEN: string;
  descriptionES: string;
  descriptionEN: string;
  cardHeaderEs: string;
  cardHeaderEn: string;
  url: string;
  phoneNumber: string;
}

export interface EducationalResoucesRs {
  name: string,
  type: string,
  fileType: string,
  size: string,
  creationDate: string,
  description: string,
  lastEditDate: string,
  creator: string,
  uuid: string
}


export interface FHSRequest {
  comments: string;
  date: string;
  email: string;
  idEcw: string;
  inEnglish: boolean;
  mobile: string;
  name: string;
  state: string;
}

export interface AnxietyRegistersResponse {
  "dateTime": string;
  "uuid": string;
  "authUid": string;
  "thinking": string;
  "moreAbout": string;
  "actions": string;
  "feeling": string;
  "physicalSymptoms": string;
  "patientId": string;
  "patientName": string;
  "state": string;
  "email": string;
  "dob": string;
  "gender": string;
}

export interface AnxietyRegisterInfo {
  anxietyRegistersDtoList: AnxietyRegistersResponse[];
  dob: string;
  email: string;
  gender: string;
  patientName: string;
  state: string;
}

export interface AnxietyRegistersParams {
  "authUid": string; 
  "state": string;
}

export interface AnxietyParameter {
  "uuid": string;
  "type": TypeAnxietyParameter;
  "contentEs": string;
  "contentEn": string;
  "status": boolean;
  "order": number;
}

export enum TypeAnxietyParameter {
  THINKING = 'Thinking',
  FEELING = 'Feeling',
  PHYSICAL_SYMPTOMS = 'Physical Symptoms',
}



