import PrivateHttpClient from '../privateHttpClient';
// DOMAIN
import { AppointmentsService } from '../../../domain/useCases/contracts/appointmentsService'
import appointmentMapper from '../mappers/appointmentMapper';

const endpoint = 'appointment';

const AppointmentsRepository: AppointmentsService = {
	createToken: (data: any) => {
		return PrivateHttpClient.post(`${endpoint}/tokenize`, appointmentMapper.mapToCreateToken(data));
	}
};

export default AppointmentsRepository;
