import { GENDERS } from "./constants";

interface LisGenderTypes { 
    key: number, 
    label: string, 
    value: string 
}

export const validateGender = (value: string = '', listGender: LisGenderTypes[]): LisGenderTypes | undefined => {

    if(!value) return undefined;

    const status = {
        m: GENDERS.male,
        f: GENDERS.female,
        u: GENDERS.unknown,
    }

    const valueToLowerCase = value.toLowerCase();

    if( status[valueToLowerCase] ) return listGender.find((v) => v.value === status[valueToLowerCase]);

    return listGender.find((v) => v.value === valueToLowerCase) ;

};