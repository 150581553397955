import { openBookAppointment } from 'adapter/chat/chatSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import chatSelectors from '../../../../adapter/chat/chatSelectors';
import { chatActions } from '../../../../adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import appointmentIcon from '../../../assets/icons/calendar_icon.svg';
import previusAppoinmentsIcon from '../../../assets/images/previusAppoinmentsIcon.svg';
import upcomingAppointmentsIcon from '../../../assets/images/upcomingAppointmentsIcon.svg';
import upcoming from '../../../assets/icons/upcoming.svg';
import previous from '../../../assets/icons/previous.svg';
import { Image, KeraltyButton, KeraltyError, KeraltyIcon, KeraltyText } from '../../../components/atoms';
import { KeraltyCard, KeraltyModal, SectionWidget } from '../../../components/molecules';
import PreviousAppointments from '../../../components/organisms/getCare/PreviousAppt';
import UpcomingApptsTab from '../../../components/organisms/getCare/upcomingApptsTab';
import { Card, CenteredWrapper, Column, Container, IconLinkRow, Loader, Row, SpaceWrapper } from '../../../components/templates';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import ROUTES from '../../../ui-core/utils/routes';
import theme from '../../../ui-core/utils/theme';
import { useRouteQuery } from '../../../hooks/useRouteQuery';
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import useIsMobile from 'app/hooks/useIsMobile';

const { MAIN, MENTAL_HEALTH: CURRENT } = ROUTES;

const RightContent = styled(Container)`
  margin: 1rem 0 5rem 0;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin-bottom: 0;
  };
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    // margin-top: 5vh;
	padding-inline: 15px;
  }
`;

let getVal = (BREAKPOINTS.maxPhoneWidth).split("px");
let getNum = parseInt(getVal[0]) + 1;
let widthEnd = getNum.toString() + 'px'; //600px;
const TitleContiner = styled(Column)`
  h2 {
    margin-top: 0rem;
  }

  @media (max-width: ${widthEnd}) {
   
	//max-width: ${BREAKPOINTS.maxPhoneWidth};
    //margin-bottom: -2rem;		
  }
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 12.75rem;
  height: auto;
  width: 28.5rem;
  margin: 3.8366rem 1.455rem;
  border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;
  	h2 {
		margin: 0rem;
		font-size: 1.5rem;
		line-height: 2.25rem;
	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const OptionColumn = styled(Column)`
  padding-right: 0;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    justify-content: center;
  }
`;

const CardsRow = styled(Row)`
  justify-content: center;
  padding: 0rem;
`;

const BeWellMain: React.FC<any> = ({ isPublic, bg, tableWidth }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(chatSelectors.selectIsLoading);
	const error = useAppSelector(chatSelectors.selectError);
	const { goBack, replace } = useHistory();
	const { push } = useHistory();
	const { subTab } = useParams();
	const { query } = useRouteQuery();
	const trigger = query.get('trigger');
	const [ageWidth, setWidth] = useState(window.innerWidth);
	const isMobile = useIsMobile();
	const [showRememberInfo, setShowRememberInfo] = useState<boolean>(false)

	useEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
		});
		return () => {
			window.removeEventListener("resize", () => {
				setWidth(window.innerWidth);
			})
		}
	}, []);

	const handleBookAppointment = useCallback(() => {
		setShowRememberInfo( false )
		dispatch(openBookAppointment());
		replace({ search: '' });
	}, [dispatch, replace]);

	useEffect(() => {
		return () => {
			dispatch(chatActions.setProp({ error: undefined }));
		};
	}, [dispatch]);

	useEffect(() => {
		if (trigger === 'book-appointment') setTimeout(handleBookAppointment, 0);
	}, [trigger, handleBookAppointment]);

	const baseTitle = t('mentalHealth.beWellProgram').split(' ')
	const title = baseTitle[0] + ' ' + baseTitle[1].toLowerCase()

	const handlePrevApt = () => {
		push(`/${MAIN.mentalHealth}/${CURRENT.appointments}/${CURRENT.beWellProgram}/${CURRENT.previousAppointments}`);
	};

	const handleUpAppts = () => {
		push(`/${MAIN.mentalHealth}/${CURRENT.appointments}/${CURRENT.beWellProgram}/${CURRENT.upcomingAppointments}`);
	};

	  
	const RememberInfo = () => (
		<Row>
			<Row noMargin style={{ paddingRight: 20, paddingLeft: 10, justifyContent: 'center' }}>
				<div>
					<KeraltyIcon
						iconClass="fas fa-exclamation"
						color="orange"
						size="medium"
						style={{
							margin: 20,
							flex: 1,
							align: "center",
						}}
						rounded
					/>
				</div>
				<SpaceWrapper mb={1}>
					<Container centered={'x'} style={{ paddingRigth: 25 }}>
						<KeraltyText
							type={'label'}
							align={'center'}
							style={{ fontSize:  '20px' , paddingBottom: 35, color: '#022F58' }}
						>
							{t('mentalHealth.remember')}
						</KeraltyText>
						<KeraltyText
							type={'label'}
							align={'center'}
							fontWeight={'400'}
							style={{
								fontSize: isMobile ? '18px' : '20px',
								lineHeight: isMobile ? '18px' : '23.5px',
							}}
						>
							{t('mentalHealth.rememberDetail')}
						</KeraltyText>
					</Container>
				</SpaceWrapper>
			</Row>
			<Container centered={'x'} style={{ paddingTop: isMobile ? 0 : 10 }}>
				<KeraltyText
					bold
					type={'link'}
					align="center"
					color={'#3CA70D'}
					style={{
						textTransform: 'uppercase',
						paddingTop: 25,
						paddingBottom: 10,
						fontSize: isMobile ? '18px' : '16px',
						lineHeight: isMobile ? '21.92px' : '19.49px',
						fontWeight: 700,
					}}
					onClick={handleBookAppointment}
				>
					{t('button.continue')}
				</KeraltyText>
			</Container>
		</Row>
	);


	return (
		<>
			{isLoading && <Loader />}
			{
				showRememberInfo &&
				(
					<KeraltyModal style={isMobile ? {width:'330px', minHeight: '342px'} : {width:'699px', minHeight: '372px'}}>
						<RememberInfo />
					</KeraltyModal>
				)
			}
			<RightContent centered="x" top='10'>
				{/* <div style={{ marginLeft: '2%', width: '100%' }}>
					<IconLinkRow
						onClick={goBack}
						color="accent"
						icon={arrowAltCircleLeftIcon}
						iconAlt={t('imgNicons.backArrow')}
						text={t('routes.back')}
					/>
				</div> */}


					<Row>
						<TitleContiner lg={12}>
							<KeraltyText type="title" align={isMobile ? 'left' : 'center'}style={{textTransform:'none'}}>{t('mentalHealth.beWellProgram')}</KeraltyText>
						</TitleContiner>
					</Row>
					{isMobile &&
						<Row  style={{paddingBottom:15,  flexDirection:'row'}}>
							<div style={{width:'20%', marginRight:-10}}>
								<KeraltyIcon
									iconClass="fas fa-exclamation"
									color="accent"
									size="common"
									style={{
										margin: 15,
										align: "left",
									}}
								rounded
								/>
							</div>
							<div style={{ width:'80%'}}>

								
							<KeraltyText  fontSize={'14px'} type="label" color='dark' align={'left'} style={{ paddingInline: 10, fontSize:14  }}>{t('mentalHealth.determine')}</KeraltyText>

							</div>
							
							
						</Row>
						
						
					}
					

				{(
					<CardsRow noMargin>
						<Column lg={0} md={0} sm={12}>
							<CenteredWrapper>
								<Card onClick={() => setShowRememberInfo( true )}>
									<SectionWidget
										img={appointmentIcon}
										text={capitalizeJustFirst(t('button.bookAppointment'))}
										description={t('mentalHealth.book')}
										alt={t('imgNicons.bookAppointmentIcon')}
										descriptionFontSize={'12px'}
										/>
								</Card>
							</CenteredWrapper>
						</Column>
						<OptionColumn lg={4} md={4} sm={0} >
							<Row style={{ paddingInline: '25%' }}>

								<GetCareNowButton color="light" squareRadius={true} onClick={() => setShowRememberInfo( true )}>
									<span>
										<Image
											src={appointmentIcon}
											width={theme.sizes.icon.big}
											height={theme.sizes.icon.big}
											alt={t('imgNicons.bookAppointmentIcon')}
										/>

										<KeraltyText type="title" fontWeight={'400'} style={{ fontWeight: 'normal', fontSize: '16px' }}>{t('button.bookAppointment')}</KeraltyText>
										<div style={{width:'204px', display:'inline-block', justifyContent:'center'}}>
											<KeraltyText type="title" color={'#747474'} style={{ color: '#7A7A7A', fontSize: '12px', lineHeight: '18px', wordBreak: 'break-word' }}>{capitalizeJustFirst(t('mentalHealth.book'))}</KeraltyText>
										</div>
									</span>
								</GetCareNowButton>

							</Row>
						</OptionColumn>
						<Column lg={0} md={0} sm={12}>
							<CenteredWrapper>
								<Card onClick={handleUpAppts}>
									<SectionWidget
										img={upcomingAppointmentsIcon}
										text={capitalizeJustFirst(t('getCare.upcomingAppointments'))}
										description={t('getCare.upcomingAppointmentsDesc')}
										alt={t('imgNicons.locationIcon')}
										descriptionFontSize={'12px'}
										/>
										
								</Card>
							</CenteredWrapper>
						</Column>
						<OptionColumn lg={4} md={4} sm={0} >
							<Row style={{ paddingInline: '25%' }}>

								<GetCareNowButton color="light" squareRadius={true} onClick={handleUpAppts}>
									<span>
										<Image
											src={upcoming}
											width={theme.sizes.icon.big}
											height={theme.sizes.icon.big}
											alt={t('imgNicons.bookAppointmentIcon')}
										/>

										<KeraltyText type="title" fontWeight={'400'} style={{ fontWeight: 'normal', fontSize: '16px' }}>{t('getCare.upcomingAppointments')}</KeraltyText>
										<div style={{width:'204px', display:'inline-block', justifyContent:'center'}}>
											<KeraltyText type="title" color={'#747474'} style={{ color: '#7A7A7A', fontSize: '12px', lineHeight: '18px', wordBreak: 'break-word' }}>{capitalizeJustFirst(t('getCare.upcomingAppointmentsDesc'))}</KeraltyText>
										</div>
									</span>
								</GetCareNowButton>

							</Row>
						</OptionColumn>
						<Column lg={0} md={0} sm={12}>
							<CenteredWrapper>
								<Card onClick={handlePrevApt}>
									<SectionWidget
										img={previusAppoinmentsIcon}
										text={capitalizeJustFirst(t('getCare.prevAppointments'))}
										description={t('getCare.prevAppointmentsDesc')}
										alt={t('imgNicons.locationIcon')} 
										descriptionFontSize={'12px'}
										/>
								</Card>
							</CenteredWrapper>
						</Column>
						<OptionColumn lg={4} md={4} sm={0} >
							<Row style={{ paddingInline: '25%' }}>

								<GetCareNowButton color="light" squareRadius={true} onClick={handlePrevApt}>
									<span>
										<Image
											src={previous}
											width={theme.sizes.icon.big}
											height={theme.sizes.icon.big}
											alt={t('imgNicons.bookAppointmentIcon')}
										/>

										<KeraltyText type="title" fontWeight={'400'} style={{ fontWeight: 'normal', fontSize: '16px' }}>{t('getCare.prevAppointments')}</KeraltyText>
										<div style={{width:'204px', display:'inline-block', justifyContent:'center'}}>
											<KeraltyText type="title" color={'#747474'} style={{ color: '#7A7A7A', fontSize: '12px', lineHeight: '18px', wordBreak: 'break-word' }}>{capitalizeJustFirst(t('getCare.prevAppointmentsDesc'))}</KeraltyText>
										</div>
									</span>
								</GetCareNowButton>


							</Row>
						</OptionColumn>
						<Column lg={12}>
							{error && <KeraltyError error={t('errors.somethingWentWrong')}></KeraltyError>}
						</Column>
					</CardsRow>
				)}
				{!isMobile &&<KeraltyText type="info" color='dark' style={{ paddingInline: isMobile ? 20 : '20%', paddingTop: isMobile ? 45 : 0 }}>{t('mentalHealth.determine')}</KeraltyText>}
			</RightContent>
		</>
	);
};

export default BeWellMain;
