const nsRegister = {
  en: {
    lenguage: 'en',
    accept: 'Accept',
    accountInformation: 'Account information',
    address1: 'Address 1',
    address2: 'Address 2',
    agree: 'I read and agree to the',
    agree2: 'I read and agree to the',
    accountAlreadyRegistered: 'This account already exists, please try again.',
    personalInfo: 'Personal information',
    basicPersonalInfo: 'Personal information (Basic)',
    complementaryPersonalInfo: 'Personal information (Complementary)',
    verification: 'Verification',
    birthdate: 'Date of birth',
    birthdatePlaceholder: 'mm/ dd/ yyyy',
    preferredDatePlaceholder: 'MM/DD/YYYY',
    preferredHourPlaceholder: 'HH:MM',
    cellphone: 'Mobile',
    city: 'City',
    codeVerification: 'Code verification',
    confirmLoginInfo: 'Confirm Login Information',
    confirmPassword: 'Confirm your new password',
    contactInfo: 'Contact information',
    contactSecurity: 'Contact and security',
    createAccount: 'Create account',
    email: 'Email',
    firstName: 'First name',
    gender: {
      female: 'Female',
      male: 'Male',
      unknown: 'Unknown',
    },
    genderLabel: 'Gender at birth',
    genderPlaceholder: 'Choose one',
    homePhone: 'Home phone',
    isAlreadyRegistered: 'Are you already a registered user of Sanitas?',
    lastName: 'Last name',
    medicalServices: 'Patient Status',
    medicalService: {
      serviceBefore: 'Have you received medical services at Sanitas?',
      serviceOption: 'Please select an option:',
      withAccNumber: 'I know my Sanitas account number',
      withOutAccNumber: 'I don`t know my Sanitas account number',
    },
    next: 'NEXT',
    no: 'No',
    noAccountMatch: 'No account matches your information. Please check the details and try again.',
    password: 'Create a password',
    passwordInfo:
      'At least 8 characters, one special character (e.g., ! @ # ?), one number, one uppercase letter.',
    passwordPlaceholder: 'Password',
    passwordStrenght: 'Password strength:',
    security: 'Security',
    ssn: 'SSN',
    state: 'State of service',
    serviceUnavailable: 'Sorry, service is currently unavailable. Please try again later.',
    title: 'Create account',
    valCodeScreen: {
      codeNotReceived: '*I did not receive the validation code',
      codeSentTimer:
        'A code has been sent to your selected option. Please enter your code to log in',
      notificationWaySelect:
        'Select the mobile number or email where you want to receive the verification code. By clicking in one of the following options you are accepting to be contacted by email or by phone via a text message.',
      // notificationWaySelect:
      // 	'Select how you’d like to receive your verification code/link.',
      // notificationWaySelect2:
      // 	'By clicking next, you are consenting to be contacted by Sanitas via email or text.',
      oneMoreStepText:
        'Thank you for verifying your account. Now, complete your one time Sanitas patient registration form.',
      selectEmail: 'Email me',
      selectPhone: 'Text me',
      codeSent: 'A link has been sent to your selected option. Please validate to continue.',
      valCodeSubTitle: 'Type the validation code sent to your phone.',
      valCodeSubTitleEmail: 'Type the validation code sent to your email.',
      valCodeSubTitleForgotEmail: 'Type the validation code sent to your email',
      valCodeSubTitleForgotSms: 'Type the validation code sent to your mobile',
      valCodeTitle: 'Validation code',
      validation: 'Verification',
      compled:
        'Thank you for verifying your account. Now, complete your one time Sanitas patient registration form.',
      SelectTheEmail: 'Select the email',
      SelectTheSms: 'Select the mobile number',
    },
    yes: 'Yes',
    zipCode: 'Zip code',
    buttonNext: 'CONTINUE',
    trier: 'The verification code is not correct. You have ',
    trier2: ' attempts left. ',
    timer: ' times',
    messageSanitasAccountNumber:
      '*If you don’t remember your Sanitas account number, please contact Support Chat',
  },
  es: {
    lenguage: 'es',
    accept: 'Acepto',
    accountInformation: 'Información de la cuenta',
    address1: 'Dirección 1',
    address2: 'Dirección 2',
    agree: 'He leído y acepto la',
    agree2: 'He leído y acepto los',
    accountAlreadyRegistered: 'Esta cuenta ya existe. Por favor verifica o entra a login.',
    personalInfo: 'Información personal',
    basicPersonalInfo: 'Información personal (Básica)',
    complementaryPersonalInfo: 'Información personal (Complementaria)',
    verification: 'Verificación',
    birthdate: 'Fecha de nacimiento',
    birthdatePlaceholder: 'mm/ dd/ yyyy',
    preferredDatePlaceholder: 'MM/DD/YYYY',
    preferredHourPlaceholder: 'HH:MM',
    cellphone: 'Celular',
    city: 'Ciudad',
    codeVerification: 'Código de verificación',
    confirmLoginInfo: 'Confirmar información de registro',
    confirmPassword: 'Confirma tu nueva contraseña',
    contactInfo: 'Información de contacto',
    contactSecurity: 'Contacto y contraseña',
    createAccount: 'Crear cuenta',
    email: 'Correo electrónico',
    firstName: 'Primer nombre',
    gender: {
      female: 'Femenino',
      male: 'Masculino',
      unknown: 'Desconocido',
    },
    genderLabel: 'Género al nacer',
    genderPlaceholder: 'Elige uno',
    homePhone: 'Teléfono fijo',
    isAlreadyRegistered: '¿Ya eres usuario registrado de Sanitas?',
    lastName: 'Apellido',
    medicalServices: 'Estado del paciente',
    medicalService: {
      serviceBefore: '¿Has recibido servicios médicos en Sanitas con anterioridad?',
      serviceOption: 'Elige una opción:',
      withAccNumber: 'Tengo mi número de cuenta Sanitas',
      withOutAccNumber: 'No se mi número de cuenta de Sanitas',
    },
    next: 'Siguiente',
    no: 'No',
    noAccountMatch:
      'No se encontró ninguna cuenta con la información proporcionada. Por favor verifica e inténtalo de nuevo.',
    password: 'Crear una contraseña',
    passwordInfo:
      'Al menos 8 caracteres, un carácter especial (por ejemplo,! @ #?), un número, una letra mayúscula.',
    passwordPlaceholder: 'Contraseña',
    passwordStrenght: 'Seguridad de contraseña',
    security: 'Contraseña',
    ssn: 'SSN',
    state: 'Estado de servicio',
    serviceUnavailable:
      'El servicio no está disponible en este momento. Vuelve a intentarlo más tarde.',
    title: 'Crear cuenta',
    valCodeScreen: {
      codeNotReceived: '*No recibí el código de verificación',
      codeSentTimer:
        'Un código ha sido enviado a la opción seleccionada. Ingresa tu código para iniciar sesión.',
      notificationWaySelect:
        'Selecciona el número de celular o correo electrónico donde quieres recibir el método de verificación. Al elegir una de las opciones estás accediendo a ser contactado por teléfono o correo electrónico via mensaje de texto.',
      // notificationWaySelect:
      // 	'Seleccione cómo quiere recibir su código de verificación.',
      // notificationWaySelect2: 'Al hacer click en "siguiente" se está dando consentimiento para ser contactado por Sánitas via correo electrónico o mensaje de texto.',
      oneMoreStepText:
        'Gracias por verificar su cuenta. A continuación complete el formulario de registro de paciente de Sanitas.',
      selectEmail: 'Contactarme por correo electrónico',
      selectPhone: 'Contactarme por mensaje de texto',
      codeSent:
        'Se ha enviado un enlace a la opción seleccionada. Por favor valide para continuar.',
      valCodeSubTitle: 'Digita el código de verificación enviado a tu teléfono celular.',
      valCodeSubTitleEmail: 'Digita el código de verificación enviado a tu correo electronico.',
      valCodeSubTitleForgotEmail:
        'Escribe el código de validación enviado a tu correo electrónico.',
      valCodeSubTitleForgotSms: 'Escribe el código de validación enviado a tu teléfono móvil.',
      valCodeTitle: 'Código de validación',
      validation: 'Verificación',
      compled:
        '¡Solo falta un paso! Completa la siguiente información para acceder a nuestros servicios.',
      SelectTheEmail: 'Seleccionar Correo',
      SelectTheSms: 'Seleccionar Número Móvil',
    },
    yes: 'Si',
    zipCode: 'Código postal',
    buttonNext: 'CONTINUAR',
    trier: 'Tu código de verificación no es correcto. Te queda ',
    trier2: ' intento ',
    timer: ' munitos restantes',
    messageSanitasAccountNumber:
      '*Si no recuerdas tu número de cuenta Sanitas, por favor contacta a Soporte al paciente',
  },
};

export default nsRegister;
