import appConfig from 'config';
import AuthRepository from '../../infrastructure/keraltyApi/repositories/auth';
// DOMAIN
import { VirtualAssistantService } from '../../domain/useCases/contracts/virtualAssistantService';

const VirtualAssistantRepository: VirtualAssistantService = {
  load: ({ lang = 'en', ...userData }) => {
    const {
      THEME: theme,
      ENV: environment,
      DIALOG_FLOW: dialogFlowSettings,
      LAN,
    } = appConfig.VIRTUAL_ASSISTANT;

    const { PROC_ID: procedureId } = !lang || lang !== 'es' ? LAN.EN : LAN.ES;

    const loadWidget = (token) => {

      /*console.log('enviado --> ' , userData.sex == 'male' ? 'M' : (userData.sex == 'female' ? 'F' : (userData.sex == 'unknown' ? 'U' : userData.sex)));
      
      console.log({userInfo: {
        userType: 'nonpublic',
        memberid: userData.idEcw || '0',
        dob: userData.birthdate,
        gender: userData.sex,
        organization_user_id: userData.authUid,
        environment,
        // ...userData,
        ...dialogFlowSettings,
      }});*/

      
      // @ts-ignore
      return SenselyWidget.init({
        // Sensely provided username & password (not required if using token)
        theme,
        token,
        procedureId,
        lang,
        // Optional additional data to control the conversation
        conversationData: {
          userInfo: {
            userType: 'nonpublic',
            memberid: userData.idEcw || '0',
            dob: userData.birthdate,
            gender: userData.sex == 'male' ? 'M' : (userData.sex == 'female' ? 'F' : (userData.sex == 'unknown' ? 'U' : userData.sex)),
            organization_user_id: userData.authUid,
            environment,
            // ...userData,
            ...dialogFlowSettings,
          },
        },
      }).catch((err) => {
        console.error('VirtualAssistant Sensely', err);
        return {
          name: 'Virtual Assistant Load Failure',
          message: 'virtualAssistantLoad',
          code: 92, // TODO: MAP code92 error
        };
      });
    };

    return AuthRepository.senselyAuthentication({ locale: lang })
      .then((data) => {
        const { access_token: token } = data;
        return loadWidget(token);
      })
      .catch(() => {
        loadWidget('');
      });
  },
};

export default VirtualAssistantRepository;

// -------------------------------------------------------
// "memberid": "0",
// "userType": "nonpublic",
// "dlgflowProject": "usa-kdc-dev-dialogflow-a403",
// "dlgflowEnv": "keralty-dev",
// "dlgflowRegion": "us-east1",
// "dlgflowSymptomAgentID": "2671f595-b277-4206-ac2a-32796e78598a"

// -------------------------------------------------------
// ...(payload.userInfo || {}),
// usertype: !payload.memberid ? 'public' : 'nonpublic',
// memberid: !payload.memberid ? "0" : payload.memberid,
// dlgflowEnv: "keralty_dev",
// dlgflowProject: "usa-kdc-dev-dialogflow-a403",
// dlgflowRegion: "us-east1",
// dlgflowSymptomAgentID: "2671f595-b277-4206-ac2a-32796e78598a",
