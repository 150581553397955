import { UserAvaility } from '../entities/userAvaility';

export const DefaultAvailityInfo: UserAvaility = {
	firstName: '',
	lastName: '',
	birthDate: '',
    memberId: '',
	isActive: false,
	authUid: '',
	state: '',
	companyId:'',
    insuranceName:'',
};
