export const capitalize = (text: string) => {
	return text?.split(' ')
		.map((sub: string) =>
			`${sub.substring(0, 1).toUpperCase()}${sub.substring(1, sub.length).toLowerCase()}`
		).join(' ');
};

export const capitalizeJustFirst = (text: string) => {
	return `${text.substring(0, 1).toUpperCase()}${text.substring(1, text.length).toLowerCase()}`;
};
