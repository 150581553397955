const MAIN = {
	appointments: 'appointments',
	chat: 'chat',
	check: 'check',
	fb: 'fb',
	forgot: 'forgot-password',
	unblock: 'unblock-account',
	guest: 'guest',
	getCare: 'get-care',
	home: 'home',
	legal: 'legal',
	login: 'login',
	myAccount: 'my-account',
	myHealth: 'my-health',
	notFound: 'not-found',
	patientRegistration: 'patient-registration',
	previousAppointments: 'previous-appointments',
	register: 'register',
	selectLocation: 'select-location',
	sso: 'sso',
	virtualAssistantChat: 'virtual-assistant',
	mentalHealth: 'mental-health',
	authentication: 'authentication',
	wellness: 'wellness',
};

const REGISTER = {
	fb: 'fb',
	sanitas: 'sanitas',
	public: 'public',
	hadSanitas: 'had-sanitas',
	accountType: 'account-type',
	account: 'account',
	confirm: 'confirm',
	basic: 'basic',
	complementary: 'complementary',
	contact: 'contact',
	codeSent: 'code-sent',
	secondSent:'second-sent',
	verify: 'verify',
	oneMore: 'one-more',
	errorVerify:'error-verify',
	trier:'triers',
	sendCode: 'send-code',
	chooseOption: 'choose-option',
	status: 'status',
};

const PATIENT_REGISTRATION = {
	responsible: 'responsible-party',
	insurance: 'insurance',
	consent: 'consent'
};

const LEGAL = {
	privacy: 'privacy-policy',
	terms: 'terms-of-service',
	third: 'third-party-notices',
};

const FORGOT = {
	recover: 'recover-password',
	contact: 'contact-form',
	verifyTwo: 'verifyTwo',
	newPass: 'new-password',
	success: 'password-successfully-changed',
	verifyFirst: 'verify-first',
	verifyLast: 'verify-last',
	verifyLastError: 'verify-last-Error',
	verifyCodeTimer: 'verify-code-timer',
	verifyExpiredCode: 'verify-expired-code',
	verifiedCode: 'verified-code'
};

const MY_HEALTH = {
	labResults: 'lab-results',
	medication: 'medications',
	referrals: 'referrals',
	immunization: 'immunization',
	visitSummary: 'visit-summary',
	currentMedication:'current-Medication',
	referralsDetails:'referrals-Details',
};
const MENTAL_HEALTH = {
	mental: 'mental',
	about: 'about',
	help:'need-help',
	edures: 'edu-resources',
	otherResources: 'other-resources',
	lifestyle: 'lifestyle',
	appointments: 'appointments',
	appointmentsFHS: 'florida-healthcare-system',
	appointmentsFormFHS: 'form-fhs',
	anxiety: 'anxiety',
	anxietyDetails: 'anxiety-details',
	thinkingOptions: 'thinking-options',
	thinkingDetails: 'thinking-details',
	actionsDetails: 'actions-details',
	feelingOptions: 'feeling-options',
	physicalSymptomsOptions: 'physical-symptoms-options',
	cbt: 'cbt',
	youMadeIt: 'you-made-it',
	selfmanagement: 'selfmanagement',
	beWellProgram:'be-well-program', 
	upcomingAppointments: 'upcoming-appointments',
	previousAppointments: 'previous-appointments',
	stinkingThinking:'stinking-thinking'
};

const GET_CARE = {
	getCareNow: 'get-care-now',
	appointments: 'appointments',
	talkToaDoctor: 'talk-to-a-doctor',
	carePrograms: 'care-programs',
	televisit: 'televisit',
	televisitPayment: 'televisit-payment',
	previousAppointments: 'previous-appointments',
	upcomingAppointments: 'upcoming-appointments',
	ChatWithDoc:'chat-with-doctor',
	paymentStatus: 'payment-status'
};

const MY_ACCOUNT = {
	personalInfo: 'personal-information',
	myInsurance: 'my-insurance',
	deleteAccount: 'delete-account',
	editAccount: 'edit-account'
};

const UNBLOCK = {
	recover: 'recover-password-unblock',
	contact: 'contact-form-unblock',
	verify: 'verify-option-unblock',
	verifyCode: 'verify-unblock-code',
	verificationStatus: 'verification-status',
	newPass: 'new-password-unblock',
	success: 'password-successfully-changed-unblock',
};

const ROUTES = {
	MAIN,
	REGISTER,
	PATIENT_REGISTRATION,
	LEGAL,
	FORGOT,
	MY_HEALTH,
	GET_CARE,
	MY_ACCOUNT,
	UNBLOCK,
	MENTAL_HEALTH
};

export default ROUTES;
