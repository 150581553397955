import { useAppSelector } from 'adapter/hooks';
import { idEcw } from 'adapter/user/userSlice';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import { EcwRepository } from 'infrastructure/keraltyApi/repositories/ecw';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { VisitSummaryInfo } from '../../../../domain/entities/visitSummaryInfo';
import EmptyLabs from '../../../assets/icons/Empty_labs.svg';
import NoResult from '../../../assets/icons/noResult.svg';
import { KeraltyText } from '../../../components/atoms';
import { Column, Loader, Row } from '../../../components/templates';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import VisitSummaryCards from './VisitSummaryCards';
import useIsMobile from '../../../hooks/useIsMobile';
import TabWrapper from './TabWrapper';
import { KeraltyFilterDate } from '../../molecules';
import i18n from 'i18next';
import FilterImg from '../../../assets/images/filter.png';
import FilterEs from '../../../assets/images/filter-es.png';
import { basicScrollIntoTop } from 'app/ui-core/utils/screen';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';
import { selectmyHealthError } from 'adapter/myHealth/myHealthSelectors';


const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 20px;
	}
`;

const CardsContainer = styled(Row)`
  justify-content: center;
  margin: 0;
`;

const KeraltyButtonFilterContainer = styled.div<any>`
  display: flex;
  margin-right: 1rem;
  span{
    margin: 0 1rem;
  }
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
   display: none;
  }
`;

const TextTitle = styled(KeraltyText)`
  font-size: 2rem;
  font-weight: 700;
  margin: 0rem;
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    font-weight: 400;
  }
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  input,
  button {
    margin: 0 !important;
  }
`;

const ContentDiv = styled.div`

	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
	background: #3CA70D;
	border-radius: 29px;
	height:25px;
	};
`;

const DivContent = styled(Column)`
	width:100%;
	margin-left: 3%;

	@media (min-width: ${BREAKPOINTS.minTabletWidth}) and (max-width: ${BREAKPOINTS.maxTabletWidth}) {
		max-width: ${BREAKPOINTS.maxTabletWidth};
		margin-left: 4em;
	}

	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		max-width: ${BREAKPOINTS.maxPhoneWidth};
		margin-left: 2.2em;
	}
`;

const styleNoResultMobile = {
//   marginTop: '40%',
}

const styleNoResult = {
  backgroundColor: '#fff'
}

const sortedByDate = (arr, order) => {
  if (!arr) return [];
  return [...arr].sort((a, b) => {
    return (order ? -1 : 1) * (moment(a.appointmentDate).unix() - moment(b.appointmentDate).unix());
  });
};

const VisitSummaryTab = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const [visitSummaryInfo, setVisitSummaryInfo] = useState<VisitSummaryInfo[]>([]);
  const [copyVisitSummaryInfo, setCopyVisitSummaryInfo] = useState<VisitSummaryInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(true);
  const [isNewestSorting, setIsNewestSorting] = useState(true);
  const history = useHistory();
  const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});
  const [ageDateFilter, setDateFilter] = useState<{ from?: string; to?: string }>({})
  const isMobile = useIsMobile();
  const [visible, setVisible] = useState(false);
  const [cleanInput, setCleanInput] = useState(false);
  const [totalData, setTotalData] = useState<number>(0);

  const [page, setPage] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const patientId = useAppSelector(idEcw);
	const [closeModalError, setCloseModalError] = useState(false);
	const [error, setError] = useState(false);

  useEffect(() => {
    const endDate = moment().format('YYYY-MM-DD');
    const beginDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
    setIsLoading(true);
    EcwRepository.getVisitSummaryData({ endDate, beginDate, patientId })
      .then((data) => {
        setError(false)
        setIsEmpty(data.length === 0);
        setVisitSummaryInfo(data);
        setCopyVisitSummaryInfo(data);
      })
      .catch(() => {
        setError(true)
        setCloseModalError(true)
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, [''])

  const goBack2 = () => {
    history.replace('/my-health')

  };

  const cards = useMemo(
    () => sortedByDate(visitSummaryInfo, isNewestSorting),
    [visitSummaryInfo, isNewestSorting]
  );

  const onValidSubmit = useCallback(
    (value) => {
      // Probablemente aqui colocar el setData-Object ...
      setDateFilter(value);
      const endDate = moment(value.to).format('YYYY-MM-DD');
      const beginDate = moment(value.from).format('YYYY-MM-DD');
      setIsLoading(true);
      //setIsFiltered(true);
      EcwRepository.getVisitSummaryData({ endDate, beginDate, patientId })
        .then((data) => {
          setError(false)
          if (!totalData) setTotalData(data?.length ?? 0);
          setIsEmpty(cards.length === 0);
          setVisitSummaryInfo(data);
        })
        .catch((error) => {
          setError(true)
          setCloseModalError(true)
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [patientId, cards, totalData]
  );

  const dataToDisplay = useMemo(() => {
    //function in charge of filtering data by date range
    if (!dataObject.to || !dataObject.from) return visitSummaryInfo;
    const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
    const toDate = moment(dataObject.to, 'MM/DD/YYYY');
    setVisible(true)
    return visitSummaryInfo.filter((appointment) => {
      const currentDate = moment(appointment.startTime, 'YYYY-MM-DD');
      return currentDate.isBetween(fromDate, toDate, undefined, '[]');
    });
  }, [dataObject, visitSummaryInfo]);

  const onChangeDate = useCallback((type, data) => {
    console.log("Data=====>", data)
    setPage(false);
    type && setDataObject(data);
    setIsModalVisible(false);
    // console.log("Data=====>", data)
    // setPage(false);
    // set--DataObject(data);
    // setIsModalVisible(false);
  }, []);

  const ClearDate = () => {
    var p1 = { from: "", to: "" };
    var Init = moment().format();
    console.log('dsadsa' + Init)
    setDataObject(p1)
    setVisible(false)
    setVisitSummaryInfo(copyVisitSummaryInfo)//Copy Data
    setIsFiltered(!isFiltered)
  }

  const FilterVisible = () => {
    return (
      <>
        <Row style={{ width: isMobile ? '85%' : '100%', justifyContent: 'space-between' }}>
          <Row noMargin style={{ width: 'auto', justifyContent: 'space-between', marginLeft: isMobile ? '-7%' : '3%', display: ageDateFilter.from ? 'flex' : 'none' }}>
            <div style={{ background: '#3CA70D', paddingInline: 20, borderRadius: 20, cursor: 'pointer', height: 30, alignItems: 'center' }} onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
              <p style={{ fontSize: '12px', color: '#FFFFFF', margin: 0 }}>
                {`${moment(ageDateFilter.from).format("MM/DD/YY")} - ${moment(ageDateFilter.to).format("MM/DD/YY")}`}
                <span style={{ color: '#3CA70D' }}>----</span><span style={{ fontFamily: 'inherit' }}>X</span></p>
            </div>
          </Row>
          <KeraltyText style={{ width: 'auto',  marginRight: isMobile ? '-2%' : '5%',alignSelf: 'center', fontSize: '16px', color: '#999999' }} >{t('myHealth.show')} {dataToDisplay.length} {t('myHealth.resul')}</KeraltyText>
        </Row>
      </>
    )
  }

  const MainFilter = () => {
    return (
      <div>
        {i18n.language == 'en' ?
          <img onClick={() => { setIsFiltered(!isFiltered); setIsModalVisible(isFiltered); setCleanInput(!cleanInput) }} src={isMobile ? FilterImg : isFiltered ? FilterImg : ''} alt="" style={{ cursor: 'pointer' }} />
          :
          <img onClick={() => { setIsFiltered(!isFiltered); setIsModalVisible(isFiltered); setCleanInput(!cleanInput) }} src={isMobile ? FilterEs : isFiltered ? FilterEs : ''} alt="" style={{ cursor: 'pointer' }} />
        }
      </div>
    );
  };

  useEffect(() => {
	basicScrollIntoTop()
  }, [])

  const [initPage, setInitPage] = useState(false);

  return (
    <TabWrapper title={!isMobile ? t('myHealth.visitSummary') : ''}>
		{ isLoading && <Loader /> }
    {closeModalError && <ModalErrorMyHealth setCloseModalError={()=>setCloseModalError(false)}/>}

		<div>
			<DivContent>
			<Row style={{ width: !isMobile ? '65vw' : '85vw', justifyContent: 'space-between' }}>
			<KeraltyText style={{ width: 'auto', margin: 0, fontSize: 18, marginLeft: isFiltered ? -10 : 0 }} type={'title'} >{isMobile ? t('myHealth.visitSummary') : ''}</KeraltyText>
			<div style={{ alignItems: 'center' }}>
				<MainFilter />
			</div>
			</Row>
			</DivContent>

			<div style={{
				zIndex: isMobile ? 4 : 1,
				display: isFiltered ? 'none' : 'grid',
				marginInlineEnd: isMobile ? 'inherit' : 'auto',
				paddingInline: '2%'
			}}>
				<KeraltyFilterDate
				onSuccess={(type, data) => {
					onValidSubmit(data);
					setIsModalVisible(false);
					setVisible(true);
					setInitPage(true)
					setInitPage(false)
				}}
				onCancel={() => { setIsModalVisible(false); setIsFiltered(true) }}
				showModalFlag={isModalVisible}
				cleanInput={cleanInput}
				/>
			</div>

			{visible ? <FilterVisible /> : ''}

			{dataToDisplay?.length ?
				<CardsContainer>
					{
						isMobile ?
							<Row style={{ marginInline: 15 }}>
								<KeraltyText type='info' color='#5B5C5B'> {t('myHealth.info')}</KeraltyText>
								<KeraltyText type='info' color='#5B5C5B'> {t('myHealth.info2')}</KeraltyText>
							</Row>
						: <>
							<KeraltyText type='info' color='#5B5C5B' margin='20px'> {t('myHealth.info')}</KeraltyText>
							<KeraltyText type='info' color='#5B5C5B'> {t('myHealth.info2')}</KeraltyText>
						</>
					}
					<VisitSummaryCards data={cards} isEmpty={false} initPage={initPage} />
				</CardsContainer>
			: <>
				{!error? !cards.length ? <div style={isMobile ? styleNoResultMobile : styleNoResult}>
					{visible ? <NoSearchResult image={NoResult} title={t('myHealth.noResult')} subTitle={t('myHealth.noResultVisitSmmary')} /> :
					<NoSearchResult image={EmptyLabs} title={""} subTitle={t('myHealth.noVisitSmmary')} />}
				</div> : <></>: <></>}

				<Row>
					<div style={{ marginBottom: 300, width: '40vw', display: 'flex', background: 'red' }}> </div>
				</Row>
			</>
		}
		</div>
    </TabWrapper>
  );
};

export default VisitSummaryTab;
