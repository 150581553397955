import styled from 'styled-components';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import { Column } from 'app/components/templates';
import { StyledCordobaWrapper } from 'app/components/templates/cordovaWrapper';
import { Image } from 'app/components/atoms';

export const ButtonWrappers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 13px;
  padding: 0;

  > div {
    width: calc(25% - 33px);
    margin: 0;
    > div {
      padding: 0.83rem 0;
      width: auto;
      min-height: 120px;
    }
  }
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    > div {
      > div {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    margin: 0 1rem;
    > div {
      width: 100%;
      padding: 0;
      > div {
        padding: 0.83rem 1rem;
      }
    }

    ${StyledCordobaWrapper} & {
      margin: 0 0 1.5rem 0;
      justify-content: space-evenly;
      > div {
        > div {
          margin: 10px 0;
        }
        width: calc(50% - 30px);
      }
    }
  }
`;

export const CordovaColumn = styled(Column)`
  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    ${StyledCordobaWrapper} & {
      display: none;
    }
  }
`;

export const ColumCards = styled(Column)`
  padding-left:0;
  padding-right:0;
  margin-top:-8px;
  
`;

export const ColumnImage = styled(Column)`
  padding: 0;
  width: 33%;
  margin: 1.3rem 0;
`;

export const BannerImg = styled(Image)`
  border-radius: 0.67rem;
  z-index: 3;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0.67rem;
    margin-right: 0px;
    width: 100%;
    height: auto;
  }
`;
