import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import AnxietySteps from "./components/AnxietySteps";
import TitleSection from "./components/TitleSection";
import { ReactComponent as actions } from '../../../../assets/icons/actions.svg';
import { KeraltyButton, KeraltyError, KeraltyText } from "app/components/atoms";
import { useAnxiety } from "./hooks/useAnxiety";
import { TitleContiner } from "./Anxiety.styled";
import TextAreaControl from "app/components/molecules/TextAreaControl";
import ROUTES from "app/ui-core/utils/routes";
import useIsMobile from "app/hooks/useIsMobile";

const ActionsDetails = () => {

  const { t } = useTranslation();
  const { register, saveInfoInStore } = useAnxiety();
  const [currentValue, setCurrentValue] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const history = useHistory();
  const isMobile = useIsMobile();

  const MIN_CHARACTERS = 10;

  const { MENTAL_HEALTH } = ROUTES;

  const handleTextArea = (text: string) => {
    setError(false);
    setCurrentValue(text);
  }

  const nextStep = () => {
    if(currentValue.length < MIN_CHARACTERS){
      setError(true);
      return
    }

    saveInfoInStore({ actions: currentValue })
    history.replace(`${MENTAL_HEALTH.feelingOptions}`)
  }

  useEffect(() => {
    if(register.actions) setCurrentValue(register.actions)
  }, [register])

  return (
    <AnxietySteps>
      <div style={{ display: 'grid', placeItems: 'center', marginBottom: 20 }}>
        <KeraltyButton fontWeight={ 600 } fontSize="14px" width="27rem" withShadow withoutTextTransform>
          { register.thinking }
        </KeraltyButton>
      </div>
      <TitleSection 
        icon={actions} 
        title={t('mentalHealth.actions')}
        subtitle={t('mentalHealth.actionsText')}
      />
      <div style={{ marginLeft: 55, display: 'grid' }}>
      <KeraltyText type="subTitle" fontWeight={400} fontSize="14px" align="left" color="gray">{t('mentalHealth.minAnswer')}</KeraltyText>
      </div>
      
      <div style={isMobile ? { display: 'grid', marginLeft: 20, marginRight: 10 } : { marginLeft: 55, display: 'grid' }}>
        <div style={{ marginTop: 20 }}>
          <TextAreaControl 
            updateReasonText={handleTextArea}
            placeholder={t('mentalHealth.typeYourAnswer')}
            charactersNotAllowed={['|']}
            initialValue={register.actions}
          />
        </div>
      </div>
      {
        error 
        && (
          <div style={{ display: 'grid', placeItems: 'center', marginTop: 0, marginBottom: 40 }}>
            <KeraltyError error={t('mentalHealth.actionDetailError')} />
          </div>
        )
      }
      <div style={{ marginTop: 20, display: 'grid', placeItems: 'center' }}>
				<KeraltyButton              
					onClick={nextStep}
					fontWeight={ 600 }
          withShadow
				>
					{t('formLabel.enter')}
				</KeraltyButton>
			</div>
    </AnxietySteps>
  )
}

export default ActionsDetails;