import { useState, useRef, useEffect } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';

import { KeraltyButton } from '.';
import { Row, Column } from '../templates';
import styled from '../../ui-core/styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from 'i18next';
import es from 'date-fns/locale/es';
import enUS from 'date-fns/locale/en-US';
import InputMask from 'react-input-mask';
import { MASK } from '../../ui-core/utils/mask';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import FORMATS from 'app/ui-core/utils/formats';
import moment from 'moment';
import useIsMobile from '../../hooks/useIsMobile';

registerLocale('es', es);
registerLocale('en', enUS);

// ****************************************************************
// API REFERENCE =>  https://next.material-ui.com/api/date-picker/
// ****************************************************************

const ButtonDone = styled(KeraltyButton)`
  align-self: center;
  padding: 0px auto !important;
  font-size: 12px !important;
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    max-width: 8rem;
    min-width: 7rem;
    height: 3rem;
    padding: 0rem 1.2rem;
  }
`;

const DatePickerWrapperStyles = styled.div<{ ismobile: boolean }>`
  &.react-datepicker__tab-loop,
  .react-datepicker.react-datepicker--time-only {
    width: 130px;
  }
  &.react-datepicker__tab-loop,
  .react-datepicker.react-datepicker--time-only,
  .react-datepicker__time-container {
    width: 100%;
  }
  &.react-datepicker__tab-loop,
  .react-datepicker.react-datepicker--time-only,
  .react-datepicker__time-container,
  .react-datepicker__time,
  .react-datepicker__time-box {
    width: ${({ ismobile }) => (ismobile ? '200px' : '100%')} !important;
    font-size: 12px;
    height: 100%;
  }

  .react-datepicker__time-list {
    height: ${({ ismobile }) => ismobile && '100%'} !important;
  }

  &.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    left: -20px !important;
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    left: -20px !important;
  }
  @media (min-width: 320px) and (max-width: 1023px) {
    .react-datepicker {
      height: 273px !important;
    }
  }
`;

const ButtonCancel = styled(KeraltyButton)`
  align-self: center;
  padding: 0px auto !important;
  font-size: 12px !important;
  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    max-width: 10rem;
    min-width: 8rem;
    height: 3rem;
    padding: 0rem 1.2rem;
  }
`;

const ButtonContainer = styled(Column)`
  justify-content: center;
`;

const TranslateDatePicker = ({ ...props }) => {
  //Props active readonly
  const { read } = props;
  //Props date selected
  const { activeHour } = props;

  const defaultFormat: string = FORMATS.date;
  const defaultPattern = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/;
  const calendarRef = useRef<any>(null);
  const isMobile = useIsMobile();
  const [selectedDate, setSelectedDate] = useState<null | Date | string>(null);
  const [initialDate, setInitialDate] = useState<null | Date | string>(null);

  const { t } = useTranslation();

  const { selected } = props;

  const validateMethod = (stringToValidate: string) => {
    if (stringToValidate) {
      if (validateInput(stringToValidate)) {
        setSelectedDate(new Date(stringToValidate));
        setInitialDate(new Date(stringToValidate));
        props.onChange(new Date(stringToValidate));
      } else {
        setSelectedDate(null);
        setInitialDate(null);
        props.onChange('Invalid Date');
      }
    } else {
      setSelectedDate(null);
      setInitialDate(null);
      props.onChange(null);
    }

    handleForceClose();
  };

  const setLocale = () => {
    if (i18n.language.includes('es')) {
      return es;
    }

    return enUS;
  };

  const validateInput = (stringDate: string): boolean => {
    const pattern = props.regexPattern ? props.regexPattern : defaultPattern;
    const currentFormat = props.dateFormatInput ? props.dateFormatInput : defaultFormat;
    const regexValidation = stringDate.match(pattern);
    const momentValidation = moment(stringDate, currentFormat, true);
    if (momentValidation.isValid() && regexValidation) {
      return true;
    }

    return false;
  };

  const handleKeyDownEvent = (e) => {
    const stringDate = calendarRef?.current?.input.value;

    if (e && (e.key === 'Tab' || e.key === 'Enter')) {
      validateMethod(stringDate);
    }
  };

  const ExampleCustomTimeInput = ({ date, value, onChange, onCancel, onDone }) => (
    <Row>
      <ButtonContainer lg={6} md={6} sm={6}>
        <ButtonCancel outline bgcolor={'transparent'} noBorders type={'button'} onClick={onCancel}>
          {t('button.cancel')}
        </ButtonCancel>
      </ButtonContainer>
      <ButtonContainer lg={6} md={6} sm={6}>
        <ButtonDone type={'button'} onClick={onDone}>
          {t('button.done')}
        </ButtonDone>
      </ButtonContainer>
    </Row>
  );

  const handleCalendarOpen = () => {
    if (!initialDate) {
      setInitialDate(props.selected);
    }
  };

  const handleClickOutside = () => {
    if (selected) {
      if (selected instanceof Date) {
        setInitialDate(selected);
        props.onChange(selected);
      } else if (validateInput(selected)) {
        setSelectedDate(new Date(selected));
      } else {
        setSelectedDate(null);
        props.onChange('Invalid Date');
      }
    }
    calendarRef?.current.clear();
    handleForceClose();
  };

  const handleCancel = () => {
    if (initialDate) {
      if (initialDate instanceof Date) {
        setSelectedDate(initialDate);
        props.onChange(initialDate);
      } else if (validateInput(initialDate)) {
        setSelectedDate(new Date(initialDate));
      } else {
        setSelectedDate(null);
        props.onChange('Invalid Date');
      }
    }
    calendarRef?.current.clear();
    handleForceClose();
  };

  const handleForceClose = () => {
    if (calendarRef && calendarRef?.current) {
      calendarRef?.current?.setOpen(false);
    }
  };

  const onClickDone = () => {
    setInitialDate(selected);
    handleForceClose();
  };

  useEffect(() => {
    setTimeout(() => {
      setSelectedDate(selected);
    }, 1000);
  }, []);

  //Send the selected time.
  const handleTimeSelect = (time) => {
    props.onChange(time);
    calendarRef.current.setOpen(false);
    props.onTimeSelect(time);
  };

  //prevent text input.
  const handleInputKeyPress = (event) => {
    if (read) {
      event.preventDefault();
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const pro = new Date(activeHour);
    //if the received date(activeHour) is greater than the current one, it shows all the hours.
    if (pro > currentDate) {
      return selectedDate.getTime();
    } else {
      return currentDate.getTime() < selectedDate.getTime();
    }
  };

  return (
    <DatePickerWrapperStyles ismobile={isMobile}>
      <DatePicker
        ref={calendarRef}
        selected={selectedDate}
        autoComplete={'off'}
        placeholderText={props.placeholderText}
        disabled={props.disabled}
        locale={setLocale()}
        onClickOutside={handleClickOutside}
        onKeyDown={handleKeyDownEvent}
        customInput={
          <InputMask
            mask={MASK.date}
            maskChar=""
            inputMode="none"
            onKeyPress={handleInputKeyPress}
          />
        }
        onChange={handleTimeSelect}
        showTimeSelectOnly
        showTimeSelect
        timeFormat="h:mm aa"
        timeIntervals={60}
        timeCaption=""
        dateFormat="HH:mm a"
        filterTime={filterPassedTime}
        onCalendarOpen={handleCalendarOpen}
        withPortal={isMobile}
        customTimeInput={
          <ExampleCustomTimeInput
            date={null}
            value={null}
            onChange={null}
            onDone={onClickDone}
            onCancel={handleCancel}
          />
        }
        shouldCloseOnSelect={false}
      />
    </DatePickerWrapperStyles>
  );
};

const KeraltyHourPicker = styled(TranslateDatePicker).attrs({
  dateFormat: 'MM/ dd/ yyyy',
  autoComplete: 'off',
})``;

export default KeraltyHourPicker;
