import { RootState, store } from '../../adapter/store';
import ROUTES from '../ui-core/utils/routes';
const { PATIENT_REGISTRATION: CURRENT, MAIN } = ROUTES;

const PatientRegistrationGuard = () => {
	const {
		register: { userInfo, registerId, patientInfo: { responsibleParty, insurance } },
	}: RootState = store.getState();

	const paths = window.location.pathname.split(`/${MAIN.patientRegistration}/`);
	const step = paths[1], mainRoute = `${paths[0]}/${MAIN.patientRegistration}`;
	const isFBMax = userInfo?.externalPartyId;

	// if (!registerId) {
	// 	console.error('--> Missing registerId for PatientRegistration');
	// 	return false;
	// }
	if (
		step &&
		((step === CURRENT.insurance && !responsibleParty) ||
			(step === CURRENT.consent && (isFBMax ? !responsibleParty : !insurance)))
	) {
		console.error('--> Missing data for PatientRegistration')
		return `${mainRoute}/${CURRENT.responsible}`;
	}

	return true;
};

export default PatientRegistrationGuard;
