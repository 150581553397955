import { UserIdentifier as UserIdentifierEntity } from '../entities/userIdentifier';
import { EmailIdentifier as EmailIdentifierEntity } from '../entities/emailIdentifier';
import { ResetPassword as ResetPasswordEntity } from '../entities/resetPassword';

import { ForgotPassService } from './contracts/forgotPasswordService';

/**
 * Forgot Password | Step 01 | User info validation
 */
export const validateUserInfo = (
  service: ForgotPassService,
  data: UserIdentifierEntity
) => {
  return service.validateUserExistence(data);
};

/**
 * Forgot Password | Step 01 | State info validation
 */
 export const validateStateInfo = (
  service: ForgotPassService,
  data: UserIdentifierEntity
) => {
  return service.validateStateExistence(data);
};

/**
 * Forgot Password | Step 02 | Send the recover link to the user email
 */
export const sendRecoverPassEmail = (
  service: ForgotPassService,
  data: EmailIdentifierEntity
) => {
  delete data.id
  return service.sendRecoverEmail(data);
};

/**
 * Forgot Password | Step 03 | Change Password
 */
export const changePassword = (
  service: ForgotPassService,
  data: ResetPasswordEntity
) => {
  return service.changePassword(data);
};

const forgotPassUseCases = {
  validateUserInfo,
  sendRecoverPassEmail,
  changePassword,
  validateStateInfo
}

export default forgotPassUseCases;
