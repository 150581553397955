import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// UI
import { KeraltyText } from '../atoms';

interface PrivacyPolicyProps {
	persistData?: () => void;
}
/** Used in several forms */
const PrivacyPolicy = ({ persistData }: PrivacyPolicyProps) => {
	const { t } = useTranslation();
	const location = useLocation();

	return (
		<KeraltyText color='dark' type="info" fontSize={"15px"}>
			{t('register.agree')}
			{' '}
			<Link to={{ pathname: "/legal/privacy-policy", state: { from: location.pathname } }} onClick={() => persistData ? persistData() : null } >
				<KeraltyText color={'dark'} type={'link'} fontSize={"15px"}>
					{t('routes.legal.privacy')}
				</KeraltyText>
			</Link>
			{/* {', '}
      <Link to={{pathname:"/legal/third-party-notices", state: {from: location.pathname}}} >
        <KeraltyText color={'dark'} type={'link'}>
          {t('routes.legal.third')}
        </KeraltyText>
      </Link> */}
		</KeraltyText>
	);
}

export default PrivacyPolicy;
