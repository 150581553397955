import { KeraltyText, KeraltyButton } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Loader, Row } from 'app/components/templates';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'app/hooks/useIsMobile';
import theme from 'app/ui-core/utils/theme';
import { useEffect, useState } from 'react';
import i18n from 'app/ui-core/i18n';
import MentalHealth from 'infrastructure/keraltyApi/repositories/mentalHealth';

const KeralTextButtom = styled(KeraltyText)`
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 25px;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
  color: ${({ isMobile }) => (isMobile ? theme.colors.blueAction : theme.colors.accent)};
  align-self: center !important;
  padding: 13px;
  margin-bottom: 2%;
  text-align: center;
  font-weight: 600;
  text-decoration: underline ${theme.colors.accent};
  font-size: 16px;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 100% !important;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline ${theme.BLUEDC1};
  }
`;

const Button = styled(KeraltyButton)`
  letter-spacing: 1px;
  align-self: center;
  margin-top: 10px;
  font-size: 16px;
  width: 325px;
  margin: 17px 0;
  height: auto;
  min-height: 52px;
  line-height: 19.49px;
  padding: 0px 3rem;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  word-break: break-word;
  &:hover {
    border-color: ${theme.GREENDC1}; 
    background-color:  ${theme.GREENDC1};
  }
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    padding: 1px 4px;
    font-size: 14px;
    width: 100%;
    height: 44px;
    margin: 9px 0;
  }

`;

interface ModalNeedProps {
  close: any;
}

const ModalNeedHelp = ({ close }: ModalNeedProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [helpData, setHelpData] = useState<any>();
  const [isLoading, setisLoading] = useState(true);

  const needHelp = async () => {
    return await MentalHealth.needHelp()
      .then((result) => {
        setHelpData(result);
        setisLoading(false);
      })

      .finally(() => console.log(''));
  };

  const selectItem = (position: number) => {
    close(position);
  };

  useEffect(() => {
    needHelp();
  }, []);

  const language = i18n.language === 'en';

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <KeraltyModal size="extraSixHundred" textalign="center">
          <div
            style={{
              marginRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Row mt={isMobile ? 1 : 2.3}>
              <KeraltyText
                type="title"
                fontWeight="600"
                fontSize="20px"
                style={{ lineHeight: '2.25rem' }}
              >
                {t('mentalHealth.modalNeedTitle')}
              </KeraltyText>
            </Row>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
              {helpData?.map((card, i) => (
                <Button
                  outline
                  smFull
                  type={'text'}
                  onClick={() => {
                    selectItem(i);
                  }}
                  key={i}
                >
                  {language ? card?.titleEN : card?.titleES}
                </Button>
              ))}
            </div>

            <KeralTextButtom link to="/mental-health" type="label" isMobile={isMobile}>
              {t('button.close')}
            </KeralTextButtom>
          </div>
        </KeraltyModal>
      )}
    </>
  );
};

export default ModalNeedHelp;
