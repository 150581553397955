import { yupResolver } from '@hookform/resolvers/yup';
import { colors } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// 3RD PARTY
import styled from 'styled-components';
import { KeraltyField, KeraltyModal } from '.';
// ADAPTER
// DOMAIN
import { DatePickerRangeInfo } from '../../../domain/entities/datePickerRange';
// LOGIC
import {
  DatePickerRangeSchema,
  DatePickerFieldToShape,
} from '../../ui-core/schemas/datePickerRange';
import BREAKPOINTS from '../../ui-core/utils/breakpoints';
import FORMATS from '../../ui-core/utils/formats';
// UI
import { KeraltyButton, KeraltyDatePicker, KeraltyIcon, KeraltyText } from '../atoms';
import { Column, Container, FormBox, Row } from '../templates';

export interface ExtraFields {
  control: any;
  rule?: any;
  name: string;
  defaultValues?: any;
  functToFormatValue?: any;
}

interface KeraltyFilterPros {
  onSuccess: any;
  extraFields?: ExtraFields[];
  showModalFlag?: boolean;
  onCancel?: any;
  maxRangeDateAllowed?: number;
  cleanInput?: boolean;
  hasNotMaxDate?: boolean; // if false, max date is current date
}

interface CustomInterface {
  [key: string]: any;
}

const FormContainer = styled(FormBox)`
  overflow: visible;
`;

const Button = styled(KeraltyButton)`
  letter-spacing: 1px;
  align-self: center;
  margin-top: 10px;
  white-space: nowrap;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    padding: 0px;
  }
`;

const ContainerFilterButton = styled(Column)`
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  margin: 0;
`;

const ContainerCancelButton = styled(Column)`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const MobileContainer = styled(Container)`
  display: none;

  @media (max-width: ${BREAKPOINTS.minDesktopWidth}) {
    display: flex;
  }
`;

const DesktopContainer = styled(Container)`
  width: 100%;
  display: none !important;

  @media (min-width: ${BREAKPOINTS.minDesktopWidth}) {
    display: flex !important;
  }
`;

const Title = styled(KeraltyText)`
  text-transform: capitalize;
`;

const KeraltyFilterContainer = styled.div`
  width: 100%;
`;
const restarKeraltyfield = {};
const KeraltyFilterDate = ({
  onSuccess,
  extraFields = [],
  showModalFlag = false,
  onCancel = () => undefined,
  maxRangeDateAllowed = 1,
  cleanInput = false,
  hasNotMaxDate,
}: KeraltyFilterPros) => {
  const { t } = useTranslation();
  const placeholderDate: string = 'mm/ dd/ yyyy';
  const errorYearIndiex: string = 'invalidDateRangeYearAfter';
  const [cleanInputs, setCleanInputs] = useState(false);

  const methodTransformDateErrorMessage = ($var) => {
    if ($var === ' `Invalid Date` (cast from the value `""`).') {
      return t('errors.required');
    } else if ($var.includes(' `Invalid Date` (cast from the value ')) {
      return t('errors.invalidDate');
    }
    return $var;
  };

  const getSchemaToValidateForm = (): any => {
    let finalObjectSchema = DatePickerRangeSchema;
    finalObjectSchema = finalObjectSchema.shape({
      to: DatePickerFieldToShape(maxRangeDateAllowed, errorYearIndiex),
    });

    extraFields.forEach((iteration) => {
      if (iteration.rule) {
        finalObjectSchema = finalObjectSchema.shape({ [iteration.name]: iteration.rule });
      }
    });
    return finalObjectSchema;
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DatePickerRangeInfo & CustomInterface>({
    mode: 'onBlur',
    resolver: yupResolver(getSchemaToValidateForm()),
  });

  useMemo(() => {
    setCleanInputs(true);
    reset({});
    setTimeout(() => {
      setCleanInputs(false);
    }, 50);
  }, [cleanInput]);

  const getExtraFieldData = useCallback(
    (data): CustomInterface => {
      let returnObject = {};
      extraFields.forEach((iteration: ExtraFields) => {
        returnObject[iteration.name] = iteration.functToFormatValue
          ? iteration.functToFormatValue(data[iteration.name])
          : data[iteration.name];
      });
      return returnObject;
    },
    [extraFields]
  );

  const fromDate = watch('from') || '';
  const onValidSubmit = useCallback(
    (value: DatePickerRangeInfo & CustomInterface) => {
      const extraFieldData = getExtraFieldData(value);
      const parsedTo = value.to ? moment(value.to).format(FORMATS.date) : '';
      const parsedFrom = value.from ? moment(value.from).format(FORMATS.date) : '';
      onSuccess('datePickerRange', {
        from: parsedFrom,
        to: parsedTo,
        ...extraFieldData,
      });
      reset({ ...value });
    },
    [onSuccess, reset, getExtraFieldData]
  );

  const extraControls =
    extraFields.length > 0
      ? extraFields.map((iteration, ind) => {
          const controlObject = iteration.control;
          const newOnChange = (v) => {
            const value = controlObject.props.type === 'select' ? v.value : v;
            setValue(iteration.name, value || '', { shouldValidate: true, shouldTouch: true });
          };
          const newError = errors[iteration.name];
          const selector = watch(iteration.name);
          let customProps = {
            onChange: newOnChange,
            error: newError,
          };
          switch (controlObject.props.type) {
            case 'checkbox':
              customProps['checked'] = selector;
              break;
            case undefined:
              customProps['field'] = register(iteration.name);
              break;
            case 'select':
              customProps['value'] = controlObject.props.options?.find(
                ({ value }) => value === selector
              );
              break;
            default:
              customProps['selector'] = selector;
              break;
          }
          return (
            <Row pl key={ind}>
              <Column lg={12} sm={12} md={12}>
                {React.cloneElement(controlObject, customProps)}
              </Column>
            </Row>
          );
        })
      : null;

  const onChange = useCallback(
    (v, field, shouldV: boolean = true, shouldT: boolean = true) => {
      setValue(field, v || '', { shouldValidate: shouldV, shouldTouch: shouldT });
    },
    [setValue]
  );

  const formRender = (
    <FormContainer width={'100%'} autoComplete={'off'} onSubmit={handleSubmit(onValidSubmit)}>
      <Row pl noMargin style={{ justifyContent: 'space-between' }}>
        <Column lg={4} md={12} sm={12} style={{ margin: '0px' }}>
          <Row pl noMargin>
            <Column lg={12} sm={12} md={12} noPadding>
              {!cleanInputs ? (
                <KeraltyField
                  iconClass={'fas fa-calendar-alt'}
                  title={t('imgNicons.calendarIcon')}
                  placeholderText={placeholderDate}
                  label={t('formLabel.fromRangeDate')}
                  control={KeraltyDatePicker}
                  customInput={<input {...register('from')} />}
                  selected={getValues('from')}
                  onChange={(v) => onChange(v, 'from')}
                  error={errors.from}
                  hasNotMaxDate
                  modifyErrorMessage={($var) => methodTransformDateErrorMessage($var)}
                />
              ) : (
                <KeraltyField
                  label={t('formLabel.fromRangeDate')}
                  iconClass={'fas fa-calendar-alt'}
                  placeholderText={placeholderDate}
                />
              )}
            </Column>
          </Row>
        </Column>
        <Column lg={4} md={12} sm={12} style={{ margin: '0px' }}>
          <Row pl noMargin>
            <Column lg={12} sm={12} md={12} noPadding>
              {!cleanInputs ? (
                <KeraltyField
                  iconClass={'fas fa-calendar-alt'}
                  title={t('imgNicons.calendarIcon')}
                  placeholderText={placeholderDate}
                  label={t('formLabel.toRangeDate')}
                  control={KeraltyDatePicker}
                  customInput={<input {...register('to')} />}
                  selected={getValues('to')}
                  onChange={(v) => onChange(v, 'to')}
                  disabled={!fromDate}
                  error={errors.to}
                  hasNotMaxDate
                  modifyErrorMessage={($var) => {
                    if (errors?.to?.message === errorYearIndiex) {
                      if (Number(maxRangeDateAllowed) > 1) {
                        return $var.replace('$yearNumber', maxRangeDateAllowed).replace('.', 's.');
                      }

                      return $var.replace('$yearNumber', maxRangeDateAllowed);
                    }
                    return methodTransformDateErrorMessage($var);
                  }}
                />
              ) : (
                <KeraltyField
                  label={t('formLabel.toRangeDate')}
                  iconClass={'fas fa-calendar-alt'}
                  placeholderText={placeholderDate}
                />
              )}
            </Column>
          </Row>
        </Column>
        <Column md={12} sm={12}>
          {extraControls}
        </Column>
        <Column
          lg={2}
          md={12}
          sm={12}
          style={{ display: 'flex', alignItems: 'center', alignSelf: 'end' }}
        >
          <Container spaceBetween={'x'} noPadding>
            <Row>
              <ContainerCancelButton lg={0} md={6} sm={6}>
                <Button
                  outline
                  style={{ borderColor: '#FFFFFF', color: 'rgba(91, 92, 91, 0.5)' }}
                  smFull
                  type={'text'}
                  onClick={() => {
                    reset({});
                    onCancel && onCancel();
                  }}
                >
                  {t('button.cancel')}
                </Button>
              </ContainerCancelButton>
              <ContainerFilterButton lg={12} md={6} sm={6} noPadding>
                <Button smFull type={'submit'}>
                  {t('button.filter')}
                </Button>
              </ContainerFilterButton>
            </Row>
          </Container>
        </Column>
      </Row>
    </FormContainer>
  );

  return (
    <KeraltyFilterContainer>
      <MobileContainer>
        {showModalFlag && (
          <KeraltyModal size="withMargin" scroll="initial">
            <Row noMargin>
              <Title type={'title'} color={'accent'} align={'left'}>
                <KeraltyIcon iconClass="fas fa-filter" color="accent" size="common" mx />
                {t('KeraltyFilter.title')}
              </Title>
              <div
                style={{ width: '100%', height: 1, background: '#D8D8D8', marginInline: '5%' }}
              ></div>
            </Row>
            <Row>
              <Row noMargin>{formRender}</Row>
            </Row>
          </KeraltyModal>
        )}
      </MobileContainer>
      <DesktopContainer noPadding>{formRender}</DesktopContainer>
    </KeraltyFilterContainer>
  );
};

export default KeraltyFilterDate;
