import { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';

import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker } from 'adapter/chat/chatSlice';
import { useAppDispatch } from '../../adapter/hooks';
/* import { useSanitas } from '@quillbot/keralty-sanitas-chatbot'; */

import icon from '../assets/icons/fa-clock.svg';
import icon2 from '../assets/icons/exclamation-circle.svg';

import KeraltyModal from './molecules/keraltyModal';
import { Container, Row, SpaceWrapper } from './templates';
import { KeraltyText, Image } from './atoms';
import styled from 'styled-components';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { LOCAL_STORAGE_ITEMS, SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

export let timeStamp;
export let TIME_EXPIRATION_MIN = 15; //Cambio temporal
export const TIME_EXPIRATION_ADVICE = 1;

const Icon = styled(Image)`
  width: 100%;
  height: 35px;
  margin-right: 1.5rem;
  margin-bottom: 20px;
`;

const SessionTimeout = () => {
	const { t } = useTranslation();
	const [events] = useState(['click', 'load', 'scroll']);
	const [, setSecond] = useState(0);
	const [isOpen, setOpen] = useState(false);
	const { isLoggedIn: isAuthenticated, logout, user } = useAuth();
	const dispatch = useAppDispatch();
	const [showModal, setShowModal] = useState<boolean | null>(false);
	const [showModalAdvice, setShowModalAdvice] = useState<boolean | null>(false);
	const [reloadPage, setReloadPage] = useState(true)
	/* const { hide, show, isChatActive } = useSanitas(); */

	const styleHr = {
		color: '#fff',
		height: 0.001,
		width: 390,
	};

	const styleButton = {
		height: 51,
		borderRadius: 40,
		color: '#FFF',
		marginRight: 10,
		marginLeft: 5,
		marginTop: 10,
		paddingTop: 0,
		paddingBottom: 0,
		backgroundColor: '#3CA70D',
		border: 'none',
		borderColor: '#FFFFFF',
		fontSize: 18,
		fontFamily: 'Proxima Nova',
	};
	const styleButton2 = {
		color: '#004B7F',
		marginRight: 0,
		marginLeft: 5,
		marginTop: 10,
		paddingTop: 0,
		paddingBottom: 0,
		backgroundColor: '#FFFFFF',
		borderRadius: 40,
		border: 'none',
		borderColor: '#FFFFFF',
		fontSize: 16,
		fontWeight: 'bold',
		fontFamily: 'Proxima Nova',
		textDecorationLine: 'underline',
	};
	const styleButton3 = {
		height: 51,
		borderRadius: 40,
		color: '#FFF',
		backgroundColor: '#3CA70D',
		border: 'none',
		borderColor: '#FFFFFF',
		fontSize: 18,
		fontFamily: 'Proxima Nova',
		width: '100%',
	};

	const styleDiv = {
		marginTop: 0,
		paddingTop: 0,
		paddingBottom: 10,
		marginLeft: 0,
		marginRight: 0,
		flex: 1,
		justifyContent: 'center',
	};

	const styleDiv2 = {
		marginTop: 0,
		paddingTop: 0,
		paddingBottom: 10,
		marginLeft: 0,
		marginRight: 0,
		flex: 1,
		align: 'center',
		justifyContent: 'center',
		alignSelf: 'center',
	};

	const styleText = {
		textAlign: 'center',
		marginRight: 7,
		fontSize: 20,
		fontWeight: 400,
	};

	const styleIcon = {
		flex: 1,
		align: 'center',
		margin: 20,
		paddingBottom: 5,
		fontSize: 32,
		fontWeight: 'bold',
	};

	let warningInactiveInterval = useRef<any>();
	let startTimerInterval = useRef<any>();

	// warning timer
	let warningInactive = useCallback(
		(timeString) => {
			clearTimeout(startTimerInterval.current);
			warningInactiveInterval.current = setInterval(() => {
				const expiration_time =
					KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.PAYMENT_IN_PROGRESS) === 'true'
						? 30
						: TIME_EXPIRATION_MIN;
				const maxTime = expiration_time;
				const popTime = expiration_time;
				const popAdviceTime = expiration_time - TIME_EXPIRATION_ADVICE;


				const diff = moment.duration(moment.utc().diff(moment.utc(timeString, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')));
				const minPast = diff.minutes();
				const leftSecond = 60 - diff.seconds();
				const chatStatus: any = /* isChatActive() */ '';

				if (minPast === popAdviceTime && isAuthenticated) {
					setShowModalAdvice(true);
					setOpen(true);
				}
				if (minPast === popTime) {
					if (chatStatus.connectionStatus === 'Disconnected') {
						setSecond(leftSecond);
						setOpen(true);
						setShowModal(true);
					}
				}

				if (minPast === maxTime && isAuthenticated) {
					setShowModal(true);
					setShowModalAdvice(false)
					getOut();
					dispatch(closeSymptomChecker());
					dispatch(sanitasAdapterMethods.shutdown());
					clearInterval(warningInactiveInterval.current);
					setOpen(true);
					KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.LAST_TIME_STAMP);
				}
			}, 1000);
		},

		[logout, isAuthenticated, dispatch]
	);

	const getOut = () => {
		logout();
		KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, 'true');
	};

	// start inactive check
	let timeChecker = useCallback(() => {
		startTimerInterval.current = setTimeout(() => {
			let storedTimeStamp = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.LAST_TIME_STAMP);
			warningInactive(storedTimeStamp);
			//TODO: take this session timer to a config file
		}, 4000);
	}, [warningInactive]);

	// reset interval timer
	let resetTimer = useCallback(() => {
		clearTimeout(startTimerInterval.current);
		clearInterval(warningInactiveInterval.current);
		if (isAuthenticated) {
			timeStamp = moment();
			KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.LAST_TIME_STAMP, timeStamp);
			timeChecker();
		} else {
			clearInterval(warningInactiveInterval.current);
			KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.LAST_TIME_STAMP);
		}

		setOpen(false);
	}, [isAuthenticated, timeChecker]);

	const handleOnClose = () => {
		/* hide() */
		getOut();
		//  dispatch(closeSymptomChecker());
		//dispatch(sanitasAdapterMethods.shutdown());
		clearInterval(warningInactiveInterval.current);
		clearTimeout(startTimerInterval.current);
		setOpen(false);
		KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.LAST_TIME_STAMP);

		setShowModal(false);
	};

	useEffect(() => {
		events.forEach((event) => {
			window.addEventListener(event, resetTimer);
		});
		let hasTimeStamp = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.LAST_TIME_STAMP);
		if (isAuthenticated && !hasTimeStamp) {
			resetTimer();
		}
		return () => {
			clearTimeout(startTimerInterval.current);
			//   resetTimer();
		};
	}, [resetTimer, events, timeChecker, isAuthenticated]);

	const extendTime = () => {
		resetTimer();
		setShowModalAdvice(false);
	};

	const ModalClose = ({reload}: {reload?: boolean}) => {

		if(reload) {
			// setTimeout(() => {
				localStorage.setItem(LOCAL_STORAGE_ITEMS.SESSION_FINISHED, 'true');
				// window.location.reload();
			// }, 2000);
		}else {
			localStorage.removeItem(LOCAL_STORAGE_ITEMS.SESSION_FINISHED)
		}

		// Remove vim modals from the dom
		const vimModal = document.getElementsByClassName('vim-widget');
		if( vimModal ) Array.from(vimModal).forEach(item => item.remove());

		return (
			<KeraltyModal size="extraLong">
				<Row noMargin>
					<Icon src={icon} alt={t('errors.sessionExpired')} />
					<SpaceWrapper mb={1}>
						<Container centered={'x'} noPadding noMargin>
							<KeraltyText type={'label'} align={'center'} style={styleText}>
								{t('errors.sessionExpired')}
							</KeraltyText>
						</Container>
					</SpaceWrapper>
				</Row>

				<Row noMargin>
					<div style={styleDiv}>
						<button style={styleButton} type="button" onClick={() => window.location.reload() /*setShowModal(false)*/}>
							{' '}
							<text style={{ whiteSpace: 'nowrap', paddingInline: 80 }}>
								{' '}
								{t('button.accept2')}{' '}
							</text>
						</button>
					</div>
				</Row>
			</KeraltyModal>
		);
	};

	const ModalAdvice = () => {
		return (
			<KeraltyModal size="extraLong" onClickContainer={extendTime}>
				<Row noMargin>
					<Icon src={icon2} alt={t('errors.sessionExpired')} />
					<SpaceWrapper mb={1}>
						<Container centered={'x'} noPadding noMargin>
							<KeraltyText type={'label'} align={'center'} style={styleText}>
								{t('button.advice')}
							</KeraltyText>
						</Container>
					</SpaceWrapper>
					<hr style={styleHr} />
				</Row>

				<Row noMargin>
					<button style={styleButton3} type="button" onClick={extendTime}>
						{' '}
						<text style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
							{' '}
							{t('button.extends')}{' '}
						</text>
					</button>
				</Row>
				<Row noMargin>
					<div style={styleDiv}>
						<button style={styleButton2} type="button" onClick={handleOnClose}>
							{' '}
							{t('button.close2')}
						</button>
					</div>
				</Row>
			</KeraltyModal>
		);
	};

	useEffect(() => {
		if (localStorage.getItem(LOCAL_STORAGE_ITEMS.SESSION_FINISHED) === 'true') {
			setShowModal(true);
			setReloadPage(false)
		}
	}, [localStorage.getItem(LOCAL_STORAGE_ITEMS.SESSION_FINISHED)])


	// change fragment to modal and handleclose func to close
	return (
		<>
			{
				!isOpen && !showModal && !showModalAdvice && <></>
			}
			{
				showModalAdvice && <ModalAdvice />
			}
			{
				showModal && <ModalClose reload={reloadPage} />
			}
		</>
	);
	//   return <Fragment>{<h1>{`La sesión vencerá en ${second} segundos`}</h1>}</Fragment>;
};

export default SessionTimeout;
