const nsPatientRegistration = {
  en: {
    responsibleInfo: 'Responsible party information',
    insuranceInfo: 'Primary insurance information',
    insuranceCardDesc:
      '(Please make sure to write Name and Last name of insured exactly as they appear in the insurance card)',
    insuranceInfo2nd: 'Secondary insurance information',
    secondaryInsuranceCheck: 'I have a secondary insurance',
    consentToTreatment: 'Consent to treatment',
    consentSubtitle: 'Consents',
  },
  es: {
    responsibleInfo: 'Información del representante',
    insuranceInfo: 'Información del seguro principal',
    insuranceCardDesc: '(Por favor asegúrese de escribir su nombre y apellido exactamente como aparece en la tarjeta de la aseguradora)',
    insuranceInfo2nd: 'Información del seguro secundario',
    secondaryInsuranceCheck: 'Tienes un seguro secundario',
    consentToTreatment: 'Consentimiento para el tratamiento',
    consentSubtitle: 'Consentimientos',
  },
};

export default nsPatientRegistration;
