// 3RD PARTY
import { useAppSelector } from 'adapter/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
//UI

import PersonalInfoTab from '../../components/organisms/myAccount/PersonalInfoTab';
import DeleteAccountTab from '../../components/organisms/myAccount/deleteAccountTab';
import FormPersonalInfo from '../../components/organisms/myAccount/formPersonalInfo';
import MyInsurance from '../../components/organisms/myAccount/myInsurance';
import Loader from '../../components/templates/loader';
import { ReactComponent as IDCardIcon } from '../../assets/icons/id-card.svg';
import { ReactComponent as UserCircleIcon } from '../../assets/icons/user-ciercle.svg';
import KeraltySidebar, { SidebarProps } from '../../components/molecules/keraltySidebar';

import ROUTES from '../../ui-core/utils/routes';
import { selectmyAccountLoading } from 'adapter/myAccount/myAccountSelector';

const { MY_ACCOUNT, MAIN } = ROUTES;

const MyAccountMain = () => {
	const { t } = useTranslation();
	const isLoading = useAppSelector(selectmyAccountLoading);
	const { tab } = useParams();

	const sidebar: SidebarProps = {
		sideTabs: [
			{
				name: MY_ACCOUNT.personalInfo,
				icon: UserCircleIcon,
				text: t('myAccount.personalInfo'),
				content: <PersonalInfoTab />,
				routeName: `${MAIN.myAccount}/${MY_ACCOUNT.personalInfo}`,
				showOnMobile: false,
			},
			{
				name: MY_ACCOUNT.myInsurance,
				icon: IDCardIcon,
				text: t('myAccount.myInsurance'),
				content: <MyInsurance />,
				routeName: `${MAIN.myAccount}/${MY_ACCOUNT.myInsurance}`,
				showOnMobile: false,

			},
			{
				name: MY_ACCOUNT.deleteAccount,
				//	icon: IDCardIcon,
				//	text: 'testDelte',
				content: <DeleteAccountTab />,
				routeName: `${MAIN.myAccount}/${MY_ACCOUNT.deleteAccount}`,
				showOnMobile: false,
			},
			{
				name: MY_ACCOUNT.editAccount,
				//	icon: IDCardIcon,
				//	text: 'testDelte',
				content: <FormPersonalInfo />,
				routeName: `${MAIN.myAccount}/${MY_ACCOUNT.editAccount}`,
				showOnMobile: false,
			},

		],
		routeLabel: t('routes.myAccount'),
		activeByRoute: tab || MY_ACCOUNT.personalInfo,
	};

	return (
		<>
			{isLoading && <Loader />}
			<KeraltySidebar {...sidebar} />;
		</>
	);
};

export default MyAccountMain;
