import { useState, useEffect } from 'react';
import BREAKPOINTS from '../ui-core/utils/breakpoints';

function calculateIsMobile() {
  return window.innerWidth < Number(BREAKPOINTS.minDesktopWidth.split('px')[0]);
}

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(calculateIsMobile());

  useEffect(() => {
    const handleResize = () => {
      calculateIsMobile() !== isMobile && setIsMobile(calculateIsMobile());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  return isMobile;
}
