import nsLogin from './screens/login';
import nsContactConsent from './screens/contactConsent';
import nsComponents from './shared/components';
import nsConsentTreatment from './screens/consentTreatment';
import nsLocationSelect from './screens/locationSelect';
import nsRegister from './screens/register';
import nsRoutes from './shared/routes';
import nsErrors from './shared/errors';
import nsFinancialPolicy from './screens/financialPolicy';
import nsForgotPassword from './screens/forgotPassword';
import nsUnblockAccount from './screens/unblockAccount';
import nsFormLabel from './shared/formLabel';
import nsButton from './shared/button';
import nsPatientRegistration from './screens/patientRegistration';
import nsPharmacyInfo from './screens/pharmacyInfo';
import nsHipaaForm from './screens/hipaaForm';
import nsHome from './screens/home';
import nsImgNicons from './shared/imgNicons';
import nsSelect from './shared/select';
import nsMessages from './shared/message';
import nsMyHealth from './screens/myHealth';
import nsMentalHealth from './screens/mentalHealth';

import nsGetCare from './screens/getCare';
import nsMyAccount from './screens/myAccount';
import nsNotFound from './screens/notFound';
import nsMonthsCalendar from './shared/monthsCalendar';
import nsTooltip from './shared/tooltip';

const en = {
	...nsComponents.en,
	...nsLocationSelect.en,
	...nsLogin.en,
	button: { ...nsButton.en },
	consentTreatment: { ...nsConsentTreatment.en },
	contactConsent: { ...nsContactConsent.en },
	errors: { ...nsErrors.en },
	financial: { ...nsFinancialPolicy.en },
	forgotPassword: { ...nsForgotPassword.en },
	unblockAccount: { ...nsUnblockAccount.en },
	formLabel: { ...nsFormLabel.en },
	getCare: { ...nsGetCare.en },
	myAccount: { ...nsMyAccount.en },
	hipaaForm: { ...nsHipaaForm.en },
	home: { ...nsHome.en },
	imgNicons: { ...nsImgNicons.en },
	message: { ...nsMessages.en },
	myHealth: { ...nsMyHealth.en },
	mentalHealth: { ...nsMentalHealth.en },
	patientRegistration: { ...nsPatientRegistration.en },
	pharmacyInfo: { ...nsPharmacyInfo.en },
	register: { ...nsRegister.en },
	routes: { ...nsRoutes.en },
	select: { ...nsSelect.en },
	notFound: { ...nsNotFound.en },
	monthsCalendar: { ...nsMonthsCalendar.en },
	tooltip: { ...nsTooltip.en }
}

export default en;
