const LinearGradient = () => {
  const handleVeryfyPlatform = () => {
    let platform;
    // if IOS
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      platform = 'ios';
      // If Android
    } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      platform = 'android';
      // If other OS
    } else {
      platform = 'other';
    }

		return platform;
	};

    return (



        (
			window.innerWidth < 1023)
			? <div>
				{
					(handleVeryfyPlatform()!=='ios')
					?	<div style={{
							background:"linear-gradient(to right, #02b5e1, #61b73c)",
							height:'9px',
							width: '100%',
							display: 'flex',
							position: 'fixed',
							zIndex: 11,
							top: 0,
							bottom:0,
							border:0,
							borderColor:'none',
						}}></div>
					:	<div>
							<div style={{
								background:'#f5f5f5',
								height:'33px',
								width: '100%',
								display: 'flex',
								position: 'fixed',
								zIndex: 1,
								top: 0,
								bottom:0,
								border:0,
								borderColor:'none',
							}}></div>

							<div style={{
								background:"linear-gradient(to right, #02b5e1, #61b73c)",
								height:'9px',
								width: '100%',
								display: 'flex',
								position: 'fixed',
								zIndex: 11,
								top: 0,
								bottom:0,
								border:0,
								borderColor:'none',
							}}></div>
            			</div>
        	}
         </div>
    	: null




    )

}

export default LinearGradient;
