
import styled from '../../ui-core/styled-components';

interface SpaceWrapperProps {
	mx?: number;
	my?: number;
	ml?: number;
	mr?: number;
	mt?: number;
	mb?: number;
	[key: string]: any;
}

const DivStructure = ({ className, children, mx, my, ml, mr, mt, mb, ...props }) => (
	<div className={className} {...props}>{children}</div>
)

const SpaceWrapper = styled(DivStructure) <SpaceWrapperProps>`
	display: inline-block;
	${({ my }) => my ? `margin-top: ${my}rem; margin-bottom: ${my}rem;` : ''}
	${({ mx }) => mx ? `margin-right: ${mx}rem; margin-left: ${mx}rem;` : ''}
	${({ ml }) => ml ? `margin-left: ${ml}rem;` : ''}
	${({ mr }) => mr ? `margin-right: ${mr}rem;` : ''}
	${({ mt }) => mt ? `margin-top: ${mt}rem;` : ''}
	${({ mb }) => mb ? `margin-bottom: ${mb}rem;` : ''}
`;

export default SpaceWrapper;
