import MaintenanceAndNotificationsRepository from "infrastructure/keraltyApi/repositories/maintenanceAndNotifications.repositories";

const maintenanceInfo = () => {
	return MaintenanceAndNotificationsRepository.maintenanceInfo();
};

const MaintenanceAndNotificationsUseCase = {
    maintenanceInfo
};

export default MaintenanceAndNotificationsUseCase;