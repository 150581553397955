import { PatientResponsibleInfo, PatientResponsibleMaxInfo } from "../entities/patientInfo";

export const DefaultPatientResponsibleInfo: PatientResponsibleInfo = {
	selfGuarantor: 100,
	guarantorReason: '',
	ssn: undefined,
	patientRelationship: '',
	firstName: '',
	lastName: '',
	gender: '',
	guarantorSsn: undefined,
	guarantorBirthdate: '',
	city: '',
	state: '',
	zipCode: '',
	address: '',
	homePhone: '',
	cellphone: '',
	IDFile: '',
	idFile64: '',
	idFileName: '',
};

export const DefaultPatientResponsibleMaxInfo: PatientResponsibleMaxInfo = {
	...DefaultPatientResponsibleInfo,
	pharmacyName: '',
	isAuthSelected: false,
	zipPharmacyCode:'',
	noFavoritePharmacy:false,
};
