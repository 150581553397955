import HttpClient from '../publicHttpClient';
import mappers from '../mappers/unblockAccountMapper';
// DOMAIN
import { UserIdentifier as UserIdentifierEntity } from '../../../domain/entities/userIdentifier';
import { EmailIdentifier as EmailIdentifierEntity } from '../../../domain/entities/emailIdentifier';
import { ResetPassword as ResetPasswordEntity } from '../../../domain/entities/resetPassword';

const ENDPOINT = 'auth/unblock'

const UnblockRepository = {
	validateStateExistence: (data: UserIdentifierEntity): Promise<any> => {
		const body = mappers.mapToUserIdentifier(data)
		return HttpClient.post(`${ENDPOINT}/validate`, body);
	},
	sendRecoverEmail: (body: EmailIdentifierEntity): Promise<any> => {
		return HttpClient.post(`${ENDPOINT}/sendemail`, body);
	},
	changePassword: (data: any): Promise<any> => {
		const body = mappers.mapToPasswordBlocked(data)
		return HttpClient.post(`${ENDPOINT}/changePass`, body);
	},
	sendCode: (body: any): Promise<any> => {
		return HttpClient.post(`${ENDPOINT}/sendCode`, body);
	},
};

export default UnblockRepository;
