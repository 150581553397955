import * as Yup from 'yup';
import { REGEX } from '../utils/regex';
import { PatientInsuranceInfo } from 'domain/entities/patientInfo';
import { AvailityInfo } from 'domain/entities/availityInfo';

//

export const AvailityInfoSchema: Yup.SchemaOf<AvailityInfo> = Yup.object().shape({
  firstName: Yup.string()
    .required('required')
    .min(2, 'min')
    .max(50, 'max')
    .matches(REGEX.lettersChars, 'invalidName'),
  lastName: Yup.string()
    .required('required')
    .min(2, 'min')
    .max(50, 'max')
    .matches(REGEX.lettersChars, 'invalidName'),

  memberId: Yup.string().required('required').max(30, 'max'),

  authUid: Yup.string().notRequired().max(30, 'max'),
  company: Yup.string().required('required'),
  companyId: Yup.string().required('required'),
  planType: Yup.string().notRequired(),
  relationship: Yup.string().required('required'),
  relationshipId: Yup.string().required('required'),
  holderName: Yup.string()
    .matches(REGEX.lettersChars, 'invalidName')
    .notRequired()
    .when('relationshipId', (relationshipId, schema) => {
      if (relationshipId && relationshipId !== '1')
        return schema.required('required').min(2, 'min').max(50, 'max');
      return schema;
    }),
  holderLastName: Yup.string()
    .matches(REGEX.lettersChars, 'invalidName')
    .notRequired()
    .when('relationshipId', (relationshipId, schema) => {
      if (relationshipId && relationshipId !== '1')
        return schema.required('required').min(2, 'min').max(50, 'max');
      return schema;
    }),
  holderDateOfBirth: Yup.date()
    .notRequired()
    .when('relationshipId', (relationshipId, schema) => {
      if (relationshipId && relationshipId !== '1') return schema.required('required');
      return schema;
    }),
});

// export const InsuranceMaxInfoSchema: Yup.SchemaOf<PatientInsuranceMaxInfo> = InsuranceInfoSchema
// 	.concat(Yup.object().shape({
// 		// PharmacyFields
// 		pharmacyName: Yup.string()
// 			.required('required')
// 			.max(120, 'max')
// 			.matches(REGEX.lettersNumberSpace, 'invalidName'),
// 		isAuthSelected: Yup.boolean()
// 			.oneOf([true], 'required'),
// 	}));
