import { useMemo, useEffect, useCallback, useState } from 'react';
// 3RD PARTY
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '../ui-core/styled-components';
// UI
import { Image, KeraltyIcon, KeraltyText } from './atoms';
import LinearGradient from './atoms/LinearGradient';
import { KeraltyLanguageSwitcher } from './molecules';
import { Column, Container, Figures, Row, SpaceWrapper } from './templates';
import logo from '../assets/images/sanitas-logo-legend.svg';
import logoutIcon from '../assets/icons/logout-duotone.svg';
import burguerMenuIcon from '../assets/icons/burguer-menu.svg';
import burguerMenuLight from '../assets/icons/burguer-menu-light.svg';
import myAccountIcon from '../assets/icons/my-account.svg';
import sanitasLogoLegendLight from '../assets/icons/sanitas-logo-legend-light.svg';
import { StyledCordobaWrapper } from '../components/templates/cordovaWrapper';
// ADAPTER
import { useAppDispatch, useAppSelector } from '../../adapter/hooks';
import * as sanitasAdapterMethods from '../../adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker, showChat } from '../../adapter/chat/chatSlice';
import { chatActions } from '../../adapter/chat/chatSlice';
import useIsMobile from 'app/hooks/useIsMobile';
// LOGIC
import useAuth from '../hooks/useAuth';
// ROUTES
import ROUTES from '../ui-core/utils/routes';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';

import { utcTime } from 'app/ui-core/utils/times';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import useChatProvider from 'app/hooks/useChatProvider';

const { MAIN /*GET_CARE*/ } = ROUTES;

const ImageKeraltyIcon = styled(Image)`
  margin-bottom: 10px;
  @media (min-width: ${BREAKPOINTS.maxPhoneWidth}) {
    ${StyledCordobaWrapper} & {
      margin-top: -10px;
    }
  }
`;

const NavLinks = styled(Column)`
  max-width: calc(56.66666666666667% - 2rem);
`;

const TextContent = styled(Row)`

  height: 65px;
  background: #055293;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: flex-end;
  justify-content: 'space-between';
  z-index: 10;
  top: 0;
  left: 0px;
  @media (min-width: 1023px) {
    display: none;
  }
`;

const FigureBorder = styled(Figures)`
  @media (max-width: ${BREAKPOINTS.minTabletWidth}) {
    ${StyledCordobaWrapper} & {
      display: none;
    }
  }
`;

const Text = styled(KeraltyText)`
  font-size: 1.33rem;
  line-height: 1.62rem;
  & > span {
    margin-right: 0.2rem;
  }
`;

const PhoneNumber = styled(KeraltyText)`
  font-size: 1.33rem;
  line-height: 1.62rem;
  & > span {
    margin-right: 0.2rem;
  }

  vertical-align: super;
`;

const PhoneNoDecoration = styled.a`
  text-decoration: none;
`;

const Header = ({ toggleMenu, children = <></> }) => {
  const { t } = useTranslation();
  const match = useRouteMatch('/:route');
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const { logout, user } = useAuth();
  const isMobile = useIsMobile();
  const { authUid } = useAppSelector((state) => state.user);

  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [isActivedChat, setIsActivedChat] = useState(false)

  const activeRoute = useMemo(() => {
    if (!match) return `/${MAIN.home}`;
    return match.params.route;
  }, [match]);

  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasState } = sanitasChatState;

  // const chatState = useAppSelector((state) => state.chat.symptomChecker);
  const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
  const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
  const isLSLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'lifeStyleLib');

  const data = useAppSelector(state => state.user);

  const { openChat } = useChatProvider();

  useEffect(() => {
	setIsActivedChat(data?.isActivedChat)
  }, [data])


  /*  const triggerVirtualAssistant = useCallback(() => {
    dispatch(sanitasAdapterMethods.shutdown());
    if (isSCOpen || isHLOpen || isLSLOpen ) dispatch(closeSymptomChecker());
    if ((!chatState.isChatOpen && !chatState.isInitialized) || isSCOpen || isHLOpen || isLSLOpen ) {
      dispatch(chatActions.openVirtualAssistant());
    } else {
      dispatch(showChat());
    }
  }, [dispatch, isSCOpen, chatState, isHLOpen, isLSLOpen]);*/

  const triggerPatientSupport = useCallback(async () => {
    dispatch(closeSymptomChecker());

	openChat({ queue: 'support' });

    // await setTimeout(async () => {
    //   await dispatch(
    //     sanitasAdapterMethods.setUp({
    //       actionColor: '#00cdac',
    //       backgroundColor: '#00cdac',
    //       name: `${user.displayName}`,
    //       email: `${user.email}`,
    //       state: `${user.state}`,
    //       currentTimeZone: `${utcTime()}`,
    //       authUid: `${authUid}`,
    //       sanitasAccountNumber: `${user.idEcw}`,
    //       gender: `${user.sex}`,
    //       birthday:`${user.birthdate}`,
    //       customAttributes: { cType: 'support' },
    //     })
    //   );
    //   await dispatch(sanitasAdapterMethods.show());
    // }, 500);
  }, [dispatch, sanitasState, user]);

  const navLinks = useMemo(
    () => [
      { label: 'routes.home', route: MAIN.home, disabled: false },
      { label: 'getCare.getCareNow', route: MAIN.getCare, disabled: false },
      { label: 'routes.myHealth', route: MAIN.myHealth, disabled: false },

      {
        label: 'routes.patientSupport',
        callback: () => triggerPatientSupport(),
        disabled: false,
      },
    ],
    [triggerPatientSupport]
  );

  useEffect(() => {
    return () => {
      if (sanitasState.isShow && sanitasState.isSetted) {
        dispatch(sanitasAdapterMethods.shutdown());
      }
    };
  }, [sanitasState, dispatch]);

  const navigate = useCallback(
    async (param) => {
      switch (param) {
        case 'doctorchat':
          await push('/home?trigger=chat-with-doctor');
          break;
        case 'televisit':
          await push('/get-care');
          break;
        case 'support':
          await push('/home?trigger=patient-support');
          break;
        case 'bookappointment':
          await push('/get-care/appointments?trigger=book-appointment');
          break;
        case 'upcappointment':
          await push('/get-care/appointments/upcoming-appointments');
          break;
        case 'prevappointment':
          await push('/get-care/appointments/previous-appointments');
          break;
        case 'visitsummary':
          await push('/my-health/visit-summary');
          break;
        case 'referral':
          await push('/my-health/referrals');
          break;
        case 'labsandresults':
          await push('/my-health/lab-results');
          break;
        case 'immunization':
          await push('/my-health/immunization');
          break;
        default:
          break;
      }
      return;
    },
    [push]
  );

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== 'https://websdk.sense.ly') return;
        //console.log(`Received message: ${event.data}`);
        navigate(event.data.MENU);
        dispatch(closeSymptomChecker());
      },
      false
    );
  }, [dispatch, navigate]);

  const handleVeryfyPlatform = () => {
    let platform;
    // if IOS
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      platform = 'ios';
      // If Android
    } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      platform = 'android';
      // If other OS
    } else {
      platform = 'other';
    }

    return platform;
  };

  let top;

  if(handleVeryfyPlatform()!=='ios'){

  top='3rem '
  }
  else{
      top='6rem'
  }

  const getOut = () => {
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, 'true');
    logout();
  };

  useEffect(() => {
	window.addEventListener("resize", (_event) => {
		setScreenWidth(window.innerWidth);
	});
  }, []);

  const Wrapper = styled(Row)`
	${StyledCordobaWrapper} & {
		padding-top: 3rem !important;
	}
	background-color: ${({ theme: { colors } }) => colors.light};
	height: ${({ theme: { sizes } }) => sizes.header};
	max-width: calc(100% - 2rem);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 1rem;
	position: fixed;
	z-index: 9;
	top: 0;
	left: 0px;
	${
		isActivedChat && screenWidth <= 500
		? `
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			z-index: 20;
			background-color: rgba(0, 0, 0,0.5);
		`
		: `
			@media (max-width: ${BREAKPOINTS.minTabletWidth}) {
				${StyledCordobaWrapper} & {
				display: none;
				}
			}
		`
	}
`;

  return (
    <>
      <Wrapper noMargin>
        <Column md={4} sm={4} noPadding>
          <Link to={`/${MAIN.home}`}>
            <ImageKeraltyIcon src={logo} height={3.5} alt={t('imgNicons.sanitasLogo')} />
          </Link>
        </Column>
        <NavLinks lg={7} md={0} sm={0} noPadding>
          <Container centered={'xy'} noPadding>
            <Container spaceAround={'x'} noPadding>
              {navLinks.map(({ disabled, route, callback, label }, i) => (
                <Text
                  key={`nav-link-${i}`}
                  color="primary"
                  onClick={!disabled ? (callback ? callback : () => push(`/${route}`)) : null}
                  bold={route === activeRoute}
                >
                  {t(label)}
                </Text>
              ))}
            </Container>
          </Container>
        </NavLinks>
        <Column md={0} sm={0} noPadding>
          <Container centered={'y'} noPadding>
            <SpaceWrapper>
              <PhoneNoDecoration href="tel:+18446654827">
                <KeraltyIcon
                  iconClass="fas fa-phone"
                  color="accent"
                  size="common"
                  title={t('imgNicons.telephoneIcon')}
                />
              </PhoneNoDecoration>
              <SpaceWrapper ml={0.5}>
                <PhoneNoDecoration href="tel:+18446654827">
                  <PhoneNumber color="accent" bold>
                    1-844-665-4827
                  </PhoneNumber>
                </PhoneNoDecoration>
              </SpaceWrapper>
            </SpaceWrapper>
            <SpaceWrapper ml={1}>
              <KeraltyLanguageSwitcher />
            </SpaceWrapper>
            <SpaceWrapper ml={1} mr={0.5}>
              <Image
                src={myAccountIcon}
                height={1.5}
                onClick={() => push(`/${MAIN.myAccount}`)}
                alt={t('imgNicons.myAccount')}
              />
            </SpaceWrapper>
            <SpaceWrapper ml={0.5}></SpaceWrapper>
            <SpaceWrapper ml={0.5}>
              <Image src={logoutIcon} height={1.5} onClick={() => getOut()} />
            </SpaceWrapper>
          </Container>
        </Column>
        <Column lg={0} md={6} sm={8}>
          <Container end={'x'} noPadding>
            {/* <KeraltyIcon
              iconClass="fas fa-comments"
              color="accent"
              size="common"
              onClick={() => triggerVirtualAssistant()}
            />*/}
            {!isMobile && (
              <KeraltyIcon
                iconClass="fas fa-question-circle"
                color="accent"
                size="common"
                mx
                onClick={() => triggerPatientSupport()}
              />
            )}
            <KeraltyLanguageSwitcher activeHideButtons={true} hideButtons={true} />
            <PhoneNoDecoration href="tel:+18446654827">
              <KeraltyIcon iconClass="fas fa-phone" color="accent" size="common" mx />
            </PhoneNoDecoration>

            <SpaceWrapper ml={0.5}>
              <Image
                src={burguerMenuIcon}
                size={1.5}
                onClick={toggleMenu}
                alt={t('imgNicons.hamburgerIcon')}
              />
            </SpaceWrapper>
          </Container>
        </Column>
      </Wrapper>

      <>
        <LinearGradient />
        <TextContent noMargin style={{ paddingTop: '3rem' }}>
          {/* logo */}
          <Column
            sm={12}
            style={{ paddingRight: 10, paddingLeft: 20, justifyContent: 'space-between' }}
          >
            <div style={{ marginTop: -20 }}>
              <Column sm={8} noPadding>
                <Link to={`/${MAIN.home}`}>
                  <ImageKeraltyIcon
                    src={sanitasLogoLegendLight}
                    height={5}
                    alt={t('imgNicons.sanitasLogo')}
                  />
                </Link>
              </Column>
            </div>
            <Column sm={6}>
              <Container spaceAround={'x'} noPadding>
                {/* <KeraltyIcon
                  iconClass="fas fa-comments"
                  color="light"
                  size="medium"
                  onClick={() => triggerVirtualAssistant()}
          />*/}
                {!isMobile && (
                  <KeraltyIcon
                    iconClass="fas fa-question-circle"
                    color="light"
                    size="medium"
                    mx
                    onClick={() => triggerPatientSupport()}
                  />
                )}
                <div style={{ color: '#FFFFFF' }}>
                  <KeraltyLanguageSwitcher activeHideButtons={true} hideButtons={true} />
                </div>
                <PhoneNoDecoration href="tel:+18446654827">
                  <KeraltyIcon iconClass="fas fa-phone" color="light" size="medium" mx />
                </PhoneNoDecoration>
                <SpaceWrapper ml={1}>
                  <Image
                    src={burguerMenuLight}
                    size={2}
                    onClick={toggleMenu}
                    alt={t('imgNicons.hamburgerIcon')}
                  />
                </SpaceWrapper>
              </Container>
            </Column>
          </Column>
          {/* end logo */}
          <div style={{ background: '#004F84', width: '100%', height: '1%' }} />
        </TextContent>
      </>
      <FigureBorder />
      {children}
    </>
  );
};

export default Header;
