import { KeraltyButton, KeraltyText, SvgIcon } from 'app/components/atoms';
import { KeraltyModal } from 'app/components/molecules';
import { Row, Column, IconLinkRow } from 'app/components/templates';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CaptchaProvider from 'app/contexts/CaptchaProvider';
import useIsMobile from 'app/hooks/useIsMobile';

import x from '../../../../../assets/icons/x.svg';
import { ReactComponent as Phoneicon } from '../../../../../assets/icons/Phoneicon.svg';
import { ReactComponent as StateIcon } from '../../../../../assets/icons/StateIcon.svg';
import { ReactComponent as MailIcon } from '../../../../../assets/icons/MailIcon.svg';
import { ReactComponent as ServiceIcon } from '../../../../../assets/icons/ServiceIcon.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/icons/UserIcon.svg';
import { ReactComponent as CityIcon } from '../../../../../assets/icons/CityIcon.svg';
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';

import { IInsurancePaymentResponse, OtherValue } from 'domain/entities/paymentInfo';
import { STATES } from 'app/ui-core/utils/stateFullName';
import { capitalizeFirstLetter } from 'app/ui-core/utils/capitalize';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import { getUrlStripe } from 'adapter/payment/paymentSlice';
import { VARIABLE_TYPES } from 'domain/valueObjects/payment';
import { userSelectors } from 'adapter/user/userSelectors';
import isCordova from 'cordova';
import appConfig from 'config';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../../../ui-core/utils/routes';
import { useState } from 'react';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const Title = styled(KeraltyText)`
  font-size: 24px !important;
  margin-top: -20px;
  margin-bottom: -5px;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    color: #0069a7 !important;
  }
`;

const TextSubtitle = styled(KeraltyText)`
  color: ${({ color }) => `${(color ? color : '#5b5c5b !important')}`};
  font-size: 16px;
  line-height: 1.58rem;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    color: #0069a7 !important;
  }
`;

const LabelRefWeb = ({ src, title, text, width = '50%' }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Row noMargin style={{ width, marginBottom: 5 }}>
      <Column noPadding>
        <div style={{ marginTop: 5, paddingLeft:10 }}>
          <SvgIcon src={src} />
        </div>
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: '#055293',
            lineHeight: '30px',
            marginLeft: 10,
            textAlign: 'left',
          }}
        >
          {t(title)}:
          {/* { ['Correo', 'Email'].includes(t(title)) && text.length + t(title).length > 20 && <br/> } */}
          <span
            style={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#5B5C5B',
              lineHeight: '17px',
              marginLeft: 5,
              wordBreak:'break-all',
              marginRight:5
            }}
          >
            {text}
          </span>
        </span>
      </Column>
    </Row>
  );
};

const LabelRef = ({ src, title, text, width = '50%' }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Row noMargin style={{ width: '100%', marginBottom: 7 }}>
      <Column noPadding style={{ width }}>
        <div style={{ marginTop: 5 }}>
          <SvgIcon src={src} />
        </div>
        <span
          style={{
            width: '100%',
            fontSize: '16px',
            fontWeight: '700',
            color: '#055293',
            lineHeight: '30px',
            marginLeft: 10,
            textAlign: 'left',
          }}
        >
          {t(title)}:{' '}
        </span>
        <span
          style={{
            width: '100%',
            fontSize: '16px',
            fontWeight: '400',
            color: '#055293',
            lineHeight: '30px',
            textAlign: isMobile ? 'right' : 'left',
          }}
        >
          {text}
        </span>
      </Column>
    </Row>
  );
};

const LabelRefBottom = ({ title, text, width = '100%', fontSize = '16px', fontWeight='600' }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Row noMargin style={{ marginBottom: 7, width: '100%' }}>
      <Column noPadding style={{ width, textAlign: 'left' }}>
        <span
          style={{
            width: '100%',
            fontSize: fontSize,
            fontWeight: fontWeight,
            color: isMobile ? '#055293' : '#5B5C5B',
            lineHeight: '30px',
          }}
        >
          {t(title)}
        </span>
        <span
          style={{
            width: '100%',
            fontSize: fontSize,
            fontWeight: '400',
            color: isMobile ? '#055293' : '#5B5C5B',
            lineHeight: '30px',
            textAlign: isMobile ? 'right' : 'right',
            paddingLeft: isMobile ? '0%' : '50%',
          }}
        >
          {text}
        </span>
      </Column>
    </Row>
  );
};

const ModalTelevisitPay = ({
  close,
  paymentInfo,
  paymentError,
  setPaymentError
}: {
  close: any;
  paymentInfo: IInsurancePaymentResponse;
  paymentError: boolean;
  setPaymentError: any
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    cost,
    quantity,
    subtotal,
    total,
    patientInformation: { firstName, lastName, city, state, email, mobile },
  } = paymentInfo;
  const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
  const linkToTelevisit: string = appConfig.TELADOC_URL;
  const { push } = useHistory();
  const { MAIN, GET_CARE } = ROUTES;

  const gotToStripe = async () => {

    if(!paymentInfo.total || paymentInfo.total === 0){
      close();
      window.open(linkToTelevisit, '_blank')
      return;
    }

    const sessionUrl = paymentInfo.otherValues?.find(
      (value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_URL
    );

    if (sessionUrl?.value) {
      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.PAYMENT_IN_PROGRESS, 'true');
      window.location.href = sessionUrl.value;
      return;
    }

    dispatch(getUrlStripe(paymentInfo.transactionId as number))
		.unwrap()
		.then( (response: { url: string }) => {
      if (!response.url) return;
      close();
      KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.PAYMENT_IN_PROGRESS, 'true');
      window.location.href = response.url;
		})
    .catch( (error: { name: string, code: string, message: string }) => {
      if(error.message === '23'){
        setPaymentError(true)
      }
    })
  };

  return (
    <KeraltyModal size="media" textalign="center" >
      <CaptchaProvider>
        <img
          onClick={close}
          src={x}
          alt=""
          style={{ cursor: 'pointer', float: 'right', width: '20px' }}
        />
        <Row noMargin style={{ paddingBottom: 10, paddingTop: 30 }}>
          <Title type="title" style={{ width: '100%' }}>
            {t('button.televisit')}
          </Title>
        </Row>
        <Row noMargin style={{ paddingBottom: 25 }}>
          <TextSubtitle type="title" style={{ width: '100%' }}>
            {t('getCare.televisit.transactionDetails')}
          </TextSubtitle>
        </Row>

        {!isMobile ? (
          <div style={{ textAlign: 'center', marginLeft: '4%' }}>
            <Row style={{ marginBottom: 7, width: '85%' }}>
              <LabelRefWeb
                src={UserIcon}
                title={'getCare.televisit.name'}
                text={capitalizeFirstLetter(firstName)}
              />
              <LabelRefWeb
                src={UserIcon}
                title={'getCare.televisit.lastname'}
                text={capitalizeFirstLetter(lastName)}
              />
              <LabelRefWeb src={CityIcon} title={'getCare.televisit.city'} text={city} />
              <LabelRefWeb src={StateIcon} title={'getCare.televisit.state'} text={STATES[state.toUpperCase()]} />
              <LabelRefWeb src={MailIcon} title={'getCare.televisit.email'} text={email} />
              <LabelRefWeb src={Phoneicon} title={'getCare.televisit.phonenumber'} text={mobile} />
              <LabelRefWeb
                src={ServiceIcon}
                title={'getCare.televisit.service'}
                text="Videocall with a doctor"
              />
            </Row>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginLeft: isMobile ? '-3%' : '0%' }}>
            <Row noMargin style={{ width: '100%', paddingTop: 3 }}>
              <LabelRef
                src={UserIcon}
                width="100%"
                title={'getCare.televisit.name'}
                text={capitalizeFirstLetter(firstName)}
              />
              <LabelRef
                src={UserIcon}
                width="100%"
                title={'getCare.televisit.lastname'}
                text={capitalizeFirstLetter(lastName)}
              />
              <LabelRef src={CityIcon} width="100%" title={'getCare.televisit.city'} text={city} />
              <LabelRef
                src={StateIcon}
                width="100%"
                title={'getCare.televisit.state'}
                text={STATES[state.toUpperCase()]}
              />
              <LabelRef
                src={MailIcon}
                width="100%"
                title={'getCare.televisit.email'}
                text={email}
              />
              <LabelRef
                src={Phoneicon}
                width="100%"
                title={'getCare.televisit.phonenumber'}
                text={mobile}
              />
              <LabelRef
                src={ServiceIcon}
                width="100%"
                title={'getCare.televisit.service'}
                text="Videocall with a doctor"
              />
            </Row>
          </div>
        )}

        <Row noMargin style={{ width: isMobile ? '100%' : '50%', paddingTop: 3, marginLeft: isMobile ? '-4%' : '-10%' }}>
          <LabelRefBottom title={'getCare.televisit.cost'} text={`$${ Number.parseFloat(cost ? cost.toString() : '0').toFixed(2) }`} />
          <LabelRefBottom title={'getCare.televisit.quantity'} text={ quantity || 0 } />
          <LabelRefBottom title={'getCare.televisit.subtotal'} text={`$${ Number.parseFloat(subtotal ? subtotal.toString() : '0').toFixed(2) }`} />
          <LabelRefBottom title={'getCare.televisit.total'} text={`$${ Number.parseFloat(total ? total.toString() : '0').toFixed(2) }`} fontSize='20px' fontWeight='700' />
        </Row>

        <Row style={{ display: 'grid', placeItems: 'center'}}>
          <KeraltyButton
            type={'buttom'}
            onClick={() => gotToStripe()}
            style={{ width: isMobile ? '80%' : '40%', fontWeight: 600, fontSize: '14px' }}
            full
            smFull
            disabled={paymentError}
          >
            {total > 0 ? t('getCare.televisit.transactionContinue') : t('button.continue')}
          </KeraltyButton>
        </Row>

        {
          paymentError &&
          (
            <Row noMargin style={{ paddingBottom: 25 }}>
              <TextSubtitle type="title" color="#B50303" style={{ width: '100%', fontSize: '14px' }}>
                {t('getCare.televisit.errorTransactionCompleted')}
              </TextSubtitle>
            </Row>
          )
        }

      </CaptchaProvider>
    </KeraltyModal>
  );
};

export default ModalTelevisitPay;
