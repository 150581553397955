import { Fragment } from 'react';
import styled from '../../ui-core/styled-components';

interface KeraltyErrorProps {
	className?: string;
	error: any | null;
	minusMT?: boolean;
	color?: string;
	fontWeight?: number;
}

const DivStructure = ({ error, className, color }: KeraltyErrorProps) => (
	<Fragment>
		{error && <div className={`${className} error-msg`}>
			<span>{error}</span>
		</div>}
	</Fragment>
);

const KeraltyError = styled(DivStructure) <KeraltyErrorProps>`
	text-align: left;
	margin-left: .5rem;
	font-size: 1.17rem;
	/* position: absolute;
	left: 5px;
	bottom: .1rem; */
	${({ minusMT }) => minusMT ? 'margin-top: -1.5rem; margin-bottom: .75rem;' : ''}
	span {
		color: ${({ theme: { colors }, color }) => color ? color : colors.danger};
		font-weight: ${({fontWeight}) => fontWeight};
	}
`;

export default KeraltyError;
