import { EcwService } from './contracts/ecwService';

export const getPersonalData = async (myAccountServices: EcwService, patientId: number) => {
  const patientIdParsed = patientId;
  const response = await myAccountServices.getPersonalData(patientIdParsed);
  return response;
};

export const getMaritalStatus = async (myAccountServices: EcwService, patientId: number) => {
  const patientIdParsed = patientId;
  const response = await myAccountServices.getMaritalStatus(patientIdParsed);
  return response;
};

export const getPatientInfo = async (myAccountServices: EcwService, data: object ) => {
  const response = await myAccountServices.getPatientInfo(data);
  return response;
};

export const getInsuranceListData = async (myAccountServices: EcwService, patientId: number) => {
  const patientIdParsed = patientId;
  const response = await myAccountServices.getInsuranceListData(patientIdParsed);
  return response;
};

export const getEmergencyContactData = async (myAccountServices: EcwService, patientId: number) => {
  const patientIdParsed = patientId;
  const response = await myAccountServices.getEmergencyContactData(patientIdParsed);
  return response;
};

export const getVisitSummaryData = async (myAccountServices: EcwService, patientId: number) => {
  const response = await myAccountServices.getVisitSummaryData({ patientId });
  return response;
}

const myAccountUseCases = {
  getPersonalData,
  getMaritalStatus,
  getPatientInfo,
  getInsuranceListData,
  getEmergencyContactData,
  getVisitSummaryData
}

export default myAccountUseCases;
