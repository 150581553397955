declare global {
  interface Window { cordova: any; }
}
declare global {
  interface Window { Fingerprint: any; }
}
const isCordova: boolean = window.cordova ? true : false;



export default isCordova;