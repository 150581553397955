// 3RD PARTY
import { useTranslation } from 'react-i18next';
// UI
import styled from '../../ui-core/styled-components';
import { KeraltyIcon, KeraltyText } from '../atoms';
import { Container } from '../templates';

interface Route {
	label: string;
	callback?: () => void;
};
export interface KeraltyBreadcrumbProps {
	className?: string;
	routes: Route[];
};

const BreadcrumbStructure = ({ className, routes }: KeraltyBreadcrumbProps) => {
	const { t } = useTranslation();
	return (
		<div className={className}>
			{routes.map(({ label, callback }: Route, i) => (
				<Container noPadding
					key={`route-${i}-${label}`}
					className={`breadcrumb ${!callback ? 'not-clickable' : ''}`}
					onClick={() => callback ? callback() : null} centered={'y'}>
					{
						((i === 0 && routes.length < 2)) &&
						<KeraltyIcon className={'bread-icon'} iconClass={'fas fa-chevron-left'} color={'accent'} title={t('imgNicons.chevronLeft')} />
					}
					<div>
						<KeraltyText className={'name'} type="link" style={{ fontSize: '14px' }}>{label}</KeraltyText>
						{
							((i === 0 && i < routes.length - 1) || i < (routes.length - 1)) &&
							<KeraltyIcon className={'bread-icon'} iconClass={'fas fa-chevron-right'} color={'accent'} title={t('imgNicons.chevronRight')} />
						}
					</div>
				</Container>
			))}
		</div>
	)
};

const KeraltyBreadcrumb = styled(BreadcrumbStructure) <KeraltyBreadcrumbProps>`
	height: 4rem;
	.breadcrumb {
		.bread-icon {
			margin-left: .4rem;
			margin-right: .4rem;
			display:inline;
		}
		.name {
			margin-bottom: 0px !important;
		}
		&.not-clickable {
			* {
				cursor: unset !important;
				text-decoration: none !important;
			}
		}
	}
`;

export default KeraltyBreadcrumb;
