import * as styledComponents from "styled-components";
// import styled from 'styled-components/macro';

// DO NOT REMOTE THIS FILE, IT WILL BE USED TO REMAP
// PRIMITIVE TYPES BETWEEN WEB AND MOBILE APPS
const {
	default: styled,
	ThemeContext
} = styledComponents;

export { ThemeContext };
export default styled;
