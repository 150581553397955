
import { Fragment } from 'react';
// import styled from '../../ui-core/styled-components';
import ReactTooltip from 'react-tooltip';
import './styles.scss'

interface KeraltyTooltipProps {
	children: any;
	className?: string;
	[key: string]: any;
}

// const Tooltip = styled(ReactTooltip)`
// 	&.__react_component_tooltip{
// 		box-shadow: rgb(184 184 184 / 25%) 0px 2px 4px;
// 		border-radius: 8px;
// 		padding: .5rem;
// 		max-width: 17rem;
// 		font-size: 1.2rem;
// 		* {
// 			font-size: .8rem;
// 		}
// 	}
// `;

const KeraltyTooltip = ({ children, className, ...props }: KeraltyTooltipProps) => (
	<Fragment>
		{children}
		<ReactTooltip className={className} {...props} />
	</Fragment>
);


export default KeraltyTooltip;
