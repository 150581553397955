import PaymentHttpClient from '../paymentHttpClient';
import { ValidInsurance as ValidInsuranceEntity, IBasePayload, Init } from '../../../domain/entities/paymentInfo';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';


const ENDPOINT = 'payment'

export const PaymentRepository = {
    init: (body: Init): Promise<any> => {
		return PaymentHttpClient.post(`${ENDPOINT}/payment/init`, body);
	},
	createTransactionBase: (body: Init): Promise<any> => {
		return PaymentHttpClient.post(`${ENDPOINT}/transaction`, body);
	},
	checkTransactionStatus: (sessionId: string): Promise<any> => {
		return PaymentHttpClient.get(`${ENDPOINT}/transaction/session/${sessionId}`);
	},
	getUrlStripe: (transactionId: number): Promise<any> => {
		const lenguaje = localStorage.getItem(SESSION_STORAGE_ITEMS.I18NEXTLNG) || 'en';
		return PaymentHttpClient.post(`${ENDPOINT}/transaction/stripe/${transactionId}/${lenguaje}`);
	},
	notifyBySms: (body: IBasePayload): Promise<any> => {
		return PaymentHttpClient.post(`${ENDPOINT}/transaction/check-sms`, body);
	},
	cancelUserTransaction: (body: Omit<IBasePayload, 'check'>): Promise<any> => {
		return PaymentHttpClient.post(`${ENDPOINT}/transaction/cancel-transaction`, body);
	},
	validateInsurance: (body: ValidInsuranceEntity): Promise<any> => {
		return PaymentHttpClient.post(`${ENDPOINT}/payment/validate-insurance`, body);
	},
}

// export default PaymentRepository;
