import styled from "app/ui-core/styled-components";

const Content = styled.div`
  width: auto;
  max-width: 549px;
  height: auto; 
  border: 1px solid #002F8714;
  border-radius: 10px;
  box-shadow: 1px 5px 5px #002D574D;
  background-color: #002F8714;
  word-break: break-word;
`;

const DescriptionCard = ({ children }) => {
  return (
    <Content>
      <div style={{ fontWeight: 400, fontSize: '12px', padding: 20}}>
        { children }
      </div>
    </Content>
  )
}

export default DescriptionCard;