import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '../../../ui-core/styled-components';
// UI
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import { Row, Column, IconLinkRow } from '../../templates';
import { KeraltyText } from '../../atoms';

import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
// ADAPTER
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker } from '../../../../adapter/chat/chatSlice';
// LOGIC
import CaptchaProvider from '../../../contexts/CaptchaProvider';
import useAuth from '../../../hooks/useAuth';
import useIsMobile from '../../../hooks/useIsMobile';
import ChatWithDocForm from './chatWithDocForm/ChatWithDocForm';
import { utcTime } from 'app/ui-core/utils/times';

const RightContent = styled(Row)`
    display: flex;
    flex-direction: column;
    flex: 100%;
    align-items: flex-start;
    background: transparent;
    height: auto;
	
`;
const Container = styled(Column)`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
  }
`;

const Icon = styled(Column)`
	display: none;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		display: block;
	}
`;

const SubTitleContainer = styled(Column)`
    justify-content: center;
		margin-right: 20px;
    padding: 1rem 0rem;
		width: 60% !important;
		text-align: center;
		@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-bottom: '5%' !important;
		display: flex;
		align-items: center;
		width: 80% !important;
	}
`;

const TextSubtitle = styled(Column)`
	color: #5B5C5B !important;
	text-align: center;
	font-size: 16px;
	line-height: 1.58rem;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		text-align: left;
	}
`;


const Title = styled(KeraltyText)`
    font-size: 24px !important;
		margin-top: -20px;
		margin-bottom: -5px;
`;

const FormContainer = styled(Row)`
    justify-content: center;
		margin-top: -10px;
`;

const ChatWithDocTab = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const dispatch = useAppDispatch();
	const { user } = useAuth();
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	const isMobile = useIsMobile();
	const { authUid } = useAppSelector(state => state.user);

	const triggerPatientSupport = useCallback(async () => {
		if (isSCOpen) dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			await dispatch(sanitasAdapterMethods.shutdown());
		}
		await setTimeout(async () => {
			await dispatch(sanitasAdapterMethods.setUp({
				actionColor: "#7cbc98",
				backgroundColor: "#7cbc98",
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday:`${user.birthdate}`,
				customAttributes: { cType: 'support' },
			}));
			await dispatch(sanitasAdapterMethods.show());
		}, 500);
	}, [dispatch, sanitasState, user, isSCOpen]);
		
	const handlerOnClick = async () => {
		triggerPatientSupport();
	};

	return (
		<CaptchaProvider>
			<RightContent fullHeight bg="light" > 
				<div style={{ marginLeft: isMobile ? '4%' : '' ,marginTop: isMobile ? 15 : '' }}><IconLinkRow 
					onClick={goBack}
					color="accent"
					icon={arrowAltCircleLeftIcon}
					iconAlt={t('imgNicons.backArrow')}
					text={t('routes.back')}
					 /></div>
				<Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: isMobile ? '10%' : '4%' }}>
					<Column>
						<Title type="title">{t('getCare.ChatWithDoc.title')}</Title>
					</Column>
					<SubTitleContainer lg={12}>
						<Icon
							className="fa fa-info-circle"
							style={{ width: '2rem', height: '2rem', color: '#3CA70D', marginLeft: '10px' }}
						/>
						<TextSubtitle>{t('getCare.ChatWithDoc.subTitle')}</TextSubtitle>
					</SubTitleContainer>
				</Container>
				<FormContainer>
					<ChatWithDocForm />
				</FormContainer>
			</RightContent>
		</CaptchaProvider>
	);
};

export default ChatWithDocTab;
