import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './app/assets/fonts/proxima-nova-regular.otf';
import App from './App';
import { store, persistor } from './adapter/store';
import theme from './app/ui-core/utils/theme';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import CordobaWrapper from './app/components/templates/cordovaWrapper';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import appConfig from 'config';

ReactDOM.render(
  // <React.StrictMode>
  <KeraltySWS.Provider
    swOptions={{
      key: appConfig.KERALTY_SWS_KEY,
      typeStorage: 'sessionStorage',
    }}
  >
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CordobaWrapper>
            <App />
          </CordobaWrapper>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </KeraltySWS.Provider>,
  // </React.StrictMode>
  document.getElementById('root')
);

navigator?.serviceWorker?.getRegistrations().then((registrations) => {
  for (let registration of registrations) {
    registration.unregister();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// if(process.env.production !== 'development')
// if (window.location.hostname !== 'localhost')
// 	navigator?.serviceWorker?.register(`${process.env.PUBLIC_URL}/service-worker.js`);
// else serviceWorker.unregister();
