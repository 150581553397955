import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { KeraltyText } from '../atoms';
import { SpaceWrapper } from '../templates';
import useUnderMaintenance from 'app/hooks/useUnderMaintenance';

const TermsAndConditions = () => {
	const { t } = useTranslation();
	const location = useLocation();

	const { isUnderMaintenance } = useUnderMaintenance();

	return (
		<div className={'terms-and-conditions'}>
			<KeraltyText type="paragraph" color="primary">
				{t('TandC.text1')}
			</KeraltyText>
			<SpaceWrapper mb={.5}></SpaceWrapper>
			<Link to={ !isUnderMaintenance() ? {pathname:"/legal/privacy-policy", state: {from: location.pathname}} : {}} >
				<KeraltyText type="link" color="#055293" bold>
					{t('routes.legal.privacy')}
				</KeraltyText>
			</Link>{' - '}
			<Link to={ !isUnderMaintenance() ? {pathname:"/legal/terms-of-service", state: {from: location.pathname}} : {}} >
				<KeraltyText type="link" color="#055293" bold>
					{t('routes.legal.terms')}
				</KeraltyText>
			</Link>{ /* ' - ' */ }
			{ /*
				<Link to={{pathname:"/legal/third-party-notices", state: {from: location.pathname}}} >
					<KeraltyText type="link" color="primary" bold>
						{t('routes.legal.third')}
					</KeraltyText>
				</Link>
			*/ }
		</div >
	);
};

export default TermsAndConditions;
