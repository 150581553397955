import React, { useEffect, useState } from 'react'

import appConfig from 'config';
import { TimeString, validateDateByRange } from 'app/ui-core/utils/dates';
import { useAppSelector } from 'adapter/hooks';
import selectSystemSelectors from 'adapter/system/system.selector';
import useAuth from './useAuth';

const useGetCareNow = () => {

	const { currentServerDate } = useAppSelector(selectSystemSelectors.systemInfo);
	const { user } = useAuth();

	const [enableVideoCall, setEnableVideoCall] = useState(true)

	const isEnableVideoCall = () => {
		const config = appConfig.CHAT_WITH_DOCTOR_HOUR;
		return validateDateByRange({
			date: currentServerDate,
			dayRange: config[user.state].DAYS,
			hourRange: { minHour: config[user.state].INIT as TimeString, maxHour: config[user.state].END as TimeString }
		});
	}

	useEffect(() => {

		const response = isEnableVideoCall();

		setEnableVideoCall(response)

	}, [currentServerDate])


  return {
	enableVideoCall,
	isEnableVideoCall
  }
}

export default useGetCareNow
