import { lazy, LazyExoticComponent, Suspense, useMemo } from 'react';
import { Loader } from '../../templates';

const RenderStep = ({ currentStep = 0, prev, next, ...props }) => {
  let Step: LazyExoticComponent<any>;

  const steps: LazyExoticComponent<any>[] = useMemo(() => {
    return [
      lazy(() => import('./RecoverUnblockForm')),
      lazy(() => import('./UnblockContactForm')),
      // lazy(() => import('./VerifyOption')),
      lazy(() => import('./VerifyCode')),
      lazy(() => import('./VerificationStatus')),
      lazy(() => import('./UnblockAccountForm')),
      lazy(() => import('./SuccessfulUnblockChange')),
    ];
  }, []);

  Step = steps[currentStep];

  return (
    <Suspense fallback={<Loader />}>
      <Step prev={prev} next={next} {...props} />
    </Suspense>
  );
};

export default RenderStep;
