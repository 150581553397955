import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'adapter/hooks';

// Actions
import { trySSOLogin } from 'adapter/user/userSlice';
import useAuth from '../hooks/useAuth';
import { Loader } from '../components/templates';

const HandlerSSO = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { search } = location;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const dispatchSSOLogin = async () => {
      let token;
      if (search) {
        token = search.split('=')[1];
        await dispatch(trySSOLogin(token));
      }
      setIsLoading(false);
    };
    dispatchSSOLogin();
  }, [dispatch, search]);

  const showLoader = isLoading || user.status === 'pending';
  if (showLoader) return <Loader />;

  return user?.authUid ? (
    <Redirect to={{ pathname: '/home' }} />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
};

export default HandlerSSO;
