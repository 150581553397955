import { SESSION_STORAGE_ITEMS } from "app/ui-core/utils/constants";
import { KeraltySWS } from "./useKeraltySWS";

const useUnderMaintenance = () => {

    const isUnderMaintenance = (): boolean => {

		// if(sessionStorage.getItem('vim')) sessionStorage.removeItem('vim');

		if(!sessionStorage.getItem(SESSION_STORAGE_ITEMS.IN_MAINTENANCE)) return false;
        
        const maintenance = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ITEMS.IN_MAINTENANCE) ?? '')
		if( maintenance?.canDisplayModal ){ 
		  sessionStorage.setItem(SESSION_STORAGE_ITEMS.UNDER_MAINTENANCE_MODAL, JSON.stringify({ displaiedModal: true }))

		  return true;
		}

		return false
      };


    return {
		isUnderMaintenance,
    }
    
}

export default useUnderMaintenance;