import { InsuranceCode } from 'domain/valueObjects/insurance';
import React, { useCallback } from 'react';
import { ModalInsuranceNotValid } from './ModalInsuranceNotValid';
import { ModalInsuranceInactive } from './ModalInsuranceInactive';
import { ModalInsurancePCP } from './ModalInsurancePCP';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import useAuth from 'app/hooks/useAuth';
import { utcTime } from 'app/ui-core/utils/times';
import { IValidateInsuranceToChatResponse } from 'domain/entities/chatWithDocInfo';
import useChatProvider from 'app/hooks/useChatProvider';

interface ModalProps {
    currentChatStatus: IValidateInsuranceToChatResponse,
    closeModalChatStatus: () => void,
    externalAction?: () => void,
}





const ModalInsuranceValidate = ({ currentChatStatus, closeModalChatStatus, externalAction }: ModalProps) => {

    const dispatch = useAppDispatch();
	const { openChat } = useChatProvider();

    const triggerPatientSupport = useCallback(async () => {
		openChat({ queue: 'support' });
		externalAction && externalAction();
		closeModalChatStatus();
	}, [dispatch]);

  return (
    <>
      {currentChatStatus.code === InsuranceCode.modal_insurance_not_validated && (
        <ModalInsuranceNotValid
          close={closeModalChatStatus}
          triggerPatientSupport={triggerPatientSupport}
          externalAction={externalAction}
        />
      )}
      {currentChatStatus.code === InsuranceCode.modal_insurance_inactived && (
        <ModalInsuranceInactive
          close={closeModalChatStatus}
          triggerPatientSupport={triggerPatientSupport}
          externalAction={externalAction}
        />
      )}
      {currentChatStatus.code === InsuranceCode.modal_pcp_no_validated && (
        <ModalInsurancePCP
          close={closeModalChatStatus}
          triggerPatientSupport={triggerPatientSupport}
          externalAction={externalAction}
        />
      )}
    </>
  );
};

export default ModalInsuranceValidate;
