import { lazy, Suspense, useCallback, useMemo, useState } from 'react';
// 3RD PARTY
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
// UI
import { KeraltyStepper } from '../../components/molecules';
import { Page, Container, Loader } from '../../components/templates';
// LOGIC
import CaptchaProvider from '../../contexts/CaptchaProvider';
import { useStepper } from '../../hooks/useStepper';
import FORMATS from 'app/ui-core/utils/formats';
// ADAPTER
import { useAppDispatch, useAppSelector } from '../../../adapter/hooks';
import { registerActions } from '../../../adapter/register/registerSlice';
import registerSelectors from '../../../adapter/register/registerSelectors';
import PrivateHttpClient from '../../../infrastructure/keraltyApi/privateHttpClient';
// DOMAIN
import FB from '../../../domain/valueObjects/floridaBlue';
// ROUTES
import ROUTES from '../../ui-core/utils/routes';
import { useRouteQuery } from 'app/hooks/useRouteQuery';
import { idEcw } from '../../../adapter/user/userSlice';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const { REGISTER: CURRENT, MAIN } = ROUTES;


const RegisterPage = () => {
	const { t } = useTranslation();
	const { replace, push, goBack } = useHistory();
	const { step } = useParams();
	const dispatch = useAppDispatch();
	const asyncError = useAppSelector(registerSelectors.selectError);
	const formValues = useAppSelector(registerSelectors.selectUserInfo);
	const isLoading = useAppSelector(registerSelectors.selectIsLoading);
	const endpoint = 'auth';
	const history = useHistory();
	const [errorVal, setErrorVal] = useState('');
	const [resVal, setResVal] = useState();
	const [timerLimit, setTimerLimit] = useState(30);
	const [value, setValue] = useState(5);
	const [error, setError] = useState(false);
	const [response, setResponse] = useState({
		"cause": "VERIFIED",
		"tries": 0,
		"timer": 0
	});
	const [smsCode, setSmsCode] = useState();
	const steps = useMemo(() => [
		{
			label: t('register.medicalServices'), route: CURRENT.hadSanitas,
			component: lazy(() => import('./organisms/MedicalServices'))
		},
		{
			label: t('register.personalInfo'), route: CURRENT.basic,
			component: lazy(() => import('./organisms/BasicPersonalInfoForm'))
		},
		{
			label: t('register.contactSecurity'), route: CURRENT.complementary,
			component: lazy(() => import('./organisms/ComplementaryPersonalInfoForm'))
		},
		{
			label: t('register.verification'), route: CURRENT.contact,
			component: lazy(() => import('./organisms/ContactInfoForm'))
		},
		{
			label: '', route: CURRENT.codeSent, hidden: true,
			component: lazy(() => import('./organisms/CodeSent'))
		},
		...(formValues?.contactMethod === 'phone' ?
			[{
				label: '', route: CURRENT.verify, hidden: false,
				component: lazy(() => import('./organisms/VerifySMSCode'))
			},
			{
				label: '', route: CURRENT.secondSent, hidden: true,
				component: lazy(() => import('./organisms/SecondSent'))
			},
			]
			:
			[{
				label: '', route: CURRENT.oneMore, hidden: false,
				component: lazy(() => import('./organisms/OneMoreStep'))
			},
			{
				label: '', route: CURRENT.secondSent, hidden: true,
				component: lazy(() => import('./organisms/SecondSent'))

			},
			]

		)
	], [t, formValues]);

	//#region FLOW ACTIONS
	const handleFinish = useCallback(() => {

		push(`/${MAIN.patientRegistration}`);
	}, [push]);

	const { currentStep, next, Step: FormStep } = useStepper(0, steps, handleFinish);
	const jump = () => {


		history.replace(`triers`)

	}

	let globalValue: any;

	const handleSuccessStep = async (value) => {
		//console.debug('Submit values =>', value);
		dispatch(registerActions.setProp({
			userInfo: {
				...formValues,
				...(formValues?.birthdate ? { birthdate: moment(formValues.birthdate).format(FORMATS.date) } : {}),
				...value
			}
		}));
		let res;
		switch (step) {
			case CURRENT.hadSanitas:
				setErrorVal('');
				if (value.hadSanitas) {
					push(`/${MAIN.register}/${CURRENT.sanitas}/${CURRENT.accountType}`);
					res = false;
				}
				else res = true;
				if (res) next()
				break;
			case CURRENT.basic:
				KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_ID)
				setErrorVal('');
				setResponse({
					"cause": "SUCCESS",
					"tries": 0,
					"timer": 0
				})
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REPLACE_ROUTE, 'basic');
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE, value.state);
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REGISTRATION_CHECK, '');
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.EMAIL, value.email);
				res = await dispatch(registerActions.registrationCheck(value)).unwrap();
				setResponse(res)
				if (res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS' || res?.cause === 'TRIES') {
					push(CURRENT.secondSent)
				}
				if(res?.cause !== 'SUCCESS'){
					if (res?.cause !== FB.USER_NO_FB) {
						const resp = await dispatch(registerActions.loadElegibilityData(res.cause)).unwrap();
						console.log({res, resp})
						let varStorage = JSON.stringify({ ...resp, id: res.cause })
						KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.REGISTRATION_CHECK, varStorage);
					}
				}

				if (res) {
					next()
				}
				// if (res !== FB.USER_NO_FB) {
				// 	push(`/${MAIN.fb}/${MAIN.register}?id=${res}`);
				// 	res = false;
				// }
				break;
			case CURRENT.complementary: res = true;
				if (res) next()
				break;
			case CURRENT.contact:
				setErrorVal('');
				setResponse({
					"cause": "SUCCESS",
					"tries": 0,
					"timer": 0
				})
				KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.ACCOUNT_NUMBER_ID)
				res = await dispatch(registerActions.initialSave()).unwrap();
				dispatch(registerActions.updatePartialRegister({tempSessionId: res.tempSessionId}));
				setResponse(res)
				if (res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS' || res?.cause === 'TRIES') {
					push(CURRENT.secondSent)
					return
				}
				if (res) {
					next()
				}
				break;
			case CURRENT.codeSent: res = true;
				setErrorVal('');
				if (res) next()
				break;
			case CURRENT.verify:
				setErrorVal('');
				const body2 = {
					"email": formValues.email,
					"byEmail": (formValues?.contactMethod === 'phone' ? false : true),
					"state": formValues.state,
					"code": value.smsCode,
				};
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.CODE, value?.smsCode)
				globalValue = value.smsCode;
				setSmsCode(value?.smsCode)
				const headers2 = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};
				try {
					res = await PrivateHttpClient.post(`${endpoint}/register/triesToBlock`, body2, { headers: headers2 })
					setResVal(res?.tries)
					setResponse(res)
					if (res?.cause === 'VERIFIED' || res?.cause === 'ATTEMPTS') {
						push(CURRENT.secondSent)
						return
					}
					if (res?.tries == 5) next()
					if (res?.tries <= 4) {
						if (res.tries == 0) {
							setValue(0)
							next()
						}
						timeOff(res.tries, res.timer)
					}
				} catch (error: any) {
					if (error.message == '160') {
						setValue(160)
						next()
					}
				}
				break;
			case CURRENT.oneMore:
				setErrorVal('');
				const body = {
					"email": formValues?.email,
					"byEmail": (formValues?.contactMethod === 'phone' ? false : true),
					"state": formValues?.state,
					"code": value?.smsCode,
				};
				KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.CODE, value?.smsCode)
				globalValue = value.smsCode;
				setSmsCode(value?.smsCode)
				const headers = {
					"Content-Type": "application/json",
					"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
				};
				setResponse({
					"cause": "SUCCESS",
					"tries": 0,
					"timer": 0
				})
				try {
					res = await PrivateHttpClient.post(`${endpoint}/register/triesToBlock`, body, { headers: headers })
					setResVal(res?.tries)
					setResponse(res)
					console.log(res)
					if(res.cause === 'VERIFIED') {
						setValue(200)
						next()
					}
					if (res?.tries == 5){				
						next()}
					if (res?.tries <= 4) {
						if (res.tries == 0) {
							setValue(0)
							next()
						}
						timeOff(res.tries, res.timer)
					}
				} catch (error: any) {
					if (error.message == '160') {
						setValue(160)
						next()
					}
				}
				break;
			case CURRENT.secondSent:
				const result = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.REGISTRATION_CHECK);
				const code = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CODE)
				const email = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.EMAIL)
				const resultJson: any = JSON.parse(result ? result : '{}')

				try{
					if (resultJson?.id) {
						let infoData: any = { id: smsCode || code, email: formValues.email || email };
						res = await dispatch(registerActions.loadMaxUserInfo(infoData)).unwrap();
					} else {
						if (formValues?.contactMethod === 'phone') {
							res = await dispatch(registerActions.loadUserInfoByCode({ code: smsCode || globalValue || code || '', email: formValues?.email || email, state: formValues?.state || KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE) })).unwrap();
						} else {
							res = await dispatch(registerActions.loadUserInfo({ code: smsCode || globalValue || code || '', email: formValues?.email || email, state: formValues?.state || KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.ID_BELONG_STATE) })).unwrap();
						}
					}

					if (res) next()
					
				}catch(error: any){
					if(error.message === '100' || error.message === '80'){
						setError(true)
					}
					return
				}

				break;
			case CURRENT.errorVerify:
				res = await dispatch(registerActions.loadUserInfoByCode(formValues)).unwrap()
				next()
				break;
		}
	};
	const timeOff = (tries, timer) => {
		setTimerLimit(timer)
		if (tries > 0) {
			if (tries == 1) setErrorVal(t('register.trier') + tries + t('register.trier2'));
			else setErrorVal(t('errors.code150'));
		} else if (tries == 0) {
			setErrorVal(`${t('myAccount.limit1')} ${timer} ${t('myAccount.limit2')}`)
		}
	}
	const handleCleanAsyncError = useCallback(() => {
		dispatch(registerActions.setProp({ error: undefined }))
	}, [dispatch]);

	const handleMissingData = () => replace(`/${MAIN.register}`);
	//#endregion

	//#region UI LOGIC
	const title = useMemo(() => {
		const routesWithoutTitle: string[] = [CURRENT.codeSent, CURRENT.secondSent, CURRENT.errorVerify, CURRENT.trier];
		if (routesWithoutTitle.indexOf(steps[currentStep]?.route) >= 0) return '';

		return t('register.createAccount');
	}, [t, currentStep, steps]);

	const handleGoBack = useCallback(() => {
		handleCleanAsyncError();
		goBack();
	}, [handleCleanAsyncError, goBack]);

	const routeNames = useMemo(() => title ? [

		{ label: t('routes.login'), callback: () => replace(`/${MAIN.login}`) },
		{ label: title || t('routes.register') }
	] : [], [t, title, replace]);
	//#endregion

	return (
		<CaptchaProvider>
			{isLoading && <Loader />}
			<Page
				title={title}
				routes={routeNames}
				onPrevious={handleGoBack}
				previousLabel={t('routes.back')}
				sendCodeResponse={response} >
				<Container centered={'xy'} noPadding>
					<KeraltyStepper steps={steps} activeStep={currentStep} noPadding>
						<Suspense fallback={<Loader />}>
							<FormStep
								timer={timerLimit}
								formValues={formValues}
								onSuccess={handleSuccessStep}
								asyncError={asyncError}
								cleanAsyncError={handleCleanAsyncError}
								onMissingData={handleMissingData}
								errorVal={errorVal}
								value={value}
								setResponse={setResponse}
								response={response}
								error={error} />
						</Suspense>
					</KeraltyStepper>
				</Container>
			</Page>
		</CaptchaProvider>
	);
};

export default RegisterPage;

