import { createSelector } from 'reselect';
import { RootState } from '../store';
import { SystemState } from './system.state';

const selectSelf = (state: RootState): SystemState => state.system;
const systemInfo = createSelector(selectSelf, value => value);

const selectSystem = createSelector(
	selectSelf,
	(info: SystemState) => ({...info})
);

const selectSystemSelectors = {
	selectSystem,
	systemInfo
};

export default selectSystemSelectors;
