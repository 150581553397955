import styled from 'styled-components';
import Image from '../image';

export const SectionWidget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageWidget = styled(Image)`
  width: auto;
  height: 35px;
  margin-bottom: 18px;
`;
