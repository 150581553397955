const nsTooltip = {
	en: {
		accountNumber: 'Your Sanitas account number (MRN) is located at the top of all medical records or lab results from Sanitas',
		enterBirth: 'Enter your date of birth.',
		regexValidation: 'At least 8 characters, one special character (e.g., ! @ # ?), one number, one uppercase letter.',
		firstName: 'If you have insurance, make sure your first name matches the name on your insurance card.',
		lastName: 'If you have insurance, make sure your last name matches the name on your insurance card.',
		email: 'The e-mail provided needs to match Sanitas’ records.',
		mobileNumber: 'The mobile number that you use to communicate with Sanitas.',
		state: 'The state where you reside.',
		insuranceCardInfo: 'Fill out this field as shown on your medical ID card.',
		enterFirstName: 'Please fill out your first name according to your medical ID card, this will be validated against it',
		enterLastName: 'Please fill out your last name according to your medical ID card, this will be validated against it',
		enterBirthDate: 'Please fill out your birth date according to your medical ID card, this will be validated against it',
	},
	es: {
		accountNumber: 'Tu número de cuenta Sanitas (MRN) se encuentra en la parte superior de todos nuestros registros médicos y resultados de laboratorio.',
		enterBirth: 'Ingresa tu fecha de nacimiento',
		regexValidation: 'Al menos 8 caracteres, un caracter especial (ej, ! @ # ?), un número, una mayúscula.',
		firstName: 'Si tienes Seguro médico, verifica que tu nombre coincide con el de la tarjeta de la aseguradora.',
		lastName: 'Si tienes Seguro médico, verifica que tu apellido coincide con el de la tarjeta de la aseguradora.',
		email: 'El correo electrónico ingresado debe coincidir con los registros de Sanitas',
		mobileNumber: 'El número de móvil que utilizas para comunicarte con Sanitas.',
		state: 'El estado de residencia',
		insuranceCardInfo: 'Ingrese la información como se muestra en su tarjeta de seguro médico',
		enterFirstName: 'Por favor Ingresa el primer nombre tal y como se muestra en la tarjeta del seguro médico ya que se valida con este.',
		enterLastName: 'Por favor Ingresa el apellido tal y como se muestra en la tarjeta del seguro médico ya que se valida con este.',
		enterBirthDate: 'Por favor Ingresa la fecha de nacimiento tal y como se muestra en la tarjeta del seguro médico ya que se valida con este.'
	}
}
export default nsTooltip;
