import { Switch, Route, Redirect, useRouteMatch, useParams, useHistory  } from 'react-router-dom';

import LoginLayout from "app/components/organisms/login/loginLayout/LoginLayout";
import { AuthenticationRoutes } from './routes';
import { SendCode, ChooseOption, Status } from './pages';
import AuthenticationLayout from './AuthenticationLayout';
import { useTranslation } from 'react-i18next';
import ROUTES from 'app/ui-core/utils/routes';
import { useTwoFactorAuthentication } from 'app/hooks/useTwoFactorAuthentication';
import { TwoFactorAuthenticationSteps } from 'adapter/twoFactorAuthentication/twoFactorAuthentication.state';
import CaptchaProvider from 'app/contexts/CaptchaProvider';

const { MAIN, REGISTER: CURRENT } = ROUTES;

const Authentication = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const { infoTwoFactor } = useTwoFactorAuthentication();

    const headerOptions = [
        { label: t('routes.login'), callback: () => history.replace(`/`) },
        { label: t('routes.loginAuthentication') }
    ]

    const option = [
        {
            path: `/${MAIN.authentication}/${CURRENT.chooseOption}`,
            backTo: () => history.replace('/login')
        },
        {
            path: `/${MAIN.authentication}/${CURRENT.sendCode}`,
            backTo: () => history.replace(`/${MAIN.authentication}/${CURRENT.chooseOption}`)
        },
        {
            path: `/${MAIN.authentication}/${CURRENT.status}`,
            backTo: () => history.replace( 
                infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.chooseOptions 
                ? `/${MAIN.authentication}/${CURRENT.chooseOption}` 
                : infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.sendCode
                ? `/${MAIN.authentication}/${CURRENT.sendCode}`
                : infoTwoFactor.currentStep === TwoFactorAuthenticationSteps.resendCode
                ? `/${MAIN.authentication}/${CURRENT.sendCode}`
                : '/login'
            )
        }
    ]

    const back = option.find(option => option.path === window.location.pathname)

    const handleGoBack = {
        label: t('routes.back'),
        callback: back ? back.backTo : () => history.goBack()
    }

    return (
        <LoginLayout hideStores hideFloattingMenu>
            <CaptchaProvider>
                <AuthenticationLayout headerOptions={headerOptions} handleGoBack={handleGoBack}>
                    <Switch>
                        <Redirect
                            exact from={AuthenticationRoutes.main}
                            to={AuthenticationRoutes.chooseOption} 
                        />
                        <Route
                            path={AuthenticationRoutes.chooseOption}
                            component={ChooseOption}
                        />
                        <Route
                            path={AuthenticationRoutes.sendCode}
                            component={SendCode} 
                        />
                        <Route
                            path={AuthenticationRoutes.status}
                            component={Status} 
                        />
                    </Switch>
                </AuthenticationLayout>
            </CaptchaProvider>
        </LoginLayout>
  )
}

export default Authentication;