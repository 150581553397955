import appConfig from 'config';
import AuthRepository from '../../infrastructure/keraltyApi/repositories/auth';
// DOMAIN
import { LifeStyleLibService } from 'domain/useCases/contracts/LifeStyleLibService';

const LifeStyleLibRepository: LifeStyleLibService = {
  load: ({ lang = 'en', ...userData }) => {
    const {
      THEME: theme,
      ENV: environment,
      LAN,
    } = appConfig.LIFESTYLE_LIB;

    const { PROC_ID: procedureId } = !lang || lang !== 'es' ? LAN.EN : LAN.ES;

    const loadWidget = (token) => {
      // @ts-ignore
      return SenselyWidget.init({
        // Sensely provided username & password (not required if using token)
        theme,
        token,
        procedureId,
        lang,
        // Optional additional data to control the conversation
        conversationData: {
          userInfo: {
            userType: 'nonpublic',
            memberid: userData.idEcw || '0',
            dob: userData.birthdate,
            gender: userData.sex,
            organization_user_id: userData.authUid,
            environment
          },
        },
      }).catch((err) => {
        console.error('LifeStyleLib Sensely', err);
        return {
          name: 'Life Style Library Load Failure',
          message: 'lifeStyleLibLoad',
          code: 92, // TODO: MAP code92 error
        };
      });
    };

    return AuthRepository.senselyAuthentication({ locale: lang })
      .then((data) => {
        const { access_token: token } = data;
        return loadWidget(token);
      })
      .catch(() => {
        loadWidget('');
      });
  },
};

export default LifeStyleLibRepository;

