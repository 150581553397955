import { ImageContainer } from '../login.styled';
import { ContainerBubbleButton, Text } from './BubbleButton.styled';
interface Props {
  src?: string;
  onClick?: () => void;
  text?: string;
}
export const BubbleButton = ({ src, onClick, text }: Props) => {
  return (
    <ContainerBubbleButton>
      <ImageContainer src={src} onClick={onClick} />
      <Text dangerouslySetInnerHTML={{ __html: text?.replace(/\n/g, '<br />') || '' }} />
    </ContainerBubbleButton>
  );
};
