import { forwardRef, useEffect, useImperativeHandle } from 'react';

// import { useSanitas } from '@quillbot/keralty-sanitas-chatbot';

import { useAppSelector, useAppDispatch } from '../../../../adapter/hooks';

import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';

const changeVisibilitySanitas = (visibilityState: 'visible' | 'hidden', dispatch: any = null) => {


  const nodeSanitasChatFrame = document.getElementById("iframe-id");

  if (nodeSanitasChatFrame && nodeSanitasChatFrame['style']) {
    nodeSanitasChatFrame['style'].visibility = visibilityState;
  }

  if (visibilityState === 'hidden' && dispatch) {
    dispatch(sanitasAdapterMethods.setStateSanitas({
      isShow: false,
    }));
  }
}

const toOnHide = (dispatch) => changeVisibilitySanitas("hidden", dispatch);
const toOnShow = (dispatch) => changeVisibilitySanitas("visible", dispatch);

const SanitasChat = forwardRef<SanitasChatRef, {}>((props, ref) => {
  // const { hide, show } = useSanitas();
  const dispatch = useAppDispatch();
  const sanitasChatState = useAppSelector((state) => state.sanitasChat);
  const { sanitasProps, sanitasState } = sanitasChatState;
  

  useImperativeHandle(ref, () => ({
    onHideChat() {
      toOnHide(dispatch);
    },
    onShowChat() {
      toOnShow(dispatch)
    },
    showState: sanitasState.isShow
  }));

  useEffect(() => {    
    if (sanitasState.isShow && sanitasState.isSetted) {
      setTimeout(() => {
        // show(sanitasProps);
      });
    }

  }, [sanitasProps, sanitasState, /* show, hide, */ dispatch]);

  return (<div></div>);
});


export const ChangeVisibility = changeVisibilitySanitas;

export default SanitasChat;

export interface SanitasChatRef {
  onHideChat: () => void;
  onShowChat: () => void;
  showState: boolean;
}