import { useState, useMemo, useEffect } from 'react';

import { Container, Row } from '../templates';
import PaginateBar from '../atoms/paginateBar';

interface PaginateItemsProps {
  itemsPerPage?: number;
  children: any | any[];
  isCenter?: boolean;
  setToStartPage?: boolean;
}

const PaginateItems = ({ itemsPerPage = 5, children, isCenter = false, setToStartPage }: PaginateItemsProps) => {
  const [currPage, setCurrPage] = useState(1);
  const [itemsPPage] = useState(itemsPerPage);
  const lastPage = Math.ceil(children.length / itemsPPage);

  const currChildren = useMemo(() => {
    const indexLastPage = currPage * itemsPPage;
    const indexFirstPage = indexLastPage - itemsPPage;

    return children.slice(indexFirstPage, indexLastPage);
  }, [children, currPage, itemsPPage]);

  useEffect(() => {
	if(!setToStartPage) return
	setCurrPage(1)
  }, [setToStartPage])


  return (
    <>
      <Container spaceAround="x">
        <Row style={isCenter ? { justifyContent: 'center' } : undefined}>{currChildren}</Row>

        <Row>
          <Container centered="x" noPadding>
            <Container centered="y" noPadding>
              {itemsPerPage < children.length && (
                <PaginateBar
                  currentPage={currPage}
                  itemsPerPage={itemsPPage}
                  lastPage={lastPage}
                  select={(page) => setCurrPage(page)}
                  totalItems={children.length}
                />
              )}
            </Container>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default PaginateItems;
