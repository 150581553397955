import * as METHODS from 'adapter/sanitasChat/sanitasChat';

export const openSanitasChat = async (dispatch, payload) => {
  const result = await dispatch(METHODS.openSanitasChat(payload));
  return result;
};

export const showSanitasChat = async (dispatch) => {
  await dispatch(METHODS.show());
}

export const hideSanitasChat = async (dispatch) => {
  await dispatch(METHODS.hide());
}

export const shutdownSanitasChat = async (dispatch) => {
  await dispatch(METHODS.shutdown());
}

export const setUpSanitasChat = async (dispatch, payload) => {
  const result = await dispatch(METHODS.setUp(payload));
  return result;
};

export const setPayloadSanitasChat = async (dispatch, payload) => {
  const result = await dispatch(METHODS.setPayload(payload));
  return result;
};

export const setStateSanitasChat = async (dispatch, payload) => {
  const result = await dispatch(METHODS.setStateSanitas(payload));
  return result;
};
