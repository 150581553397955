import LoginLayout from 'app/components/organisms/login/loginLayout/LoginLayout';
import LoginForm from 'app/components/organisms/login/loginForm/LoginForm';
import { Redirect } from 'react-router-dom';

import { Container, Loader, Page, SpaceWrapper } from '../../components/templates';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../adapter/hooks';
import TermsAndConditions from '../../components/termsAndConditions/TermsAndConditions';
import CaptchaProvider from '../../contexts/CaptchaProvider';
import chatSelectors from '../../../adapter/chat/chatSelectors';
import Healow from 'app/components/Healow';

const LoginPage = () => {
	const { t } = useTranslation();

	const isLoggingIn = useAppSelector((state) => state.user.isLoading);
	const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
	const isLoading = useAppSelector(chatSelectors.selectIsLoading);

	const Pagefooter = (
		<Container centered={'xy'} end={'y'} noPadding>
			<div className="login-form-footer">
				<TermsAndConditions />
			</div>
		</Container>
	);


	return (
		isLoggedIn ? (
			<Redirect to={{ pathname: '/' }} />
		) :
			<CaptchaProvider>
				<Healow />
				<LoginLayout hideStores bottomPosition={'15%'}>
					{(isLoggingIn || isLoading) && <Loader />}
					<Page
						bigTitle
						className={'no-margin-top'}
						title={t('loginTitle')}
						subtitleProps={{ bold: false }}
						subtitle={t('loginSubTitle')}
						footer={Pagefooter}>
						<Container centered={'x'} mt={3}>
							<LoginForm />
						</Container>
						<SpaceWrapper mt={5}></SpaceWrapper>
					</Page>

				</LoginLayout>
			</CaptchaProvider>
	);
};

export default LoginPage;
