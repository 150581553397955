import { useParams, Redirect } from 'react-router-dom';
import ROUTES from '../ui-core/utils/routes';


const { MAIN, UNBLOCK } = ROUTES;

const UnblockAccountGuard = ({ children }) => {
  const { step } = useParams();

  //@TODO: Validate unblock form
  return step ? children : <Redirect to={`/${MAIN.unblock}/${UNBLOCK.recover}`}/>
};

export default UnblockAccountGuard;