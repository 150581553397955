import type { EcwService } from '../../../domain/useCases/contracts/ecwService';
// DOMAIN
import PrivateHttpClient from '../privateHttpClient';
import PublicHttpClient from '../publicHttpClient';
import appConfig from 'config';

const endpoint = 'ecw';

export const EcwRepository: EcwService = {
  loadInsurances: async () => {
    return PublicHttpClient.get(`${endpoint}/general/insurances`);
  },
  loadInsurancesByState: ( state: string) => {
    return PrivateHttpClient.get(`${endpoint}/general/insurances/${state}`);
  },
  getPharmacies: (zipCode: number) => {
    return PrivateHttpClient.get(`${endpoint}/general/pharmacies-by-zip-code?zipCode=${zipCode}`,{ headers: { Authorization: `Bearer ` + appConfig.PUBLIC_API_TOKEN } });
  }, 
  getCurrentMedication: (pacientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/currentMedications?patientId=${pacientId}`);
  },
  getLabData: (pacientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/lab-dis?patientId=${pacientId}`);
  },
  getMedicationData: (pacientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/medications?patientId=${pacientId}`);
  },
  getReferralData: (pacientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/referrals?patientId=${pacientId}`);
  },
  getInmunizationData: (pacientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/immunizations?patientId=${pacientId}`);
  },
  getPersonalData: (user: any) => {
    return PrivateHttpClient.get(
      `${endpoint}/search/personal-info?dob=${user.birthdate}&firstName=${user.firstName}&lastName=${user.lastName}&sex=${user.sex}&state=${user.state}&email=${user.email}&phone=${user.mobile}`
    );
  }, 
  getMaritalStatus: (user: any) => {
    return PrivateHttpClient.get(
      `${endpoint}/general/marital-status`
    );
  },
  getPatientInfo: (data: any) => {
    return PrivateHttpClient.get(
      `/users/patient-info/${data.authUid}/${data.state}`      
    );
  },
  getInsuranceListData: (patientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/insurance-providers?patientId=${patientId}`);
  },
  getInsuranceListDataByState: (authId: string, state: string) => {
    return PrivateHttpClient.get(`/auth/insurance/information/${authId}/${state}`);
  },
  getVisitSummaryData: (payload: any) => {
    const { idEcw, ...allOthers } = payload;
    const params = new URLSearchParams(allOthers).toString();

    return PrivateHttpClient.get(`${endpoint}/patient/visit-summaries?${params}`);
  },
  getPreviousAppointments: (payload: any) => {
    const { idEcw, ...allOthers } = payload;
    const params = new URLSearchParams(allOthers).toString();

    return PrivateHttpClient.get(`${endpoint}/patient/previous-appointments?${params}`);
  },

  getUpcomingAppointments: (payload: any) => {
    const { idEcw, ...allOthers } = payload;
    const params = new URLSearchParams(allOthers).toString();

    return PrivateHttpClient.get(`${endpoint}/patient/upcoming-appointments-vim?${params}`);
  },
  getUpcomingAppointmentsBeWell: (payload: any,state:string) => {
    const { idEcw, ...allOthers } = payload;
    const params = new URLSearchParams(allOthers).toString();

    return PrivateHttpClient.get(`${endpoint}/patient/upcoming-appointments-vim/${state}?${params}`);
  },

  getPreviousAppointmentsBeWell: (payload: any,state:string) => {
    const { idEcw, ...allOthers } = payload;
    const params = new URLSearchParams(allOthers).toString();

    return PrivateHttpClient.get(`${endpoint}/patient/previous-appointments/${state}?${params}`);
  },
  getEmergencyContactData: async (patientId: number) => {
    return PrivateHttpClient.get(`${endpoint}/patient/emergency-contacts?patientId=${patientId}`);
  },
};
