/**
###### toLocaleString ######

        - dateStyle: "full", "long", "medium", "short"
        - timeStyle: "full", "long", "medium", "short"
        - localeMatcher: "best-fit" (default), "lookup"

    timeZone
        - hour12: false, true
        - hourCycle: "h11", "h12", "h23", "h24"
        - formatMatcher: "basic", "best-fit" (default)
        - weekday: "long", "short", "narrow"
        - year: "2-digit", "numeric"
        - month: "2-digit", "long", "narrow", "numeric", "short"
        - day: "2-digit", "numeric"
        - hour: "2-digit", "numeric"
        - minute: "2-digit", "numeric"
        - second: "2-digit", "numeric"
        - timeZoneName: "long", "short"
*/
import i18n from 'i18next';
import { string } from 'yup/lib/locale';
import { DAYS_OF_WEEK } from './constants';
import { ServerDateTime } from 'adapter/system/system.state';

type Format = "2-digit" | "long" | "narrow" | "numeric" | "short";

interface CurrentDateProps {
    date: string,
    timeZone?: TimeZone,
    language?: string
}

interface TimeZone {
    timeZone: string,
    key?: string
}

interface DateByRange {
	date: ServerDateTime,
	dayRange: string[],
	hourRange: HourRange
}

interface HourRange { minHour: TimeString, maxHour: TimeString }
export type TimeString = `${number}${number}:${number}${number}`;

// --------------------------------------------------------------------------------------

const getMonthName = (date: string | undefined, format: Format ): string => {

    if(!date) return '';

    const dateConverted = new Date(date);
    const month = dateConverted.toLocaleString(i18n.language ?? 'default', { month: format });

    return month;

}

const getDateAsText = (date: string | undefined, format: Format): string => {

    if(!date) return '';

    const month = getMonthName(date, format)
    const newDate = new Date(date);
    return `${month} ${newDate.getDate()}${i18n.language === 'en' ? ordinalText(newDate.getDate()) : ''}, ${newDate.getFullYear()}`
}

const ordinalText = (day: number) => {
    if( day % 10 === 1 ) return 'st';
    if( day === 2 || day === 22 ) return 'nd';
    if( day === 3 || day === 23 ) return 'rd';
    if( day >= 4 && day <= 20 || day >= 24 && day <= 30 ) return 'th';

    return '';
}

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const EasternTime = 'America/New_York';

const getCustomDate = ({date, timeZone = {timeZone: currentTimeZone, key: ''}, language = 'en'}: CurrentDateProps) => {

    let originalDate = new Date(date);

    const finalDate = originalDate.toLocaleString(`${language}-US`, {
      timeZone: timeZone.timeZone,
      year: 'numeric',
      month: 'numeric',//'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: true,
    });

    const finalDate2 = originalDate.toLocaleString('en-US', {
        timeZone: timeZone.timeZone,
    });

    const finalDateSplited = finalDate.replace(',', '').split(' ');

    if(language === 'es') {

        const customDate = finalDateSplited[0].split('/')

        const day = parseInt(customDate[0]) > 9 ? customDate[0] : `0${customDate[0]}`;
        const month = parseInt(customDate[1]) > 9 ? customDate[1] : `0${customDate[1]}`

        return {
            date: `${month}/${day}/${customDate[2]}`,
            time: `${finalDateSplited[1]} ${finalDateSplited[2]} ${timeZone?.key ?? ''}`,
            dateToValidate: finalDate2
        };

    } else {

        const customDate = finalDateSplited[0].split('/')

        const month = parseInt(customDate[0]) > 9 ? customDate[0] : `0${customDate[0]}`;
        const day = parseInt(customDate[1]) > 9 ? customDate[1] : `0${customDate[1]}`

        return {
            date: `${month}/${day}/${customDate[2]}`,
            time: `${finalDateSplited[1]} ${finalDateSplited[2]} ${timeZone?.key ?? ''}`,
            dateToValidate: finalDate2
        };

    }

}

const getHourByTimeZone = (timeZone: string) => {
    const date = new Date();

    // create a DateTimeFormat object with specific options
    const dateFormat = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
        year: 'numeric',
        month: '2-digit',//'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).formatToParts(date).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {
        day: string,
        hour: string,
        literal: string,
        minute: string,
        month: string,
        timeZoneName: string,
        year: string,
    });

    return dateFormat;
}

const validateDateByRange = ({date, dayRange, hourRange}: DateByRange) => {
    const day = date.day;       // 0 (Domingo) - 6 (Sábado)
    const hours = date.hour;   // 0 - 23
    const minutes = date.minute; // 0 - 59

    const isWeekday = dayRange.map( day => DAYS_OF_WEEK[day] ).includes(day);

	const isInTimeRange = (hours === (Number(hourRange.maxHour.split(':')[0])) && (Number(hourRange.maxHour.split(':')[1])) === 0 ) ? false : !(hours >= Number(hourRange.minHour.split(':')[0]) && hours <= Number(hourRange.maxHour.split(':')[0])) ? false : (hours >= Number(hourRange.minHour.split(':')[0]) && hours <= Number(hourRange.maxHour.split(':')[0]))
		&& (Number(hourRange.minHour.split(':')[1]) === 0 ? true : minutes >= Number(hourRange.minHour.split(':')[1])) && (Number(hourRange.maxHour.split(':')[1]) === 0 ? true : minutes < Number(hourRange.maxHour.split(':')[1]));

	// console.log({
	//     isWeekday,
	//     hours,
	//     minutes,
	//     hourRange,
	//     isInTimeRange,
	//     hora: Number(hourRange.minHour.split(':')[0]) <= hours && hours >= Number(hourRange.maxHour.split(':')[0]),
	//     minuto: Number(hourRange.minHour.split(':')[1]) <= minutes && minutes >= Number(hourRange.maxHour.split(':')[1]),
	//     horamin: Number(hourRange.minHour.split(':')[0]),
	//     horamax: Number(hourRange.maxHour.split(':')[0]),
	//     minmin: Number(hourRange.minHour.split(':')[1]),
	//     minmax: Number(hourRange.maxHour.split(':')[1])
	// })
	// console.log("====================")
	// console.log("=> en hora: ", hours >= Number(hourRange.minHour.split(':')[0]) && hours <= Number(hourRange.maxHour.split(':')[0]))
	// console.log("=> en minuto: ", minutes >= Number(hourRange.minHour.split(':')[1]) && minutes <= Number(hourRange.maxHour.split(':')[1]))

    return isWeekday && isInTimeRange;
}

const transformUtcDate = ({ date, timeZone }: { date: string, timeZone: string}) => {

		const utcDate = new Date(date);

		const options = {
			timeZone, // Zona horaria para Central Time
			hour12: false,               // Formato de 24 horas
			year: 'numeric' as "numeric" | "2-digit" | undefined,
			month: '2-digit' as "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined,
			day: '2-digit' as "numeric" | "2-digit" | undefined,
			hour: '2-digit' as "numeric" | "2-digit" | undefined,
			minute: '2-digit' as "numeric" | "2-digit" | undefined,
			second: '2-digit' as "numeric" | "2-digit" | undefined
		};

		const centralTime = utcDate.toLocaleString('en-US', options);

		return centralTime;

}

const convertTo12HourFormat = (hour24: string) => {
    let [hours, minutes] = hour24.split(':').map(Number);

    // Determinar AM o PM
    const ampm = hours >= 12 ? 'p.m' : 'a.m';

    // Convertir a formato de 12 horas
    hours = hours % 12;
    hours = hours ? hours : 12; // Si la hora es 0 (medianoche), ajustarla a 12

    // Retornar la hora en formato 12 horas
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
}

export {
    getMonthName,
    getDateAsText,
    getCustomDate,
    getHourByTimeZone,
	validateDateByRange,
	transformUtcDate,
	convertTo12HourFormat
}
