import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { PersonalInfo } from 'domain/entities/personalInfo';
import { InsuranceInfo } from 'domain/entities/insuranceInfo';
import { EmergencyContanctInfo } from 'domain/entities/emergencyContanctInfo';

import MyAccount from 'domain/useCases/myAccount';
import { EcwRepository as myAccountServices } from 'infrastructure/keraltyApi/repositories/ecw';

interface InitialState<DataEntityType = any> {
	data: DataEntityType | null;
	isLoading: boolean;
	error: SerializedError | null;
}

type MyAccountType = {
	personalInfo?: PersonalInfo;
	insuranceInfo?: InsuranceInfo[];
	emergencyContactInfo?: EmergencyContanctInfo;
}

const initialState: InitialState<MyAccountType> = {
	data: null,
	isLoading: false,
	error: null
};

export const getPersonalData = createAsyncThunk(
	'myAccount/getPersonalData', async (user: any) => {
		return await MyAccount.getPersonalData(myAccountServices, user);
	}
);

export const getMaritalStatus = createAsyncThunk(
	'myAccount/getMaritalStatus', async (user: any) => {
		return await MyAccount.getMaritalStatus(myAccountServices, user);
	}
);

export const getPatientInfo = createAsyncThunk( 
	'myAccount/getPatientInfo', async (user: object) => {
		return await MyAccount.getPatientInfo(myAccountServices, user);
	}
);

export const getInsuranceListData = createAsyncThunk(
	'myAccount/getInsuranceListData', async (patientID: number) => {
		return await MyAccount.getInsuranceListData(myAccountServices, patientID);
	}
);

export const getVisitSummaryData = createAsyncThunk(
	'myAccount/getVisitSummaryData', async (patientId: number) => {
		return await MyAccount.getVisitSummaryData(myAccountServices, patientId);
	}
);

export const getEmergencyContactData = createAsyncThunk(
	'myAccount/getEmergencyContactData', async (patientID: number) => {
		return await MyAccount.getEmergencyContactData(myAccountServices, patientID);
	}
);

const handleFulfilled = (stateKey: string) => (state, { payload }) => {
	state.error = null;
	state.isLoading = false;
	if (payload?.length || Object.entries(payload).length) {
		state.data = {
			...state.data,
			[stateKey]: payload
		}
	}
}

const handlePending = (state) => {
	state.error = null;
	state.isLoading = true;
}

const handleError = (state, action) => {
	state.error = action.error;
	state.isLoading = false;
}


export const MyAccountSlice = createSlice({
	name: 'myAccount',
	initialState,
	reducers: {
		cleanError: (state) => {
			state.error = null;
		},
		cleanState: (state) => {
			state.data = null;
		}
	},
	extraReducers: (builder) => {
		builder
			// Personal Info Results
			.addCase(getPersonalData.pending, handlePending)
			.addCase(getPersonalData.fulfilled, handleFulfilled('personalInfo'))
			.addCase(getPersonalData.rejected, handleError)
			// Insurance Data Info
			.addCase(getMaritalStatus.pending, handlePending)
			.addCase(getMaritalStatus.fulfilled, handleFulfilled('personalInfo'))
			.addCase(getMaritalStatus.rejected, handleError)
			// Insurance Data Info
			.addCase(getInsuranceListData.pending, handlePending)
			.addCase(getInsuranceListData.fulfilled, handleFulfilled('insuranceInfo'))
			.addCase(getInsuranceListData.rejected, handleError)
			// Emergency Contact Data Info
			.addCase(getEmergencyContactData.pending, handlePending)
			.addCase(getEmergencyContactData.fulfilled, handleFulfilled('emergencyContactInfo'))
			.addCase(getEmergencyContactData.rejected, handleError)

	},
});

export const myAccountState = (state: RootState) => state.MyAccountSlice;
export const { cleanError } = MyAccountSlice.actions
export const { cleanState } = MyAccountSlice.actions
export default MyAccountSlice.reducer;
