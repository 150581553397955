import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from '../../../../ui-core/styled-components';
// UI
import arrowAltCircleLeftIcon from '../../../../assets/icons/arrow-alt-circle-left.svg';
import { ReactComponent as pendienteicon } from '../../../../assets/icons/pendienteicon.svg';
import { ReactComponent as clock1220 } from '../../../../assets/icons/clock1220.svg';

import { Row, Column, IconLinkRow } from '../../../templates';
import { KeraltyButton, KeraltyText, KeraltyIcon, Image } from '../../../atoms';
import BREAKPOINTS from '../../../../ui-core/utils/breakpoints';

// ADAPTER
import { useAppDispatch, useAppSelector } from '../../../../../adapter/hooks';
import * as sanitasAdapterMethods from '../../../../../adapter/sanitasChat/sanitasChat';
import { closeSymptomChecker } from '../../../../../adapter/chat/chatSlice';
// LOGIC
import CaptchaProvider from '../../../../contexts/CaptchaProvider';
import useIsMobile from '../../../../hooks/useIsMobile';
import { TEXT } from 'app/components/atoms/circularMenu/FloatingMenu';
import { ISessionInformation } from '../../../../../domain/entities/paymentInfo';
import moment from 'moment';
import FORMATS from '../../../../ui-core/utils/formats';

type SvgIconProps = {
  src: React.FC;
  color?: string;
  w?: number | string | null;
  h?: number | string | null;
};

const IconWrapper = styled.span<Partial<SvgIconProps>>`
  svg {
    ${({ theme: { colors }, color, w, h }) => `
      color: ${color ? colors[color] : undefined};
      fill: ${color ? colors[color] : undefined};
      
      path {
        color: ${color ? colors[color] : undefined};
        fill: ${color ? colors[color] : undefined};
      }

      ${h ? `height: ${h}rem !important;` : null}

      ${w ? `width: ${w}rem !important;` : null}
    `}
  }
`;

const RightContent = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 100%;
  align-items: flex-start;
  background: transparent;
  height: auto;
`;
const Container = styled(Column)`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
        @media (max-width: ${BREAKPOINTS.maxTabletWidth}) {
            width: auto;
            align-items: center;
            text-align: center;
        }
  }
`;

const SubTitleContainer = styled(Column)`
  justify-content: center;
  padding: 1rem 0rem;
  width: 60% !important;
  text-align: center;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    text-align: center;
    margin-bottom: '5%' !important;
    display: flex;
    align-items: center;
    width: 80% !important;
  }
`;

const TextSubtitle = styled(Column)`
  color: #000000 !important;
  text-align: center;
  font-size: 16px;
  line-height: 1.58rem;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color:#055293 !important;
	}
`;

const FormContainer = styled(Row)`
  justify-content: center;
`;

const SvgIcon: React.FC<SvgIconProps> = ({ src: IconComponent, color, w, h }) =>
  IconComponent && (
    <IconWrapper color={color} w={w ? w : null} h={h ? h : null}>
      <IconComponent />
    </IconWrapper>
  );

const LabelRefBottom = ({ title, text, width = '100%', customSize='16px' }) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    return (
      <Row noMargin style={{ marginBottom: 7, width: '100%' }}>
        <Column noPadding style={{ width, textAlign: 'left' }}>
          <span
            style={{
              width: !isMobile ? '100%' : '60%',
              fontSize: customSize,
              fontWeight: '700',
              color: ! isMobile?'#5B5C5B':'#055293',
              lineHeight: '30px',
            }}
          >
            {t(title)}
          </span>
          <span
            style={{
              textAlign: 'right', overflowWrap: 'break-word',
              width: !isMobile ? '100%' : '50%',
              fontSize: customSize,
              fontWeight: '400',
              color: ! isMobile?'#5B5C5B':'#055293',
              lineHeight: '30px',
              marginRight: isMobile ? '10%' : '40%'
            }}
          >
            {text}
          </span>
        </Column>
      </Row>
    );
};

interface ITelevisitPayResultProps {
  paymentInfo: ISessionInformation 
}

const TelevisitPayTransactionExpired = ({ paymentInfo }: ITelevisitPayResultProps) => {
  const isMobile = useIsMobile();
  const { goBack, replace } = useHistory();
  const { t } = useTranslation();

  const { id, transactionInformation: { paymentID, paymentDate, serviceTotalValue } } = paymentInfo;
  const time = paymentDate ? moment(new Date(paymentDate)).format(FORMATS.date4) : null;

  return (
   // <CaptchaProvider>
      <RightContent fullHeight bg="light">

        <Container
          style={{ alignItems: 'center', alignSelf: 'center', marginTop: isMobile ? '10%' : '1%' }}
        >
          <Column
            style={{
              display: isMobile ? 'grid' : '-webkit-box',
              WebkitBoxAlign: 'center',
              paddingRight: isMobile ? '1%' : '5%',
            }}
          >
            <SvgIcon src={clock1220} w={isMobile ? 4 : 3.5} h={isMobile ? 4 : 3.5} />
            <KeraltyText type="title" fontWeight={'400'} fontSize={isMobile ? '24px' : '32px'} style={{ fontWeight: 'normal' }}>
              {t('getCare.televisit.transactionExpired')}
            </KeraltyText>
          </Column>
          <SubTitleContainer lg={12}>
            <TextSubtitle>{t('getCare.televisit.transactionExpiredSubtitle')}</TextSubtitle>
          </SubTitleContainer>
        </Container>

        <FormContainer>
          <Container>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: '3%',
                paddingLeft: isMobile ? '5%' : '10%',
              }}
            >
              <Row noMargin style={{ width: isMobile ? '90%' : '50%', paddingTop: 3 }}>
                <LabelRefBottom title={'getCare.televisit.transactionID'} text={id} />
                <LabelRefBottom
                  title={'getCare.televisit.transactionState'}
                  text={t('getCare.televisit.expired')}
                />
                <LabelRefBottom title={'getCare.televisit.transactionTotal'} text={`$${Number.parseFloat(serviceTotalValue.toString()).toFixed(2)}`} customSize={isMobile ? '20px' : '16px'} />
              </Row>
            </div>

            <div style={{ width: '100%', textAlign: 'center', paddingTop: isMobile ? '10%' : '3%' }}>
              <KeraltyButton
                onClick={ () => replace('/get-care') }
                style={{ width: isMobile ? '80%' : window.innerWidth <= 1280 ? '40%' : window.innerWidth === 1366 ? '35%' : '30%', fontWeight: 600, fontSize: isMobile ? '14px' : '20px' }} 
                full
                smFull
              >
                {t('button.returnPreviousMenu')}
              </KeraltyButton>
            </div>
          </Container>
        </FormContainer>
      </RightContent>
   // </CaptchaProvider>
  );
};

export default TelevisitPayTransactionExpired;
