import { KeraltyText, SvgIcon } from 'app/components/atoms';
import { TitleContiner } from '../Anxiety.styled';

interface TitleSectionProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>,
  title: string,
  subtitle: string
}

const TitleSection = ({ icon, title, subtitle }: TitleSectionProps) => {
  return (
    <TitleContiner lg={12} style={{ gap: 10, display: 'flex' }}>
      <SvgIcon src={icon} color={undefined} h={3} />
      <div style={{ display: 'grid' }}>
        <KeraltyText type="subTitle" fontWeight={900} fontSize="18px" align="left">
          {title}
        </KeraltyText>
        <KeraltyText type="subTitle" fontSize="14px" align="left" color={'#212121'}>
          {subtitle}
        </KeraltyText>
      </div>
    </TitleContiner>
  );
};

export default TitleSection;