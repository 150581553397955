import { KeraltyIcon, KeraltyText } from "app/components/atoms";
import { KeraltyModal } from "app/components/molecules";
import { Container, Row, SpaceWrapper } from "app/components/templates";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { DoneButton, styleButton, styleDivFirst, styleIcon, styleText } from "./styles";
import { useTwoFactorAuthentication } from "app/hooks/useTwoFactorAuthentication";

const CodeExpired = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { resetInfoInTwoFactorStore } = useTwoFactorAuthentication();

  const handleClick = () =>{
    resetInfoInTwoFactorStore();
    history.replace(`/login`)
  }
  
  if (window.innerWidth < 1024) {

      return (
          <KeraltyModal size="long">
              <Row noMargin>
                  <div style={{ marginLeft: '34%' }} >
                      <KeraltyIcon iconClass="fas	fa-exclamation" color="secundaryAlert" size="medium" style={styleIcon} rounded />
                  </div>
                  <SpaceWrapper mb={1}>
                      <Container centered={'x'} noPadding noMargin>
                        <KeraltyText type={'label'} align={'center'} style={styleText}>
                          {t('message.twoFactorCodeExpired')}
                        </KeraltyText>
                      </Container>
                  </SpaceWrapper>

              </Row>
              <Row noMargin>
                  <div style={styleDivFirst}>    
                      <button
                          style={styleButton}
                          type="button"
                          onClick={handleClick}
                      > 	
                          {t('myAccount.textAccept')}
                      </button>
                  </div>
              </Row>
          </KeraltyModal>
      )
  }
  else {

      return (
          <div style={{ marginRight: '20%', marginLeft: '20%' }}>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '2.5rem' }}>
                  <KeraltyIcon
                      iconClass={'fas	fa-exclamation'}
                      size="big"
                      color="secundaryAlert"
                      title={t('imgNicons.paperPlaneIcon')}
                      rounded
                  />
              </div>
              <KeraltyText type="subTitle" width='550px'>
                {t('message.twoFactorCodeExpired')}
              </KeraltyText> 
              <div style={{ marginTop: '35px', display: 'flex', justifyContent: 'center' }}>
                  <DoneButton onClick={handleClick}> 	
                      { t('myAccount.textAccept') }
                  </DoneButton>
              </div>
          </div >
      )
  }
}

export default CodeExpired;