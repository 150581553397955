const nsButton = {
  en: {
    createAccount: 'create account',
    saveAndContinue: 'save and continue',
    next: 'next',
    continue: 'continue',
    changePassword: 'change password',
    close: 'Close',
    close2: 'CLOSE',
    back: 'back',
    bookAppointment: 'Book appointment',
    attach: 'attach',
    addOther: 'add other +',
    accept: 'Accept',
    accept2: 'ACCEPT',
    talkToaDoctor: 'Talk to a doctor',
    chatWithaDoctor: 'Chat with a Health Professional',
    chatWithaDoctorText: 'Connect with a healthcare provider using our chat platform',
    chatWithaDoctorC: 'CHAT WITH A HEALTH PROFESIONAL',
    televisit: 'Videocall with a Health Professional',
    televisitTN: 'Videocall with a Health Professional',
    televisitText: 'Connect with a healthcare provider via video',
	televisitTextV2: 'Connect with a healthcare provider using our videocall platform, available [days] [hours]',
	televisitTextV3: 'Available [days] [hours]',
	sorryTelevisit: "We're sorry but this service is currently not available",
	sorryTelevisitPartTwo: "For assistance, please call our Clinical Call Center at ",
    callDoctor: 'Call with a Health Professional',
    callDoctorText: 'Connect via phone with our clinical team (medical assistants and providers available)',
    televisit2: 'Televisit',
    checkYourSymp: 'Check your symptoms',
    backToHome: 'back to home',
    chatWithaCareEducator: 'Chat with care educator',
    virtualAssistant: 'Virtual Assistant',
    goToFB: 'Go to florida blue portal',
    update: 'UPDATE INFORMATION',
    filter: 'Filter',
    cancel: 'Cancel',
    done: "Done",
    ok: 'OK',
    chatWithaCareEducatorDescription: 'Get answers about ',
    chatWithaCareEducatorDescription2: 'Sanitas health programs to understand your medical condition',

    extends:'EXTEND MY SESSION TIME',
    advice:"Your session is near to expire. If you want to extend the time before closing your session select the “Extend my session time” option.",
    no:"NO",
    yes:"YES",
    inactive:"Do you want to save this insurance information? ",
    inactiveTitle:"Your insurance seems to be inactive",
    updatePop:"Tomorrow you are able to see the change on My Insurance functionality",
    UpdatePupTitle:"      Your information has been updated",
    UpdatePupTitleInactive:"      Your information has been updated",
    updatePopInactive:"The status of your insurance has changed to active. Remember that you'll be able to schedule appointments until tomorrow. ",
    errorUpdateInsurance:'There has been an issue updating your information. Please try again later.',
    supportChat:"Support Chat",
    comingSoon: 'COMING SOON',
    schedule: 'Available Monday thru Friday 8:00 a.m - 6:00 p.m',
    beAble: 'You will be able to:',
    disabledChatTitle: 'Chat with a doctor will be available very soon!',
    disabledChatSubTitle: 'Connect with a healthcare provider using our chat platform',
    disabledVideoChatTitle: 'Videocall with a doctor will be available very soon!',
    disabledVideoChatSubTitle: 'Connect with a healthcare provider via video',
    returnPreviousMenu: 'Return to previous menu',
    cancelTransaction: 'CANCEL TRANSACTION',
		unblockAccount: "Unblock account"
  },
  es: {
    createAccount: 'crear cuenta',
    saveAndContinue: 'guardar y continuar',
    next: 'siguiente',
    continue: 'Continuar',
    changePassword: 'cambiar contraseña',
    close: 'Cerrar',
    close2: 'CERRAR',
    back: 'regresar',
    bookAppointment: 'Agendar cita',
    attach: 'adjuntar',
    addOther: 'agregar otro +',
    accept: 'Aceptar',
    accept2: 'ACEPTAR',
    talkToaDoctor: 'Habla con un doctor',
    chatWithaDoctor: 'Chatea con un Profesional de la Salud',
    chatWithaDoctorC: 'CHAT CON UN PROFESIONAL DE LA SALUD',
    televisit: 'Teleconsulta',
    // televisitTN: 'Videollamada con un Profesional de la salud',
	televisitTN: 'Teleconsulta con un Doctor',
    televisit2: 'Teleconsulta',
    callDoctor: 'Llamada con un Profesional de la Salud',
    checkYourSymp: 'Chequea tus síntomas ',
    backToHome: 'regresar a pagina principal',
    chatWithaCareEducator: 'Chatea con tu educador médico',
    virtualAssistant: 'Asistente virtual',
    goToFB: 'Ir al portal de Florida Blue',
    update: '   ACTUALIZAR INFORMACIÓN   ',
    filter: 'Filtrar',
    cancel: 'Cancelar',
    done: "Hecho",
    ok: 'ACEPTAR',
    chatWithaDoctorText: 'Conectate con un médico usando nuestra plataforma de chat',
    televisitText: 'Conéctate con un médico para una videollamada',
	televisitTextV2: 'Conéctate con un médico usando nuestra plataforma de video llamada, disponible [days] [hours]',
	televisitTextV3: 'Disponible [days] [hours]',
	sorryTelevisit: "Lo sentimos, este servicio no está disponible actualmente.",
	sorryTelevisitPartTwo: "Para obtener asistencia, llame a nuestro Centro de Atención Clínica al ",
    callDoctorText: 'Conéctate con un agente para resolver tus dudas',
    chatWithaCareEducatorDescription: 'Obtén respuestas sobre los programas de salud de ',
    chatWithaCareEducatorDescription2: 'Sanitas para entender tu condición médica',

    extends:'EXTENDER MI TIEMPO DE SESIÓN',
    advice:'Tu sesión está a punto de expirar. Si deseas extender el tiempo de sesión, haz clic en el botón de “Extender mi tiempo de sesión”',
    no:"NO",
    yes:"SI",
    inactive:"¿Desea guardar esta información del seguro ingresada?",
    inactiveTitle:"Tu seguro parece estar inactivo",
    updatePop:"Estos cambios se verán reflejados mañana en nuestros sistemas.",
    UpdatePupTitle:"Tu información ha sido actualizada",
    UpdatePupTitleInactive:"      Tu información ha sido actualizada",
    updatePopInactive:" El estado de tu seguro ha cambiado a activo. Recuerda que podrás agendar citas hasta mañana. ",
    errorUpdateInsurance:'Ha ocurrido un problema actualizando tu información. Por favor inténtalo más tarde.',

    supportChat:"Chat de Soporte",
    comingSoon: 'PROXIMAMENTE DISPONIBLE',
    schedule: 'Disponible Lunes a Viernes 8:00 a.m - 6:00 p.m',
    beAble: 'Serás capaz de:',
    disabledChatTitle: '¡Chatea con un doctor estará disponible pronto!',
    disabledChatSubTitle: 'Conectarte con un médico usando nuestra plataforma de chat',
    disabledVideoChatTitle: '¡Teleconsulta con un doctor estará disponible pronto!',
    disabledVideoChatSubTitle: 'Conéctarte con un médico para una videollamada',
    returnPreviousMenu: 'REGRESAR AL MENÚ ANTERIOR',
    cancelTransaction: 'CANCELAR TRANSACCIÓN',
		unblockAccount: "DESBLOQUEAR CUENTA"
  },
};

export default nsButton;
