/**
 * This function only is used to validate if time value is iqual to 0
 * 
 * @param time 
 * @return number 
 */
import moment from "moment";

const validateTime = (time: number): number => {
    if(time === 0) return 1;
    return time;
}


// This function gets the UTC of the timezone.
const today = new Date();

const utcTime = () => {
    let utc = moment(today).utcOffset()
	const utcNow = (moment(today).utcOffset(utc).format("Z"));
    return utcNow;
}

export {
    validateTime,
    utcTime
}